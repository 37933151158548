/* ------------------------ Functional imports start ------------------------ */
import React from "react"
import { useTranslation } from "react-i18next"
import GetConstant from "./utils/constants"
import { GuestRequest } from "./utils/types"
/* ------------------------- Functional imports end ------------------------- */
/* -------------------------- Design imports start -------------------------- */
import MultilevelDrawer from "../../components/layout/MultilevelDrawer"
import { Grid } from "@mui/material"
import { Form } from "../Form"
import { updateGuestRequest } from "./utils/functions"
import { toast } from "react-toastify"
/* --------------------------- Design imports end --------------------------- */


type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  onClose?: () => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  dataSource: any
  setFilteredData: React.Dispatch<React.SetStateAction<GuestRequest[]>>
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function UnauthRequestDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, onClose, formState, dataSource, setFilteredData } = props
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */


  /* -------------------------- Render constants start ------------------------ */
  const guestRequestFormFields: any = GetConstant({ name: 'guestRequestEditFormFields' })
  /* --------------------------- Render constants end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <MultilevelDrawer
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      title={t("common:content.label.editRequest") || ""}
      confirmButtonProps={{
        text: t("common:interaction.button.save"),
        onConfirm: async () => {
          updateGuestRequest(
            formState.state as GuestRequest,
            (gr: GuestRequest) => {              
              const newDataSource = [...dataSource.current]
              const index = dataSource.current.findIndex((g: GuestRequest) => g.self === gr.self)
              if(index > -1) {
                newDataSource[index] = gr
                dataSource.current = newDataSource                
              }
              setFilteredData(newDataSource)              
              toast.success(t("common:feedback.success.saved") || "")
              setOpen(false)
              onClose && onClose()
            },
            () => {
              toast.error(t("common:feedback.error.savingFailed") || "")
            }
          )
        },
        disabled: false,
        hidden: false,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            editing={true}
            formFields={guestRequestFormFields}
            formObject={formState.state}
            onChange={(value: any) => {              
              formState.setState(value)
            }}            
          />
        </Grid>
      </Grid>
    </MultilevelDrawer>      
  )
}