
/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxInput from '../../../components/inputs/CheckboxInput'
import ColorInput from '../../../components/inputs/ColorInput'
import DateInput from '../../../components/inputs/DateInput'
import EmailInput from '../../../components/inputs/EmailInput'
import NumberInput from '../../../components/inputs/NumberInput'
import S3FileUpload from '../../../components/inputs/S3FileUpload'
import SearchSelectInput from '../../../components/inputs/SearchSelectInput'
import TextInput from '../../../components/inputs/TextInput'
import { FieldObject } from '../utils/types'
import CheckedTextInput from '../../../components/inputs/CheckedTextInput'
import SelectWithTextInput from '../../../components/inputs/SelectWithTextInput'
import CADFileUpload from '../../../components/inputs/CADFileUpload'
import TimeDeltaInput from '../../../components/inputs/TimeDeltaInput'
import SearchMultiSelectInput from '../../../components/inputs/SearchSelectMultiInput'
import OptionalTextInput from '../../../components/inputs/OptionalTextInput'
import TimeInput from '../../../components/inputs/TimeInput'
import NumberInputWithUnit from '../../../components/inputs/NumberInputWithUnit'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  field: FieldObject
  disabled?: boolean
  onChange: (value: any) => void
  value: any
  required?: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export function FormField({ field, onChange, value, disabled, required }: Props) {
  const { t } = useTranslation()
  switch (field.type) {
    case 'custom':
      return <div>{field.CustomComponent}</div>
    case 'email':
      return (
        <EmailInput
          label={field.label}
          onChange={onChange}
          value={value}
          disabled={disabled || field.disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          required={required}
          showError={field.showError}
        />
      )
    case 'selectWithFreeText':
      return (
        <SelectWithTextInput
          name={field.label}
          value={value}
          options={field.options}
          onChange={onChange}
          freeSolo={true}
          disabled={disabled}
          required={required}
          validation={field.validation}
        />
      )
    case 'select-search':
      return (
        <SearchSelectInput
          name={field.label}
          value={value}
          options={field.options}
          loadData={field.loadData}
          loadAllData={field.loadAllData}
          onChange={onChange}
          disabled={disabled || field.disabled}
          errorMessage={field.errorMessage}
          validation={field.validation}
          required={required}
          onAddOption={field.onAddOption}
        />
      )
    case 'select-search-multi':
      return (
        <SearchMultiSelectInput
          name={field.label}
          value={value}
          options={field.options}
          loadData={field.loadData}
          loadAllData={field.loadAllData}
          onChange={onChange}
          disabled={disabled}
          errorMessage={field.errorMessage}
          validation={field.validation}
          required={required}
          onAddOption={field.onAddOption}
        />
      )
    case 'text':
      return (
        <TextInput
          label={field.label}
          onChange={onChange}
          value={value}
          disabled={disabled || field.disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          required={required}
          showError={field.showError}
          loadSuggestions={field.loadSuggestions}
          suggestions={field.suggestions}
        />
      )
    case 'optional-text':
      return (
        <OptionalTextInput
          label={field.label}
          onChange={onChange}
          value={value}
          disabled={disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          required={required}
          showError={field.showError}
        />
      )
    case 'multiline':
      return (
        <TextInput
          label={field.label}
          onChange={onChange}
          value={value}
          disabled={disabled || field.disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          multiline={true}
          required={required}
          showError={field.showError}
        />
      )
    case 'number':
      return (
        <NumberInput
          label={field.label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          helperText={field.helperText}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          required={required}
          showError={field.showError}
        />
      )
      case 'measurement':
        return (
              <NumberInputWithUnit
                unit={field.unit}
                label={field.label}
                value={value}
                limits={{minInput: 0.000000000000000001}}
                onChange={(input_value) => onChange(!Number.isNaN(input_value) && input_value)}
                disabled={disabled}
                required={((value && value.unit) && !value.value)}
                errorMessage={field.errorMessage}
                labelWidth={field.labelWidth}
                validation={true}
              />
        )
    case 'timedelta':
      return (
        <TimeDeltaInput
          label={field.label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
      )
    case 'time':
      return (
        <TimeInput
          label={field.label}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )
    case 'date':
      return (
        <DateInput
          value={value}
          label={field.label}
          onChange={onChange}
          disabled={disabled}
          errorMessage={field.errorMessage}
          labelWidth={field.labelWidth}
          validation={field.validation}
          required={required}
        />
      )
    case 'checkbox':
      return (
        <CheckboxInput
          value={value}
          label={field.label}
          onChange={onChange}
          disabled={disabled || field.disabled}
          required={required}
        />
      )
    case 'checked':
      return (
        <CheckedTextInput
          value={value}
          label={field.label}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
      )
    case 'color':
      return <ColorInput onChange={onChange} value={value} />
    case 'file_upload':
      return <S3FileUpload />
    case 'cad_upload':
      return <CADFileUpload />
    case 'placeholder':
      return <div />
    default:
      return null
  }
}
