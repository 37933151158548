/* -------------------------- Design imports start -------------------------- */
import { Form, FormFieldType } from '../Form'
import { Alert, CircularProgress, Collapse, Grid, Link, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { GuestRequest } from './utils/types'
import GetConstant from './utils/constants'
import { Trans, useTranslation } from 'react-i18next'
import UnauthS3FileUpload from '../../components/inputs/UnauthS3FileUpload'
import { missingRequiredFormFields } from '../../utils/functions'
import { postGuestRequest } from './utils/functions'
import { toast } from 'react-toastify'
import useMobileService from '../../hooks/useMobileService'
import LogTool from '../../logger/logTools'
import { KeyboardArrowDown } from '@mui/icons-material'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  selectedForm: string
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function UnauthRequestForm(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'UnauthRequestForm', enable: true, logLevel: 'warn' })
  const { t } = useTranslation(['common', 'request'])
  const { selectedForm } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [expanded, setExpanded] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [guestRequest, setGuestRequest] = useState<GuestRequest | undefined>(undefined)
  const [fileList, setFileList] = useState<any[]>([])
  const isMobile = useMobileService()
  /* ----------------------------- Data states end ---------------------------- */

  /* ---------------------------- Use Effects start --------------------------- */
  useEffect(() => {
    guestRequest && isMobile ? setExpanded(true) : setExpanded(false)
  }, [guestRequest, isMobile])
  /* ----------------------------- Use Effects end ---------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const guestRequestFormFields: FormFieldType[] = GetConstant({
    name: 'guestRequestFormFields',
  }) as FormFieldType[]
  const mobileBaseFields = ['name', 'email']
  const mobileExpandedFields = ['company', 'phone', 'address', 'zipCode', 'city', 'country', 'quantity', 'description']
  const mobileBaseFormFields: FormFieldType[] = guestRequestFormFields.filter(
    (field: FormFieldType) => mobileBaseFields.includes(field.key as string)
  )
  const mobileExpandedFormFields: FormFieldType[] = guestRequestFormFields.filter(
    (field: FormFieldType) => mobileExpandedFields.includes(field.key as string)
  )

  /* -------------------------- Render constants end -------------------------- */

  if (loading) return (
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  if(isMobile) return (
    <Grid container justifyContent="center" alignItems="center" margin="auto" spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container justifyContent="center" alignItems="center" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} height={"80%"}>
            <Typography  variant="h5" align="center">
              {t('request:content.heading.startYourRequestHere')} <b>{t('request:content.heading.request')}</b> <br />
              {t('request:content.heading.weAreLookingForwardToYourProject')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{height: "100%"}}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form
                  formFields={mobileBaseFormFields}
                  formObject={guestRequest as GuestRequest}
                  onChange={(value: any) => setGuestRequest(value as GuestRequest)}
                  editing={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Collapse in={expanded}>
                  <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{height: "100%"}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form
                        formFields={mobileExpandedFormFields}
                        formObject={guestRequest as GuestRequest}
                        onChange={(value: any) => setGuestRequest(value as GuestRequest)}
                        editing={true}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <UnauthS3FileUpload fileList={fileList} setFileList={setFileList} selectedForm={selectedForm} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="caption" align="center" color={selectedForm === "unauthRequestForm" ? "" : "gray"}>
                        {t('request:content.label.requiredFieldsText')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              {showAlert && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert severity="error" onClose={() => setShowAlert(false)}>
                  {t('request:feedback.error.requiredFields')}
                </Alert>
              </Grid>)}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  id="sendRequestButton"
                  variant="contained"
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  fullWidth
                  onClick={() => {
                    if(!expanded) {
                      setExpanded(true)
                      return
                    }
                    if(missingRequiredFormFields(guestRequestFormFields, guestRequest)) {
                      setShowAlert(true)
                      return
                    }
                    if (guestRequest === undefined) return
                    setLoading(true)
                    postGuestRequest(
                      guestRequest,
                      fileList,
                      () => {
                        toast.success(t('request:feedback.success.requestSent'))
                        setGuestRequest(undefined)
                        setFileList([])
                        setLoading(false)
                      },
                      error => {
                        toast.error(t('request:feedback.error.requestNotSent'))
                        log.error("Error posting Guest Request -> ", error)
                        setLoading(false)
                      }
                    )
                  }}
                >
                  {!expanded
                    ? <KeyboardArrowDown />
                    : t('request:interaction.button.sendRequest')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )


  return (
    <Grid container justifyContent="center" alignItems="center" margin="auto" spacing={2}>
      <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
        <Grid container justifyContent="center" alignItems="center" spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} height={"80%"}>
            <Typography style={{marginBottom:'20px'}} variant="h5" align="center" color={selectedForm === "unauthRequestForm" ? "" : "gray"}>
              {t('request:content.heading.startYourRequestHere')} <b>{t('request:content.heading.request')}</b> <br />
              {t('request:content.heading.weAreLookingForwardToYourProject')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{height: "100%"}}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form
                  formFields={guestRequestFormFields}
                  formObject={guestRequest as GuestRequest}
                  onChange={(value: any) => setGuestRequest(value as GuestRequest)}
                  editing={true}
                  sx={{
                    opacity: selectedForm === "unauthRequestForm" ? 1 : 0.8,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt:'-12px'
              }}>
                <Typography variant="caption" align="center" color={selectedForm === "unauthRequestForm" ? "" : "gray"}>
                  {t('request:content.label.requiredFieldsText')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  opacity: selectedForm === "unauthRequestForm" ? 1 : 0.8
                }}
              >
                <UnauthS3FileUpload fileList={fileList} setFileList={setFileList} selectedForm={selectedForm} />
              </Grid>
              {showAlert && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert severity="error" onClose={() => setShowAlert(false)}>
                  {t('request:feedback.error.requiredFields')}
                </Alert>
              </Grid>)}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              id="sendRequestButton"
              variant="contained"
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                opacity: selectedForm === "unauthRequestForm" ? 1 : 0.6
              }}
              fullWidth
              onClick={() => {
                if(missingRequiredFormFields(guestRequestFormFields, guestRequest)) {
                  setShowAlert(true)
                  return
                }
                if (guestRequest === undefined) return
                setLoading(true)
                postGuestRequest(
                  guestRequest,
                  fileList,
                  () => {
                    toast.success(t('request:feedback.success.requestSent'))
                    setGuestRequest(undefined)
                    setFileList([])
                    setLoading(false)
                  },
                  error => {
                    toast.error(t('request:feedback.error.requestNotSent'))
                    log.error("Error posting Guest Request -> ", error)
                    setLoading(false)
                  }
                )
              }}
            >
              {t('request:interaction.button.sendRequest')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" align="center" color={selectedForm === "unauthRequestForm" ? "" : "gray"}>
              {t('request:content.label.orSendEmailInstead')} <Link href="mailto:info@assemblean.com">info@assemblean.com</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
