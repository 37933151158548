/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIChannel,
    APIChannelFromJSON,
    APIChannelToJSON,
    APIChannelCreation,
    APIChannelCreationFromJSON,
    APIChannelCreationToJSON,
    APIChannelCreationRequest,
    APIChannelCreationRequestFromJSON,
    APIChannelCreationRequestToJSON,
    APIPaginatedChannelList,
    APIPaginatedChannelListFromJSON,
    APIPaginatedChannelListToJSON,
    APIPatchedChannelRequest,
    APIPatchedChannelRequestFromJSON,
    APIPatchedChannelRequestToJSON,
} from '../models';

export interface V1ChatChannelCreateRequest {
    aPIChannelCreationRequest: APIChannelCreationRequest;
}

export interface V1ChatChannelDestroyRequest {
    id: string;
}

export interface V1ChatChannelListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ChatChannelPartialUpdateRequest {
    id: string;
    aPIPatchedChannelRequest?: APIPatchedChannelRequest;
}

export interface V1ChatChannelRetrieveRequest {
    id: string;
}

export interface V1ChatChannelUpdateRequest {
    id: string;
    aPIChannelCreationRequest: APIChannelCreationRequest;
}

/**
 * 
 */
export class ChatchannelApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelCreateRaw(requestParameters: V1ChatChannelCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannelCreation>> {
        if (requestParameters.aPIChannelCreationRequest === null || requestParameters.aPIChannelCreationRequest === undefined) {
            throw new runtime.RequiredError('aPIChannelCreationRequest','Required parameter requestParameters.aPIChannelCreationRequest was null or undefined when calling v1ChatChannelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIChannelCreationRequestToJSON(requestParameters.aPIChannelCreationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelCreationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelCreate(requestParameters: V1ChatChannelCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannelCreation> {
        const response = await this.v1ChatChannelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelDestroyRaw(requestParameters: V1ChatChannelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelDestroy(requestParameters: V1ChatChannelDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ChatChannelDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelListRaw(requestParameters: V1ChatChannelListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedChannelList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedChannelListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelList(requestParameters: V1ChatChannelListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedChannelList> {
        const response = await this.v1ChatChannelListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelPartialUpdateRaw(requestParameters: V1ChatChannelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedChannelRequestToJSON(requestParameters.aPIPatchedChannelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelPartialUpdate(requestParameters: V1ChatChannelPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannel> {
        const response = await this.v1ChatChannelPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelRetrieveRaw(requestParameters: V1ChatChannelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelRetrieve(requestParameters: V1ChatChannelRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannel> {
        const response = await this.v1ChatChannelRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Only the creator of a channel is allowed to edit the channel. Make sure that the requesting user is the creator of the channel or forbid the action.
     */
    async v1ChatChannelUpdateRaw(requestParameters: V1ChatChannelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannelCreation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelUpdate.');
        }

        if (requestParameters.aPIChannelCreationRequest === null || requestParameters.aPIChannelCreationRequest === undefined) {
            throw new runtime.RequiredError('aPIChannelCreationRequest','Required parameter requestParameters.aPIChannelCreationRequest was null or undefined when calling v1ChatChannelUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIChannelCreationRequestToJSON(requestParameters.aPIChannelCreationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelCreationFromJSON(jsonValue));
    }

    /**
     * Only the creator of a channel is allowed to edit the channel. Make sure that the requesting user is the creator of the channel or forbid the action.
     */
    async v1ChatChannelUpdate(requestParameters: V1ChatChannelUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannelCreation> {
        const response = await this.v1ChatChannelUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
