/* -------------------------- Design imports start -------------------------- */
import { Chip, Grid, Stack } from "@mui/material"
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Address, SelectOption } from "../../../utils/types"
import moment from "moment"
import { Check, Close } from "@mui/icons-material"
import { APIAddress } from "../../../generated-types"
import { createAddress, getPKfromSelf, isPrivileged, loadAddressOptions, loadAllStaffUsers } from "../../../utils/functions"
import { Bill, Contract } from "./types"
import { useUserContext } from "../../../utils/context"
import { Offer, Request } from "../../Request/utils/types"
import { Article } from "../../Item/utils/types"
import Button from "../../../components/Button"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
  name:
    | 'contractTableColumns'
    | 'billTableColumns'
    | 'certificateOptions'
    | "contractFormFields"
    | "contractFormFieldsDetails"
    | "contractFormFieldsDetailsEditable"
    | "contractFormFieldsStaff"
    | "billStaffFormFields"
    | "addressFormFields"
    | "shippingFormFields"
    | "billingAddressFormFields"
    | "contractFormFieldsDisabled"
} & (
  ContractTableColumnsKwargs
  | BillTableColumnsKwargs
  | CertificateOptions
  | ContractFormFieldsDetailsKwargs
  | ContractFormFieldsDetailsEditableKwargs
  | ContractFormFieldsStaffKwargs
  | ContractFormFieldsKwargs
  | BillStaffFormFieldsKwargs
  | AddressFormFieldsKwargs
  | BillingAddressFormFieldsKwargs
  | ShippingFormFieldsKwargs
)

type ContractTableColumnsKwargs = {
  name: 'contractTableColumns'
  newContracts?: Contract[]
  handleBill?: (e: any, record: Contract) => void
}

type BillTableColumnsKwargs = {
  newBills?: Bill[]
  name: 'billTableColumns'
}

type CertificateOptions = {
  name: 'certificateOptions'| "contractFormFieldsDisabled"
}
type ContractFormFieldsKwargs = {
  name: 'contractFormFields'
  certificateOptions: SelectOption[]
  selectedProduct: string
}
type ContractFormFieldsDetailsKwargs = {
  name: 'contractFormFieldsDetails'
}

type ContractFormFieldsStaffKwargs = {
  name: 'contractFormFieldsStaff'
}

type BillFormFieldsDisabledKwargs = {
  name: 'billFormFieldsDisabled'
}

type BillStaffFormFieldsKwargs = {
  name: 'billStaffFormFields'
}

type AddressFormFieldsKwargs = {
  name: 'addressFormFields'
  addressOptions?: SelectOption[]
  onAddAddressOption?: () => void
}
type BillingAddressFormFieldsKwargs = {
  name: 'billingAddressFormFields'
  addressOptions?: SelectOption[]
  onAddBillingAddressOption?: (address: Address) => void
}
type ContractFormFieldsDetailsEditableKwargs = {
  name: 'contractFormFieldsDetailsEditable'
  certificateOptions: SelectOption[]
  selectedProduct: string
}

type ShippingFormFieldsKwargs = {
  name: 'shippingFormFields'
}



/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function GetConstant(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { t } = useTranslation()
  const { user } = useUserContext()
  const history = useHistory()

  const renderContractStatus = (status: number | undefined) => {
    if(status === undefined) return t("common:content.label.notAvailable")
    switch (status) {
      case 0:
        return t("common:content.label.created")
      case 1:
        return t("common:content.label.productionOrdersCreated")
      case 2:
        return t("common:content.label.inProgress")
      case 3:
        return t("common:content.label.shipped")
      case 4:
        return t("common:content.label.completed")
      case 5:
        return t("common:content.label.billCreated")
      case 6:
        return t("common:content.label.paid")
      case 7:
        return t("common:content.label.archived")
      case 8:
        return t("common:content.label.trash")
      case 9:
        return t("common:content.label.deleted")
      default:
        return status
    }
  }

  const renderAddress = (apiAddress: APIAddress) => {
    const address = createAddress(apiAddress as Address);
    return (
        <>
            {address?.address1}, {address?.address2} {address?.address2}<br />
            {address?.zipCode} {address?.city}<br />
            {address?.country} <br />
            {address?.info}
        </>
    )
  }
  /* --------------------------- Non state data end --------------------------- */
  if(props.name === "contractTableColumns") {
    return [
      {
        title: "",
        dataIndex: 'read',
        key: 'read',
        render: (read: boolean, record: Contract) => {
          const newContract = props.newContracts?.find(contract => contract.key === record.key)
          if(!newContract) return
          if(newContract && newContract.read === true) return
          if (newContract && newContract.read === false) {
            return (
              <Grid container justifyContent="center">
                <Grid item>
                  {newContract && newContract.read === false
                    && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                  }
                </Grid>
              </Grid>
            )
          }
        }
      },
      {
        title: t('common:content.label.contractNumber'),
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: t('contract:content.label.offerNumber'),
        dataIndex: 'offer',
        key: 'offer',
        render: (offer: Offer) => offer?.self && getPKfromSelf(offer.self),
      },
      {
        title: t('common:content.label.article'),
        dataIndex: 'article',
        key: 'article',
        render: (article: Article) => article?.name,
      },
      {
        title: t('common:content.label.price'),
        dataIndex: 'price',
        key: 'price',
        render: (price: any) => Object.keys(price).map((key) => `${key} ${t("request:content.label.pieces")}: ${price[key]} €`).join(', '),
      },
      {
        title: t('common:content.label.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date: moment.MomentInput) => moment(date).format('DD.MM.YYYY'),
      },
      {
        title: t('common:content.label.certificate'),
        dataIndex: 'request',
        key: 'certificate',
        render: (request: Request) => request?.certificate,
      },
      {
        title: t('common:content.label.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
      {
        title: t('common:content.label.desiredDeliveryDate'),
        dataIndex: 'desiredDelivery',
        key: 'desiredDelivery',
        render: (date: moment.MomentInput) => moment(date).format('DD.MM.YYYY'),
      },
      {
        title: t('common:content.label.latestDeliveryDate'),
        dataIndex: 'latestDelivery',
        key: 'latestDelivery',
        render: (date: moment.MomentInput) => moment(date).format('DD.MM.YYYY'),
      },
      {
        title: t('common:content.label.shippingAddress'),
        dataIndex: 'shippingAddress',
        key: 'shippingAddress',
        render: (address: APIAddress | undefined) => address && renderAddress(address),
      },
      {
        title: t('common:content.label.billingAddress'),
        dataIndex: 'billingAddress',
        key: 'billingAddress',
        render: (address: APIAddress | undefined) => address && renderAddress(address),
      },
      {
        title: t('common:content.label.priorityShipping'),
        dataIndex: 'priorityShipping',
        key: 'priorityShipping',
        render: (priority_shipping: boolean) =>
          <>
            {priority_shipping
              ? <Check />
              : <Close />
            }
          </>,
      },
      {
        title: t('common:content.label.contractStatus'),
        dataIndex: 'contractStatus',
        key: 'contractStatus',
        render: (status: number) => renderContractStatus(status)
      },
      {
        title: t('common:content.label.editors'),
        key: 'editors',
        dataIndex: 'editors',
        render: (text: string, record: Contract) => {
            if (Array.isArray(record.editors)) {
              return (
                <>
                  {record.editors.map((e: any) => (
                    <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                  ))}
                </>
              )
            } else {
              return ''
            }
          },
      },
      /* {
        title: t('common:content.label.info'),
        dataIndex: 'info',
        key: 'info',
        render: () => (
          <IconButton id="openInfoBarButton">
            <InfoOutlined />
          </IconButton>
        ),
      }, */
      {
        title: t('common:content.label.action'),
        key: 'action',
        dataIndex: 'action',
        render: (text: string, record: Contract) => (
          <Stack direction="column" spacing={1}>
            <Button
              id="billByAssembleanButton"
              size="small"
              variant="contained"
              onClick={(e) => props.handleBill && props.handleBill(e, record)}
              disabled={record.contractStatus !== 4}
            >
              {t('contract:interaction.button.createBill')}
            </Button>
          </Stack>
        ),
      }
    ].filter((column) => (!isPrivileged(user, "STAFF") && column.key !== 'editors' && column.key !== "action") || isPrivileged(user, "STAFF"))
  }

  if(props.name === "billTableColumns") {
    return [
      {
        title: "",
        dataIndex: 'read',
        key: 'read',
        render: (read: boolean, record: Bill) => {
          const newBill = props.newBills?.find(bill => bill.key === record.key)
          if(!newBill) return
          if(newBill && newBill.read === true) return
          if (newBill && newBill.read === false) {
            return (
              <Grid container justifyContent="center">
                <Grid item>
                  {newBill && newBill.read === false
                    && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                  }
                </Grid>
              </Grid>
            )
          }
        }
      },
      {
        title: t('contract:content.label.billNumber'),
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: t('common:content.label.contractNumber'),
        dataIndex: 'contract',
        key: 'contract',
        render: (contract: Contract) => contract?.self ? getPKfromSelf(contract.self) : '',
      },
      {
        title: t('common:content.label.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date: moment.MomentInput) => moment(date).format('DD.MM.YYYY'),
      },
      {
        title: t('common:content.label.dueDate'),
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (date: moment.MomentInput) => moment(date).format('DD.MM.YYYY'),
      },
      {
        title: t('contract:content.label.amount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: number) => `${amount} €`,
      },
      {
        title: t('common:content.label.paid'),
        dataIndex: 'paid',
        key: 'paid',
        render: (paid: boolean) =>
          <>
            {paid
              ? <Check />
              : <Close />
            }
          </>,
      },
      {
        title: t('common:content.label.editors'),
        key: 'editors',
        dataIndex: 'editors',
        render: (text: string, record: Contract) => {
            if (Array.isArray(record.editors)) {
              return (
                <>
                  {record.editors.map((e: any) => (
                    <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                  ))}
                </>
              )
            } else {
              return ''
            }
          },
      },
    ].filter((column) => (!isPrivileged(user, "STAFF") && column.key !== 'editors') || isPrivileged(user, "STAFF"))
  }

  if(props.name === "certificateOptions") {
    return [
      { value: 'AS 9100D', label: 'AS 9100D' },
      { value: 'DIN EN ISO 13485', label: 'DIN EN ISO 13485' },
      { value: 'DIN EN ISO 9001', label: 'DIN EN ISO 9001' },
      { value: 'NADCAP', label: 'NADCAP' },
      { value: 'ISO 14001', label: 'ISO 14001' },
      { value: 'IATF 16949', label: 'IATF 16949' },
      { value: 'ISO 27001', label: 'ISO 27001' },
      { value: 'BS7799', label: 'BS7799' },
      { value: 'ISO 50001', label: 'ISO 50001' },
      { value: 'EN ISO 17065', label: 'EN ISO 17065' },
      { value: 'SA8000', label: 'SA8000' },
      { value: 'EMAS', label: 'EMAS' },
      { value: 'DIN EN 1090', label: 'DIN EN 1090' },
      { value: 'DIN EN ISO 3834', label: 'DIN EN ISO 3834' },
      { value: 'DIN 2304', label: 'DIN 2304' },
      { value: 'DIN EN ISO 13585', label: 'DIN EN ISO 13585' },
      { value: 'DIN ISO 11745', label: 'DIN ISO 11745' },
    ]
  }
  if (props.name === "contractFormFieldsDisabled") {
    return[
      {
        type: 'text',
        key: 'name',
        label: t('contract:content.label.selectedProduct'),
        xs: 12,
        sm: 6,
      },
      {
        type: 'text',
        key: 'number',
        label: t('contract:content.label.articleNumber'),
        xs: 12,
        sm: 6,
      },
    ]
  }
  if(props.name === "contractFormFields") {
    return [/*
      {
        type: 'custom',
        key: 'link',
        xs: 12,
        sm: 6,
        CustomComponent: (
          <div>
            <Button fullWidth variant="outlined" sx={{textTransform:'none', fontSize:'16px', height:'55px'}} onClick={() => {
                console.log(props.selectedProduct,'props.selectedProduct')
                  history.push({
                    pathname: '/items',
                    state: {
                      selectedArticleName: props.selectedProduct,
                    },
                  })}
                }>
              {t('contract:interaction.button.viewProduct')}
            </Button>
          </div>
        ),
      },*/
      {
        type: 'multiline',
        key: 'comment',
        label: t('common:content.label.comment'),
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    ]
  }
  if(props.name === "contractFormFieldsDetails") {
    return[
      {
        type: 'text',
        key: 'article',
        label: t('contract:content.label.selectedProduct'),
        xs: 12,
        sm: 6,
      },
      {
        type: 'text',
        key: 'key',
        label: t('contract:content.label.articleNumber'),
        xs: 12,
        sm: 6,
      },
    ]
  }
  if(props.name === "contractFormFieldsDetailsEditable") {
    return [
      {
        type: 'custom',
        key: 'link',
        xs: 12,
        sm: 6,
        CustomComponent: (
          <div>
            <Button fullWidth variant="outlined"  sx={{ fontSize:'16px', height:'55px'}} onClick={() => {
                  history.push({
                    pathname: '/items',
                    state: {
                      selectedArticleName: props.selectedProduct,
                    },
                  })}
                }>
              {t('contract:interaction.button.viewProduct')}
            </Button>
          </div>
        ),
      },
      { type: 'number', required:true, validation:true, key: 'quantity', label: t('common:content.label.quantity'), xs: 12, sm: 6 },
      {
        type: 'select-search',
        key: 'certificates',
        label: t('contract:content.label.certificates'),
        xs: 12,
        sm: 6,
        options: props.certificateOptions,
        validation: false,
        required: false,
      },
      {
        type: 'text',
        key: 'comment',
        label: t('common:content.label.comment'),
        xs: 12,
        sm: 6,
      },
    ]
  }

  if(props.name === "contractFormFieldsStaff") {
    /* contract_status, editors */
    return [
      {
        type: 'select-search',
        key: 'contractStatus',
        label: t('common:content.label.contractStatus'),
        options: [
          { value: 0, label: t('common:content.label.created') },
          { value: 1, label: t('common:content.label.productionOrdersCreated') },
          { value: 2, label: t('common:content.label.inProgress') },
          { value: 3, label: t('common:content.label.shipped')},
          { value: 4, label: t('common:content.label.completed')},
          { value: 5, label: t('common:content.label.billCreated')},
          { value: 6, label: t('common:content.label.paid')},
          { value: 7, label: t('common:content.label.archived')},
          { value: 8, label: t('common:content.label.trash')},
          { value: 9, label: t('common:content.label.deleted')},
        ] as any[],
        required: false,
        validation: false,
        showError: false,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      {
        type: 'select-search-multi',
        key: 'editors',
        label: t('common:content.label.editors'),
        options: [] as SelectOption[],
        loadData: loadAllStaffUsers,
        required: false,
        validation: false,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      }
    ]
  }

  if(props.name === "billStaffFormFields") {
    return [
      {
        type: 'date',
        key: 'dueDate',
        label: t('common:content.label.dueDate'),
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      {
        type: 'select-search-multi',
        key: 'editors',
        label: t('common:content.label.editors'),
        options: [] as SelectOption[],
        loadData: loadAllStaffUsers,
        required: false,
        validation: false,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      {
        type: 'checkbox',
        key: 'paid',
        label: t('common:content.label.paid'),
        required: false,
        validation: false,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ]
  }

  if(props.name === "addressFormFields") {
    return [
        {
            type: 'select-search',
            key: 'shippingAddress',
            label: t('common:content.label.shippingAddress'),
            options: props.addressOptions,
            onAddOption: props.onAddAddressOption,
            loadData: loadAddressOptions,
            required: true,
            validation: false,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12,
        },
    ]
  }
  if(props.name === "billingAddressFormFields") {
    return [
        {
            type: 'select-search',
            key: 'billingAddress',
            label: t('common:content.label.billingAddress'),
            options: props.addressOptions,
            onAddOption: props.onAddBillingAddressOption,
            loadData: loadAddressOptions,
            required: true,
            validation: false,
            xl: 12,
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12,
        },
    ]
  }

  if(props.name === "shippingFormFields") {
    return [
      {
        type: 'date',
        key: 'desiredDelivery',
        label: t('common:content.label.desiredDeliveryDate'),
        xs: 12,
        sm: 6,
      },
      {
        type: 'date',
        key: 'latestDelivery',
        label: t('common:content.label.latestDeliveryDate'),
        xs: 12,
        sm: 6,
      },
      {
        type: 'checkbox',
        key: 'priorityShipping',
        label: t('common:content.label.priorityShipping'),
        required: false,
        validation: false,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ]
  }
}
