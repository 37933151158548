/* -------------------------- Design imports start -------------------------- */
import {
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { Add, Delete, Logout } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useChatContext } from '../../utils/context'
import { log } from 'console'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  sx?: React.CSSProperties
}

/**
 * List all Channels
 *  - Admin has information about the sender
 */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ChannelList(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { sx = {} } = props

  const {
    newMessages,
    countMessages,
    newestMessage,
    channels,
    handleCreateChannelClick,
    handleChannelClick,
    selectedChannels,
    selectedChannelLeave,
    setSelectedChannelLeave,
    setSelectedChannels,
    setDeleteMultipleModal,
    activeUser,
    setLeaveChatDialogOpen,
  } = useChatContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['chat', 'common'])
  /* ----------------------------- Data states end ---------------------------- */



  // const sortedChannels = channels.sort((a, b) => {
  //   // Vergleichen Sie die Zeitstempel der neuesten Nachrichten
  //   return new Date(b.newestMessage?.createdAt).getTime() - new Date(a.newestMessage?.createdAt).getTime();
  // });




  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div style={sx}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6">{t('chat:content.label.chats')}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title={t('interaction.button.deleteChat')}>
            <IconButton
              id="deleteChatButton"
              disabled={selectedChannels.length === 0}
              onClick={() => setDeleteMultipleModal(true)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('interaction.button.createChat')}>
            <IconButton id="createChatButton" onClick={handleCreateChannelClick}>
              <Add />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <div
        style={{
          height: 'calc(100vh - 64px - 58px - 75px - 20px - 20px)',
          overflowY: 'auto',
          marginTop: '20px',
          marginRight: '20px',
        }}
      >
        <List
          sx={{
            width: '100%',
          }}
        >
          {channels.map(channel => (
            <ListItem
              id="channelListItem"
              key={channel.key}
              disablePadding
              sx={{
                width: '100%',
                bgcolor: 'transparent',
                borderRadius: '4px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                border: '1px solid #E0E0E0',
                marginBottom: '10px',
                padding: '10px',
              }}
              secondaryAction={
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      {countMessages[channel.self] !== undefined &&
                        countMessages[channel.self] > 0 && (
                          <Grid item>
                            <Chip
                              label={countMessages[channel.self]}
                              color="primary"
                              size="small"
                              sx={{ marginRight: '10px', marginTop: '8px', color:'whitesmoke' }}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {typeof channel.creator !== 'string' &&
                    channel.creator.self === activeUser?.self ? (
                      <Checkbox
                        id="channelCheckbox"
                        edge="end"
                        sx={{ marginRight: '10px' }}
                        checked={selectedChannels.includes(channel)}
                        onChange={() => {
                          if (selectedChannels.includes(channel)) {
                            setSelectedChannels(selectedChannels.filter(c => c !== channel))
                          } else {
                            setSelectedChannels([...selectedChannels, channel])
                          }
                        }}
                        inputProps={{ 'aria-labelledby': channel.self }}
                      />
                    ) : (
                      <IconButton
                        id="leaveChatButton"
                        size="small"
                        sx={{ marginRight: '10px' }}
                        onClick={() => {
                          setSelectedChannelLeave([channel])
                          setLeaveChatDialogOpen(true)
                        }}
                      >
                        <Logout />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              }
            >
              <ListItemButton
                id="channelListItemButton"
                onClick={() => handleChannelClick(channel)}
                sx={{ width: '100%', bgcolor: 'transparent' }}
              >
                <ListItemText
                  primary={`${t('chat:content.label.subject')}: ${channel.name}`}
                  secondary={
                    channel.members && (
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {`${t('chat:content.label.numberOfParticipants')}: ${channel.members.length}`}
                      </Typography>
                    )
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}
