/* ------------------------ Functional imports start ------------------------ */
import pino from 'pino'
import { getTimeString, parseDisableLoggingEnvVar } from './utils'
/* ------------------------- Functional imports end ------------------------- */
export default class LogTool {
  context: string
  pinoLogger: any
  enable: boolean

  /**
   * Create a new LogTool instance to write log-messages from a react component.
   * @param  config Log-Instance configuration object.
   * @param  config.context: Semantic context of the logger; e.g. the components name.
   * @param  config.enable: flag that enables/disables the LogTool-instance.
   * @param  config.logLevel: The lowest logging level that should be logged.
   */
  constructor({context, enable=true, logLevel='debug'}: {context: string, enable?: boolean, logLevel?: 'debug'|'info'|'warn'|'error'}) {
    this.context = context
    this.pinoLogger = pino({

      // lowest log level that is printed out
      level: logLevel,
      browser: {
        // disable logging for the entire app
        disabled: parseDisableLoggingEnvVar(process.env.REACT_APP_DISABLE_LOGGING),

        asObject: true,
        write: {
          error: (logObj: any) => {
            const date = new Date(logObj.time)
            const timeString = getTimeString(date)
            const prefix = timeString + ` || ${logObj.context} [${logObj.event}]:`
            console.error(prefix, ...logObj.content)
          },
          warn: (logObj: any) => {
            const date = new Date(logObj.time)
            const timeString = getTimeString(date)
            const prefix = timeString + ` || ${logObj.context} [${logObj.event}]:`
            console.warn(prefix, ...logObj.content)
          },
          info: (logObj: any) => {
            const date = new Date(logObj.time)
            const timeString = getTimeString(date)
            const prefix = timeString + ` || ${logObj.context} [${logObj.event}]:`
            console.info(prefix, ...logObj.content)
          },
          debug: (logObj: any) => {
            const date = new Date(logObj.time)
            const timeString = getTimeString(date)
            const prefix = timeString + ` || ${logObj.context} [${logObj.event}]:`
            console.debug(prefix, ...logObj.content)
          },
        }
      }
    })
    this.enable = enable
  }

  /**
   * Get the name of the function that called the log method.
   * @param callerDistance the number of stack trace entries the searched caller functions should be above the function calling getCallerName.
   * @returns name of function as string
   */
  getCallerName(callerDistance: number): string {
    // disable this function in production as it could cause security risks
    if(process.env.NODE_ENV === 'production') return ''

    try {
      throw new Error();
    } catch (e: any) {
      // try to find out which browser is beeing used
      if(e.stack.match(/(\w+)@(\w+):/g)) {
        // browser: Firefox
        // console.log("Browser: Firefox")
        const allMatches = e.stack.match(/(\w+)@(\w+):/g);
        // console.log("e ->", e, "stack ->", e.stack, "allMatches ->", allMatches);

        // in rare occasions allMatches[callerDistance] can be undefined
        // therefore use allMatches[callerDistance]?.match...
        const callerMatches = allMatches[callerDistance]?.match(/(\w+)@\w+:/)
        // since allMatches[callerDinstance] may be undefined, callerMatches may be undefined aswell
        if(!callerMatches) return "caller_not_found"
        // console.log("callerMatches ->", callerMatches)
        return callerMatches[1]
      }
      if(e.stack.match(/at ((async )?(\w+(\.\w+)*)( \(|\.tsx)|http)/g)) {
        // browser: Chrome, Edge, Opera, ... (Chromium)
        /* console.log("Browser: Chromium based") */
        const allMatches = e.stack.match(/at ((async )?(\w+(\.\w+)*)( \(|\.tsx)|http)/g);
        // console.log("e ->", e, "stack ->", e.stack, "allMatches ->", allMatches);

        // in rare occasions allMatches[callerDistance] can be undefined
        // therefore use allMatches[callerDistance]?.match...
        const callerMatches = allMatches[callerDistance]?.match(/at ((async )?(\w+(\.\w+)*)( \(|\.tsx)|http)/)
        // since allMatches[callerDinstance] may be undefined, callerMatches may be undefined aswell
        if(!callerMatches) return "caller_not_found"
        //console.log("e ->", e, "stack ->", e.stack, "allMatches ->", allMatches, "callerMatches ->", callerMatches);
        if(typeof callerMatches[3] === 'undefined' && callerMatches[0].includes('http')) {
            // caller function is a react function component -> GLOBAL
            return 'GLOBAL'
        } else if(callerMatches[3] && callerMatches[2] === 'async') {
          // caller function has an 'async' prefix -> caller name is in the next matching-group
          return callerMatches[3]
        } else {
          return callerMatches[3] // the regex-match group that holds the caller name
        }
      }
      return ''
    }
  }

  logToConsole(mode: 'debug' | 'info' | 'warn' | 'error', ...content: any) {
    const callerName = this.getCallerName(3)
    const event = callerName.match(new RegExp(this.context, 'i')) ? "GLOBAL" : callerName

    const pinoLogArguments = {
      context: this.context,
      event: event,
      content: content,
    }

    switch(mode) {
      case 'debug':
        this.pinoLogger.debug(pinoLogArguments)
        break;
      case 'info':
        this.pinoLogger.info(pinoLogArguments)
        break;
      case 'warn':
        this.pinoLogger.warn(pinoLogArguments)
        break;
      case 'error':
        this.pinoLogger.error(pinoLogArguments)
        break;
    }
  }

  /**
   * Print error messages to the console. Use this method for notifying developers that something went wrong or failed.
   * @param content anything
   * @returns
   */
  error(...content: any ) {
    this.enable && this.logToConsole("error", ...content)
  }

  /**
   * Print warning messages to the console. Use this method for notifying developers of unexpected behavior of functions or unexpected values of variables, ...
   * @param content anything
   * @returns
   */
  warn(...content: any ) {
    this.enable && this.logToConsole("warn", ...content)
  }

  /**
   * Print info messages to the console. Use this method for and only for describing the high-level program flow.
   * @param content anything
   * @returns
   */
  info(...content: any ) {
    this.enable && this.logToConsole("info", ...content)
  }

  /**
   * Print debugging information to the console. Use this method for any debugging purposes that are not covered by the error/warn/info log methods.
   * @param content anything
   * @returns
   */
  debug(...content: any ) {
    this.enable && this.logToConsole("debug", ...content)
  }
}

