/* -------------------------- Design imports start -------------------------- */
import {
  ButtonGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import ReplayIcon from '@mui/icons-material/Replay'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import LogTool from '../../logger/logTools'
import { useTranslation } from 'react-i18next'
import { formatBytes } from '../../utils/functions'
import { FileInfo } from '../../utils/types'
import moment from 'moment'
import { Delete, Download } from '@mui/icons-material'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  files: FileInfo[]
  noFilesInfo: string
  // set the flag below to false to indicate that the user is creating a new article. In that case there is
  // only one action in the file list: removing the file from the list to prevent it from beeing uploaded.
  // Set the flag to true to indicate that the user is editing an existing article. In that case there are
  // multiple actions available that have greater effect than just removing a file from the file list.
  enableEditMode: boolean
  onRemoveFile: (file: FileInfo) => void
  onReUploadFile: (file: FileInfo, url: string) => Promise<void>
  onDeleteFile: (file: FileInfo) => Promise<void>
  sx?: React.CSSProperties
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
/**
 * Render a list of files, showing their upload status and available actions.
 * @param props
 * @returns
 */
export default function InputFileList(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    files,
    noFilesInfo,
    enableEditMode,
    onRemoveFile,
    onReUploadFile,
    onDeleteFile,
    sx = {},
  } = props
  const log = new LogTool({ context: 'InputFileList', enable: true, logLevel: 'warn' })
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleDownloadFile = async (file: any) => {
    log.info('downloadFile', file)
    const link = document.createElement('a')
    link.href = file.document
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const renderUploadStatusIcon = (file: FileInfo) => {
    if (!file.uploadStatus) return null

    if (file.uploadStatus === 'loading') {
      return <CircularProgress size="1rem" />
    }
    if (file.uploadStatus === 'success') {
      return <CheckIcon color="success" fontSize="small" />
    }
    if (file.uploadStatus === 'uploadError') {
      return (
        <Tooltip arrow title={t('common:feedback.error.uploadSingleFile')}>
          <ErrorIcon color="error" fontSize="small" />
        </Tooltip>
      )
    }
  }

  const renderFileActions = (file: FileInfo) => {
    if (enableEditMode && file.uploadStatus !== 'loading') {
      // when the mode is changed from create to edit without re-rendering the parent component
      // the files still have the 'uploadStatus' property.
      if (file.uploadStatus === 'uploadError') {
        // enable the user to reupload the file if an error occured
        return (
          <IconButton onClick={() => onReUploadFile(file, file.uploadUrl as string)}>
            <ReplayIcon />
          </IconButton>
        )
      }
      return (
        <ButtonGroup>
          <IconButton id="downloadButton" onClick={() => handleDownloadFile(file)}>
            <Download />
          </IconButton>
          <IconButton id="deleteButton" onClick={() => onDeleteFile(file)}>
            <Delete />
          </IconButton>
        </ButtonGroup>
      )
    } else {
      // create mode actions
      return (
        <IconButton
          id="closeButton"
          disabled={file.uploadStatus === 'loading'}
          onClick={() => onRemoveFile(file)}
        >
          <Delete />
        </IconButton>
      )
    }
  }

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <div className="container" style={{ ...sx, margin: '5px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '1rem' }}></TableCell>
            <TableCell sx={{ maxWidth: '30%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {t('common:content.label.name')}
            </TableCell>
            <TableCell>{t('common:content.label.size')}</TableCell>
            <TableCell>{t('common:content.label.lastModified')}</TableCell>
            <TableCell sx={{ width: '10%' }}>{t('common:content.label.action')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.length === 0 ? (
            // there are no files for the current article yet.
            <TableRow>
              <TableCell colSpan={5} align="center" sx={{ padding: 3 }}>
                <Typography variant="body2">{noFilesInfo}</Typography>
              </TableCell>
            </TableRow>
          ) : (
            // there are files uploaded for the current article -> display them
            files.map((file, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  sx={{
                    pl: '16px',
                    pr: 0,
                    pb: '12px',
                    overflow: 'hidden',
                  }}
                >
                  {renderUploadStatusIcon(file)}
                </TableCell>
                <TableCell>{file.name}</TableCell>
                <TableCell>{formatBytes(file.size as number)}</TableCell>
                <TableCell>{moment(file.lastModified).format('DD.MM.YYYY')}</TableCell>
                <TableCell>{renderFileActions(file)}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
}
