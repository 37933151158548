/* -------------------------- Design imports start -------------------------- */
import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import Page from '../../components/layout/Page'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { handleAPICallV1 } from '../../utils/functions'
import { HTTPMethod } from '../../utils/types'
import useMobileService from '../../hooks/useMobileService'
import LogTool from '../../logger/logTools'
import logo from '../../assets/logo_black.svg'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

enum VerifyEvent {
  invite = 'invite',
  signup = 'signup',
  resetPassword = 'reset_password',
  changeEmail = 'changeEmail',
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function VerifyPage() {
  /* -------------------------- Non state data start -------------------------- */
  const location: any = useLocation()
  const history = useHistory()
  // get token from url after ?token=
  const token = new URLSearchParams(location.search).get('token')
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  const { t } = useTranslation(['common'])
  const log = new LogTool({context: 'VerifyPage', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [notSamePassword, setNotSamePassword] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const isMobile = useMobileService()
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [repeatPassword, setRepeatPassword] = useState<string | undefined>(undefined)
  const [verifyEvent, setVerifyEvent] = useState<VerifyEvent | undefined>(undefined)
  const [verifyToken, setVerifyToken] = useState<string | undefined>(undefined)
  const { event } = useParams<{ event: VerifyEvent }>()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    token && setVerifyToken(token)
    event && setVerifyEvent(event)
    if (event === VerifyEvent.resetPassword) {
      verifyPasswordToken(undefined)
    }
    if (event === VerifyEvent.changeEmail || event === VerifyEvent.signup) {
      verifyEmailToken(undefined)
    }
  }, [])
  /* ------------------------------- Effects end ------------------------------ */

  log.debug('verifyEvent', verifyEvent)
  log.debug('verifyToken', verifyToken)

  /* ------------------------- Utility functions start ------------------------ */
  async function verifyEmailToken(password: string | undefined) {
    setLoading(true)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'auth/email/request/email_verification/',
      {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      password ? { token: token, password: password } : { token: token }
    )
    if (error) {
      setError(true)
      setLoading(false)
      return
    }
    if (response) {
      history.push('/signIn', password ? { inviteSuccess: true } : { orgSignUpSuccess: true })
    }
  }

  async function verifyPasswordToken(password: string | undefined) {
    setLoading(true)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      password ? 'auth/email/request/password_reset/confirm/' : 'auth/email/request/check_token/',
      {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      password ? { token: token, password: password } : { token: token }
    )
    if (error) {
      setError(true)
      setLoading(false)
      return
    }
    if (response) {
      setLoading(false)
      password && history.push('/signIn', { passwordResetSuccess: true })
    }
  }

  /*async function resendEmailLink() {
    setLoading(true)
    log.debug(event)
    setLoading(false)
  }*/
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const passwordIsValid = (password: string) => {
    return password && passwordRegex.test(password)
  }

  const passwordsAreEqual = () => {
    return password === repeatPassword
  }

  function handleSubmit(e: any, type: string = 'email') {
    e.preventDefault()
    if (password && passwordIsValid(password)) {
      if(passwordsAreEqual()) {
        type === 'email' ? verifyEmailToken(password) : verifyPasswordToken(password)
      } else {
        setNotSamePassword(true)
      }
    } else {
      setInvalidPassword(true)
    }
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const VerifyEmailOrgAdmin = <></>

  const VerifyEmailUser = (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          {t('content.auth.signUp')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {t('content.auth.choosePasswordText')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password"
          type="password"
          label={t('content.auth.password')}
          variant="outlined"
          fullWidth
          helperText={t('common:content.loginForm.passwordHelperText')}
          error={invalidPassword}
          onChange={e => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="repeatPassword"
          type="password"
          label={t('content.loginForm.repeatPassword')}
          variant="outlined"
          fullWidth
          helperText={t('common:content.loginForm.repeatPasswordPlaceholder')}
          error={notSamePassword}
          onChange={e => setRepeatPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          {t('content.auth.signUp')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="info"
          fullWidth
          onClick={() => history.push('/signIn')}
          style={{textTransform: 'none'}}
        >
          {t('content.auth.backToSignIn')}
        </Button>
      </Grid>
    </>
  )

  const VerifyNewEmailUser = <div>Verify New User Email</div>

  const VerifyNewPasswordUser = (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          {t('content.auth.changePassword')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {t('content.auth.choosePasswordText')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="password"
          type="password"
          label={t('content.auth.password')}
          variant="outlined"
          fullWidth
          helperText={t('common:content.loginForm.passwordHelperText')}
          error={invalidPassword}
          onChange={e => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={e => handleSubmit(e, 'password')}
          style={{color:'whitesmoke', textTransform: 'none'}}
        >
          {t('content.auth.changePassword')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="info"
          fullWidth
          onClick={() => history.push('/signIn')}
          style={{textTransform: 'none'}}
        >
          {t('content.auth.backToSignIn')}
        </Button>
      </Grid>
    </>
  )

  const VerificationError = (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          {t('content.auth.verificationError')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {t('content.auth.verificationErrorText')}
        </Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => resendEmailLink()}
        >
          {t('auth.resendEmailLink')}
        </Button>
      </Grid> */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="info"
          fullWidth
          onClick={() => history.push('/signIn')}
          style={{textTransform: 'none'}}
        >
          {t('content.auth.backToSignIn')}
        </Button>
      </Grid>
    </>
  )

  // return 404 page if event is not valid
  if (!Object.values(VerifyEvent).includes(event)) {
    return <Redirect to="/404" />
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Grid
          container
          spacing={2}
          width={isMobile ? '100%' : '60%'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12} display="flex" justifyContent="center">
            <img
              src={logo}
              alt="logo"
              style={{ width: '200px', height: 'auto' }}
            />
          </Grid>
          {loading && (
            <Grid item xs={12} md={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
          {event === VerifyEvent.signup && !error && VerifyEmailOrgAdmin}
          {event === VerifyEvent.invite && !error && VerifyEmailUser}
          {event === VerifyEvent.changeEmail && !error && VerifyNewEmailUser}
          {event === VerifyEvent.resetPassword && !error && VerifyNewPasswordUser}
          {error && VerificationError}
        </Grid>
      </Box>
    </Page>
  )
}
