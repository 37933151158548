/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedPropertyList,
    APIPaginatedPropertyListFromJSON,
    APIPaginatedPropertyListToJSON,
    APIPatchedPropertyRequest,
    APIPatchedPropertyRequestFromJSON,
    APIPatchedPropertyRequestToJSON,
    APIProperty,
    APIPropertyFromJSON,
    APIPropertyToJSON,
    APIPropertyRequest,
    APIPropertyRequestFromJSON,
    APIPropertyRequestToJSON,
} from '../models';

export interface V1ItemsArticlesPropertiesCreateRequest {
    articlePk: string;
    aPIPropertyRequest: APIPropertyRequest;
}

export interface V1ItemsArticlesPropertiesDestroyRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesPropertiesListRequest {
    articlePk: string;
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesPropertiesPartialUpdateRequest {
    articlePk: string;
    id: number;
    aPIPatchedPropertyRequest?: APIPatchedPropertyRequest;
}

export interface V1ItemsArticlesPropertiesRetrieveRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesPropertiesUpdateRequest {
    articlePk: string;
    id: number;
    aPIPropertyRequest: APIPropertyRequest;
}

export interface V1ItemsPropertiesCreateRequest {
    aPIPropertyRequest: APIPropertyRequest;
}

export interface V1ItemsPropertiesDestroyRequest {
    id: number;
}

export interface V1ItemsPropertiesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsPropertiesPartialUpdateRequest {
    id: number;
    aPIPatchedPropertyRequest?: APIPatchedPropertyRequest;
}

export interface V1ItemsPropertiesRetrieveRequest {
    id: number;
}

export interface V1ItemsPropertiesUpdateRequest {
    id: number;
    aPIPropertyRequest: APIPropertyRequest;
}

/**
 * 
 */
export class ItemspropertiesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesCreateRaw(requestParameters: V1ItemsArticlesPropertiesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesCreate.');
        }

        if (requestParameters.aPIPropertyRequest === null || requestParameters.aPIPropertyRequest === undefined) {
            throw new runtime.RequiredError('aPIPropertyRequest','Required parameter requestParameters.aPIPropertyRequest was null or undefined when calling v1ItemsArticlesPropertiesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIPropertyRequestToJSON(requestParameters.aPIPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesCreate(requestParameters: V1ItemsArticlesPropertiesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesDestroyRaw(requestParameters: V1ItemsArticlesPropertiesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesDestroy(requestParameters: V1ItemsArticlesPropertiesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesPropertiesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesListRaw(requestParameters: V1ItemsArticlesPropertiesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedPropertyList>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedPropertyListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesList(requestParameters: V1ItemsArticlesPropertiesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedPropertyList> {
        const response = await this.v1ItemsArticlesPropertiesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesPartialUpdateRaw(requestParameters: V1ItemsArticlesPropertiesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedPropertyRequestToJSON(requestParameters.aPIPatchedPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesPartialUpdate(requestParameters: V1ItemsArticlesPropertiesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesRetrieveRaw(requestParameters: V1ItemsArticlesPropertiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesRetrieve(requestParameters: V1ItemsArticlesPropertiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesUpdateRaw(requestParameters: V1ItemsArticlesPropertiesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesUpdate.');
        }

        if (requestParameters.aPIPropertyRequest === null || requestParameters.aPIPropertyRequest === undefined) {
            throw new runtime.RequiredError('aPIPropertyRequest','Required parameter requestParameters.aPIPropertyRequest was null or undefined when calling v1ItemsArticlesPropertiesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIPropertyRequestToJSON(requestParameters.aPIPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesUpdate(requestParameters: V1ItemsArticlesPropertiesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesCreateRaw(requestParameters: V1ItemsPropertiesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.aPIPropertyRequest === null || requestParameters.aPIPropertyRequest === undefined) {
            throw new runtime.RequiredError('aPIPropertyRequest','Required parameter requestParameters.aPIPropertyRequest was null or undefined when calling v1ItemsPropertiesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIPropertyRequestToJSON(requestParameters.aPIPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesCreate(requestParameters: V1ItemsPropertiesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesDestroyRaw(requestParameters: V1ItemsPropertiesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesDestroy(requestParameters: V1ItemsPropertiesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsPropertiesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesListRaw(requestParameters: V1ItemsPropertiesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedPropertyList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedPropertyListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesList(requestParameters: V1ItemsPropertiesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedPropertyList> {
        const response = await this.v1ItemsPropertiesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesPartialUpdateRaw(requestParameters: V1ItemsPropertiesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedPropertyRequestToJSON(requestParameters.aPIPatchedPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesPartialUpdate(requestParameters: V1ItemsPropertiesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesRetrieveRaw(requestParameters: V1ItemsPropertiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesRetrieve(requestParameters: V1ItemsPropertiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesUpdateRaw(requestParameters: V1ItemsPropertiesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesUpdate.');
        }

        if (requestParameters.aPIPropertyRequest === null || requestParameters.aPIPropertyRequest === undefined) {
            throw new runtime.RequiredError('aPIPropertyRequest','Required parameter requestParameters.aPIPropertyRequest was null or undefined when calling v1ItemsPropertiesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIPropertyRequestToJSON(requestParameters.aPIPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesUpdate(requestParameters: V1ItemsPropertiesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
