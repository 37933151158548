/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIOffer,
    APIOfferFromJSON,
    APIOfferToJSON,
    APIOfferRequest,
    APIOfferRequestFromJSON,
    APIOfferRequestToJSON,
    APIPaginatedOfferList,
    APIPaginatedOfferListFromJSON,
    APIPaginatedOfferListToJSON,
    APIPatchedOfferRequest,
    APIPatchedOfferRequestFromJSON,
    APIPatchedOfferRequestToJSON,
} from '../models';

export interface V1RequestOffersCreateRequest {
    aPIOfferRequest: APIOfferRequest;
}

export interface V1RequestOffersDestroyRequest {
    id: number;
}

export interface V1RequestOffersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1RequestOffersPartialUpdateRequest {
    id: number;
    aPIPatchedOfferRequest?: APIPatchedOfferRequest;
}

export interface V1RequestOffersRetrieveRequest {
    id: number;
}

export interface V1RequestOffersUpdateRequest {
    id: number;
    aPIOfferRequest: APIOfferRequest;
}

/**
 * 
 */
export class RequestofferApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersCreateRaw(requestParameters: V1RequestOffersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.aPIOfferRequest === null || requestParameters.aPIOfferRequest === undefined) {
            throw new runtime.RequiredError('aPIOfferRequest','Required parameter requestParameters.aPIOfferRequest was null or undefined when calling v1RequestOffersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOfferRequestToJSON(requestParameters.aPIOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersCreate(requestParameters: V1RequestOffersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersDestroyRaw(requestParameters: V1RequestOffersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersDestroy(requestParameters: V1RequestOffersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1RequestOffersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersListRaw(requestParameters: V1RequestOffersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedOfferList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedOfferListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersList(requestParameters: V1RequestOffersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedOfferList> {
        const response = await this.v1RequestOffersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersPartialUpdateRaw(requestParameters: V1RequestOffersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedOfferRequestToJSON(requestParameters.aPIPatchedOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersPartialUpdate(requestParameters: V1RequestOffersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersRetrieveRaw(requestParameters: V1RequestOffersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersRetrieve(requestParameters: V1RequestOffersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersUpdateRaw(requestParameters: V1RequestOffersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersUpdate.');
        }

        if (requestParameters.aPIOfferRequest === null || requestParameters.aPIOfferRequest === undefined) {
            throw new runtime.RequiredError('aPIOfferRequest','Required parameter requestParameters.aPIOfferRequest was null or undefined when calling v1RequestOffersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIOfferRequestToJSON(requestParameters.aPIOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersUpdate(requestParameters: V1RequestOffersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
