/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIContactPreferredContactMethod,
    APIContactPreferredContactMethodFromJSON,
    APIContactPreferredContactMethodFromJSONTyped,
    APIContactPreferredContactMethodToJSON,
} from './APIContactPreferredContactMethod';

/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIRichContact
 */
export interface APIRichContact {
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    readonly self: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIRichContact
     */
    addresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    readonly suppliers: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    readonly customers: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRichContact
     */
    comment?: string;
    /**
     * 
     * @type {APIContactPreferredContactMethod}
     * @memberof APIRichContact
     */
    preferredContactMethod?: APIContactPreferredContactMethod | null;
}

export function APIRichContactFromJSON(json: any): APIRichContact {
    return APIRichContactFromJSONTyped(json, false);
}

export function APIRichContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIRichContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'addresses': !exists(json, 'addresses') ? undefined : json['addresses'],
        'suppliers': json['suppliers'],
        'customers': json['customers'],
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'preferredContactMethod': !exists(json, 'preferred_contact_method') ? undefined : APIContactPreferredContactMethodFromJSON(json['preferred_contact_method']),
    };
}

export function APIRichContactToJSON(value?: APIRichContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addresses': value.addresses,
        'email': value.email,
        'phone': value.phone,
        'mobile': value.mobile,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'language': value.language,
        'comment': value.comment,
        'preferred_contact_method': APIContactPreferredContactMethodToJSON(value.preferredContactMethod),
    };
}

