/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIArticle,
    APIArticleFromJSON,
    APIArticleToJSON,
    APIArticleRequest,
    APIArticleRequestFromJSON,
    APIArticleRequestToJSON,
    APIPaginatedArticleList,
    APIPaginatedArticleListFromJSON,
    APIPaginatedArticleListToJSON,
    APIPatchedArticleRequest,
    APIPatchedArticleRequestFromJSON,
    APIPatchedArticleRequestToJSON,
} from '../models';

export interface V1ItemsBomBasesCreateRequest {
    aPIArticleRequest: APIArticleRequest;
}

export interface V1ItemsBomBasesDestroyRequest {
    id: number;
}

export interface V1ItemsBomBasesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsBomBasesPartialUpdateRequest {
    id: number;
    aPIPatchedArticleRequest?: APIPatchedArticleRequest;
}

export interface V1ItemsBomBasesRetrieveRequest {
    id: number;
}

export interface V1ItemsBomBasesUpdateRequest {
    id: number;
    aPIArticleRequest: APIArticleRequest;
}

/**
 * 
 */
export class ItemsbomRootsApi extends runtime.BaseAPI {

    /**
     * Create a bom base by setting the is_bom_base flag on the provided article to true.
     */
    async v1ItemsBomBasesCreateRaw(requestParameters: V1ItemsBomBasesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.aPIArticleRequest === null || requestParameters.aPIArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIArticleRequest','Required parameter requestParameters.aPIArticleRequest was null or undefined when calling v1ItemsBomBasesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleRequestToJSON(requestParameters.aPIArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Create a bom base by setting the is_bom_base flag on the provided article to true.
     */
    async v1ItemsBomBasesCreate(requestParameters: V1ItemsBomBasesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a bom base by setting the is_bom_base flag on the provided article to false and deleting all bom edges that have that article set as parent.
     */
    async v1ItemsBomBasesDestroyRaw(requestParameters: V1ItemsBomBasesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a bom base by setting the is_bom_base flag on the provided article to false and deleting all bom edges that have that article set as parent.
     */
    async v1ItemsBomBasesDestroy(requestParameters: V1ItemsBomBasesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsBomBasesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesListRaw(requestParameters: V1ItemsBomBasesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedArticleList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedArticleListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesList(requestParameters: V1ItemsBomBasesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedArticleList> {
        const response = await this.v1ItemsBomBasesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesPartialUpdateRaw(requestParameters: V1ItemsBomBasesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedArticleRequestToJSON(requestParameters.aPIPatchedArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesPartialUpdate(requestParameters: V1ItemsBomBasesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesRetrieveRaw(requestParameters: V1ItemsBomBasesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesRetrieve(requestParameters: V1ItemsBomBasesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesUpdateRaw(requestParameters: V1ItemsBomBasesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesUpdate.');
        }

        if (requestParameters.aPIArticleRequest === null || requestParameters.aPIArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIArticleRequest','Required parameter requestParameters.aPIArticleRequest was null or undefined when calling v1ItemsBomBasesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleRequestToJSON(requestParameters.aPIArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesUpdate(requestParameters: V1ItemsBomBasesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
