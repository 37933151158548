/* -------------------------- Design imports start -------------------------- */

/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import Table, { ColumnsType } from 'antd/es/table'
import GetConstant from '../utils/constants'
import FilterComponent, { FilterOptions } from '../../../components/widgets/FilterMenu'
import {
  deleteBills,
  fetchBill,
  fetchBills,
  inputToAPIBillJSON,
} from '../utils/functions'
import { Button, Chip, CircularProgress, Grid } from '@mui/material'
import LoadingSkeleton from '../../../components/widgets/LoadingSkeleton'
import { Space } from 'antd'
import { DeleteOutlined, EditOutlined, FilterAltOutlined } from '@mui/icons-material'
import ColumnSelector from '../../../components/widgets/ColumnSelector'
import { handleAPICallV1, isPrivileged } from '../../../utils/functions'
import BillStaffDrawer from './BillStaffDrawer'
import { useCountContext, useUserContext } from '../../../utils/context'
import DeleteModal from '../../../components/widgets/DeleteModal'
import FilesDrawer from '../../../components/FilesDrawer'
import { toast } from 'react-toastify'
import { HTTPMethod } from '../../../utils/types'
import { Bill } from '../utils/types'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  openInfoPanel: boolean
  setOpenInfoPanel: (value: boolean) => void
  selectedBill: /* Bill | any */ any
  setSelectedBill: (value: /* Bill | any */ any) => void
  openFilesDrawer: boolean
  setOpenFilesDrawer: (value: boolean) => void
}
/* -------------------------------------------------------------------------- */
/*                                Start Component                             */
/* -------------------------------------------------------------------------- */
export default function BillOverviewPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    openInfoPanel,
    setOpenInfoPanel,
    selectedBill,
    setSelectedBill,
    openFilesDrawer,
    setOpenFilesDrawer,
  } = props
  const { t } = useTranslation()
  const log = new LogTool({ context: 'BillOverviewPage', enable: true, logLevel: 'warn' })
  const columnsData: ColumnsType = GetConstant({ name: 'billTableColumns' }) as ColumnsType
  const { user } = useUserContext()
  const { initialBills, setTabValue } = useCountContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState<boolean>(false)
  const [billDrawerOpen, setBillDrawerOpen] = useState<boolean>(false)
  const [billStaffDrawerOpen, setBillStaffDrawerOpen] = useState<boolean>(false)
  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

/* ---------------------------- Data States start --------------------------- */
const dataSource = useRef<any[]>([])
const [myFilterOptions, setMyFilterOptions] = useState<FilterOptions>({})
const [filteredData, setFilteredData] = useState<any[]>([])
const [defaultColumns] = useState<string[]>([
  "read",
  "key",
  "contract",
  "createdAt",
  "dueDate",
  "amount",
  "paid",
  "editors"
])
const [columns] = useState<ColumnsType>(columnsData)
const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
const [bills, setBills] = useState<any[]>([])
const [totalBills, setTotalBills] = useState<number>(0)
const [selectedBills, setSelectedBills] = useState<any[]>([])
const [billFormInput, setBillFormInput] = useState<any>({})
const [billStaffFormInput, setBillStaffFormInput] = useState<any>({})
const [newBills, setNewBills] = useState<Bill[]>(initialBills)
/* ----------------------------- Data States end ---------------------------- */

  /* ----------------------------- Effects start ------------------------------ */
  useEffect(() => {
    setLoading(true)
    fetchBills({
      onSuccess: (bills: any, count: number | undefined) => {
        dataSource.current = [...dataSource.current, ...bills]
        count && setTotalBills(count)
        setFilteredData(dataSource.current)
        setLoading(false)
      },
      onError: (error: any) => {
        log.error('Error fetching bills', error)
        setLoading(false)
      },
      parameter: ['expand=contract,editors,files'],
    })
  }, [])
  /* ------------------------------ Effects end ------------------------------- */

  log.debug('dataSource.current', dataSource.current)

  /* -------------------------- Utility functions start ----------------------- */

  /**
   * Refreshes the data source by fetching the offers from the API
   */
  const refreshDataSource = (): void => {
    dataSource.current = []
    fetchBills({
      onSuccess: (bills: any[], count: any) => {
        dataSource.current = [...dataSource.current, ...bills]
        setTotalBills(count)
        setFilteredData(dataSource.current)
      },
      onError: (error: any) => {
        log.error('Error fetching Bills -> ', error)
      },
      parameter: ['expand=contract,editors'],
    })
  }

  /* useEffect(() => {
    // Funktion zum Aktualisieren der Daten, wenn der TabValue auf die BillOverviewPage gesetzt wird
    const handleTabChange: (newValue: number) => number = (newValue: number) => {
      if (newValue === 4) {
        // Angenommen, der Index für die BillOverviewPage ist 4
        refreshDataSource()
      }
      return newValue
    }

    // Registrieren Sie die handleTabChange-Funktion als Callback für die setTabValue-Prop
    if (setTabValue) {
      setTabValue(handleTabChange as any)
    }
  }, [setTabValue, refreshDataSource]) */

  async function handleUpdateBill(input: any) {
    log.debug('HandleUpdateBill', input)
    const json = inputToAPIBillJSON(input)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      input.self,
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.info('Success updating bill', response)
      fetchBill(
        response.self,
        (bill: any) => {
          dataSource.current = dataSource.current.map(d => (d.key === bill.key ? bill : d))
          setFilteredData(dataSource.current)
          toast.success(t('contract:feedback.success.updateBill'))
          refreshDataSource()
        },
        (error: any) => {
          log.error('Error fetching updated bill', error)
          toast.error(t('contract:feedback.error.updateBill'))
        },
        ['expand=contract,editors']
      )
      setSelectedRowKeys([])
      setSelectedBills([])
      setSelectedBill(undefined)
      setOpenInfoPanel(false)
      return response.files
    } else {
      log.error('Error updating bill', error)
      toast.error(t('request:feedback.error.updateBill'))
      setSelectedRowKeys([])
      setSelectedBills([])
      setSelectedBill(undefined)
      setOpenInfoPanel(false)
    }
  }

  async function handleDeleteBills() {
    deleteBills(
      selectedBills,
      () => {
        toast.success(t('contract:feedback.success.deleteBills'))
        setSelectedRowKeys([])
        setSelectedBills([])
        setFilteredData(prev => prev.filter(d => !selectedBills.includes(d)))
        setOpenDelete(false)
      },
      (error: any) => {
        toast.error(t('contract:feedback.error.deleteBills'))
        log.error('Error deleting bills', error)
        setOpenDelete(false)
      }
    )
  }

  const filterData = (data: any[], filterOptions: { [x: string]: any }) => {
    // TODO: Write filterData function for Bills
    if (!data || !Array.isArray(data)) {
      return [] // Rückgabe eines leeren Arrays, wenn die Daten ungültig sind
    }

    return data.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // Kein Filter angewendet
        if (key === 'name') {
          return filterValues.some((value: string) =>
            d.name.toLowerCase().includes(value.toLowerCase())
          )
        } else if (key === 'quantity') {
          return filterValues.some((value: string) => parseInt(d.in_storage) >= parseInt(value))
        } else {
          const cellValue = d[key]
          return filterValues.some((value: string) =>
            cellValue?.toString().toLowerCase().includes(value.toLowerCase())
          )
        }
      })
    })
  }
  /* --------------------------- Utility functions end ------------------------ */

  /* ------------------------ Callback functions start ------------------------ */
  const handleApplyFilter = (filterOptions: Record<string, any>) => {
    setMyFilterOptions(filterOptions)

    const filteredProducts = dataSource.current.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // no filter applied

        const cellValue = d[key as keyof any]
        if (cellValue === null) return null // Ignore null values
        if (typeof cellValue === 'string') {
          // String-based filtering
          if (typeof cellValue === 'string') {
            return filterValues.some((value: string) =>
              cellValue.toLowerCase().includes(value.toLowerCase())
            )
          }
        } else if (typeof cellValue === 'number') {
          // Number-based filtering
          const cellValueAsNumber =
            typeof cellValue === 'string' ? parseFloat(cellValue) : cellValue
          return filterValues.some((value: number) => cellValueAsNumber == value)
        }

        return true // Ignore other data types or no match
      })
    })

    setFilteredData(filteredProducts)
    setFilterVisible(false)
  }

  const handleColumnChange = (checkedColumns: string[]) => {
    setVisibleColumns(checkedColumns)
  }

  const handleCancelFilter = () => {
    setFilterVisible(false)
  }

  const handleReset = () => {
    setMyFilterOptions({})
    setFilteredData(dataSource.current)
  }

  const handleShowFilter = () => {
    setFilterVisible(true)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const billTableColumns: any = GetConstant({ name: 'billTableColumns', newBills: newBills })
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns)
  const filteredColumns = billTableColumns.filter((column: any) => {
    return visibleColumns.includes(column.key?.toString() ?? '')
  })

  const chips = Object.entries(myFilterOptions).map(([key, values]) => {
    if (!values.length) return <></>
    return (
      <Chip
        key={key}
        label={`${t('common:content.label.' + key)}: ${values}`}
        onDelete={() => {
          setMyFilterOptions(prev => {
            const updatedOptions = { ...prev, [key]: [] }
            setFilteredData(filterData(dataSource.current, updatedOptions))
            return updatedOptions
          })
        }}
      />
    )
  })

  const buttonContainerStyle = {
    marginLeft: openInfoPanel ? '-270px' : '0',
    transition: 'margin-left 0.3s ease-in-out',
  }



  if (loading) {
    return <LoadingSkeleton pagetitle="" />
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                              Render Component                              */
/* -------------------------------------------------------------------------- */
return (
  <Grid container>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {chips}
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="flex-end" style={buttonContainerStyle}>
        {isPrivileged(user, "STAFF") && (
          <Grid item>
            <Space>
              <Button
                id="editBillButton"
                size="small"
                variant="outlined"
                startIcon={<EditOutlined />}
                style={{ textTransform: 'none' }}
                onClick={() => {
                  if(isPrivileged(user, "STAFF")) {
                    setBillStaffFormInput(selectedBills[0])
                    setBillStaffDrawerOpen(true)
                  } else {
                    setBillFormInput(selectedBills[0])
                    setBillDrawerOpen(true)
                  }
                }}
                disabled={selectedBills.length !== 1}
              >
                {t('contract:interaction.button.editBill')}
              </Button>
            </Space>
          </Grid>
        )}
        {isPrivileged(user, "STAFF") && (
          <Grid item>
            <Space>
              <Button
                id="deleteBillButton"
                size="small"
                variant="outlined"
                style={{ textTransform: 'none' }}
                startIcon={<DeleteOutlined />}
                onClick={() => setOpenDelete(true)}
                disabled={selectedBills.length === 0}
              >
                {t('contract:interaction.button.deleteBills')}
              </Button>
            </Space>
          </Grid>
        )}
        <Grid item>
          <Space>
            <Button
              id="showFilterButton"
              size="small"
              variant="outlined"
              style={{ textTransform: 'none' }}
              startIcon={<FilterAltOutlined />}
              onClick={handleShowFilter}
            >
              {t('common:content.label.filter')}
            </Button>
          </Space>
        </Grid>
        <Grid item>
          <ColumnSelector
            columns={columns as Array<{key: string, title: string}>}
            defaultColumns={defaultColumns}
            onChangeColumns={handleColumnChange}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Table
        id="billTable"
        columns={filteredColumns}
        dataSource={filteredData}
        pagination={{
          total: totalBills,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        locale={{
          emptyText: bills.length === 0 ? t('common:content.label.noData') : <CircularProgress />,
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedBills(selectedRows)
          }
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setSelectedBill(record as any)
              const newBill = newBills.find(bill => bill.key === record.key)
              if(newBill && !newBill.read) {
                setNewBills(prev => prev.map(bill => {
                  if(bill.key === record.key) {
                    return {...bill, read: true}
                  }
                  return bill
                }))
              }
              if(selectedRowKeys.includes(record.key as React.Key)) {
                setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key))
                setSelectedBills(selectedBills.filter(bill => bill.key !== record.key))
                setSelectedBill(undefined)
                setOpenInfoPanel(false)
              } else {
                setSelectedRowKeys([...selectedRowKeys, record.key as React.Key])
                setSelectedBills([...selectedBills, record])
                setSelectedBill(record as any)
                setOpenInfoPanel(true)
              }
            }
          }
        }}
      />
      {filterVisible && (
        <FilterComponent
          columns={billTableColumns}
          onApplyFilter={handleApplyFilter}
          onCancelFilter={handleCancelFilter}
          onResetFilter={handleReset}
          filterOptions={myFilterOptions}
        />
      )}
      {isPrivileged(user, "STAFF") && (
        <BillStaffDrawer
          open={billStaffDrawerOpen}
          setOpen={setBillStaffDrawerOpen}
          formState={{
            state: billStaffFormInput,
            setState: setBillStaffFormInput
          }}
          onEditConfirm={handleUpdateBill}
          enableEditMode={selectedBills.length === 1}
        />
      )}
    </Grid>
    <DeleteModal
      open={openDelete}
      setOpen={setOpenDelete}
      onDelete={handleDeleteBills}
      name={t('contract:content.label.bills')}
    />
    <FilesDrawer
      open={openFilesDrawer}
      setOpen={setOpenFilesDrawer}
      onClose={() => {}}
      files={selectedBill?.files}
      title={t('contract:content.label.bills') as string}
    />
  </Grid>
)
}
