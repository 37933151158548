/* -------------------------- Design imports start -------------------------- */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@mui/styles'
import {  Modal, Theme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import useMobileService from '../../hooks/useMobileService'
import Button from '../Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  name: string
  open: boolean
  setOpen: (open: boolean) => void
  onDelete: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
)

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */

export default function DeleteModal(props: Props) {
  /* ---------------------------- Flag states start --------------------------- */
  const [actionIsLoading, setActionIsLoading] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render
  const { t } = useTranslation()
  const isMobile = useMobileService()
  let size
  isMobile
    ? (size = { minHeight: '27vh', width: '95vw' })
    : (size = { minHeight: '210px', width: '500px' })

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    // reset loading state each time the modal is closed/opend
    setActionIsLoading(false)
  }, [props.open])
  /* ------------------------------- Effects end ------------------------------ */

  const handleOnDelete = () => {
    setActionIsLoading(true)
    props.onDelete()
  }

  const body = (
    <div
      className={classes.paper}
      style={{
        borderRadius: '5px',
        display: 'inline-block',
        position: 'fixed',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        width: size.width,
        minHeight: size.minHeight,
        margin: 'auto',
      }}
    >
      <h2 id="simple-modal-title">{t('common:content.label.areYouSure')}</h2>
      <div style={{ marginTop: '20px', marginBottom: '25px' }}>
        {t('common:content.label.deleteText', { name: props.name })}
      </div>
      <Button
        id="cancelButton"
        style={{ float: 'left' }}
        color="inherit"
        variant="outlined"
        disabled={actionIsLoading}
        onClick={() => props.setOpen(false)}
      >
        {t('common:interaction.button.cancel')}
      </Button>
      <LoadingButton
        id="deleteButtonModal"
        style={{ float: 'right', color: 'whitesmoke', textTransform: 'none' }}
        color="primary"
        variant="contained"
        loading={actionIsLoading}
        loadingPosition="start"
        startIcon={<DeleteForeverIcon />}
        onClick={handleOnDelete}
      >
        {t('interaction.button.delete')}
      </LoadingButton>
    </div>
  )

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="delete-modal"
        aria-describedby="modal-for-final-delete"
      >
        {body}
      </Modal>
    </div>
  )
}
