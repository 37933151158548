/* -------------------------- Design imports start -------------------------- */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme
} from "@mui/material"
import MultilevelDrawer from "../../components/layout/MultilevelDrawer"
import { TipsAndUpdatesRounded, WarningRounded, CheckBoxRounded, ExpandMoreRounded, AddRounded, DeleteRounded } from "@mui/icons-material"
import NumberInputWithUnit from "../../components/inputs/NumberInputWithUnit"
import { LoadingButton } from "@mui/lab"
import { toast } from "react-toastify"
import InteractiveTextDisplay from "../../components/widgets/InteractiveTextDisplay"
import FormEditorDrawer from "../../components/widgets/FormEditorDrawer"
import SearchSelectInput from "../../components/inputs/SearchSelectInput"
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import LogTool from "../../logger/logTools"
import { useTranslation } from "react-i18next"
import { Article, FeedbackForm, Sharepoint } from "../Item/utils/types"
import { getPKfromSelf, handleAPICallV1, fetchRichContacts, formInputToAPIContactJSON, createContact, getContactRepresentation, loadArticleData, loadAllArticleData } from "../../utils/functions"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { Contact, HTTPMethod, RichContact, SelectOption } from "../../utils/types"
import { Form, FormFieldType } from "../Form"
import { createArticle, createFeedbackForm, createSharepoint, fetchArticles, fetchFeedbackForms, getArticleRepresentation, inputToAPIArticleJSON, inputToAPIFeedbackFormJSON } from "../Item/utils/functions"
import InfoField from "../../components/inputs/InfoField"
import { useHistory } from "react-router-dom"
import { Supplier } from "../Supplier/utils/types"
import { Customer } from "../Customer/utils/types"
import ContactDrawer from "../Customer/ContactDrawer"
import FieldLabel from "../../components/widgets/FieldLabel"
import Button from "../../components/Button"
import { GuestRequest } from "./utils/types"
import ArticleDrawer from "../Item/ArticleDrawer"
import FilesDrawer from "../../components/FilesDrawer"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  selectedGuestRequest: GuestRequest
  onClose?: () => void
  onConfirm?: (input: any) => Promise<Sharepoint | "ERROR">
}

type AutomaticShareFormInput = {
  [inputKey: string]: {contact: SelectOption, customInvitationMessage: string}
}



/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function CreateGuestRequestSharepointDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    selectedGuestRequest,
    onClose = () => null,
    onConfirm = () => null,
  } = props
  const log = new LogTool({context: "CreateGuestRequestSharepointDrawer", enable: true, logLevel: 'warn'})
  const { t } = useTranslation()
  const history = useHistory()
  const securePasswordGenerator = require('secure-random-password')


  const defaultShareDuration = "20"

  const customTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#16a9e4',
            },
            '&.Mui-completed': {
              color: '#16a9e4',
            },
          },
          text: {
            color: 'white', // Set the text color to white for all steps
          },
        },
      },
    },
  })
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [prevOpen, setPrevOpen] = useState(false)
  const [waitingOnAction, setWaiting] = useState(false)
  // this form-state is purely for frontend purposes. In the backend unlimited
  // sharepoint duration is set by setting the sharepoint duration to 0
  const [unlimitedShareDuration, setUnlimitedShareDuration] = useState(false)
  const [openFormEditorDrawer, setOpenFormEditorDrawer] = useState(false)
  const [openContactDrawer, setOpenContactDrawer] = useState(false)
  const [openArticleDrawer, setOpenArticleDrawer] = useState(false)
  const [openFilesDrawer, setOpenFilesDrawer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  const uniqueKeyStore = useRef(0)
  const articleStore = useRef<Article[]>([])
  const [articleOptions, setArticleOptions] = useState<SelectOption[]>()
  const [activeStep, setActiveStep] = useState<number>(0)
  const [articleFormInput, setArticleFormInput] = useState<any>({})
  const [manualSharesForm, setManualSharesForm] = useState<any>([{type: 'addManualShare'}])
  const [manualSharesFormInput, setManualSharesFormInput] = useState<{
    [inputKey: string]: {comment: string, password?: string, accessUrl?: string}
  }>({})
  const [automaticSharesForm, setAutomaticSharesForm] = useState<any>([{type: 'addAutomaticShare'}])
  const [automaticSharesFormInput, setAutomaticSharesFormInput] = useState<AutomaticShareFormInput>({})
  const feedbackFormStore = useRef<FeedbackForm[]>([])
  const [formEditorInput, setFormEditorInput] = useState<any>()
  const [feedbackFormOptions, setFeedbackFormOptions] = useState<SelectOption[]>([])
  const [feedbackFormPreviewInput, setFeedbackFormPreviewInput] = useState<any>()
  const contactStore = useRef<{[relatedToName: string]: RichContact[]}>({})
  const [contactFormInput, setContactFormInput] = useState<any>({})
  const [contactOptions, setContactOptions] = useState<SelectOption[]>([])
  const [newContactReceiver, setNewContactReceiver] = useState<string>()
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchFeedbackForms({
      onSuccess: (forms: FeedbackForm[]) => {
        feedbackFormStore.current = [...feedbackFormStore.current, ...forms]
        setFeedbackFormOptions(feedbackFormStore.current.map(
          form => ({value: form.self, label: form.formTitle})
        ))
      }
    })
    fetchRichContacts({
      onSuccess: (richContacts: RichContact[]) => {
        // collect contacts ordered by relation to suppliers / customers / unrelated
        richContacts.forEach((contact: RichContact) => {
          contact.suppliers.forEach((supplier: Supplier) => {
            if(contactStore.current[supplier.name]) {
              contactStore.current[supplier.name].push(contact)
            } {
              contactStore.current[supplier.name] = [contact]
            }
          })
          contact.customers.forEach((customer: Customer) => {
            if(contactStore.current[customer.name]) {
              contactStore.current[customer.name].push(contact)
            } else {
              contactStore.current[customer.name] = [contact]
            }
          })
          if(contact.customers.length === 0 && contact.suppliers.length === 0) {
            if(contactStore.current['unrelated']) {
              contactStore.current['unrelated'].push(contact)
            } else {
              contactStore.current['unrelated'] = [contact]
            }
          }
        })

        // generate list of contact options sorted by suppliers and customers
        let initialContactOptions: SelectOption[] = []
        Object.keys(contactStore.current)
          .forEach((relatedToName: string) => {contactStore.current[relatedToName]
            .forEach((contact: RichContact) => {
              initialContactOptions.push({
                value: contact.self,
                label: getContactRepresentation(
                  contact,
                  relatedToName !== 'unrelated' ? relatedToName : ''
                )
              })
            })
          })

        // update state
        setContactOptions(initialContactOptions)
      }
    })
  }, [])
  /* ------------------------------- Effects end ------------------------------ */




  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Generates a unique element key (ascending number).
   * @returns
   */
  function getUniqueKey(): string {
    const key = uniqueKeyStore.current
    uniqueKeyStore.current += 1
    return String(key)
  }

  /**
   * Creates a new, unique ManualShareFieldObject that can be inserted into the manualSharesForm.
   * @returns
   */
  function generateManualShareFieldObject() {
    const fieldObjKey = `manualShare${getUniqueKey()}`
    return {
      key: fieldObjKey,
      label: t("common:content.label.comment"),
      onChange: (input: string) => {
        log.debug('Updating', fieldObjKey, 'with value ->', input)
        setManualSharesFormInput((prev: any) => {
          if(prev[fieldObjKey]?.comment) {
            // the user entered a comment before already -> update it
            prev[fieldObjKey] = {...prev[fieldObjKey], comment: input}
          } else {
            prev[fieldObjKey] = {
              comment: input,
              password: formState.state.password ?? securePasswordGenerator.randomPassword()
            }
          }
          return {...prev}
        })
      }
    }
  }
  /**
   * Creates a new, unique AutomaticShareFieldObject that can be inserted into the automaticSharesForm.
   * @returns
   */
  function generateAutomaticShareFieldObject() {
    const fieldObjKey = `automaticShare${getUniqueKey()}`
    return {
      key: fieldObjKey,
      label: t("common:content.label.comment"),
    }
  }

  /**
   * Determines if there are any empty automatic share comment input fields.
   * @returns
   */
  function missingAutomaticShareSelect(): boolean {
    let selectIsMissing = false
    const automaticSharesInputValues: SelectOption[] = Object.values(automaticSharesFormInput)
                                                                .map(
                                                                  (autoShare: {contact: SelectOption, customInvitationMessage: string}) => autoShare.contact
                                                                )
    if( (automaticSharesForm.length - 1) > automaticSharesInputValues.length ) {
      // there is a automaticShareFieldObject that was not yet used to select a automatic share contact
      selectIsMissing = true
    }
    if(automaticSharesInputValues.length > 0) {
      for(const automaticShare of automaticSharesInputValues) {
        if(!automaticShare) {
          selectIsMissing = true;
          break;
        }
      }
    }
    return selectIsMissing
  }
  /**
   * Determines if there are any empty manual share comment input fields.
   * @returns
   */
  function missingManualShareComment(): boolean {
    let commentIsMissing = false
    const manualSharesFormInputValues: {comment: string, password?: string}[] = Object.values(manualSharesFormInput)
    if( (manualSharesForm.length - 1) > manualSharesFormInputValues.length ) {
      // there is a manualSharesFormFieldObject that was not yet used to enter a manual share comment
      commentIsMissing = true
    }
    if(manualSharesFormInputValues.length > 0) {
      for(const manualShare of manualSharesFormInputValues) {
        if(manualShare.comment === '') {
          commentIsMissing = true;
          break;
        }
      }
    }
    return commentIsMissing
  }
  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = (preventOnClose: boolean = false) => {
    // reset drawer to initial state
    uniqueKeyStore.current = 0
    setPrevOpen(false)
    setActiveStep(0)
    setManualSharesForm([{type: 'addManualShare'}])
    setManualSharesFormInput({})
    setAutomaticSharesForm([{type: 'addAutomaticShare'}])
    setAutomaticSharesFormInput({})
    setFormEditorInput(undefined)
    setFeedbackFormPreviewInput({})
    formState.setState({})

    setWaiting(false)
    setUnlimitedShareDuration(false)

    setOpen(false)


    !preventOnClose && onClose()
  }

  const handleBack = () => {
    if(activeStep > 0) {
      setActiveStep(prev => prev - 1)
    }
  }

  const handleNext = () => {
    if(activeStep < steps.length) {
      setActiveStep(prev => prev + 1)
    }
  }

  const handleConfirm = async () => {
    // visual feedback
    setWaiting(true)

    // posting request to server and waiting on response
    const confirmStatus = await onConfirm({
      ...formState.state,
      shareDuration: unlimitedShareDuration ? "0" : (formState.state.shareDuration ?? defaultShareDuration),
      articles: [formState.state.guestRequestArticle.value],
      guestRequest: selectedGuestRequest.self,
      ...(formState.state.feedbackForm && {feedbackForm: formState.state.feedbackForm.value}),
      ...(formState.state.automaticShares && {automaticShares: formState.state.automaticShares.map(
        (supplier: SelectOption) => supplier.value)}
      ),
      ...((automaticSharesForm.length > 1 && Object.values(automaticSharesFormInput).length > 0) && {
        // convert the formInput object into a list of automatic share info-objects
        automaticShares: Object.values(automaticSharesFormInput)
                          .map(automaticShare => (
                            {
                              contact: automaticShare.contact.value,
                              customInvitationMessage: automaticShare.customInvitationMessage
                            }
                          ))}
      ),
      ...((manualSharesForm.length > 1 && Object.values(manualSharesFormInput).length > 0) && {
        // convert the formInput object into a list of manual share info-objects
        manualShares: Object.values(manualSharesFormInput)}
      ),
    })

    if(typeof confirmStatus === 'object') {
      toast.success(t("sharepoint:feedback.success.creatingSharepoint"))
      const sharepoint: Sharepoint = createSharepoint(confirmStatus)
      // go to next step (does not really exist) to display the generated sharepoint url
      const [response, error] = await handleAPICallV1(
        HTTPMethod.GET,
        sharepoint!.self + 'manual_shares/',
        undefined,
        undefined,
      )

      if(!error && response) {
        log.info('Success fetching manual shares (GuestUsers)', response.results)
        // combine all manual shares data from user input and server response
        setManualSharesFormInput(
          response.results.map((manualShareServerResp: any) => {
            // compare manual share from server response with the manual shares created within this drawer
            const match: any = Object.values(manualSharesFormInput).find(
              (manualShareInput: any) => {
                log.debug('Compare server resp against', manualShareInput)
                return manualShareInput.comment === manualShareServerResp.comment
              }
            )
            return {
              comment: match?.comment,
              password: match?.password,
              accessUrl: window.location.origin + '/guest-signIn/' + manualShareServerResp.guest_id
            }
          })
        )
      }
      if(Object.keys(manualSharesFormInput).length > 0) {
        handleNext()
      } else {
        handleClose(true)
      }
    } else {
      toast.error(t('sharepoint:feedback.error.creatingSharepoint'))
    }
    setWaiting(false)
  }

  const handleFetchArticleOptions = ()=> {
    fetchArticles({
      onSuccess: (articles: Article[]) => {
        articleStore.current = [...articleStore.current, ...articles]
        setArticleOptions(articleStore.current.map(a => ({value: a.self, label: getArticleRepresentation(a)})))
      }
    })
  }

  const handleAddArticleOption = async (articleInput: any) => {
    log.info('Begin creating article option')
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'items/articles/',
      undefined,
      inputToAPIArticleJSON(articleInput)
    )

    if(!error && response) {
      log.info('Success creating article option')

      // update states
      const article = createArticle(response)
      articleStore.current.push(article)
      setArticleOptions(prev => [...prev!, {value: article.self, label: getArticleRepresentation(article)}])
      formState.setState((prev: any) => ({...prev, guestRequestArticle: {value: article.self, label: getArticleRepresentation(article)}}))

      return response.files
    } else {
      log.error('Error creating article option')
      return 'ERROR'
    }
  }

  const handleAddFormOption = async (feedbackForm: any): Promise<"SUCCESS" | "ERROR"> => {
    log.info("Begin sending feedback form.")
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'items/feedback_forms/',
      undefined,
      inputToAPIFeedbackFormJSON(feedbackForm)
    )

    if(response && !error) {
      log.info("Success creating feedback form")
      const feedbackForm: FeedbackForm = createFeedbackForm(response)

      setFeedbackFormOptions(prev => [
        ...prev,
        { value: feedbackForm.self, label: feedbackForm.formTitle}
      ])

      //set selected contact option
      formState.setState((prev: any) => {return {
        ...prev,
        feedbackForm: {value: feedbackForm.self, label: feedbackForm.formTitle}
      }})

      // add feedback form to formStore
      feedbackFormStore.current = [...feedbackFormStore.current, feedbackForm]

      return 'SUCCESS'
    } else {
      log.error('Error while creating feedback form option', error)
      return 'ERROR'
    }
  }

  const handleAddAutomaticShare = () => {
    setAutomaticSharesForm((prev: any) => {
      // we need to store this index to enable the onChange method
      const fieldObjIndex: number = prev.length - 1

      // replace the last element with a new textfield
      prev[fieldObjIndex] = generateAutomaticShareFieldObject()

      // add 'addFormField' entry to the end of formJson
      return [...prev, {type: 'addAutomaticShare'}]
    })
  }

  const handleAddManualShare = () => {
    setManualSharesForm((prev: any) => {
      // we need to store this index to enable the onChange method
      const fieldObjIndex: number = prev.length - 1

      // replace the last element with a new textfield
      prev[fieldObjIndex] = generateManualShareFieldObject()

      // add 'addFormField' entry to the end of formJson
      return [...prev, {type: 'addManualShare'}]
    })
  }

  const handleDeleteAutomaticShare = (automaticShareFieldObj: any) => {
    setAutomaticSharesForm((prev: any) => {
      const i = prev.indexOf(automaticShareFieldObj)
      // remove manualShareFieldObject
      prev.splice(i, 1)

      // delete the according manualShareFormInput
      setAutomaticSharesFormInput((prev: any) => {
        delete prev[automaticShareFieldObj.key]
        return {...prev}
      })
      return [...prev]
    })
  }

  const handleDeleteManualShare = (manualShareFieldObj: any) => {
    setManualSharesForm((prev: any) => {
      const i = prev.indexOf(manualShareFieldObj)
      // remove manualShareFieldObject
      prev.splice(i, 1)

      // delete the according manualShareFormInput
      setManualSharesFormInput((prev: any) => {
        delete prev[manualShareFieldObj.key]
        return {...prev}
      })
      return [...prev]
    })
  }

  const handleAddContactOption = async (contactFormInput: any): Promise<"SUCCESS" | "ERROR"> => {
    log.info("Begin sending contact.")
    const json = formInputToAPIContactJSON(contactFormInput)
    log.debug("Input ->", contactFormInput, ", JSON ->", json)

    // create new contact
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'contacts/contacts/',
      undefined,
      json
    )

    if(!error && response) {

      // update contact options
      const newContact = createContact(response)
      if(contactStore.current['unrelated']) {
        contactStore.current['unrelated'].push(newContact as RichContact)
      } else {
        contactStore.current['unrelated'] = [newContact as RichContact]
      }
      setContactOptions(prev => [
          ...prev,
          {
            value: newContact.self,
            label: getContactRepresentation(newContact),
          }
        ]
      )

      // update the select that was used to initiate the contact creation process
      setAutomaticSharesFormInput(prev => {
        prev[newContactReceiver as string] = {
          contact: {
            value: newContact.self,
            label: getContactRepresentation(newContact)
          },
          customInvitationMessage: ''
        }
        return {...prev}
      })

      return "SUCCESS"
    } else {
      log.error("Error creating contact option", error)
      return "ERROR"
    }
  }
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const CustomStepIcon = (props: any) => {
    return (
      <ThemeProvider theme={customTheme}>
        <StepIcon {...props} />
      </ThemeProvider>
    )
  }

  const steps = [
    t('common:content.label.hints'),
    t('item:content.label.createArticle'),
    t('sharepoint:content.label.configureSharepoint'),
    t('sharepoint:content.label.shareSharepoint'),
  ]

  const implicationsAndComments = [
    {
      content: <span>{t('sharepoint:content.description.guestRequestSharepointHints.guestRequestArticleHint')}</span>
    },
    {
      content: <>
        <span>{t('sharepoint:content.description.guestRequestSharepointHints.editSharepointHint')}</span><br/>
        <Button
          variant='contained'
          color='inherit'
          sx={{ mt: '0.5rem'}}
          onClick={() => history.push('/items/sharepoints')}
        >
          {t('sharepoint:interaction.button.editSharepoint')}
        </Button>
      </>,
    },
  ]

  const renderDrawerHeader = () => {
    return (
      <Box display='flex' width='100%'>
        <Typography variant="h6" noWrap sx={{ flexShrink: 0, mr: '2rem' }}>
          Gastanfrage teilen
        </Typography>
        <Stepper
          activeStep={activeStep}
          sx={{ marginRight: '16px', marginLeft: '-8px', width: '100%' }}
        >
          {steps.map((label: string, index: number) => {
            return (
              <Step key={index}>
                <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    )
  }

  const renderDrawerActions = () => {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        {/* ----------------------- Start cancel / back buttons ---------------------- */}
        {activeStep === 0 &&
          // show cancel-button on first step and after creating the sharepoint
          <Button
            id="cancelButton"
            onClick={() => setOpen(false)}
            color="inherit"
            variant="contained"
            disabled={waitingOnAction}
            sx={{ mr: 1}}
          >
            {t('common:interaction.button.cancel')}
          </Button>
        }
        {(activeStep > 0 && activeStep < steps.length) &&
          <Button
            id="backButton"
            color="inherit"
            variant="contained"
            disabled={waitingOnAction}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            {t('common:interaction.button.back')}
          </Button>
        }
        {/* ------------------------ End cancel / back buttons ----------------------- */}

        {/* ---------------------- Start next / confirm buttons ---------------------- */}
        {activeStep < 3 &&
          <Button
            id="nextButton"
            variant="contained"
            disabled={activeStep === 1 && !formState.state.guestRequestArticle}
            sx={{ marginLeft: '10px'}}
            onClick={handleNext}
          >
            {t('common:interaction.button.next')}
          </Button>
        }
        {activeStep === 3 &&
          <LoadingButton
            id="createButton"
            variant="contained"
            sx={{ textTransform: 'none', marginLeft: '10px', color: 'whitesmoke' }}
            // disabled={nextStepDisabled(activeStep)}
            loading={waitingOnAction}
            onClick={handleConfirm}
            disabled={
              // to create a sharepoint the user has to specify at least one sharepoint guest
              (Object.values(automaticSharesFormInput).length === 0 && Object.values(manualSharesFormInput).length === 0)
              || missingAutomaticShareSelect()
              || missingManualShareComment()
            }
          >
            {t('sharepoint:interaction.button.createSharepoint')}
          </LoadingButton>
        }
        {activeStep === steps.length &&
          <Button
            id='doneButton'
            variant='contained'
            sx={{textTransform: 'none', marginLeft: '10px', color: 'whitesmoke'}}
            onClick={() => handleClose(false)}
          >
            {t('common:interaction.button.done')}
          </Button>
        }
        {/* ----------------------- End next / confirm buttons ----------------------- */}
      </Box>
    )
  }

  const renderFeedbackForm = (form: FeedbackForm | undefined) => {
    if(typeof form === 'undefined') {
      return (
        <Typography variant="body1" color='GrayText' textAlign='center' width='100%' mt='1.5rem' mb='1.5rem'>
          {t('sharepoint:feedback.info.selectFeedbackForm')}
        </Typography>
      )
    }
    return (
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h5" textAlign='center' fontWeight={700} mb='2.5rem'>
            {form.formTitle}
          </Typography>
        </Grid>
        <Form
          formObject={feedbackFormPreviewInput}
          formFields={form.formJson as FormFieldType[]}
          onChange={(input: any) => setFeedbackFormPreviewInput({...input})}
          editing
        />
      </Grid>
    )
  }

  const renderAutomaticShareFieldObject = (automaticShareFieldObj: any, listItemKey: number) => {
    if(automaticShareFieldObj.type === 'addAutomaticShare') {
      return (
        <ListItem key={listItemKey}>
          <ListItemIcon>
            <Button
                startIcon={<AddRounded style={{ fontSize: '25px', color: 'GrayText'}} />}
                onClick={handleAddAutomaticShare}
                sx={{ fontSize: '16px', color: 'black', ml: '4px' }}
                style={{color: 'black', textTransform: 'none'}}
              >
                {t('sharepoint:content.label.addAutomaticShare')}
              </Button>
          </ListItemIcon>
        </ListItem>
      )
    }
    return (
      <ListItem key={listItemKey} sx={{alignItems: 'start'}}>
        <ListItemIcon sx={{marginTop: '0.6875rem'}}>
          <IconButton onClick={() => handleDeleteAutomaticShare(automaticShareFieldObj)}>
            <DeleteRounded/>
          </IconButton>
        </ListItemIcon>
        <ListItemText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SearchSelectInput
                name={t('sharepoint:content.label.shareWith')}
                fullWidth
                value={automaticSharesFormInput[automaticShareFieldObj.key]?.contact ?? undefined}
                // exclude all contacts that are selected already
                options={contactOptions.filter(
                  (option: SelectOption) => !Object.values(automaticSharesFormInput)
                                                    .map((autoShare: {contact: SelectOption, customInvitationMessage: string}) => autoShare.contact)
                                                    .includes(option))
                }
                onChange={(input: any) => {
                  const fieldObjKey = automaticShareFieldObj.key
                  log.debug('Updating', fieldObjKey, 'with value ->', input)
                  setAutomaticSharesFormInput(prev => {
                    if(!prev[fieldObjKey]) {
                      // first select change -> create input object
                      prev[fieldObjKey] = {contact: input, customInvitationMessage: ''}
                    } else {
                      prev[fieldObjKey] = {...prev[fieldObjKey], contact: input}}
                    return {...prev}
                  })
                }}
                onAddOption={() => {
                  setNewContactReceiver(automaticShareFieldObj.key)
                  setOpenContactDrawer(true)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={
                  (automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? '').length > 0
                    ? 'Personalisierte Einladungsnachricht' + `    ${(automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? '').length}/500`
                    : 'Personalisierte Einladungsnachricht'
                }
                value={automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? ''}
                disabled={!automaticSharesFormInput[automaticShareFieldObj.key]?.contact}
                fullWidth
                multiline
                rows={2}
                error={(automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? '').length > 500}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const fieldObjKey = automaticShareFieldObj.key
                  const input = event.target.value
                  log.debug('Updating', fieldObjKey, 'with value ->', input)

                  setAutomaticSharesFormInput(prev => {
                    prev[fieldObjKey] = {...prev[fieldObjKey], customInvitationMessage: input}
                    return {...prev}
                  })
                }}
              />
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
    )
  }

  const renderManualShareFieldObject = (manualShareFieldObj: any, listItemKey: number) => {
    if(manualShareFieldObj.type === 'addManualShare') {
      return (
        <ListItem key={listItemKey}>
          <ListItemIcon>
            <Button
                startIcon={<AddRounded style={{ fontSize: '25px', color: 'GrayText'}} />}
                onClick={handleAddManualShare}
                sx={{ textTransform: 'none', fontSize: '16px', color: 'black', ml: '4px' }}
                style={{color: 'black', textTransform: 'none'}}
              >
                {t('sharepoint:content.label.addManualShare')}
              </Button>
          </ListItemIcon>
        </ListItem>
      )
    }
    return (
      <ListItem key={listItemKey}>
        <ListItemIcon>
          <IconButton onClick={() => handleDeleteManualShare(manualShareFieldObj)}>
            <DeleteRounded/>
          </IconButton>
        </ListItemIcon>
        <ListItemText>
          <TextField
            value={manualSharesFormInput[manualShareFieldObj.key]?.comment ?? ''}
            onChange={(event: any) => manualShareFieldObj.onChange(event.target.value)}
            autoFocus
            fullWidth
            label={manualShareFieldObj.label}
            required={true}
          />
        </ListItemText>
      </ListItem>
    )
  }
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */
  if(!prevOpen && open) {
    setPrevOpen(true)
    fetchArticles({
      onSuccess: (articles: Article[]) => {
        articleStore.current = [...articleStore.current, ...articles]
        setArticleOptions(articleStore.current.map(a => ({value: a.self, label: getArticleRepresentation(a)})))
      }
    })
  }

  // get the selected articles by their self values
  const selectedArticle = articleStore.current.filter(a => a.self === formState.state.guestRequestArticle?.value)[0]
  /* ------------------------- Pre render actions end ------------------------- */

  log.debug(
    "formState ->", formState.state,
    ", formEditorInput ->", formEditorInput,
    ", feedbackFormStore ->", feedbackFormStore.current,
    ", feedbackFormOptions ->", feedbackFormOptions,
    ", contactStore ->", contactStore.current,
    ", contactOptions ->", contactOptions,
    ", automaticSharesForm ->", automaticSharesForm,
    ", automaticSharesFormInput ->", automaticSharesFormInput,
    ", manualSharesForm ->", manualSharesForm,
    ", manualSharesFormInput ->", manualSharesFormInput
  )

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        size="big"
        title={t('sharepoint:content.heading.shareArticles') as string}
        onClose={handleClose}
        customHeader={renderDrawerHeader()}
        customActions={renderDrawerActions()}
      >
        {activeStep === 0 && <>
          <Typography variant='body1'>
            {t('sharepoint:content.description.guestRequestSharepointHints.introduction')}
          </Typography>
          <List>
            {implicationsAndComments.map(
              (listItem: {content: JSX.Element, icon?: JSX.Element}, index: number) => {
                return (
                  <ListItem alignItems='flex-start' key={index}>
                    <ListItemIcon>
                      {listItem.icon ?? <TipsAndUpdatesRounded color='info'/>}
                    </ListItemIcon>
                    <ListItemText>
                      {listItem.content}
                    </ListItemText>
                  </ListItem>
                )
              })
            }
          </List>
        </>}
        {activeStep === 1 && <>
          <Typography variant='h6' mb='1.2rem'>
            {t('sharepoint:content.heading.createGuestRequestArticle')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Stack direction='column' spacing={2}>
                <InfoField
                  label={t('request:content.label.requestFrom')}
                  value={selectedGuestRequest.name}
                />
                <InfoField
                  label={t('common:content.label.description')}
                  value={selectedGuestRequest.message}
                />
                <Button
                  id="showFilesButton"
                  fullWidth
                  variant="contained"
                  onClick={() => setOpenFilesDrawer(true)}
                >
                  {t('common:interaction.button.showFiles')}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction='column' spacing={2}>
                <Typography variant="body1">
                  {t('sharepoint:content.description.guestRequestSharepointHints.chooseGuestRequestArticleHint')}
                </Typography>
                {/* <SelectInput
                  label={t('common:content.label.item')}
                  value={formState.state.guestRequestArticle}
                  onChange={(input: any) => formState.setState((prev: any) => ({...prev, guestRequestArticle: input}))}
                  onFetchOptions={handleFetchArticleOptions}
                  options={articleOptions}
                  onAddOption={() => setOpenArticleDrawer(true)}
                /> */}
                <SearchSelectInput
                  name={t('common:content.label.item')}
                  value={formState.state.guestRequestArticle}
                  options={articleOptions}
                  onChange={(input: any) => formState.setState((prev: any) => ({...prev, guestRequestArticle: input}))}
                  onAddOption={() => setOpenArticleDrawer(true)}
                  // loadData={loadArticleData}
                  // loadAllData={loadAllArticleData}
                />
              </Stack>
            </Grid>
          </Grid>
        </>}
        {activeStep === 2 && <>
          <Typography variant='h6' mb='1.2rem'>
            {t('sharepoint:content.heading.scopeAndDuration')}
          </Typography>
          <Grid container spacing={2} pl='2rem'>
            <Grid item xs={6}>
              <FieldLabel value={t('common:content.label.article')} />
              <List
                sx={{
                  maxHeight: '20vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  border: '1px solid lightGrey',
                  borderRadius: '4px',
                }}
              >
                <ListItem key={getPKfromSelf(formState.state.guestRequestArticle.self)}>
                  <ListItemIcon><CheckBoxRounded color="disabled" /></ListItemIcon>
                  <ListItemText
                    sx={{
                      textWrap: 'wrap',
                      wordBreak: 'break-word',
                    }}
                    primary={getArticleRepresentation(selectedArticle)}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t('sharepoint:content.description.articleSharepointHints.sharedArticlesHint')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <NumberInputWithUnit
                  disabled={unlimitedShareDuration}
                  label={t('sharepoint:content.label.sharepointDuration')}
                  value={unlimitedShareDuration ? '' : (formState.state.shareDuration ?? defaultShareDuration)}
                  limits={{minInput: 1, maxInput: 365}}
                  unit={t('common:content.label.days') as string}
                  onChange={
                    function (value: string | number): void {
                      formState.setState((prev: any) => { return {
                        ...prev,
                        shareDuration: value
                      }})
                    }
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={unlimitedShareDuration} onChange={(e: any) => setUnlimitedShareDuration(e.target.checked)} />
                  }
                  label={t('sharepoint:interaction.checkbox.unlimitedSharepointDuration')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t('sharepoint:content.description.configureSharepoint.durationHint')}
              </Typography>
            </Grid>
          </Grid>
          {/* TODO: feature for the future  */}
          {/* <Typography variant='h6' mt='4rem;' mb='1.2rem'>
            {t('common:content.label.security')}
          </Typography>
          <Grid container spacing={2} pl='2rem'>
            <Grid item xs={6}>
              <FieldLabel value={t('common:content.label.permissions')} />
              <FormControl
                fullWidth
                sx={{
                  border: '1px solid LightGrey',
                  borderRadius: '4px',
                  padding: '14px',
                  position: 'relative',
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    disabled
                    control={<Switch defaultChecked />}
                    label={t('sharepoint:interaction.switch.sharepointViewPermission')}
                  />
                  <FormControlLabel
                    control={<Switch />}
                    label={t('sharepoint:interaction.switch.sharepointEditPermission')}
                    name="edit"
                    checked={formState.state.permissions?.edit || false}
                    onChange={(e: any) => formState.setState((prev: any) => {return {
                        ...prev,
                        permissions: {
                          [e?.target.name]: e.target.checked,
                        }
                      }}
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t('sharepoint:content.description.configureSharepoint.permissionHint')}
              </Typography>
            </Grid>
          </Grid> */}
          <Typography variant='h6' mt='4rem;' mb='1.2rem'>
            {t('common:content.label.feedback')}
          </Typography>
          <Grid container spacing={2} pl='2rem'>
            <Grid item xs={6}>
              <SearchSelectInput
                name={t('sharepoint:content.label.feedbackForm')}
                value={formState.state.feedbackForm}
                options={feedbackFormOptions}
                onChange={(input: any) => formState.setState((prev: any) => {return {...prev, feedbackForm: input}})}
                validation={false}
                onAddOption={() => setOpenFormEditorDrawer(true)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                {t('sharepoint:content.description.configureSharepoint.feedbackFormHint')}
              </Typography>
            </Grid>
            <Grid item xs={12} pb='1rem'>
              <Accordion disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreRounded/>}
                >
                  {t('sharepoint:content.heading.feedbackFormReview')}
                </AccordionSummary>
                <AccordionDetails>
                  {formState.state.feedbackForm
                    ? renderFeedbackForm(feedbackFormStore.current.find(
                      (form: FeedbackForm) => form.self === formState.state.feedbackForm.value))
                    : (
                      <Typography variant="body1" color='GrayText' textAlign='center' width='100%' mt='1.5rem' mb='1.5rem'>
                        {t('sharepoint:feedback.info.selectFeedbackForm')}
                      </Typography>
                    )
                  }
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </>}
        {(activeStep === steps.length - 1) && <>
          <Typography variant='h6' mb='1.2rem'>
            {t('sharepoint:content.heading.shareAutomatically')}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" ml='16px'>
                {t('sharepoint:content.description.configureSharepoint.automaticShareHint')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {automaticSharesForm.map((automaticShareFieldObj: any, index: number) =>
                  renderAutomaticShareFieldObject(automaticShareFieldObj, index)
                )}
              </List>
            </Grid>
          </Grid>
          <Typography variant='h6' mt='4rem;' mb='1.2rem'>
            {t('sharepoint:content.heading.shareManually')}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" ml='16px'>
                {t('sharepoint:content.description.configureSharepoint.manualShareHint')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <List>
                {manualSharesForm.map((manualShareFieldObj: any, index: number) =>
                  renderManualShareFieldObject(manualShareFieldObj, index)
                )}
              </List>
            </Grid>
          </Grid>
        </>}
        {activeStep === steps.length && <>
          <Typography variant="h6" mb='1.2rem'>
            {t('sharepoint:content.heading.shareManually')}
          </Typography>
          <Grid container spacing={6}  pl='2rem' >
            {Object.values(manualSharesFormInput).map(
              (manualShare: any, index: number) => {
                return (
                  <Grid item container spacing={1} key={index}>
                    <Grid item xs={12}>
                      <InfoField label={t('common:content.label.comment')} value={manualShare.comment} fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                      <InteractiveTextDisplay
                        type="link"
                        label={t('sharepoint:content.label.sharepointLink') as string}
                        value={manualShare.accessUrl}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InteractiveTextDisplay
                        type="password"
                        label={t('common:content.auth.password') as string}
                        value={manualShare.password}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
        </>}
      </MultilevelDrawer>
      <ArticleDrawer
        open={openArticleDrawer}
        setOpen={setOpenArticleDrawer}
        formState={{
          state: articleFormInput,
          setState: setArticleFormInput
        }}
        onCreateConfirm={handleAddArticleOption}
      />
      <FilesDrawer
        open={openFilesDrawer}
        setOpen={setOpenFilesDrawer}
        files={selectedGuestRequest.files}
      />
      <FormEditorDrawer
        open={openFormEditorDrawer}
        setOpen={setOpenFormEditorDrawer}
        formState={{
          state: formEditorInput,
          setState: setFormEditorInput
        }}
        onConfirm={handleAddFormOption}
      />
      <ContactDrawer
        open={openContactDrawer}
        setOpen={setOpenContactDrawer}
        formState={{
          state: contactFormInput,
          setState: setContactFormInput,
        }}
        onConfirm={handleAddContactOption}
      />
    </>
  )
}