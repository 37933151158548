/* -------------------------- Design imports start -------------------------- */
import {  Typography, MenuItem, Menu } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useMobileService from '../../hooks/useMobileService'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { createUser, handleAPICallV1 } from '../../utils/functions'
import { HTTPMethod, User } from '../../utils/types'
import LogTool from '../../logger/logTools'
import LanguageSelectionDrawer from './LanguageSelectionDrawer'
import { useWebSocket } from '../../WebSocketProvider'
import Button from '../Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  setUser: (user: any) => void
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function AccountButton(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { setUser } = props
  const isMobile = useMobileService()
  let size
  isMobile
    ? (size = { height: '27vh', width: '95vw' })
    : (size = { height: '250px', width: '500px' })
  const log = new LogTool({ context: 'AccountButton', enable: true, logLevel: 'warn' })
  const { setIsLoggedIn, setAccessTokenWS } = useWebSocket()
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const id = anchorEl ? 'simple-popover' : undefined
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [userData, setUserData] = useState<User>()
  const history = useHistory()
  const { t } = useTranslation(['common'])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchUser()
  }, [])
  /* ------------------------------- Effects end ------------------------------ */
  /* ------------------------- Utility functions start ------------------------ */
  async function fetchUser() {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'accounts/users/self/?expand=roles',
      undefined,
      undefined
    )
    if (error) {
      log.error('Error fetching user', error)
    }
    if (response) {
      const fetchedUser = createUser(response)
      setUserData(fetchedUser)
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setUser(null)
    setIsLoggedIn(false)
    setAccessTokenWS("")
    Cookies.remove('access')
    Cookies.remove('refresh')
    history.push('/signIn')
    handleClose() // Close the Popover after handling logout
  }

  const handleProfileClick = () => {
    handleClose() // Close the Popover when the profile is clicked
    history.push('/profile')
  }

  const handleSettingsClick = () => {
    setModalOpen(true)
    handleClose() // Close the Popover when the settings are clicked
  }

  /* ------------------------- Callback functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Button
        id="accountButton"
        style={{ marginLeft: '25px', color: 'inherit' }}
        startIcon={<AccountCircleOutlinedIcon />}
        size="medium"
        variant="text"
        color="inherit"
        onClick={handleClick}
      >
        <Typography style={{ fontSize: '13px', marginLeft: '5px', textTransform: 'none' }}>
          {userData?.email}
        </Typography>
      </Button>
      {anchorEl && (
        <Menu
          open={Boolean(anchorEl)}
          id={id}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginTop: '20px' }}
        >
          <MenuItem
            id="settingsMenuItem"
            onClick={handleSettingsClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SettingsIcon style={{ color: 'darkgray' }} />
            <Typography variant="inherit" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
              {t('common:content.label.settings')}
            </Typography>
          </MenuItem>
          {!userData?.isGuestUser &&
            <MenuItem
              id="profileMenuItem"
              onClick={handleProfileClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <AccountCircleOutlinedIcon style={{ color: 'darkgray' }} />
              <Typography variant="inherit" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
                {t('common:content.label.profile')}
              </Typography>
            </MenuItem>
          }
          <MenuItem
            id="logoutMenuItem"
            onClick={handleLogout}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <LogoutIcon style={{ color: 'darkgray' }} />
            <Typography variant="inherit" sx={{ marginLeft: 1, fontWeight: 'bold' }}>
              {t('common:content.label.logout')}
            </Typography>
          </MenuItem>
        </Menu>
      )}
      <LanguageSelectionDrawer open={modalOpen} setOpen={setModalOpen} />
    </>
  )
}
