import moment from "moment"
import { APICustomer, APICustomerFromJSON } from "../../../generated-types"
import LogTool from "../../../logger/logTools"
import { buildFetchUrl, createContact, getPKfromSelf, handleAPICallV1, parseNestedObject, parseNestedObjectList } from "../../../utils/functions"
import { HTTPMethod } from "../../../utils/types"
import { Customer, CustomerOrders } from "./types"

const log = new LogTool({context: "customerUtils", enable: true, logLevel: 'warn'})
/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */

/**
 * Convert an Customer-Server-Response into an CustomerInfo object.
 * @param responseObject The APIArticle response Object.
 * @returns
 */
export function createCustomer(responseObject: any): Customer {

  const c: APICustomer = APICustomerFromJSON(responseObject)

    return {
      ...c,
      key: getPKfromSelf(c.self),
      contacts: parseNestedObjectList(c.contacts, createContact)
    }
  }

  /**
   * Convert an Customer object to the JSON representation of the corresponding APICustomer object.
   * @param input Customer to convert.
   * @returns
   */
  export function formInputToAPICustomerJSON(input: any): any {
    return {
      ...(input.name && {'name': input.name}),
      ...(input.ustId && {'ust_id': input.ustId}),
      ...(input.versionComment && {'version_comment': input.versionComment}),
      // TODO: enable creating supplier with multiple contacts
      /* ...((input.contacts && Array.isArray(input.contacts) && typeof input.contacts[0] === 'string')
        && {'contacts': input.contacts} // input.contacts is list of hyperlinks
      ) */
      'contacts': (input.contacts && Array.isArray(input.contacts) && typeof input.contacts[0] === 'string')
        ? input.contacts
        : input.contacts[0] !== "string"
        ? input.contacts.map((contact: any) => contact.self)
        : [],
    }
  }

  /**
   * Convert an Customer object to the JSON representation of the corresponding APICustomerOrder object.
   * @param input Customer to convert.
   * @returns
   */

  export function inputToAPICustomerOrderJSON(input: any) {
    return {
        ...input,
        ...(input.dueDate && { due_date: moment(input.dueDate).format("YYYY-MM-DD") }),
        ...(input.startDate && { start_date: moment(input.startDate).format("YYYY-MM-DD") }),
        ...(input.wishDate && { wish_date: moment(input.wishDate).format("YYYY-MM-DD") }),
        ...(input.orderDate && { order_date: moment(input.orderDate).format("YYYY-MM-DD") }),
        ...(input.planning && { planning: input.planning?.value }),
    }
}


/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */

/**
 * Fetch customer data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchCustomers(
  kwargs: {
    onSuccess: (response: Customer[], count: any) => void,
    onFinal?: () => void,
    onError?: (error: any) => void,
    parameter?: string[],
    url?: string}
): Promise<void>
{
  // getting kwargs and setting defaults
  const {
    onSuccess,
    onFinal = () => null,
    onError = (error: any) => null,
    parameter = [],
    url = 'customer/customers/'
  } = kwargs


  log.info("Begin fetching customer.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/customer\/customers\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch customers
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching customers for URL", fetchUrl, ", Response ->", response)
    const customers = response.results.map((c: any) => createCustomer(c))
    const count = response.count
    log.debug("Fetched customers", customers)

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchCustomers({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(customers, count)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching customers", error)
    onError(error)
  }
}

export async function fetchCustomer(
  url: string,
  onSuccess: (response: Customer) => void,
  onError: (error: any) => void,
  parameter: string[] = [],
) {
  log.info('Begin fetching supplier.')

  const fetchUrl = url + buildFetchUrl('', parameter)
  const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      fetchUrl,
  )

  if(!error && response) {
      log.info('Successfully fetched supplier.')
      const supplier = createCustomer(response)
      onSuccess(supplier)
  } else {
      log.error('Error fetching supplier.', error)
      onError(error)
  }
}

/**
 * Fetch sharepoint data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchCustomerOrders(
  kwargs: {
    onSuccess: (response: CustomerOrders[]) => void,
    onFinal?: () => void,
    onError?: (error: any) => void,
    parameter?: string[],
    url?: string}
): Promise<void>
{
  // getting kwargs and setting defaults
  const {
    onSuccess,
    onFinal = () => null,
    onError = (error: any) => null,
    parameter = [],
    url = 'customer/customer_orders/'
  } = kwargs


  log.info("Begin fetching customer orders.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/customer\/customer_orders\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch customers
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching customer orders for URL", fetchUrl, ", Response ->", response)
    const customers = response.results.map((c: any) => createCustomer(c))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchCustomerOrders({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(customers)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching customer orders", error)
    onError(error)
  }
}

export async function postCustomer(
  customer: Customer,
  onSuccess: (customer: Customer) => void,
  onError: (error: any) => void,
) {
  log.info('Begin posting customer.')
  if(!customer) return
  const json = formInputToAPICustomerJSON(customer)
  const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'customer/customers/',
      undefined,
      json
  )

  if(!error && response) {
      log.info('Successfully posted customer.')
      fetchCustomer(
        response.self,
        (customer: Customer) => onSuccess(customer),
        (error: any) => onError(error),
        ["expand=contacts"]
      )
  } else {
      log.error('Error posting customer.', error)
      onError(error)
  }
}

export async function updateCustomer(
  customer: Customer,
  onSuccess: (customer: Customer) => void,
  onError: (error: any) => void,
) {
  log.info('Begin updating customer.')
  if(!customer) return
  const json = formInputToAPICustomerJSON(customer)
  const [response, error] = await handleAPICallV1(
      HTTPMethod.PUT,
      customer.self,
      undefined,
      json
  )

  if(!error && response) {
      log.info('Successfully updated customer.')
      const customer = createCustomer(response)
      onSuccess(customer)
  } else {
      log.error('Error updating customer.', error)
      onError(error)
  }
}

export async function deleteCustomers(
  customers: Customer[],
  onSuccess: (customers: Customer[]) => void,
  onError: () => void,
) {
  log.info('Begin deleting customers.')
  if(customers.length === 0) return
  const promises = customers.map((customer: Customer) => deleteCustomer(customer.self))
  const results = await Promise.all(promises)
  if(results.every((result: any) => result === true)) {
      onSuccess(customers)
  } else {
      onError()
  }
}

async function deleteCustomer(customer: string) {
  const [response, error] = await handleAPICallV1(
      HTTPMethod.DELETE,
      customer,
      undefined,
      undefined,
      'text'
    )
    if (error) {
      log.error('Error deleting customer.', error)
      return false
    } else {
      log.info('Successfully deleted customer.')
      return true
    }
}