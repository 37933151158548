/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedContractFileList,
    APIPaginatedContractFileListFromJSON,
    APIPaginatedContractFileListToJSON,
} from '../models';

export interface V1ContractContractsFilesListRequest {
    contractPk: number;
    page?: number;
    pageSize?: number;
}

export interface V1ContractFilesListRequest {
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class ContractcontractFileApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesListRaw(requestParameters: V1ContractContractsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedContractFileList>> {
        if (requestParameters.contractPk === null || requestParameters.contractPk === undefined) {
            throw new runtime.RequiredError('contractPk','Required parameter requestParameters.contractPk was null or undefined when calling v1ContractContractsFilesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{contract_pk}/files/`.replace(`{${"contract_pk"}}`, encodeURIComponent(String(requestParameters.contractPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedContractFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesList(requestParameters: V1ContractContractsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedContractFileList> {
        const response = await this.v1ContractContractsFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesListRaw(requestParameters: V1ContractFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedContractFileList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/files/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedContractFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesList(requestParameters: V1ContractFilesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedContractFileList> {
        const response = await this.v1ContractFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
