/* -------------------------- Design imports start -------------------------- */
import Page from '../../components/layout/Page'
import { Box } from '@mui/system'
import { Grid, TextField, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/logo_black.svg'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function PasswordResetPage() {
  /* -------------------------- Non state data start -------------------------- */
  const isValidEmail = (email: string) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return email && emailRegex.test(email)
  }
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [newCodeSent, setNewCodeSent] = useState(false)
  const [error, setError] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const { t } = useTranslation(['common'])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    setError(false)
  }, [email])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  async function sendToken(event: any) {
    event.preventDefault()
    if (!isValidEmail(email)) {
      return
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'auth/email/request/password_reset/request/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
        },
        body: JSON.stringify({ email }),
      }
    )
    if (response.status === 200) {
      setNewCodeSent(true)
    } else {
      setError(true)
      setErrorMessage('Something went wrong. Please try again.')
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        mt={4}
        height="100vh"
      >
        <Grid
          container
          maxWidth={600}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} display="flex" justifyContent="center">
            <img
              src={logo}
              alt="logo"
              style={{ width: '200px', height: '100px', marginBottom: '-30px' }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography display="block" variant="h5">
              {t('content.auth.resetPassword')}
            </Typography>
          </Grid>
          {!newCodeSent ? (
            <>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography display="block" variant="body1">
                  {t('content.auth.enterEmailAndWeWillSendYouAVerificationCode')}
                </Typography>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                  id="email"
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                  error={error}
                  helperText={error && errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onKeyDown={e => e.key === 'Enter' && sendToken}
                  onClick={sendToken}
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!isValidEmail(email)}
                >
                  {t('content.auth.sendVerificationCode')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => history.push('/signIn')}
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  {t('content.auth.backToSignIn')}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography display="block" variant="body1">
                  {t('content.auth.checkYourEmailForTheVerificationLink')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => history.push('/signIn')}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  {t('content.auth.backToSignIn')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Page>
  )
}
