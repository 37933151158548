/* -------------------------- Design imports start -------------------------- */
import RequestForm from '../../components/widgets/RequestForm'
import Page from '../../components/layout/Page'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function UnauthRequestPage() {
  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <RequestForm />
    </Page>
  )
}
