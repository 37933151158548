/* -------------------------- Design imports start -------------------------- */
import { Box, Button, Grid, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import GetConstant from '../utils/constants'
import { Form } from '../../Form'
import FileInput from '../../../components/inputs/FileInput'
import InputFileList from '../../../components/widgets/InputFileList'
import { FileInfo, FileUploadStatus } from '../../../utils/types'
import { deleteFile, uploadFile } from '../../../utils/functions'
import LoadingButton from '@mui/lab/LoadingButton'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onEditConfirm?: (formState: any) => Promise<string>
}

/* -------------------------------------------------------------------------- */
/*                                Start Component                             */
/* -------------------------------------------------------------------------- */
export default function RequestStaffDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    onEditConfirm = () => null,
  } = props
  const { t } = useTranslation(['request', 'common'])
  const log = new LogTool({ context: 'RequestStaffDrawer', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  const [waiting, setWaiting] = useState(false)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (fileInput.length > 0 && fileInput.every(file => file.uploadStatus === 'success')) {
      setWaiting(false);
      setOpen(false);
    }
  }, [fileInput])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleEditConfirm = async () => {
    log.debug('handleEditRequest -> ', formState.state)
    setWaiting(true)
    // set loading state on all files to loading
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    // posting request to server and waiting on response
    const requestFilesUrl = await onEditConfirm(formState.state)

    log.debug('requestFilesUrl -> ', requestFilesUrl)
    // check if the user specified files to upload !
    if (fileInput.length > 0 && requestFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of fileInput) {
        uploadRequestFile(file, requestFilesUrl)
        // response.articleFilesUrl cannot be undefined here as the server created the article successfully and therefore created an article files url

        // store the articleFilesUrl in the inputFiles state to have it available in case something goes wrong
        /* setInputFiles((prev: FileInfo[]) => prev.map(
          file => Object.assign(file, {uploadUrl: response.articleFilesUrl})
        )) */
      }
    } else {
      // if no files are uploaded, we can set the waiting state to false and close the drawer
      setWaiting(false)
      setOpen(false)
    }
  }

  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadRequestFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileUploadStatus(file, 'success')
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }
  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadRequestFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const requestStaffFormFields: any = GetConstant({ name: 'requestFormFieldsStaff' })
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        size="big"
        title={t('request:content.label.editRequest') || ''}
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Button
              id="cancelButton"
              onClick={() => setOpen(false)}
              color="inherit"
              variant="contained"
              sx={{ mr: 1, textTransform: 'none' }}
            >
              {t('common:interaction.button.cancel')}
            </Button>
            <LoadingButton
              id="createButton"
              variant="contained"
              sx={{ marginLeft: '10px', color: 'whitesmoke', textTransform: 'none' }}
              loading={waiting}
              onClick={handleEditConfirm}
            >
              {t('common:interaction.button.save')}
            </LoadingButton>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form
              editing={true}
              formFields={requestStaffFormFields}
              formObject={formState.state}
              onChange={(value: any) => {
                formState.setState(value)
              }}
            />
            <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
              {t('common:content.label.documents')}
            </Typography>
            <FileInput
              inputState={{ state: fileInput, setState: setFileInput }}
              maxAllowedFiles={10}
            />
            {fileInput.length > 0 && (
              <InputFileList
                files={fileInput}
                noFilesInfo=""
                enableEditMode={false}
                onRemoveFile={handleRemoveFileFromList}
                onReUploadFile={handleReUploadFile}
                onDeleteFile={handleDeleteFile}
              />
            )}
          </Grid>
        </Grid>
      </MultilevelDrawer>
    </>
  )
}
