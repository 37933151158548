/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIContact,
    APIContactFromJSON,
    APIContactToJSON,
    APIPaginatedContactList,
    APIPaginatedContactListFromJSON,
    APIPaginatedContactListToJSON,
} from '../models';

export interface V1SupplierSuppliersContactsListRequest {
    suppliersPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1SupplierSuppliersContactsRetrieveRequest {
    id: number;
    suppliersPk: string;
}

/**
 * 
 */
export class SuppliersupplierContactsApi extends runtime.BaseAPI {

    /**
     */
    async v1SupplierSuppliersContactsListRaw(requestParameters: V1SupplierSuppliersContactsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedContactList>> {
        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersContactsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/contacts/`.replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedContactListFromJSON(jsonValue));
    }

    /**
     */
    async v1SupplierSuppliersContactsList(requestParameters: V1SupplierSuppliersContactsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedContactList> {
        const response = await this.v1SupplierSuppliersContactsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1SupplierSuppliersContactsRetrieveRaw(requestParameters: V1SupplierSuppliersContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersContactsRetrieve.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersContactsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     */
    async v1SupplierSuppliersContactsRetrieve(requestParameters: V1SupplierSuppliersContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1SupplierSuppliersContactsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
