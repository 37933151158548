/* -------------------------- Design imports start -------------------------- */
import { Autocomplete, Box, Modal, TextField, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Channel } from '../../utils/types'
import Button from '../../../../components/Button'
import { User } from '../../../../utils/types'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  addMemberModal: boolean
  setAddMemberModal: (modal: boolean) => void
  selectedUsers: any[]
  setSelectedUsers: (users: any[]) => void
  users: any[]
  channel: any
  handleAddMembers: (users: any[]) => void
}

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function AddMemberModal(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    addMemberModal,
    setAddMemberModal,
    selectedUsers,
    setSelectedUsers,
    users,
    channel,
    handleAddMembers,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['chat'])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------- Utility functions start ------------------------ */
  function userAlreadyInChannel(channel: Channel, user: User) {
    if (!channel || !channel.members) return false
    if (typeof channel.members !== 'string') {
      const channelUsers = channel.members.map((user: any) => user.self)
      return channelUsers.includes(user.self)
    } else {
      return false
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Modal
      open={addMemberModal}
      onClose={() => setAddMemberModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '4px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('content.label.addMemberModalTitle')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('content.label.addMemberModalDescription')}
        </Typography>
        <Box sx={{ marginTop: '10px' }}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={users.filter((user: any) => !userAlreadyInChannel(channel, user))}
            getOptionLabel={option => option.first_name && option.last_name ? `${option.first_name} ${option.last_name}` : option.email}
            filterSelectedOptions
            onChange={(event, value) => {
              setSelectedUsers(value)
            }}
            renderInput={params => (
              <TextField {...params} label={t('common:content.label.users')} />
            )}
          />
        </Box>
        <Button
          id="addMemberButton"
          variant="contained"
          sx={{
            marginTop: '10px',
            float: 'right',
            marginLeft: '10px',
          }}
          onClick={() => {
            handleAddMembers(selectedUsers.map((user: any) => user.self))
            setSelectedUsers([])
            setAddMemberModal(false)
          }}
        >
          {t('common:interaction.button.add')}
        </Button>
        <Button
          id="cancelButton"
          variant="outlined"
          color="primary"
          sx={{ marginTop: '10px', float: 'left', }}
          onClick={() => {
            setSelectedUsers([])
            setAddMemberModal(false)
          }}
        >
          {t('common:interaction.button.cancel')}
        </Button>
      </Box>
    </Modal>
  )
}
