/* -------------------------- Design imports start -------------------------- */
import MultilevelDrawer from '../../components/layout/MultilevelDrawer'
import { toast } from 'react-toastify'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from 'react-i18next'
import LogTool from '../../logger/logTools'
import { useEffect, useRef, useState } from 'react'
import GetConstant from './utils/constants'
import { Form, FormFieldType } from '../Form'
import {
  createAddress,
  fetchAddresses,
  formInputToAPIAddressJSON,
  getAddressRepresentation,
  handleAPICallV1,
  missingRequiredFormFields,
} from '../../utils/functions'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import AddressDrawer from '../Address/components/AddressDrawer'
import { Address, HTTPMethod, SelectOption } from '../../utils/types'
import { AddRounded, DeleteRounded } from '@mui/icons-material'
import SearchSelectInput from '../../components/inputs/SearchSelectInput'
import { set } from 'cypress/types/lodash'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onConfirm?: (input: any) => Promise<'SUCCESS' | 'ERROR'>
  enableEdit?: boolean
  onEditConfirm?: (input: any) => Promise<'SUCCESS' | 'ERROR'>
}

type AddressListFieldObject =  {
  key: string,
  type: 'addAddress' | 'selectAddress' | 'savedAddress',
  value: SelectOption | undefined
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function ContactDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'ContactDrawer', enable: true, logLevel: 'warn' })
  const {
    open,
    formState,
    setOpen,
    onClose = () => null,
    onConfirm = (input: any) => null,
    onEditConfirm = (input: any) => null,
    enableEdit = false,
  } = props

  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [prevOpen, setPrevOpen] = useState(false)
  const [inEditMode, setInEditMode] = useState(false)
  const [openAddressDrawer, setOpenAddressDrawer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const uniqueKeyStore = useRef(0)
  const addressStore = useRef<Address[]>([])
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([])
  const [addressListForm, setAddressListForm] = useState<AddressListFieldObject[]>([
    generateAddressFieldObject('selectAddress'),
    generateAddressFieldObject('addAddress'),
  ])
  // key of the AddressListFieldObject through that the addAddressOption callback was fired
  const [newAddressReceiver, setNewAddressReceiver] = useState<string | undefined>()
  /* ----------------------------- Data states end ---------------------------- */


  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    // Laden der Adressen beim Initialisieren der Komponente
    fetchAddresses({
      onSuccess: (addresses: Address[]) => {
        addressStore.current = [...addressStore.current, ...addresses]
        setAddressOptions(
          addresses.map((address: Address) => ({
            value: address.self,
            label: getAddressRepresentation(address, true, false),
          }))
        )
      },
    })
  }, [])

  // useEffect(() => {
  //   log.debug('formState.state.addresses change ->', formState.state?.addresses)


  // }, [formState.state?.addresses])
  /* ------------------------------- Effects end ------------------------------ */


  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Generates a unique element key (ascending number).
   * @returns Unique Key
   */
  function getUniqueKey(): string {
    const key = uniqueKeyStore.current
    uniqueKeyStore.current += 1
    return String(key)
  }
  /**
   * Creates a new, unique AddressListFieldObject that can safely be inserted into the addressListForm.
   */
  function generateAddressFieldObject(
    type: 'addAddress' | 'selectAddress' | 'savedAddress' = 'selectAddress',
    value?: SelectOption
  ): AddressListFieldObject {
    return {
      key: `address${getUniqueKey()}`,
      type: type,
      value: value,
    }
  }

  /**
   * Determines if there are any empty address select input fields.
   * @returns
   */
  function missingAddressSelectInput() {
    let missingInput = false
    addressListForm.forEach((fieldObj: AddressListFieldObject) => {
      if(fieldObj.type === 'selectAddress' && !Boolean(fieldObj.value)) {
        // the user did not select an address for this AddressListFieldObject yet
        missingInput = true
      }
    })
    return missingInput
    // const addressInputValues: SelectOption[] = Object.values(addressListFormInput)
    // if (addressListForm.length - 1 > addressInputValues.length) {
    //   // there is an addressFieldObject that has no selected address yet
    //   selectIsMissing = true
    // }
    // if (addressInputValues.length > 0) {
    //   for (const address of addressInputValues) {
    //     if (!address) {
    //       selectIsMissing = true
    //       break
    //     }
    //   }
    // }
    // return selectIsMissing
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = (preventOnClose: boolean = false) => {
    // reset modal to initial state
    formState.setState({})
    uniqueKeyStore.current = 0
    setAddressListForm([
      generateAddressFieldObject('selectAddress'),
      generateAddressFieldObject('addAddress'),
    ])
    setPrevOpen(false)
    setInEditMode(false)
    // setOpen(false)

    !preventOnClose && onClose()
  }

  /**
   * Callback that collects all inputs of the drawer and reaches them up to the
   * onCreateConfirm or onEditConfirm method depending on whether the drawer is
   * opened in edit mode or not.
   */
  const handleConfirm = async () => {
    // collect all inputs into one object
    const input = {
      ...formState.state,
      addresses: addressListForm
        .filter((fieldObj: AddressListFieldObject) => (
          fieldObj.type !== 'addAddress' && typeof fieldObj.value !== 'undefined'))
        .map((fieldObj: AddressListFieldObject) => fieldObj.value!.value)
    }

    // posting request to server and waiting on response
    let confirmStatus: string | null = ''
    if(inEditMode) {
      confirmStatus = await onEditConfirm(input)
    } else {
      confirmStatus = await onConfirm(input)
    }

    // provide visual feedback
    if(confirmStatus === 'SUCCESS') {
      if(inEditMode) {
        toast.success(t('common:feedback.success.editedContactSuccessfully'))
      } else {
        toast.success(t('common:feedback.success.createdContactSuccessfully'))
      }
    } else {
      if(inEditMode) {
        toast.error(t('common:feedback.error.editedContactError'))
      } else {
        toast.error(t('common:feedback.error.createdContactError'))
      }
    }
  }

  const handleAddAddressOption = async (addressFormInput: any): Promise<'SUCCESS' | 'ERROR'> => {
    log.info('Begin creating address option')
    const json = formInputToAPIAddressJSON(addressFormInput)
    log.debug('Input ->', addressFormInput, ', JSON ->', json)

    // create a new address
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'contacts/addresses/',
      undefined,
      json
    )

    if (!error && response) {
      log.info('Success creating address option')
      const newAddress = createAddress(response)

      // update addressStore
      addressStore.current = [...addressStore.current, newAddress]
      setAddressOptions(
        addressStore.current.map(addr => ({
          value: addr.self,
          label: getAddressRepresentation(addr, true, false),
        }))
      )

      setAddressListForm(prev => {
        const receiverFieldObj = prev.find((fieldObj: AddressListFieldObject) => fieldObj.key === newAddressReceiver)
        receiverFieldObj!.value = {value: newAddress.self, label: getAddressRepresentation(newAddress, true, false)}
        return [...prev]
      })

      setNewAddressReceiver(undefined)
      return 'SUCCESS'
    } else {
      log.error('Error creating address option', error)
      return 'ERROR'
    }
  }

  const handleAddAddressToList = () => {
    setAddressListForm((prev: any) => {
      const fieldObjIndex: number = prev.length - 1
      prev[fieldObjIndex] = generateAddressFieldObject()
      return [...prev, generateAddressFieldObject('addAddress')]
    })
  }

  const handleRemoveAddressFromList = (addrFieldObj: any) => {
    log.debug('handleRemoveAddressFromList', addrFieldObj)
    setAddressListForm((prev: any) => {
      const i = prev.indexOf(addrFieldObj)
      // remove addressFieldObject
      prev.splice(i, 1)

      return [...prev]
    })
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const formFieldsContact = GetConstant({ name: 'contactFormFields' }) as FormFieldType[]

  // filter the addressOptions to make it harder to select the same address twice
  const selectedAddressSelfs: string[] = addressListForm
    .filter((fieldObj: AddressListFieldObject) => fieldObj.type !== 'addAddress' && Boolean(fieldObj.value))
    .map((fieldObj: AddressListFieldObject) => fieldObj.value!.value)
  const filteredAddressOptions = addressOptions
    .filter((addressOption: SelectOption) => !selectedAddressSelfs.includes(addressOption.value))

  const renderAddressFieldObject = (addrFieldObj: any) => {
    if (addrFieldObj.type === 'addAddress') {
      return (
        <ListItem key={addrFieldObj.key}>
          <ListItemIcon>
            <Tooltip title={t('common:content.label.addAddress')} placement="right" arrow>
              <Button
                startIcon={<AddRounded style={{ fontSize: '25px', color: 'GrayText' }} />}
                onClick={handleAddAddressToList}
                style={{ fontSize: '16px', color: 'black' }}
              >
                {t('common:content.label.addAddress')}
              </Button>
            </Tooltip>
          </ListItemIcon>
        </ListItem>
      )
    } else if (addrFieldObj.type === 'savedAddress') {
      return (
        <ListItem key={addrFieldObj.key}>
          <ListItemIcon>
            <IconButton onClick={() => handleRemoveAddressFromList(addrFieldObj)}>
              <DeleteRounded />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={addrFieldObj.value.label} />
        </ListItem>
      )
    } else if(addrFieldObj.type === 'selectAddress') {
      return (
        <ListItem key={addrFieldObj.key}>
          <ListItemIcon>
            <IconButton onClick={() => handleRemoveAddressFromList(addrFieldObj)}>
              <DeleteRounded />
            </IconButton>
          </ListItemIcon>
          <ListItemText>
            <SearchSelectInput
              name={t('common:content.label.address')}
              fullWidth
              value={addrFieldObj.value}
              options={filteredAddressOptions}
              onChange={(input: any) => {
                // update field object
                addrFieldObj.value = input
                // trigger rerender
                setAddressListForm(prev => [...prev])
              }}
              onAddOption={() => {
                setNewAddressReceiver(addrFieldObj.key)
                setOpenAddressDrawer(true)
              }}
            />
          </ListItemText>
        </ListItem>
      )
    }
  }
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */
  if(open && !prevOpen) {
    setPrevOpen(true)

    // if the formState is populated while opening the drawer we need to enable edit mode
    if(typeof formState.state !== 'undefined' && Object.keys(formState.state).length > 0) {
      setInEditMode(true)

      // initialize addressListForm
      if(Array.isArray(formState.state.addresses) && formState.state.addresses.length > 0) {
        setAddressListForm([
          ...formState.state.addresses.map((addr: Address) => (
            generateAddressFieldObject(
              'savedAddress',
              {value: addr.self, label: getAddressRepresentation(addr, true, false)}
            )
          )),
          generateAddressFieldObject('addAddress')
        ])
      }
    }
  }
  /* ------------------------- Pre render actions end ------------------------- */

  log.debug(
    'formState ->',
    formState.state,
    ', addressListForm ->',
    addressListForm,
    ', selectedAddresses ->',
    selectedAddressSelfs,
  )

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        size="big"
        title={
          inEditMode
            ? (t('common:content.label.editContact') as string)
            : (t('common:content.label.addContact') as string)
        }
        onClose={handleClose}
        confirmButtonProps={{
          text: inEditMode
            ? t('common:interaction.button.save')
            : t('common:interaction.button.add'),
          onConfirm: handleConfirm,
          disabled:
            missingRequiredFormFields([...formFieldsContact], formState.state) ||
            missingAddressSelectInput()
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('customer:content.label.contactData')}
        </Typography>
        <Grid spacing={2} container>
          <Form
            editing={true}
            formFields={formFieldsContact}
            formObject={formState.state}
            onChange={(value: any) => formState.setState(value)}
          />
        </Grid>
        <Typography sx={{ mt: 4 }} variant="h6">
          {t('common:content.label.address')}
        </Typography>
        <List>
          {addressListForm.map((addrFieldObj: any) => renderAddressFieldObject(addrFieldObj))}
        </List>
      </MultilevelDrawer>
      <AddressDrawer
        open={openAddressDrawer}
        setOpen={setOpenAddressDrawer}
        formState={{
          state: formState.state,
          setState: formState.setState,
        }}
        onConfirm={(input: any) => handleAddAddressOption(input)}
      />
    </>
  )
}
