/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIFeedbackFormField,
    APIFeedbackFormFieldFromJSON,
    APIFeedbackFormFieldFromJSONTyped,
    APIFeedbackFormFieldToJSON,
} from './APIFeedbackFormField';

/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIFeedbackForm
 */
export interface APIFeedbackForm {
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackForm
     */
    readonly self: string;
    /**
     * 
     * @type {Array<APIFeedbackFormField>}
     * @memberof APIFeedbackForm
     */
    formJson: Array<APIFeedbackFormField>;
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackForm
     */
    formTitle: string;
}

export function APIFeedbackFormFromJSON(json: any): APIFeedbackForm {
    return APIFeedbackFormFromJSONTyped(json, false);
}

export function APIFeedbackFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIFeedbackForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'formJson': ((json['form_json'] as Array<any>).map(APIFeedbackFormFieldFromJSON)),
        'formTitle': json['form_title'],
    };
}

export function APIFeedbackFormToJSON(value?: APIFeedbackForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_json': ((value.formJson as Array<any>).map(APIFeedbackFormFieldToJSON)),
        'form_title': value.formTitle,
    };
}

