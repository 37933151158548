import { useTranslation } from "react-i18next";
import { FormFieldType } from "../../Form";
import { ColumnProps } from "antd/es/table";
import { Chip, Grid } from "@mui/material";
import { loadAllStaffUsers } from "../../../utils/functions";
import { GuestRequest } from "./types";
import { SelectOption } from "../../../utils/types";

type Props = {
    name: 'guestRequestTableColumns'
        | 'guestRequestFormFields'
        | 'guestRequestEditFormFields'
} & (guestRequestTableColumnsKwargs | guestRequestFormFieldsKwargs | guestRequestEditFormFieldsKwargs)

type guestRequestTableColumnsKwargs = {
    name: 'guestRequestTableColumns'
    newGuestRequests?: GuestRequest[]
}

type guestRequestFormFieldsKwargs = {
    name: 'guestRequestFormFields'
}

type guestRequestEditFormFieldsKwargs = {
    name: 'guestRequestEditFormFields',
}

export default function GetConstant(props: Props) : FormFieldType[] | ColumnProps<any>[] {
    const { t } = useTranslation(['common', 'request']);

    if(props.name === 'guestRequestTableColumns'){
        return [
            {
                title: "",
                dataIndex: 'read',
                key: 'read',
                render: (read: boolean, record: GuestRequest) => {
                    const newGuestRequest = props.newGuestRequests?.find(guestRequest => guestRequest.key === record.key)
                    if(!newGuestRequest) return
                    if(newGuestRequest && newGuestRequest.read === true) return
                    if (newGuestRequest && newGuestRequest.read === false) {
                        return (
                            <Grid container justifyContent="center">
                                <Grid item>
                                    {newGuestRequest && newGuestRequest.read === false
                                    && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                }
            },
            {
                title: t("request:content.label.name"),
                dataIndex: "name",
                key: "name",
            },
            {
                title: t("request:content.label.email"),
                dataIndex: "email",
                key: "email",
            },
            {
                title: t("request:content.label.company"),
                dataIndex: "company",
                key: "company",
            },
            {
                title: t("request:content.label.address"),
                dataIndex: "address",
                key: "address",
            },
            {
                title: t("request:content.label.zipCode"),
                dataIndex: "zipCode",
                key: "zipCode",
            },
            {
                title: t("request:content.label.city"),
                dataIndex: "city",
                key: "city",
            },
            {
                title: t("request:content.label.country"),
                dataIndex: "country",
                key: "country",
            },
            {
                title: t("request:content.label.phone"),
                dataIndex: "phone",
                key: "phone",
            },
            {
                title: t("request:content.label.quantity"),
                dataIndex: "quantity",
                key: "quantity",
            },
            {
                title: t("request:content.label.description"),
                dataIndex: "message",
                key: "description",
            },
            {
                title: t("common:content.label.status"),
                dataIndex: "status",
                key: "status",
            },
            {
                title: t("common:content.label.createdAt"),
                dataIndex: "createdAt",
                key: "createdAt",
            },
            {
                title: t('common:content.label.editors'),
                key: 'editors',
                dataIndex: 'editors',
                render: (text: string, record: GuestRequest) => {
                    if (Array.isArray(record.editors)) {
                      return (
                        <>
                          {record.editors.map((e: any) => (
                            <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                          ))}
                        </>
                      )
                    } else {
                      return ''
                    }
                  },
            },
        ] as ColumnProps<any>[]
    }
    if(props.name === 'guestRequestFormFields'){
        return [
            {
                type: 'text',
                key: 'name',
                label: t('request:content.label.name'),
                required: true,
                validation: true,
                showError: false,
                errorMessage: t('request:feedback.error.shouldNotBeEmpty'),
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            },
            {
                type: 'email',
                key: 'email',
                label: t('request:content.label.email'),
                required: true,
                validation: true,
                showError: false,
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            },
            {
                type: 'text',
                key: 'company',
                label: t('request:content.label.company'),
                required: true,
                validation: true,
                showError: false,
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            },
            {
                type: 'text',
                key: 'phone',
                label: t('request:content.label.phone'),
                required: true,
                validation: true,
                showError: false,
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            },
            {
                type: 'text',
                key: 'address',
                label: t('request:content.label.address'),
                required: false,
                validation: true,
                showError: false,
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12,
                xs: 12,
            },
            {
                type: 'number',
                key: 'zipCode',
                label: t('request:content.label.zipCode'),
                required: false,
                validation: true,
                showError: false,
                xl: 6,
                lg: 6,
                md: 6,
                sm: 12,
                xs: 12,
            },
            {
                type: 'text',
                key: 'city',
                label: t('request:content.label.city'),
                required: false,
                validation: true,
                showError: false,
                xl: 6,
                lg: 6,
                md: 6,
                sm: 12,
                xs: 12,
            },
            {
                type: 'text',
                key: 'country',
                label: t('request:content.label.country'),
                required: false,
                validation: true,
                showError: false,
                xl: 8,
                lg: 8,
                md: 8,
                sm: 12,
                xs: 12,
            },
            {
                type: 'number',
                key: 'quantity',
                label: t('request:content.label.quantity'),
                validation: true,
                showError: false,
                xl: 4,
                lg: 4,
                md: 4,
                sm: 12,
                xs: 12,
            },
            {
                type: 'multiline',
                key: 'message',
                label: t('request:content.label.description'),
                required: false,
                validation: false,
                showError: false,
                xl: 12,
                lg: 12,
                md: 12,
                sm: 12,
                xs: 12,
            },

        ] as FormFieldType[]
    }
    if(props.name === 'guestRequestEditFormFields'){
        return [
            {
                type: 'text',
                key: 'status',
                label: t('common:content.label.status'),
                required: false,
                validation: false,
                showError: false,
                suggestions: [
                    { value: 'pending', label: t('common:content.label.pending') },
                    { value: 'approved', label: t('common:content.label.approved') },
                    { value: 'rejected', label: t('common:content.label.rejected') },
                ],
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'select-search-multi',
                key: 'editors',
                label: t('common:content.label.editors'),
                options: [] as SelectOption[],
                loadData: loadAllStaffUsers,
                required: false,
                validation: false,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            }
        ] as FormFieldType[]
    }

    return [] as FormFieldType[] | ColumnProps<any>[];
}