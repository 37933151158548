export function timePrepandZero(x: number): string {
    if(Number(x) < 10) {
        return `0${x}`
    } else {
        return `${x}`
    }
}

export function getTimeString(date: Date): string {
    const hours: string = timePrepandZero(date.getHours())
    const minutes: string = timePrepandZero(date.getMinutes())
    const seconds: string = timePrepandZero(date.getSeconds())
    return `${hours}:${minutes}:${seconds}`
}

export function parseDisableLoggingEnvVar(envVar: string | undefined): boolean {
    if(typeof envVar === 'undefined') {
        return true
    }
    if(envVar?.match(/true/i)) {
        return true
    }
    return false
}