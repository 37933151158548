/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIBillFile,
    APIBillFileFromJSON,
    APIBillFileToJSON,
    APIPaginatedBillFileList,
    APIPaginatedBillFileListFromJSON,
    APIPaginatedBillFileListToJSON,
} from '../models';

export interface V1ContractBillFilesCreateRequest {
    bill: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillFilesDestroyRequest {
    id: number;
}

export interface V1ContractBillFilesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ContractBillFilesPartialUpdateRequest {
    id: number;
    bill?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillFilesRetrieveRequest {
    id: number;
}

export interface V1ContractBillFilesUpdateRequest {
    id: number;
    bill: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillsFilesCreateRequest {
    billPk: number;
    bill: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillsFilesDestroyRequest {
    billPk: number;
    id: number;
}

export interface V1ContractBillsFilesListRequest {
    billPk: number;
    page?: number;
    pageSize?: number;
}

export interface V1ContractBillsFilesPartialUpdateRequest {
    billPk: number;
    id: number;
    bill?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillsFilesRetrieveRequest {
    billPk: number;
    id: number;
}

export interface V1ContractBillsFilesUpdateRequest {
    billPk: number;
    id: number;
    bill: string;
    key?: string | null;
    document?: Blob | null;
}

/**
 * 
 */
export class ContractbillFileApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesCreateRaw(requestParameters: V1ContractBillFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.bill === null || requestParameters.bill === undefined) {
            throw new runtime.RequiredError('bill','Required parameter requestParameters.bill was null or undefined when calling v1ContractBillFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bill_files/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesCreate(requestParameters: V1ContractBillFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesDestroyRaw(requestParameters: V1ContractBillFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesDestroy(requestParameters: V1ContractBillFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContractBillFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesListRaw(requestParameters: V1ContractBillFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedBillFileList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bill_files/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedBillFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesList(requestParameters: V1ContractBillFilesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedBillFileList> {
        const response = await this.v1ContractBillFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesPartialUpdateRaw(requestParameters: V1ContractBillFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesPartialUpdate(requestParameters: V1ContractBillFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesRetrieveRaw(requestParameters: V1ContractBillFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesRetrieve(requestParameters: V1ContractBillFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesUpdateRaw(requestParameters: V1ContractBillFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesUpdate.');
        }

        if (requestParameters.bill === null || requestParameters.bill === undefined) {
            throw new runtime.RequiredError('bill','Required parameter requestParameters.bill was null or undefined when calling v1ContractBillFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesUpdate(requestParameters: V1ContractBillFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesCreateRaw(requestParameters: V1ContractBillsFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesCreate.');
        }

        if (requestParameters.bill === null || requestParameters.bill === undefined) {
            throw new runtime.RequiredError('bill','Required parameter requestParameters.bill was null or undefined when calling v1ContractBillsFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesCreate(requestParameters: V1ContractBillsFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesDestroyRaw(requestParameters: V1ContractBillsFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesDestroy(requestParameters: V1ContractBillsFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContractBillsFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesListRaw(requestParameters: V1ContractBillsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedBillFileList>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedBillFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesList(requestParameters: V1ContractBillsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedBillFileList> {
        const response = await this.v1ContractBillsFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesPartialUpdateRaw(requestParameters: V1ContractBillsFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesPartialUpdate(requestParameters: V1ContractBillsFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesRetrieveRaw(requestParameters: V1ContractBillsFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesRetrieve(requestParameters: V1ContractBillsFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesUpdateRaw(requestParameters: V1ContractBillsFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesUpdate.');
        }

        if (requestParameters.bill === null || requestParameters.bill === undefined) {
            throw new runtime.RequiredError('bill','Required parameter requestParameters.bill was null or undefined when calling v1ContractBillsFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesUpdate(requestParameters: V1ContractBillsFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
