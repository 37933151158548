/* ------------------------ Functional imports start ------------------------ */
import React, { createContext, useContext, useState, ReactNode } from 'react'
/* ------------------------- Functional imports end ------------------------- */

interface JoyrideState {
  run: boolean
  stepIndex: number
}

interface JoyrideContextProps {
  joyrideState: JoyrideState
  runJoyride: boolean
  startJoyride: () => void
  nextStep: () => void
  resetJoyride: () => void
}

const JoyrideContext = createContext<JoyrideContextProps | undefined>(undefined)

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export const JoyrideProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [joyrideState, setJoyrideState] = useState<JoyrideState>({
    run: false,
    stepIndex: 0,
    // ... weitere Joyride-Statusinformationen hier
  })

  const startJoyride = () => {
    setJoyrideState(prevState => ({ ...prevState, run: true }))
  }

  const nextStep = () => {
    setJoyrideState(prevState => ({ ...prevState, stepIndex: prevState.stepIndex + 1 }))
  }

  const resetJoyride = () => {
    setJoyrideState({ run: false, stepIndex: 0 })
  }

  const runJoyride = joyrideState.run

  return (
    <JoyrideContext.Provider
      value={{ joyrideState, startJoyride, nextStep, resetJoyride, runJoyride }}
    >
      {children}
    </JoyrideContext.Provider>
  )
}

export const useJoyride = (): JoyrideContextProps => {
  const context = useContext(JoyrideContext)
  if (!context) {
    throw new Error('useJoyride must be used within a JoyrideProvider')
  }
  return context
}
