/* -------------------------- Design imports start -------------------------- */
import { TextField, Grid, Typography, Autocomplete } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPKfromSelf, handleAPICallV1 } from '../../../../utils/functions'
import { HTTPMethod } from '../../../../utils/types'
import { toast } from 'react-toastify'
import LogTool from '../../../../logger/logTools'
import { Channel } from '../../utils/types'
import { useWebSocket } from '../../../../WebSocketProvider'
import { useChatContext } from '../../utils/context'
import { useUserContext } from '../../../../utils/context'
import Button from '../../../../components/Button'
/* ------------------------- Functional imports end ------------------------- */
/**
 * Page that lets you create a new channel
 *  - Reference
 *  - Product
 *  - To: (Receiver (only for admin))
 */
/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function NewChannel() {
  /* -------------------------- Non state data start -------------------------- */
  const {
    //activeUser,
    fetchChannels,
    setChannels,
    activeChannel,
    setActiveChannel
  } = useChatContext()
  const log = new LogTool({context: 'NewChannel', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['chat', 'common'])
  const { socket } = useWebSocket()
  const { user } = useUserContext()
  const [users, setUsers] = useState<any[]>([])
  const [subject, setSubject] = useState<string>('')
  const [members, setMembers] = useState<any[]>([])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchUsers()
  }, [])

  log.info('NewChannel rendered')
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  function userAlreadyInChannel(channel: any, userInChannel: any) {
    if(!channel && user) {
      return userInChannel.self === user.self
    }
    if (!channel || !channel.users) return false
    const channelUsers = channel.users.map((user: any) => user.self)
    return channelUsers.includes(userInChannel.self)
  }

  async function fetchUsers() {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'accounts/users/?expand=roles__sub_roles&exclude=roles__self',
      undefined,
      undefined
    )
    if (response) {
      setUsers(response.results)
    } else {
      log.error('Error fetching users', error)
    }
  }

  const createNewChat = async () => {
    const [response, error] = await handleAPICallV1(HTTPMethod.POST, 'chat/channel/', undefined, {
      subject: subject,
    })
    if (!error) {
      const result: boolean = await addMembers(response)
      if (result) {
        toast.success(t('common:feedback.success.chatCreatedSuccessfully'))
      } else {
        toast.error(t('common:feedback.error.chatCreatedButMembersFailed'))
      }
      log.info('Chat created', response)
    } else {
      log.error('Error creating chat', error)
      toast.error(t('common:feedback.error.chatCreatedError'))
    }
    setSubject('')
    setMembers([])
    fetchChannels({
      onSuccess: (channels: Channel[]) => {
        handleChannelsUpdate(channels, response)
      },
      parameter: ["expand=members,creator,messages"]
    })
  }

  const addMembers = async (channel: any) => {
    const results: any[] = []
    members.forEach(async (member: any) => {
      const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        channel.memberships,
        undefined,
        {
          user: member.self,
        },
        'text'
      )
      if (!error) {
        results.push(true)
      } else {
        log.error('Error adding member', error)
        results.push(false)
      }
    })
    return !results.includes(false)
  }

  /* -------------------------- Utility functions end ------------------------- */
  /* ------------------------ Helper functions start ------------------------ */
  function handleChannelsUpdate(channels: Channel[], response: any) {
    let newChannel : Channel | undefined = channels.find((channel: Channel) => channel.self === response.self)
    if(newChannel && typeof newChannel.members !== 'string'){
      newChannel = {...newChannel, members: [...newChannel.members, ...members]}
    }
    const newChannels : (Channel | undefined)[] = channels.map((channel: Channel) => {
      if(channel.self === response.self){
        return newChannel
      } else {
        return channel
      }
    })
    setChannels(newChannels as Channel[])
    setActiveChannel(newChannel as Channel)
  }

  /* ------------------------- Helper functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t('content.label.newChat')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="channel-subject"
          label={t('content.label.subject')}
          variant="outlined"
          fullWidth
          value={subject}
          onChange={event => setSubject(event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={users.filter((user: any) => !userAlreadyInChannel(activeChannel, user))}
          getOptionLabel={option => option.first_name && option.last_name ? `${option.first_name} ${option.last_name}` : option.email}
          filterSelectedOptions
          onChange={(event, value) => {
            setMembers(value)
          }}
          renderInput={params => <TextField {...params} label={t('common:content.label.users')} />}
        />
      </Grid>
      <Grid item xs={12}>
        <Button id="createNewChatButton" variant="contained" onClick={createNewChat} sx={{ float: 'right' }}>
          {t('interaction.button.createNewChat')}
        </Button>
      </Grid>
    </Grid>
  )
}
