/*import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import Page from '../components/layout/Page'
import FAB from '../components/widgets/FAB'
import useMobileService from '../hooks/useMobileService'
import { useTranslation } from 'react-i18next'
import { Form, FormFieldType } from '../features/Form'
import InfoPanel from '../components/layout/InfoPanel'
import ColumnSelector from '../components/widgets/ColumnSelector'
import { handleAPICallV1 } from '../utils/functions'
import { HTTPMethod } from '../utils/types'
import LoadingSkeleton from '../components/widgets/LoadingSkeleton'
import LogTool from '../logger/logTools'
import InfoField from '../components/inputs/InfoField'

interface Permission {
  key: string
  name: string
  action?: string
  view?: string
  description: string
  comment?: string
}


*/
export default function PermissionsPage() {
  /*
  const { t } = useTranslation(['permission'])
  const log = new LogTool({ context: 'PermissionsPage', enable: true })
  const isMobile = useMobileService()
  let size
  isMobile ? (size = { height: '40%', width: '95%' }) : (size = { height: '50%', width: '40%' })
  const formFields: FormFieldType[] = [
    {
      type: 'text',
      key: 'name',
      label: t('common:content.label.name'),
    },
    {
      type: 'text',
      key: 'description',
      label: t('common:content.label.description'),
    },
  ]
  const columns: ColumnsType<Permission> = [
    {
      title: t('common:content.label.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('common:content.label.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('common:content.label.action'),
      dataIndex: 'action',
      key: 'action',
    },

    {
      title: t('common:content.label.view'),
      dataIndex: 'view',
      key: 'view',
    },
    {
      title: t('common:content.label.comment'),
      dataIndex: 'comment',
      key: 'comment',
    },
  ]
  /* ---------------------------------------------states start--------------------------------------------- */
  /*
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [newPermission, setNewPermission] = useState<Permission>()
  const [open, setOpen] = useState(false)
  const [openInfoPanel, setOpenInfoPanel] = useState(false)
  const [view, setView] = React.useState<string>('')
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    columns.map(c => c.key?.toString() ?? '')
  )
  const [action, setAction] = React.useState<string>('')
  const [clickedRecord, setClickedRecord] = useState<any>()
  /* ---------------------------------------------states end----------------------------------------------- */
  /*-------------------------------------------variables start--------------------------------------------- */
  //const filteredColumns = columns.filter(c => visibleColumns.includes(c.key?.toString() ?? ''))
  /*--------------------------------------------variables end---------------------------------------------- */
  /* -------------------------------------------useEffects start------------------------------------------- */
  /*useEffect(() => {
    fetchPermissions()
  }, [])

  /* --------------------------------------------useEffects end-------------------------------------------- */
  /* ----------------------------------------async functions start----------------------------------------- */
  /* async function fetchPermissions() {
    setLoading(true)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'general/permissions/',
      undefined,
      undefined
    )
    if (error) {
      log.error('fetch permissions error: ', error)
      setLoading(false)
      return
    }
    const permissions = response.permissions
      .map((p: any, index: number) => ({
        key: index.toString(),
        name: p.name,
        description: p.description,
        action: p.name.split(':')[1],
        view: p.name.split(':')[0],
        comment: p?.comment,
      }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
    setPermissions(permissions)
    setLoading(false)
  }

  /* -----------------------------------------async functions end------------------------------------------ */
  /* -------------------------------------------functions start-------------------------------------------- */
  /*
  if (loading) {
    return <LoadingSkeleton pagetitle={t('common:content.label.permissions')} />
  }

  const handleCancel = () => {
    setNewPermission(undefined)
    setOpen(false)
  }

  const handleConfirm = () => {
    setPermissions([...permissions, newPermission!])
    setNewPermission(undefined)
    setOpen(false)
  }

  const handleClose = () => {
    setNewPermission(undefined)
    setOpen(false)
  }

  const handleChangeView = (event: SelectChangeEvent<typeof view>) => {
    setView(String(event.target.value) || '')
  }

  const handleChangeAction = (event: SelectChangeEvent<typeof action>) => {
    setAction(String(event.target.value) || '')
  }

  const handleColumnChange = (checkedColumns: string[]) => {
    setVisibleColumns(checkedColumns)
  }
  /* -------------------------------------------functions end-------------------------------------------- */
  /*
  return (
    <Page title={t('common:content.label.permissions')}>
      <ColumnSelector
        columns={columns as Array<{ key: string; title: string }>}
        onChangeColumns={handleColumnChange}
      />
      <Table
        columns={filteredColumns}
        dataSource={permissions}
        /* style={{ zIndex: 100 }} */
  /*       onRow={r => ({
          onClick: () => {
            setOpenInfoPanel(true)
            setClickedRecord(r)
          }
        })}
      />
      <InfoPanel
        open={openInfoPanel}
        setOpen={setOpenInfoPanel}
        allowEdit={false}
        label={t("common:content.label.permissionInformation")}
        disabled={disabled}
      >
        <Grid container spacing={2} style={{ padding: '20px' }}>
          <Grid item xs={12}>
            <InfoField
              label={t("common:content.label.name")}
              value={clickedRecord?.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t("common:content.label.description")}
              value={clickedRecord?.description}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t("common:content.label.action")}
              value={clickedRecord?.action}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t("common:content.label.view")}
              value={clickedRecord?.view}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t("common:content.label.comment")}
              value={clickedRecord?.comment}
              fullWidth
            />
          </Grid>s
        </Grid>
      </InfoPanel>
      <FAB type={'ADD'} onClick={() => setOpen(true)} tooltip={t('interaction.button.createNewPermisson')} />
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px',
            maxHeight: '80%',

            overflow: 'auto',
          }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '16px',
                minHeight: '200px', // Set a minimum height for the content
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                {t('interaction.button.addPermission')}
              </Typography>

              <div>
                <Form
                  editing={true}
                  formFields={formFields}
                  formObject={newPermission!}
                  onChange={value => setNewPermission(value as Permission)}
                />
              </div>

              <FormControl sx={{ m: 1, mt: 2, width: '100%' }}>
                <InputLabel htmlFor="demo-dialog-native">View</InputLabel>
                <Select
                  native
                  value={view}
                  onChange={handleChangeView}
                  input={<OutlinedInput label="View" />}
                >
                  <option aria-label="None" value="" />
                  <option value={'Personnel'}>Personnel</option>
                  <option value={'Customer'}>Customer</option>
                  <option value={'Supplier'}>Supplier</option>
                  <option value={'Orders'}>Orders</option>
                  <option value={'BOM'}>BOM</option>
                  <option value={'Stock'}>Stock</option>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, mt: 2, width: '100%' }}>
                <InputLabel id="demo-dialog-select-label">Action</InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select"
                  value={action}
                  onChange={handleChangeAction}
                  input={<OutlinedInput label="Action" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Read Only'}>Read Only</MenuItem>
                  <MenuItem value={'Read & Write'}>Read & Write</MenuItem>
                  <MenuItem value={'No Permission'}>No Permission</MenuItem>
                </Select>
              </FormControl>

              <div
                aria-label="textfieldModal-actions"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  marginTop: '20px',
                }}
              >
                <Button onClick={handleCancel}>{t('common:interaction.button.cancel')}</Button>
                <Button onClick={handleConfirm} variant="contained" color="primary" sx={{ ml: 2 }}>
                  {t('common:interaction.button.add')}
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </Page>
  )*/
}
