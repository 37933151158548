/* -------------------------- Design imports start -------------------------- */
import { toast } from "react-toastify"
import { VisibilityOff, Visibility, ContentCopyRounded, TaskAltRounded } from "@mui/icons-material"
import { Box, Stack, IconButton, Link, Typography, SxProps } from "@mui/material"
import AnimatedTaskDoneIcon from "../animatedIcons/taskDoneIcon"
import labelStyle from './utils/fieldLabel.module.css'
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { useState } from "react"
import { copyToClipboard } from "../../utils/functions"
import LogTool from "../../logger/logTools"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
    value: string | number
    label?: string
    type?: 'text' | 'link' | 'password'
    variant?: 'outlined' | 'underlined'
    sx?: SxProps
}



/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function InteractiveTextDisplay(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    value,
    label = "",
    type = 'text',
    variant = 'outlined',
    sx = {},
  } = props

  const log = new LogTool({context: 'InteractiveTextDisplay', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [showPassword, setShowPassword] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  // const [hiddenPassword] = useState(hideText(String(value)))
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  // some useEffects...
  // !! Note!!: you should try to avoid setting state in a useEffect as this renders
  // the component twice. For more info read: https://react.dev/learn/you-might-not-need-an-effect
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Render a string of "•"s with the length of txt.
   * @param txt
   * @returns
   */
  function hideText(txt: string | number): string {
    if(typeof txt !== 'undefined') {
      const strTxt = String(txt)
      return "●".repeat(strTxt.length)
    }
    return ""
  }
  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  const handleToggleShowPassword = () => {
    setShowPassword(prev => !prev)
  }

  const handleCopyValueToClipboard = async () => {
    const copyStatus = await copyToClipboard(value)
    if(copyStatus === "SUCCESS") {
      setCopySuccess(true)
    } else {
      toast.error("Fehler beim Kopieren in den Zwischenspeicher.")
    }
  }
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const getBorderStyle = (variant: string) => {
    if(variant === 'outlined') {
      return {
        border: '1px solid LightGrey',
        borderRadius: '4px'
      }
    }
    if(variant === 'underlined') {
      return {
        borderBottom: '1px solid LightGrey',
      }
    }
    return {
      border: 'none'
    }
  }
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */
  if(copySuccess) {
    // hide the copy-success-icon after 2 seconds
    setTimeout(() => setCopySuccess(false), 2000)
  }
  /* ------------------------- Pre render actions end ------------------------- */

  if(typeof value === 'undefined') {
    log.warn("Received value of type undefined. The Component might not behave as expected.")
  }

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <Box sx={sx}>
      <Box display='flex' alignItems='center' width='100%'
      sx={getBorderStyle(variant)}
        position='relative'
      >
        {label && <label className={labelStyle.label}>{label}</label>}
        <Box flexBasis={1} flexGrow={1}
          padding='14px'
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
            lineHeight: '1.4375'
          }}
        >
          {type === 'text' &&
            <Typography variant="body1" >{value}</Typography>
          }
          {type === 'password' &&
            <Typography variant="body1" >
              {showPassword ? value : hideText(value)}
            </Typography>
          }
          {type === 'link' &&
            <Link href={String(value)} lineHeight='1.4375rem'
              whiteSpace='nowrap'
            >
              {value}
            </Link>
          }
        </Box>
        <Box
          flexBasis={0}
          flexGrow={0}
          padding='6px' // 14 - 8 = 6; the IconButton has 8px padding by default
          paddingLeft='0px'
        >
          <Stack spacing={1} direction='row'>
            {type === 'password' &&
              <IconButton
                onClick={handleToggleShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
            <IconButton
              onClick={handleCopyValueToClipboard}
            >
              {copySuccess ? <AnimatedTaskDoneIcon /> : <ContentCopyRounded/>}
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}