/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIAllInOneUser,
    APIAllInOneUserFromJSON,
    APIAllInOneUserToJSON,
    APIAllInOneUserRequest,
    APIAllInOneUserRequestFromJSON,
    APIAllInOneUserRequestToJSON,
    APIOrgUserCreationRequest,
    APIOrgUserCreationRequestFromJSON,
    APIOrgUserCreationRequestToJSON,
    APIPaginatedAllInOneUserList,
    APIPaginatedAllInOneUserListFromJSON,
    APIPaginatedAllInOneUserListToJSON,
    APIPatchedAllInOneUserRequest,
    APIPatchedAllInOneUserRequestFromJSON,
    APIPatchedAllInOneUserRequestToJSON,
} from '../models';

export interface V1AccountsUsersCreateRequest {
    aPIOrgUserCreationRequest: APIOrgUserCreationRequest;
}

export interface V1AccountsUsersDestroyRequest {
    id: string;
}

export interface V1AccountsUsersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1AccountsUsersPartialUpdateRequest {
    id: string;
    aPIPatchedAllInOneUserRequest?: APIPatchedAllInOneUserRequest;
}

export interface V1AccountsUsersRetrieveRequest {
    id: string;
}

export interface V1AccountsUsersUpdateRequest {
    id: string;
    aPIAllInOneUserRequest?: APIAllInOneUserRequest;
}

export interface V1ChatChannelMembersCreateRequest {
    channelPk: string;
    aPIOrgUserCreationRequest: APIOrgUserCreationRequest;
}

export interface V1ChatChannelMembersDestroyRequest {
    channelPk: string;
    id: string;
}

export interface V1ChatChannelMembersListRequest {
    channelPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1ChatChannelMembersPartialUpdateRequest {
    channelPk: string;
    id: string;
    aPIPatchedAllInOneUserRequest?: APIPatchedAllInOneUserRequest;
}

export interface V1ChatChannelMembersRetrieveRequest {
    channelPk: string;
    id: string;
}

export interface V1ChatChannelMembersUpdateRequest {
    channelPk: string;
    id: string;
    aPIAllInOneUserRequest?: APIAllInOneUserRequest;
}

/**
 * 
 */
export class AccountsusersApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersCreateRaw(requestParameters: V1AccountsUsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aPIOrgUserCreationRequest === null || requestParameters.aPIOrgUserCreationRequest === undefined) {
            throw new runtime.RequiredError('aPIOrgUserCreationRequest','Required parameter requestParameters.aPIOrgUserCreationRequest was null or undefined when calling v1AccountsUsersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/users/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOrgUserCreationRequestToJSON(requestParameters.aPIOrgUserCreationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersCreate(requestParameters: V1AccountsUsersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AccountsUsersCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersDestroyRaw(requestParameters: V1AccountsUsersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsUsersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersDestroy(requestParameters: V1AccountsUsersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AccountsUsersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersListRaw(requestParameters: V1AccountsUsersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAllInOneUserList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAllInOneUserListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersList(requestParameters: V1AccountsUsersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAllInOneUserList> {
        const response = await this.v1AccountsUsersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersPartialUpdateRaw(requestParameters: V1AccountsUsersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAllInOneUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsUsersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAllInOneUserRequestToJSON(requestParameters.aPIPatchedAllInOneUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAllInOneUserFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersPartialUpdate(requestParameters: V1AccountsUsersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAllInOneUser> {
        const response = await this.v1AccountsUsersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersRetrieveRaw(requestParameters: V1AccountsUsersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAllInOneUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsUsersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAllInOneUserFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersRetrieve(requestParameters: V1AccountsUsersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAllInOneUser> {
        const response = await this.v1AccountsUsersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersUpdateRaw(requestParameters: V1AccountsUsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAllInOneUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsUsersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/users/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAllInOneUserRequestToJSON(requestParameters.aPIAllInOneUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAllInOneUserFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsUsersUpdate(requestParameters: V1AccountsUsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAllInOneUser> {
        const response = await this.v1AccountsUsersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersCreateRaw(requestParameters: V1ChatChannelMembersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMembersCreate.');
        }

        if (requestParameters.aPIOrgUserCreationRequest === null || requestParameters.aPIOrgUserCreationRequest === undefined) {
            throw new runtime.RequiredError('aPIOrgUserCreationRequest','Required parameter requestParameters.aPIOrgUserCreationRequest was null or undefined when calling v1ChatChannelMembersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/members/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOrgUserCreationRequestToJSON(requestParameters.aPIOrgUserCreationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersCreate(requestParameters: V1ChatChannelMembersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ChatChannelMembersCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersDestroyRaw(requestParameters: V1ChatChannelMembersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMembersDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMembersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/members/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersDestroy(requestParameters: V1ChatChannelMembersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ChatChannelMembersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersListRaw(requestParameters: V1ChatChannelMembersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAllInOneUserList>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMembersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/members/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAllInOneUserListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersList(requestParameters: V1ChatChannelMembersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAllInOneUserList> {
        const response = await this.v1ChatChannelMembersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersPartialUpdateRaw(requestParameters: V1ChatChannelMembersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAllInOneUser>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMembersPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMembersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/members/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAllInOneUserRequestToJSON(requestParameters.aPIPatchedAllInOneUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAllInOneUserFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersPartialUpdate(requestParameters: V1ChatChannelMembersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAllInOneUser> {
        const response = await this.v1ChatChannelMembersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersRetrieveRaw(requestParameters: V1ChatChannelMembersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAllInOneUser>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMembersRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMembersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/members/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAllInOneUserFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersRetrieve(requestParameters: V1ChatChannelMembersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAllInOneUser> {
        const response = await this.v1ChatChannelMembersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersUpdateRaw(requestParameters: V1ChatChannelMembersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAllInOneUser>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMembersUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMembersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/members/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAllInOneUserRequestToJSON(requestParameters.aPIAllInOneUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAllInOneUserFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMembersUpdate(requestParameters: V1ChatChannelMembersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAllInOneUser> {
        const response = await this.v1ChatChannelMembersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
