/* -------------------------- Design imports start -------------------------- */
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { SendRounded } from '@mui/icons-material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import useMobileService from '../../../../hooks/useMobileService'
import { t } from 'i18next'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  sendMessage: (message: string) => void
  disabled?: boolean
  sx?: React.CSSProperties
}

/**
 * TextInput to write new chat messages in
 * "Ctrl + enter " --> makes a newline in the textbox
 * "enter" --> sends message
 */
/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function ChatTextInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { sendMessage, disabled=false, sx = {} } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [messageText, setMessageText] = useState<string>('')
  const isMobile = useMobileService()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------- Utility functions start ------------------------ */
  async function handleSubmit(event: any) {
    if (messageText.trim().length !== 0) {
      event.preventDefault()
      event.stopPropagation()
      sendMessage(messageText)
      setMessageText('')
    }
  }

  async function handleKeyDown(event: any) {
    if (event.keyCode === 13 && !event.ctrlKey && messageText.trim().length !== 0) {
      event.preventDefault()
      event.stopPropagation()
      sendMessage(messageText)
      setMessageText('')
    }
  }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  function handleChange(event: any) {
    setMessageText(event.currentTarget.value)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return isMobile ? (
    <div style={{ ...sx, display: 'flex', flexShrink: 0 }}>
      <FormControl
        disabled={disabled}
        fullWidth
        sx={{
          height: 'max-content',
          margin: '5px',
        }}
      >
        <OutlinedInput
          id="chat-input"
          placeholder={t('chat:content.label.enterMessage') as string}
          multiline
          minRows={1}
          maxRows={4}
          value={messageText}
          onChange={e => handleChange(e)}
          onKeyDown={event => handleKeyDown(event)}
          endAdornment={
            <InputAdornment position="end" sx={{ marginBottom: '0px' }}>
              <IconButton
                onClick={handleSubmit}
                /* check if string is empty or only contains spaces */
                disabled={disabled || messageText.length === 0 || messageText.trim().length === 0}
                size="large"
                edge="end"
                color="primary"
                sx={{ float: 'right' }}
              >
                <SendRounded color="primary" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  ) : (
    <div style={{ ...sx, display: 'flex', flexShrink: 0 }}>
      <FormControl
      disabled={disabled}
        fullWidth
        sx={{
          height: 'max-content',
          margin: '5px',
        }}
      >
        <OutlinedInput
          id="chat-input"
          placeholder={t('chat:content.label.enterMessage') as string}
          multiline
          minRows={1}
          maxRows={4}
          value={messageText}
          onChange={e => setMessageText(e.currentTarget.value)}
          onKeyDown={event => handleKeyDown(event)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleSubmit}
                /* check if string is empty or only contains spaces */
                disabled={disabled || messageText.length === 0 || messageText.trim().length === 0}
                size="large"
                edge="end"
                color="primary"
                sx={{ float: 'right' }}
              >
                <SendRounded color="primary" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  )
}
