/* -------------------------- Design imports start -------------------------- */
import {  Checkbox, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  setOpenVersionDrawer: (open: boolean) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function VersionSubmit(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { formState, setOpenVersionDrawer } = props
  const { t } = useTranslation(['common'])
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container spacing={2} sx={{ margin: 'auto' }}>
      {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button variant="outlined" onClick={() => setOpenVersionDrawer(true)}>
                    {t('interaction.button.openVersionHistory')}
                </Button>
            </Grid> */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FormControlLabel
          control={
            <Switch
              id="newVersion"
              checked={formState.state.newVersion}
              onChange={() =>
                formState.setState((prev: any) => ({ ...prev, newVersion: !prev.newVersion }))
              }
            />
          }
          label={t('content.label.newVersionText')}
        />
      </Grid>
      {formState.state.newVersion && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="versionComment"
            fullWidth
            multiline
            rows={4}
            label={t('content.label.versionComment')}
            value={formState.state.versionComment}
            onChange={e =>
              formState.setState((prev: any) => {
                return { ...prev, versionComment: e.target.value }
              })
            }
          />
        </Grid>
      )}
      <Grid item>
        <Button
          id="openVersionHistoryButton"
          variant="contained"
          onClick={() => setOpenVersionDrawer(true)}
        >
          {t('interaction.button.openVersionHistory')}
        </Button>
      </Grid>
    </Grid>
  )
}
