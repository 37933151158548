/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIArticleFile,
    APIArticleFileFromJSON,
    APIArticleFileToJSON,
    APIPaginatedArticleFileList,
    APIPaginatedArticleFileListFromJSON,
    APIPaginatedArticleFileListToJSON,
} from '../models';

export interface V1ItemsArticlesFilesCreateRequest {
    articlePk: number;
    article: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsArticlesFilesDestroyRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesFilesListRequest {
    articlePk: number;
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesFilesPartialUpdateRequest {
    articlePk: number;
    id: number;
    article?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsArticlesFilesRetrieveRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesFilesUpdateRequest {
    articlePk: number;
    id: number;
    article: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsFilesCreateRequest {
    article: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsFilesDestroyRequest {
    id: number;
}

export interface V1ItemsFilesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsFilesPartialUpdateRequest {
    id: number;
    article?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsFilesRetrieveRequest {
    id: number;
}

export interface V1ItemsFilesUpdateRequest {
    id: number;
    article: string;
    key?: string | null;
    document?: Blob | null;
}

/**
 * 
 */
export class ItemsarticleFileApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesCreateRaw(requestParameters: V1ItemsArticlesFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesCreate.');
        }

        if (requestParameters.article === null || requestParameters.article === undefined) {
            throw new runtime.RequiredError('article','Required parameter requestParameters.article was null or undefined when calling v1ItemsArticlesFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesCreate(requestParameters: V1ItemsArticlesFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesDestroyRaw(requestParameters: V1ItemsArticlesFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesDestroy(requestParameters: V1ItemsArticlesFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesListRaw(requestParameters: V1ItemsArticlesFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedArticleFileList>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedArticleFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesList(requestParameters: V1ItemsArticlesFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedArticleFileList> {
        const response = await this.v1ItemsArticlesFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesPartialUpdateRaw(requestParameters: V1ItemsArticlesFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesPartialUpdate(requestParameters: V1ItemsArticlesFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesRetrieveRaw(requestParameters: V1ItemsArticlesFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesRetrieve(requestParameters: V1ItemsArticlesFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesUpdateRaw(requestParameters: V1ItemsArticlesFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesUpdate.');
        }

        if (requestParameters.article === null || requestParameters.article === undefined) {
            throw new runtime.RequiredError('article','Required parameter requestParameters.article was null or undefined when calling v1ItemsArticlesFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesUpdate(requestParameters: V1ItemsArticlesFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesCreateRaw(requestParameters: V1ItemsFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.article === null || requestParameters.article === undefined) {
            throw new runtime.RequiredError('article','Required parameter requestParameters.article was null or undefined when calling v1ItemsFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/files/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesCreate(requestParameters: V1ItemsFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesDestroyRaw(requestParameters: V1ItemsFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesDestroy(requestParameters: V1ItemsFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesListRaw(requestParameters: V1ItemsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedArticleFileList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/files/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedArticleFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesList(requestParameters: V1ItemsFilesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedArticleFileList> {
        const response = await this.v1ItemsFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesPartialUpdateRaw(requestParameters: V1ItemsFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesPartialUpdate(requestParameters: V1ItemsFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesRetrieveRaw(requestParameters: V1ItemsFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesRetrieve(requestParameters: V1ItemsFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesUpdateRaw(requestParameters: V1ItemsFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesUpdate.');
        }

        if (requestParameters.article === null || requestParameters.article === undefined) {
            throw new runtime.RequiredError('article','Required parameter requestParameters.article was null or undefined when calling v1ItemsFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesUpdate(requestParameters: V1ItemsFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
