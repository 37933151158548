/* -------------------------- Design imports start -------------------------- */
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
/* --------------------------- Design imports end --------------------------- */



/* ------------------------ Functional imports start ------------------------ */
import { useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import LogTool from "../../logger/logTools"
import { useTranslation } from "react-i18next"
import { formatBytes, handleAPICallV1 } from "../../utils/functions"
import { FileInfo } from "../../utils/types"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
  inputState: {
    state: FileInfo[]
    setState: React.Dispatch<React.SetStateAction<FileInfo[]>>
  } // a react state object that is used to keep track of the files that were input.
  maxAllowedFiles?: number // the maximum amount of files that are alowed to be uploaded at once
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
/**
 * A file input component to handle (drag and drop) input of files. Input files are stored in the inputState of the component.
 * @param props
 * @returns
 */
export default function FileInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {inputState, maxAllowedFiles = Number.MAX_SAFE_INTEGER} = props
  const log = new LogTool({context: 'FileUpload', enable: true, logLevel: 'warn'})
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */

  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  // some useEffects...
  // !! Note!!: you should try to avoid setting state in a useEffect as this renders
  // the component twice. For more info read: https://react.dev/learn/you-might-not-need-an-effect
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */

  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  /**
   * Callback that adds accepted files to the FileInput state, if they are not in there already.
   * @param acceptedFiles List of files that were accepted on "upload" by the DropZone component.
   */
  const handleMultipleFileInput = (acceptedFiles: File[]) => {
    // get all the files that are new
    let newFiles: File[] = [];
    for(const file of acceptedFiles) {
      if(!inputState.state.find(listedFile => listedFile.name === file.name)) {
        newFiles.push(file)
      }
    }
    if(newFiles.length + inputState.state.length > maxAllowedFiles) {
      // TODO
      // the user specified to many files
      alert(`Tell the user that maximum ${maxAllowedFiles} are allowed!`)

      // only add the first few files from newFiles until the max is reached
      newFiles = newFiles.slice(0, maxAllowedFiles - inputState.state.length)
    }
    // add the new files to the file list
    inputState.setState(prevFiles => [...prevFiles, ...newFiles])
  }

  /**
   * Callback function that removes the file specified by fileName from the FileInput state.
   * @param fileName Name of the file that should be removed from FileInput state.
   */
  const handleRemoveFile = (fileName: string) => {
    // update rendered files list
    inputState.setState(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(file => file.name === fileName)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDropAccepted: handleMultipleFileInput})


  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    height: '148px',
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const focusedStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <div className="container" style={{ margin: '5px' }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography>{t('common:content.label.dragAndDrop')}</Typography>
      </div>
    </div>
  )
}