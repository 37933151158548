/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIContract,
    APIContractFromJSON,
    APIContractToJSON,
    APIContractRequest,
    APIContractRequestFromJSON,
    APIContractRequestToJSON,
    APIPaginatedContractList,
    APIPaginatedContractListFromJSON,
    APIPaginatedContractListToJSON,
    APIPatchedContractRequest,
    APIPatchedContractRequestFromJSON,
    APIPatchedContractRequestToJSON,
} from '../models';

export interface V1ContractContractsCreateRequest {
    aPIContractRequest: APIContractRequest;
}

export interface V1ContractContractsDestroyRequest {
    id: number;
}

export interface V1ContractContractsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ContractContractsPartialUpdateRequest {
    id: number;
    aPIPatchedContractRequest?: APIPatchedContractRequest;
}

export interface V1ContractContractsRetrieveRequest {
    id: number;
}

export interface V1ContractContractsUpdateRequest {
    id: number;
    aPIContractRequest: APIContractRequest;
}

/**
 * 
 */
export class ContractcontractsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsCreateRaw(requestParameters: V1ContractContractsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.aPIContractRequest === null || requestParameters.aPIContractRequest === undefined) {
            throw new runtime.RequiredError('aPIContractRequest','Required parameter requestParameters.aPIContractRequest was null or undefined when calling v1ContractContractsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIContractRequestToJSON(requestParameters.aPIContractRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsCreate(requestParameters: V1ContractContractsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsDestroyRaw(requestParameters: V1ContractContractsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsDestroy(requestParameters: V1ContractContractsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContractContractsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsListRaw(requestParameters: V1ContractContractsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedContractList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedContractListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsList(requestParameters: V1ContractContractsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedContractList> {
        const response = await this.v1ContractContractsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsPartialUpdateRaw(requestParameters: V1ContractContractsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedContractRequestToJSON(requestParameters.aPIPatchedContractRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsPartialUpdate(requestParameters: V1ContractContractsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsRetrieveRaw(requestParameters: V1ContractContractsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsRetrieve(requestParameters: V1ContractContractsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsUpdateRaw(requestParameters: V1ContractContractsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsUpdate.');
        }

        if (requestParameters.aPIContractRequest === null || requestParameters.aPIContractRequest === undefined) {
            throw new runtime.RequiredError('aPIContractRequest','Required parameter requestParameters.aPIContractRequest was null or undefined when calling v1ContractContractsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIContractRequestToJSON(requestParameters.aPIContractRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsUpdate(requestParameters: V1ContractContractsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
