/* -------------------------- Design imports start -------------------------- */
import { toast } from "react-toastify";
import MultilevelDrawer from "../../components/layout/MultilevelDrawer";
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from "react-i18next";
import LogTool from "../../logger/logTools";
import { useEffect, useRef, useState } from "react";
import { Contact, HTTPMethod, SelectOption } from "../../utils/types";
import { APIContact } from "../../generated-types";
import { createContact, fetchContacts, formInputToAPIContactJSON, handleAPICallV1, loadAllContactOptions, loadContactOptions, missingRequiredFormFields } from "../../utils/functions";
import { Form, FormFieldType } from "../Form";
import GetConstant from "./utils/constants";
import { Button, Grid, Typography } from "@mui/material";
import ContactDrawer from "../Customer/ContactDrawer";
/* ------------------------- Functional imports end ------------------------- */


interface Props {
    open: boolean
    setOpen: (open: boolean) => void
    formState: { state: any | undefined; setState: React.Dispatch<React.SetStateAction<any | undefined>>; }
    onClose?: () => void
    onCreateConfirm: (input: any) => Promise<void>
    enableEditMode?: boolean
    onEditConfirm?: (input: any) => Promise<void>
  }



/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function SupplierDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'SupplierModal', enable: true, logLevel: 'warn' })
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    enableEditMode = false,
    onCreateConfirm = () => null,
    onEditConfirm = () => alert('TODO: provide a onEditConfirm callback!'),
  } = props
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [prevOpen, setPrevOpen] = useState(false)
  const [openContactModal, setOpenContactModal] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  const contactStore = useRef<Contact[]>([])
  const [contactOptions, setContactOptions] = useState<SelectOption[]>([])
  const [contactFormInput, setContactFormInput] = useState<any>({} as APIContact)
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    // load contacts on mounting of component
    fetchContacts({
      onSuccess: (contacts: Contact[]) => {
        contactStore.current = [...contactStore.current, ...contacts]
      }
    })
  }, [])
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------ Callback functions start ------------------------ */
  /**
   * Callback that is executed when the modal is closed. Sets all modal state to the initial values
   * such that the modal is 'fresh' every time it is opened again.
   * @param preventOnClose prevent calling the onClose function. If true handleClose only resets all states!
   */
  const handleClose = (preventOnClose: boolean = false) => {
    // reset modal to initial state
    formState.setState({})
    setOpen(false)

    !preventOnClose && onClose()
  }

  const handleCreateConfirm = async () => {
    // posting request to server and waiting on response
    await onCreateConfirm({...formState.state, contacts: [formState.state.contact?.value]})
    handleClose(true)
  }

  const handleEditConfirm = async () => {
    // posting request to server and waiting on response
    await onEditConfirm(formState.state)
    handleClose(true)
  }

  const handleAddContactOption = async (contactFormInput: any): Promise<"SUCCESS" | "ERROR"> => {
    log.info("Begin sending contact.")
    const json = formInputToAPIContactJSON(contactFormInput)
    log.debug("Input ->", contactFormInput, ", JSON ->", json)

    // create new contact
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'contacts/contacts/',
      undefined,
      json
    )

    if(!error && response) {

      // update contact options
      setContactOptions(
        prev => {return [
          {
            value: response.self,
            label: (response.firstName && response.lastName)
              ? `${response.firstName} ${response.lastName}`
              : response.email}, ...prev
          ]
        }
      )

      //set selected contact option
      // TODO: enable multi contact input and find a better way to keep track of select and server request data!
      formState.setState((prev: any) => {return {
        ...prev,
        contact: {
          value: response.self,
          label: (response.firstName && response.lastName)
            ? `${response.firstName} ${response.lastName}`
            : response.email
        }, // contact stores the select value and label
        contacts: [response.self], // contacts stores the contact list that is send to the server
        isNewContact: true
      }})

      return "SUCCESS"
    } else {
      log.error("Error on creating contact option.")
      return "ERROR"
    }
  }
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const formFieldsSupplier: FormFieldType[] = GetConstant({name: 'supplierFormFields'}) as FormFieldType[]
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  if (open === true && prevOpen === false) {
    setPrevOpen(true)

    setContactOptions(
      contactStore.current.map(contact => {
        return {
          value: contact.self,
          label:
            contact.firstName && contact.lastName
              ? `${contact.firstName} ${contact.lastName}`
              : contact.email,
        }
      })
    )
  }
  /* ------------------------- Pre render actions end ------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        size="small"
        onClose={handleClose}
        title={t('supplier:interaction.button.addSupplier') as string}
        confirmButtonProps={{
          text: enableEditMode ? t('common:interaction.button.save') : t('common:interaction.button.add'),
          onConfirm: enableEditMode ? handleEditConfirm : handleCreateConfirm,
          disabled: missingRequiredFormFields(formFieldsSupplier, formState.state)
        }}
      >
        <Grid container spacing={2}>
            <Form
              editing={true}
              formFields={formFieldsSupplier}
              formObject={formState.state}
              onChange={(value: any) => formState.setState(value)}
            />
        </Grid>
        {!enableEditMode && <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            {t('supplier:content.label.contactData')}
        </Typography>}
        {!enableEditMode && <Grid container>
          <Form
            editing={true}
            formFields={[{
              type: 'select-search',
              key: 'contact',
              label: t('common:content.label.contact'),
              loadData: loadContactOptions,
              loadAllData: loadAllContactOptions,
              onAddOption: () => setOpenContactModal(true),
              xl: 12,
              sm: 12,
              md: 12,
              lg: 12,
            }]}
            formObject={formState.state}
            onChange={(value: any) => formState.setState(value)}
          />
        </Grid>}
      </MultilevelDrawer>
      <ContactDrawer
        open={openContactModal}
        setOpen={setOpenContactModal}
        formState={{state: contactFormInput, setState: setContactFormInput}}
        onConfirm={handleAddContactOption}
      />
    </>
  )
}