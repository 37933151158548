/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIFeedbackForm,
    APIFeedbackFormFromJSON,
    APIFeedbackFormToJSON,
    APIFeedbackFormRequest,
    APIFeedbackFormRequestFromJSON,
    APIFeedbackFormRequestToJSON,
    APIFeedbackSubmissionCreation,
    APIFeedbackSubmissionCreationFromJSON,
    APIFeedbackSubmissionCreationToJSON,
    APIFeedbackSubmissionCreationRequest,
    APIFeedbackSubmissionCreationRequestFromJSON,
    APIFeedbackSubmissionCreationRequestToJSON,
    APIPaginatedFeedbackFormList,
    APIPaginatedFeedbackFormListFromJSON,
    APIPaginatedFeedbackFormListToJSON,
    APIPatchedFeedbackFormRequest,
    APIPatchedFeedbackFormRequestFromJSON,
    APIPatchedFeedbackFormRequestToJSON,
} from '../models';

export interface V1ItemsFeedbackFormsCreateRequest {
    aPIFeedbackFormRequest: APIFeedbackFormRequest;
}

export interface V1ItemsFeedbackFormsDestroyRequest {
    id: string;
}

export interface V1ItemsFeedbackFormsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsFeedbackFormsPartialUpdateRequest {
    id: string;
    aPIPatchedFeedbackFormRequest?: APIPatchedFeedbackFormRequest;
}

export interface V1ItemsFeedbackFormsRetrieveRequest {
    id: string;
}

export interface V1ItemsFeedbackFormsSubmitCreateRequest {
    id: string;
    aPIFeedbackSubmissionCreationRequest: APIFeedbackSubmissionCreationRequest;
}

export interface V1ItemsFeedbackFormsUpdateRequest {
    id: string;
    aPIFeedbackFormRequest: APIFeedbackFormRequest;
}

/**
 * 
 */
export class ItemsfeedbackFormApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsCreateRaw(requestParameters: V1ItemsFeedbackFormsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackForm>> {
        if (requestParameters.aPIFeedbackFormRequest === null || requestParameters.aPIFeedbackFormRequest === undefined) {
            throw new runtime.RequiredError('aPIFeedbackFormRequest','Required parameter requestParameters.aPIFeedbackFormRequest was null or undefined when calling v1ItemsFeedbackFormsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIFeedbackFormRequestToJSON(requestParameters.aPIFeedbackFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackFormFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsCreate(requestParameters: V1ItemsFeedbackFormsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackForm> {
        const response = await this.v1ItemsFeedbackFormsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsDestroyRaw(requestParameters: V1ItemsFeedbackFormsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackFormsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsDestroy(requestParameters: V1ItemsFeedbackFormsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsFeedbackFormsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsListRaw(requestParameters: V1ItemsFeedbackFormsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedFeedbackFormList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedFeedbackFormListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsList(requestParameters: V1ItemsFeedbackFormsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedFeedbackFormList> {
        const response = await this.v1ItemsFeedbackFormsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsPartialUpdateRaw(requestParameters: V1ItemsFeedbackFormsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackForm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackFormsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedFeedbackFormRequestToJSON(requestParameters.aPIPatchedFeedbackFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackFormFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsPartialUpdate(requestParameters: V1ItemsFeedbackFormsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackForm> {
        const response = await this.v1ItemsFeedbackFormsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsRetrieveRaw(requestParameters: V1ItemsFeedbackFormsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackForm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackFormsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackFormFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsRetrieve(requestParameters: V1ItemsFeedbackFormsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackForm> {
        const response = await this.v1ItemsFeedbackFormsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsSubmitCreateRaw(requestParameters: V1ItemsFeedbackFormsSubmitCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackSubmissionCreation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackFormsSubmitCreate.');
        }

        if (requestParameters.aPIFeedbackSubmissionCreationRequest === null || requestParameters.aPIFeedbackSubmissionCreationRequest === undefined) {
            throw new runtime.RequiredError('aPIFeedbackSubmissionCreationRequest','Required parameter requestParameters.aPIFeedbackSubmissionCreationRequest was null or undefined when calling v1ItemsFeedbackFormsSubmitCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/{id}/submit/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIFeedbackSubmissionCreationRequestToJSON(requestParameters.aPIFeedbackSubmissionCreationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackSubmissionCreationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsSubmitCreate(requestParameters: V1ItemsFeedbackFormsSubmitCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackSubmissionCreation> {
        const response = await this.v1ItemsFeedbackFormsSubmitCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsUpdateRaw(requestParameters: V1ItemsFeedbackFormsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackForm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackFormsUpdate.');
        }

        if (requestParameters.aPIFeedbackFormRequest === null || requestParameters.aPIFeedbackFormRequest === undefined) {
            throw new runtime.RequiredError('aPIFeedbackFormRequest','Required parameter requestParameters.aPIFeedbackFormRequest was null or undefined when calling v1ItemsFeedbackFormsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_forms/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIFeedbackFormRequestToJSON(requestParameters.aPIFeedbackFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackFormFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackFormsUpdate(requestParameters: V1ItemsFeedbackFormsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackForm> {
        const response = await this.v1ItemsFeedbackFormsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
