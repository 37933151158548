/* ------------------------ Functional imports start ------------------------ */
import { createContext, useContext, useEffect, useRef, useState } from "react"
import LogTool from "../../logger/logTools"
import { handleAPICallV1 } from "../../utils/functions"
import { HTTPMethod } from "../../utils/types"
import { useUserContext } from "../../utils/context"
/* ------------------------- Functional imports end ------------------------- */


type UnreadFeedbackSubmissionCountObject = {
  total: number,
  articleShares: number,
  BOMShares: number,
  guestRequestShares: number,
}

type ShareTypes = 'articleShares' | 'BOMShares' | 'guestRequestShares'


/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function SharepointGlobalStateProvider(props: {children: React.ReactNode}) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({context: 'SharepointGlobalStates', enable: true, logLevel: 'warn'})
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */


  /* ---------------------------- Data states start --------------------------- */
  // note: a value of undefined indicates that the feedbackSubmissionCount was not fetched yet
  const [
    unreadFeedbackSubmissionCount,
    setUnreadFeedbackSubmissionCount
  ] = useState<UnreadFeedbackSubmissionCountObject| undefined>()
  /* ----------------------------- Data states end ---------------------------- */


  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    // fetch the unreadFeedbackSubmissionCount the first time we know about an authenticated user
    if(user && typeof unreadFeedbackSubmissionCount === 'undefined') {
      fetchUnreadFeedbackSubmissionCount()
    }
  }, [user])
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  async function fetchUnreadFeedbackSubmissionCount() {
    log.info('Begin fetching unread feedback submission count.')
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'items/sharepoints/unread_feedback_submission_count/',
    )

    if(!error && response) {
      log.info('Success fetching unread feedback submission count', response)
      const unreadFeedbackSubmissionCount = {
        total: response.total,
        articleShares: response.article_shares,
        BOMShares: response.bom_shares,
        guestRequestShares: response.guest_request_shares,
      }
      setUnreadFeedbackSubmissionCount(unreadFeedbackSubmissionCount)
    } else {
      log.error('Error fetching unread feedback submission count', error)
    }
  }

  function addToFeedbackCount(value: number, shareType: ShareTypes) {
    if( typeof unreadFeedbackSubmissionCount === 'undefined') return

    setUnreadFeedbackSubmissionCount(prev => {
      prev!.total += value
      prev![shareType] += value
      return {...prev!}
    })
  }
  function subtractFromFeedbackCount(value: number, shareType: ShareTypes) {
    if( typeof unreadFeedbackSubmissionCount === 'undefined') return

    setUnreadFeedbackSubmissionCount(prev => {
      prev!.total -= value
      prev![shareType] -= value
      return {...prev!}
    })
  }
  function setFeedbackCount(value: number, shareType: ShareTypes) {
    if( typeof unreadFeedbackSubmissionCount === 'undefined') return

    setUnreadFeedbackSubmissionCount(prev => {
      const valueDifference = prev![shareType] - value
      prev!.total += valueDifference
      prev![shareType] = value
      return {...prev!}
    })
  }
  /* -------------------------- Utility functions end ------------------------- */


  log.debug('unreadFeedbackSubmissionsCount ->', unreadFeedbackSubmissionCount)


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <SharepointGlobalStates.Provider
      value={{
        unreadFeedbackSubmissionCount: unreadFeedbackSubmissionCount || {total: 0, articleShares: 0, BOMShares: 0, guestRequestShares: 0},
        addToFeedbackCount: addToFeedbackCount,
        subtractFromFeedbackCount: subtractFromFeedbackCount,
        setFeedbackCount: setFeedbackCount,
      }}
    >
      {props.children}
    </SharepointGlobalStates.Provider>
  )
}

interface ISharepointGlobalStates {
  unreadFeedbackSubmissionCount: UnreadFeedbackSubmissionCountObject,
  // setUnreadFeedbackSubmissionCount: React.Dispatch<React.SetStateAction<UnreadFeedbackSubmissionCountObject>>
  addToFeedbackCount: (value: number, shareType: ShareTypes) => void,
  subtractFromFeedbackCount: (value: number, shareType: ShareTypes) => void,
  setFeedbackCount: (value: number, shareType: ShareTypes) => void,
}

export const SharepointGlobalStates = createContext<ISharepointGlobalStates>({
  unreadFeedbackSubmissionCount: {total: 0, articleShares: 0, BOMShares: 0, guestRequestShares: 0},
  addToFeedbackCount: (value: number, shareType: ShareTypes) => null,
  subtractFromFeedbackCount: (value: number, shareType: ShareTypes) => null,
  setFeedbackCount: (value: number, shareType: ShareTypes) => null,
})