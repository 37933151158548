/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIAddress,
    APIAddressFromJSON,
    APIAddressToJSON,
    APIAddressRequest,
    APIAddressRequestFromJSON,
    APIAddressRequestToJSON,
    APIPaginatedAddressList,
    APIPaginatedAddressListFromJSON,
    APIPaginatedAddressListToJSON,
    APIPatchedAddressRequest,
    APIPatchedAddressRequestFromJSON,
    APIPatchedAddressRequestToJSON,
} from '../models';

export interface V1ContactsAddressesCreateRequest {
    aPIAddressRequest: APIAddressRequest;
}

export interface V1ContactsAddressesDestroyRequest {
    id: number;
}

export interface V1ContactsAddressesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ContactsAddressesPartialUpdateRequest {
    id: number;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1ContactsAddressesRetrieveRequest {
    id: number;
}

export interface V1ContactsAddressesUpdateRequest {
    id: number;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1ContactsContactsAddressesCreateRequest {
    contactsPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1ContactsContactsAddressesDestroyRequest {
    contactsPk: string;
    id: number;
}

export interface V1ContactsContactsAddressesListRequest {
    contactsPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1ContactsContactsAddressesPartialUpdateRequest {
    contactsPk: string;
    id: number;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1ContactsContactsAddressesRetrieveRequest {
    contactsPk: string;
    id: number;
}

export interface V1ContactsContactsAddressesUpdateRequest {
    contactsPk: string;
    id: number;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1CustomerCustomersAddressesCreateRequest {
    customersPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1CustomerCustomersAddressesDestroyRequest {
    customersPk: string;
    id: number;
}

export interface V1CustomerCustomersAddressesListRequest {
    customersPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1CustomerCustomersAddressesPartialUpdateRequest {
    customersPk: string;
    id: number;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1CustomerCustomersAddressesRetrieveRequest {
    customersPk: string;
    id: number;
}

export interface V1CustomerCustomersAddressesUpdateRequest {
    customersPk: string;
    id: number;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1SupplierSuppliersAddressesCreateRequest {
    suppliersPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1SupplierSuppliersAddressesDestroyRequest {
    id: number;
    suppliersPk: string;
}

export interface V1SupplierSuppliersAddressesListRequest {
    suppliersPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1SupplierSuppliersAddressesPartialUpdateRequest {
    id: number;
    suppliersPk: string;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1SupplierSuppliersAddressesRetrieveRequest {
    id: number;
    suppliersPk: string;
}

export interface V1SupplierSuppliersAddressesUpdateRequest {
    id: number;
    suppliersPk: string;
    aPIAddressRequest: APIAddressRequest;
}

/**
 * 
 */
export class ContactsaddressApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesCreateRaw(requestParameters: V1ContactsAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1ContactsAddressesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesCreate(requestParameters: V1ContactsAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesDestroyRaw(requestParameters: V1ContactsAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesDestroy(requestParameters: V1ContactsAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContactsAddressesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesListRaw(requestParameters: V1ContactsAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAddressList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAddressListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesList(requestParameters: V1ContactsAddressesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAddressList> {
        const response = await this.v1ContactsAddressesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesPartialUpdateRaw(requestParameters: V1ContactsAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesPartialUpdate(requestParameters: V1ContactsAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesRetrieveRaw(requestParameters: V1ContactsAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesRetrieve(requestParameters: V1ContactsAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesUpdateRaw(requestParameters: V1ContactsAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesUpdate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1ContactsAddressesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesUpdate(requestParameters: V1ContactsAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesCreateRaw(requestParameters: V1ContactsContactsAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1ContactsContactsAddressesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesCreate(requestParameters: V1ContactsContactsAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesDestroyRaw(requestParameters: V1ContactsContactsAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesDestroy(requestParameters: V1ContactsContactsAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContactsContactsAddressesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesListRaw(requestParameters: V1ContactsContactsAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAddressList>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAddressListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesList(requestParameters: V1ContactsContactsAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAddressList> {
        const response = await this.v1ContactsContactsAddressesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesPartialUpdateRaw(requestParameters: V1ContactsContactsAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesPartialUpdate(requestParameters: V1ContactsContactsAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesRetrieveRaw(requestParameters: V1ContactsContactsAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesRetrieve(requestParameters: V1ContactsContactsAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesUpdateRaw(requestParameters: V1ContactsContactsAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesUpdate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1ContactsContactsAddressesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesUpdate(requestParameters: V1ContactsContactsAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesCreateRaw(requestParameters: V1CustomerCustomersAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1CustomerCustomersAddressesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesCreate(requestParameters: V1CustomerCustomersAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesDestroyRaw(requestParameters: V1CustomerCustomersAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesDestroy(requestParameters: V1CustomerCustomersAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1CustomerCustomersAddressesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesListRaw(requestParameters: V1CustomerCustomersAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAddressList>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAddressListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesList(requestParameters: V1CustomerCustomersAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAddressList> {
        const response = await this.v1CustomerCustomersAddressesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesPartialUpdateRaw(requestParameters: V1CustomerCustomersAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesPartialUpdate(requestParameters: V1CustomerCustomersAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesRetrieveRaw(requestParameters: V1CustomerCustomersAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesRetrieve(requestParameters: V1CustomerCustomersAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesUpdateRaw(requestParameters: V1CustomerCustomersAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesUpdate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1CustomerCustomersAddressesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesUpdate(requestParameters: V1CustomerCustomersAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesCreateRaw(requestParameters: V1SupplierSuppliersAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1SupplierSuppliersAddressesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/`.replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesCreate(requestParameters: V1SupplierSuppliersAddressesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesDestroyRaw(requestParameters: V1SupplierSuppliersAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesDestroy.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesDestroy(requestParameters: V1SupplierSuppliersAddressesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1SupplierSuppliersAddressesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesListRaw(requestParameters: V1SupplierSuppliersAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAddressList>> {
        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/`.replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAddressListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesList(requestParameters: V1SupplierSuppliersAddressesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAddressList> {
        const response = await this.v1SupplierSuppliersAddressesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesPartialUpdateRaw(requestParameters: V1SupplierSuppliersAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesPartialUpdate.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesPartialUpdate(requestParameters: V1SupplierSuppliersAddressesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesRetrieveRaw(requestParameters: V1SupplierSuppliersAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesRetrieve.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesRetrieve(requestParameters: V1SupplierSuppliersAddressesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesUpdateRaw(requestParameters: V1SupplierSuppliersAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesUpdate.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesUpdate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1SupplierSuppliersAddressesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesUpdate(requestParameters: V1SupplierSuppliersAddressesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
