/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedSharepointList,
    APIPaginatedSharepointListFromJSON,
    APIPaginatedSharepointListToJSON,
    APIPatchedSharepointRequest,
    APIPatchedSharepointRequestFromJSON,
    APIPatchedSharepointRequestToJSON,
    APISharepoint,
    APISharepointFromJSON,
    APISharepointToJSON,
    APISharepointCreation,
    APISharepointCreationFromJSON,
    APISharepointCreationToJSON,
    APISharepointCreationRequest,
    APISharepointCreationRequestFromJSON,
    APISharepointCreationRequestToJSON,
    APISharepointUpdate,
    APISharepointUpdateFromJSON,
    APISharepointUpdateToJSON,
    APISharepointUpdateRequest,
    APISharepointUpdateRequestFromJSON,
    APISharepointUpdateRequestToJSON,
} from '../models';

export interface V1ItemsSharepointsCreateRequest {
    aPISharepointCreationRequest: APISharepointCreationRequest;
}

export interface V1ItemsSharepointsDestroyRequest {
    id: number;
}

export interface V1ItemsSharepointsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsSharepointsPartialUpdateRequest {
    id: number;
    aPIPatchedSharepointRequest?: APIPatchedSharepointRequest;
}

export interface V1ItemsSharepointsRetrieveRequest {
    id: number;
}

export interface V1ItemsSharepointsUpdateRequest {
    id: number;
    aPISharepointUpdateRequest: APISharepointUpdateRequest;
}

/**
 * 
 */
export class ItemssharepointApi extends runtime.BaseAPI {

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsCreateRaw(requestParameters: V1ItemsSharepointsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepointCreation>> {
        if (requestParameters.aPISharepointCreationRequest === null || requestParameters.aPISharepointCreationRequest === undefined) {
            throw new runtime.RequiredError('aPISharepointCreationRequest','Required parameter requestParameters.aPISharepointCreationRequest was null or undefined when calling v1ItemsSharepointsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APISharepointCreationRequestToJSON(requestParameters.aPISharepointCreationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointCreationFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsCreate(requestParameters: V1ItemsSharepointsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepointCreation> {
        const response = await this.v1ItemsSharepointsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsDestroyRaw(requestParameters: V1ItemsSharepointsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsSharepointsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsDestroy(requestParameters: V1ItemsSharepointsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsSharepointsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsListRaw(requestParameters: V1ItemsSharepointsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedSharepointList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedSharepointListFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsList(requestParameters: V1ItemsSharepointsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedSharepointList> {
        const response = await this.v1ItemsSharepointsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsPartialUpdateRaw(requestParameters: V1ItemsSharepointsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepoint>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsSharepointsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedSharepointRequestToJSON(requestParameters.aPIPatchedSharepointRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsPartialUpdate(requestParameters: V1ItemsSharepointsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepoint> {
        const response = await this.v1ItemsSharepointsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsRetrieveRaw(requestParameters: V1ItemsSharepointsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepoint>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsSharepointsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsRetrieve(requestParameters: V1ItemsSharepointsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepoint> {
        const response = await this.v1ItemsSharepointsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the count of all unread feedback submissions for all sharepoint types.
     */
    async v1ItemsSharepointsUnreadFeedbackSubmissionCountRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepoint>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/unread_feedback_submission_count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointFromJSON(jsonValue));
    }

    /**
     * Returns the count of all unread feedback submissions for all sharepoint types.
     */
    async v1ItemsSharepointsUnreadFeedbackSubmissionCountRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepoint> {
        const response = await this.v1ItemsSharepointsUnreadFeedbackSubmissionCountRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsUpdateRaw(requestParameters: V1ItemsSharepointsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepointUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsSharepointsUpdate.');
        }

        if (requestParameters.aPISharepointUpdateRequest === null || requestParameters.aPISharepointUpdateRequest === undefined) {
            throw new runtime.RequiredError('aPISharepointUpdateRequest','Required parameter requestParameters.aPISharepointUpdateRequest was null or undefined when calling v1ItemsSharepointsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APISharepointUpdateRequestToJSON(requestParameters.aPISharepointUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointUpdateFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsUpdate(requestParameters: V1ItemsSharepointsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepointUpdate> {
        const response = await this.v1ItemsSharepointsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
