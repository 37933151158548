/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIBarcodeType,
    APIBarcodeTypeFromJSON,
    APIBarcodeTypeToJSON,
    APIBarcodeTypeRequest,
    APIBarcodeTypeRequestFromJSON,
    APIBarcodeTypeRequestToJSON,
    APIPaginatedBarcodeTypeList,
    APIPaginatedBarcodeTypeListFromJSON,
    APIPaginatedBarcodeTypeListToJSON,
    APIPatchedBarcodeTypeRequest,
    APIPatchedBarcodeTypeRequestFromJSON,
    APIPatchedBarcodeTypeRequestToJSON,
} from '../models';

export interface V1ItemsBarcodeTypesCreateRequest {
    aPIBarcodeTypeRequest: APIBarcodeTypeRequest;
}

export interface V1ItemsBarcodeTypesDestroyRequest {
    id: number;
}

export interface V1ItemsBarcodeTypesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsBarcodeTypesPartialUpdateRequest {
    id: number;
    aPIPatchedBarcodeTypeRequest?: APIPatchedBarcodeTypeRequest;
}

export interface V1ItemsBarcodeTypesRetrieveRequest {
    id: number;
}

export interface V1ItemsBarcodeTypesUpdateRequest {
    id: number;
    aPIBarcodeTypeRequest: APIBarcodeTypeRequest;
}

/**
 * 
 */
export class ItemsbarcodeTypesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesCreateRaw(requestParameters: V1ItemsBarcodeTypesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.aPIBarcodeTypeRequest === null || requestParameters.aPIBarcodeTypeRequest === undefined) {
            throw new runtime.RequiredError('aPIBarcodeTypeRequest','Required parameter requestParameters.aPIBarcodeTypeRequest was null or undefined when calling v1ItemsBarcodeTypesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBarcodeTypeRequestToJSON(requestParameters.aPIBarcodeTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesCreate(requestParameters: V1ItemsBarcodeTypesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesDestroyRaw(requestParameters: V1ItemsBarcodeTypesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesDestroy(requestParameters: V1ItemsBarcodeTypesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsBarcodeTypesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesListRaw(requestParameters: V1ItemsBarcodeTypesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedBarcodeTypeList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedBarcodeTypeListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesList(requestParameters: V1ItemsBarcodeTypesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedBarcodeTypeList> {
        const response = await this.v1ItemsBarcodeTypesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesPartialUpdateRaw(requestParameters: V1ItemsBarcodeTypesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBarcodeTypeRequestToJSON(requestParameters.aPIPatchedBarcodeTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesPartialUpdate(requestParameters: V1ItemsBarcodeTypesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesRetrieveRaw(requestParameters: V1ItemsBarcodeTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesRetrieve(requestParameters: V1ItemsBarcodeTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesUpdateRaw(requestParameters: V1ItemsBarcodeTypesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesUpdate.');
        }

        if (requestParameters.aPIBarcodeTypeRequest === null || requestParameters.aPIBarcodeTypeRequest === undefined) {
            throw new runtime.RequiredError('aPIBarcodeTypeRequest','Required parameter requestParameters.aPIBarcodeTypeRequest was null or undefined when calling v1ItemsBarcodeTypesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIBarcodeTypeRequestToJSON(requestParameters.aPIBarcodeTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesUpdate(requestParameters: V1ItemsBarcodeTypesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
