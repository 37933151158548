/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIEmailAddress,
    APIEmailAddressFromJSON,
    APIEmailAddressToJSON,
    APIEmailAddressRequest,
    APIEmailAddressRequestFromJSON,
    APIEmailAddressRequestToJSON,
    APIPaginatedEmailAddressList,
    APIPaginatedEmailAddressListFromJSON,
    APIPaginatedEmailAddressListToJSON,
    APIPatchedEmailAddressRequest,
    APIPatchedEmailAddressRequestFromJSON,
    APIPatchedEmailAddressRequestToJSON,
} from '../models';

export interface V1AuthEmailCreateRequest {
    aPIEmailAddressRequest: APIEmailAddressRequest;
}

export interface V1AuthEmailDestroyRequest {
    id: number;
}

export interface V1AuthEmailListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1AuthEmailPartialUpdateRequest {
    id: number;
    aPIPatchedEmailAddressRequest?: APIPatchedEmailAddressRequest;
}

export interface V1AuthEmailRetrieveRequest {
    id: number;
}

export interface V1AuthEmailUpdateRequest {
    id: number;
    aPIEmailAddressRequest: APIEmailAddressRequest;
}

/**
 * 
 */
export class AuthenticationemailApi extends runtime.BaseAPI {

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailCreateRaw(requestParameters: V1AuthEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIEmailAddress>> {
        if (requestParameters.aPIEmailAddressRequest === null || requestParameters.aPIEmailAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIEmailAddressRequest','Required parameter requestParameters.aPIEmailAddressRequest was null or undefined when calling v1AuthEmailCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIEmailAddressRequestToJSON(requestParameters.aPIEmailAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIEmailAddressFromJSON(jsonValue));
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailCreate(requestParameters: V1AuthEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIEmailAddress> {
        const response = await this.v1AuthEmailCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailDestroyRaw(requestParameters: V1AuthEmailDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AuthEmailDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailDestroy(requestParameters: V1AuthEmailDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthEmailDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailListRaw(requestParameters: V1AuthEmailListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedEmailAddressList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedEmailAddressListFromJSON(jsonValue));
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailList(requestParameters: V1AuthEmailListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedEmailAddressList> {
        const response = await this.v1AuthEmailListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailPartialUpdateRaw(requestParameters: V1AuthEmailPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIEmailAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AuthEmailPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedEmailAddressRequestToJSON(requestParameters.aPIPatchedEmailAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIEmailAddressFromJSON(jsonValue));
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailPartialUpdate(requestParameters: V1AuthEmailPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIEmailAddress> {
        const response = await this.v1AuthEmailPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailRetrieveRaw(requestParameters: V1AuthEmailRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIEmailAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AuthEmailRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIEmailAddressFromJSON(jsonValue));
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailRetrieve(requestParameters: V1AuthEmailRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIEmailAddress> {
        const response = await this.v1AuthEmailRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailUpdateRaw(requestParameters: V1AuthEmailUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIEmailAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AuthEmailUpdate.');
        }

        if (requestParameters.aPIEmailAddressRequest === null || requestParameters.aPIEmailAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIEmailAddressRequest','Required parameter requestParameters.aPIEmailAddressRequest was null or undefined when calling v1AuthEmailUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIEmailAddressRequestToJSON(requestParameters.aPIEmailAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIEmailAddressFromJSON(jsonValue));
    }

    /**
     * An endpoint for email address.
     */
    async v1AuthEmailUpdate(requestParameters: V1AuthEmailUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIEmailAddress> {
        const response = await this.v1AuthEmailUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
