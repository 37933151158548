/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIContact,
    APIContactFromJSON,
    APIContactToJSON,
    APIContactRequest,
    APIContactRequestFromJSON,
    APIContactRequestToJSON,
    APIPaginatedContactList,
    APIPaginatedContactListFromJSON,
    APIPaginatedContactListToJSON,
    APIPaginatedRichContactList,
    APIPaginatedRichContactListFromJSON,
    APIPaginatedRichContactListToJSON,
    APIPatchedContactRequest,
    APIPatchedContactRequestFromJSON,
    APIPatchedContactRequestToJSON,
    APIRichContact,
    APIRichContactFromJSON,
    APIRichContactToJSON,
} from '../models';

export interface V1ContactsContactsCreateRequest {
    aPIContactRequest: APIContactRequest;
}

export interface V1ContactsContactsDestroyRequest {
    id: number;
}

export interface V1ContactsContactsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ContactsContactsPartialUpdateRequest {
    id: number;
    aPIPatchedContactRequest?: APIPatchedContactRequest;
}

export interface V1ContactsContactsRetrieveRequest {
    id: number;
}

export interface V1ContactsContactsUpdateRequest {
    id: number;
    aPIContactRequest: APIContactRequest;
}

export interface V1ContactsRichContactsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ContactsRichContactsRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class ContactscontactApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsCreateRaw(requestParameters: V1ContactsContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.aPIContactRequest === null || requestParameters.aPIContactRequest === undefined) {
            throw new runtime.RequiredError('aPIContactRequest','Required parameter requestParameters.aPIContactRequest was null or undefined when calling v1ContactsContactsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIContactRequestToJSON(requestParameters.aPIContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsCreate(requestParameters: V1ContactsContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsDestroyRaw(requestParameters: V1ContactsContactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsDestroy(requestParameters: V1ContactsContactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContactsContactsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsListRaw(requestParameters: V1ContactsContactsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedContactList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedContactListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsList(requestParameters: V1ContactsContactsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedContactList> {
        const response = await this.v1ContactsContactsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsPartialUpdateRaw(requestParameters: V1ContactsContactsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedContactRequestToJSON(requestParameters.aPIPatchedContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsPartialUpdate(requestParameters: V1ContactsContactsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsRetrieveRaw(requestParameters: V1ContactsContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsRetrieve(requestParameters: V1ContactsContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsUpdateRaw(requestParameters: V1ContactsContactsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsUpdate.');
        }

        if (requestParameters.aPIContactRequest === null || requestParameters.aPIContactRequest === undefined) {
            throw new runtime.RequiredError('aPIContactRequest','Required parameter requestParameters.aPIContactRequest was null or undefined when calling v1ContactsContactsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIContactRequestToJSON(requestParameters.aPIContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsUpdate(requestParameters: V1ContactsContactsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsListRaw(requestParameters: V1ContactsRichContactsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedRichContactList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedRichContactListFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsList(requestParameters: V1ContactsRichContactsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedRichContactList> {
        const response = await this.v1ContactsRichContactsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsRetrieveRaw(requestParameters: V1ContactsRichContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsRetrieve(requestParameters: V1ContactsRichContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
