/* -------------------------- Design imports start -------------------------- */
import { useDropzone } from 'react-dropzone'
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material'
import DocumentView from '../DocumentView'
import LinearProgressWithLabel from '../widgets/LinearProgressWithLabel'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type Props = {
  reloadFiles?: (reloaded: boolean) => void
  reloaded?: boolean
  uploadPath?: string
  //component?: Component
  //product?: Product
  isAdmin?: boolean
  credentials?: any
  identityIds?: any
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function CADFileUpload(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: acceptedFiles => uploadFiles(acceptedFiles),
  })
  const log = new LogTool({context: 'CADFileUpload', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [loading] = useState<boolean>(false) // loading status for uploading files
  const [sceneLoading, setSceneLoading] = useState<boolean>(false) // loading status for 3D scene
  /* ----------------------------- Flag states end ---------------------------- */
  /* ------------------------------ Data states start ----------------------------- */
  const [filePath] = useState<string>() // path where to upload files
  // signedUrl needed to upload to private folders
  const [signedURL] = useState<string>('')
  // data thats returned from CADConvert Lambda function
  const [convertedData, setConvertedData] = useState<any>({})
  const [progress] = useState<number>(0) // progress of fileUpload
  const { t } = useTranslation()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchUser()
  }, [])

  /* useEffect(() => {
    props.reloadFiles ? props.reloadFiles(!props.reloaded) : null
    const fileKey: string = Object.values(fileKeys)[0]
    if (fileKey && fileKey.split('.').pop() === 'stp') {
      convertSTPtoSTL(fileKey)
    }
  }, [fileKeys])

  useEffect(() => {
    // upload files by location (e. g. uploaded in contracts uploads to /contracts folder)
    if (user && user?.attributes && user?.attributes.email) {
      if (props.product) {
        setFilePath(user.attributes.email + '/contracts/' + props.product.name + '/')
      } else if (props.component) {
        setFilePath(user.attributes.email + '/products/' + props.component.name + '/')
      } else if (props.uploadPath) {
        setFilePath(props.uploadPath.slice(-1) === '/' ? props.uploadPath : props.uploadPath + '/')
      } else {
        setFilePath(user.attributes.email + '/')
      }
    }
  }, [user, props.uploadPath]) */

  useEffect(() => {
    // bring convertedData in the right form to display in
    if (convertedData && convertedData.data) {
      const dataObject: any = {}
      const geometryData: string[] = convertedData.data.slice(1).split(',')
      dataObject['volume'] = geometryData[1].split('=')[1]
      dataObject['dimension_x'] = geometryData[2].split('=')[1]
      dataObject['dimension_y'] = geometryData[3].split('=')[1]
      dataObject['dimension_z'] = geometryData[4].split('=')[1]
      const key: string = geometryData[5].split('=')[1].split('/').splice(-1).join().slice(0, -1)
      dataObject['key'] = key
      setConvertedData(dataObject)
    }
  }, [convertedData])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  async function fetchUser() {
    log.info('fetchUser')
    /* const [res, error] = await handlePromise(
      'getCurrentAuthenticatedUser',
      Auth.currentAuthenticatedUser()
    )
    res ? setUser(res) : log.error('Error on fetching current authenticated user') */
  }
  /**
   * Handles uploading one ore multiple files
   * @params files list of files to upload
   */
  function uploadFiles(files: any[]) {
    files.map((file: any, index) => {
      props.isAdmin && filePath && filePath.split('/').length > 2
        ? handleUploadAdmin(file)
        : handleUpload(file)
    })
  }
  /**
   * Handles uploading single file by file path and sets progress of upload
   * @params file fileobject with key
   */
  async function handleUpload(file: any) {
    log.info('handleUpload')
    /* setLoading(true)
    setProgress(0)
    const [data, error] = await handlePromise(
      'uploadFile',
      Storage.put(filePath + file.name, file, {
        level: 'private',
        progressCallback(progress) {
          setProgress(Math.round(progress.loaded / progress.total) * 100)
        },
      })
    )
    setFileKeys(data)
    setLoading(false) */
  }
  /**
   * Handles uploading file as an admin using lambda layer assembleanplatformgraphQL
   * @params file fileobject with key
   */
  async function handleUploadAdmin(file: any) {
    log.info('handleUploadAdmin')
    /* if(filePath) {
      setLoading(true)
      try {
        const result = await s3Client.send(
          new PutObjectCommand({
            Bucket: 'assembleanplatforma89110a18c66412ba6ff5ad5db03c140259-staging', // current bucket (change if bucket changes)
            Key: 'private' +
            "/" + props.identityIds[filePath.split("/")[1]] +
            filePath + file.name, // uploads need to be on private folders
            Body: file,
          })
        )
        props.reloadFiles ? props.reloadFiles(!props.reloaded) : null
        const path: string | undefined = filePath?.split('/').slice(2).join('/')
        setFileKeys({ key: path + file.name } as any)
        setLoading(false)
      } catch (err) {
        log.error('Error: ', err)
        setLoading(false)
      }
    } */
  }

  const convertFileKey = (stlFileKey: string) => {
    return stlFileKey.split('/').splice(2).join('/')
  }
  /**
   * Converts a uploaded stp file to an stl file to extract geometric information and to display uploaded model
   * It uses the lambda function ConvertCAD for the conversion
   * @params fileKey key of uploaded object that needs to be converted
   */
  async function convertSTPtoSTL(fileKey: string) {
    log.info('convertSTPtoSTL')
    /* log.debug('FileKey: ' + fileKey)
    setSceneLoading(true)
    if (fileKey !== null) {
      const urlForKey: string = await Storage.get(fileKey, { level: 'private' })
      const key: string = decodeURIComponent(urlForKey).split('/').splice(3).join('/').split('?')[0] // get key from returned object url
      const [res, err] = await handlePromise(
        'convertCADtoSTL',
        API.graphql(graphqlOperation(convertCADtoSTL, { key: key }))
      )
      if (res) {
        addNotification({ message: t('common:success.uploadedFileSuccessfully'), color: 'success' })
        const convertedFileKey: string = convertFileKey(res.data.convertCADtoSTL.file_key)
        const signedURL = await Storage.get(convertedFileKey, { level: 'private' }) // get the signed url for download of a file
        setConvertedData(res.data.convertCADtoSTL)
        setSignedURL(signedURL)
        setSceneLoading(false)
      } else {
        setSceneLoading(false)
        addNotification({ message: t('common:error.errorOccurred'), color: 'error' })
      }
    } */
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Card>
      <CardContent>
        <Typography>{t('common:content.label.uploadTechnicalDrawing')}</Typography>
      </CardContent>
      <Card>
        <div className="container" style={{ margin: '5px' }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>{t('common:content.label.dragAndDrop')}</p>
          </div>
          {/* <FileList type={'CAD'} fileKeys={fileKeys} filePath={filePath as string} /> */}
        </div>
        {loading && <LinearProgressWithLabel value={progress} />}
      </Card>
      {signedURL !== '' && Object.values(convertedData).length !== 0 && !loading && (
        <Card sx={{ margin: '10px' }}>
          {!sceneLoading && convertedData.volume && (
            <div>
              <DocumentView link={signedURL} setSceneLoading={setSceneLoading} />
              <div style={{ margin: '10px' }}>
                <Typography>Name: {convertedData.key}</Typography>
                <Typography>Volume: {Math.round(convertedData.volume)}</Typography>
                <Typography>X: {Math.round(convertedData.dimension_x)}</Typography>
                <Typography>Y: {Math.round(convertedData.dimension_y)}</Typography>
                <Typography>Z: {Math.round(convertedData.dimension_z)}</Typography>
              </div>
            </div>
          )}
        </Card>
      )}
      {sceneLoading && (
        <div style={{ margin: '10px' }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ margin: 'auto' }}>
              <CircularProgress />
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ margin: 'auto' }}>{t('loadingSTLPreview')}</Typography>
          </Box>
        </div>
      )}
    </Card>
  )
}
