/* ------------------------ Functional imports start ------------------------ */
import React from "react";
import { Grid, IconButton, Modal } from "@mui/material";
import LogTool from "../../logger/logTools";
import { useTranslation } from "react-i18next";
import useMobileService from "../../hooks/useMobileService";
import { Close } from "@mui/icons-material";
import STLViewer from "./StlViewer";
/* ------------------------ Functional imports end -------------------------- */
/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  url: string
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function StlViewerModal(props: Props) {
/* -------------------------- Non state data start -------------------------- */
const { open, setOpen, url } = props
const log = new LogTool({ context: 'StlViewerModal', enable: true, logLevel: 'warn' })
const { t } = useTranslation(['common'])
const isMobile = useMobileService()
let size = isMobile ? { minHeight: '90vh', width: '95vw' } : { minHeight: '90vh', width: '90vw' }
/* --------------------------- Non state data end --------------------------- */

/* ---------------------------- Flag states start --------------------------- */
/* const [loading, setLoading] = React.useState<boolean>(true) */
/* ----------------------------- Flag states end ---------------------------- */

/* ---------------------------- Data states start --------------------------- */
/* ----------------------------- Data states end ---------------------------- */

/* ---------------------------- use Effects start --------------------------- */
/* ----------------------------- use Effects end ---------------------------- */

/* ------------------------- Callback functions start ----------------------- */
/* -------------------------- Callback functions end ------------------------ */

/* ------------------------- Render constants start ------------------------- */
/* -------------------------- Render constants end -------------------------- */

/* -------------------------------------------------------------------------- */
/*                              Render Component                              */
/* -------------------------------------------------------------------------- */

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="stl-viewer-modal"
      aria-describedby="stl-viewer-modal-for-viewing-stl-files"
    >
      <Grid
        container
        spacing={0}
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          width: size.width,
          minHeight: size.minHeight,
          backgroundColor: 'white',
          boxShadow: 24,
          padding: 4,
        }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <IconButton onClick={() => {
              /* setLoading(true) */
              setOpen(false)
            }}>
              <Close />
            </IconButton>
            </Grid>
          </Grid>
          {/* {loading && (
            <Grid item xs={12}>
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
            </Grid>
          )} */}
          <Grid item xs={12} sx={{
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}>
            <STLViewer
              url={url}
              height={isMobile ? 600 : 1000}
              width={isMobile ? 350 : 1100}
            />
        </Grid>
      </Grid>
    </Modal>
  )
}