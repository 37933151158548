/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const APIProductionOrderStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;
export type APIProductionOrderStatusEnum = typeof APIProductionOrderStatusEnum[keyof typeof APIProductionOrderStatusEnum];


export function APIProductionOrderStatusEnumFromJSON(json: any): APIProductionOrderStatusEnum {
    return APIProductionOrderStatusEnumFromJSONTyped(json, false);
}

export function APIProductionOrderStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIProductionOrderStatusEnum {
    return json as APIProductionOrderStatusEnum;
}

export function APIProductionOrderStatusEnumToJSON(value?: APIProductionOrderStatusEnum | null): any {
    return value as any;
}

