/* -------------------------- Design imports start -------------------------- */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { toast } from 'react-toastify'
import { Form, FormFieldType } from '../Form'
import InputFileList from '../../components/widgets/InputFileList'
import FileInput from '../../components/inputs/FileInput'
import SupplierDrawer from '../Supplier/SupplierDrawer'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useEffect, useRef, useState } from 'react'
import LogTool from '../../logger/logTools'
import { useTranslation } from 'react-i18next'
import { Category, Property, Supplier } from '../Supplier/utils/types'
import { FileInfo, FileUploadStatus, SelectOption } from '../../utils/types'
import { fetchSuppliers } from '../Supplier/utils/functions'
import {
  fetchArticleBrands,
  fetchArticleCategories,
  fetchArticleProperties,
  handleAddOption,
} from './utils/functions'
import {
  deleteFile,
  fetchFiles,
  missingRequiredFormFields,
  uploadFile,
} from '../../utils/functions'
import GetConstant from './utils/constants'
import React from 'react'
import MultilevelDrawer from '../../components/layout/MultilevelDrawer'
import VersionSubmit from '../Version/components/VersionSubmit'
import VersionDrawer from '../Version/components/VersionDrawer'
import CategoryDrawer from './CategoryDrawer'
import PropertyDrawer from './PropertyDrawer'
import ProcessSelection from './ManufacturingProcess.tsx/ProcessSelection'
import { Version } from '../Version/utils/types'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onCancel?: () => void
  onCreateConfirm: (a: any) => Promise<void>
  enableEditMode?: boolean
  onEditConfirm?: (input: any) => Promise<void>
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function ArticleDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    onCreateConfirm = () => null,
    enableEditMode = false,
    onEditConfirm = () => null,
  } = props
  const log = new LogTool({ context: 'ArticleDrawer', enable: false, logLevel: 'warn' })
  const { t } = useTranslation(['item'])
  const steps = [
    t('common:content.label.generalInformation'),
    t('common:content.label.process'),
    t('common:content.label.upload'),
  ]
  const customTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#16a9e4',
            },
            '&.Mui-completed': {
              color: '#16a9e4',
            },
          },
          text: {
            color: 'white', // Set the text color to white for all steps
          },
        },
      },
    },
  })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [prevOpen, setPrevOpen] = useState<boolean>(false)
  const [isPurchasedPart, setIsPurchasedPart] = useState(true)
  const [isManufacturedPart, setIsManufacturedPart] = useState(false)
  const [openSupplierModal, setOpenSupplierModal] = useState(false)
  // this modal needs to control its waiting state itself as it implements custom modal actions
  const [waitingOnAction, setWaiting] = useState(false)
  const [openVersionDrawer, setOpenVersionDrawer] = useState(false)
  const [openCategoryDrawer, setOpenCategoryDrawer] = useState(false)
  const [openPropertyDrawer, setOpenPropertyDrawer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const supplierStore = useRef<Supplier[]>([] as Supplier[])
  const [revertVersion, setRevertVersion] = useState<Version | undefined>(undefined)
  const brandStore = useRef<string[]>([] as string[])
  const propertiesStore = useRef<Property[]>([] as Property[])
  const categoriesStore = useRef<Category[]>([] as Category[])
  const [articleOptions, setArticleOptions] = useState<SelectOption[]>([])
  const [propertiesOptions, setPropertiesOptions] = useState<SelectOption[]>([])
  const [brandOptions, setBrandOptions] = useState<SelectOption[]>([])
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([])
  const [supplierFormInput, setSupplierFormInput] = useState<any>({} as Supplier)
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
  const [categoryFormInput, setCategoryFormInput] = useState<any>({} as Category)
  const [propertyFormInput, setPropertyFormInput] = useState<any>({} as Property)
  const [tempManProcess, setTempManProcess] = useState<any>({})
  const [tempPurchasedPart, setTempPurchasedPart] = useState<any>({})
  // we have to support both types ArticleFile and File as we have to deal with File objects before uploading the files, but with ArticleFile objects after fetching the files
  const [inputFiles, setInputFiles] = useState<any[]>([])
  const [skippedSteps, setSkippedSteps] = useState(new Set<number>())
  const [activeStep, setActiveStep] = useState<number>(0)
  /* ----------------------------- Data states end ---------------------------- */
  log.debug('formState.state', formState.state)
  log.debug('revertVersion', revertVersion)
  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchSuppliers({
      onSuccess: (suppliers: Supplier[]) => {
        supplierStore.current = [...supplierStore.current, ...suppliers]
      },
    })
    fetchArticleBrands({
      onSuccess: (brands: string[]) => (brandStore.current = brands),
    })

    fetchArticleCategories({
      onSuccess: (categories: Category[]) => (categoriesStore.current = categories),
    })
    fetchArticleProperties({
      onSuccess: (properties: Property[]) => {
        propertiesStore.current = properties
      },
    })
  }, [])

  useEffect(() => {
    if (formState.state?.manufacturingProcess) {
      setIsManufacturedPart(true)
      setIsPurchasedPart(false)
    } else {
      setIsManufacturedPart(false)
      setIsPurchasedPart(true)
    }
  }, [formState.state])

  useEffect(() => {
    if(formState.state?.supplier){
      setIsPurchasedPart(true)
      setIsManufacturedPart(false)
    }
  }
  ,[formState.state])

  /* ------------------------------ Effects start ----------------------------- */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Check if either a value or unit is specified for a dimension FormField. If so, the according unit / value is required!
   * @param dimension Key of the dimension FormField.
   * @returns
   */
  const isRequiredDimension = (dimension: string): boolean => {
    return (
      Object.hasOwn(formState.state, dimension) &&
      formState.state[dimension] !== undefined &&
      ((formState.state[dimension].value && !formState.state[dimension].unit) ||
        (!formState.state[dimension].value && formState.state[dimension].unit))
    )
  }

  /**
   * Check if all required FormFields of the current step are filled in correclty.
   * @param activeStep
   * @returns
   */
  const nextStepDisabled = (activeStep: number): boolean => {
    if (activeStep === 0) {
      return missingRequiredFormFields(
        [...generalFormFields, ...wareHouseFormFields, ...dimensionsFormFields],
        formState.state
      )
    } else if (activeStep === 1) {
      if (isPurchasedPart) {
        return missingRequiredFormFields(purchasedPartFormFields, formState.state)
      } else if (isManufacturedPart) {
        return missingRequiredFormFields(
          [
            {
              key: 'manufacturingProcess',
              required: true,
              validation: true,
              type: 'text',
              label: t('common:content.label.manufacturingProcess'),
            },
          ],
          { manufacturingProcess: formState.state.manufacturingProcess ?? {} }
        )
      }
    }
    return false
  }

  const isStepOptional = (step: number) => {
    return step === 4
  }

  const isStepSkipped = (step: number) => {
    return skippedSteps.has(step)
  }

  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setInputFiles((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadArticleFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setInputFiles(prev =>
          prev.map((listedFile: FileInfo) => {
            if (listedFile.name === file.name) {
              return { ...response, uploadStatus: 'success' }
            }
            return listedFile
          })
        )
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleRevertVersion = async (version: Version) => {
    log.debug('handleRevertVersion', version)
    setRevertVersion(version.data)

    // Iteriere über alle Schlüssel in version.data
    Object.keys(version.data).forEach(key => {
      // Prüfe, ob der Schlüssel auch in formState.state existiert und ob die Werte unterschiedlich sind
      if (formState.state.hasOwnProperty(key) && formState.state[key] !== version.data[key]) {
        if (key === 'category' || key === 'properties') {
          formState.setState((prevState: any) => ({
            ...prevState,
          }))
        } else if (
          key === 'length' ||
          key === 'width' ||
          key === 'height' ||
          key === 'weight' ||
          key === 'volume'
        ) {
          formState.setState((prevState: any) => ({
            ...prevState,
            value: version.data[key].value,
            unit: version.data[key].unit,
          }))
        } else {
          // Wenn ja, überschreibe den Wert in formState.state mit dem Wert aus version.data
          formState.setState((prevState: any) => ({
            ...prevState,
            [key]: version.data[key],
          }))
        }
      }
    })
  }

  const handleClose = (preventOnClose: boolean = false) => {
    // reset form state
    setWaiting(false)
    formState.setState({})
    setInputFiles([])
    setActiveStep(0)

    setOpen(false)
    setPrevOpen(false)
    !preventOnClose && onClose()
  }

  const handleCreateConfirm = async () => {
    // visual feedback
    setWaiting(true)

    // set loading state on all files to loading
    if (inputFiles.length > 0) {
      setInputFiles((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    // posting request to server and waiting on response
    const articleFilesUrl = await onCreateConfirm({ ...formState.state })
    // check if the user specified files to upload !
    if (inputFiles.length > 0 && articleFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of inputFiles) {
        uploadArticleFile(file, articleFilesUrl)
        // response.articleFilesUrl cannot be undefined here as the server created the article successfully and therefore created an article files url

        // store the articleFilesUrl in the inputFiles state to have it available in case something goes wrong
        /* setInputFiles((prev: FileInfo[]) => prev.map(
          file => Object.assign(file, {uploadUrl: response.articleFilesUrl})
        )) */
      }
    }
    setWaiting(false)
    handleClose()
  }

  const handleEditConfirm = async () => {
    // posting request to server and waiting on response
    log.debug('formState.state in editConfirm', formState.state)
    const articleFilesUrl = await onEditConfirm(formState.state)
    if (inputFiles.length > 0 && articleFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of inputFiles) {
        uploadArticleFile(file, articleFilesUrl)
        // response.articleFilesUrl cannot be undefined here as the server created the article successfully and therefore created an article files url

        // store the articleFilesUrl in the inputFiles state to have it available in case something goes wrong
        setInputFiles((prev: FileInfo[]) =>
          prev.map(file => Object.assign(file, { uploadUrl: articleFilesUrl }))
        )
      }
    }
    handleClose()
  }

  const handleAddArticleOption = async (
    input: any,
    url: 'supplier/suppliers/' | 'items/categories/' | 'items/properties/'
  ) => {
    handleAddOption(
      url,
      input,
      (response: Supplier | Category | Property) => {
        // update supplier select options
        switch (url) {
          case 'supplier/suppliers/':
            setSupplierOptions(prev => {
              return [...prev, { value: response.self, label: response.name }]
            })
            formState.setState((prev: any) => {
              return { ...prev, supplier: { value: response.self, label: response.name } }
            })
            toast.success(t('item:feedback.success.creatingSupplier'))
            break
          case 'items/categories/':
            log.debug('response', response)
            setCategoryOptions(prev => {
              return [...prev, { value: response.self, label: response.name }]
            })
            formState.setState((prev: any) => {
              return { ...prev, category: [{ value: response?.self, label: response?.name }] }
            })
            toast.success(t('item:feedback.success.creatingCategory'))
            break
          case 'items/properties/':
            setPropertiesOptions(prev => {
              return [...prev, { value: response.self, label: response.name }]
            })
            formState.setState((prev: any) => {
              return { ...prev, properties: { value: response.self, label: response.name } }
            })
            toast.success(t('item:feedback.success.creatingProperty'))
            break
          default:
            break
        }
      },
      (error: any) => {
        switch (url) {
          case 'supplier/suppliers/':
            toast.error(t('item:feedback.error.creatingSupplier'))
            break
          case 'items/categories/':
            toast.error(t('item:feedback.error.creatingCategory'))
            break
          case 'items/properties/':
            toast.error(t('item:feedback.error.creatingProperty'))
            break
          default:
            break
        }
        log.error('Error creating option', error)
      }
    )
  }
  log.debug('formState', formState.state)
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadArticleFile(file, url)
  }

  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setInputFiles(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }

  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: formState.state.self + 'files/' + file.self!.split('/').slice(-2)[0] + '/',
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempManProcess(formState.state.manufacturingProcess)
    formState.setState((prev: any) => {
      return {
        ...prev,
        supplier: tempPurchasedPart.supplier,
        countryOfOrigin: tempPurchasedPart.countryOfOrigin,
        batchSize: tempPurchasedPart.batchSize,
        minimumOrder: tempPurchasedPart.minimumOrder,
        leadTime: tempPurchasedPart.leadTime,
        manufacturingProcess: undefined,
      }
    })
    setIsPurchasedPart(event.target.checked)
    setIsManufacturedPart(false)
  }

  const handleManufactureCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // reset fields for purchased part
    setTempPurchasedPart((prev: any) => ({
      supplier: formState.state.supplier,
      countryOfOrigin: formState.state.countryOfOrigin,
      batchSize: formState.state.batchSize,
      minimumOrder: formState.state.minimumOrder,
      leadTime: formState.state.leadTime,
    }))
    formState.setState((prev: any) => {
      return {
        ...prev,
        supplier: undefined,
        countryOfOrigin: '',
        batchSize: 0,
        minimumOrder: undefined,
        leadTime: undefined,
        manufacturingProcess: tempManProcess,
      }
    })
    setIsManufacturedPart(event.target.checked)
    setIsPurchasedPart(false)
  }

  /* const handleAddtoBom = () => {
    history.push('/items/bom/overview')
  } */

  const handleNext = () => {
    let newSkipped = skippedSteps
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const generalFormFields = GetConstant({
    name: 'generalFormFields',
    brandOptions: brandOptions,
    articleOptions: articleOptions,
    categoryOptions: categoryOptions,
    propertyOptions: propertiesOptions,
    onAddPropertyOption: () => {
      setOpenPropertyDrawer(true)
    },
    onAddCategoryOption: () => {
      setOpenCategoryDrawer(true)
    },
  }) as FormFieldType[]
  const dimensionsFormFields = GetConstant({
    name: 'dimensionsFormFields',
    isRequiredDimension,
  }) as FormFieldType[]
  const purchasedPartFormFields = GetConstant({
    name: 'purchasedPartFormFields',
    supplierOptions: supplierOptions,
    onAddSupplierOption: () => {
      setOpenSupplierModal(true)
    },
  }) as FormFieldType[]

  const wareHouseFormFields = GetConstant({ name: 'wareHouseFormFields' }) as FormFieldType[]

  const CustomStepIcon = (props: any) => {
    return (
      <ThemeProvider theme={customTheme}>
        <StepIcon {...props} />
      </ThemeProvider>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  // initialize select options every time the modal opens
  // Note: do not move these state setting operations into a useEffect as that leads to unecessary re-rendering the component

  if (open === true && prevOpen === false) {
    setPrevOpen(true)

    // first filter any empty array slots, than map the articles to SelectOptions

    setBrandOptions(
      Array.isArray(brandStore?.current)
        ? brandStore.current.map((brand: any) => ({
            value: brand?.self,
            label: brand?.name,
          }))
        : []
    )

    setPropertiesOptions(
      propertiesStore.current.map((property: Property) => {
        return { value: property.self, label: property.name }
      })
    )

    setCategoryOptions(
      categoriesStore.current.map((category: Category) => {
        return { value: category.self, label: category.name }
      })
    )

    setSupplierOptions(
      supplierStore.current.map((supplier: Supplier) => {
        return { value: supplier.self, label: supplier.name }
      })
    )

    // open up manufacturing process form if we are in edit mode and the article contains process information
    if (
      formState.state.manufacturingProcess !== undefined &&
      formState.state.manufacturingProcess.name
    ) {
      setIsManufacturedPart(true)
      setIsPurchasedPart(false)
    }

    // when this modal is opened in edit mode, the article might have files that we need to display
    if (enableEditMode && Object.hasOwn(formState.state, 'files')) {
      fetchFiles({
        url: (formState.state.files[0]?.self as string) || '',
        onSuccess: (files: any) => setInputFiles(files),
      })
    }
  }

  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        size="big"
        customHeader={
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Typography variant="h6" noWrap sx={{ flexShrink: 0, mr: '2rem' }}>
              {enableEditMode
                ? t('item:content.heading.editArticle')
                : t('item:content.heading.createNewArticle')}
            </Typography>
            <Stepper
              activeStep={activeStep}
              sx={{ marginRight: '16px', marginLeft: '-8px', width: '100%' }}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: { optional?: React.ReactNode } = {}
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        }
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {activeStep === 0 ? (
              <Button
                id="cancelButton"
                onClick={() => setOpen(false)}
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                sx={{ mr: 1, textTransform: 'none' }}
              >
                {t('common:interaction.button.cancel')}
              </Button>
            ) : (
              <Button
                id="backButton"
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                onClick={handleBack}
                sx={{ mr: 1, textTransform: 'none' }}
              >
                {t('common:interaction.button.back')}
              </Button>
            )}
            {isStepOptional(activeStep) && (
              <Button id="skipButton" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t('common:interaction.button.skip')}
              </Button>
            )}
            {activeStep < steps.length - 1 ? (
              <Button
                id="nextButton"
                variant="contained"
                sx={{ marginLeft: '10px' }}
                disabled={nextStepDisabled(activeStep)}
                onClick={handleNext}
              >
                {t('common:interaction.button.next')}
              </Button>
            ) : (
              <LoadingButton
                id="createButton"
                variant="contained"
                sx={{ textTransform: 'none', marginLeft: '10px', color: 'whitesmoke' }}
                disabled={nextStepDisabled(activeStep)}
                loading={waitingOnAction}
                onClick={() => {
                  if (enableEditMode) {
                    handleEditConfirm()
                  } else {
                    handleCreateConfirm()
                  }
                }}
              >
                {enableEditMode
                  ? t('interaction.button.updateItem')
                  : t('interaction.button.createItem')}
              </LoadingButton>
            )}
          </Box>
        }
      >
        <React.Fragment>
          {activeStep === 0 && (
            <Grid>
              <Form
                editing={true}
                formFields={generalFormFields}
                formObject={formState.state!}
                onChange={value => formState.setState(value)}
              />
              <Accordion style={{ marginTop: '20px' }}>
                <AccordionSummary>
                  <ExpandMoreIcon />
                  <Typography variant="subtitle1">
                    {t('common:content.label.warehouse')}{' '}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Form
                    editing={true}
                    formFields={wareHouseFormFields}
                    formObject={formState.state!}
                    onChange={value => formState.setState(value)}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion style={{ marginTop: '20px' }}>
                <AccordionSummary>
                  <ExpandMoreIcon />
                  <Typography variant="subtitle1">
                    {t('common:content.label.dimensionWeight')}{' '}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="subtitle2" sx={{mb:'30px'}}>
                    {t('common:feedback.info.dimensionWeight')}
                  </Typography>
                  <Form
                    editing={true}
                    formFields={dimensionsFormFields}
                    formObject={formState.state!}
                    onChange={value => formState.setState(value)}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControlLabel
                    id="purchasedPartCheckbox"
                    control={<Checkbox checked={isPurchasedPart} onChange={handleCheckboxChange} />}
                    label={t('common:content.label.purchasedPart')}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="manufacturedPartCheckbox"
                        checked={isManufacturedPart}
                        onChange={handleManufactureCheckboxChange}
                      />
                    }
                    label={t('common:content.label.manufacturedPart')}
                  />
                </Grid>
              </Grid>
              {isPurchasedPart && (
                <Form
                  editing={true}
                  formFields={purchasedPartFormFields}
                  formObject={formState.state!}
                  onChange={value => formState.setState(value)}
                />
              )}
              {isManufacturedPart && (
                <React.Fragment>
                  <ProcessSelection formState={formState} />
                </React.Fragment>
              )}
            </Grid>
          )}
          {activeStep === 2 && (
            <>
              <Typography variant="h6">{t('files:content.label.uploadFiles')}</Typography>
              <FileInput
                inputState={{ state: inputFiles, setState: setInputFiles }}
                maxAllowedFiles={10}
              />
              {inputFiles.length > 0 && (
                <InputFileList
                  files={inputFiles}
                  noFilesInfo={t('item:feedback.info.noArticleFiles')}
                  enableEditMode={enableEditMode}
                  onRemoveFile={handleRemoveFileFromList}
                  onReUploadFile={handleReUploadFile}
                  onDeleteFile={handleDeleteFile}
                  sx={{
                    marginTop: '2rem',
                  }}
                />
              )}
              {enableEditMode && (
                <VersionSubmit formState={formState} setOpenVersionDrawer={setOpenVersionDrawer} />
              )}
            </>
          )}
        </React.Fragment>
      </MultilevelDrawer>
      <SupplierDrawer
        open={openSupplierModal}
        setOpen={setOpenSupplierModal}
        onCreateConfirm={() => handleAddArticleOption(supplierFormInput, 'supplier/suppliers/')}
        formState={{ state: supplierFormInput, setState: setSupplierFormInput }}
      />
      <CategoryDrawer
        open={openCategoryDrawer}
        setOpen={setOpenCategoryDrawer}
        onClose={() => null}
        formState={{ state: categoryFormInput, setState: setCategoryFormInput }}
        onCreateConfirm={() => handleAddArticleOption(categoryFormInput, 'items/categories/')}
        enableEditMode={false}
      />
      <PropertyDrawer
        open={openPropertyDrawer}
        setOpen={setOpenPropertyDrawer}
        onClose={() => null}
        formState={{ state: propertyFormInput, setState: setPropertyFormInput }}
        onCreateConfirm={() => handleAddArticleOption(propertyFormInput, 'items/properties/')}
        enableEditMode={false}
      />
      <VersionDrawer
        open={openVersionDrawer}
        setOpen={setOpenVersionDrawer}
        formState={formState}
        onConfirm={() => null}
        onRevertVersion={handleRevertVersion}
      />
    </>
  )
}
