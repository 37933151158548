/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedProductionWithdrawalList,
    APIPaginatedProductionWithdrawalListFromJSON,
    APIPaginatedProductionWithdrawalListToJSON,
    APIPatchedProductionWithdrawalRequest,
    APIPatchedProductionWithdrawalRequestFromJSON,
    APIPatchedProductionWithdrawalRequestToJSON,
    APIProductionWithdrawal,
    APIProductionWithdrawalFromJSON,
    APIProductionWithdrawalToJSON,
    APIProductionWithdrawalRequest,
    APIProductionWithdrawalRequestFromJSON,
    APIProductionWithdrawalRequestToJSON,
} from '../models';

export interface V1LogisticsProductionWithdrawalsCreateRequest {
    aPIProductionWithdrawalRequest: APIProductionWithdrawalRequest;
}

export interface V1LogisticsProductionWithdrawalsDestroyRequest {
    id: number;
}

export interface V1LogisticsProductionWithdrawalsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1LogisticsProductionWithdrawalsPartialUpdateRequest {
    id: number;
    aPIPatchedProductionWithdrawalRequest?: APIPatchedProductionWithdrawalRequest;
}

export interface V1LogisticsProductionWithdrawalsRetrieveRequest {
    id: number;
}

export interface V1LogisticsProductionWithdrawalsUpdateRequest {
    id: number;
    aPIProductionWithdrawalRequest: APIProductionWithdrawalRequest;
}

/**
 * 
 */
export class LogisticsproductionWithdrawalsApi extends runtime.BaseAPI {

    /**
     */
    async v1LogisticsProductionWithdrawalsCreateRaw(requestParameters: V1LogisticsProductionWithdrawalsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionWithdrawal>> {
        if (requestParameters.aPIProductionWithdrawalRequest === null || requestParameters.aPIProductionWithdrawalRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionWithdrawalRequest','Required parameter requestParameters.aPIProductionWithdrawalRequest was null or undefined when calling v1LogisticsProductionWithdrawalsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/production_withdrawals/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionWithdrawalRequestToJSON(requestParameters.aPIProductionWithdrawalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsCreate(requestParameters: V1LogisticsProductionWithdrawalsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionWithdrawal> {
        const response = await this.v1LogisticsProductionWithdrawalsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsDestroyRaw(requestParameters: V1LogisticsProductionWithdrawalsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsProductionWithdrawalsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/production_withdrawals/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsDestroy(requestParameters: V1LogisticsProductionWithdrawalsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1LogisticsProductionWithdrawalsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsListRaw(requestParameters: V1LogisticsProductionWithdrawalsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedProductionWithdrawalList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/production_withdrawals/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedProductionWithdrawalListFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsList(requestParameters: V1LogisticsProductionWithdrawalsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedProductionWithdrawalList> {
        const response = await this.v1LogisticsProductionWithdrawalsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsPartialUpdateRaw(requestParameters: V1LogisticsProductionWithdrawalsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionWithdrawal>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsProductionWithdrawalsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/production_withdrawals/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedProductionWithdrawalRequestToJSON(requestParameters.aPIPatchedProductionWithdrawalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsPartialUpdate(requestParameters: V1LogisticsProductionWithdrawalsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionWithdrawal> {
        const response = await this.v1LogisticsProductionWithdrawalsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsRetrieveRaw(requestParameters: V1LogisticsProductionWithdrawalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionWithdrawal>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsProductionWithdrawalsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/production_withdrawals/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsRetrieve(requestParameters: V1LogisticsProductionWithdrawalsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionWithdrawal> {
        const response = await this.v1LogisticsProductionWithdrawalsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsUpdateRaw(requestParameters: V1LogisticsProductionWithdrawalsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionWithdrawal>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsProductionWithdrawalsUpdate.');
        }

        if (requestParameters.aPIProductionWithdrawalRequest === null || requestParameters.aPIProductionWithdrawalRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionWithdrawalRequest','Required parameter requestParameters.aPIProductionWithdrawalRequest was null or undefined when calling v1LogisticsProductionWithdrawalsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/production_withdrawals/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionWithdrawalRequestToJSON(requestParameters.aPIProductionWithdrawalRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionWithdrawalFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsProductionWithdrawalsUpdate(requestParameters: V1LogisticsProductionWithdrawalsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionWithdrawal> {
        const response = await this.v1LogisticsProductionWithdrawalsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
