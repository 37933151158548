/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APICategory,
    APICategoryFromJSON,
    APICategoryToJSON,
    APICategoryRequest,
    APICategoryRequestFromJSON,
    APICategoryRequestToJSON,
    APIPaginatedCategoryList,
    APIPaginatedCategoryListFromJSON,
    APIPaginatedCategoryListToJSON,
    APIPatchedCategoryRequest,
    APIPatchedCategoryRequestFromJSON,
    APIPatchedCategoryRequestToJSON,
} from '../models';

export interface V1ItemsArticlesCategoriesCreateRequest {
    articlePk: string;
    aPICategoryRequest: APICategoryRequest;
}

export interface V1ItemsArticlesCategoriesDestroyRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesCategoriesListRequest {
    articlePk: string;
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesCategoriesPartialUpdateRequest {
    articlePk: string;
    id: number;
    aPIPatchedCategoryRequest?: APIPatchedCategoryRequest;
}

export interface V1ItemsArticlesCategoriesRetrieveRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesCategoriesUpdateRequest {
    articlePk: string;
    id: number;
    aPICategoryRequest: APICategoryRequest;
}

export interface V1ItemsCategoriesCreateRequest {
    aPICategoryRequest: APICategoryRequest;
}

export interface V1ItemsCategoriesDestroyRequest {
    id: number;
}

export interface V1ItemsCategoriesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsCategoriesPartialUpdateRequest {
    id: number;
    aPIPatchedCategoryRequest?: APIPatchedCategoryRequest;
}

export interface V1ItemsCategoriesRetrieveRequest {
    id: number;
}

export interface V1ItemsCategoriesUpdateRequest {
    id: number;
    aPICategoryRequest: APICategoryRequest;
}

/**
 * 
 */
export class ItemscategoriesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesCreateRaw(requestParameters: V1ItemsArticlesCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesCreate.');
        }

        if (requestParameters.aPICategoryRequest === null || requestParameters.aPICategoryRequest === undefined) {
            throw new runtime.RequiredError('aPICategoryRequest','Required parameter requestParameters.aPICategoryRequest was null or undefined when calling v1ItemsArticlesCategoriesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICategoryRequestToJSON(requestParameters.aPICategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesCreate(requestParameters: V1ItemsArticlesCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesDestroyRaw(requestParameters: V1ItemsArticlesCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesDestroy(requestParameters: V1ItemsArticlesCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesCategoriesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesListRaw(requestParameters: V1ItemsArticlesCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedCategoryList>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedCategoryListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesList(requestParameters: V1ItemsArticlesCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedCategoryList> {
        const response = await this.v1ItemsArticlesCategoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesPartialUpdateRaw(requestParameters: V1ItemsArticlesCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCategoryRequestToJSON(requestParameters.aPIPatchedCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesPartialUpdate(requestParameters: V1ItemsArticlesCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesRetrieveRaw(requestParameters: V1ItemsArticlesCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesRetrieve(requestParameters: V1ItemsArticlesCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesUpdateRaw(requestParameters: V1ItemsArticlesCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesUpdate.');
        }

        if (requestParameters.aPICategoryRequest === null || requestParameters.aPICategoryRequest === undefined) {
            throw new runtime.RequiredError('aPICategoryRequest','Required parameter requestParameters.aPICategoryRequest was null or undefined when calling v1ItemsArticlesCategoriesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APICategoryRequestToJSON(requestParameters.aPICategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesUpdate(requestParameters: V1ItemsArticlesCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesCreateRaw(requestParameters: V1ItemsCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.aPICategoryRequest === null || requestParameters.aPICategoryRequest === undefined) {
            throw new runtime.RequiredError('aPICategoryRequest','Required parameter requestParameters.aPICategoryRequest was null or undefined when calling v1ItemsCategoriesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICategoryRequestToJSON(requestParameters.aPICategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesCreate(requestParameters: V1ItemsCategoriesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesDestroyRaw(requestParameters: V1ItemsCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesDestroy(requestParameters: V1ItemsCategoriesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsCategoriesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesListRaw(requestParameters: V1ItemsCategoriesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedCategoryList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedCategoryListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesList(requestParameters: V1ItemsCategoriesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedCategoryList> {
        const response = await this.v1ItemsCategoriesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesPartialUpdateRaw(requestParameters: V1ItemsCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCategoryRequestToJSON(requestParameters.aPIPatchedCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesPartialUpdate(requestParameters: V1ItemsCategoriesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesRetrieveRaw(requestParameters: V1ItemsCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesRetrieve(requestParameters: V1ItemsCategoriesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesUpdateRaw(requestParameters: V1ItemsCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesUpdate.');
        }

        if (requestParameters.aPICategoryRequest === null || requestParameters.aPICategoryRequest === undefined) {
            throw new runtime.RequiredError('aPICategoryRequest','Required parameter requestParameters.aPICategoryRequest was null or undefined when calling v1ItemsCategoriesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APICategoryRequestToJSON(requestParameters.aPICategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesUpdate(requestParameters: V1ItemsCategoriesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
