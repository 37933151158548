/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIFamily,
    APIFamilyFromJSON,
    APIFamilyToJSON,
    APIFamilyRequest,
    APIFamilyRequestFromJSON,
    APIFamilyRequestToJSON,
    APIPaginatedFamilyList,
    APIPaginatedFamilyListFromJSON,
    APIPaginatedFamilyListToJSON,
    APIPatchedFamilyRequest,
    APIPatchedFamilyRequestFromJSON,
    APIPatchedFamilyRequestToJSON,
} from '../models';

export interface V1ItemsFamiliesCreateRequest {
    aPIFamilyRequest: APIFamilyRequest;
}

export interface V1ItemsFamiliesDestroyRequest {
    id: number;
}

export interface V1ItemsFamiliesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsFamiliesPartialUpdateRequest {
    id: number;
    aPIPatchedFamilyRequest?: APIPatchedFamilyRequest;
}

export interface V1ItemsFamiliesRetrieveRequest {
    id: number;
}

export interface V1ItemsFamiliesUpdateRequest {
    id: number;
    aPIFamilyRequest: APIFamilyRequest;
}

/**
 * 
 */
export class ItemsfamiliesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesCreateRaw(requestParameters: V1ItemsFamiliesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.aPIFamilyRequest === null || requestParameters.aPIFamilyRequest === undefined) {
            throw new runtime.RequiredError('aPIFamilyRequest','Required parameter requestParameters.aPIFamilyRequest was null or undefined when calling v1ItemsFamiliesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIFamilyRequestToJSON(requestParameters.aPIFamilyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesCreate(requestParameters: V1ItemsFamiliesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesDestroyRaw(requestParameters: V1ItemsFamiliesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesDestroy(requestParameters: V1ItemsFamiliesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsFamiliesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesListRaw(requestParameters: V1ItemsFamiliesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedFamilyList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedFamilyListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesList(requestParameters: V1ItemsFamiliesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedFamilyList> {
        const response = await this.v1ItemsFamiliesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesPartialUpdateRaw(requestParameters: V1ItemsFamiliesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedFamilyRequestToJSON(requestParameters.aPIPatchedFamilyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesPartialUpdate(requestParameters: V1ItemsFamiliesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesRetrieveRaw(requestParameters: V1ItemsFamiliesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesRetrieve(requestParameters: V1ItemsFamiliesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesUpdateRaw(requestParameters: V1ItemsFamiliesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesUpdate.');
        }

        if (requestParameters.aPIFamilyRequest === null || requestParameters.aPIFamilyRequest === undefined) {
            throw new runtime.RequiredError('aPIFamilyRequest','Required parameter requestParameters.aPIFamilyRequest was null or undefined when calling v1ItemsFamiliesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIFamilyRequestToJSON(requestParameters.aPIFamilyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesUpdate(requestParameters: V1ItemsFamiliesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
