/* -------------------------- Design imports start -------------------------- */
import Header from './Header'
import { Box, Container } from '@mui/material'
import Footer from './Footer'
import SideBar from './SideBar'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useHistory } from 'react-router-dom'
import useMobileService from '../../hooks/useMobileService'
import { ToastContainer } from 'react-toastify'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  children: React.ReactNode
  title?: string
  showBack?: boolean
  onBackClick?: () => void
  setUser: (user: any) => void
  darkMode: boolean
  setDarkMode: (darkMode: boolean) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export function MainLayout(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const isMobile = useMobileService()
  const { children, title, showBack, onBackClick, setUser, darkMode, setDarkMode } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [open, setOpen] = React.useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const history = useHistory()
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="row" // Neu hinzugefügt, um die Sidebar zu platzieren
    >
      {isMobile ? null : (
        <div>
          {open ? (
            <Box
              sx={{
                width: '240px',
                backgroundColor: '#ffffff',
                padding: '16px',
              }}
            ></Box>
          ) : null}
          <SideBar openSidebar={open} />
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Header
          title={title}
          centerTitle
          showBack={showBack}
          onBackClick={onBackClick || history.goBack}
          setUser={setUser}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          open={open}
          handleSideBar={() => setOpen(!open)}
        />
        <Box
          sx={{
            flex: '1 1 auto',
            overflowX: 'hidden',
            overflowY: 'auto',
            padding: isMobile ? '16px' : '64px',
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  )
}

export const AuthLayout = ({ children }: Partial<Props>) => (
  <Box minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
    {children}
  </Box>
)

export const EmptyLayout = ({ children }: Partial<Props>) => (
  <Box minHeight="100vh" display="flex" flexDirection="column">
    {children}
  </Box>
)
