/* -------------------------- Design imports start -------------------------- */
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: boolean
  label: string
  labelWidth?: number
  required?: boolean
  onChange: (value: boolean) => void
  validation?: (value: boolean) => boolean
  errorMessage?: string
  disabled?: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function CheckboxInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { label, value, onChange, labelWidth = 100, disabled = false, required } = props
  /* --------------------------- Non state data end --------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Checkbox
              id={label}
              color="primary"
              checked={value}
              required={required}
              onChange={() => onChange(!value)}
              name={label}
              disabled={disabled}
            />
          }
          label={label}
        />
      </FormControl>
    </div>
  )
}
