/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIGuestRequest,
    APIGuestRequestFromJSON,
    APIGuestRequestToJSON,
    APIGuestRequestRequest,
    APIGuestRequestRequestFromJSON,
    APIGuestRequestRequestToJSON,
    APIPaginatedGuestRequestList,
    APIPaginatedGuestRequestListFromJSON,
    APIPaginatedGuestRequestListToJSON,
    APIPatchedGuestRequestRequest,
    APIPatchedGuestRequestRequestFromJSON,
    APIPatchedGuestRequestRequestToJSON,
} from '../models';

export interface V1GuestRequestRequestsCreateRequest {
    aPIGuestRequestRequest: APIGuestRequestRequest;
}

export interface V1GuestRequestRequestsDestroyRequest {
    id: number;
}

export interface V1GuestRequestRequestsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1GuestRequestRequestsPartialUpdateRequest {
    id: number;
    aPIPatchedGuestRequestRequest?: APIPatchedGuestRequestRequest;
}

export interface V1GuestRequestRequestsRetrieveRequest {
    id: number;
}

export interface V1GuestRequestRequestsUpdateRequest {
    id: number;
    aPIGuestRequestRequest: APIGuestRequestRequest;
}

/**
 * 
 */
export class GuestRequestrequestApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsCreateRaw(requestParameters: V1GuestRequestRequestsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequest>> {
        if (requestParameters.aPIGuestRequestRequest === null || requestParameters.aPIGuestRequestRequest === undefined) {
            throw new runtime.RequiredError('aPIGuestRequestRequest','Required parameter requestParameters.aPIGuestRequestRequest was null or undefined when calling v1GuestRequestRequestsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIGuestRequestRequestToJSON(requestParameters.aPIGuestRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsCreate(requestParameters: V1GuestRequestRequestsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequest> {
        const response = await this.v1GuestRequestRequestsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsDestroyRaw(requestParameters: V1GuestRequestRequestsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsDestroy(requestParameters: V1GuestRequestRequestsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GuestRequestRequestsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsListRaw(requestParameters: V1GuestRequestRequestsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedGuestRequestList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedGuestRequestListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsList(requestParameters: V1GuestRequestRequestsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedGuestRequestList> {
        const response = await this.v1GuestRequestRequestsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsPartialUpdateRaw(requestParameters: V1GuestRequestRequestsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedGuestRequestRequestToJSON(requestParameters.aPIPatchedGuestRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsPartialUpdate(requestParameters: V1GuestRequestRequestsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequest> {
        const response = await this.v1GuestRequestRequestsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsRetrieveRaw(requestParameters: V1GuestRequestRequestsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsRetrieve(requestParameters: V1GuestRequestRequestsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequest> {
        const response = await this.v1GuestRequestRequestsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsUpdateRaw(requestParameters: V1GuestRequestRequestsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsUpdate.');
        }

        if (requestParameters.aPIGuestRequestRequest === null || requestParameters.aPIGuestRequestRequest === undefined) {
            throw new runtime.RequiredError('aPIGuestRequestRequest','Required parameter requestParameters.aPIGuestRequestRequest was null or undefined when calling v1GuestRequestRequestsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIGuestRequestRequestToJSON(requestParameters.aPIGuestRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsUpdate(requestParameters: V1GuestRequestRequestsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequest> {
        const response = await this.v1GuestRequestRequestsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
