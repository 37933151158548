/* -------------------------- Design imports start -------------------------- */
import {
  Box,
  FormControlLabel,
  Grid,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Switch,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Table from 'antd/es/table'
import InfoField from '../../../components/inputs/InfoField'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import { useUserContext } from '../../../utils/context'
import { Address, FileInfo, FileUploadStatus, HTTPMethod, SelectOption } from '../../../utils/types'
import { createAddress, deleteFile, fetchAddresses, formInputToAPIAddressJSON, getAddressRepresentation, handleAPICallV1, isPrivileged, missingRequiredFormFields, uploadFile } from '../../../utils/functions'
import { updateOffer } from '../../Request/utils/functions'
import { Offer } from '../../Request/utils/types'
import GetConstant from '../utils/constants'
import { Form, FormFieldType } from '../../Form'
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import FieldLabel from '../../../components/widgets/FieldLabel'
import { APIContract } from '../../../generated-types'
import FileInput from '../../../components/inputs/FileInput'
import InputFileList from '../../../components/widgets/InputFileList'
import Button from '../../../components/Button'
import AddressDrawer from '../../Address/components/AddressDrawer'


/* ------------------------- Functional imports end ------------------------- */

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onCancel?: () => void
  onCreateConfirm?: (a: any) => Promise<void>
  enableEditMode?: boolean
  onEditConfirm?: (input: any) => void
  setTabValue?: (value: number) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function ContractDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    onCancel = () => null,
    enableEditMode = false,
    onCreateConfirm = () => null,
    onEditConfirm = () => alert('TODO: provide a onEditConfirm callback!'),
    setTabValue,
  } = props

  const customTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#16a9e4',
            },
            '&.Mui-completed': {
              color: '#16a9e4',
            },
          },
          text: {
            color: 'white', // Set the text color to white for all steps
          },
        },
      },
    },
  })
  /* -------------------------- Non state data start -------------------------- */
  const { t } = useTranslation(['contract', 'common'])
  const log = new LogTool({ context: 'ContractDrawer', enable: true, logLevel: 'warn' })
  const steps = [
    t('common:content.label.generalInformation'),
    t('common:content.label.shipping'),
    t('common:content.label.upload'),
  ]
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [sameBillingAddress, setSameBillingAddress] = useState(true)
  const [waitingOnAction, setWaiting] = useState(false)
  const [prevOpen, setPrevOpen] = useState<boolean>(false)
  const [openAddressDrawer, setOpenAddressDrawer] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const addressStore = useRef<Address[]>([])
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>()
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  const [skippedSteps, setSkippedSteps] = useState(new Set<number>())
  const [activeStep, setActiveStep] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [addressFormInput, setAddressFormInput] = useState<any>()
  // key of the address select through that the addAddressOption callback was fired
  const [newAddressReceiver, setNewAddressReceiver] = useState<string | undefined>()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchAddresses({
      onSuccess: (addresses: Address[]) => {
        addressStore.current = [...addressStore.current, ...addresses]
        setAddressOptions(
          addressStore.current.map(
            addr => ({value: addr.self, label: getAddressRepresentation(addr, true, false)})
          )
        )
      },
    })
  }, [])

  useEffect(() => {
    if (open && !prevOpen) {
      setPrevOpen(true)
    }
  }, [open])

  useEffect(() => {
    if (formState.state.billingAddress && formState.state.shippingAddress) {
      setSameBillingAddress(formState.state.billingAddress === formState.state.shippingAddress)
    }
  }, [formState.state.billingAddress, formState.state.shippingAddress])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadContractFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileInput(prev =>
          prev.map((listedFile: FileInfo) => {
            if (listedFile.name === file.name) {
              return { ...response, uploadStatus: 'success' }
            }
            return listedFile
          })
        )
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }

  /**
   * Check if all required FormFields of the current step are filled in correclty.
   * @param activeStep
   * @returns
   */
  const nextStepDisabled = (activeStep: number): boolean => {
    if (activeStep === 0) {
      return missingRequiredFormFields([...contractFormFields], formState.state)
    } else if (activeStep === 1) {
      return missingRequiredFormFields([...addressFormFields], formState.state)
    }
    return false
  }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleCreateContract = async () => {
    log.debug('handleCreateRequest -> ', formState.state)
    setWaiting(true)
    // set loading state on all files to loading
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    // posting request to server and waiting on response
    const contractFilesUrl = await onCreateConfirm({ ...formState.state })
    log.debug('requestFilesUrl -> ', contractFilesUrl)
    // check if the user specified files to upload !
    if (fileInput.length > 0 && contractFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of fileInput) {
        uploadContractFile(file, contractFilesUrl)
        // response.articleFilesUrl cannot be undefined here as the server created the article successfully and therefore created an article files url

        // store the articleFilesUrl in the inputFiles state to have it available in case something goes wrong
        /* setInputFiles((prev: FileInfo[]) => prev.map(
          file => Object.assign(file, {uploadUrl: response.articleFilesUrl})
        )) */
      }
    }
    setWaiting(false)
    handleClose()
    updateOffer(
      { ...formState.state.offer, status: 1 },
      [],
      (offer: Offer) => {
        log.debug('Successfully updated offer -> ', offer)
      },
      (error: any) => {
        log.error('Error updating offer -> ', error)
      }
    )
    setTabValue && setTabValue(isPrivileged(user, 'STAFF') ? 3 : 2)
  }

  log.debug('Offer -> ', formState.state.offer)

  const handleClose = (preventOnClose: boolean = false) => {
    // reset form state
    setWaiting(false)
    formState.setState({})
    setFileInput([])
    setActiveStep(0)

    setOpen(false)
    setPrevOpen(false)
    !preventOnClose && onClose()
  }

  const handleContract = (operation: 'create' | 'update') => {
    operation === 'create' ? handleCreateContract() : handleUpdateContract()
  }

  const handleUpdateContract = async () => {
    log.info('Begin update contract')
    /* setWaiting(true)
    updateContract(
      formState.state,
      [],
      (contract: Contract) => {
        log.info('Contract updated successfully')
        toast.success(t('contract:feedback.success.contractUpdated'))
        setWaiting(false)
        handleClose()
      },
      (error: any) => {
        log.error('Error updating contract', error)
        toast.error(t('contract:feedback.error.contractNotUpdated'))
        setWaiting(false)
      }
    ) */
  }

  const handleAddAddressOption = async (addressFormInput: any): Promise<'SUCCESS' | 'ERROR'> => {
    log.info('Begin creating address option')
    const json = formInputToAPIAddressJSON(addressFormInput)
    log.debug('Input ->', addressFormInput, ', json ->', json)

    // create new address
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'contacts/addresses/',
      undefined,
      json,
    )

    if(!error && response) {
      log.info('Success creating address option')
      const address = createAddress(response)

      // update addressStore
      addressStore.current = [...addressStore.current, address]
      setAddressOptions(
        addressStore.current.map(addr => ({
          value: addr.self,
          label: getAddressRepresentation(addr, true, false)
        }))
      )

      // update formInput
      formState.setState((prev: any) => {
        prev[newAddressReceiver!] = {
          value: address.self,
          label: getAddressRepresentation(address, true, false)
        }
        return prev
      })

      setNewAddressReceiver(undefined)
      return 'SUCCESS'
    } else {
      log.error('Error creating address option', error)
      return 'ERROR'
    }
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }

  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadContractFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }

  const isStepOptional = (step: number) => {
    return step === 4
  }

  const isStepSkipped = (step: number) => {
    return skippedSteps.has(step)
  }

  const handleNext = () => {
    let newSkipped = skippedSteps
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  /* ------------------------- Render constants start ------------------------- */
  const certificateOptions = GetConstant({ name: 'certificateOptions' }) as SelectOption[]
  const contractFormFieldsDisabled = GetConstant({
    name: 'contractFormFieldsDisabled',
  }) as FormFieldType[]
  const contractFormFields = GetConstant({
    name: 'contractFormFields',
    certificateOptions: certificateOptions,
    selectedProduct: formState.state?.article?.self || '',
  }) as FormFieldType[]
  const addressFormFields = GetConstant({
    name: 'addressFormFields',
    onAddAddressOption: () => {
      setOpenAddressDrawer(true)
      setNewAddressReceiver('shippingAddress')
    }
  }) as FormFieldType[]
  const billingAddressFormFields = GetConstant({
    name: 'billingAddressFormFields',
    onAddBillingAddressOption: () => {
      setOpenAddressDrawer(true)
      setNewAddressReceiver('billingAddress')
    }
  }) as FormFieldType[]
  const shippingFormFields = GetConstant({ name: 'shippingFormFields' }) as FormFieldType[]

  const CustomStepIcon = (props: any) => {
    return (
      <ThemeProvider theme={customTheme}>
        <StepIcon {...props} />
      </ThemeProvider>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */

  log.debug('FormInput ->', formState.state)

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        size="big"
        title={t('common:content.label.createContract') as string}
        customHeader={
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Typography variant="h6" noWrap sx={{ flexShrink: 0, mr: '2rem' }}>
              {enableEditMode
                ? t('contract:content.label.editContract')
                : t('common:content.label.createContract')}
            </Typography>
            <Stepper
              activeStep={activeStep}
              sx={{ marginRight: '16px', marginLeft: '-8px', width: '100%' }}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: { optional?: React.ReactNode } = {}
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        }
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {activeStep === 0 ? (
              <Button
                id="cancelButton"
                onClick={() => setOpen(false)}
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                sx={{ mr: 1 }}
              >
                {t('common:interaction.button.cancel')}
              </Button>
            ) : (
              <Button
                id="backButton"
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t('common:interaction.button.back')}
              </Button>
            )}
            {isStepOptional(activeStep) && (
              <Button id="skipButton" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t('common:interaction.button.skip')}
              </Button>
            )}
            {activeStep < steps.length - 1 ? (
              <Button
                id="nextButton"
                variant="contained"
                sx={{ marginLeft: '10px' }}
                disabled={nextStepDisabled(activeStep)}
                onClick={handleNext}
              >
                {t('common:interaction.button.next')}
              </Button>
            ) : (
              <LoadingButton
                id="createButton"
                variant="contained"
                sx={{ marginLeft: '10px', color: 'whitesmoke', textTransform: 'none' }}
                disabled={nextStepDisabled(activeStep)}
                loading={waitingOnAction}
                onClick={() => {
                  handleContract(enableEditMode ? 'update' : 'create')
                }}
              >
                {enableEditMode
                  ? t('common:interaction.button.save')
                  : t('common:interaction.button.create')}
              </LoadingButton>
            )}
          </Box>
        }
      >
        <React.Fragment>
          {activeStep === 0 && (
            <>
              <Typography variant="h6" style={{ marginBottom: '10px', marginTop: '20px' }}>
                {t('common:content.label.generalInformation')}
              </Typography>
              <Form
                sx={{ marginBottom: '20px' }}
                formObject={formState.state?.offer?.article ?? { name: '', number: '' }}
                formFields={contractFormFieldsDisabled}
                editing={false}
                onChange={() => {}}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldLabel value={t('common:content.label.prices') as string} />
                </Grid>
                <Grid item xs={12}>
                  <Table
                    rowSelection={{
                      type: 'radio',
                      selectedRowKeys,
                      onSelect: (record, selected, selectedRows) => {
                        const priceObject : any = {}
                        priceObject[record.quantity.toString()] = record.price.split(" ")[0]
                        formState.setState({
                          ...formState.state,
                          price: priceObject
                        })
                        setSelectedRowKeys(selected ? [record.key] : [])
                      },
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => {
                          const priceObject : any = {}
                          priceObject[record.quantity.toString()] = record.price.split(" ")[0]
                          formState.setState({
                            ...formState.state,
                            price: priceObject
                          })
                          setSelectedRowKeys([record.key])
                        },
                      }
                    }}
                    dataSource={
                      formState.state.offer?.prices &&
                      Object.keys(formState.state.offer?.prices).map(
                        (key: string, index: number) => {
                          return {
                            key: `${key}-${index}`,
                            quantity: key,
                            price: formState.state.offer?.prices[key] + ' €',
                          }
                        }
                      )
                    }
                    columns={[
                      {
                        title: t('common:content.label.quantity'),
                        dataIndex: 'quantity',
                        key: 'quantity',
                      },
                      {
                        title: t('common:content.label.price'),
                        dataIndex: 'price',
                        key: 'price',
                      },
                    ]}
                    pagination={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoField
                    label={t('common:content.label.certificate')}
                    fullWidth
                    value={formState.state?.offer?.request?.certificate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <InfoField
                    label={t('common:content.label.wishDate')}
                    fullWidth
                    value={formState.state?.offer?.request?.wish_date}
                  />
                </Grid>
              </Grid>
              <Form
                formObject={formState.state}
                formFields={contractFormFields}
                onChange={(value: any) => formState.setState(value as APIContract)}
                editing={true}
              />
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography variant="h6" style={{ marginTop: '15px', marginBottom: '10px' }}>
                {t('common:content.label.shipping')}
              </Typography>
              <Form
                formObject={formState.state}
                formFields={addressFormFields}
                onChange={(value: any) => formState.setState(value as APIContract)}
                editing={true}
              />
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{
                    marginBottom: '20px',
                  }}
                  control={
                    <Switch
                      id="sameBillingAddressSwitch"
                      checked={sameBillingAddress}
                      onChange={(e: any) => setSameBillingAddress(e.target.checked)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={t('common:interaction.button.sameBillingAddress')}
                />
              </Grid>
              {!sameBillingAddress && (
                <Form
                  formObject={formState.state}
                  formFields={billingAddressFormFields}
                  onChange={(value: any) => formState.setState(value as APIContract)}
                  editing={true}
                />
              )}
              <Form
                formObject={formState.state}
                formFields={shippingFormFields}
                onChange={(value: any) => formState.setState(value as APIContract)}
                editing={true}
              />
            </>
          )}
          {activeStep === 2 && (
            <Grid>
              <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
                {t('common:content.label.documents')}
              </Typography>
              <FileInput
                inputState={{ state: fileInput, setState: setFileInput }}
                maxAllowedFiles={10}
              />
              {fileInput.length > 0 && (
                <InputFileList
                  files={fileInput}
                  noFilesInfo=""
                  enableEditMode={false}
                  onRemoveFile={handleRemoveFileFromList}
                  onReUploadFile={handleReUploadFile}
                  onDeleteFile={handleDeleteFile}
                />
              )}
            </Grid>
          )}
        </React.Fragment>
      </MultilevelDrawer>
      <AddressDrawer
        open={openAddressDrawer}
        setOpen={setOpenAddressDrawer}
        formState={{
          state: addressFormInput,
          setState: setAddressFormInput,
        }}
        onConfirm={(input: any) => handleAddAddressOption(input)}
      />
    </>
  )
}
