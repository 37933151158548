/* -------------------------- Design imports start -------------------------- */
import {
  Chip,
  Grid,
} from '@mui/material'
import InfoField from '../../components/inputs/InfoField'
import InfoPanel from '../../components/layout/InfoPanel'
  /* --------------------------- Design imports end --------------------------- */

  /* ------------------------ Functional imports start ------------------------ */
  import { t } from 'i18next'
  import { useHistory } from 'react-router-dom'
  import LogTool from '../../logger/logTools'
import Button from '../../components/Button'
  /* ------------------------- Functional imports end ------------------------- */

  type Props = {
    open: boolean
    setOpen: (open: boolean) => void
    setOpenFilesDrawer: (open: boolean) => void
    infoData: any
    title?: string
    disabled?: boolean
  }

  /* -------------------------------------------------------------------------- */
  /*                               Start Component                              */
  /* -------------------------------------------------------------------------- */

  export default function GuestRequestInfoPanel(props: Props) {
    /* -------------------------- Non state data start -------------------------- */
    const { open, setOpen, setOpenFilesDrawer, infoData, title = '', disabled = false } = props
    const log = new LogTool({ context: 'GuestRequestInfoPanel', enable: true, logLevel: 'warn' })
    /* --------------------------- Non state data end --------------------------- */
    const history = useHistory()
    /* ---------------------------- Flag states start --------------------------- */

    /* ----------------------------- Flag states end ---------------------------- */

    /* ---------------------------- Data states start --------------------------- */

    /* ----------------------------- Data states end ---------------------------- */

    /* ------------------------------ Effects start ----------------------------- */
    /* ------------------------------- Effects end ------------------------------ */

    /* ------------------------- Utility functions start ------------------------ */

    /* -------------------------- Utility functions end ------------------------- */

    /* ------------------------ Callback functions start ------------------------ */
    // some callback functions...
    /* ------------------------- Callback functions end ------------------------- */

    /* ------------------------- Render constants start ------------------------- */
    /* -------------------------- Render constants end -------------------------- */

    /* ------------------------ Pre render actions start ------------------------ */
    // Effects that should happen on certain re-renders, that are not allowed in
    // useEffect because of setting states
    // e.g. setArticleOptions on the NewItemPage should be called every time the modal
    // is opened
    /* ------------------------- Pre render actions end ------------------------- */

    /* -------------------------------------------------------------------------- */
    /*                              Render Component                              */
    /* -------------------------------------------------------------------------- */

    log.debug("InfoData -> ", infoData)
    return (
        <InfoPanel
        open={open}
        setOpen={setOpen}
        label={t('request:content.label.guestRequestInformation')}
        allowEdit={false}
        disabled={true}
      >
        <Grid container spacing={2} style={{ padding: '20px' }}>
          <Grid item xs={12}>
            <InfoField
              label={t('common:content.label.name')}
              fullWidth
              value={infoData?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.email')}
              fullWidth
              value={infoData?.email}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.company')}
              fullWidth
              value={infoData?.company}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.address')}
              fullWidth
              value={infoData?.address}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.zipCode')}
              fullWidth
              value={infoData?.zipCode}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.city')}
              fullWidth
              value={infoData?.city}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.country')}
              fullWidth
              value={infoData?.country}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.phone')}
              fullWidth
              value={infoData?.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('request:content.label.description')}
              fullWidth
              value={infoData?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('common:content.label.createdAt')}
              fullWidth
              value={infoData?.createdAt}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('common:content.label.status')}
              fullWidth
              value={infoData?.status}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoField
              label={t('common:content.label.editors')}
              fullWidth
              value={""}
            />
            {infoData?.editors && infoData.editors.length > 0 && infoData.editors.map((editor: any) => {
              return <Chip key={editor.id} label={`${editor.first_name} ${editor.last_name}`} sx={{marginLeft: "10px"}} />
            })}
          </Grid>
          <Grid item xs={12}>
            <Button
              id="showFilesButton"
              fullWidth
              variant="contained"
              onClick={() => {
                setOpenFilesDrawer(true)
              }}
            >
              {t('common:interaction.button.showFiles')}
            </Button>
          </Grid>
        </Grid>
      </InfoPanel>
    )
  }
