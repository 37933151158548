import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import reportWebVitals from './reportWebVitals'
import Routes from './Routes'
import i18n from './utils/i18n'
import './styles.css'
import CssBaseline from '@mui/material/CssBaseline'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MaintenanceInfoPage from './pages/MaintenanceInfoPage'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (process.env.REACT_APP_MAINTENANCE_MODE === 'TRUE') {
  root.render(
    <I18nextProvider i18n={i18n}>
      <Suspense>      
        <CssBaseline />
          <MaintenanceInfoPage />
      </Suspense>
    </I18nextProvider>
  )
} else {
  root.render(
    <I18nextProvider i18n={i18n}>
      <Suspense>      
        <CssBaseline />
        <Routes />
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />      
      </Suspense>
    </I18nextProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
