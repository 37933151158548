/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import ChatMessage from './ChatMessage'
import { useTranslation } from 'react-i18next'
import { handleAPICallV1 } from '../../../../utils/functions'
import { HTTPMethod } from '../../../../utils/types'
import { toast } from 'react-toastify'
import LogTool from '../../../../logger/logTools'
import { useChatContext } from '../../utils/context'
import { Message } from '../../utils/types'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  verticalSpacing?: number
  sx?: React.CSSProperties
}

/**
 * Component that holds the conversation of a channel
 * Sender messages on the right side (editable)
 * Receiver messages on the left side (not editable)
 */

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function ChatConversation(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { verticalSpacing, sx = {} } = props
  const { messages, setMessages, newMessages, activeChannel, activeUser } = useChatContext()
  const log = new LogTool({ context: 'ChatConversation', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [showMessageActions, setShowMessageActions] = useState<number>(-1)
  const [lastVisited, setLastVisited] = useState<any>(undefined)
  const { t } = useTranslation(['chat', 'common'])
  const chatContainerRef = React.useRef<HTMLDivElement | null>(null)
  /* ----------------------------- Data states end ---------------------------- */
  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    scrollToBottom()
  }, [messages, newMessages])

  useEffect(() => {
    fetchLastVisited()
  }, [activeChannel])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  async function fetchLastVisited() {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      activeChannel?.self as string,
      undefined,
      undefined
    )
    if (response) {
      const lastReadAt = response.memberships.find(
        (m: any) => m.user === activeUser.self
      )?.last_read_at
      setLastVisited(lastReadAt)
    } else {
      log.error('Error fetching last visited: ', error)
    }
  }

  log.debug('lastVisited: ', lastVisited)

  async function onMessageDelete(message: Message) {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      message.self,
      undefined,
      {
        body: t('chat:content.label.messageDeleted'),
      },
      'text'
    )
    if (!error) {
      toast.success(t('common:feedback.success.deleteMessageSuccess'))
    } else {
      log.error('Error deleting message: ', error)
      toast.error(t('common:feedback.error.deleteMessageError'))
    }
  }

  async function onMessageEdit(message: any, editValue: string, edit: boolean, setEdit: any) {
    setEdit(!edit)
    if (edit) {
      const [response, error] = await handleAPICallV1(HTTPMethod.PATCH, message.self, undefined, {
        body: editValue,
      })
      if (response) {
        toast.success(t('common:feedback.success.editMessageSuccess'))
      } else {
        log.error('Error editing message: ', error)
        toast.error(t('common:feedback.error.editMessageError'))
      }
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------- Render constants starts ------------------------ */
  const messageDivider = () => {
    return (
      /* show divider with date of that the channel was last visited */
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          color: '#BDBDBD',
        }}
      >
        <hr style={{ width: '20%' }} />
        <span>
          {t('chat:content.label.lastVisited')} {new Date(lastVisited).toLocaleString()}
        </span>
        <hr style={{ width: '20%' }} />
      </div>
    )
  }

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  const chatContainerStyle: React.CSSProperties = {
    ...sx,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '8px',
    margin: 'auto',
    overflowY: 'scroll',
  }

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div ref={chatContainerRef} style={chatContainerStyle}>
      {activeChannel?.self &&
        messages[activeChannel.self]?.map((message: any, index: number) => {
          const received: boolean =
            message.author.self && !message.author.self.includes(activeUser.id)
          return (
            <>
              <ChatMessage
                key={index}
                messageIndex={index}
                onMessageEdit={onMessageEdit}
                onMessageDelete={onMessageDelete}
                message={message}
                setShowMessageActions={setShowMessageActions}
                showMessageActions={showMessageActions}
                received={received}
                activeUser={activeUser}
                cornerRadius={8}
              />
            </>
          )
        })}
      {activeChannel?.self &&
        newMessages[activeChannel.self]?.length > 0 &&
        newMessages[activeChannel.self]?.filter((m: any) => m.author.self === activeUser.self)
          .length === 0 &&
        messageDivider()}
      {activeChannel?.self &&
        newMessages[activeChannel.self]?.length > 0 &&
        newMessages[activeChannel.self]
          ?.filter((m: any) => m.channel === activeChannel.self)
          .map((message: any, index: number) => {
            const received: boolean =
              message.author.self && !message.author.self.includes(activeUser.id)
            const newMessageIndex: number = messages[activeChannel.self]?.length + index
            return (
              <>
                <ChatMessage
                  key={newMessageIndex}
                  messageIndex={newMessageIndex}
                  onMessageEdit={onMessageEdit}
                  onMessageDelete={onMessageDelete}
                  message={message}
                  setShowMessageActions={setShowMessageActions}
                  showMessageActions={showMessageActions}
                  received={received}
                  activeUser={activeUser}
                  cornerRadius={8}
                />
              </>
            )
          })}
    </div>
  )
}
