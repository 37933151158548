/* -------------------------- Design imports start -------------------------- */
import { Autocomplete, TextField } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/* ------------------------- Functional imports end ------------------------- */

type Props<T> = {
  name: string
  value: T
  options: { value: T; label?: string; disabled?: boolean }[]
  freeSolo?: boolean
  disabled?: boolean
  required?: boolean
  validation?: boolean
  labelWidth?: number
  onChange: (value: T) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function SelectWithTextInput<T extends string | number | boolean>(props: Props<T>) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    name,
    value,
    options,
    freeSolo = false,
    disabled,
    labelWidth = 100,
    onChange,
    required,
    validation,
  } = props
  const translatedOptions = () =>
    options.map((value: any) => {
      return value.label
    })
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(required ? true : false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['common', 'profile'])
  const [inputValue, setInputValue] = useState<string>('')
  const [newOptions, setNewOptions] = useState<any[]>(translatedOptions())
  /* ----------------------------- Data states end ---------------------------- */
  /* ----------------------------- useEffects start ----------------------------- */

  /* ------------------------------ useEffects end ------------------------------ */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Autocomplete
      id={name}
      options={newOptions}
      noOptionsText={t('common:content.label.enterForNewOption')}
      isOptionEqualToValue={(option: T, value: T) => option == value}
      onInputChange={(e, newValue) => {
        onChange(newValue as T)
        setInputValue(newValue)
      }}
      freeSolo={freeSolo}
      disabled={disabled}
      defaultValue={value}
      renderInput={params => (
        <TextField
          {...params}
          label={name}
          required={required}
          error={error}
          variant="outlined"
          onKeyDown={e => {
            if (
              e.key === 'Enter' &&
              newOptions.findIndex((o: any) => o.value === inputValue) === -1
            ) {
              setNewOptions([...newOptions, { value: inputValue as T, label: inputValue }])
              onChange(inputValue as T)
            }
          }}
        />
      )}
      disablePortal /* List of suggestions will not be rendered on top of everything */
    />
  )
}
