/* -------------------------- Design imports start -------------------------- */
import { IconButton, Table, TableCell, TableHead, TableRow } from '@mui/material'
import { Delete } from '@mui/icons-material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { formatBytes } from '../../utils/functions'
import { useTranslation } from 'react-i18next'
import LogTool from '../../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */
type File = {
  name: string
  size: number
  path: string
  type: string
  lastModified: number
  webkitRelativePath: string
}

type Props = {
  fileList: File[]
  setFileList: (fileList: File[]) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function UnauthFileList(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { fileList, setFileList } = props
  const log = new LogTool({context: 'UnauthFileList', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const { t } = useTranslation()
  /* ----------------------------- Flag states end ---------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const deleteFile = (name: string) => {
    // delete file from fileList
    const key = fileList.findIndex(file => file.path === name)
    fileList.splice(key, 1)
    setFileList([...fileList])
  }
/* ------------------------- Callback functions end ------------------------- */

/* ------------------------- Render constants start ------------------------- */
  const files = fileList.map((file, index) => {
    // return files in a table row
    log.debug(file)
    return (
      <TableRow key={index}>
        <TableCell>{file.name}</TableCell>
        <TableCell>{formatBytes(file.size)}</TableCell>
        <TableCell>
          <IconButton onClick={() => deleteFile(file.path)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  })
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('name')}</TableCell>
          <TableCell>{t('size')}</TableCell>
          <TableCell>{t('delete')}</TableCell>
        </TableRow>
      </TableHead>
      {files}
    </Table>
  )
}
