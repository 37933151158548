/* -------------------------- Design imports start -------------------------- */
import { FormControl, FormHelperText, TextField } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import LogTool from '../../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: string
  limits?: { minInput?: number; maxInput?: number }
  label: string
  labelWidth?: number
  onChange: (value: number | string) => void
  validation?: boolean
  helperText?: string
  errorMessage?: string
  disabled?: boolean
  required?: boolean
  showError?: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function NumberInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    label,
    value,
    onChange,
    labelWidth = 100,
    disabled = false,
    required = false,
    errorMessage = '',
    validation = true,
    showError = true,
  } = props
  let { limits = { minInput: Number.MIN_SAFE_INTEGER, maxInput: Number.MAX_SAFE_INTEGER } } = props

  // parse limits
  if (!Object.hasOwn(limits, 'minInput')) {
    limits = { minInput: Number.MIN_SAFE_INTEGER, maxInput: limits.maxInput }
  }
  if (!Object.hasOwn(limits, 'maxInput')) {
    limits = { minInput: limits.minInput, maxInput: Number.MAX_SAFE_INTEGER }
  }

  const log = new LogTool({ context: 'NumberInput', enable: false })

  const isValidInput = (input: any): boolean => {
    if (typeof input === 'undefined') {
      log.debug(`isValidInput(${input})`, 'Input is undefined, return true.')
      return true
    }
    if (input === '') {
      log.debug(`isValidInput(${input})`, 'Input is empty string, return true.')
      return true
    }
    if (!/^[0-9]+((.|,)[0-9]+)?$/.test(input)) {
      log.debug(
        `isValidInput(${input})`,
        'Input does not only contain numbers and decimal delimiters, return false.'
      )
      return false
    }

    const parsedInput = parseFloat(input)
    if (Number.isNaN(parsedInput)) {
      log.debug(`isValidInput(${input})`, 'Input is NaN, return false.')
      return false
    }
    if (parsedInput >= limits.minInput! && parsedInput < limits.maxInput!) {
      log.debug(
        `isValidInput(${input})`,
        'Input is a number within the provided limits, return true.'
      )
      return true
    }
    return false
  }
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>((validation && !isValidInput(value)) || (required && !value))
  /* ----------------------------- Flag states end ---------------------------- */

  useEffect(() => {
    if(required && value === '') setError(true)
  }, [value, validation])

  log.debug('GLOBAL', 'value ->', value, ', error ->', error)
  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        type="text" // if number is used the input label shrinks even if value !== ''
        //inputMode='numeric'
        variant="outlined"
        label={label}
        id={label}
        helperText={props.helperText}
        value={value || ''}
        onChange={e => {
          onChange(e.currentTarget.value)
          log.debug(
            `onChange(${e.currentTarget.value})`,
            'Typeof ->',
            typeof e.currentTarget.value,
            ', parseFloat(value) ->',
            parseFloat(e.currentTarget.value)
          )
          validation && setError(!isValidInput(e.currentTarget.value))
        }}
        autoComplete="number"
        disabled={disabled}
        required={required}
        error={error && showError}
        aria-describedby="text-input"
        inputProps={{
          'aria-label': 'weight',
          /* defaultValue: '1',
          min: '0',
          step: '1', */
        }}
      />
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
