/* --------------------------- Design states start -------------------------- */
import { Autocomplete, AutocompleteRenderOptionState, CircularProgress, FormControl, FormHelperText, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material'
/* ---------------------------- Design states end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: string) => void
  validation?: boolean
  errorMessage?: string
  disabled?: boolean
  required?: boolean
  multiline?: boolean
  showError?: boolean
  loadSuggestions?: () => Promise<{ value: string | number | boolean | null; label?: string; disabled?: boolean }[]>
  suggestions?: { value: string | number | boolean | null; label?: string; disabled?: boolean }[]
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default React.memo(function TextInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    label,
    value = '',
    onChange,
    labelWidth = 100,
    disabled = false,
    required = false,
    errorMessage = '',
    validation,
    multiline,
    showError = true,
    loadSuggestions,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(showError)
  const [suggestions, setSuggestions] = useState<any[]>(props.suggestions || [])
  /* ----------------------------- Flag states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if(showError){
      validation !== undefined && value === "" ? setError(true) : setError(false)
    }
  }, [showError, validation, value])

  /* ------------------------------- Effects end ------------------------------ */

  const handleOpen = useCallback(async () => {
    if(props.suggestions) {
      setSuggestions(props.suggestions)
    }
    if (loadSuggestions) {
      const suggestions = await loadSuggestions()
      if(suggestions.length > 0) {
        setSuggestions(suggestions)
      }
    }
  }, [loadSuggestions, props.suggestions])

  const handleChange = (e: any, value: any) => {
    if(value && value.label){
      onChange(value.label)
    } else if(value === null) {
      onChange("")
    } else if(value) {
      onChange(value)
    }
  }

  /* ------------------------- Render constants start ------------------------- */
  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => {
    return (
      <ListItem {...props} key={option.value} style={{ display: 'flex', alignItems: 'center', }}>
        <ListItemText primary={option.label} />
      </ListItem>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <FormControl fullWidth variant="outlined">
      {(loadSuggestions || props.suggestions) && (
        <Autocomplete
          id={label}
          value={value}
          onChange={handleChange}
          onInputChange={(e, value : string) => {
            handleChange(e, value)
          }}
          freeSolo
          disabled={disabled}
          onOpen={handleOpen}
          openOnFocus
          options={suggestions}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      )}
      {!(loadSuggestions || props.suggestions) && (
        <TextField
            id={label}
            variant="outlined"
            multiline={multiline}
            rows={multiline ? 4 : 1}
            label={label}
            value={value || ''}
            onChange={e => {
              e.currentTarget.value !== '' || validation === undefined
                ? setError(false)
                : setError(true)
              onChange(e.currentTarget.value)
            }}
            autoComplete="text"
            required={required}
            disabled={disabled}
            error={error}
            aria-describedby="text-input"
            inputProps={{
              'aria-label': 'weight',
            }}            
          />
      )}
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
})
