/* -------------------------- Design imports start -------------------------- */
import ChatTextInput from './ChatTextInput'
import { Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import ChatConversation from './ChatConversation'
import NewChannel from '../Channel/NewChannel'
import { AccountCircle, Add, Cancel } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import RemoveMemberModal from '../Member/RemoveMemberModal'
import AddMemberModal from '../Member/AddMemberModal'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import useMobileService from '../../../../hooks/useMobileService'
import Page from '../../../../components/layout/Page'
import { useTranslation } from 'react-i18next'
import { getPKfromSelf, handleAPICallV1 } from '../../../../utils/functions'
import { FetchKwargs, HTTPMethod } from '../../../../utils/types'
import { useWebSocket } from '../../../../WebSocketProvider'
import { toast } from 'react-toastify'
import LogTool from '../../../../logger/logTools'
import { Channel, MessageObject } from '../../utils/types'
import { useChatContext } from '../../utils/context'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  sx?: React.CSSProperties
}

/**
 * Container to view the Chat shows reference, product, textinput and messages
 * Also holds subscriptions and logic for messages
 */

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function ChatView(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { sx = {} } = props
  const {
    messages,
    setMessages,
    newMessages,
    setNewMessages,
    activeUser,
    fetchChannels,
    activeChannel,
    setActiveChannel,
    setChannels,
    handleMobileCloseChatView,
    visitedChannel,
    createChannelMode,
    handleChannelClick,
  } = useChatContext()
  const { socket } = useWebSocket()
  const log = new LogTool({ context: 'ChatView', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [removeMemberModal, setRemoveMemberModal] = useState<boolean>(false)
  const [addMemberModal, setAddMemberModal] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['chat', 'common'])
  const isMobile = useMobileService()
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const [users, setUsers] = useState<any[]>([])
  const [memberToRemove, setMemberToRemove] = useState<any>(null)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchUsers()
  }, [addMemberModal])

  useEffect(() => {
    // load messages if the channel is not null
    if (activeChannel && activeChannel.self) {
      fetchMessages()
    }
  }, [activeChannel])

  /* ------------------------------- Effects end ------------------------------ */
  // TODO: update messages when new messages are send/received
  /* useEffect(() => {
    // listen for new messages on socket
    if (!socket) return
    socket.addEventListener('message', (event: any) => {
      const messageObject: any = JSON.parse(event.data)
      console.log(messageObject)
    })
  }, [socket]) */
  // TODO: update messages when new messages are deleted
  // TODO: update messages when new messages are edited
  // TODO: Differentiate between new and old messages in a chat
  // TODO: write function that fetches messages
  // TODO: write function that sends messages

  /* ------------------------- Utility functions start ------------------------ */
  async function fetchUsers() {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'accounts/users/?expand=roles__sub_roles&exclude=roles__self',
      undefined,
      undefined
    )
    if (response) {
      setUsers(response.results)
    } else {
      log.error('Error fetching users', error)
    }
  }

  async function fetchMessages() {
    if (!activeChannel) return
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      `chat/channel/${getPKfromSelf(activeChannel.self)}/messages/?expand=self,author`,
      undefined,
      undefined
    )
    if (response) {
      if (response.results && response.results.length > 0) {
        const messages = response.results
        const sortedMessages = messages.sort((a: any, b: any) => {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        })
        const newMessages = sortedMessages.filter((message: any) => message.isNewMessage === true)
        const oldMessages = sortedMessages.filter((message: any) => message.isNewMessage === false)
        const newMessagesObject: any = {
          [activeChannel.self]: newMessages,
        }
        const oldMessagesObject: any = {
          [activeChannel.self]: oldMessages,
        }
        setNewMessages(newMessagesObject)
        setMessages(oldMessagesObject)
      } else {
        setMessages({ [activeChannel.self]: [] })
      }
    } else {
      log.error('Error fetching messages', error)
    }
  }

  async function sendMessage(message: string) {
    if (!activeChannel) return
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      `chat/channel/${getPKfromSelf(activeChannel.self)}/messages/`,
      undefined,
      {
        body: message,
      }
    )
    if (response) {
      const messageObject = { [activeChannel.self]: [response] }
      sendSocketMessage(messageObject)
    } else {
      log.error('Error sending message', error)
    }
  }

  function sendSocketMessage(message: any) {
    if (!socket) return
    if (!message) return
    if (!activeChannel) return
    message[activeChannel.self][0]['author'] = activeUser
    socket.send(
      JSON.stringify({
        type: 'chat_message',
        data: {
          message: JSON.stringify(message),
        },
        room: activeChannel.key,
      })
    )
  }

  async function handleRemoveMember(user: any) {
    if (!activeChannel) return
    const [response, error] = await handleAPICallV1(
      HTTPMethod.DELETE,
      `${activeChannel.self}member/`,
      undefined,
      {
        user: user.self,
      },
      'text'
    )
    if (!error) {
      toast.success(t('common:feedback.success.removeMemberSuccess'))
    } else {
      log.error('Error removing member', error)
      toast.error(t('common:feedback.error.removeMemberError'))
    }
    fetchChannels({
      onSuccess: (channels: Channel[]) => {
        setChannels(channels)
      },
      parameter: ['expand=members,creator,messages'],
    })
    if (typeof activeChannel.members !== 'string') {
      setActiveChannel({
        ...activeChannel,
        members: activeChannel.members.filter((member: any) => member.self !== user.self),
      })
    }
  }

  async function handleAddMembers(userUrls: string[]) {
    if (!activeChannel) return
    const results: any[] = []
    Promise.all(
      userUrls.map(async url => {
        const [response, error] = await handleAPICallV1(
          HTTPMethod.POST,
          `${activeChannel.self}member/`,
          undefined,
          {
            user: url,
          },
          'text'
        )
        if (response) {
          results.push(true)
        } else {
          log.error('Error adding members', error)
          results.push(false)
        }
      })
    )
    if (results.includes(false)) {
      toast.error(t('common:feedback.error.addMembersError'))
    } else {
      toast.success(t('common:feedback.success.addMembersSuccess'))
    }
    fetchChannels({
      onSuccess: (channels: Channel[]) => {
        setChannels(channels)
      },
      parameter: ['expand=members,creator,messages'],
    })
    const newUsers: any[] = users.filter((user: any) => userUrls.includes(user.self))
    if (typeof activeChannel.members !== 'string') {
      setActiveChannel({
        ...activeChannel,
        members: [...activeChannel.members, ...newUsers],
      })
    }
  }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */

  const mobileCloseActionStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignContent: 'center',
  }

  const headerStyle: React.CSSProperties = {
    background: '#16a9e4',
    height: 'max-content',
    borderRadius: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
  }

  const subjectStyle: React.CSSProperties = {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    maxHeight: '150px',
    overflowY: 'auto',
    color: 'whitesmoke',
  }

  const chatConversationStyle: React.CSSProperties = {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: '1 1 auto',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '4px',
  }

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  if (activeChannel) {
    return (
      <div
        aria-label="chatView-container"
        style={{ ...sx, display: 'flex', flexDirection: 'column' }}
      >
        {isMobile && (
          <div aria-label="chatView-mobileCloseAction" style={mobileCloseActionStyle}>
            <IconButton id="closeButton" onClick={handleMobileCloseChatView} sx={{ display: 'flex' }}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div aria-label="chatView-header" style={headerStyle}>
          <div aria-label="chatView-header-subject" style={{ display: 'flex' }}>
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ marginRight: '5px', color: 'whitesmoke' }}
            >
              {t('content.label.subject') + ':'}
            </Typography>
            <Typography variant="subtitle1" sx={subjectStyle}>
              {activeChannel && activeChannel.name}
            </Typography>
          </div>
          <Grid container direction="row" alignItems="center" gap={1}>
            <Grid item>
              <AccountCircle style={{ color: 'whitesmoke' }} />
              {activeChannel &&
                typeof activeChannel.members !== 'string' &&
                activeChannel.members &&
                activeChannel.members.length > 0 &&
                activeChannel.members.map((user: any) => {
                  log.debug('user ->', user)
                  if (
                    typeof activeChannel.creator !== 'string' &&
                    user.self === activeChannel.creator.self
                  )
                    return (
                      <Tooltip key={user.id} title={user.email} placement="bottom">
                        <Chip
                          key={user.id}
                          label={
                            user.firstName && user.lastName
                              ? `${user.firstName} ${user.lastName} (Host)`
                              : user.email
                          }
                          sx={{
                            marginRight: '5px',
                            marginBottom: '10px',
                            marginLeft: '5px',
                            color: 'whitesmoke',
                          }}
                        />
                      </Tooltip>
                    )
                  return (
                    <Tooltip key={user.id} title={user.email} placement="bottom">
                      {typeof activeChannel.creator !== 'string' &&
                      activeUser.self === activeChannel.creator.self ? (
                        <Chip
                          key={user.id}
                          label={ user.firstName && user.lastName
                            ? `${user.firstName} ${user.lastName} (Host)`
                            : `${user.first_name} ${user.last_name} (Host)` }
                          onDelete={() => {
                            setMemberToRemove(user)
                            setRemoveMemberModal(true)
                          }}
                          sx={{
                            marginRight: '5px',
                            marginBottom: '10px',
                            marginLeft: '5px',
                            color: 'whitesmoke',
                          }}
                        />
                      ) : (
                        <Chip
                          key={user.id}
                          label={user.firstName && user.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : `${user.first_name} ${user.last_name}`}
                          sx={{
                            marginRight: '5px',
                            marginBottom: '10px',
                            marginLeft: '5px',
                            color: 'whitesmoke',
                          }}
                        />
                      )}
                    </Tooltip>
                  )
                })}
              {typeof activeChannel.creator !== 'string' &&
                activeUser.self === activeChannel.creator.self && (
                  <Tooltip title={t('content.label.addNewMemberTooltip')} placement="bottom">
                    <Chip
                      label={<Add style={{ marginTop: '5px' }} />}
                      sx={{
                        marginRight: '5px',
                        marginBottom: '10px',
                        marginLeft: '5px',
                        color: 'whitesmoke',
                      }}
                      onClick={() => {
                        setAddMemberModal(true)
                      }}
                      clickable
                    />
                  </Tooltip>
                )}
            </Grid>
          </Grid>
        </div>
        <ChatConversation verticalSpacing={25} sx={chatConversationStyle} />
        <ChatTextInput
          sendMessage={sendMessage}
          sx={{ height: 'max-content', overflowY: 'auto' }}
        />
        <RemoveMemberModal
          memberToRemove={memberToRemove}
          removeMemberModal={removeMemberModal}
          setRemoveMemberModal={setRemoveMemberModal}
          handleRemoveMember={handleRemoveMember}
        />
        <AddMemberModal
          addMemberModal={addMemberModal}
          setAddMemberModal={setAddMemberModal}
          handleAddMembers={handleAddMembers}
          users={users}
          channel={activeChannel}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      </div>
    )
  } else {
    return !isMobile ? (
      <NewChannel />
    ) : (
      <Page>
        <div aria-label="chatView-mobileCloseAction" style={mobileCloseActionStyle}>
          <IconButton onClick={handleMobileCloseChatView} sx={{ display: 'flex' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <NewChannel />
      </Page>
    )
  }
}
