/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React from 'react';
import LogTool from '../../../logger/logTools';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../utils/context';
import InfoPanel from '../../../components/layout/InfoPanel';
import { Chip, Grid } from '@mui/material';
import Button from '../../../components/Button';
import InfoField from '../../../components/inputs/InfoField';
import { getPKfromSelf, isPrivileged } from '../../../utils/functions';
import { Check, Close } from '@mui/icons-material';
import moment from 'moment';
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOpenFilesDrawer: (open: boolean) => void;
  infoData: any;
  title?: string;
  disabled?: boolean;
}

/* -------------------------------------------------------------------------- */
/*                                Start Component                             */
/* -------------------------------------------------------------------------- */
export default function BillInfoPanel(props: Props) {
/* -------------------------- Non state data start -------------------------- */
const { open, setOpen, setOpenFilesDrawer, infoData, title = '', disabled = false } = props
const log = new LogTool({ context: 'BillInfoPanel', enable: true, logLevel: 'warn' })
const { t } = useTranslation()
const { user } = useUserContext()
/* --------------------------- Non state data end --------------------------- */

/* ---------------------------- Flag states start --------------------------- */
/* ----------------------------- Flag states end ---------------------------- */

/* ---------------------------- Data States start --------------------------- */
/* ----------------------------- Data States end ---------------------------- */

/* ----------------------------- Effects start ------------------------------ */
/* ------------------------------ Effects end ------------------------------- */

/* -------------------------- Utility functions start ----------------------- */
/* --------------------------- Utility functions end ------------------------ */

/* ------------------------ Callback functions start ------------------------ */
/* ------------------------- Callback functions end ------------------------- */

/* ------------------------- Render constants start ------------------------- */
/* -------------------------- Render constants end -------------------------- */

/* ------------------------ Pre render actions start ------------------------ */
/* ------------------------- Pre render actions end ------------------------- */

log.debug("Info Data -> ", infoData)

/* -------------------------------------------------------------------------- */
/*                              Render Component                              */
/* -------------------------------------------------------------------------- */
return (
  <InfoPanel
    open={open}
    setOpen={setOpen}
    label={t('contract:content.label.billInformation')}
    allowEdit={false}
    disabled={true}
  >
    <Grid container spacing={2} style={{ padding: "20px"}}>
      <Grid item xs={12}>
        <InfoField
          label={t('contract:content.label.billNumber')}
          fullWidth
          value={infoData?.self ? getPKfromSelf(infoData?.self) : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoField
          label={t('contract:content.label.contractNumber')}
          fullWidth
          value={infoData?.contract?.self ? getPKfromSelf(infoData?.contract?.self) : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoField
          label={t('common:content.label.createdAt')}
          fullWidth
          value={moment(infoData?.createdAt).format('DD.MM.YYYY')}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoField
          label={t('contract:content.label.dueDate')}
          fullWidth
          value={moment(infoData?.dueDate).format('DD.MM.YYYY')}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoField
          label={t('contract:content.label.amount')}
          fullWidth
          value={infoData?.amount + " €"}
        />
      </Grid>
      <Grid item xs={12}>
        <InfoField
          label={t('contract:content.label.paid')}
          fullWidth
          value={infoData?.paid ? <Check /> : <Close />}
        />
      </Grid>
      {isPrivileged(user, "STAFF") && (<Grid item xs={12}>
        <InfoField
          label={t('common:content.label.editors')}
          fullWidth
          value={""}
        />
        {infoData?.editors && infoData.editors.length > 0 && infoData.editors.map((editor: any) => {
          return <Chip key={editor.id} label={`${editor.first_name} ${editor.last_name}`} sx={{marginLeft: "10px"}} />
        })}
      </Grid>)}
      <Grid item xs={12}>
        <Button
          id="showFilesButton"
          fullWidth
          variant="contained"
          onClick={() => {
            setOpenFilesDrawer(true)
          }}
        >
          {t('common:interaction.button.showFiles')}
        </Button>
      </Grid>
    </Grid>
  </InfoPanel>
)
}