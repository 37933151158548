/* -------------------------- Design imports start -------------------------- */
import { Button, Chip, CircularProgress, Grid } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Contract } from '../utils/types';
import { useTranslation } from 'react-i18next';
import { useCountContext, useUserContext } from '../../../utils/context';
import LogTool from '../../../logger/logTools';
import GetConstant from '../utils/constants';
import Table, { ColumnsType } from 'antd/es/table';
import FilterComponent, { FilterOptions } from '../../../components/widgets/FilterMenu';
import { deleteContracts, fetchContract, fetchContracts, inputToAPIBillJSON, inputToAPIContractJSON, postBill, updateBill, updateContract } from '../utils/functions';
import LoadingSkeleton from '../../../components/widgets/LoadingSkeleton';
import { canDelete, handleAPICallV1, isPrivileged } from '../../../utils/functions';
import { Space } from 'antd';
import { DeleteOutlined, EditOutlined, FilterAltOutlined } from '@mui/icons-material';
import ColumnSelector from '../../../components/widgets/ColumnSelector';
import DeleteModal from '../../../components/widgets/DeleteModal';
import FilesDrawer from '../../../components/FilesDrawer';
import BillStaffDrawer from '../Bill/BillStaffDrawer';
import { HTTPMethod } from '../../../utils/types';
import ContractDrawer from './ContractDrawer';
import ContractStaffDrawer from './ContractStaffDrawer';
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  openInfoPanel: boolean
  setOpenInfoPanel: (open: boolean) => void
  selectedContract: Contract | undefined
  setSelectedContract: (contract: Contract | undefined) => void
  openFilesDrawer: boolean
  setOpenFilesDrawer: (open: boolean) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function ContractOverviewPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    openInfoPanel,
    setOpenInfoPanel,
    selectedContract,
    setSelectedContract,
    openFilesDrawer,
    setOpenFilesDrawer,
  } = props
  const { t } = useTranslation()
  const { user, userPermissions } = useUserContext()
  const { initialContracts, setTabValue } = useCountContext()
  const log = new LogTool({ context: 'ContractOverviewPage', enable: true, logLevel: 'warn' })
  const columnsData: ColumnsType = GetConstant({ name: 'contractTableColumns' }) as ColumnsType
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [contractDrawerOpen, setContractDrawerOpen] = useState(false)
  const [contractStaffDrawerOpen, setContractStaffDrawerOpen] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [billDrawerOpen, setBillDrawerOpen] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* --------------------------- Data states start ---------------------------- */
  const dataSource = useRef<Contract[]>([])
  const [myFilterOptions, setMyFilterOptions] = useState<FilterOptions>({})
  const [filteredData, setFilteredData] = useState<Contract[]>([])
  const [defaultColumns] = useState<string[]>([
    'read',
    'key',
    'offer',
    'article',
    'price',
    'createdAt',
    'desiredDelivery',
    'latestDelivery',
    'priorityShipping',
    'editors',
    'contractStatus',
    'action',
  ])
  const [columns] = useState<ColumnsType>(columnsData)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [contracts, setContracts] = useState<Contract[]>([])
  const [totalContracts, setTotalContracts] = useState(0)
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>([])
  const [contractFormInput, setContractFormInput] = useState<any>({})
  const [contractStaffFormInput, setContractStaffFormInput] = useState<any>({})
  const [billFormInput, setBillFormInput] = useState<any>({})
  const [newContracts, setNewContracts] = useState<Contract[]>(initialContracts)
  /* ---------------------------- Data states end ----------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    setLoading(true)
    fetchContracts({
      onSuccess: (contracts: Contract[], count: number | undefined) => {
        dataSource.current = [...dataSource.current, ...contracts]
        count && setTotalContracts(count)
        setFilteredData(dataSource.current)
        setLoading(false)
      },
      onError: (error: any) => {
        log.error('Error fetching contracts.', error)
        setLoading(false)
      },
      parameter: ['expand=offer,editors,shipping_address,billing_address'],
    })
  }, [])

  /* ------------------------------- Effects end ------------------------------ */

  log.debug('dataSource.current', dataSource.current)

  /* ------------------------- Utility functions start ------------------------ */
  const filterData = (data: any[], filterOptions: { [x: string]: any }) => {
    if (!data || !Array.isArray(data)) {
      return [] // Rückgabe eines leeren Arrays, wenn die Daten ungültig sind
    }

    return data.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // Kein Filter angewendet
        if (key === 'name') {
          return filterValues.some((value: string) =>
            d.name.toLowerCase().includes(value.toLowerCase())
          )
        } else if (key === 'quantity') {
          return filterValues.some((value: string) => parseInt(d.in_storage) >= parseInt(value))
        } else {
          const cellValue = d[key]
          return filterValues.some((value: string) =>
            cellValue?.toString().toLowerCase().includes(value.toLowerCase())
          )
        }
      })
    })
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  /**
   * Refreshes the data source by fetching all contracts from the API.
   */
  const refreshDataSource = (): void => {
    dataSource.current = []
    setFilteredData([])
    fetchContracts({
      onSuccess: (contracts: Contract[], count: number | undefined) => {
        dataSource.current = [...dataSource.current, ...contracts]
        count && setTotalContracts(count)
        setFilteredData(dataSource.current)
      },
      onError: (error: any) => {
        log.error('Error fetching contracts.', error)
      },
      parameter: ['expand=offer,editors,shipping_address,billing_address'],
    })
  }

  useEffect(() => {
    // Funktion zum Aktualisieren der Daten, wenn der TabValue auf die BillOverviewPage gesetzt wird
    const handleTabChange: (newValue: number) => number = (newValue: number) => {
      if (newValue === 4) {
        // Angenommen, der Index für die BillOverviewPage ist 4
        refreshDataSource()
      }
      return newValue
    }

    // Registrieren Sie die handleTabChange-Funktion als Callback für die setTabValue-Prop
    if (setTabValue) {
      setTabValue(handleTabChange as any)
    }
  }, [setTabValue, refreshDataSource])

  async function handleUpdateContract(input: any) {
    log.debug('handleUpdateContract', input)
    const json = inputToAPIContractJSON(input)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      input.self,
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.debug('Contract updated', response)
      fetchContract(
        response.self,
        (data: Contract) => {
          log.debug('Contract fetched', data)
          dataSource.current = dataSource.current.map(d => (d.key === data.key ? data : d))
          setFilteredData(dataSource.current)
          refreshDataSource()
          toast.success(t('contract:feedback.success.editContractSuccess'))
        },
        (error: any) => {
          log.error('Error fetching contract', error)
          toast.error(t('contract:feedback.error.editContractFailed'))
        },
        ['expand=offer,editors,shipping_address,billing_address']
      )
      setSelectedRowKeys([])
      setSelectedContracts([])
      setSelectedContract(undefined)
      setOpenInfoPanel(false)
      return response.files
    } else {
      log.error('Error updating contract', error)
      toast.error(t('contract:feedback.error.editContractFailed'))
      setSelectedRowKeys([])
      setSelectedContracts([])
      setSelectedContract(undefined)
      setOpenInfoPanel(false)
    }
  }

  async function handleDeleteContracts() {
    deleteContracts(
      selectedContracts,
      () => {
        toast.success(t('contract:feedback.success.deleteContractSuccess'))
        setSelectedRowKeys([])
        setSelectedContracts([])
        setFilteredData(prev => prev.filter(d => !selectedContracts.includes(d)))
        refreshDataSource()
        setOpenDelete(false)
      },
      (error: any) => {
        log.error(error)
        toast.error(t('contract:feedback.error.deleteContractFailed'))
        setOpenDelete(false)
      }
    )
  }

  const handleBill = (event: any, selectedContract: Contract) => {
    log.debug('Creating Bill for contract -> ', selectedContract)
    event.stopPropagation()
    event.preventDefault()
    const newBill: any = {
      contract: selectedContract,
      amount: selectedContract?.price
        ? Object?.keys(selectedContract.price)
            .map(p => selectedContract?.price && selectedContract?.price[p])[0]
            ?.toString()
        : '0',
    }
    const selectedRowKey = selectedContract.key
    setSelectedRowKeys([selectedRowKey])
    setBillFormInput(newBill)
    setBillDrawerOpen(true)
    refreshDataSource()
  }

  const handleCreateBill = async (input: any) => {
    log.debug('HandleCreateBill -> ', input)
    const json = inputToAPIBillJSON(input)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'contract/bills/',
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.info('Success creating bill', response)
      toast.success(t('contract:feedback.success.createBill'))
      refreshDataSource()
      log.debug('bill created -> ', response)
      return response.files
    } else {
      log.error('Error creating bill', error)
      toast.error(t('contract:feedback.error.createBill'))
    }
  }

  const handleApplyFilter = (filterOptions: Record<string, any>) => {
    setMyFilterOptions(filterOptions)

    const filteredProducts = dataSource.current.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // no filter applied

        const cellValue = d[key as keyof Contract]
        if (cellValue === null) return null // Ignore null values
        if (typeof cellValue === 'string') {
          // String-based filtering
          if (typeof cellValue === 'string') {
            return filterValues.some((value: string) =>
              cellValue.toLowerCase().includes(value.toLowerCase())
            )
          }
        } else if (typeof cellValue === 'number') {
          // Number-based filtering
          const cellValueAsNumber =
            typeof cellValue === 'string' ? parseFloat(cellValue) : cellValue
          return filterValues.some((value: number) => cellValueAsNumber == value)
        }

        return true // Ignore other data types or no match
      })
    })

    setFilteredData(filteredProducts)
    setFilterVisible(false)
  }

  const handleColumnChange = (checkedColumns: string[]) => {
    setVisibleColumns(checkedColumns)
  }

  const handleCancelFilter = () => {
    setFilterVisible(false)
  }

  const handleReset = () => {
    setMyFilterOptions({})
    setFilteredData(dataSource.current)
  }

  const handleShowFilter = () => {
    setFilterVisible(true)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const contractTableColumns: any = GetConstant({
    name: 'contractTableColumns',
    newContracts: newContracts,
    handleBill: handleBill,
  })
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns)
  const filteredColumns = contractTableColumns.filter((column: any) => {
    return visibleColumns.includes(column.key?.toString() ?? '')
  })

  const renderContractStatus = (status: number | undefined) => {
    if (status === undefined) return t('common:content.label.notAvailable')
    switch (status) {
      case 0:
        return { value: 0, label: t('common:content.label.created') }
      case 1:
        return { value: 1, label: t('common:content.label.productionOrdersCreated') }
      case 2:
        return { value: 2, label: t('common:content.label.inProgress') }
      case 3:
        return { value: 3, label: t('common:content.label.shipped') }
      case 4:
        return { value: 4, label: t('common:content.label.completed') }
      case 5:
        return { value: 5, label: t('common:content.label.paid') }
      case 6:
        return { value: 6, label: t('common:content.label.archived') }
      case 7:
        return { value: 7, label: t('common:content.label.trash') }
      case 8:
        return { value: 8, label: t('common:content.label.deleted') }
      default:
        return status
    }
  }

  const renderAddress = (address: any) => {
    if (!address) return ''
    if (typeof address === 'string') return address
    if (address)
      return {
        value: address.self,
        label: `${address.address_1}, ${address.zip_code},  ${address.city}`,
      }
  }

  const chips = Object.entries(myFilterOptions).map(([key, values]) => {
    if (!values.length) return <></>
    return (
      <Chip
        key={key}
        label={`${t('common:content.label.' + key)}: ${values}`}
        onDelete={() => {
          setMyFilterOptions(prev => {
            const updatedOptions = { ...prev, [key]: [] }
            setFilteredData(filterData(dataSource.current, updatedOptions))
            return updatedOptions
          })
        }}
      />
    )
  })

  const buttonContainerStyle = {
    marginLeft: openInfoPanel ? '-270px' : '0',
    transition: 'margin-left 0.3s ease-in-out',
  }

  if (loading) {
    return <LoadingSkeleton pagetitle="" />
  }
  /* -------------------------- Render constants end -------------------------- */
  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>{chips}</Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" style={buttonContainerStyle}>
          {isPrivileged(user, 'STAFF') && (
            <Grid item>
              <Space>
                <Button
                  id="editContractButton"
                  size="small"
                  variant="outlined"
                  startIcon={<EditOutlined />}
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    if (!isPrivileged(user, 'STAFF')) {
                      const selectedContract = {
                        ...selectedContracts[0],
                        billingAddress: renderAddress(selectedContracts[0].billingAddress),
                        shippingAddress: renderAddress(selectedContracts[0].shippingAddress),
                        contractStatus: renderContractStatus(selectedContracts[0].contractStatus),
                      }
                      setContractFormInput(selectedContract)
                      setContractDrawerOpen(true)
                    } else {
                      const selectedContract = { ...selectedContracts[0] }
                      setContractStaffFormInput(selectedContract)
                      setContractStaffDrawerOpen(true)
                    }
                  }}
                  disabled={selectedRowKeys.length !== 1}
                >
                  {t('contract:interaction.button.editContract')}
                </Button>
              </Space>
            </Grid>
          )}
          {(canDelete(userPermissions, "contract:contract") || isPrivileged(user)) && (<Grid item>
            <Space>
              <Button
                id="deleteContractsButton"
                size="small"
                variant="outlined"
                style={{ textTransform: 'none' }}
                startIcon={<DeleteOutlined />}
                onClick={() => setOpenDelete(true)}
                disabled={
                  selectedRowKeys.length === 0 ||
                  selectedContracts.filter(contract => contract.contractStatus !== 8).length > 0
                }
              >
                {t('contract:interaction.button.deleteContracts')}
              </Button>
            </Space>
          </Grid>)}
          <Grid item>
            <Space>
              <Button
                id="filterButton"
                size="small"
                variant="outlined"
                style={{ textTransform: 'none' }}
                startIcon={<FilterAltOutlined />}
                onClick={handleShowFilter}
              >
                {t('common:content.label.filter')}
              </Button>
            </Space>
          </Grid>
          <Grid item>
            <ColumnSelector
              columns={columns as Array<{ key: string; title: string }>}
              onChangeColumns={handleColumnChange}
              defaultColumns={defaultColumns}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          id="contractTable"
          columns={filteredColumns}
          dataSource={filteredData}
          pagination={{
            total: totalContracts,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
          locale={{
            emptyText: contracts.length === 0 ? 'No data' : <CircularProgress />,
          }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedKeys: React.Key[], selectedRows: Contract[]) => {
              setSelectedRowKeys(selectedKeys)
              setSelectedContracts(selectedRows)
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                setSelectedContract(record as Contract)
                const newContract = newContracts.find(contract => contract.key === record.key)
                if(newContract && !newContract.read) {
                  setNewContracts(prev => prev.map(contract => {
                    if(contract.key === record.key) {
                      return {...contract, read: true}
                    }
                    return contract
                  }))
                }
                if (selectedRowKeys.includes(record.key as React.Key)) {
                  setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key))
                  setSelectedContracts(
                    selectedContracts.filter(contract => contract.key !== record.key)
                  )
                  setSelectedContract(undefined)
                  setOpenInfoPanel(false)
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.key as React.Key])
                  setSelectedContracts([...selectedContracts, record as Contract])
                  setSelectedContract(record as Contract)
                  setOpenInfoPanel(true)
                }
              },
            }
          }}
        />
        {filterVisible && (
          <FilterComponent
            columns={contractTableColumns}
            onApplyFilter={handleApplyFilter}
            onCancelFilter={handleCancelFilter}
            onResetFilter={handleReset}
            filterOptions={myFilterOptions}
          />
        )}
        <ContractDrawer
          open={contractDrawerOpen}
          setOpen={setContractDrawerOpen}
          formState={{
            state: contractFormInput,
            setState: setContractFormInput,
          }}
          enableEditMode={true}
        />
        {isPrivileged(user, 'STAFF') && (
          <BillStaffDrawer
            open={billDrawerOpen}
            setOpen={setBillDrawerOpen}
            formState={{
              state: billFormInput,
              setState: setBillFormInput,
            }}
            onCreateConfirm={handleCreateBill}
            setTabValue={setTabValue}
          />
        )}
        {isPrivileged(user, 'STAFF') && (
          <ContractStaffDrawer
            open={contractStaffDrawerOpen}
            setOpen={setContractStaffDrawerOpen}
            formState={{
              state: {
                ...contractStaffFormInput,
                contractStatus: renderContractStatus(contractStaffFormInput.contractStatus),
              },
              setState: setContractStaffFormInput,
            }}
            onEditConfirm={handleUpdateContract}
          />
        )}
      </Grid>
      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={handleDeleteContracts}
        name={t('common:content.label.contracts')}
      />
      <FilesDrawer
        open={openFilesDrawer}
        setOpen={setOpenFilesDrawer}
        onClose={() => {}}
        files={selectedContract?.files}
        title={t('common:content.label.contractFiles') as string}
      />
    </Grid>
  )
}
