/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIStatusDaeEnum,
    APIStatusDaeEnumFromJSON,
    APIStatusDaeEnumFromJSONTyped,
    APIStatusDaeEnumToJSON,
} from './APIStatusDaeEnum';

/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APISupplyOrder
 */
export interface APISupplyOrder {
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    supplier: string;
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    article: string;
    /**
     * 
     * @type {APIStatusDaeEnum}
     * @memberof APISupplyOrder
     */
    status?: APIStatusDaeEnum;
    /**
     * 
     * @type {Date}
     * @memberof APISupplyOrder
     */
    readonly orderDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof APISupplyOrder
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof APISupplyOrder
     */
    dueDate: Date;
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof APISupplyOrder
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISupplyOrder
     */
    approval?: boolean;
}

export function APISupplyOrderFromJSON(json: any): APISupplyOrder {
    return APISupplyOrderFromJSONTyped(json, false);
}

export function APISupplyOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): APISupplyOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'supplier': json['supplier'],
        'article': json['article'],
        'status': !exists(json, 'status') ? undefined : APIStatusDaeEnumFromJSON(json['status']),
        'orderDate': (new Date(json['order_date'])),
        'createdAt': (new Date(json['created_at'])),
        'dueDate': (new Date(json['due_date'])),
        'amount': json['amount'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'approval': !exists(json, 'approval') ? undefined : json['approval'],
    };
}

export function APISupplyOrderToJSON(value?: APISupplyOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supplier': value.supplier,
        'article': value.article,
        'status': APIStatusDaeEnumToJSON(value.status),
        'due_date': (value.dueDate.toISOString()),
        'amount': value.amount,
        'unit': value.unit,
        'description': value.description,
        'comment': value.comment,
        'approval': value.approval,
    };
}

