/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    APINullEnum,
    APINullEnumFromJSON,
    APINullEnumFromJSONTyped,
    APINullEnumToJSON,
} from './APINullEnum';
import {
    APIPreferredContactMethodEnum,
    APIPreferredContactMethodEnumFromJSON,
    APIPreferredContactMethodEnumFromJSONTyped,
    APIPreferredContactMethodEnumToJSON,
} from './APIPreferredContactMethodEnum';

/**
 * @type APIContactPreferredContactMethod
 *
 * @export
 */
export type APIContactPreferredContactMethod = APINullEnum | APIPreferredContactMethodEnum;

export function APIContactPreferredContactMethodFromJSON(json: any): APIContactPreferredContactMethod {
    return APIContactPreferredContactMethodFromJSONTyped(json, false);
}

export function APIContactPreferredContactMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIContactPreferredContactMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return Object.assign(APINullEnumFromJSONTyped(json, true), APIPreferredContactMethodEnumFromJSONTyped(json, true) );
}

export function APIContactPreferredContactMethodToJSON(value?: APIContactPreferredContactMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return { ...APINullEnumToJSON(value as APINullEnum), ...APIPreferredContactMethodEnumToJSON(value as APIPreferredContactMethodEnum)};
}

