/* -------------------------- Design imports start -------------------------- */
import labelStyle from './utils/fieldLabel.module.css'
/* --------------------------- Design imports end --------------------------- */



type Props = {
  value: string
}


/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

/**
 * Small text label that is placed just above the Component that is following the FieldLabel
 * Component - just like the labels of Mui Textfields, ...
 *
 *
 *
 * How to use:
 *  Say you want to provide a label for a List of items:
 *
 *    <List>
 *      <ListItem>
 *        <ListItemIcon>
 *        ...
 *        ...
 *      </ListItem>
 *      ...
 *    </List>
 *
 *  Put the FieldLabel directly above the list in the document flow:
 *
 *    <FieldLabel value="This is a list of items"/>
 *    <List>
 *      <ListItem>
 *        <ListItemIcon>
 *        ...
 *        ...
 *      </ListItem>
 *      ...
 *    </List>
 *
 *
 *
 * Recomendation:
 *  The FieldLabel looks the best when the Component you want to label has a thin border,
 *  just like the Mui Textfields, ... have!
 * @param props
 * @returns
 */
export default function FieldLabel(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {value} = props
  /* --------------------------- Non state data end --------------------------- */


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <div className={labelStyle.labelContainer}>
      <label className={labelStyle.label}>{value}</label>
    </div>
  )
}