import { SvgIcon } from "@mui/material";
import styles from './animatedIcons.module.css'


export default function AnimatedTaskDoneIcon() {
    return (
      <SvgIcon color='success'>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          fill="none"
          stroke="currentColor"
        >
          <path
            className={styles.taskDoneCircle}
            // fill="none" stroke="black" strokeWidth={15}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M 16.23,4.02
              C 16.23,4.02 15.00,2.99 12.00,2.99
                7.38,2.99 3.09,6.74 3.09,12.06
                3.09,17.37 7.27,21.13 12.00,21.13
                16.73,21.13 21.03,17.06 21.03,12.06
                21.03,11.27 20.91,10.45 20.91,10.45"
          />
          <path
            className={styles.taskDoneCheck}
            // fill="none" stroke="black" stroke-width={15}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M 7.66,12.24
              C 7.66,12.24 10.55,15.01 10.55,15.01
                10.55,15.01 20.81,5.05 20.81,5.05"
          />
        </svg>
      </SvgIcon>
    )
}