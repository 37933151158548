/* -------------------------- Design imports start -------------------------- */
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react'
import ChannelOverview from './ChannelOverview'
import { useTranslation } from 'react-i18next'
import { Channel, Message, MessageCountObject, MessageObject } from '../../utils/types'
import { fetchUser, getPKfromSelf, handleAPICallV1 } from '../../../../utils/functions'
import { HTTPMethod, User, WebsocketMessage } from '../../../../utils/types'
import { toast } from 'react-toastify'
import LogTool from '../../../../logger/logTools'
import { createMessage, fetchChannels } from '../../utils/functions'
import { ChatContext } from '../../utils/context'
import DeleteModal from '../../../../components/widgets/DeleteModal'
import Button from '../../../../components/Button'
import { useWebsocket } from '../../../../utils/WebsocketProvider'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  setBadgeStatus_countMessages?: (count: number) => void
  header?: boolean
}

/**
 * Logic for channel operation (delete/create) and subscriptions
 */

/* ----------------------------- Component start ---------------------------- */
export default function ChannelLogic(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { setBadgeStatus_countMessages, header } = props
  const log = new LogTool({ context: 'ChannelLogic', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState<boolean>(true)
  const [deleteMultipleModal, setDeleteMultipleModal] = useState<boolean>(false)
  const [leaveChatDialogOpen, setLeaveChatDialogOpen] = useState<boolean>(false)
  const [createChannelMode, setCreateChannelMode] = useState<boolean>(false) // necessary for mobile
  // channel that is visited, helps to differentiate seen and unseen messages
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const dataSource = useRef<Channel[]>([])
  const [activeUser, setActiveUser] = useState<User>({} as User)
  const [channels, setChannels] = useState<Channel[]>([]) // current channels
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]) // channels that are selected for deletion
  const [selectedChannelLeave, setSelectedChannelLeave] = useState<Channel[]>([])
  const [newestMessage, setNewestMessage] = useState<Message | undefined>(undefined) // newest message in the channel
  // channel that is active and used for operations
  const [activeChannel, setActiveChannel] = useState<Channel | undefined>(undefined)
  const [visitedChannel, setVisitedChannel] = useState<Channel | undefined>(undefined)
  // channelIds with number of new Messages
  const [messages, setMessages] = useState<MessageObject>({}) // messages inside the active channel
  // new messages that have been received
  const [newMessages, setNewMessages] = useState<MessageObject>({})
  /* const [initNewMessages, setInitNewMessages] = useState<MessageObject>({}) */
  const [countMessages, setCountMessages] = useState<MessageCountObject>({}) // number of new received messages
  const { websocketMessage, websocketIsReady, websocketSend } = useWebsocket()
  const { t } = useTranslation(['chat', 'common'])

  /* ----------------------------- Data states end ---------------------------- */

  /* ----------------------------------Effects start---------------------------------------- */
  useEffect(() => {
    fetchUser({
      onSuccess: (user: User) => {
        setActiveUser(user)
      },
    })
    fetchChannels({
      onSuccess: (channels: Channel[]) => {
        // Erstellen Sie eine Kopie der Kanäle, um sie zu aktualisieren
        const updatedChannels = channels.map(channel => {
          // Finden Sie die neueste Nachricht für den Kanal
          const newestMessage = channel.messages[channel.messages.length - 1]
          // Fügen Sie die neueste Nachricht als Eigenschaft zum Kanal hinzu
          return { ...channel, newestMessage }
        })

        // Aktualisieren Sie den Zustand mit den aktualisierten Kanälen
        setChannels(channels)

        // Setzen Sie den aktiven Kanal, falls Kanäle vorhanden sind
        if (updatedChannels.length > 0) {
          setActiveChannel(channels[0])
        }

        setLoading(false)
      },
      parameter: ['expand=members,creator,messages'],
    })
  }, [])

  useEffect(() => {
    fetchNewMessagesInChannels()
  }, [activeUser])
  /* ---------------------------------------Effects end------------------------------------------- */
  /* -------------------------------WebSocket Functions start------------------------------------- */

  // useEffect(() => {
  //   if (socket) {
  //     socket.onmessage = async event => {
  //       handleSocketEvent(event)
  //     }
  //     socket.addEventListener('error', event => {
  //       log.error('socket error: ', event)
  //     })
  //   }
  // }, [socket, activeChannel])

  useEffect(() => {
    if(websocketIsReady && typeof websocketMessage !== 'undefined') {
      const msgType = websocketMessage.type
      if(msgType.startsWith('chat_')) {
        handleSocketEvent(websocketMessage)
      }
    }
  }, [websocketMessage])
  // TODO: udpate channels on delete/create in real time and update badge status
  /* -------------------------------WebSocket Functions end--------------------------------------- */

  async function handleSocketEvent(msg: WebsocketMessage) {
    switch (msg.type) {
      case 'chat_message':
        // const messageObject: MessageObject = JSON.parse(msg.data.message)
        const message: any = createMessage(msg.data)// messageObject[Object.keys(messageObject)[0]][0]
        log.info('Message ->', message)
        setNewMessages(prevMessages => {
          const newMessages = { ...prevMessages }
          if (typeof message.channel === 'string') {
            // the backend only sends the id of the channel, the message was send in
            const channelId = getPKfromSelf(message.channel)
            if (newMessages[channelId]?.length > 0) {
              newMessages[channelId].push(message)
            } else {
              newMessages[channelId] = [message]
            }
          }
          return newMessages
        })
        setCountMessages((prevCount: MessageCountObject) => {
          const countMessages: MessageCountObject = { ...prevCount }
          if (
            typeof message.channel === 'string' &&
            activeUser &&
            message.author.key !== activeUser.key &&
            activeChannel?.key !== message.channel
          ) {
            const channelId = message.channel
            countMessages[channelId] = countMessages[channelId]
              ? countMessages[channelId] + 1
              : 1
          }
          return countMessages
        })
        setChannels(prevChannels => {
          const updatedChannels = prevChannels.map(channel => {
            if (channel.key === message.channel) {
              message.createdAt = new Date(message.created_at)
              return { ...channel, newestMessage: message }
            }
            return channel
          })
          const sortedChannels = updatedChannels.sort((a, b) => {
            return new Date(b.newestMessage?.created_at).getTime() - new Date(a.newestMessage?.created_at).getTime()
          })
          return sortedChannels
        })
        break
      case 'channel_new':
        const newChannel = await fetchNewChannel(JSON.parse(msg.data.channel))
        if (newChannel.creator.self !== activeUser.self) {
          setChannels(prevChannels => [...prevChannels, newChannel])
        }
        break
      case 'channel_deleted':
        fetchChannels({
          onSuccess: (channels: Channel[]) => {
            dataSource.current = [...channels]
            setChannels(dataSource.current)
            if (dataSource.current && dataSource.current.length > 0) {
              setActiveChannel(dataSource.current[0])
            }
            setLoading(false)
          },
          parameter: ['expand=members,creator,messages'],
        })
        const deletedChannel = channels.find((channel: Channel) => channel.key === msg.data.channel)
        if (deletedChannel) {
          setChannels(prevChannels =>
            prevChannels.filter((channel: Channel) => channel.self !== deletedChannel.self)
          )
        }
        break
      case 'message_edit':
        if (msg.data.channel === activeChannel?.key) {
          fetchMessagesForSocketEvent(msg.data.channel)
        }
        break
      default:
        break
    }
  }

  /* ------------------------- Utility functions start ------------------------ */

  async function fetchNewChannel(channel: number) {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'chat/channel/' + channel + '/?expand=members,creator,messages',
      undefined,
      undefined
    )
    if (error) {
      log.error('Error: ', error)
      return null
    } else {
      log.info('Successfully fetched new channel: ', response)
      return response
    }
  }

  async function fetchMessagesForSocketEvent(channelId: number) {
    if (!activeChannel) return
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      `chat/channel/${channelId}/messages/?expand=self,author`,
      undefined,
      undefined
    )
    if (response && channelId) {
      if (response.results && response.results.length > 0) {
        const messages = response.results
        const sortedMessages = messages.sort((a: any, b: any) => {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        })
        const newMessages = sortedMessages.filter((message: any) => message.isNewMessage === true)
        const oldMessages = sortedMessages.filter((message: any) => message.isNewMessage === false)
        const newMessagesObject: any = {
          [activeChannel.self]: newMessages,
        }
        const oldMessagesObject: any = {
          [activeChannel.self]: oldMessages,
        }
        setNewMessages(newMessagesObject)
        setMessages(oldMessagesObject)
      } else {
        setMessages({ [activeChannel.self]: [] })
      }
    } else {
      log.error('Error fetching messages', error)
    }
  }

  async function fetchNewMessagesInChannels() {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'chat/channel/?expand=messages',
      undefined,
      undefined
    )
    if (error) {
      log.error('Error: ', error)
    } else {
      const channels: Channel[] = response.results
      let countObject: MessageCountObject = {}
      channels.forEach(channel => {
        if (channel.messages.length > 0 && Array.isArray(channel.messages)) {
          channel.messages.forEach((message: Message) => {
            if (message.isNewMessage && message.author !== activeUser.self) {
              countObject[channel.self] = countObject[channel.self]
                ? countObject[channel.self] + 1
                : 1
            }
          })
        }
      })
      setCountMessages(countObject)
    }
  }

  async function setChannelInteraction() {
    if (activeChannel) {
      setVisitedChannel(activeChannel)
      const [response, error] = await handleAPICallV1(
        HTTPMethod.GET,
        activeChannel.self + 'interacted/',
        undefined,
        undefined,
        'text'
      )
      if (error) {
        log.error('Error: ', error)
      } else {
        log.info('Successfully set channel interaction: ')
      }
    }
  }

  async function handleChannelDelete() {
    setDeleteMultipleModal(false)
    const results = await Promise.all(selectedChannels.map(channel => deleteChannel(channel)))
    log.debug('Results: ', results)
    if (results.includes(false)) {
      toast.error(t('common:feedback.error.errorDeletingChats'))
    } else {
      toast.success(t('common:feedback.success.successDeletingChats'))
    }
    setSelectedChannels([])
    setActiveChannel(undefined)
    fetchChannels({
      onSuccess: (channels: Channel[]) => {
        setChannels(channels)
      },
      parameter: ['expand=members,creator,messages'],
    })
  }

  async function deleteChannel(channel: Channel) {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.DELETE,
      channel.self,
      undefined,
      undefined,
      'text'
    )
    log.debug('Response: ', response)
    log.debug('Error: ', error)
    if (error) {
      log.error('Error deleting channel', error)
      return false
    } else {
      return true
    }
  }

  // WIP: handle channel leave for one channel
  async function handleLeaveChat() {
    setLeaveChatDialogOpen(false)
    const channel: Channel = selectedChannelLeave[0]
    log.debug('Channel: ', channel)
    log.debug('Channel self: ', channel.self)
    log.debug('Active user: ', activeUser.self)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.DELETE,
      `${channel.self}member/`,
      undefined,
      {
        user: activeUser.self,
      },
      'text'
    )
    if (!error) {
      toast.success(t('common:feedback.success.removeMemberSuccess'))
    } else {
      log.error('Error removing member', error)
      toast.error(t('common:feedback.error.removeMemberError'))
    }
    fetchChannels({
      onSuccess: (channels: Channel[]) => {
        setChannels(channels)
      },
      parameter: ['expand=members,creator,messages'],
    })
    setSelectedChannels([])
  }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = () => {
    setActiveChannel(undefined)
    if (setBadgeStatus_countMessages) {
      setBadgeStatus_countMessages(0)
    }
  }

  //allows chatview to be closed on mobile
  const handleMobileCloseChatView = () => {
    setActiveChannel(undefined)
    setCreateChannelMode(false)
  }

  const handleCreateChannelClick = () => {
    setActiveChannel(undefined)
    setCreateChannelMode(true)
  }

  const handleChannelClick = (channel: Channel | undefined) => {
    setVisitedChannel(activeChannel)
    setCountMessages(prevCount => {
      const countMessages = { ...prevCount }
      if (channel && countMessages[channel?.self] > 0) {
        countMessages[channel?.self] = 0
      }
      return countMessages
    })
    setActiveChannel(channel)
    setChannelInteraction()
  }

  /* ------------------------- Callback functions end ------------------------- */
  if (header) return null
  else {
    if (loading || !websocketIsReady) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      )
    }
  }

  log.debug('open: ', deleteMultipleModal)
  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      {channels && channels?.length >= 0 && !setBadgeStatus_countMessages && (
        <ChatContext.Provider
          value={{
            channels,
            setChannels,
            messages,
            setMessages,
            newMessages,
            setNewMessages,
            newestMessage,
            countMessages,
            activeUser,
            visitedChannel,
            activeChannel,
            setActiveChannel,
            fetchChannels,
            createChannelMode,
            handleMobileCloseChatView,
            handleCreateChannelClick,
            handleChannelClick,
            handleChannelDelete,
            handleClose,
            selectedChannelLeave,
            setSelectedChannelLeave,
            selectedChannels,
            setSelectedChannels,
            setDeleteMultipleModal,
            leaveChatDialogOpen,
            setLeaveChatDialogOpen,
          }}
        >
          <ChannelOverview />
        </ChatContext.Provider>
      )}
      <DeleteModal
        open={deleteMultipleModal}
        onDelete={handleChannelDelete}
        name={t('chat:feedback.info.doYouReallyWantToDeleteThisChats')}
        setOpen={setDeleteMultipleModal}
      />
      <Dialog open={leaveChatDialogOpen} onClose={() => setLeaveChatDialogOpen(false)}>
        <DialogTitle>{t('chat:feedback.info.doYouReallyWantToLeaveThisChat')}</DialogTitle>
        <DialogContent>
          <p>{`${t('chat:content.label.subject')}: ${selectedChannelLeave[0]?.name}`}</p>
          <p>{t('chat:feedback.info.youWillNotBeAbleToSeeTheMessages')}</p>
        </DialogContent>
        <DialogActions>
          <Button
            id="cancelLeaveChatButton"
            color="primary"
            variant="outlined"
            sx={{ float: 'left' }}
            onClick={() => {
              setLeaveChatDialogOpen(false)
              setSelectedChannels([])
            }}
          >
            {t('common:interaction.button.cancel')}
          </Button>
          <Button
            id="leaveChatButton"
            variant="contained"
            onClick={() => handleLeaveChat()}
          >
            {t('common:interaction.button.leave')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
