/* ------------------------ Functional imports start ------------------------ */
import { createContext, useContext, useEffect, useRef, useState } from "react"
import LogTool from "../../logger/logTools"
import { handleAPICallV1 } from "../../utils/functions"
import { HTTPMethod } from "../../utils/types"
import { useUserContext } from "../../utils/context"
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ChatSystemGlobalStateProvider(props: {children: React.ReactNode}) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({context: 'ChatSystemGlobalStateProvider', enable: true, logLevel: 'warn'})
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */


  /* ---------------------------- Data states start --------------------------- */
  // note: a value of -1 indicates that the unreadMessagesTotal was not fetched yet
  const [unreadMessagesTotal, setUnreadMessagesTotal] = useState<number>(-1)
  /* ----------------------------- Data states end ---------------------------- */


  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    // fetch the unreadFeedbackSubmissionTotal the first time we know about an authenticated user
    if(user && unreadMessagesTotal === -1) {
      fetchUnreadMessagesTotal()
    }
  }, [user])
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  async function fetchUnreadMessagesTotal() {
    log.info('Begin fetching total unread feedback submission count.')
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'chat/channel/unread_messages_count/',
    )

    if(!error && response) {
      log.info('Success fetching total unread messages count', response)
      setUnreadMessagesTotal(response.unread_messages_count)
    } else {
      setUnreadMessagesTotal(0)
      log.error('Error fetching total unread messages count', error)
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  log.debug('UnreadMessagesTotal ->', unreadMessagesTotal)


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <ChatSystemGlobalStates.Provider
      value={{
        unreadMessagesTotal: unreadMessagesTotal > 0 ? unreadMessagesTotal : 0,
        setUnreadMessagesTotal: setUnreadMessagesTotal,
      }}
    >
      {props.children}
    </ChatSystemGlobalStates.Provider>
  )
}

interface ISharepointGlobalStates {
  unreadMessagesTotal: number,
  setUnreadMessagesTotal: React.Dispatch<React.SetStateAction<number>>
}

export const ChatSystemGlobalStates = createContext<ISharepointGlobalStates>({
  unreadMessagesTotal: 0,
  setUnreadMessagesTotal: () => null
})