/* -------------------------- Design imports start -------------------------- */
import { FormControl, FormHelperText, TextField } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: string
  label: string
  required?: boolean
  labelWidth?: number
  onChange: (value: string) => void
  validation?: boolean
  errorMessage?: string
  disabled?: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function DateInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    label,
    value,
    onChange,
    labelWidth = 100,
    disabled = false,
    errorMessage = '',
    validation,
    required,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(required === true)
  /* ----------------------------- Flag states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        id={label}
        label={label}
        required={required}
        value={value || ''}
        disabled={disabled}
        error={error}
        inputProps={
          validation
            ? {
                min: new Date().toISOString().split('T')[0],
              }
            : {}
        }
        onChange={e => {
          onChange(e.currentTarget.value)
          e.currentTarget.value !== '' ? setError(false) : setError(true)
        }}
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
