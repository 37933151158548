import React from "react"
import { useTranslation } from "react-i18next"
import { FormFieldType } from "../../Form"

type Props = {
    name: 'assembly_manually'
    | 'assembly_robot'
    | 'joining_welding'
    | 'joining_soldering'
    | 'joining_glueing'
    | 'cnc_milling'
    | 'cnc_drilling'
    | 'cnc_turning'
    | 'cnc_grinding'
    | 'casting_die_cast'
    | 'casting_injection_moulding'
    | 'casting_investment_casting'
    | 'casting_sand_casting'
    | 'postprocessing_blasting'
    | 'postprocessing_brush'
    | 'postprocessing_grinding'
    | 'postprocessing_electroPlatedNickelPlating'
    | 'postprocessing_electroPlatedChromePlating'
    | 'postprocessing_laserEngraving'
    | 'postprocessing_polishing'
    | 'postprocessing_painting'
    | 'postprocessing_anodizing'
    | 'postprocessing_powderCoating'
    | 'postprocessing_electroGalvanizing'
    | 'sheetmetal_tubeLaserCutting'
    | 'sheetmetal_punching'
    | 'sheetmetal_bending'
    | 'sheetmetal_folding'
    | 'sheetmetal_laserCutting'
    | 'printing3D_3DPrinting'
    | 'standardComponent_standardComponent'
    | 'rawMaterial_rawMaterial'
    | 'qualityControl_qualityControl'
    | 'customProcess_customProcess'
} & (
    AssemblyManuallyKwargs
    | AssemblyRobotKwargs
    | JoiningWeldingKwards
    | JoiningSolderingKwards
    | JoiningGlueingKwards
    | CNCMillingKwargs
    | CNCDrillingKwargs
    | CNCTurningKwargs
    | CNCGrindingKwargs
    | CastingDieCastKwargs
    | CastingInjectionMouldingKwargs
    | CastingInvestmentCastingKwargs
    | CastingSandCastingKwargs
    | PostprocessingBlastingKwargs
    | PostprocessingBrushKwargs
    | PostprocessingGrindingKwargs
    | PostprocessingElectroPlatedNickelPlatingKwargs
    | PostprocessingElectroPlatedChromePlatingKwargs
    | PostprocessingLaserEngravingKwargs
    | PostprocessingPolishingKwargs
    | PostprocessingPaintingKwargs
    | PostprocessingAnodizingKwargs
    | PostprocessingPowderCoatingKwargs
    | PostprocessingElectroGalvanizingKwargs
    | SheetMetalTubeLaserCuttingKwargs
    | SheetMetalPunchingKwargs
    | SheetMetalBendingKwargs
    | SheetMetalFoldingKwargs
    | SheetMetalLaserCuttingKwargs
    | Printing3DKwargs
    | StandardComponentKwargs
    | RawMaterialKwargs
    | QualityControlKwargs
    | CustomProcessKwargs
)

type AssemblyManuallyKwargs = {
    name: 'assembly_manually'
}

type AssemblyRobotKwargs = {
    name: 'assembly_robot'
}

type JoiningWeldingKwards = {
    name: 'joining_welding'
}

type JoiningSolderingKwards = {
    name: 'joining_soldering'
}

type JoiningGlueingKwards = {
    name: 'joining_glueing'
}

type CNCMillingKwargs = {
    name: 'cnc_milling'
}

type CNCDrillingKwargs = {
    name: 'cnc_drilling'
}

type CNCTurningKwargs = {
    name: 'cnc_turning'
}

type CNCGrindingKwargs = {
    name: 'cnc_grinding'
}

type CastingDieCastKwargs = {
    name: 'casting_die_cast'
}

type CastingInjectionMouldingKwargs = {
    name: 'casting_injection_moulding'
}

type CastingInvestmentCastingKwargs = {
    name: 'casting_investment_casting'
}

type CastingSandCastingKwargs = {
    name: 'casting_sand_casting'
}

type PostprocessingBlastingKwargs = {
    name: 'postprocessing_blasting'
}

type PostprocessingBrushKwargs = {
    name: 'postprocessing_brush'
}

type PostprocessingGrindingKwargs = {
    name: 'postprocessing_grinding'
}

type PostprocessingElectroPlatedNickelPlatingKwargs = {
    name: 'postprocessing_electroPlatedNickelPlating'
}

type PostprocessingElectroPlatedChromePlatingKwargs = {
    name: 'postprocessing_electroPlatedChromePlating'
}

type PostprocessingLaserEngravingKwargs = {
    name: 'postprocessing_laserEngraving'
}

type PostprocessingPolishingKwargs = {
    name: 'postprocessing_polishing'
}

type PostprocessingPaintingKwargs = {
    name: 'postprocessing_painting'
}

type PostprocessingAnodizingKwargs = {
    name: 'postprocessing_anodizing'
}

type PostprocessingPowderCoatingKwargs = {
    name: 'postprocessing_powderCoating'
}

type PostprocessingElectroGalvanizingKwargs = {
    name: 'postprocessing_electroGalvanizing'
}

type SheetMetalTubeLaserCuttingKwargs = {
    name: 'sheetmetal_tubeLaserCutting'
}

type SheetMetalPunchingKwargs = {
    name: 'sheetmetal_punching'
}

type SheetMetalBendingKwargs = {
    name: 'sheetmetal_bending'
}

type SheetMetalFoldingKwargs = {
    name: 'sheetmetal_folding'
}

type SheetMetalLaserCuttingKwargs = {
    name: 'sheetmetal_laserCutting'
}

type Printing3DKwargs = {
    name: 'printing3D_3DPrinting'
}

type StandardComponentKwargs = {
    name: 'standardComponent_standardComponent'
}

type RawMaterialKwargs = {
    name: 'rawMaterial_rawMaterial'
}

type QualityControlKwargs = {
    name: 'qualityControl_qualityControl'
}

type CustomProcessKwargs = {
    name: 'customProcess_customProcess'
}

export default function GetFormConstant(props: Props) {
    const { t } = useTranslation(['item'])

    if(props.name === 'assembly_manually') {
        return [
            {
                type: 'time',
                key: 'time',
                label: t('item:content.label.time'),
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === 'assembly_robot') {
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === 'joining_welding'){
        return [
            {
                type: 'measurement',
                key: 'seam_width',
                label: t('item:content.label.seam_width'),
                unit: 'mm',
            },
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'text',
                key: 'method',
                label: t('item:content.label.method'),
                suggestions: [
                    {value: "gas welding", label: t("item:content.label.selectMethod.gas_welding")},
                    {value: "oxyacetylene welding", label: t("item:content.label.selectMethod.oxyacetylene_welding")},
                    {value: "Resistance spot welding", label: t("item:content.label.selectMethod.resistance_spot_welding")},
                    {value: "TIG welding", label: t("item:content.label.selectMethod.tig_welding")},
                    {value: "MAG welding", label: t("item:content.label.selectMethod.mag_welding")},
                    {value: "Laser welding", label: t("item:content.label.selectMethod.laser_welding")},
                    {value: "Plasma welding", label: t("item:content.label.selectMethod.plasma_welding")},
                    {value: "friction welding", label: t("item:content.label.selectMethod.friction_welding")},
                    {value: "Electromagnetic pulse welding", label: t("item:content.label.selectMethod.electromagnetic_pulse_welding")},
                ]
            },
            {
                type: 'text',
                key: 'seam',
                label: t('item:content.label.seam'),
                suggestions: [
                    {value: "HV-seam", label: t("item:content.label.selectSeam.hv_seam")},
                    {value: "K-seam", label: t("item:content.label.selectSeam.k_seam")},
                    {value: "K-seam (variable)", label: t("item:content.label.selectSeam.k_seam_variable")},
                    {value: "V-seam", label: t("item:content.label.selectSeam.v_seam")},
                    {value: "X-seam", label: t("item:content.label.selectSeam.x_seam")},
                    {value: "2/3 X-seam", label: t("item:content.label.selectSeam.2_3_x_seam")},
                    {value: "2/3 X-seam (variable)", label: t("item:content.label.selectSeam.2_3_x_seam_variable")},
                    {value: "Y-seam", label: t("item:content.label.selectSeam.y_seam")},
                    {value: "Tulips-seam", label: t("item:content.label.selectSeam.tulips_seam")},
                    {value: "Double tulip", label: t("item:content.label.selectSeam.double_tulip")},
                    {value: "UV-seam", label: t("item:content.label.selectSeam.uv_seam")},
                    {value: "Steep side seam", label: t("item:content.label.selectSeam.steep_side_seam")},
                    {value: "fillet", label: t("item:content.label.selectSeam.fillet")},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_stick_count',
                label: t('item:content.label.custom_stick_count'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }
    if (props.name === 'joining_soldering') {
        return [
            {
                type: 'measurement',
                key: 'surface_area',
                label: t('item:content.label.surface_area'),
                unit: 'mm²',
            },
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'text',
                key: 'method',
                label: t('item:content.label.method'),
                suggestions: [
                    {value: "Soldering iron", label: t("item:content.label.selectMethod.soldering_iron")},
                    {value: "Soldering bath", label: t("item:content.label.selectMethod.soldering_bath")},
                    {value: "Brazing", label: t("item:content.label.selectMethod.brazing")},
                    {value: "Furnace brazing", label: t("item:content.label.selectMethod.furnace_brazing")},
                    {value: "Electric resistance soldering", label: t("item:content.label.selectMethod.electric_resistance_soldering")},
                    {value: "Induction blazing", label: t("item:content.label.selectMethod.induction_blazing")},
                    {value: "Electron beam soldering", label: t("item:content.label.selectMethod.electron_beam_soldering")},
                    {value: "Arc brazing (MIG/TIG, plasma)", label: t("item:content.label.selectMethod.arc_brazing")},
                    {value: "laser brazing", label: t("item:content.label.selectMethod.laser_brazing")},
                ]
            },
            {
                type: 'text',
                key: 'seam',
                label: t('item:content.label.seam'),
                suggestions: [
                    {value: "I-seam", label: t("item:content.label.selectSeam.i_seam")},
                    {value: "HV-seam", label: t("item:content.label.selectSeam.hv_seam")},
                    {value: "K-seam", label: t("item:content.label.selectSeam.k_seam")},
                    {value: "V-seam", label: t("item:content.label.selectSeam.v_seam")},
                    {value: "X-seam", label: t("item:content.label.selectSeam.x_seam")},
                    {value: "2/3 X-seam", label: t("item:content.label.selectSeam.2_3_x_seam")},
                    {value: "2/3 X-seam", label: t("item:content.label.selectSeam.2_3_x_seam")},
                    {value: "Y-seam", label: t("item:content.label.selectSeam.y_seam")},
                    {value: "Tulips seam", label: t("item:content.label.selectSeam.tulips_seam")},
                    {value: "Double tulip", label: t("item:content.label.selectSeam.double_tulip")},
                    {value: "UV-seam", label: t("item:content.label.selectSeam.uv_seam")},
                    {value: "Steep side seam", label: t("item:content.label.selectSeam.steep_side_seam")},
                    {value: "Fillet weld", label: t("item:content.label.selectSeam.fillet_weld")},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_thickness_soldering_gap',
                label: t('item:content.label.custom_thickness_soldering_gap'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === 'joining_glueing') {
        return [
            {
                type: 'measurement',
                key: 'layer_thickness',
                label: t('item:content.label.layer_thickness'),
                unit: 'mm',
            },
            {
                type: 'measurement',
                key: 'surface_area',
                label: t('item:content.label.surface_area'),
                unit: 'mm²',
            },
            {
                type: 'text',
                key: 'material_1',
                label: t('item:content.label.material_1'),
                suggestions: [
                    {value: "Steel", label: t("item:content.label.selectMaterial.steel")},
                    {value: "Stainless Steel", label: t("item:content.label.selectMaterial.stainless_steel")},
                    {value: "Aluminum", label: t("item:content.label.selectMaterial.aluminum")},
                    {value: "Copper", label: t("item:content.label.selectMaterial.copper")},
                    {value: "Brass", label: t("item:content.label.selectMaterial.brass")},
                    {value: "Magnesium", label: t("item:content.label.selectMaterial.magnesium")},
                    {value: "Titanium", label: t("item:content.label.selectMaterial.titanium")},
                    {value: "Invar", label: t("item:content.label.selectMaterial.invar")},
                    {value: "Inconel", label: t("item:content.label.selectMaterial.inconel")},
                    {value: "SECTION", label: "SECTION"},
                    {value: "PC", label: "PC"},
                    {value: "Polypropylene", label: "Polypropylene"},
                    {value: "PP GF", label: "PP GF"},
                    {value: "Nylon", label: "Nylon"},
                    {value: "Nylon GF", label: "Nylon GF"},
                    {value: "POM (Delrin\\Acetal)", label: "POM (Delrin\\Acetal)"},
                    {value: "PTFE (Teflon)", label: "PTFE (Teflon)"},
                    {value: "PET", label: "PET"},
                    {value: "PMMA", label: "PMMA"},
                    {value: "Polyethylene", label: "Polyethylene"},
                    {value: "PEEK", label: "PEEK"},
                    {value: "PVC", label: "PVC"},
                ]
            },
            {
                type: 'text',
                key: 'material_2',
                label: t('item:content.label.material_2'),
                suggestions: [
                    {value: "Steel", label: t("item:content.label.selectMaterial.steel")},
                    {value: "Stainless Steel", label: t("item:content.label.selectMaterial.stainless_steel")},
                    {value: "Aluminum", label: t("item:content.label.selectMaterial.aluminum")},
                    {value: "Copper", label: t("item:content.label.selectMaterial.copper")},
                    {value: "Brass", label: t("item:content.label.selectMaterial.brass")},
                    {value: "Magnesium", label: t("item:content.label.selectMaterial.magnesium")},
                    {value: "Titanium", label: t("item:content.label.selectMaterial.titanium")},
                    {value: "Invar", label: t("item:content.label.selectMaterial.invar")},
                    {value: "Inconel", label: t("item:content.label.selectMaterial.inconel")},
                    {value: "SECTION", label: "SECTION"},
                    {value: "PC", label: "PC"},
                    {value: "Polypropylene", label: "Polypropylene"},
                    {value: "PP GF", label: "PP GF"},
                    {value: "Nylon", label: "Nylon"},
                    {value: "Nylon GF", label: "Nylon GF"},
                    {value: "POM (Delrin\\Acetal)", label: "POM (Delrin\\Acetal)"},
                    {value: "PTFE (Teflon)", label: "PTFE (Teflon)"},
                    {value: "PET", label: "PET"},
                    {value: "PMMA", label: "PMMA"},
                    {value: "Polyethylene", label: "Polyethylene"},
                    {value: "PEEK", label: "PEEK"},
                    {value: "PVC", label: "PVC"},
                ]
            },
            {
                type: 'text',
                key: 'application_method',
                label: t('item:content.label.application_pattern'),
                suggestions: [
                    {value: "caterpillar", label: t("item:content.label.selectApplication_pattern.caterpillar")},
                    {value: "area", label: t("item:content.label.selectApplication_pattern.area")},
                    {value: "punctual", label: t("item:content.label.selectApplication_pattern.punctual")},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_mechanical_load_capacity',
                label: t('item:content.label.custom_mechanical_load_capacity'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_product_life_cycle',
                label: t('item:content.label.custom_product_life_cycle'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_temperature_resistance',
                label: t('item:content.label.custom_temperature_resistance'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_chemical_resistance',
                label: t('item:content.label.custom_chemical_resistance'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_weather_resistance',
                label: t('item:content.label.custom_weather_resistance'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            }
        ] as FormFieldType[]
    }

    if(props.name === "cnc_milling") {
        return [
            {
                type: 'measurement',
                key: 'surface_area',
                label: t('item:content.label.surface_area'),
                unit: 'mm²',
            },
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038", label: "Steel S235 / St37-K / 1.0038"},
                    {value: "Steel S355 / St52 / 1.0570", label: "Steel S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                    {value: "Titanium / EN 3.7035", label: "Titanium / EN 3.7035"},
                    {value: "ABS", label: "ABS"},
                    {value: "POM", label: "POM"},
                    {value: "PC", label: "PC"},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_tolerances',
                label: t('item:content.label.custom_tolerances'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "cnc_drilling") {
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038", label: "Steel S235 / St37-K / 1.0038"},
                    {value: "Steel S355 / St52 / 1.0570", label: "Steel S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                    {value: "Titanium / EN 3.7035", label: "Titanium / EN 3.7035"},
                    {value: "ABS", label: "ABS"},
                    {value: "POM", label: "POM"},
                    {value: "PC", label: "PC"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "cnc_turning") {
        return [
            {
                type: 'measurement',
                key: 'surface_area',
                label: t('item:content.label.surface_area'),
                unit: 'mm²',
            },
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038", label: "Steel S235 / St37-K / 1.0038"},
                    {value: "Steel S355 / St52 / 1.0570", label: "Steel S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                    {value: "Titanium / EN 3.7035", label: "Titanium / EN 3.7035"},
                    {value: "ABS", label: "ABS"},
                    {value: "POM", label: "POM"},
                    {value: "PC", label: "PC"},
                ]
            },
            {
                type: 'text',
                key: 'rotation_axis',
                label: t('item:content.label.rotation_axis'),
                suggestions: [
                    {value: "x", label: "x"},
                    {value: "y", label: "y"},
                    {value: "z", label: "z"},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_tolerances',
                label: t('item:content.label.custom_tolerances'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "cnc_grinding") {
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038", label: "Steel S235 / St37-K / 1.0038"},
                    {value: "Steel S355 / St52 / 1.0570", label: "Steel S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                    {value: "Titanium / EN 3.7035", label: "Titanium / EN 3.7035"},
                    {value: "ABS", label: "ABS"},
                    {value: "POM", label: "POM"},
                    {value: "PC", label: "PC"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "casting_die_cast"){
        return [
            {
                type: 'text',
                key: 'post_treatment',
                label: t('item:content.label.post_treatment'),
                suggestions: [
                    {value: "Fine grinding", label: t("item:content.label.selectPost_treatment.fine_grinding")},
                    {value: "lapping", label: t("item:content.label.selectPost_treatment.lapping")},
                    {value: "hard chrome plating", label: t("item:content.label.selectPost_treatment.hard_chrome_plating")},
                    {value: "nitriding", label: t("item:content.label.selectPost_treatment.nitriding")},
                ]
            },
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_gating_line',
                label: t('item:content.label.custom_gating_line'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_runner_position',
                label: t('item:content.label.custom_runner_position'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_number_of_cavities',
                label: t('item:content.label.custom_number_of_cavities'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "casting_injection_moulding"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'text',
                key: 'form_surface',
                label: t('item:content.label.form_surface'),
                suggestions: [
                    {value: "no surface treatment", label: t("item:content.label.selectForm_surface.no_surface_treatment")},
                    {value: "Glossy surface (A-1)", label: t("item:content.label.selectForm_surface.glossy_surface_a_1")},
                    {value: "Glossy surface (A-2)", label: t("item:content.label.selectForm_surface.glossy_surface_a_2")},
                    {value: "Glossy surface (A-3)", label: t("item:content.label.selectForm_surface.glossy_surface_a_3")},
                    {value: "Semi-gloss surface (B-1)", label: t("item:content.label.selectForm_surface.semi_gloss_surface_b_1")},
                    {value: "Semi-gloss surface (B-2)", label: t("item:content.label.selectForm_surface.semi_gloss_surface_b_2")},
                    {value: "Semi-gloss surface (B-3)", label: t("item:content.label.selectForm_surface.semi_gloss_surface_b_3")},
                    {value: "Matt surface (C-1)", label: t("item:content.label.selectForm_surface.matt_surface_c_1")},
                    {value: "Matt surface (C-2)", label: t("item:content.label.selectForm_surface.matt_surface_c_2")},
                    {value: "Matt surface (C-3)", label: t("item:content.label.selectForm_surface.matt_surface_c_3")},
                    {value: "Textured surface (D-1)", label: t("item:content.label.selectForm_surface.textured_surface_d_1")},
                    {value: "Textured surface (D-2)", label: t("item:content.label.selectForm_surface.textured_surface_d_2")},
                    {value: "Textured surface (D-3)", label: t("item:content.label.selectForm_surface.textured_surface_d_3")},
                ]
            },
            {
                type: 'text',
                key: 'mold',
                label: t('item:content.label.mold'),
                suggestions: [
                    {value: "steel mold", label: t("item:content.label.selectMold.steel_mold")},
                    {value: "aluminum mold", label: t("item:content.label.selectMold.aluminum_mold")},
                ]
            },
            {
                type: 'text',
                key: 'number_of_cavities',
                label: t('item:content.label.number_of_cavities'),
            },
            {
                type: 'optional-text',
                key: 'custom_dividing_line',
                label: t('item:content.label.custom_dividing_line'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'optional-text',
                key: 'custom_sprue_position',
                label: t('item:content.label.custom_sprue_position'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "casting_investment_casting"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "casting_sand_casting"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_blasting"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_brush"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_grinding"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_electroPlatedNickelPlating"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_electroPlatedChromePlating"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_laserEngraving"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_polishing"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_painting"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            },
            {
                type: 'color',
                key: 'color',
                label: t('item:content.label.color'),
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_anodizing"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            },
            {
                type: 'color',
                key: 'color',
                label: t('item:content.label.color'),
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_powderCoating"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            },
            {
                type: 'color',
                key: 'color',
                label: t('item:content.label.color'),
            }
        ] as FormFieldType[]
    }

    if(props.name === "postprocessing_electroGalvanizing"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "sheetmetal_tubeLaserCutting"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "sheetmetal_punching"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "sheetmetal_bending"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "sheetmetal_folding"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "sheetmetal_laserCutting"){
        return [
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: "Aluminum 5083 / AlMg4", label: "Aluminum 5083 / AlMg4"},
                    {value: "5Mn / 3.1645", label: "5Mn / 3.1645"},
                    {value: "Aluminum 6060 / Al MgSi / 3.3206", label: "Aluminum 6060 / Al MgSi / 3.3206"},
                    {value: "7075 / Al Zn6MgCu / 3.4365", label: "7075 / Al Zn6MgCu / 3.4365"},
                    {value: "Steel S235 / St37-K / 1.0038 steel", label: "Steel S235 / St37-K / 1.0038 steel"},
                    {value: "S355 / St52 / 1.0570", label: "S355 / St52 / 1.0570"},
                    {value: "C45 / 1.0503", label: "C45 / 1.0503"},
                    {value: "Steel 1.7225 / 42CrMo4", label: "Steel 1.7225 / 42CrMo4"},
                    {value: "Stainless steel V2A / X5CrNi18.10 / 1.4301", label: "Stainless steel V2A / X5CrNi18.10 / 1.4301"},
                    {value: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404", label: "Stainless steel V4A / X2CrNiMo17-12-2 / 1.4404"},
                ]
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "printing3D_3DPrinting"){
        return [
            {
                type: 'measurement',
                key: 'filling_degree',
                label: t('item:content.label.filling_degree'),
                unit: '%',
            },
            {
                type: 'measurement',
                key: 'layer_height',
                label: t('item:content.label.layer_height'),
                unit: 'mm',
            },
            {
                type: 'text',
                key: 'material',
                label: t('item:content.label.material'),
                suggestions: [
                    {value: 'ABS', label: 'ABS'},
                    {value: 'Nylon', label: 'Nylon'},
                    {value: 'PETG', label: 'PETG'},
                    {value: 'PLA', label: 'PLA'},
                    {value: 'Wood', label: t('item:content.label.selectMaterial.wood')},
                    {value: 'ASA', label: 'ASA'},
                    {value: 'Polycarbonate', label: 'Polycarbonate'},
                    {value: 'TPU', label: 'TPU'},
                    {value: 'Carbon fiber', label: 'Carbon fiber'},
                    {value: 'Peek', label: 'Peek'},
                    {value: 'Alumide', label: 'Alumide'},
                    {value: 'Ceramics', label: 'Ceramics'},
                    {value: 'Pet', label: 'Pet'},
                    {value: 'PVA', label: 'PVA'},
                ]
            },
            {
                type: 'text',
                key: 'orientation',
                label: t('item:content.label.orientation'),
                suggestions: [
                    {value: "x", label: "x"},
                    {value: "y", label: "y"},
                    {value: "z", label: "z"},
                ]
            },
            {
                type: 'optional-text',
                key: 'custom_tolerances',
                label: t('item:content.label.custom_tolerances'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 6,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "standardComponent_standardComponent"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "customProcess_customProcess"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "qualityControl_qualityControl"){
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "rawMaterial_rawMaterial") {
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('item:content.label.remarks'),
                xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
            }
        ] as FormFieldType[]
    }
}
