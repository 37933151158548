/* -------------------------- Design imports start -------------------------- */
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
/* ------------------------- Functional imports end ------------------------- */

interface TimeInputProps {
  label: string
  value: Date | null
  onChange: (value: string) => void
  disabled?: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function TimeInput(props: TimeInputProps) {
  /* -------------------------- Non state data start -------------------------- */
  const { label, value, onChange, disabled } = props

  /* --------------------------- Non state data end --------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleTimeChange = (time: any | null) => {
    if (time) {
      onChange(time.format('HH:mm:ss'))
    } else {
      onChange('')
    }
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker
          label={label}
          ampm={false}
          value={value}
          onChange={handleTimeChange}
          disabled={disabled}
          defaultValue={null}
          views={['hours', 'minutes', 'seconds']}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
