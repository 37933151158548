/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React from 'react';
import LogTool from '../../../logger/logTools';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../utils/context';
import { APIAddress } from '../../../generated-types';
import { createAddress, getPKfromSelf, isPrivileged } from '../../../utils/functions';
import { Address } from '../../../utils/types';
import InfoPanel from '../../../components/layout/InfoPanel';
import { Chip, Grid } from '@mui/material';
import Button from '../../../components/Button';
import InfoField from '../../../components/inputs/InfoField';
import moment from 'moment';
import FieldLabel from '../../../components/widgets/FieldLabel';
import { Table } from 'antd';
import { Check, Close } from '@mui/icons-material';
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOpenFilesDrawer: (open: boolean) => void;
  infoData: any;
  title?: string;
  disabled?: boolean;
}

export default function ContractInfoPanel(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, setOpenFilesDrawer, infoData, title = '', disabled = false } = props
  const log = new LogTool({ context: 'ContractInfoPanel', enable: true, logLevel: 'warn' })
  const { t } = useTranslation()
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */

  const renderContractStatus = (status: number | undefined) => {
    if(status === undefined) return t("common:content.label.notAvailable")
    switch (status) {
      case 0:
        return t("common:content.label.created")
      case 1:
        return t("common:content.label.productionOrdersCreated")
      case 2:
        return t("common:content.label.inProgress")
      case 3:
        return t("common:content.label.shipped")
      case 4:
        return t("common:content.label.completed")
      case 5:
        return t("common:content.label.paid")
      case 6:
        return t("common:content.label.archived")
      case 7:
        return t("common:content.label.trash")
      case 8:
        return t("common:content.label.deleted")
      default:
        return status
    }
  }

  const renderAddress = (apiAddress: APIAddress) => {
    const address = createAddress(apiAddress as Address);
    return (
        <>
            {address?.address1}, {address?.address2} {address?.address2}<br />
            {address?.zipCode} {address?.city}<br />
            {address?.country} <br />
            {address?.info}
        </>
    )
}
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  log.debug("InfoData -> ", infoData)
  return (
    <InfoPanel
      open={open}
      setOpen={setOpen}
      label={t("common:content.label.contractInformation")}
      allowEdit={false}
      disabled={true}
    >
      <Grid container spacing={2} style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <InfoField
            label={t("contract:content.label.contractNumber")}
            fullWidth
            value={infoData?.self && getPKfromSelf(infoData?.self)}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("contract:content.label.offerNumber")}
            fullWidth
            value={infoData?.offer?.self && getPKfromSelf(infoData?.offer.self)}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.createdAt")}
            fullWidth
            value={moment(infoData?.createdAt).format('DD.MM.YYYY')}
          />
        </Grid>
        {isPrivileged(user, "STAFF") && (
          <Grid item xs={12}>
            <InfoField
              label={t("common:content.label.contractStatus")}
              fullWidth
              value={renderContractStatus(infoData?.contractStatus)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.price")}
            fullWidth
            value={infoData?.price ? Object.keys(infoData?.price).map(p => `${infoData.price[p]} ${t('request:content.label.pieces')}: ${p} €`) : t("common:content.label.notAvailable")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.certificate")}
            fullWidth
            value={infoData?.request?.certificate}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.comment")}
            fullWidth
            value={infoData?.comment}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.desiredDeliveryDate")}
            fullWidth
            value={infoData?.desiredDelivery}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.latestDeliveryDate")}
            fullWidth
            value={infoData?.latestDelivery}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.shippingAddress")}
            fullWidth
            value={infoData?.shippingAddress ? renderAddress(infoData?.shippingAddress) : t("common:content.label.notAvailable")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.billingAddress")}
            fullWidth
            value={infoData?.billingAddress ? renderAddress(infoData?.billingAddress) : t("common:content.label.notAvailable")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.priorityShipping")}
            fullWidth
            value={infoData?.priorityShipping ? <Check /> : <Close />}
          />
        </Grid>
        {isPrivileged(user, "STAFF") && (<Grid item xs={12}>
          <InfoField
            label={t('common:content.label.editors')}
            fullWidth
            value={""}
          />
          {infoData?.editors && infoData.editors.length > 0 && infoData.editors.map((editor: any) => {
            return <Chip key={editor.id} label={`${editor.first_name} ${editor.last_name}`} sx={{marginLeft: "10px"}} />
          })}
        </Grid>)}
        <Grid item xs={12}>
          <Button
            id="showFilesButton"
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenFilesDrawer(true)
            }}
          >
            {t('common:interaction.button.showFiles')}
          </Button>
        </Grid>
      </Grid>
    </InfoPanel>
  )
}

