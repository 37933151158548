/* --------------------------- Design states start -------------------------- */
import { FormControl, FormHelperText, TextField } from '@mui/material'
/* ---------------------------- Design states end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: string) => void
  validation?: boolean
  errorMessage?: string
  disabled?: boolean
  required?: boolean
  multiline?: boolean
  showError?: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function EmailInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    label,
    value = '',
    onChange,
    labelWidth = 100,
    disabled = false,
    required = false,
    errorMessage = '',
    validation,
    multiline,
    showError = true,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(showError)
  /* ----------------------------- Flag states end ---------------------------- */

  const isValidEmail = (email: string) => {    
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if(showError){
      validation !== undefined && value === ""
        ? setError(true) 
        : setError(false)
    }
  }, [showError, value, validation])
  /* ------------------------------- Effects end ------------------------------ */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <FormControl fullWidth variant="outlined">
      <TextField
        id={label}
        variant="outlined"
        multiline={multiline}
        rows={multiline ? 4 : 1}
        label={label}
        value={value || ''}
        onChange={e => {
          e.currentTarget.value !== '' || validation === undefined
            ? setError(false)
            : setError(true)
          onChange(e.currentTarget.value)
        }}
        autoComplete="text"
        required={required}
        disabled={disabled}
        error={error || (value !== "" && !isValidEmail(value))}
        aria-describedby="text-input"
        inputProps={{
          'aria-label': 'weight',
        }}
      />
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
