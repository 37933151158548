/* -------------------------- Design imports start -------------------------- */
import Page from "../components/layout/Page"
import LogTool from "../logger/logTools"
import Footer from "../components/layout/Footer"
import maintenanceIllustration from '../assets/QC_grayscale.jpeg'
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { AutoAwesomeRounded, AutoModeRounded, BuildRounded } from "@mui/icons-material"
/* ------------------------- Functional imports end ------------------------- */


type MaintenanceHint = {
  type: 'newFeature' | 'optimizingFeature' | 'bugFixes'
  description: any | string
}


/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function MaintenanceInfoPage() {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({context: 'MaintenanceInfoPage', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  // some flag states...
  // e.g. const [openSupplierModal, setOpenSupplierModal] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  // some data states/refs...
  // e.g. const articleStore = useRef<Article[]>()
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  // some useEffects...
  // !! Note!!: you should try to avoid setting state in a useEffect as this renders
  // the component twice. For more info read: https://react.dev/learn/you-might-not-need-an-effect
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  // some API functions like fetch, create, ...
  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  // some callback functions...
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const maintenanceHints: MaintenanceHint[] = [
    {
      type: 'newFeature',
      description: <span><b>Sharepoint</b>: Erstellen Sie Freigaben und holen Sie sich Rückmeldung zu Ihren freigegebenen Artikeln ein.</span>
    },
    {
      type: 'optimizingFeature',
      description: <span><b>Kontakte</b>: Ein Kontakt kann nun mehrere Adressen speichern.</span>
    },
    {
      type: 'bugFixes',
      description: <span>Import / Export: Das Importieren von bereits vorhandenen Artikeln aktualisiert diese, anstatt das der Import fehlschlägt.</span>
    }
  ]



  const renderMaintenanceHint = (hint: MaintenanceHint, listItemKey: number) => {
    return (
      <ListItem key={listItemKey}>
        <ListItemIcon>
          {hint.type === 'newFeature' && <AutoAwesomeRounded color='primary' />}
          {hint.type === 'optimizingFeature' && <AutoModeRounded />}
          {hint.type === 'bugFixes' && <BuildRounded sx={{color: 'lightgray'}} />}
        </ListItemIcon>
        <ListItemText>
          {hint.description}
        </ListItemText>
      </ListItem>
    )
  }
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */
  // Effects that should happen on certain re-renders, that are not allowed in
  // useEffect because of setting states
  // e.g. setArticleOptions on the NewItemPage should be called every time
  // the modal is opened:
  /* ------------------------- Pre render actions end ------------------------- */



  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <Page title='' withoutPadding>
      <Box height='100vh' width='100vw' display='flex' flexDirection='column'>
        <Box flexBasis={1} flexGrow={1} flexShrink={0} padding='15vh 20vw'>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='end'>
            <Typography variant="h4">
              Wir führen gerade Wartungen durch
            </Typography>
            <img
              src={maintenanceIllustration}
              alt="Maintenance illustration"
              style={{
                marginBottom: '15px',
                width: '250px',
                height: 'auto',
                opacity: 0.6,
              }}
            />
          </Box>
          <Typography variant="body1" mt='1.5rem'>
            Entschuldigung für die Unannehmlichkeiten, die assemblean Platform ist bald wieder erreichbar! Wir arbeiten ständig an der Verbesserung der Platform, um Ihnen eine immer bessere Nutzererfahrung und immer mehr Funktionalitäten zu bieten.
          </Typography>
          <List>
            {maintenanceHints.map((hint: MaintenanceHint, index: number) => {
              return renderMaintenanceHint(hint, index)
            })}
          </List>
        </Box>
        <Footer/>
      </Box>
    </Page>
  )
}