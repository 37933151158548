/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIInventoryMovement,
    APIInventoryMovementFromJSON,
    APIInventoryMovementToJSON,
    APIInventoryMovementRequest,
    APIInventoryMovementRequestFromJSON,
    APIInventoryMovementRequestToJSON,
    APIPaginatedInventoryMovementList,
    APIPaginatedInventoryMovementListFromJSON,
    APIPaginatedInventoryMovementListToJSON,
    APIPatchedInventoryMovementRequest,
    APIPatchedInventoryMovementRequestFromJSON,
    APIPatchedInventoryMovementRequestToJSON,
} from '../models';

export interface V1LogisticsInventoryMovementsCreateRequest {
    aPIInventoryMovementRequest: APIInventoryMovementRequest;
}

export interface V1LogisticsInventoryMovementsDestroyRequest {
    id: number;
}

export interface V1LogisticsInventoryMovementsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1LogisticsInventoryMovementsPartialUpdateRequest {
    id: number;
    aPIPatchedInventoryMovementRequest?: APIPatchedInventoryMovementRequest;
}

export interface V1LogisticsInventoryMovementsRetrieveRequest {
    id: number;
}

export interface V1LogisticsInventoryMovementsUpdateRequest {
    id: number;
    aPIInventoryMovementRequest: APIInventoryMovementRequest;
}

/**
 * 
 */
export class LogisticsinventoryMovementsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsCreateRaw(requestParameters: V1LogisticsInventoryMovementsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.aPIInventoryMovementRequest === null || requestParameters.aPIInventoryMovementRequest === undefined) {
            throw new runtime.RequiredError('aPIInventoryMovementRequest','Required parameter requestParameters.aPIInventoryMovementRequest was null or undefined when calling v1LogisticsInventoryMovementsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIInventoryMovementRequestToJSON(requestParameters.aPIInventoryMovementRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsCreate(requestParameters: V1LogisticsInventoryMovementsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsDestroyRaw(requestParameters: V1LogisticsInventoryMovementsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsDestroy(requestParameters: V1LogisticsInventoryMovementsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1LogisticsInventoryMovementsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsListRaw(requestParameters: V1LogisticsInventoryMovementsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedInventoryMovementList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedInventoryMovementListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsList(requestParameters: V1LogisticsInventoryMovementsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedInventoryMovementList> {
        const response = await this.v1LogisticsInventoryMovementsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsPartialUpdateRaw(requestParameters: V1LogisticsInventoryMovementsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedInventoryMovementRequestToJSON(requestParameters.aPIPatchedInventoryMovementRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsPartialUpdate(requestParameters: V1LogisticsInventoryMovementsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsRetrieveRaw(requestParameters: V1LogisticsInventoryMovementsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsRetrieve(requestParameters: V1LogisticsInventoryMovementsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsUpdateRaw(requestParameters: V1LogisticsInventoryMovementsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsUpdate.');
        }

        if (requestParameters.aPIInventoryMovementRequest === null || requestParameters.aPIInventoryMovementRequest === undefined) {
            throw new runtime.RequiredError('aPIInventoryMovementRequest','Required parameter requestParameters.aPIInventoryMovementRequest was null or undefined when calling v1LogisticsInventoryMovementsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIInventoryMovementRequestToJSON(requestParameters.aPIInventoryMovementRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsUpdate(requestParameters: V1LogisticsInventoryMovementsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
