/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef } from 'react'
import * as BABYLON from 'babylonjs'
import Engine = BABYLON.Engine
import Scene = BABYLON.Scene
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function SceneComponent(props: any) {
  /* -------------------------- Non state data start -------------------------- */
  const reactCanvas = useRef(null)
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
  } = props
  /* --------------------------- Non state data end --------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio)
      const scene = new Scene(engine, sceneOptions)

      if (scene.isReady()) {
        props.onSceneReady(scene)
      } else {
        scene.onReadyObservable.addOnce((scene: any) => props.onSceneReady(scene))
      }

      engine.runRenderLoop(() => {
        if (typeof onRender === 'function') {
          onRender(scene)
        }
        scene.render()
      })

      const resize = () => {
        scene.getEngine().resize()
      }

      if (window) {
        window.addEventListener('resize', resize)
      }
      return () => {
        scene.getEngine().dispose()
        if (window) {
          window.removeEventListener('resize', resize)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactCanvas])
  /* ------------------------------- Effects end ------------------------------ */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return <canvas ref={reactCanvas} {...rest} style={{ margin: '10px', width: '97%' }} />
}
