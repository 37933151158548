/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedProductionOrderList,
    APIPaginatedProductionOrderListFromJSON,
    APIPaginatedProductionOrderListToJSON,
    APIPatchedProductionOrderRequest,
    APIPatchedProductionOrderRequestFromJSON,
    APIPatchedProductionOrderRequestToJSON,
    APIProductionOrder,
    APIProductionOrderFromJSON,
    APIProductionOrderToJSON,
    APIProductionOrderRequest,
    APIProductionOrderRequestFromJSON,
    APIProductionOrderRequestToJSON,
} from '../models';

export interface V1CustomerProductionOrdersCreateRequest {
    aPIProductionOrderRequest: APIProductionOrderRequest;
}

export interface V1CustomerProductionOrdersDestroyRequest {
    id: number;
}

export interface V1CustomerProductionOrdersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1CustomerProductionOrdersPartialUpdateRequest {
    id: number;
    aPIPatchedProductionOrderRequest?: APIPatchedProductionOrderRequest;
}

export interface V1CustomerProductionOrdersRetrieveRequest {
    id: number;
}

export interface V1CustomerProductionOrdersUpdateRequest {
    id: number;
    aPIProductionOrderRequest: APIProductionOrderRequest;
}

export interface V1ItemsArticlesProductionOrdersCreateRequest {
    articlePk: number;
    aPIProductionOrderRequest: APIProductionOrderRequest;
}

export interface V1ItemsArticlesProductionOrdersDestroyRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesProductionOrdersListRequest {
    articlePk: number;
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesProductionOrdersPartialUpdateRequest {
    articlePk: number;
    id: number;
    aPIPatchedProductionOrderRequest?: APIPatchedProductionOrderRequest;
}

export interface V1ItemsArticlesProductionOrdersRetrieveRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesProductionOrdersUpdateRequest {
    articlePk: number;
    id: number;
    aPIProductionOrderRequest: APIProductionOrderRequest;
}

/**
 * 
 */
export class CustomerproductionOrdersApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersCreateRaw(requestParameters: V1CustomerProductionOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.aPIProductionOrderRequest === null || requestParameters.aPIProductionOrderRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionOrderRequest','Required parameter requestParameters.aPIProductionOrderRequest was null or undefined when calling v1CustomerProductionOrdersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionOrderRequestToJSON(requestParameters.aPIProductionOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersCreate(requestParameters: V1CustomerProductionOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1CustomerProductionOrdersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersDestroyRaw(requestParameters: V1CustomerProductionOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersDestroy(requestParameters: V1CustomerProductionOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1CustomerProductionOrdersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersListRaw(requestParameters: V1CustomerProductionOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedProductionOrderList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedProductionOrderListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersList(requestParameters: V1CustomerProductionOrdersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedProductionOrderList> {
        const response = await this.v1CustomerProductionOrdersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersPartialUpdateRaw(requestParameters: V1CustomerProductionOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedProductionOrderRequestToJSON(requestParameters.aPIPatchedProductionOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersPartialUpdate(requestParameters: V1CustomerProductionOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1CustomerProductionOrdersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersRetrieveRaw(requestParameters: V1CustomerProductionOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersRetrieve(requestParameters: V1CustomerProductionOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1CustomerProductionOrdersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersUpdateRaw(requestParameters: V1CustomerProductionOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersUpdate.');
        }

        if (requestParameters.aPIProductionOrderRequest === null || requestParameters.aPIProductionOrderRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionOrderRequest','Required parameter requestParameters.aPIProductionOrderRequest was null or undefined when calling v1CustomerProductionOrdersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionOrderRequestToJSON(requestParameters.aPIProductionOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersUpdate(requestParameters: V1CustomerProductionOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1CustomerProductionOrdersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersCreateRaw(requestParameters: V1ItemsArticlesProductionOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesProductionOrdersCreate.');
        }

        if (requestParameters.aPIProductionOrderRequest === null || requestParameters.aPIProductionOrderRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionOrderRequest','Required parameter requestParameters.aPIProductionOrderRequest was null or undefined when calling v1ItemsArticlesProductionOrdersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/production_orders/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionOrderRequestToJSON(requestParameters.aPIProductionOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersCreate(requestParameters: V1ItemsArticlesProductionOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1ItemsArticlesProductionOrdersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersDestroyRaw(requestParameters: V1ItemsArticlesProductionOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesProductionOrdersDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesProductionOrdersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/production_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersDestroy(requestParameters: V1ItemsArticlesProductionOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesProductionOrdersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersListRaw(requestParameters: V1ItemsArticlesProductionOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedProductionOrderList>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesProductionOrdersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/production_orders/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedProductionOrderListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersList(requestParameters: V1ItemsArticlesProductionOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedProductionOrderList> {
        const response = await this.v1ItemsArticlesProductionOrdersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersPartialUpdateRaw(requestParameters: V1ItemsArticlesProductionOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesProductionOrdersPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesProductionOrdersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/production_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedProductionOrderRequestToJSON(requestParameters.aPIPatchedProductionOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersPartialUpdate(requestParameters: V1ItemsArticlesProductionOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1ItemsArticlesProductionOrdersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersRetrieveRaw(requestParameters: V1ItemsArticlesProductionOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesProductionOrdersRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesProductionOrdersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/production_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersRetrieve(requestParameters: V1ItemsArticlesProductionOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1ItemsArticlesProductionOrdersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersUpdateRaw(requestParameters: V1ItemsArticlesProductionOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesProductionOrdersUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesProductionOrdersUpdate.');
        }

        if (requestParameters.aPIProductionOrderRequest === null || requestParameters.aPIProductionOrderRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionOrderRequest','Required parameter requestParameters.aPIProductionOrderRequest was null or undefined when calling v1ItemsArticlesProductionOrdersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/production_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionOrderRequestToJSON(requestParameters.aPIProductionOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesProductionOrdersUpdate(requestParameters: V1ItemsArticlesProductionOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionOrder> {
        const response = await this.v1ItemsArticlesProductionOrdersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
