/* -------------------------- Design imports start -------------------------- */
import {  Card, CardContent, Typography } from '@mui/material'
import LinearProgressWithLabel from '../widgets/LinearProgressWithLabel'
import UnauthFileList from '../widgets/UnauthFileList'
import { useDropzone } from 'react-dropzone'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type File = {
  name: string
  size: number
  type: string
  lastModified: number
  path: string
  webkitRelativePath: string
}

type Props = {
  fileList: File[]
  setFileList: (fileList: File[]) => void
  selectedForm?: string
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function UnauthS3FileUpload(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { fileList, setFileList, selectedForm = "unauthRequestForm" } = props
  const maxFileSize = 52428800 // 50MB
  const maxFiles = 10 // 10 files
  const log = new LogTool({ context: 'UnauthS3FileUpload', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false) // loading status for uploading files
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [errorMsg, setErrorMsg] = useState<string>('') // error status for uploading files
  const { t } = useTranslation(['common'])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (errorMsg) {
      log.error(errorMsg)
    }
  }, [errorMsg])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  // hook for upload that uses drag and drop
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({
      multiple: true,
      /* accept: ".stl, .stp, .step, .iges, .igs, .3mf, .e57, .example, .gltf-binary, .JT, .mesh, .mtl, .obj, .prc, .u3d, .vnd, vrml, .dxf, .dwg, .sat, .sab, .pdf, .xlsx, .doc, .docx, .ppt, .pptx, .xls, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .tif, .svg, .mp3, .mp4, .wav, .avi, .mov, .wmv, .flv, .mkv, .webm, .mpg, .mpeg, .m4v", */
      onDrop: (acceptedFiles: any[], fileRejections) => {
        addFiles(acceptedFiles)
        if (fileRejections.length > 0) {
          fileRejections.forEach(file => {
            if (file.errors.length > 0) {
              file.errors.forEach(error => {
                if (error.code === 'file-too-large') {
                  setErrorMsg('error.fileTooLargeText')
                } else if (error.code === 'file-invalid-type') {
                  setErrorMsg('error.fileInvalidTypeText')
                } else if (error.code === 'too-many-files') {
                  setErrorMsg('error.tooManyFilesText')
                } else {
                  setErrorMsg('error.fileInvalidText')
                }
              })
            }
          })
        }
      },
      maxFiles: maxFiles, // 10 files
      maxSize: maxFileSize, // 50MB
    })

  const addFiles = (files: File[]) => {
    setFileList([...fileList, ...files])
  }

  /* -------------------------- Utility functions end ------------------------- */
  /* ------------------------- Render constants start ------------------------- */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography
          color={selectedForm === 'unauthRequestForm' ? '' : 'gray'}
        >
          {t('common:content.label.uploadAdditionalFiles')}
        </Typography>
      </CardContent>
      <div className="container" style={{ margin: '5px' }}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p style={{color:'#666565'}}>
            {t('common:content.label.dragAndDrop')} <br />
            {t('request:content.label.uploadMaxFilesText')} {Math.ceil(maxFileSize / 1049000)}MB{' '}
            <br />
            {t('request:content.label.supportedFilesText')}
          </p>
        </div>
        {loading && <LinearProgressWithLabel value={100} />}
        {fileList.length > 0 && <UnauthFileList fileList={fileList} setFileList={setFileList} />}
      </div>
    </Card>
  )
}
