/* -------------------------- Design imports start -------------------------- */
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useEffect, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { APIAllInOneUser } from '../../generated-types/models/APIAllInOneUser'
import { APIArticle } from '../../generated-types/models/APIArticle'
import { APICustomer } from '../../generated-types/models/APICustomer'
import { ArticleInfo } from '../../features/Stock/utils/types'
import { SupplierInfo } from '../../features/Supplier/utils/types'
import { APISupplier } from '../../generated-types/models/APISupplier'
import { Customer } from '../../features/Customer/utils/types'
import { ProductionOrder, SupplyOrder } from '../../features/ProductionOrder/utils/types'
import { useTranslation } from 'react-i18next'
import { Article } from '../../features/Item/utils/types'
import { BOMNode } from '../../features/Bom/utils/types'
import LogTool from '../../logger/logTools'
import { CustomerOrder } from '../../features/Orders/utils/types'
import { Role } from '../../features/Roles/utils/types'
import Button from '../Button'

/* ------------------------- Functional imports end ------------------------- */

let textFieldValuesCache: { [key: string]: string } = {}

interface SupplierDetails {
  key: string
  item: string
  description: string
  comment?: string
}

interface ProcessPage {
  key: string
  name: string
  description: string
  comment?: string
}

interface DataType2 {
  key: string
  articleName: string
  name: string
  lastModified: string
  modifiedBy: string
  createdBy: string
  children?: DataType2[]
}

export interface FilterOptions {
  [key: string]: string[]
}

interface Props<
  T extends
    | Article
    | Role
    | ArticleInfo
    | APIArticle
    | DataType2
    | APIAllInOneUser
    | APICustomer
    | APISupplier
    | SupplyOrder
    | SupplierDetails
    | ProcessPage
    | SupplierInfo
    | Customer
    | CustomerOrder
    | ProductionOrder
    | BOMNode
> {
  columns: Array<ColumnProps<T>>
  onApplyFilter: (filterOptions: Record<string, string[]>) => void
  onCancelFilter: () => void
  onResetFilter: () => void
  filterOptions?: FilterOptions
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function FilterComponent<
  T extends
    | Article
    | Role
    | ArticleInfo
    | APIArticle
    | DataType2
    | APIAllInOneUser
    | APICustomer
    | APISupplier
    | SupplyOrder
    | SupplierDetails
    | CustomerOrder
    | ProcessPage
    | SupplierInfo
    | Customer
    | ProductionOrder
    | BOMNode
>(props: Props<T>) {
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* --------------------------- Data states start --------------------------- */
  const [textFieldValues, setTextFieldValues] = useState<{ [key: string]: string }>(
    textFieldValuesCache
  )
  const log = new LogTool({ context: 'filtermenu', enable: true, logLevel: 'warn' })
  const { t } = useTranslation(['common'])
  /* ---------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    textFieldValuesCache = textFieldValues
  }, [textFieldValues])

  useEffect(() => {
    setTextFieldValues(textFieldValuesCache)
  }, [])

  useEffect(() => {
    if (props.filterOptions) {
      const updatedTextFieldValues: { [key: string]: string } = {}
      Object.keys(props.filterOptions).forEach(key => {
        const value = props.filterOptions![key].join(', ')
        updatedTextFieldValues[key] = value
      })
      setTextFieldValues(updatedTextFieldValues)
    }
  }, [props.filterOptions])

  /* ------------------------------- Effects end ------------------------------ */

  /* ----------------------------- Functions start ---------------------------- */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setTextFieldValues(prevState => ({ ...prevState, [name]: value }))
  }

  const handleReset = () => {
    setTextFieldValues({})
    textFieldValuesCache = {}
    props.onResetFilter()
  }

  const BooleanSelect = ({
    value,
    onChange,
  }: {
    value: any
    onChange: (value: boolean) => void
  }) => {
    return (
      <FormControl fullWidth>
        <InputLabel id="boolean-select-label">{t('common:content.label.isActive')}</InputLabel>
        <Select
          labelId="boolean-select-label"
          value={value}
          onChange={e => onChange(e.target.value === 'true')}
          displayEmpty
        >
          <MenuItem value="" disabled></MenuItem>
          <MenuItem value="true">{t('common:content.label.yes')}</MenuItem>
          <MenuItem value="false">{t('common:content.label.no')}</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const handleApplyFilter = () => {
    const updatedFilterOptions: FilterOptions = {}
    Object.keys(textFieldValues).forEach(key => {
      const value = textFieldValues[key].split(',').map(val => val.trim())
      if (value.length === 1 && value[0] === '') {
        updatedFilterOptions[key] = []
      } else {
        updatedFilterOptions[key] = value
      }
    })
    log.debug('updatedFilterOptions', updatedFilterOptions)
    textFieldValuesCache = textFieldValues
    props.onApplyFilter(updatedFilterOptions)
  }

  const handleCancelFilter = () => {
    setTextFieldValues(textFieldValuesCache)
    props.onCancelFilter()
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleApplyFilter()
    }
  }

  const getUnit = (key: string) => {
    switch (key) {
      case 'weight':
        return 'g'
      case 'height':
      case 'width':
      case 'length':
        return 'm'
      case 'volume':
        return 'm³'
      default:
        return ''
    }
  }
  log.debug(props.columns, 'columnsFilterMenu')

  return (
    <Dialog open onClose={handleCancelFilter}>
      <DialogTitle>{t('common:content.label.filter')}</DialogTitle>
      <DialogContent>
        <Alert severity="info" color="warning" style={{ marginBottom: '10px' }}>
          {t('common:feedback.info.filterInfo')}
        </Alert>
        <Grid container spacing={2} style={{ marginTop: '5px' }}>
          {' '}
          {/* Use Grid container to arrange elements horizontally */}
          {props.columns.map(column => {
            if (
              column.key == 'weight' ||
              column.key == 'height' ||
              column.key == 'width' ||
              column.key == 'length' ||
              column.key == 'volume'
            ) {
              return (
                <Grid key={column.dataIndex as string} item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{column.title as string}</InputLabel>
                    <OutlinedInput
                      id={column.dataIndex as string}
                      type="number"
                      fullWidth
                      label={column.title as string}
                      name={column.dataIndex as string}
                      value={textFieldValues[column.dataIndex as string] ?? ''}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      endAdornment={
                        <InputAdornment position="end">{getUnit(column.key) ?? ''}</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              )
            }
            if (column.key == 'isActive') {
              return (
                <Grid key={column.dataIndex as string} item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <BooleanSelect
                      value={textFieldValues[column.dataIndex as string] || ''}
                      onChange={value =>
                        setTextFieldValues(prevState => ({
                          ...prevState,
                          [column.dataIndex as string]: value ? 'true' : 'false',
                        }))
                      }
                    />
                  </FormControl>
                </Grid>
              )
            } else if (
              column.key !== '' &&
              column.title !== '' &&
              column.key !== 'action' &&
              column.key !== 'subRoles' &&
              column.key !== 'priorityShipping'
            ) {
              return (
                <Grid key={column.dataIndex as string} item xs={12} md={6}>
                  {' '}
                  {/* Use xs and md to control width */}
                  <TextField
                    id={column.dataIndex as string}
                    fullWidth
                    label={column.title as string}
                    name={column.dataIndex as string}
                    value={textFieldValues[column.dataIndex as string] || ''}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
              )
            }
            return null
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id="cancelButton" variant="contained" color="inherit" onClick={handleCancelFilter}>
          {t('common:interaction.button.cancel')}
        </Button>
        <Button id="resetButton" variant="contained" onClick={handleReset}>
          {t('common:interaction.button.reset')}
        </Button>
        <Button id="applyButton" variant="contained" onClick={handleApplyFilter}>
          {t('common:interaction.button.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
