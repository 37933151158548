/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* -------------------------- Design imports start -------------------------- */
import { Add } from '@mui/icons-material'
import { TextField, TextFieldProps, Autocomplete, AutocompleteRenderOptionState, ListItem, ListItemButton, ListItemIcon, ListItemText, CircularProgress } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  name: string
  value: any[]
  errorMessage?: string
  options?: { value: string | number | boolean | null; label?: string; disabled?: boolean }[]
  loadData?: () => Promise<{ value: string | number | boolean | null; label?: string; disabled?: boolean }[]>
  loadAllData?: () => Promise<{ value: string | number | boolean | null; label?: string; disabled?: boolean }[]>
  disabled?: boolean
  required?: boolean
  onChange: (value: any[]) => void
  validation?: boolean
  onAddOption?: () => void
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function SearchMultiSelectInput<T>(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { name, value, options, loadData, disabled,loadAllData, errorMessage, validation, onChange, required, onAddOption } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(!value && required ? true : false)
  const [loading, setLoading] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ------------------------------Data states start--------------------------- */
  const [newOptions, setNewOptions] = useState<{ value: string | number | boolean | null; label?: string; disabled?: boolean }[]>(options || [])
  /* ------------------------------ Data states end --------------------------- */

  useEffect(() => {
    if(!value && required) {
      setError(true)
    }
    if(validation !== false && !value) {
      setError(true)
    }
  }, [value])

  const handleOpen = useCallback(async () => {
    if (loadData) {
      setLoading(true)
      const data = await loadData()
      setNewOptions(data)
      if (loadAllData) {
        const fulldata = await loadAllData()
        setNewOptions([])
        setNewOptions(fulldata)
      }
      setLoading(false)
    }
  }, [loadData, loadAllData])

  const parseValue = (value: any[]) => {
    if(Array.isArray(value)) {
      if(value[0] && Object.keys(value[0]).includes('label')) {
        return value
      } else {
        if(value) {
          return value.map((v: any) => {
            return {value: v, label: `${v.first_name} ${v.last_name}`}
          })
        } else {
          return []
        }
      }
    } else{
      if(value) {
        return [value]
      } else {
        return []
      }
    }
  }

  /* ------------------------- Render constants start ------------------------- */
  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => {
    if(loading && option.value === 'add') {
      return (
        <ListItem
          key={option.value}
          style={{ display: 'flex', alignItems: 'center', zIndex: 9999 }}
          disablePadding
        >
          <ListItemButton dense>
            <ListItemIcon>
              <CircularProgress color="inherit" size={20} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      )
    }
    if (!onAddOption && option.value === 'add') {
      return null
    }
    if (option.value === 'add') {
      return (
        <ListItem
          key={option.value}
          style={{ display: 'flex', alignItems: 'center' }}
          disablePadding
        >
          <ListItemButton  id="addOption" onClick={onAddOption} dense>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      )
    }
    return (
      <ListItem {...props} key={option.value.self} style={{ display: 'flex', alignItems: 'center', }}>
        <ListItemText primary={option.label} />
      </ListItem>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Autocomplete
      sx={{zIndex: 9999}}
      id={name}
      openOnFocus
      onOpen={handleOpen}
      multiple
      disabled={disabled}
      loading={loading}
      isOptionEqualToValue={(option, value) => {
        return option.value.self === value.self
      }}
      loadingText={<CircularProgress color="inherit" size={20} />}
      options={[{value: "add", label: "add"}, ...newOptions] as any[]}
      value={parseValue(value)}
      filterOptions={(options : any[], state : any) => {
        if (state.inputValue !== '' && state.index !== -1) {
          return options.filter((option) => option.label?.toLowerCase().includes(state.inputValue.toLowerCase()))
        } else {
          return options
        }
      }}
      renderOption={renderOption}
      disablePortal /* List of suggestions will not be rendered on top of everything */
      onChange={(_: any, option: any) => {
        onChange(option)
        option !== '' ? setError(false) : setError(true)
      }}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          id={name}
          required={required}
          {...params}
          error={error}
          helperText={error && errorMessage}
          label={name}
          variant="outlined"
        />
      )}
    />
  )
}