/* -------------------------- Design imports start -------------------------- */
import Drawer from '@mui/material/Drawer'
import { Grid, IconButton, Typography } from '@mui/material'
import { Cancel, Close, Edit, Save } from '@mui/icons-material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  allowEdit?: boolean
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
  children: React.ReactNode
  label: string
  disabled: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function InfoPanel(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    allowEdit = true,
    onEdit,
    onSave,
    onCancel,
    children,
    label,
    disabled,
  } = props
  const drawerWidth = 350
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const infoPanelRef = useRef<any>(null)
  const { t } = useTranslation()
  const history = useHistory()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    const adjustInfoPanelHeight = () => {
      const infoPanelElement = infoPanelRef.current
      if (infoPanelElement) {
        const windowHeight = window.innerHeight
        const infoPanelTopOffset = infoPanelElement.getBoundingClientRect().top
        const newInfoPanelHeight = windowHeight - infoPanelTopOffset
        infoPanelElement.style.height = `${newInfoPanelHeight}px`
      }
    }

    adjustInfoPanelHeight()
    window.addEventListener('resize', adjustInfoPanelHeight)

    return () => {
      window.removeEventListener('resize', adjustInfoPanelHeight)
    }
  }, [])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Render constants start ------------------------- */
  const list = () => (
    <div ref={infoPanelRef} style={{ overflowY: 'auto' }}>
      <Grid style={{ overflowY: 'auto' }} container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, margin: '14px' }}>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => setOpen(false)} sx={{ margin: '5px', marginTop: '10px' }}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginRight: '10px' }}>
          {allowEdit &&
            (!disabled ? (
              <>
                {onSave && (
                  <IconButton sx={{ float: 'right' }} onClick={onSave}>
                    <Save />
                  </IconButton>
                )}
                {onCancel && (
                  <IconButton sx={{ float: 'right' }} onClick={onCancel}>
                    <Cancel />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                {onEdit && (
                  <IconButton sx={{ float: 'right' }} onClick={onEdit}>
                    <Edit />
                  </IconButton>
                )}
              </>
            ))}
        </Grid>
        <Grid style={{ marginBottom: '5px' }} item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  )

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <div>
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              top: 64,
            },
          }}
          open={open}
          anchor="right"
          onClose={() => setOpen(false)}
          variant="persistent"
          style={{ position: 'relative', zIndex: 1 }}
        >
          {list()}
        </Drawer>
      </div>
    </div>
  )
}
