/* -------------------------- Design imports start -------------------------- */
import Page from '../components/layout/Page'
import { Box, Chip, Grid, Tab, Tabs, Typography } from '@mui/material'
import UnauthRequestOverviewPage from '../features/UnauthRequest/UnauthRequestOverviewPage'
import RequestOverviewPage from '../features/Request/Request/RequestOverviewPage'
import ContractOverviewPage from '../features/Contract/Contract/ContractOverviewPage'
import ContractInfoPanel from '../features/Contract/Contract/ContractInfoPanel'
import RequestInfoPanel from '../features/Request/Request/RequestInfoPanel'
import OfferOverviewPage from '../features/Request/Offer/OfferOverviewPage'
import OfferInfoPanel from '../features/Request/Offer/OfferInfoPanel'
import BillOverviewPage from '../features/Contract/Bill/BillOverviewPage'
import BillInfoPanel from '../features/Contract/Bill/BillInfoPanel'
import GuestRequestInfoPanel from '../features/Customer/GuestRequestInfoPanel'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../logger/logTools'
import { canView, isPrivileged } from '../utils/functions'
import { GuestRequest } from '../features/UnauthRequest/utils/types'
import { Request } from '../features/Request/utils/types'
import { useCountContext, useUserContext } from '../utils/context'
import { Contract } from '../features/Contract/utils/types'
/* ------------------------- Functional imports end ------------------------- */

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

type Props = {
  setBadgeStatus_countTotals?: (count: number) => void
  header?: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function ContractOverviewAdminPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { setBadgeStatus_countTotals, header } = props
  const log = new LogTool({ context: 'RequestOverviewAdminPage', enable: true, logLevel: 'error' })
  const { userPermissions, user } = useUserContext()
  const {
    initialGuestRequests,
    setInitialGuestRequests,
    initialRequests,
    setInitialRequests,
    initialOffers,
    setInitialOffers,
    initialContracts,
    setInitialContracts,
    initialBills,
    setInitialBills,
    countTotals,
    setCountTotals,
    tabValue,
    setTabValue,
  } = useCountContext()
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [openContractInfoPanel, setOpenContractInfoPanel] = useState(false)
  const [openGuestInfoPanel, setOpenGuestInfoPanel] = useState(false)
  const [openRequestInfoPanel, setOpenRequestInfoPanel] = useState(false)
  const [openOfferInfoPanel, setOpenOfferInfoPanel] = useState(false)
  const [openBillInfoPanel, setOpenBillInfoPanel] = useState(false)
  const [openBillFilesDrawer, setOpenBillFilesDrawer] = useState(false)
  const [openContractFilesDrawer, setOpenContractFilesDrawer] = useState(false)
  const [openRequestFilesDrawer, setOpenRequestFilesDrawer] = useState(false)
  const [openGuestRequestFilesDrawer, setOpenGuestRequestFilesDrawer] = useState(false)
  const [openOfferFilesDrawer, setOpenOfferFilesDrawer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* --------------------------- Data states start --------------------------- */
  const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined)
  const [selectedGuestRequest, setSelectedGuestRequest] = useState<GuestRequest | undefined>(
    undefined
  )
  const [selectedRequest, setSelectedRequest] = useState<Request | undefined>(undefined)
  const [selectedOffer, setSelectedOffer] = useState<any | undefined>()
  const [selectedBill, setSelectedBill] = useState<any | undefined>()
  const { t } = useTranslation()
  /* ---------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (setBadgeStatus_countTotals) setBadgeStatus_countTotals(countTotals)
  }, [countTotals])

  useEffect(() => {
    const total = initialGuestRequests.length + initialRequests.length + initialOffers.length + initialContracts.length + initialBills.length
    setCountTotals(total)
  }, [initialGuestRequests, initialRequests, initialOffers, initialContracts, initialBills])
  /* ------------------------------- Effects end ------------------------------ */
  /* ------------------------- Utility functions start ------------------------ */

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const renderTabLabel = (count: number, title: string) => {
    if(count === 0) return title
    return (
      <Grid container spacing={1}>
        <Grid item sx={{marginTop: "5px"}}>
          <Typography variant="inherit">{title}</Typography>
        </Grid>
        <Grid item>
          <Chip
            label={count}
            color="primary"
            size="small"
            variant='outlined'
          />
        </Grid>
      </Grid>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  if (header) return null

  return (
    <Page marginBottom="200px">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            if(tabValue === newValue) return
            if(isPrivileged(user, 'STAFF')) {
              if(tabValue === 0) setInitialGuestRequests([])
              if(tabValue === 1) setInitialRequests([])
              if(tabValue === 2) setInitialOffers([])
              if(tabValue === 3) setInitialContracts([])
              if(tabValue === 4) setInitialBills([])
            } else {
              if(tabValue === 0) setInitialRequests([])
              if(tabValue === 1) setInitialOffers([])
              if(tabValue === 2) setInitialContracts([])
              if(tabValue === 3) setInitialBills([])
            }
            setTabValue(newValue)
          }}
          aria-label="basic tabs example"
        >
          {(canView(userPermissions, 'guest_request:guest_request') ||
            isPrivileged(user, 'STAFF')) && (
              <Tab label={renderTabLabel(initialGuestRequests.length, t('common:content.label.guestRequests'))} {...a11yProps(0)} />
          )}
          {(canView(userPermissions, 'request:request') || isPrivileged(user)) && (
            <Tab label={renderTabLabel(initialRequests.length, t('common:content.label.requests'))} {...a11yProps(1)} />
          )}
          {(canView(userPermissions, 'request:offer') || isPrivileged(user)) && (
            <Tab label={renderTabLabel(initialOffers.length, t('common:content.label.offers'))} {...a11yProps(2)} />
          )}
          {(canView(userPermissions, 'contract:contract') || isPrivileged(user)) && (
            <Tab label={renderTabLabel(initialContracts.length, t('common:content.label.contracts'))} {...a11yProps(3)} />
          )}
          {(canView(userPermissions, 'contract:bill') || isPrivileged(user)) && (
            <Tab label={renderTabLabel(initialBills.length, t('common:content.label.bills'))} {...a11yProps(4)} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={isPrivileged(user, 'STAFF') ? 0 : -1}>
        <UnauthRequestOverviewPage
          openInfoPanel={openGuestInfoPanel}
          setOpenInfoPanel={setOpenGuestInfoPanel}
          setSelectedGuestRequest={setSelectedGuestRequest}
          openFilesDrawer={openGuestRequestFilesDrawer}
          setOpenFilesDrawer={setOpenGuestRequestFilesDrawer}
          selectedGuestRequest={selectedGuestRequest}
        />
        <GuestRequestInfoPanel
          open={openGuestInfoPanel}
          setOpen={setOpenGuestInfoPanel}
          setOpenFilesDrawer={setOpenGuestRequestFilesDrawer}
          infoData={selectedGuestRequest}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={isPrivileged(user, 'STAFF') ? 1 : 0}>
        <RequestOverviewPage
          openInfoPanel={openRequestInfoPanel}
          setOpenInfoPanel={setOpenRequestInfoPanel}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          openFilesDrawer={openRequestFilesDrawer}
          setOpenFilesDrawer={setOpenRequestFilesDrawer}
        />
        <RequestInfoPanel
          open={openRequestInfoPanel}
          setOpen={setOpenRequestInfoPanel}
          setOpenFilesDrawer={setOpenRequestFilesDrawer}
          infoData={selectedRequest}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={isPrivileged(user, 'STAFF') ? 2 : 1}>
        <OfferOverviewPage
          openInfoPanel={openOfferInfoPanel}
          setOpenInfoPanel={setOpenOfferInfoPanel}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          openFilesDrawer={openOfferFilesDrawer}
          setOpenFilesDrawer={setOpenOfferFilesDrawer}
        />
        <OfferInfoPanel
          open={openOfferInfoPanel}
          setOpen={setOpenOfferInfoPanel}
          setOpenFilesDrawer={setOpenOfferFilesDrawer}
          infoData={selectedOffer}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={isPrivileged(user, 'STAFF') ? 3 : 2}>
        <ContractOverviewPage
          openInfoPanel={openContractInfoPanel}
          setOpenInfoPanel={setOpenContractInfoPanel}
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
          openFilesDrawer={openContractFilesDrawer}
          setOpenFilesDrawer={setOpenContractFilesDrawer}
        />
        <ContractInfoPanel
          open={openContractInfoPanel}
          setOpen={setOpenContractInfoPanel}
          setOpenFilesDrawer={setOpenContractFilesDrawer}
          infoData={selectedContract}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={isPrivileged(user, 'STAFF') ? 4 : 3}>
        <BillOverviewPage
          openInfoPanel={openBillInfoPanel}
          setOpenInfoPanel={setOpenBillInfoPanel}
          selectedBill={selectedBill}
          setSelectedBill={setSelectedBill}
          openFilesDrawer={openBillFilesDrawer}
          setOpenFilesDrawer={setOpenBillFilesDrawer}
        />
        <BillInfoPanel
          open={openBillInfoPanel}
          setOpen={setOpenBillInfoPanel}
          setOpenFilesDrawer={setOpenBillFilesDrawer}
          infoData={selectedBill}
        />
      </TabPanel>
    </Page>
  )
}
