/* -------------------------- Design imports start -------------------------- */
import { Grid } from '@mui/material'
import NumberInput from './NumberInput'
import styles from './utils/timeDeltaInput.module.css'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from 'react-i18next'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  label?: string | null
  value: { months: string; weeks: string; days: string }
  onChange: (input: any) => void
  disabled?: boolean
  required?: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function InfoField(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { label, value, onChange, disabled = false, required = false } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation()
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={`${styles.root} ${styles.fullwidth}`}>
      {label && <label className={styles.label}>{label}</label>}
      <Grid container spacing={1} sx={{ paddingTop: '1rem' }}>
        <Grid item xl={4}>
          <NumberInput
            label={t('common:content.label.months')}
            value={value?.months}
            limits={{ minInput: 0 }}
            onChange={input =>
              onChange({
                months: !Number.isNaN(input) && input,
                weeks: value?.weeks,
                days: value?.days,
              })
            }
            disabled={disabled}
            required={required}
            validation={true}
          />
        </Grid>
        <Grid item xl={4}>
          <NumberInput
            label={t('common:content.label.weeks')}
            value={value?.weeks}
            limits={{ minInput: 0 }}
            onChange={input =>
              onChange({
                months: value?.months,
                weeks: !Number.isNaN(input) && input,
                days: value?.days,
              })
            }
            disabled={disabled}
            required={required}
            validation={true}
          />
        </Grid>
        <Grid item xl={4}>
          <NumberInput
            label={t('common:content.label.days')}
            value={value?.days}
            limits={{ minInput: 0 }}
            onChange={input =>
              onChange({
                months: value?.months,
                weeks: value?.weeks,
                days: !Number.isNaN(input) && input,
              })
            }
            disabled={disabled}
            required={required}
            validation={true}
          />
        </Grid>
      </Grid>
      {/* <div className={styles.value_container}>
        <span className={styles.value_text}>
          {value}
        </span>
      </div> */}
    </div>
  )
}
