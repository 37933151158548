/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIBOM
 */
export interface APIBOM {
    /**
     * 
     * @type {string}
     * @memberof APIBOM
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIBOM
     */
    parent: string;
    /**
     * 
     * @type {string}
     * @memberof APIBOM
     */
    child: string;
    /**
     * 
     * @type {number}
     * @memberof APIBOM
     */
    amount?: number;
}

export function APIBOMFromJSON(json: any): APIBOM {
    return APIBOMFromJSONTyped(json, false);
}

export function APIBOMFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIBOM {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'parent': json['parent'],
        'child': json['child'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function APIBOMToJSON(value?: APIBOM | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parent': value.parent,
        'child': value.child,
        'amount': value.amount,
    };
}

