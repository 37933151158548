/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import { useUserContext } from '../../../utils/context'
import GetConstant from '../utils/constants'
import { FormFieldType } from '../../Form/utils/types'
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import { Form } from '../../Form'
import {
  deleteFile,
  isPrivileged,
  uploadFile,
} from '../../../utils/functions'
import { FileInfo, FileUploadStatus } from '../../../utils/types'
import { updateContract } from '../utils/functions'
import FileInput from '../../../components/inputs/FileInput'
import InputFileList from '../../../components/widgets/InputFileList'
import { LoadingButton } from '@mui/lab'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onCancel?: () => void
  onCreateConfirm?: (input: any) => Promise<string>
  enableEditMode?: boolean
  onEditConfirm?: (input: any) => Promise<string>
  setTabValue?: (value: number) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function BillStaffDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    onCancel = () => null,
    onCreateConfirm = () => null,
    enableEditMode = false,
    onEditConfirm = () => null,
    setTabValue,
  } = props

  const { t } = useTranslation(['contract', 'common'])
  const log = new LogTool({ context: 'BillStaffDrawer', enable: true, logLevel: 'warn' })
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [waiting, setWaiting] = useState(false)
  const [prevOpen, setPrevOpen] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start ---------------------------- */
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  /* ----------------------------- Data states end ----------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (open && !prevOpen) {
      setPrevOpen(true)
    }
  }, [open])

  useEffect(() => {
    if (fileInput.length > 0 && fileInput.every(file => file.uploadStatus === 'success')) {
      setWaiting(false)
      handleClose()
    }
  }, [fileInput])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadBillFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileUploadStatus(file, 'success')
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleCreateBill = async () => {
    log.debug('handleCreateBill -> ', formState.state)
    setWaiting(true)

    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    const billFilesUrl = await onCreateConfirm(formState.state)
    if(fileInput.length > 0 && billFilesUrl) {
      for(const file of fileInput) {
        uploadBillFile(file, billFilesUrl)
      }
    }
    setWaiting(false)
    handleClose()
    if(formState.state.paid === true) {
    updateContract(
      { ...formState.state.contract, contractStatus: 6 },
      [],
      () => {
        log.debug('Contract status updated successfully')
      },
      () => {
        log.error('Error updating contract status')
      }
    )}
    else{
      updateContract(
        { ...formState.state.contract, contractStatus: 5 },
        [],
        () => {
          log.debug('Contract status updated successfully')
        },
        () => {
          log.error('Error updating contract status')
        }
      )
    }
    setTabValue && setTabValue(isPrivileged(user, 'STAFF') ? 4 : 3)
  }

  const handleEditBill = async () => {
    log.debug('handleEditBill -> ', formState.state)
    setWaiting(true)
    if(formState.state.paid === true) {
      updateContract(
        { ...formState.state.contract, contractStatus: 6, editors:'' },
        [],
        () => {
          log.debug('Contract status updated successfully')
        },
        () => {
          log.error('Error updating contract status')
        }
      )
    }
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    const billFilesUrl = await onEditConfirm(formState.state)
    if(fileInput.length > 0 && billFilesUrl) {
      for(const file of fileInput) {
        uploadBillFile(file, billFilesUrl)
      }
    } else {
      setWaiting(false)
      handleClose()
    }
  }

  const handleBill = async (operation: 'update' | 'create') => {
    log.debug('handleBill -> ', operation)
    operation === 'create' ? handleCreateBill() : handleEditBill()
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }
  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadBillFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }

  const handleClose = (preventOnClose: boolean = false) => {
    // reset form state
    setWaiting(false)
    formState.setState({})
    setFileInput([])
    setOpen(false)
    setPrevOpen(false)
    !preventOnClose && onClose()
  }
  /* ------------------------- Callback functions end ------------------------- */
  console.log('formState.state -> ', formState.state)
  /* ------------------------- Render constants start ------------------------- */
  const billStaffFormFields = GetConstant({ name: 'billStaffFormFields' }) as FormFieldType[]
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */

  log.debug('FormInput -> ', formState.state)

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        size="big"
        title={
          enableEditMode
            ? (t('contract:content.label.editBill') as string)
            : (t('contract:content.label.createBill') as string)
        }
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Button
              id="cancelButton"
              onClick={() => setOpen(false)}
              color="inherit"
              variant="contained"
              sx={{ mr: 1, textTransform: 'none' }}
            >
              {t('common:interaction.button.cancel')}
            </Button>
            <LoadingButton
              id="createButton"
              variant="contained"
              sx={{ marginLeft: '10px', color: 'whitesmoke', textTransform: 'none' }}
              loading={waiting}
              onClick={enableEditMode ? () => handleBill('update') : () => handleBill('create')}
            >
              {enableEditMode ? t('common:interaction.button.save') : t('common:interaction.button.create')}
            </LoadingButton>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="contractPrice"
              label={t('contract:content.label.price')}
              variant="outlined"
              fullWidth
              disabled
              value={
                formState.state?.contract?.price
                  ? Object.keys(formState.state.contract.price).map(
                      p =>
                        `${formState.state.contract.price[p]} ${t(
                          'request:content.label.pieces'
                        )}: ${p} €`
                    )
                  : t('common:content.label.notAvailable')
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Form
              editing={true}
              formFields={billStaffFormFields}
              formObject={formState.state}
              onChange={(value: any) => {
                formState.setState(value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
              {t('common:content.label.documents')}
            </Typography>
            <FileInput
              inputState={{ state: fileInput, setState: setFileInput }}
              maxAllowedFiles={10}
            />
            {fileInput.length > 0 && (
              <InputFileList
                files={fileInput}
                noFilesInfo=""
                enableEditMode={false}
                onRemoveFile={handleRemoveFileFromList}
                onReUploadFile={handleReUploadFile}
                onDeleteFile={handleDeleteFile}
              />
            )}
          </Grid>
        </Grid>
      </MultilevelDrawer>
    </>
  )
}
