/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedRequestList,
    APIPaginatedRequestListFromJSON,
    APIPaginatedRequestListToJSON,
    APIPatchedRequestRequest,
    APIPatchedRequestRequestFromJSON,
    APIPatchedRequestRequestToJSON,
    APIRequest,
    APIRequestFromJSON,
    APIRequestToJSON,
    APIRequestRequest,
    APIRequestRequestFromJSON,
    APIRequestRequestToJSON,
} from '../models';

export interface V1RequestRequestsCreateRequest {
    aPIRequestRequest: APIRequestRequest;
}

export interface V1RequestRequestsDestroyRequest {
    id: number;
}

export interface V1RequestRequestsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1RequestRequestsPartialUpdateRequest {
    id: number;
    aPIPatchedRequestRequest?: APIPatchedRequestRequest;
}

export interface V1RequestRequestsRetrieveRequest {
    id: number;
}

export interface V1RequestRequestsUpdateRequest {
    id: number;
    aPIRequestRequest: APIRequestRequest;
}

/**
 * 
 */
export class RequestrequestApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsCreateRaw(requestParameters: V1RequestRequestsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.aPIRequestRequest === null || requestParameters.aPIRequestRequest === undefined) {
            throw new runtime.RequiredError('aPIRequestRequest','Required parameter requestParameters.aPIRequestRequest was null or undefined when calling v1RequestRequestsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIRequestRequestToJSON(requestParameters.aPIRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsCreate(requestParameters: V1RequestRequestsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsDestroyRaw(requestParameters: V1RequestRequestsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsDestroy(requestParameters: V1RequestRequestsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1RequestRequestsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsListRaw(requestParameters: V1RequestRequestsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedRequestList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedRequestListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsList(requestParameters: V1RequestRequestsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedRequestList> {
        const response = await this.v1RequestRequestsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsPartialUpdateRaw(requestParameters: V1RequestRequestsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedRequestRequestToJSON(requestParameters.aPIPatchedRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsPartialUpdate(requestParameters: V1RequestRequestsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsRetrieveRaw(requestParameters: V1RequestRequestsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsRetrieve(requestParameters: V1RequestRequestsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsUpdateRaw(requestParameters: V1RequestRequestsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsUpdate.');
        }

        if (requestParameters.aPIRequestRequest === null || requestParameters.aPIRequestRequest === undefined) {
            throw new runtime.RequiredError('aPIRequestRequest','Required parameter requestParameters.aPIRequestRequest was null or undefined when calling v1RequestRequestsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIRequestRequestToJSON(requestParameters.aPIRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsUpdate(requestParameters: V1RequestRequestsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
