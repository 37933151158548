/* -------------------------- Design imports start -------------------------- */
import { Box, Typography } from '@mui/material'
import Button from '../Button'
import styles from './MultilevelDrawer.module.css'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import LogTool from '../../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  title?: string // the title is displayed in the header when no customHeader is specified
  customHeader?: ReactNode
  size?: 'big' | 'small'
  onClose?: () => void
  confirmButtonProps?: {
    text: string
    onConfirm: () => Promise<void>
    disabled: boolean
    hidden?: boolean
  }
  customActions?: ReactNode
  children?: React.ReactNode
}

/* -------------------------------------------------------------------------- */
/*                               Component Start                              */
/* -------------------------------------------------------------------------- */

/**
 * An animated drawer component that is implemented to stack upon itself while keeping
 * the context of the component visible as much as possible.
 *
 * @returns
 */
export default function MultilevelDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open /* the open state keeps track of wether the drawer should be opened or not. It is used as a trigger to play the drawer animation and portal the component into or out of the drawerContainer! */,
    setOpen,
    title = 'Todo: Provide a drawer title or a custom header!',
    customHeader,
    size = 'small',
    onClose = () => null,
    confirmButtonProps = {
      text: 'Todo: Provide confirmButtonProps or custom actions!',
      onConfirm: () => null,
      disabled: false,
      hidden: false,
    },
    customActions,
    children,
  } = props

  const { t } = useTranslation()
  const log = new LogTool({ context: 'MultilevelDrawer', enable: true, logLevel: 'warn' })

  const platformContentRoot = document.getElementById('root')
  const drawerRoot = document.getElementById('drawerRoot')
  const drawerWidth = size === 'small' ? '33vw' : '66vw'

  const transitionNode = useRef(null)
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [prevOpen, setPrevOpen] = useState(false)
  const [enablePortal, setEnablePortal] =
    useState(
      false
    ) /* This state controls if the Multileveldrawer is portaled into the DOM or not! */
  const [showDrawerContent, setShowDrawerContent] =
    useState(true) /* This state controls the beginning of the drawer animation! */
  const [waitingOnAction, setWaiting] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */

  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* useEffect(() => {
    // when the drawer is mounted the 'in' prop of
    // CSSTransition has to be 'true' to play the animation -> set to true on every open
    setShowDrawerContent(true)
  }, [open]) */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = () => {
    // by setting the 'in' property of CSSTransition to false the 'Exit-Animation'
    // is played. Once the animation is finished the onExited callback unmounts
    // the MultilevelDrawer-Component
    setWaiting(false)
    setShowDrawerContent(false)

    // reseting the state of the drawer is done in the onExited callback of the CSSTransition component
  }

  const handleConfirm = async () => {
    // visual feedback: waiting on server response
    setWaiting(true)
    // posting request to server and waiting on response
    await confirmButtonProps.onConfirm()

    handleClose()
  }

  /* const handleCustomActionClick = (event: any) => {
    if(event.target.className.includes("onCloseTrigger")) {
      handleClose()
    }
  } */
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  if (open === true && prevOpen === false) {
    setPrevOpen(true)
    // when the drawer is opened...
    // ... it has to be portaled into the drawerContainer to be rendered on top of everything else
    setEnablePortal(true)

    // ... and the 'in' prop of CSSTransition has to be 'true' to play the animation -> set to true on every open
    setShowDrawerContent(true)
  } else if (open === false && prevOpen === true) {
    setPrevOpen(false)
    // when the drawer is closed trigger the exit animation.
    // The portal is disabled in the onExited-Callback of the CSSTransition component!
    setShowDrawerContent(false)
  }
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  if (drawerRoot === null || !enablePortal) return null
  return ReactDOM.createPortal(
    <div
      className={styles.drawerContainer}
      onClick={
        event =>
          event.stopPropagation() /* Prevent any onClick events from inside the drawer to propagate outside of the drawer (see: https://stackoverflow.com/questions/71368592/what-is-the-performance-difference-between-multiple-reactdom-render-and-one-reac) */
      }
    >
      <div className={styles.drawerBackdrop} onClick={handleClose}></div>{' '}
      {/* TODO: Test mobile compatibility */}
      <CSSTransition
        nodeRef={transitionNode}
        in={showDrawerContent}
        appear={true}
        timeout={250}
        classNames={{
          appear: styles.drawerAppear,
          appearActive: styles.drawerAppearActive,
          appearDone: styles.drawerAppearDone,
          enter: styles.drawerEnter,
          enterActive: styles.drawerEnterActive,
          enterDone: styles.drawerEnterDone,
          exit: styles.drawerExit,
          exitActive: styles.drawerExitActive,
          exitDone: styles.drawerExitDone,
        }}
        onEnter={() => {
          // check if this drawer is rendered on top of another drawer
          if (drawerRoot.childElementCount > 1) {
            // this drawer is rendered on top of another drawer
            // -> move the underlying drawer to the left on enter

            // get the second last drawer (hence the underlying drawer to this one)
            const underlyingDrawerContainer = drawerRoot.children[drawerRoot.childElementCount - 2]
            const underlyingDrawer = underlyingDrawerContainer.getElementsByClassName(
              styles.drawer
            )[0]
            if (drawerWidth === '33vw') {
              // if this drawer is a small drawer push the underlying drawer to the left only as much as needed
              underlyingDrawer.classList.add(styles.reduceDrawerIndent)
            } else {
              // if this drawer is big push the underlying drawer all the way to the left
              underlyingDrawer.classList.add(styles.removeDrawerIndent)
            }
          } else {
            // disable scrolling outside of the drawer when opening the first drawer
            platformContentRoot!.style.overflow = 'hidden'
          }
        }}
        onExit={() => {
          // check if this drawer is rendered on top of another drawer
          if (drawerRoot.childElementCount > 1) {
            // this drawer is rendered on top of another drawer
            // -> move the underlying drawer to the right on exit

            // get the second last drawer (hence the underlying drawer to this one)
            const underlyingDrawerContainer = drawerRoot.children[drawerRoot.childElementCount - 2]
            const underlyingDrawer = underlyingDrawerContainer.getElementsByClassName(
              styles.drawer
            )[0]

            // remove all indent modifying classes
            underlyingDrawer.classList.remove(styles.reduceDrawerIndent)
            underlyingDrawer.classList.remove(styles.removeDrawerIndent)
          } else {
            // enable scrolling outside of the drawer once the last drawer is closed
            platformContentRoot!.style.overflow = ''
          }
        }}
        onExited={() => {
          // reset drawer state
          setWaiting(false)
          setPrevOpen(false)
          setOpen(false)

          onClose()

          // unmount drawer
          setEnablePortal(false)
        }}
      >
        <div
          className={styles.drawer}
          ref={transitionNode}
          style={{ left: `calc(100vw - ${drawerWidth})` }}
        >
          <div className={styles.drawerHeader} style={{ flexGrow: 0, flexShrink: 0 }}>
            {customHeader ?? <Typography variant="h6">{title}</Typography>}
          </div>
          <div
            className={styles.drawerContentScrollContainer}
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <div
              className={styles.drawerContent}
              style={{
                /* this makes sure that the content is centered within the drawer while allowing the scrollbar to slide to the right */
                width: `calc(${drawerWidth} - 7.5vw)`,
              }}
            >
              {children}
            </div>
          </div>
          <div className={styles.drawerActions} style={{ flexGrow: 0, flexShrink: 0 }}>
            {customActions ? (
              <div>
                {' '}
                {/* onClick={handleCustomActionClick} */}
                {customActions}
              </div>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  id="cancelButton"
                  variant="contained"
                  color="inherit"
                  sx={{ textTransform: 'none' }}
                  disabled={waitingOnAction}
                  onClick={handleClose}
                >
                  {t('common:interaction.button.cancel')}
                </Button>
                {!confirmButtonProps.hidden && (
                  <LoadingButton
                    id="confirmButton"
                    variant="contained"
                    sx={{ color: 'whitesmoke', textTransform: 'none' }}
                    onClick={handleConfirm}
                    disabled={confirmButtonProps?.disabled}
                    loading={waitingOnAction}
                  >
                    {confirmButtonProps.text}
                  </LoadingButton>
                )}
              </Box>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>,
    drawerRoot
  )
}
