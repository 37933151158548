/* ------------------------ Functionsl imports start ------------------------ */
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/es/table/Column'
import { FormFieldType } from '../../Form'
import { IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { loadSupplierVersionsOptions } from './functions'
import { getAddressRepresentation } from '../../../utils/functions'

/* ------------------------- Functional imports end ------------------------- */

type Props = {
  supplier?: string
  name:
    | 'supplierDetailsTableColumns'
    | 'supplierFormFields'
    | 'supplierVersionsFormFields'
    | 'supplierExpandedTableColumns'
    | 'supplierTableColumns'
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function GetConstant(props: Props): FormFieldType[] | ColumnProps<any>[] {
  /* -------------------------- Non state data start -------------------------- */
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */

  if (props.name === 'supplierDetailsTableColumns') {
    return [
      {
        title: t('common:content.label.article'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('common:content.label.inStorage'),
        dataIndex: 'inStorage',
        key: 'inStorage',
      },
    ]
  }
  if (props.name === 'supplierTableColumns') {
    return [
      {
        title: t('common:content.label.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('common:content.label.vat'),
        dataIndex: 'ustId',
        key: 'vat',
      },
      {
        title: t('common:content.label.details'),
        key: '',
        render: () => (
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        ),
      },
    ]
  }
  if (props.name === 'supplierExpandedTableColumns') {
    return [
      {
        title: t('common:content.label.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: t('common:content.label.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: t('common:content.label.email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('common:content.label.phone'),
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: t('common:content.label.address'),
        dataIndex: 'addresses',
        key: 'addresses',
        width: '30%',
        render: (addresses: any[]) => (
          <ul>
            {addresses?.map((address: any, index: number) => (
              <li key={index}>
                {getAddressRepresentation(address)}
              </li>
            ))}
          </ul>
        ),
      },
      {
        title: t('common:content.label.language'),
        dataIndex: 'language',
        key: 'language',
      },
      {
        title: t('common:content.label.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
    ]
  }
  if (props.name === 'supplierFormFields') {
    return [
      {
        type: 'text',
        key: 'name',
        label: t('common:content.label.name'),
        required: true,
        validation: true,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      {
        type: 'text',
        key: 'ustId',
        label: t('content.label.vat'),
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ] as FormFieldType[]
  }

  return [] as FormFieldType[]
}
