/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedStoragePositionList,
    APIPaginatedStoragePositionListFromJSON,
    APIPaginatedStoragePositionListToJSON,
    APIPatchedStoragePositionRequest,
    APIPatchedStoragePositionRequestFromJSON,
    APIPatchedStoragePositionRequestToJSON,
    APIStoragePosition,
    APIStoragePositionFromJSON,
    APIStoragePositionToJSON,
    APIStoragePositionRequest,
    APIStoragePositionRequestFromJSON,
    APIStoragePositionRequestToJSON,
} from '../models';

export interface V1LogisticsStoragePositionsCreateRequest {
    aPIStoragePositionRequest: APIStoragePositionRequest;
}

export interface V1LogisticsStoragePositionsDestroyRequest {
    id: number;
}

export interface V1LogisticsStoragePositionsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1LogisticsStoragePositionsPartialUpdateRequest {
    id: number;
    aPIPatchedStoragePositionRequest?: APIPatchedStoragePositionRequest;
}

export interface V1LogisticsStoragePositionsRetrieveRequest {
    id: number;
}

export interface V1LogisticsStoragePositionsUpdateRequest {
    id: number;
    aPIStoragePositionRequest: APIStoragePositionRequest;
}

/**
 * 
 */
export class LogisticsstoragePositionsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsCreateRaw(requestParameters: V1LogisticsStoragePositionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.aPIStoragePositionRequest === null || requestParameters.aPIStoragePositionRequest === undefined) {
            throw new runtime.RequiredError('aPIStoragePositionRequest','Required parameter requestParameters.aPIStoragePositionRequest was null or undefined when calling v1LogisticsStoragePositionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStoragePositionRequestToJSON(requestParameters.aPIStoragePositionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsCreate(requestParameters: V1LogisticsStoragePositionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsDestroyRaw(requestParameters: V1LogisticsStoragePositionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsDestroy(requestParameters: V1LogisticsStoragePositionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1LogisticsStoragePositionsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsListRaw(requestParameters: V1LogisticsStoragePositionsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedStoragePositionList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedStoragePositionListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsList(requestParameters: V1LogisticsStoragePositionsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedStoragePositionList> {
        const response = await this.v1LogisticsStoragePositionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsPartialUpdateRaw(requestParameters: V1LogisticsStoragePositionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedStoragePositionRequestToJSON(requestParameters.aPIPatchedStoragePositionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsPartialUpdate(requestParameters: V1LogisticsStoragePositionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsRetrieveRaw(requestParameters: V1LogisticsStoragePositionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsRetrieve(requestParameters: V1LogisticsStoragePositionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsUpdateRaw(requestParameters: V1LogisticsStoragePositionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsUpdate.');
        }

        if (requestParameters.aPIStoragePositionRequest === null || requestParameters.aPIStoragePositionRequest === undefined) {
            throw new runtime.RequiredError('aPIStoragePositionRequest','Required parameter requestParameters.aPIStoragePositionRequest was null or undefined when calling v1LogisticsStoragePositionsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIStoragePositionRequestToJSON(requestParameters.aPIStoragePositionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsUpdate(requestParameters: V1LogisticsStoragePositionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
