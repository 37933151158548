/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedSupplyOrderList,
    APIPaginatedSupplyOrderListFromJSON,
    APIPaginatedSupplyOrderListToJSON,
    APIPatchedSupplyOrderRequest,
    APIPatchedSupplyOrderRequestFromJSON,
    APIPatchedSupplyOrderRequestToJSON,
    APISupplyOrder,
    APISupplyOrderFromJSON,
    APISupplyOrderToJSON,
    APISupplyOrderRequest,
    APISupplyOrderRequestFromJSON,
    APISupplyOrderRequestToJSON,
} from '../models';

export interface V1ItemsArticlesSupplyOrdersCreateRequest {
    articlePk: number;
    aPISupplyOrderRequest: APISupplyOrderRequest;
}

export interface V1ItemsArticlesSupplyOrdersDestroyRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesSupplyOrdersListRequest {
    articlePk: number;
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesSupplyOrdersPartialUpdateRequest {
    articlePk: number;
    id: number;
    aPIPatchedSupplyOrderRequest?: APIPatchedSupplyOrderRequest;
}

export interface V1ItemsArticlesSupplyOrdersRetrieveRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesSupplyOrdersUpdateRequest {
    articlePk: number;
    id: number;
    aPISupplyOrderRequest: APISupplyOrderRequest;
}

export interface V1SupplierSupplyOrdersCreateRequest {
    aPISupplyOrderRequest: APISupplyOrderRequest;
}

export interface V1SupplierSupplyOrdersDestroyRequest {
    id: number;
}

export interface V1SupplierSupplyOrdersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1SupplierSupplyOrdersPartialUpdateRequest {
    id: number;
    aPIPatchedSupplyOrderRequest?: APIPatchedSupplyOrderRequest;
}

export interface V1SupplierSupplyOrdersRetrieveRequest {
    id: number;
}

export interface V1SupplierSupplyOrdersUpdateRequest {
    id: number;
    aPISupplyOrderRequest: APISupplyOrderRequest;
}

/**
 * 
 */
export class SuppliersupplyOrdersApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersCreateRaw(requestParameters: V1ItemsArticlesSupplyOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesSupplyOrdersCreate.');
        }

        if (requestParameters.aPISupplyOrderRequest === null || requestParameters.aPISupplyOrderRequest === undefined) {
            throw new runtime.RequiredError('aPISupplyOrderRequest','Required parameter requestParameters.aPISupplyOrderRequest was null or undefined when calling v1ItemsArticlesSupplyOrdersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/supply_orders/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APISupplyOrderRequestToJSON(requestParameters.aPISupplyOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersCreate(requestParameters: V1ItemsArticlesSupplyOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1ItemsArticlesSupplyOrdersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersDestroyRaw(requestParameters: V1ItemsArticlesSupplyOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesSupplyOrdersDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesSupplyOrdersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/supply_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersDestroy(requestParameters: V1ItemsArticlesSupplyOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesSupplyOrdersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersListRaw(requestParameters: V1ItemsArticlesSupplyOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedSupplyOrderList>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesSupplyOrdersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/supply_orders/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedSupplyOrderListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersList(requestParameters: V1ItemsArticlesSupplyOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedSupplyOrderList> {
        const response = await this.v1ItemsArticlesSupplyOrdersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersPartialUpdateRaw(requestParameters: V1ItemsArticlesSupplyOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesSupplyOrdersPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesSupplyOrdersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/supply_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedSupplyOrderRequestToJSON(requestParameters.aPIPatchedSupplyOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersPartialUpdate(requestParameters: V1ItemsArticlesSupplyOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1ItemsArticlesSupplyOrdersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersRetrieveRaw(requestParameters: V1ItemsArticlesSupplyOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesSupplyOrdersRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesSupplyOrdersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/supply_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersRetrieve(requestParameters: V1ItemsArticlesSupplyOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1ItemsArticlesSupplyOrdersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersUpdateRaw(requestParameters: V1ItemsArticlesSupplyOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesSupplyOrdersUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesSupplyOrdersUpdate.');
        }

        if (requestParameters.aPISupplyOrderRequest === null || requestParameters.aPISupplyOrderRequest === undefined) {
            throw new runtime.RequiredError('aPISupplyOrderRequest','Required parameter requestParameters.aPISupplyOrderRequest was null or undefined when calling v1ItemsArticlesSupplyOrdersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/supply_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APISupplyOrderRequestToJSON(requestParameters.aPISupplyOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesSupplyOrdersUpdate(requestParameters: V1ItemsArticlesSupplyOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1ItemsArticlesSupplyOrdersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersCreateRaw(requestParameters: V1SupplierSupplyOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.aPISupplyOrderRequest === null || requestParameters.aPISupplyOrderRequest === undefined) {
            throw new runtime.RequiredError('aPISupplyOrderRequest','Required parameter requestParameters.aPISupplyOrderRequest was null or undefined when calling v1SupplierSupplyOrdersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/supply_orders/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APISupplyOrderRequestToJSON(requestParameters.aPISupplyOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersCreate(requestParameters: V1SupplierSupplyOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1SupplierSupplyOrdersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersDestroyRaw(requestParameters: V1SupplierSupplyOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSupplyOrdersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/supply_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersDestroy(requestParameters: V1SupplierSupplyOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1SupplierSupplyOrdersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersListRaw(requestParameters: V1SupplierSupplyOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedSupplyOrderList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/supply_orders/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedSupplyOrderListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersList(requestParameters: V1SupplierSupplyOrdersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedSupplyOrderList> {
        const response = await this.v1SupplierSupplyOrdersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersPartialUpdateRaw(requestParameters: V1SupplierSupplyOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSupplyOrdersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/supply_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedSupplyOrderRequestToJSON(requestParameters.aPIPatchedSupplyOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersPartialUpdate(requestParameters: V1SupplierSupplyOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1SupplierSupplyOrdersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersRetrieveRaw(requestParameters: V1SupplierSupplyOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSupplyOrdersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/supply_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersRetrieve(requestParameters: V1SupplierSupplyOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1SupplierSupplyOrdersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersUpdateRaw(requestParameters: V1SupplierSupplyOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplyOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSupplyOrdersUpdate.');
        }

        if (requestParameters.aPISupplyOrderRequest === null || requestParameters.aPISupplyOrderRequest === undefined) {
            throw new runtime.RequiredError('aPISupplyOrderRequest','Required parameter requestParameters.aPISupplyOrderRequest was null or undefined when calling v1SupplierSupplyOrdersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/supply_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APISupplyOrderRequestToJSON(requestParameters.aPISupplyOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplyOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSupplyOrdersUpdate(requestParameters: V1SupplierSupplyOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplyOrder> {
        const response = await this.v1SupplierSupplyOrdersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
