/* ------------------------ Functional imports start ------------------------ */
import { Customer } from "../features/Customer/utils/types";
import { Article, Sharepoint } from "../features/Item/utils/types";
import { Supplier } from "../features/Supplier/utils/types";
import { APIAddress, APIAllInOneUser, APIArticleFile, APIContact, APIContractFile, APIGuestRequestFile, APIGuestUser, APIOrgUser, APIProductionOrder, APIRequestFile, APIRichContact, APIRole } from "../generated-types";
/* ------------------------- Functional imports end ------------------------- */

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export interface SelectOption {
  value: string;
  label?: string | undefined;
  disabled?: boolean | undefined;
}

/**
 * Keyword arguments for API fetch functions.
 * onSuccess: Callback function that is executed every time a response is received.
 * onFinal: Callback function that is executed *after* calling onSuccess for the last time.
 * onError: Callback function that is executed every time an error is received.
 * parameter: URL parameter, e.g. 'expand=supplier,article', 'exclude=id'
 * url: The URL that specifies the API endpoint to request.
 */
export interface FetchKwargs<T> {
  // TODO: remove ? from count
  onSuccess: (response: T[], count?: number) => void,
  onFinal?: () => void,
  onError?: (error: any) => void,
  parameter?: string[],
  url?: string
}

export interface FetchKwargsSingle<T> {
  onSuccess: (response: T) => void,
  onError?: (error: any) => void,
  parameter?: string[],
  url?: string
}

export interface WebsocketMessage {
  type: string,
  data: {[key: string]: any}
}

export interface Address extends APIAddress {
  key: string
}
export interface Contact extends Omit<APIContact, 'addresses'>{
  key: string
  addresses: Address[] | string
}
export interface RichContact extends Omit<APIRichContact, 'addresses' | 'suppliers' | 'customers'>{
  key: string
  addresses: Address[] | string
  suppliers: Supplier []
  customers: Customer []
}

// export interface User extends Omit<APIUser, 'roles'> {
//   key: string
//   isOrgAdmin: boolean
//   roles: Role[] | string
// }
export interface User extends Omit<APIAllInOneUser, 'roles' | 'repFor'> {
  key: string
  roles: Role[] | string
  // objectPermissions: {[key: string]: any}
  // sharepoint?: Sharepoint | string
  repFor?: Contact | string
}

export interface OrganizationUser extends Omit<APIOrgUser, 'roles'> {
  isGuestUser: false
  key: string
  roles: Role[] | string
  // objectPermissions: {[key: string]: any}
}

export interface GuestUser extends Omit<APIGuestUser, 'repFor' | 'roles'> {
  isGuestUser: true
  key: string
  roles: Role[] | string
  // objectPermissions: {[key: string]: any}
  // sharepoint?: Sharepoint | string
  repFor?: Contact | string
}

export interface Role extends APIRole {
  key: string
}

export interface ProductionOrder extends APIProductionOrder {
  key: string

}

export type FileUploadStatus = "loading" | "success" | "uploadError" | "deleteError"

/**
 * A file interface that supports the typescript file interface and all file interfaces that are defined by our backed.
 */
export interface FileInfo extends
  Partial<File>,
  Partial<Omit<APIArticleFile, "size">>,
  Partial<Omit<APIContractFile, "size">>,
  Partial<Omit<APIRequestFile, "size">>,
  Partial<Omit<APIGuestRequestFile, "size">>
{
  uploadStatus?: FileUploadStatus
  // TODO: the following property can probably be removed
  uploadUrl?: string // e.g. items/articles/6/files/
}