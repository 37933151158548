/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface V1GeneralPermissionsRetrieve2Request {
    id: string;
}

export interface V1GeneralUnitsRetrieve2Request {
    id: string;
}

/**
 * 
 */
export class GeneralpermissionsApi extends runtime.BaseAPI {

    /**
     */
    async v1GeneralPermissionsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/permissions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralPermissionsRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralPermissionsRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1GeneralPermissionsRetrieve2Raw(requestParameters: V1GeneralPermissionsRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GeneralPermissionsRetrieve2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/permissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralPermissionsRetrieve2(requestParameters: V1GeneralPermissionsRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralPermissionsRetrieve2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1GeneralUnitsRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/units/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralUnitsRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralUnitsRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1GeneralUnitsRetrieve2Raw(requestParameters: V1GeneralUnitsRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GeneralUnitsRetrieve2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/units/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralUnitsRetrieve2(requestParameters: V1GeneralUnitsRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralUnitsRetrieve2Raw(requestParameters, initOverrides);
    }

}
