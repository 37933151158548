/* -------------------------- Design imports start -------------------------- */
import { Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { ColorPicker, ColorValue } from 'mui-color'
import React from 'react'
import { useTranslation } from 'react-i18next'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: ColorValue
  disabled?: boolean
  labelWidth?: number
  onChange: (value: ColorValue) => void
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ColorInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { value, disabled, labelWidth, onChange } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation()
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <Typography>{t('item:content.label.selectColor')}</Typography>
      <ColorPicker value={value} onChange={(color: ColorValue) => onChange(color)} />
    </div>
  )
}
