/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedProductionFeedbackList,
    APIPaginatedProductionFeedbackListFromJSON,
    APIPaginatedProductionFeedbackListToJSON,
    APIPatchedProductionFeedbackRequest,
    APIPatchedProductionFeedbackRequestFromJSON,
    APIPatchedProductionFeedbackRequestToJSON,
    APIProductionFeedback,
    APIProductionFeedbackFromJSON,
    APIProductionFeedbackToJSON,
    APIProductionFeedbackRequest,
    APIProductionFeedbackRequestFromJSON,
    APIProductionFeedbackRequestToJSON,
} from '../models';

export interface V1CustomerProductionFeedbackCreateRequest {
    aPIProductionFeedbackRequest: APIProductionFeedbackRequest;
}

export interface V1CustomerProductionFeedbackDestroyRequest {
    id: number;
}

export interface V1CustomerProductionFeedbackListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1CustomerProductionFeedbackPartialUpdateRequest {
    id: number;
    aPIPatchedProductionFeedbackRequest?: APIPatchedProductionFeedbackRequest;
}

export interface V1CustomerProductionFeedbackRetrieveRequest {
    id: number;
}

export interface V1CustomerProductionFeedbackUpdateRequest {
    id: number;
    aPIProductionFeedbackRequest: APIProductionFeedbackRequest;
}

export interface V1CustomerProductionOrdersFeedbackCreateRequest {
    productionOrderPk: number;
    aPIProductionFeedbackRequest: APIProductionFeedbackRequest;
}

export interface V1CustomerProductionOrdersFeedbackDestroyRequest {
    id: number;
    productionOrderPk: number;
}

export interface V1CustomerProductionOrdersFeedbackListRequest {
    productionOrderPk: number;
    page?: number;
    pageSize?: number;
}

export interface V1CustomerProductionOrdersFeedbackPartialUpdateRequest {
    id: number;
    productionOrderPk: number;
    aPIPatchedProductionFeedbackRequest?: APIPatchedProductionFeedbackRequest;
}

export interface V1CustomerProductionOrdersFeedbackRetrieveRequest {
    id: number;
    productionOrderPk: number;
}

export interface V1CustomerProductionOrdersFeedbackUpdateRequest {
    id: number;
    productionOrderPk: number;
    aPIProductionFeedbackRequest: APIProductionFeedbackRequest;
}

/**
 * 
 */
export class CustomerproductionOrdersfeedbackApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackCreateRaw(requestParameters: V1CustomerProductionFeedbackCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.aPIProductionFeedbackRequest === null || requestParameters.aPIProductionFeedbackRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionFeedbackRequest','Required parameter requestParameters.aPIProductionFeedbackRequest was null or undefined when calling v1CustomerProductionFeedbackCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_feedback/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionFeedbackRequestToJSON(requestParameters.aPIProductionFeedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackCreate(requestParameters: V1CustomerProductionFeedbackCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionFeedbackCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackDestroyRaw(requestParameters: V1CustomerProductionFeedbackDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionFeedbackDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackDestroy(requestParameters: V1CustomerProductionFeedbackDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1CustomerProductionFeedbackDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackListRaw(requestParameters: V1CustomerProductionFeedbackListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedProductionFeedbackList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_feedback/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedProductionFeedbackListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackList(requestParameters: V1CustomerProductionFeedbackListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedProductionFeedbackList> {
        const response = await this.v1CustomerProductionFeedbackListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackPartialUpdateRaw(requestParameters: V1CustomerProductionFeedbackPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionFeedbackPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedProductionFeedbackRequestToJSON(requestParameters.aPIPatchedProductionFeedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackPartialUpdate(requestParameters: V1CustomerProductionFeedbackPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionFeedbackPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackRetrieveRaw(requestParameters: V1CustomerProductionFeedbackRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionFeedbackRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackRetrieve(requestParameters: V1CustomerProductionFeedbackRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionFeedbackRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackUpdateRaw(requestParameters: V1CustomerProductionFeedbackUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionFeedbackUpdate.');
        }

        if (requestParameters.aPIProductionFeedbackRequest === null || requestParameters.aPIProductionFeedbackRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionFeedbackRequest','Required parameter requestParameters.aPIProductionFeedbackRequest was null or undefined when calling v1CustomerProductionFeedbackUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionFeedbackRequestToJSON(requestParameters.aPIProductionFeedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionFeedbackUpdate(requestParameters: V1CustomerProductionFeedbackUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionFeedbackUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackCreateRaw(requestParameters: V1CustomerProductionOrdersFeedbackCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.productionOrderPk === null || requestParameters.productionOrderPk === undefined) {
            throw new runtime.RequiredError('productionOrderPk','Required parameter requestParameters.productionOrderPk was null or undefined when calling v1CustomerProductionOrdersFeedbackCreate.');
        }

        if (requestParameters.aPIProductionFeedbackRequest === null || requestParameters.aPIProductionFeedbackRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionFeedbackRequest','Required parameter requestParameters.aPIProductionFeedbackRequest was null or undefined when calling v1CustomerProductionOrdersFeedbackCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{production_order_pk}/feedback/`.replace(`{${"production_order_pk"}}`, encodeURIComponent(String(requestParameters.productionOrderPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionFeedbackRequestToJSON(requestParameters.aPIProductionFeedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackCreate(requestParameters: V1CustomerProductionOrdersFeedbackCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionOrdersFeedbackCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackDestroyRaw(requestParameters: V1CustomerProductionOrdersFeedbackDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersFeedbackDestroy.');
        }

        if (requestParameters.productionOrderPk === null || requestParameters.productionOrderPk === undefined) {
            throw new runtime.RequiredError('productionOrderPk','Required parameter requestParameters.productionOrderPk was null or undefined when calling v1CustomerProductionOrdersFeedbackDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{production_order_pk}/feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"production_order_pk"}}`, encodeURIComponent(String(requestParameters.productionOrderPk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackDestroy(requestParameters: V1CustomerProductionOrdersFeedbackDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1CustomerProductionOrdersFeedbackDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackListRaw(requestParameters: V1CustomerProductionOrdersFeedbackListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedProductionFeedbackList>> {
        if (requestParameters.productionOrderPk === null || requestParameters.productionOrderPk === undefined) {
            throw new runtime.RequiredError('productionOrderPk','Required parameter requestParameters.productionOrderPk was null or undefined when calling v1CustomerProductionOrdersFeedbackList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{production_order_pk}/feedback/`.replace(`{${"production_order_pk"}}`, encodeURIComponent(String(requestParameters.productionOrderPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedProductionFeedbackListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackList(requestParameters: V1CustomerProductionOrdersFeedbackListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedProductionFeedbackList> {
        const response = await this.v1CustomerProductionOrdersFeedbackListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackPartialUpdateRaw(requestParameters: V1CustomerProductionOrdersFeedbackPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersFeedbackPartialUpdate.');
        }

        if (requestParameters.productionOrderPk === null || requestParameters.productionOrderPk === undefined) {
            throw new runtime.RequiredError('productionOrderPk','Required parameter requestParameters.productionOrderPk was null or undefined when calling v1CustomerProductionOrdersFeedbackPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{production_order_pk}/feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"production_order_pk"}}`, encodeURIComponent(String(requestParameters.productionOrderPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedProductionFeedbackRequestToJSON(requestParameters.aPIPatchedProductionFeedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackPartialUpdate(requestParameters: V1CustomerProductionOrdersFeedbackPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionOrdersFeedbackPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackRetrieveRaw(requestParameters: V1CustomerProductionOrdersFeedbackRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersFeedbackRetrieve.');
        }

        if (requestParameters.productionOrderPk === null || requestParameters.productionOrderPk === undefined) {
            throw new runtime.RequiredError('productionOrderPk','Required parameter requestParameters.productionOrderPk was null or undefined when calling v1CustomerProductionOrdersFeedbackRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{production_order_pk}/feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"production_order_pk"}}`, encodeURIComponent(String(requestParameters.productionOrderPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackRetrieve(requestParameters: V1CustomerProductionOrdersFeedbackRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionOrdersFeedbackRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackUpdateRaw(requestParameters: V1CustomerProductionOrdersFeedbackUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProductionFeedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerProductionOrdersFeedbackUpdate.');
        }

        if (requestParameters.productionOrderPk === null || requestParameters.productionOrderPk === undefined) {
            throw new runtime.RequiredError('productionOrderPk','Required parameter requestParameters.productionOrderPk was null or undefined when calling v1CustomerProductionOrdersFeedbackUpdate.');
        }

        if (requestParameters.aPIProductionFeedbackRequest === null || requestParameters.aPIProductionFeedbackRequest === undefined) {
            throw new runtime.RequiredError('aPIProductionFeedbackRequest','Required parameter requestParameters.aPIProductionFeedbackRequest was null or undefined when calling v1CustomerProductionOrdersFeedbackUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/production_orders/{production_order_pk}/feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"production_order_pk"}}`, encodeURIComponent(String(requestParameters.productionOrderPk))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIProductionFeedbackRequestToJSON(requestParameters.aPIProductionFeedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIProductionFeedbackFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerProductionOrdersFeedbackUpdate(requestParameters: V1CustomerProductionOrdersFeedbackUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProductionFeedback> {
        const response = await this.v1CustomerProductionOrdersFeedbackUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
