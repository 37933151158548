/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedRoleList,
    APIPaginatedRoleListFromJSON,
    APIPaginatedRoleListToJSON,
    APIRole,
    APIRoleFromJSON,
    APIRoleToJSON,
} from '../models';

export interface V1AccountsRolesSubRolesListRequest {
    rolePk: string;
    page?: number;
    pageSize?: number;
}

export interface V1AccountsRolesSubRolesRetrieveRequest {
    id: number;
    rolePk: string;
}

/**
 * 
 */
export class AccountssubRolesApi extends runtime.BaseAPI {

    /**
     */
    async v1AccountsRolesSubRolesListRaw(requestParameters: V1AccountsRolesSubRolesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedRoleList>> {
        if (requestParameters.rolePk === null || requestParameters.rolePk === undefined) {
            throw new runtime.RequiredError('rolePk','Required parameter requestParameters.rolePk was null or undefined when calling v1AccountsRolesSubRolesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/{role_pk}/sub_roles/`.replace(`{${"role_pk"}}`, encodeURIComponent(String(requestParameters.rolePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedRoleListFromJSON(jsonValue));
    }

    /**
     */
    async v1AccountsRolesSubRolesList(requestParameters: V1AccountsRolesSubRolesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedRoleList> {
        const response = await this.v1AccountsRolesSubRolesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1AccountsRolesSubRolesRetrieveRaw(requestParameters: V1AccountsRolesSubRolesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsRolesSubRolesRetrieve.');
        }

        if (requestParameters.rolePk === null || requestParameters.rolePk === undefined) {
            throw new runtime.RequiredError('rolePk','Required parameter requestParameters.rolePk was null or undefined when calling v1AccountsRolesSubRolesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/{role_pk}/sub_roles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"role_pk"}}`, encodeURIComponent(String(requestParameters.rolePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRoleFromJSON(jsonValue));
    }

    /**
     */
    async v1AccountsRolesSubRolesRetrieve(requestParameters: V1AccountsRolesSubRolesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRole> {
        const response = await this.v1AccountsRolesSubRolesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
