/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APICustomerOrder,
    APICustomerOrderFromJSON,
    APICustomerOrderToJSON,
    APICustomerOrderRequest,
    APICustomerOrderRequestFromJSON,
    APICustomerOrderRequestToJSON,
    APIPaginatedCustomerOrderList,
    APIPaginatedCustomerOrderListFromJSON,
    APIPaginatedCustomerOrderListToJSON,
    APIPatchedCustomerOrderRequest,
    APIPatchedCustomerOrderRequestFromJSON,
    APIPatchedCustomerOrderRequestToJSON,
} from '../models';

export interface V1CustomerCustomerOrdersCreateRequest {
    aPICustomerOrderRequest: APICustomerOrderRequest;
}

export interface V1CustomerCustomerOrdersDestroyRequest {
    id: number;
}

export interface V1CustomerCustomerOrdersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1CustomerCustomerOrdersPartialUpdateRequest {
    id: number;
    aPIPatchedCustomerOrderRequest?: APIPatchedCustomerOrderRequest;
}

export interface V1CustomerCustomerOrdersRetrieveRequest {
    id: number;
}

export interface V1CustomerCustomerOrdersUpdateRequest {
    id: number;
    aPICustomerOrderRequest: APICustomerOrderRequest;
}

export interface V1ItemsArticlesCustomerOrdersCreateRequest {
    articlePk: number;
    aPICustomerOrderRequest: APICustomerOrderRequest;
}

export interface V1ItemsArticlesCustomerOrdersDestroyRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesCustomerOrdersListRequest {
    articlePk: number;
    page?: number;
    pageSize?: number;
}

export interface V1ItemsArticlesCustomerOrdersPartialUpdateRequest {
    articlePk: number;
    id: number;
    aPIPatchedCustomerOrderRequest?: APIPatchedCustomerOrderRequest;
}

export interface V1ItemsArticlesCustomerOrdersRetrieveRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesCustomerOrdersUpdateRequest {
    articlePk: number;
    id: number;
    aPICustomerOrderRequest: APICustomerOrderRequest;
}

/**
 * 
 */
export class CustomercustomerOrdersApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersCreateRaw(requestParameters: V1CustomerCustomerOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.aPICustomerOrderRequest === null || requestParameters.aPICustomerOrderRequest === undefined) {
            throw new runtime.RequiredError('aPICustomerOrderRequest','Required parameter requestParameters.aPICustomerOrderRequest was null or undefined when calling v1CustomerCustomerOrdersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customer_orders/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICustomerOrderRequestToJSON(requestParameters.aPICustomerOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersCreate(requestParameters: V1CustomerCustomerOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1CustomerCustomerOrdersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersDestroyRaw(requestParameters: V1CustomerCustomerOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomerOrdersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customer_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersDestroy(requestParameters: V1CustomerCustomerOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1CustomerCustomerOrdersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersListRaw(requestParameters: V1CustomerCustomerOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedCustomerOrderList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customer_orders/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedCustomerOrderListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersList(requestParameters: V1CustomerCustomerOrdersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedCustomerOrderList> {
        const response = await this.v1CustomerCustomerOrdersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersPartialUpdateRaw(requestParameters: V1CustomerCustomerOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomerOrdersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customer_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCustomerOrderRequestToJSON(requestParameters.aPIPatchedCustomerOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersPartialUpdate(requestParameters: V1CustomerCustomerOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1CustomerCustomerOrdersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersRetrieveRaw(requestParameters: V1CustomerCustomerOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomerOrdersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customer_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersRetrieve(requestParameters: V1CustomerCustomerOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1CustomerCustomerOrdersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersUpdateRaw(requestParameters: V1CustomerCustomerOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomerOrdersUpdate.');
        }

        if (requestParameters.aPICustomerOrderRequest === null || requestParameters.aPICustomerOrderRequest === undefined) {
            throw new runtime.RequiredError('aPICustomerOrderRequest','Required parameter requestParameters.aPICustomerOrderRequest was null or undefined when calling v1CustomerCustomerOrdersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customer_orders/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APICustomerOrderRequestToJSON(requestParameters.aPICustomerOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomerOrdersUpdate(requestParameters: V1CustomerCustomerOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1CustomerCustomerOrdersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersCreateRaw(requestParameters: V1ItemsArticlesCustomerOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCustomerOrdersCreate.');
        }

        if (requestParameters.aPICustomerOrderRequest === null || requestParameters.aPICustomerOrderRequest === undefined) {
            throw new runtime.RequiredError('aPICustomerOrderRequest','Required parameter requestParameters.aPICustomerOrderRequest was null or undefined when calling v1ItemsArticlesCustomerOrdersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/customer_orders/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICustomerOrderRequestToJSON(requestParameters.aPICustomerOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersCreate(requestParameters: V1ItemsArticlesCustomerOrdersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1ItemsArticlesCustomerOrdersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersDestroyRaw(requestParameters: V1ItemsArticlesCustomerOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCustomerOrdersDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCustomerOrdersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/customer_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersDestroy(requestParameters: V1ItemsArticlesCustomerOrdersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsArticlesCustomerOrdersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersListRaw(requestParameters: V1ItemsArticlesCustomerOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedCustomerOrderList>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCustomerOrdersList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/customer_orders/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedCustomerOrderListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersList(requestParameters: V1ItemsArticlesCustomerOrdersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedCustomerOrderList> {
        const response = await this.v1ItemsArticlesCustomerOrdersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersPartialUpdateRaw(requestParameters: V1ItemsArticlesCustomerOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCustomerOrdersPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCustomerOrdersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/customer_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCustomerOrderRequestToJSON(requestParameters.aPIPatchedCustomerOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersPartialUpdate(requestParameters: V1ItemsArticlesCustomerOrdersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1ItemsArticlesCustomerOrdersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersRetrieveRaw(requestParameters: V1ItemsArticlesCustomerOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCustomerOrdersRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCustomerOrdersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/customer_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersRetrieve(requestParameters: V1ItemsArticlesCustomerOrdersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1ItemsArticlesCustomerOrdersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersUpdateRaw(requestParameters: V1ItemsArticlesCustomerOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomerOrder>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCustomerOrdersUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCustomerOrdersUpdate.');
        }

        if (requestParameters.aPICustomerOrderRequest === null || requestParameters.aPICustomerOrderRequest === undefined) {
            throw new runtime.RequiredError('aPICustomerOrderRequest','Required parameter requestParameters.aPICustomerOrderRequest was null or undefined when calling v1ItemsArticlesCustomerOrdersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/customer_orders/{id}/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APICustomerOrderRequestToJSON(requestParameters.aPICustomerOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerOrderFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCustomerOrdersUpdate(requestParameters: V1ItemsArticlesCustomerOrdersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomerOrder> {
        const response = await this.v1ItemsArticlesCustomerOrdersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
