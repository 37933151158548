/* -------------------------- Design imports start -------------------------- */
import LinearProgressWithLabel from '../widgets/LinearProgressWithLabel'
import { useDropzone } from 'react-dropzone'
import { Card, CardContent, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import LogTool from '../../logger/logTools'
import { text } from 'stream/consumers'
/* ------------------------- Functional imports end ------------------------- */

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  height: '148px',
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#16a9e4',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

type Props = {
  uploadPath?: string
  fetchFiles?: (path: string) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function S3FileUpload(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  // hook for upload that uses drag and drop
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({ onDrop: acceptedFiles => uploadFiles(acceptedFiles) })
  const log = new LogTool({ context: 'S3FileUpload', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation()
  const [progress, setProgress] = useState<number>(0)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Handles uploading one or multiple files
   * @params files list of files to upload
   */
  function uploadFiles(files: any[]) {
    setLoading(true)
    const results: any[] = []
    files.forEach((file, index) => {
      results.push(handleUpload(file, index))
    })
    Promise.all(results)
      .then(data => {
        toast.success(t('common:feedback.success.uploadSuccess'))
        props.fetchFiles && props.fetchFiles(props.uploadPath || '')
      })
      .catch(error => {
        toast.error(t('common:feedback.error.uploadError'))
        log.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  /**
   * Handles uploading single file by file path and sets progress of upload
   * @params file fileobject with key
   */
  async function handleUpload(file: any, index: number) {
    const token = Cookies.get('access')
    if (!token) {
      log.warn('no token')
      return
    }
    let headersList = {
      Accept: '*/*',
      'Content-Disposition': "form-data; name='file';",
      Authorization: 'Bearer ' + token,
    }
    let bodyContent = new FormData()
    bodyContent.append('file', props.uploadPath + '/' + file.name)
    bodyContent.append('file', file)
    let response = await fetch(process.env.REACT_APP_API_URL + 'general/files/', {
      method: 'POST',
      body: bodyContent,
      headers: headersList,
    })

    let data = await response.json()
    return data
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography>{t('files:content.label.uploadFiles')}</Typography>
      </CardContent>
        <div className="container" style={{ margin: '5px' }}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Typography sx={{color:'#666565', fontWeight:'bold'}}>{t('common:content.label.dragAndDrop')}</Typography>
          </div>
          {loading && <LinearProgressWithLabel value={progress} />}
          {/* <FileList type={'DOCUMENT'} fileKeys={fileKeys} filePath={filePath as string} /> */}
        </div>
    </Card>
  )
}
