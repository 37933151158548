/* -------------------------- Design imports start -------------------------- */
import { Box, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import Page from "../../components/layout/Page";
import logo from '../../assets/logo_black.svg'
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../utils/context";
import { useHistory, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "../../components/Button";
/* ------------------------- Functional imports end ------------------------- */




/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function GuestSignInPage() {
  /* -------------------------- Non state data start -------------------------- */
  const { t } = useTranslation()
  const { setAccessToken } = useUserContext()
  const { token } = useParams<{token: string}>()
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [signInError, setSignInError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  // const [guestId, setGuestId] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const history = useHistory()
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  // some useEffects...
  // !! Note!!: you should try to avoid setting state in a useEffect as this renders
  // the component twice. For more info read: https://react.dev/learn/you-might-not-need-an-effect
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  // some API functions like fetch, create, ...
  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  const handleSignIn = async (e: any) => {
    e.preventDefault()
    if (token && password) {
      setLoading(true)
      const response = await fetch(process.env.REACT_APP_API_URL + 'auth/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
        },
        body: JSON.stringify({ "guest_id": token, "password":password }),
      })
      const data = await response.json()
      if (data.access && data.refresh) {
        // TODO: set cookies in production with secure, sameSite and httpOnly
        Cookies.set(
          'access',
          data.access /*{
          httpOnly: true,
          secure: true,
          sameSite: 'strict',
        }*/
        )
        Cookies.set('refresh', data.refresh)
        setAccessToken(data.access)
        setLoading(false)
      } else {
        setSignInError(true)
        setLoading(false)
      }
    }
  }

  /**
   * Send login request on pressing enter when the password textfield is the active element.
   * @param e
   */
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSignIn(e)
    }
  }


  const handleClickShowPassword = () => setShowPassword(show => !show)


  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  // datastructures that are used for rendering and don't need to be state
  // e.g. formFields of the Form component, columns of the antd table
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */
  // get guestId from url
  // if(!guestId) {
  //   const urlParams = new URLSearchParams(window.location.search)
  //   const token = urlParams.get("token")
  //   setGuestId(token ?? "invalid token")
  // }


  /* ------------------------- Pre render actions end ------------------------- */



  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  if (loading) return <CircularProgress />

  if (!loading && Cookies.get('access')) {
    history.push('/items')
  }

  return (
    <Page title=''>
      <Box width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
         <img
          src={logo}
          alt='assemblean Logo'
          style={{
            width: '250px',
            height: 'auto',
            marginBottom: '50px'
          }}
        />
        <Grid
          container
          spacing={2}
          width='60%'
        >
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {t('content.auth.signIn')}
            </Typography>
            <Typography variant="body2" align="center">
              {t('content.auth.guestSignInHint')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password">
                {t('common:content.auth.password')}
              </InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      id="togglePasswordVisibilityButton"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleKeyDown}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t('common:content.auth.password')}
              />
              <FormHelperText sx={{ml:'-1px'}} id="password-helper-text">
              {t('common:content.auth.passwordHelperTextGuest')}
              </FormHelperText>
            </FormControl>
          </Grid>
          {signInError && (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                {t('content.auth.signInError')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{ color: 'whitesmoke', textTransform: 'none' }}
              fullWidth
              onClick={handleSignIn}
              onKeyDown={handleKeyDown}
            >
              {t('content.auth.signIn')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}