/* -------------------------- Design imports start -------------------------- */
import { toast } from "react-toastify"
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { createContext, useContext, useEffect, useRef, useState } from "react"
import LogTool from "../logger/logTools"
import Cookies from "js-cookie"
import { handleAPICallV1 } from "./functions"
import { HTTPMethod, WebsocketMessage } from "./types"
import { useUserContext } from "./context"
/* ------------------------- Functional imports end ------------------------- */

interface IWebsocketContext {
  websocketMessage: any,
  websocketIsReady: boolean,
  websocketSend: (data: string | ArrayBufferLike | Blob | ArrayBufferView) => void,
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function WebsocketProvider(props: {children: React.ReactNode}) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({context: 'WebsocketProvider', enable: true, logLevel: 'debug'})
  const { user, accessToken } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */


  /* ---------------------------- Flag states start --------------------------- */
  const [userIsAuthenticated, setAuthenticated] = useState(Boolean(Cookies.get('access')))
  const [isReady, setIsReady] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */


  /* ---------------------------- Data states start --------------------------- */
  const ws = useRef<WebSocket | undefined>(undefined)
  const [message, setMessage] = useState<WebsocketMessage | undefined>(undefined)
  /* ----------------------------- Data states end ---------------------------- */


  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    // try to connect the websocket the first time we know about an authenticated user
    if(user && accessToken && !isReady) {
      const socket = connectWebsocket()
      return () => {
        socket.close()
      }
    }
  }, [user])
  /* ------------------------------- Effects end ------------------------------ */


  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Create a new WebSocket instance that is configured to load new messages into
   * the message state and reconnect automatically if the connection is interrupted.
   * @param websocketUrl
   * @returns WebSocket
   */
  function connectWebsocket() {
    // generate a new websocket url every time a new socket is created to ensure that we always use the newest access token
    const wsUrl: string = process.env.REACT_APP_WS_URL + `authenticated_socket/?token=${Cookies.get('access')}`
    const socket = new WebSocket(wsUrl)

    socket.onopen = () => {
      log.info('Websocket connected. Ready to receive and send messages!')
      setIsReady(true)
    }
    socket.onclose = (ev: CloseEvent) => {
      if(ev.wasClean) {
        log.info('Websocket connection closed by the server. Code ->', ev.code)
      } else {
        log.warn('Websocket disconnected. Unable to receive or send messages until reconnected!')

        // try to reconnect after a few seconds
        // making sure the access token is valid when we are trying to reconnect
        authenticateUser()
        setTimeout(() => {
          log.info('Trying to reconnect websocket')
          connectWebsocket()
        }, 5*1000)
      }
      setIsReady(false)
    }
    socket.onmessage = (ev: MessageEvent<any>) => {
      const messageObj = JSON.parse(ev.data)
      log.info('Received message ->', messageObj)
      setMessage(messageObj)
    }
    ws.current = socket
    return socket
  }

  /**
   * Authenticated the user / refresh the access token by fetching accounts/users/self/.
   */
  async function authenticateUser() {
    log.info('Begin fetching user/self to make sure that the user is authenticated')
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'accounts/users/self/'
    )

    if(!error && response) {
      log.info('Success authenticating user')
      setAuthenticated(true)
    } else {
      log.error('Error authenticating user', error)

      // if the authentication failed, the websocket will not be connected.
      // Try again to authenticate the user to enable websocket connection!
      // setTimeout(() => {
      //   log.info('Trying to authenticate user one more time')
      //   authenticateUser()
      // }, 10 * 1000)
    }
  }
  /* -------------------------- Utility functions end ------------------------- */


  /* ------------------------- Render constants start ------------------------- */
  const providerValue: IWebsocketContext = {
    websocketMessage: message,
    websocketIsReady: isReady,
    websocketSend: ws.current?.send
      ? ws.current.send
      : () => {throw new Error('Websocket is not ready to send messages yet. Wait until websocketIsReady is true!')}
  }
  /* -------------------------- Render constants end -------------------------- */


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return <WebsocketContext.Provider value={providerValue}>{props.children}</WebsocketContext.Provider>
}

const WebsocketContext = createContext<IWebsocketContext>({
  websocketMessage: null,
  websocketIsReady: false,
  websocketSend: () => {throw new Error('Websocket is not ready to send messages yet. Wait until isReady is true!')},
})

export const useWebsocket = () => useContext(WebsocketContext)