/* -------------------------- Design imports start -------------------------- */
import { Checkbox, FormControlLabel, IconButton, Menu, MenuItem } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
/* ------------------------- Functional imports end ------------------------- */
interface ColumnSelectorProps<T> {
  columns: { key?: string; title: string }[]
  onChangeColumns: (selectedColumns: string[]) => void
  defaultColumns?: string[]
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ColumnSelector<T>({
  columns,
  onChangeColumns,
  defaultColumns = [],
}: ColumnSelectorProps<T>) {
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['common'])
  const [checkedColumns, setCheckedColumns] = useState<string[]>(
    defaultColumns ?? columns.map(c => c.key ?? '')
  )
  const [anchorElColumnSelector, setAnchorElColumnSelector] = React.useState<null | HTMLElement>(
    null
  )
  /* ----------------------------- Data states end ---------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const openColumnSelector = Boolean(anchorElColumnSelector)

  /* ----------------------------- Flag states end ---------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClickColumnSelector = (event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorElColumnSelector(event.currentTarget)
  }

  const handleCloseColumnSelector = () => {
    setAnchorElColumnSelector(null)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const columnName = event.target.name
    const isChecked = event.target.checked

    let updatedCheckedColumns: string[]

    if (isChecked) {
      updatedCheckedColumns = [...checkedColumns, columnName]
    } else {
      updatedCheckedColumns = checkedColumns.filter(c => c !== columnName)
    }

    setCheckedColumns(updatedCheckedColumns)
    onChangeColumns(updatedCheckedColumns)
    event.stopPropagation()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <React.Fragment>
      <IconButton sx={{ float: 'right' }} onClick={(e: any) => handleClickColumnSelector(e)}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id="main-menu"
        anchorEl={anchorElColumnSelector}
        open={openColumnSelector}
        onClose={handleCloseColumnSelector}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map(c => {
          if (c.key !== '' && c.key !== undefined && c.key !== 'openDetailPage') {
            return (
              <MenuItem key={c.key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={c.key}
                      checked={checkedColumns.includes(c.key!)}
                      onChange={handleChange}
                    />
                  }
                  label={c.title}
                />
              </MenuItem>
            )
          }
          return null // Schlüssel ist leer, gib null zurück
        })}
      </Menu>
    </React.Fragment>
  )
}
