/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIAddress,
    APIAddressFromJSON,
    APIAddressToJSON,
    APIAddressRequest,
    APIAddressRequestFromJSON,
    APIAddressRequestToJSON,
    APIPaginatedAddressList,
    APIPaginatedAddressListFromJSON,
    APIPaginatedAddressListToJSON,
    APIPatchedAddressRequest,
    APIPatchedAddressRequestFromJSON,
    APIPatchedAddressRequestToJSON,
} from '../models';

export interface V1OrganizationsOrganizationShippingAddressCreateRequest {
    organizationPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1OrganizationsOrganizationShippingAddressDestroyRequest {
    id: number;
    organizationPk: string;
}

export interface V1OrganizationsOrganizationShippingAddressListRequest {
    organizationPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1OrganizationsOrganizationShippingAddressPartialUpdateRequest {
    id: number;
    organizationPk: string;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1OrganizationsOrganizationShippingAddressRetrieveRequest {
    id: number;
    organizationPk: string;
}

export interface V1OrganizationsOrganizationShippingAddressUpdateRequest {
    id: number;
    organizationPk: string;
    aPIAddressRequest: APIAddressRequest;
}

/**
 * 
 */
export class OrganizationsorganizationsshippingAddressApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressCreateRaw(requestParameters: V1OrganizationsOrganizationShippingAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1OrganizationsOrganizationShippingAddressCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/`.replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressCreate(requestParameters: V1OrganizationsOrganizationShippingAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressDestroyRaw(requestParameters: V1OrganizationsOrganizationShippingAddressDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressDestroy.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressDestroy(requestParameters: V1OrganizationsOrganizationShippingAddressDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1OrganizationsOrganizationShippingAddressDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressListRaw(requestParameters: V1OrganizationsOrganizationShippingAddressListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAddressList>> {
        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/`.replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAddressListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressList(requestParameters: V1OrganizationsOrganizationShippingAddressListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAddressList> {
        const response = await this.v1OrganizationsOrganizationShippingAddressListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressPartialUpdateRaw(requestParameters: V1OrganizationsOrganizationShippingAddressPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressPartialUpdate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressPartialUpdate(requestParameters: V1OrganizationsOrganizationShippingAddressPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressRetrieveRaw(requestParameters: V1OrganizationsOrganizationShippingAddressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressRetrieve.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressRetrieve(requestParameters: V1OrganizationsOrganizationShippingAddressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressUpdateRaw(requestParameters: V1OrganizationsOrganizationShippingAddressUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressUpdate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressUpdate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1OrganizationsOrganizationShippingAddressUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressUpdate(requestParameters: V1OrganizationsOrganizationShippingAddressUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
