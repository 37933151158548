/* ------------------------ Functional imports start ------------------------ */
import LogTool from "../../../logger/logTools";
import { buildFetchUrl, getPKfromSelf, handleAPICallV1 } from "../../../utils/functions";
import { FetchKwargs, HTTPMethod } from "../../../utils/types";
import { fetchArticle } from "../../Item/utils/functions";
import { Offer, PriceObject, Request, RequestFile } from "./types";
/* ------------------------- Functional imports end ------------------------- */

const log = new LogTool({context: 'RequestFunctions', enable: true, logLevel: 'warn'})
/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */

export function createRequest(requestResponse: any): Request {
    return {
        key: getPKfromSelf(requestResponse.self),
        self: requestResponse.self,
        article: requestResponse.article,
        remarks: requestResponse.remarks,
        country: requestResponse.country,
        certificate: requestResponse.certificate,
        description: requestResponse.description,
        quantities: requestResponse.quantities,
        wishDate: requestResponse.wish_date,
        calculatedCosts: requestResponse.calculated_costs,
        reportedCosts: requestResponse.reported_costs,
        status: requestResponse.status,
        priority: requestResponse.priority,
        createdAt: requestResponse.created_at,
        updatedAt: requestResponse.updated_at,
        editors: requestResponse.editors,
        files: requestResponse.files,
        read: requestResponse.read,
    }
}

export function createOffer(offerResponse: any): Offer {
    return {
        key: getPKfromSelf(offerResponse.self),
        self: offerResponse.self,
        request: offerResponse.request,
        remarks: offerResponse.remarks,
        prices: offerResponse.prices,
        status: offerResponse.status,
        editors: offerResponse.editors,
        createdAt: offerResponse.created_at,
        updatedAt: offerResponse.updated_at,
        article: offerResponse.article,
        files: offerResponse.files,
        read: offerResponse.read,
    }
}

export function createRequestFile(fileResponse: any): RequestFile {
    const file = {
        ...fileResponse,
        key: fileResponse.self,
        createdAt: new Date(fileResponse.created_at).toLocaleString(),
    }
    delete file.created_at;
    return file;
}

export function createOfferFile(fileResponse: any): any {
    const file = {
        ...fileResponse,
        key: fileResponse.self,
        createdAt: new Date(fileResponse.created_at).toLocaleString(),
    }
    delete file.created_at;
    return file;
}

export function inputToAPIRequestJSON(r: any): any {
    log.debug("Request -> ", r)
    const quantities: number[] = [...r.quantities]
    return {
        ...(r.article && { article: r.article?.self }),
        ...(r.remarks && { remarks: r.remarks }),
        ...(r.certificate && { certificate: r.certificate?.value }),
        ...(r.description && { description: r.description }),
        ...(r.price && { price: parseInt(r.price) }),
        ...(r.currency && { currency: r.currency }),
        ...(r.quantities && { quantities: quantities}),
        ...(r.wishDate && { wish_date: r.wishDate }),
        ...(r.status !== false && { status: parseInt(r.status) }),
        ...(r.priority !== undefined && { priority: r.priority }),
        ...(r.country && { country: r.country?.value }),
        ...(r.shippingAddress && { shipping_address: r.shippingAddress?.value }),
        ...(r.billingAddress && { billing_address: r.billingAddress?.value }),
        ...(r.editors && { editors: r.editors.map((editor: any) => editor?.value?.self ? editor.value.self : editor.self)}),
    }
}

export function inputToAPIOfferJSON(o: any): any {
    return {
        ...(o.request && { request: o.request?.self }),
        ...(o.remarks && { remarks: o.remarks }),
        ...(o.prices && { prices: o.prices }),
        ...(o.status !== false && { status: parseInt(o.status) }),
        ...(o.editors && { editors: o.editors.map((editor: any) => editor?.value?.self ? editor.value.self : editor.self)}),
    }
}

/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */

export async function fetchRequests({
        onSuccess = (requests : Request[], count?: number ) => null,
        onFinal = () => null,
        onError = (error : any) => null,
        parameter = [],
        url = "request/requests/",
    }: FetchKwargs<Request>
): Promise<void> {
    log.info("Begin fetching requests");
    if(!url.match(/request\/requests\//)) {
        log.error(`The provided URL ${url} does not lead to the correct API endpoint!`);
        return;
    }

    const fetchUrl = buildFetchUrl(url, parameter);

    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if(!error && response) {
        log.info("Success fetching requests for URL", fetchUrl, ", Response ->", response);
        const requests : Request[] = response.results.map((requestResponse : any) => createRequest(requestResponse));
        const count = response.count;

        // fetch all pages of a paginated server response
        if(response.next) {
            // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
            fetchRequests({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
        }

        // execute callbacks
        onSuccess(requests, count)
        if(!response.next) {
            // the are no more pages -> onSuccess was called for the last time
            onFinal()
        }
    } else {
        log.error("Error fetching requests", error);
        onError(error);
    }
}

export async function fetchRequest(
        url: string,
        onSuccess: (request: Request) => void,
        onError: (error : any) => void,
        parameter: string[] = [],
): Promise<void> {
    log.info("Begin fetching request");

    const fetchUrl = buildFetchUrl(url, parameter);

    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if(!error && response) {
        log.info("Success fetching request for URL", fetchUrl, ", Response ->", response);
        const request = createRequest(response);
        onSuccess(request);
    } else {
        log.error("Error fetching request", error);
        onError(error);
    }
}

export async function fetchRequestFiles({
    onSuccess = (files : RequestFile[]) => null,
    onFinal = () => null,
    onError = (error : any) => null,
    parameter = [],
    url = "",
}: FetchKwargs<RequestFile>): Promise<void> {
    log.info("Begin fetching request files");

    const fetchUrl = buildFetchUrl(url + "files/", parameter);

    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if(!error && response) {
        log.info("Success fetching request files for URL", fetchUrl, ", Response ->", response);
        const files : RequestFile[] = response.results.map((fileResponse : any) => {
            return createRequestFile(fileResponse);
        })

        // fetch all pages of a paginated server response
        if(response.next) {
            // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
            fetchRequestFiles({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
        }

        // execute callbacks
        onSuccess(files)
        if(!response.next) {
            // the are no more pages -> onSuccess was called for the last time
            onFinal()
        }
    } else {
        log.error("Error fetching request files", error);
        onError(error);
    }
}

export async function postRequest(
    r: any,
    files: any[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin posting request");

    const json = inputToAPIRequestJSON({...r, status: 0});
    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "request/requests/",
        {
            "Content-Type": "application/json",
        },
        json
    );

    if(!error && response) {
        log.info("Success posting request, Response ->", response);
        const request = createRequest(response);
        const success = await postRequestFiles(files, request);
        if(!success) {
            log.error("Error posting request files");
            onError("Error posting request files");
            return;
        } else {
            log.info("Success posting request files");
            onSuccess();
        }
    } else {
        log.error("Error posting request", error);
        onError(error);
    }
}

async function postRequestFiles(files: any[], request: Request) : Promise<boolean> {
    log.info("Begin posting request files");
    const promises = files.map((file : any) => handleRequestFileUpload(file, request));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error posting request files");
        return false;
    } else {
        log.info("Success posting request files");
        return true;
    }
}

async function handleRequestFileUpload(file: any, request: Request) : Promise<boolean> {
    log.info("Begin posting request file");
    log.debug("Request -> ", request.self)
    const formData = new FormData();
    formData.append('request', request.self)
    formData.append('document', file)
    log.debug("FormData -> ", formData)
    log.debug("File -> ", file)
    for (let pair of formData.entries() as any) {
        log.debug(pair[0]+ ', ' + pair[1]);
    }

    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        request.self + "files/",
        {
            "Content-Type": "multipart/form-data",
        },
        formData
    );

    if(!error && response) {
        log.info("Success posting request file, Response ->", response);
        return true;
    } else {
        log.error("Error posting request file", error);
        return false;
    }
    /* return false; */
}

export async function updateRequest(
    r: Request,
    files: any[],
    onSuccess: (request: Request) => void,
    onError: (error : any) => void,
    parameter: string[] = [],
) : Promise<void> {
    log.info("Begin updating request");
    const json = inputToAPIRequestJSON(r);
    log.debug("JSON -> ", json)
    const [response, error] = await handleAPICallV1(
        HTTPMethod.PUT,
        r.self,
        {
            "Content-Type": "application/json",
        },
        json
    );
    if(!error && response) {
        log.info("Success updating request, Response ->", response);
        const request = createRequest(response);
        const success = await postRequestFiles(files, request);
        if(!success) {
            log.error("Error posting request files");
            onError("Error posting request files");
            return;
        } else {
            log.info("Success posting request files");
            onSuccess(request);
        }
    } else {
        log.error("Error posting request", error);
        onError(error);
    }
}

export async function deleteRequests(
    requests: Request[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin deleting requests");
    const promises = requests.map((request : Request) => deleteRequest(request));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error deleting requests");
        onError("Error deleting requests");
        return;
    } else {
        log.info("Success deleting requests");
        onSuccess();
    }
}

async function deleteRequest(request: Request) : Promise<boolean> {
    log.info("Begin deleting request");
    const [response, error] = await handleAPICallV1(
        HTTPMethod.DELETE,
        request.self,
        undefined,
        undefined,
        "text"
    );

    if(!error) {
        log.info("Success deleting request, Response ->", response);
        return true;
    } else {
        log.error("Error deleting request", error);
        return false;
    }
}

export async function fetchOffers({
    onSuccess = (offers: Offer[], count?: number) => null,
    onFinal = () => null,
    onError = (error: any) => null,
    parameter = [],
    url = "request/offers/",
}: FetchKwargs<Offer>): Promise<void> {
    log.info("Begin fetching offers");
    if (!url.match(/request\/offers\//)) {
        log.error(`The provided URL ${url} does not lead to the correct API endpoint!`);
        return;
    }

    const fetchUrl = buildFetchUrl(url, parameter);
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if (!error && response) {
        log.info("Success fetching offers for URL", fetchUrl, ", Response ->", response);
        const offers: any[] = response.results.map((offerResponse: any) => createOffer(offerResponse));
        log.debug("Offers -> ", offers);
        // Fetch article data for each offer
        if(offers.length > 0) {
            const articleFetches = offers?.map(async (offer) => {
                if(!offer?.request?.article) return;
                await fetchArticle({
                    onSuccess: (articleData) => {
                        log.debug("Article data fetched -> ", articleData);
                        offer.article = articleData;
                    },
                    onError: (articleError) => {
                        log.error("Error fetching article data", articleError);
                    },
                    url: offer?.request?.article as string
                });
            });

            await Promise.all(articleFetches);
        }

        const count = response.count;
        // fetch all pages of a paginated server response
        if(response.next) {
            // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
            fetchOffers({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
        }

        // execute callbacks
        onSuccess(offers, count)
        if(!response.next) {
            // the are no more pages -> onSuccess was called for the last time
            onFinal()
        }
    } else {
        log.error("Error fetching offers", error);
        onError(error);
    }
}

export async function fetchOffer(
        url: string,
        onSuccess: (offer: Offer) => void,
        onError: (error : any) => void,
        parameter: string[] = [],
): Promise<void> {
    log.info("Begin fetching offer");

    const fetchUrl = buildFetchUrl(url, parameter);

    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if(!error && response) {
        log.info("Success fetching offer for URL", fetchUrl, ", Response ->", response);
        const offer = createOffer(response);
        onSuccess(offer);
    } else {
        log.error("Error fetching offer", error);
        onError(error);
    }
}

export async function postOffer(
    o: any,
    onSuccess: (offer: Offer) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin posting offer");

    const json = inputToAPIOfferJSON({...o, status: 0});
    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "request/offers/",
        {
            "Content-Type": "application/json",
        },
        json
    );

    if(!error && response) {
        log.info("Success posting offer, Response ->", response);
        const offer = createOffer(response);
        onSuccess(offer);
    } else {
        log.error("Error posting offer", error);
        onError(error);
    }
}

async function postOfferFiles(files: any[], offer: any) : Promise<boolean> {
    log.info("Begin posting offer files");
    const promises = files.map((file : any) => handleOfferFileUpload(file, offer));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error posting offer files");
        return false;
    } else {
        log.info("Success posting offer files");
        return true;
    }
}

async function handleOfferFileUpload(file: any, offer: any) : Promise<boolean> {
    log.info("Begin posting offer file");
    const formData = new FormData();
    formData.append('offer', offer.self)
    formData.append('document', file)
    log.debug("FormData -> ", formData)
    log.debug("File -> ", file)
    for (let pair of formData.entries() as any) {
        log.debug(pair[0]+ ', ' + pair[1]);
    }

    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "request/offer_files/",
        {
            "Content-Type": "multipart/form-data",
        },
        formData
    );

    if(!error && response) {
        log.info("Success posting offer file, Response ->", response);
        return true;
    } else {
        log.error("Error posting offer file", error);
        return false;
    }
    /* return false; */
}

export async function updateOffer(
    o: any,
    files: any[],
    onSuccess: (offer: Offer) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin updating offer");
    const json = inputToAPIOfferJSON(o);
    const [response, error] = await handleAPICallV1(
        HTTPMethod.PUT,
        o.self,
        {
            "Content-Type": "application/json",
        },
        json
    );
    if(!error && response) {
        log.info("Success updating offer, Response ->", response);
        const offer = createOffer(response);
        const success = await postOfferFiles(files, offer);
        if(!success) {
            log.error("Error posting offer files");
            onError("Error posting offer files");
            return;
        } else {
            log.info("Success posting offer files");
            onSuccess(offer);
        }
    } else {
        log.error("Error posting offer", error);
        onError(error);
    }
}

export async function deleteOffers(
    offers: any[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin deleting offers");
    const promises = offers.map((offer : any) => deleteOffer(offer));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error deleting offers");
        onError("Error deleting offers");
        return;
    } else {
        log.info("Success deleting offers");
        onSuccess();
    }
}

async function deleteOffer(offer: any) : Promise<boolean> {
    log.info("Begin deleting offer");
    const [response, error] = await handleAPICallV1(
        HTTPMethod.DELETE,
        offer.self,
        undefined,
        undefined,
        "text"
    );

    if(!error) {
        log.info("Success deleting offer, Response ->", response);
        return true;
    } else {
        log.error("Error deleting offer", error);
        return false;
    }
}