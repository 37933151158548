/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIAddress,
    APIAddressFromJSON,
    APIAddressToJSON,
    APIAddressRequest,
    APIAddressRequestFromJSON,
    APIAddressRequestToJSON,
    APIArticle,
    APIArticleFromJSON,
    APIArticleToJSON,
    APIArticleFile,
    APIArticleFileFromJSON,
    APIArticleFileToJSON,
    APIArticleRequest,
    APIArticleRequestFromJSON,
    APIArticleRequestToJSON,
    APIBOM,
    APIBOMFromJSON,
    APIBOMToJSON,
    APIBOMRequest,
    APIBOMRequestFromJSON,
    APIBOMRequestToJSON,
    APIBarcodeType,
    APIBarcodeTypeFromJSON,
    APIBarcodeTypeToJSON,
    APIBarcodeTypeRequest,
    APIBarcodeTypeRequestFromJSON,
    APIBarcodeTypeRequestToJSON,
    APIBill,
    APIBillFromJSON,
    APIBillToJSON,
    APIBillFile,
    APIBillFileFromJSON,
    APIBillFileToJSON,
    APIBillRequest,
    APIBillRequestFromJSON,
    APIBillRequestToJSON,
    APIBrand,
    APIBrandFromJSON,
    APIBrandToJSON,
    APIBrandRequest,
    APIBrandRequestFromJSON,
    APIBrandRequestToJSON,
    APICategory,
    APICategoryFromJSON,
    APICategoryToJSON,
    APICategoryRequest,
    APICategoryRequestFromJSON,
    APICategoryRequestToJSON,
    APIChangePasswordRequest,
    APIChangePasswordRequestFromJSON,
    APIChangePasswordRequestToJSON,
    APIChannel,
    APIChannelFromJSON,
    APIChannelToJSON,
    APIChannelRequest,
    APIChannelRequestFromJSON,
    APIChannelRequestToJSON,
    APIContact,
    APIContactFromJSON,
    APIContactToJSON,
    APIContactRequest,
    APIContactRequestFromJSON,
    APIContactRequestToJSON,
    APIContract,
    APIContractFromJSON,
    APIContractToJSON,
    APIContractFile,
    APIContractFileFromJSON,
    APIContractFileToJSON,
    APIContractRequest,
    APIContractRequestFromJSON,
    APIContractRequestToJSON,
    APIFamily,
    APIFamilyFromJSON,
    APIFamilyToJSON,
    APIFamilyRequest,
    APIFamilyRequestFromJSON,
    APIFamilyRequestToJSON,
    APIGuestUser,
    APIGuestUserFromJSON,
    APIGuestUserToJSON,
    APIInventoryMovement,
    APIInventoryMovementFromJSON,
    APIInventoryMovementToJSON,
    APIInventoryMovementRequest,
    APIInventoryMovementRequestFromJSON,
    APIInventoryMovementRequestToJSON,
    APIOffer,
    APIOfferFromJSON,
    APIOfferToJSON,
    APIOfferFile,
    APIOfferFileFromJSON,
    APIOfferFileToJSON,
    APIOfferRequest,
    APIOfferRequestFromJSON,
    APIOfferRequestToJSON,
    APIOrgUserTokenObtainPairRequest,
    APIOrgUserTokenObtainPairRequestFromJSON,
    APIOrgUserTokenObtainPairRequestToJSON,
    APIOrganization,
    APIOrganizationFromJSON,
    APIOrganizationToJSON,
    APIOrganizationRequest,
    APIOrganizationRequestFromJSON,
    APIOrganizationRequestToJSON,
    APIPatchedAddressRequest,
    APIPatchedAddressRequestFromJSON,
    APIPatchedAddressRequestToJSON,
    APIPatchedArticleRequest,
    APIPatchedArticleRequestFromJSON,
    APIPatchedArticleRequestToJSON,
    APIPatchedBOMRequest,
    APIPatchedBOMRequestFromJSON,
    APIPatchedBOMRequestToJSON,
    APIPatchedBarcodeTypeRequest,
    APIPatchedBarcodeTypeRequestFromJSON,
    APIPatchedBarcodeTypeRequestToJSON,
    APIPatchedBillRequest,
    APIPatchedBillRequestFromJSON,
    APIPatchedBillRequestToJSON,
    APIPatchedBrandRequest,
    APIPatchedBrandRequestFromJSON,
    APIPatchedBrandRequestToJSON,
    APIPatchedCategoryRequest,
    APIPatchedCategoryRequestFromJSON,
    APIPatchedCategoryRequestToJSON,
    APIPatchedChangePasswordRequest,
    APIPatchedChangePasswordRequestFromJSON,
    APIPatchedChangePasswordRequestToJSON,
    APIPatchedChannelRequest,
    APIPatchedChannelRequestFromJSON,
    APIPatchedChannelRequestToJSON,
    APIPatchedContactRequest,
    APIPatchedContactRequestFromJSON,
    APIPatchedContactRequestToJSON,
    APIPatchedContractRequest,
    APIPatchedContractRequestFromJSON,
    APIPatchedContractRequestToJSON,
    APIPatchedFamilyRequest,
    APIPatchedFamilyRequestFromJSON,
    APIPatchedFamilyRequestToJSON,
    APIPatchedInventoryMovementRequest,
    APIPatchedInventoryMovementRequestFromJSON,
    APIPatchedInventoryMovementRequestToJSON,
    APIPatchedOfferRequest,
    APIPatchedOfferRequestFromJSON,
    APIPatchedOfferRequestToJSON,
    APIPatchedOrganizationRequest,
    APIPatchedOrganizationRequestFromJSON,
    APIPatchedOrganizationRequestToJSON,
    APIPatchedPropertyRequest,
    APIPatchedPropertyRequestFromJSON,
    APIPatchedPropertyRequestToJSON,
    APIPatchedRequestRequest,
    APIPatchedRequestRequestFromJSON,
    APIPatchedRequestRequestToJSON,
    APIPatchedRichContactRequest,
    APIPatchedRichContactRequestFromJSON,
    APIPatchedRichContactRequestToJSON,
    APIPatchedStorageArticleRequest,
    APIPatchedStorageArticleRequestFromJSON,
    APIPatchedStorageArticleRequestToJSON,
    APIPatchedStoragePositionRequest,
    APIPatchedStoragePositionRequestFromJSON,
    APIPatchedStoragePositionRequestToJSON,
    APIPatchedTicketRequest,
    APIPatchedTicketRequestFromJSON,
    APIPatchedTicketRequestToJSON,
    APIPatchedTypeRequest,
    APIPatchedTypeRequestFromJSON,
    APIPatchedTypeRequestToJSON,
    APIPatchedWasteRequest,
    APIPatchedWasteRequestFromJSON,
    APIPatchedWasteRequestToJSON,
    APIProperty,
    APIPropertyFromJSON,
    APIPropertyToJSON,
    APIPropertyRequest,
    APIPropertyRequestFromJSON,
    APIPropertyRequestToJSON,
    APIRegister,
    APIRegisterFromJSON,
    APIRegisterToJSON,
    APIRegisterRequest,
    APIRegisterRequestFromJSON,
    APIRegisterRequestToJSON,
    APIRequest,
    APIRequestFromJSON,
    APIRequestToJSON,
    APIRequestFile,
    APIRequestFileFromJSON,
    APIRequestFileToJSON,
    APIRequestRequest,
    APIRequestRequestFromJSON,
    APIRequestRequestToJSON,
    APIRichContact,
    APIRichContactFromJSON,
    APIRichContactToJSON,
    APIRichContactRequest,
    APIRichContactRequestFromJSON,
    APIRichContactRequestToJSON,
    APISharepoint,
    APISharepointFromJSON,
    APISharepointToJSON,
    APIStorageArticle,
    APIStorageArticleFromJSON,
    APIStorageArticleToJSON,
    APIStorageArticleRequest,
    APIStorageArticleRequestFromJSON,
    APIStorageArticleRequestToJSON,
    APIStoragePosition,
    APIStoragePositionFromJSON,
    APIStoragePositionToJSON,
    APIStoragePositionRequest,
    APIStoragePositionRequestFromJSON,
    APIStoragePositionRequestToJSON,
    APITicket,
    APITicketFromJSON,
    APITicketToJSON,
    APITicketRequest,
    APITicketRequestFromJSON,
    APITicketRequestToJSON,
    APITokenRefresh,
    APITokenRefreshFromJSON,
    APITokenRefreshToJSON,
    APITokenRefreshRequest,
    APITokenRefreshRequestFromJSON,
    APITokenRefreshRequestToJSON,
    APITokenVerifyRequest,
    APITokenVerifyRequestFromJSON,
    APITokenVerifyRequestToJSON,
    APIType,
    APITypeFromJSON,
    APITypeToJSON,
    APITypeRequest,
    APITypeRequestFromJSON,
    APITypeRequestToJSON,
    APIWaste,
    APIWasteFromJSON,
    APIWasteToJSON,
    APIWasteRequest,
    APIWasteRequestFromJSON,
    APIWasteRequestToJSON,
} from '../models';

export interface V1AccountsRegisterCreateRequest {
    aPIRegisterRequest: APIRegisterRequest;
}

export interface V1AuthChangePasswordPartialUpdateRequest {
    aPIPatchedChangePasswordRequest?: APIPatchedChangePasswordRequest;
}

export interface V1AuthChangePasswordUpdateRequest {
    aPIChangePasswordRequest: APIChangePasswordRequest;
}

export interface V1AuthTokenCreateRequest {
    aPIOrgUserTokenObtainPairRequest: APIOrgUserTokenObtainPairRequest;
}

export interface V1AuthTokenRefreshCreateRequest {
    aPITokenRefreshRequest: APITokenRefreshRequest;
}

export interface V1AuthTokenVerifyCreateRequest {
    aPITokenVerifyRequest: APITokenVerifyRequest;
}

export interface V1ChatChannelInteractedPartialUpdateRequest {
    id: string;
    aPIPatchedChannelRequest?: APIPatchedChannelRequest;
}

export interface V1ChatChannelLeaveChannelCreateRequest {
    id: string;
    aPIChannelRequest: APIChannelRequest;
}

export interface V1ContactsAddressesCompareVersionsCreateRequest {
    id: number;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1ContactsAddressesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1ContactsAddressesVersionsRetrieveRequest {
    id: number;
}

export interface V1ContactsContactsAddressesCompareVersionsCreateRequest {
    contactsPk: string;
    id: number;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1ContactsContactsAddressesRevertPartialUpdateRequest {
    contactsPk: string;
    id: number;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1ContactsContactsAddressesVersionsRetrieveRequest {
    contactsPk: string;
    id: number;
}

export interface V1ContactsContactsCompareVersionsCreateRequest {
    id: number;
    aPIContactRequest: APIContactRequest;
}

export interface V1ContactsContactsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedContactRequest?: APIPatchedContactRequest;
}

export interface V1ContactsContactsVersionsRetrieveRequest {
    id: number;
}

export interface V1ContactsRichContactsCompareVersionsCreateRequest {
    id: number;
    aPIRichContactRequest: APIRichContactRequest;
}

export interface V1ContactsRichContactsCreateRequest {
    aPIRichContactRequest: APIRichContactRequest;
}

export interface V1ContactsRichContactsDestroyRequest {
    id: number;
}

export interface V1ContactsRichContactsPartialUpdateRequest {
    id: number;
    aPIPatchedRichContactRequest?: APIPatchedRichContactRequest;
}

export interface V1ContactsRichContactsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedRichContactRequest?: APIPatchedRichContactRequest;
}

export interface V1ContactsRichContactsUpdateRequest {
    id: number;
    aPIRichContactRequest: APIRichContactRequest;
}

export interface V1ContactsRichContactsVersionsRetrieveRequest {
    id: number;
}

export interface V1ContractBillFilesCompareVersionsCreateRequest {
    id: number;
    bill: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillFilesRevertPartialUpdateRequest {
    id: number;
    bill?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillFilesVersionsRetrieveRequest {
    id: number;
}

export interface V1ContractBillsCompareVersionsCreateRequest {
    id: number;
    aPIBillRequest: APIBillRequest;
}

export interface V1ContractBillsFilesCompareVersionsCreateRequest {
    billPk: number;
    id: number;
    bill: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillsFilesRevertPartialUpdateRequest {
    billPk: number;
    id: number;
    bill?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractBillsFilesVersionsRetrieveRequest {
    billPk: number;
    id: number;
}

export interface V1ContractBillsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedBillRequest?: APIPatchedBillRequest;
}

export interface V1ContractBillsVersionsRetrieveRequest {
    id: number;
}

export interface V1ContractContractsCompareVersionsCreateRequest {
    id: number;
    aPIContractRequest: APIContractRequest;
}

export interface V1ContractContractsFilesCompareVersionsCreateRequest {
    contractPk: number;
    id: number;
    contract: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractContractsFilesRevertPartialUpdateRequest {
    contractPk: number;
    id: number;
    contract?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractContractsFilesVersionsRetrieveRequest {
    contractPk: number;
    id: number;
}

export interface V1ContractContractsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedContractRequest?: APIPatchedContractRequest;
}

export interface V1ContractContractsVersionsRetrieveRequest {
    id: number;
}

export interface V1ContractFilesCompareVersionsCreateRequest {
    id: number;
    contract: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractFilesRevertPartialUpdateRequest {
    id: number;
    contract?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ContractFilesVersionsRetrieveRequest {
    id: number;
}

export interface V1CustomerCustomersAddressesCompareVersionsCreateRequest {
    customersPk: string;
    id: number;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1CustomerCustomersAddressesRevertPartialUpdateRequest {
    customersPk: string;
    id: number;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1CustomerCustomersAddressesVersionsRetrieveRequest {
    customersPk: string;
    id: number;
}

export interface V1GeneralFilesDestroyRequest {
    fileName: string;
}

export interface V1ItemsArticlesAllowedBomChildrenRetrieveRequest {
    id: number;
}

export interface V1ItemsArticlesAncestorsRetrieveRequest {
    id: number;
}

export interface V1ItemsArticlesBomCompareVersionsCreateRequest {
    articlePk: string;
    id: number;
    aPIBOMRequest: APIBOMRequest;
}

export interface V1ItemsArticlesBomRevertPartialUpdateRequest {
    articlePk: string;
    id: number;
    aPIPatchedBOMRequest?: APIPatchedBOMRequest;
}

export interface V1ItemsArticlesBomVersionsRetrieveRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesCategoriesCompareVersionsCreateRequest {
    articlePk: string;
    id: number;
    aPICategoryRequest: APICategoryRequest;
}

export interface V1ItemsArticlesCategoriesRevertPartialUpdateRequest {
    articlePk: string;
    id: number;
    aPIPatchedCategoryRequest?: APIPatchedCategoryRequest;
}

export interface V1ItemsArticlesCategoriesVersionsRetrieveRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesCompareVersionsCreateRequest {
    id: number;
    aPIArticleRequest: APIArticleRequest;
}

export interface V1ItemsArticlesFilesCompareVersionsCreateRequest {
    articlePk: number;
    id: number;
    article: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsArticlesFilesRevertPartialUpdateRequest {
    articlePk: number;
    id: number;
    article?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsArticlesFilesVersionsRetrieveRequest {
    articlePk: number;
    id: number;
}

export interface V1ItemsArticlesPropertiesCompareVersionsCreateRequest {
    articlePk: string;
    id: number;
    aPIPropertyRequest: APIPropertyRequest;
}

export interface V1ItemsArticlesPropertiesRevertPartialUpdateRequest {
    articlePk: string;
    id: number;
    aPIPatchedPropertyRequest?: APIPatchedPropertyRequest;
}

export interface V1ItemsArticlesPropertiesVersionsRetrieveRequest {
    articlePk: string;
    id: number;
}

export interface V1ItemsArticlesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedArticleRequest?: APIPatchedArticleRequest;
}

export interface V1ItemsArticlesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsBarcodeTypesCompareVersionsCreateRequest {
    id: number;
    aPIBarcodeTypeRequest: APIBarcodeTypeRequest;
}

export interface V1ItemsBarcodeTypesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedBarcodeTypeRequest?: APIPatchedBarcodeTypeRequest;
}

export interface V1ItemsBarcodeTypesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsBomBasesCompareVersionsCreateRequest {
    id: number;
    aPIArticleRequest: APIArticleRequest;
}

export interface V1ItemsBomBasesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedArticleRequest?: APIPatchedArticleRequest;
}

export interface V1ItemsBomBasesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsBomsCompareVersionsCreateRequest {
    id: number;
    aPIBOMRequest: APIBOMRequest;
}

export interface V1ItemsBomsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedBOMRequest?: APIPatchedBOMRequest;
}

export interface V1ItemsBomsVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsBrandsCompareVersionsCreateRequest {
    id: number;
    aPIBrandRequest: APIBrandRequest;
}

export interface V1ItemsBrandsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedBrandRequest?: APIPatchedBrandRequest;
}

export interface V1ItemsBrandsVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsCategoriesCompareVersionsCreateRequest {
    id: number;
    aPICategoryRequest: APICategoryRequest;
}

export interface V1ItemsCategoriesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedCategoryRequest?: APIPatchedCategoryRequest;
}

export interface V1ItemsCategoriesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsFamiliesCompareVersionsCreateRequest {
    id: number;
    aPIFamilyRequest: APIFamilyRequest;
}

export interface V1ItemsFamiliesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedFamilyRequest?: APIPatchedFamilyRequest;
}

export interface V1ItemsFamiliesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsFilesCompareVersionsCreateRequest {
    id: number;
    article: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsFilesRevertPartialUpdateRequest {
    id: number;
    article?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1ItemsFilesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsPropertiesCompareVersionsCreateRequest {
    id: number;
    aPIPropertyRequest: APIPropertyRequest;
}

export interface V1ItemsPropertiesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedPropertyRequest?: APIPatchedPropertyRequest;
}

export interface V1ItemsPropertiesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsSharepointsAutomaticSharesRetrieveRequest {
    id: number;
}

export interface V1ItemsSharepointsManualSharesRetrieveRequest {
    id: number;
}

export interface V1ItemsTypesCompareVersionsCreateRequest {
    id: number;
    aPITypeRequest: APITypeRequest;
}

export interface V1ItemsTypesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedTypeRequest?: APIPatchedTypeRequest;
}

export interface V1ItemsTypesVersionsRetrieveRequest {
    id: number;
}

export interface V1ItemsWasteCompareVersionsCreateRequest {
    id: number;
    aPIWasteRequest: APIWasteRequest;
}

export interface V1ItemsWasteRevertPartialUpdateRequest {
    id: number;
    aPIPatchedWasteRequest?: APIPatchedWasteRequest;
}

export interface V1ItemsWasteVersionsRetrieveRequest {
    id: number;
}

export interface V1LogisticsInventoryMovementsCompareVersionsCreateRequest {
    id: number;
    aPIInventoryMovementRequest: APIInventoryMovementRequest;
}

export interface V1LogisticsInventoryMovementsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedInventoryMovementRequest?: APIPatchedInventoryMovementRequest;
}

export interface V1LogisticsInventoryMovementsVersionsRetrieveRequest {
    id: number;
}

export interface V1LogisticsStorageArticlesCompareVersionsCreateRequest {
    id: number;
    aPIStorageArticleRequest: APIStorageArticleRequest;
}

export interface V1LogisticsStorageArticlesRelocateCreateRequest {
    id: number;
    aPIStorageArticleRequest: APIStorageArticleRequest;
}

export interface V1LogisticsStorageArticlesRevertPartialUpdateRequest {
    id: number;
    aPIPatchedStorageArticleRequest?: APIPatchedStorageArticleRequest;
}

export interface V1LogisticsStorageArticlesStoreCreateRequest {
    aPIStorageArticleRequest: APIStorageArticleRequest;
}

export interface V1LogisticsStorageArticlesVersionsRetrieveRequest {
    id: number;
}

export interface V1LogisticsStorageArticlesWithdrawCreateRequest {
    id: number;
    aPIStorageArticleRequest: APIStorageArticleRequest;
}

export interface V1LogisticsStoragePositionsCompareVersionsCreateRequest {
    id: number;
    aPIStoragePositionRequest: APIStoragePositionRequest;
}

export interface V1LogisticsStoragePositionsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedStoragePositionRequest?: APIPatchedStoragePositionRequest;
}

export interface V1LogisticsStoragePositionsVersionsRetrieveRequest {
    id: number;
}

export interface V1OrganizationsOrganizationBillingAddressCompareVersionsCreateRequest {
    id: number;
    organizationPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1OrganizationsOrganizationBillingAddressRevertPartialUpdateRequest {
    id: number;
    organizationPk: string;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1OrganizationsOrganizationBillingAddressVersionsRetrieveRequest {
    id: number;
    organizationPk: string;
}

export interface V1OrganizationsOrganizationCompareVersionsCreateRequest {
    id: number;
    aPIOrganizationRequest: APIOrganizationRequest;
}

export interface V1OrganizationsOrganizationRevertPartialUpdateRequest {
    id: number;
    aPIPatchedOrganizationRequest?: APIPatchedOrganizationRequest;
}

export interface V1OrganizationsOrganizationShippingAddressCompareVersionsCreateRequest {
    id: number;
    organizationPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1OrganizationsOrganizationShippingAddressRevertPartialUpdateRequest {
    id: number;
    organizationPk: string;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1OrganizationsOrganizationShippingAddressVersionsRetrieveRequest {
    id: number;
    organizationPk: string;
}

export interface V1OrganizationsOrganizationVersionsRetrieveRequest {
    id: number;
}

export interface V1RequestFilesCompareVersionsCreateRequest {
    id: number;
    request: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestFilesRevertPartialUpdateRequest {
    id: number;
    request?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestFilesVersionsRetrieveRequest {
    id: number;
}

export interface V1RequestOfferFilesCompareVersionsCreateRequest {
    id: number;
    offer: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOfferFilesRevertPartialUpdateRequest {
    id: number;
    offer?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOfferFilesVersionsRetrieveRequest {
    id: number;
}

export interface V1RequestOffersCompareVersionsCreateRequest {
    id: number;
    aPIOfferRequest: APIOfferRequest;
}

export interface V1RequestOffersFilesCompareVersionsCreateRequest {
    id: number;
    offerPk: number;
    offer: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOffersFilesRevertPartialUpdateRequest {
    id: number;
    offerPk: number;
    offer?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOffersFilesVersionsRetrieveRequest {
    id: number;
    offerPk: number;
}

export interface V1RequestOffersRevertPartialUpdateRequest {
    id: number;
    aPIPatchedOfferRequest?: APIPatchedOfferRequest;
}

export interface V1RequestOffersVersionsRetrieveRequest {
    id: number;
}

export interface V1RequestRequestsCompareVersionsCreateRequest {
    id: number;
    aPIRequestRequest: APIRequestRequest;
}

export interface V1RequestRequestsFilesCompareVersionsCreateRequest {
    id: number;
    requestPk: number;
    request: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestRequestsFilesRevertPartialUpdateRequest {
    id: number;
    requestPk: number;
    request?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestRequestsFilesVersionsRetrieveRequest {
    id: number;
    requestPk: number;
}

export interface V1RequestRequestsRevertPartialUpdateRequest {
    id: number;
    aPIPatchedRequestRequest?: APIPatchedRequestRequest;
}

export interface V1RequestRequestsVersionsRetrieveRequest {
    id: number;
}

export interface V1SupplierSuppliersAddressesCompareVersionsCreateRequest {
    id: number;
    suppliersPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1SupplierSuppliersAddressesRevertPartialUpdateRequest {
    id: number;
    suppliersPk: string;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1SupplierSuppliersAddressesVersionsRetrieveRequest {
    id: number;
    suppliersPk: string;
}

export interface V1SupplierSuppliersArticlesRetrieveRequest {
    id: number;
}

export interface V1SupportTicketsCompareVersionsCreateRequest {
    id: string;
    aPITicketRequest: APITicketRequest;
}

export interface V1SupportTicketsRevertPartialUpdateRequest {
    id: string;
    aPIPatchedTicketRequest?: APIPatchedTicketRequest;
}

export interface V1SupportTicketsVersionsRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class V1Api extends runtime.BaseAPI {

    /**
     * An endpoint for new Organization registration.
     */
    async v1AccountsRegisterCreateRaw(requestParameters: V1AccountsRegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRegister>> {
        if (requestParameters.aPIRegisterRequest === null || requestParameters.aPIRegisterRequest === undefined) {
            throw new runtime.RequiredError('aPIRegisterRequest','Required parameter requestParameters.aPIRegisterRequest was null or undefined when calling v1AccountsRegisterCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIRegisterRequestToJSON(requestParameters.aPIRegisterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRegisterFromJSON(jsonValue));
    }

    /**
     * An endpoint for new Organization registration.
     */
    async v1AccountsRegisterCreate(requestParameters: V1AccountsRegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRegister> {
        const response = await this.v1AccountsRegisterCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * An endpoint for changing password.
     */
    async v1AuthChangePasswordPartialUpdateRaw(requestParameters: V1AuthChangePasswordPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/change_password`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedChangePasswordRequestToJSON(requestParameters.aPIPatchedChangePasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * An endpoint for changing password.
     */
    async v1AuthChangePasswordPartialUpdate(requestParameters: V1AuthChangePasswordPartialUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthChangePasswordPartialUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * An endpoint for changing password.
     */
    async v1AuthChangePasswordUpdateRaw(requestParameters: V1AuthChangePasswordUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aPIChangePasswordRequest === null || requestParameters.aPIChangePasswordRequest === undefined) {
            throw new runtime.RequiredError('aPIChangePasswordRequest','Required parameter requestParameters.aPIChangePasswordRequest was null or undefined when calling v1AuthChangePasswordUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/change_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIChangePasswordRequestToJSON(requestParameters.aPIChangePasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * An endpoint for changing password.
     */
    async v1AuthChangePasswordUpdate(requestParameters: V1AuthChangePasswordUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthChangePasswordUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1AuthEmailRequestPasswordResetConfirmCreateRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/request/password_reset/confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1AuthEmailRequestPasswordResetConfirmCreate(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthEmailRequestPasswordResetConfirmCreateRaw(initOverrides);
    }

    /**
     */
    async v1AuthEmailRequestPasswordResetRequestCreateRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/auth/email/request/password_reset/request/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1AuthEmailRequestPasswordResetRequestCreate(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthEmailRequestPasswordResetRequestCreateRaw(initOverrides);
    }

    /**
     * Takes and handles a set of user credentials from different user types and returns an access and refresh JSON web token pair to prove the authenticity of those credentials.
     */
    async v1AuthTokenCreateRaw(requestParameters: V1AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aPIOrgUserTokenObtainPairRequest === null || requestParameters.aPIOrgUserTokenObtainPairRequest === undefined) {
            throw new runtime.RequiredError('aPIOrgUserTokenObtainPairRequest','Required parameter requestParameters.aPIOrgUserTokenObtainPairRequest was null or undefined when calling v1AuthTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOrgUserTokenObtainPairRequestToJSON(requestParameters.aPIOrgUserTokenObtainPairRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Takes and handles a set of user credentials from different user types and returns an access and refresh JSON web token pair to prove the authenticity of those credentials.
     */
    async v1AuthTokenCreate(requestParameters: V1AuthTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthTokenCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async v1AuthTokenRefreshCreateRaw(requestParameters: V1AuthTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITokenRefresh>> {
        if (requestParameters.aPITokenRefreshRequest === null || requestParameters.aPITokenRefreshRequest === undefined) {
            throw new runtime.RequiredError('aPITokenRefreshRequest','Required parameter requestParameters.aPITokenRefreshRequest was null or undefined when calling v1AuthTokenRefreshCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APITokenRefreshRequestToJSON(requestParameters.aPITokenRefreshRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITokenRefreshFromJSON(jsonValue));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async v1AuthTokenRefreshCreate(requestParameters: V1AuthTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITokenRefresh> {
        const response = await this.v1AuthTokenRefreshCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async v1AuthTokenVerifyCreateRaw(requestParameters: V1AuthTokenVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aPITokenVerifyRequest === null || requestParameters.aPITokenVerifyRequest === undefined) {
            throw new runtime.RequiredError('aPITokenVerifyRequest','Required parameter requestParameters.aPITokenVerifyRequest was null or undefined when calling v1AuthTokenVerifyCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/auth/token/verify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APITokenVerifyRequestToJSON(requestParameters.aPITokenVerifyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async v1AuthTokenVerifyCreate(requestParameters: V1AuthTokenVerifyCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AuthTokenVerifyCreateRaw(requestParameters, initOverrides);
    }

    /**
     * update the last interacted time in the ChannelMembership
     */
    async v1ChatChannelInteractedPartialUpdateRaw(requestParameters: V1ChatChannelInteractedPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelInteractedPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{id}/interacted/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedChannelRequestToJSON(requestParameters.aPIPatchedChannelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelFromJSON(jsonValue));
    }

    /**
     * update the last interacted time in the ChannelMembership
     */
    async v1ChatChannelInteractedPartialUpdate(requestParameters: V1ChatChannelInteractedPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannel> {
        const response = await this.v1ChatChannelInteractedPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove the requesting user from the members of the channel that is specified by the pk.
     */
    async v1ChatChannelLeaveChannelCreateRaw(requestParameters: V1ChatChannelLeaveChannelCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelLeaveChannelCreate.');
        }

        if (requestParameters.aPIChannelRequest === null || requestParameters.aPIChannelRequest === undefined) {
            throw new runtime.RequiredError('aPIChannelRequest','Required parameter requestParameters.aPIChannelRequest was null or undefined when calling v1ChatChannelLeaveChannelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{id}/leave_channel/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIChannelRequestToJSON(requestParameters.aPIChannelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelFromJSON(jsonValue));
    }

    /**
     * Remove the requesting user from the members of the channel that is specified by the pk.
     */
    async v1ChatChannelLeaveChannelCreate(requestParameters: V1ChatChannelLeaveChannelCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannel> {
        const response = await this.v1ChatChannelLeaveChannelCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the sum of all unread messages for all channels based on the requesting user.
     */
    async v1ChatChannelUnreadMessagesCountRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIChannel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/unread_messages_count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIChannelFromJSON(jsonValue));
    }

    /**
     * Returns the sum of all unread messages for all channels based on the requesting user.
     */
    async v1ChatChannelUnreadMessagesCountRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIChannel> {
        const response = await this.v1ChatChannelUnreadMessagesCountRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesCompareVersionsCreateRaw(requestParameters: V1ContactsAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesCompareVersionsCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1ContactsAddressesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesCompareVersionsCreate(requestParameters: V1ContactsAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesRevertPartialUpdateRaw(requestParameters: V1ContactsAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesRevertPartialUpdate(requestParameters: V1ContactsAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesVersionsRetrieveRaw(requestParameters: V1ContactsAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsAddressesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/addresses/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsAddressesVersionsRetrieve(requestParameters: V1ContactsAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsAddressesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesCompareVersionsCreateRaw(requestParameters: V1ContactsContactsAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesCompareVersionsCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1ContactsContactsAddressesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/compare_versions/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesCompareVersionsCreate(requestParameters: V1ContactsContactsAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesRevertPartialUpdateRaw(requestParameters: V1ContactsContactsAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/revert/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesRevertPartialUpdate(requestParameters: V1ContactsContactsAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesVersionsRetrieveRaw(requestParameters: V1ContactsContactsAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.contactsPk === null || requestParameters.contactsPk === undefined) {
            throw new runtime.RequiredError('contactsPk','Required parameter requestParameters.contactsPk was null or undefined when calling v1ContactsContactsAddressesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsAddressesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{contacts_pk}/addresses/{id}/versions/`.replace(`{${"contacts_pk"}}`, encodeURIComponent(String(requestParameters.contactsPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsAddressesVersionsRetrieve(requestParameters: V1ContactsContactsAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1ContactsContactsAddressesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsCompareVersionsCreateRaw(requestParameters: V1ContactsContactsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsCompareVersionsCreate.');
        }

        if (requestParameters.aPIContactRequest === null || requestParameters.aPIContactRequest === undefined) {
            throw new runtime.RequiredError('aPIContactRequest','Required parameter requestParameters.aPIContactRequest was null or undefined when calling v1ContactsContactsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIContactRequestToJSON(requestParameters.aPIContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsCompareVersionsCreate(requestParameters: V1ContactsContactsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsRevertPartialUpdateRaw(requestParameters: V1ContactsContactsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedContactRequestToJSON(requestParameters.aPIPatchedContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsRevertPartialUpdate(requestParameters: V1ContactsContactsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsVersionsRetrieveRaw(requestParameters: V1ContactsContactsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsContactsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/contacts/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContactsContactsVersionsRetrieve(requestParameters: V1ContactsContactsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1ContactsContactsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsCompareVersionsCreateRaw(requestParameters: V1ContactsRichContactsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsCompareVersionsCreate.');
        }

        if (requestParameters.aPIRichContactRequest === null || requestParameters.aPIRichContactRequest === undefined) {
            throw new runtime.RequiredError('aPIRichContactRequest','Required parameter requestParameters.aPIRichContactRequest was null or undefined when calling v1ContactsRichContactsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIRichContactRequestToJSON(requestParameters.aPIRichContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsCompareVersionsCreate(requestParameters: V1ContactsRichContactsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsCreateRaw(requestParameters: V1ContactsRichContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.aPIRichContactRequest === null || requestParameters.aPIRichContactRequest === undefined) {
            throw new runtime.RequiredError('aPIRichContactRequest','Required parameter requestParameters.aPIRichContactRequest was null or undefined when calling v1ContactsRichContactsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIRichContactRequestToJSON(requestParameters.aPIRichContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsCreate(requestParameters: V1ContactsRichContactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsDestroyRaw(requestParameters: V1ContactsRichContactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsDestroy(requestParameters: V1ContactsRichContactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContactsRichContactsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsPartialUpdateRaw(requestParameters: V1ContactsRichContactsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedRichContactRequestToJSON(requestParameters.aPIPatchedRichContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsPartialUpdate(requestParameters: V1ContactsRichContactsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsRevertPartialUpdateRaw(requestParameters: V1ContactsRichContactsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedRichContactRequestToJSON(requestParameters.aPIPatchedRichContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsRevertPartialUpdate(requestParameters: V1ContactsRichContactsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsUpdateRaw(requestParameters: V1ContactsRichContactsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsUpdate.');
        }

        if (requestParameters.aPIRichContactRequest === null || requestParameters.aPIRichContactRequest === undefined) {
            throw new runtime.RequiredError('aPIRichContactRequest','Required parameter requestParameters.aPIRichContactRequest was null or undefined when calling v1ContactsRichContactsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIRichContactRequestToJSON(requestParameters.aPIRichContactRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsUpdate(requestParameters: V1ContactsRichContactsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsVersionsRetrieveRaw(requestParameters: V1ContactsRichContactsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRichContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContactsRichContactsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contacts/rich_contacts/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRichContactFromJSON(jsonValue));
    }

    /**
     * List and Retrieve views that enrich contacts with context information, that is for which suppliers / customers the contact is used.
     */
    async v1ContactsRichContactsVersionsRetrieve(requestParameters: V1ContactsRichContactsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRichContact> {
        const response = await this.v1ContactsRichContactsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesCompareVersionsCreateRaw(requestParameters: V1ContractBillFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesCompareVersionsCreate.');
        }

        if (requestParameters.bill === null || requestParameters.bill === undefined) {
            throw new runtime.RequiredError('bill','Required parameter requestParameters.bill was null or undefined when calling v1ContractBillFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesCompareVersionsCreate(requestParameters: V1ContractBillFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesRevertPartialUpdateRaw(requestParameters: V1ContractBillFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesRevertPartialUpdate(requestParameters: V1ContractBillFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesVersionsRetrieveRaw(requestParameters: V1ContractBillFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bill_files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillFilesVersionsRetrieve(requestParameters: V1ContractBillFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsCompareVersionsCreateRaw(requestParameters: V1ContractBillsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsCompareVersionsCreate.');
        }

        if (requestParameters.aPIBillRequest === null || requestParameters.aPIBillRequest === undefined) {
            throw new runtime.RequiredError('aPIBillRequest','Required parameter requestParameters.aPIBillRequest was null or undefined when calling v1ContractBillsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBillRequestToJSON(requestParameters.aPIBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsCompareVersionsCreate(requestParameters: V1ContractBillsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesCompareVersionsCreateRaw(requestParameters: V1ContractBillsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesCompareVersionsCreate.');
        }

        if (requestParameters.bill === null || requestParameters.bill === undefined) {
            throw new runtime.RequiredError('bill','Required parameter requestParameters.bill was null or undefined when calling v1ContractBillsFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/compare_versions/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesCompareVersionsCreate(requestParameters: V1ContractBillsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesRevertPartialUpdateRaw(requestParameters: V1ContractBillsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.bill !== undefined) {
            formParams.append('bill', requestParameters.bill as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/revert/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesRevertPartialUpdate(requestParameters: V1ContractBillsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesVersionsRetrieveRaw(requestParameters: V1ContractBillsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBillFile>> {
        if (requestParameters.billPk === null || requestParameters.billPk === undefined) {
            throw new runtime.RequiredError('billPk','Required parameter requestParameters.billPk was null or undefined when calling v1ContractBillsFilesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{bill_pk}/files/{id}/versions/`.replace(`{${"bill_pk"}}`, encodeURIComponent(String(requestParameters.billPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsFilesVersionsRetrieve(requestParameters: V1ContractBillsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBillFile> {
        const response = await this.v1ContractBillsFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsRevertPartialUpdateRaw(requestParameters: V1ContractBillsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBillRequestToJSON(requestParameters.aPIPatchedBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsRevertPartialUpdate(requestParameters: V1ContractBillsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsVersionsRetrieveRaw(requestParameters: V1ContractBillsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsVersionsRetrieve(requestParameters: V1ContractBillsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsCompareVersionsCreateRaw(requestParameters: V1ContractContractsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsCompareVersionsCreate.');
        }

        if (requestParameters.aPIContractRequest === null || requestParameters.aPIContractRequest === undefined) {
            throw new runtime.RequiredError('aPIContractRequest','Required parameter requestParameters.aPIContractRequest was null or undefined when calling v1ContractContractsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIContractRequestToJSON(requestParameters.aPIContractRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsCompareVersionsCreate(requestParameters: V1ContractContractsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesCompareVersionsCreateRaw(requestParameters: V1ContractContractsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContractFile>> {
        if (requestParameters.contractPk === null || requestParameters.contractPk === undefined) {
            throw new runtime.RequiredError('contractPk','Required parameter requestParameters.contractPk was null or undefined when calling v1ContractContractsFilesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsFilesCompareVersionsCreate.');
        }

        if (requestParameters.contract === null || requestParameters.contract === undefined) {
            throw new runtime.RequiredError('contract','Required parameter requestParameters.contract was null or undefined when calling v1ContractContractsFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.contract !== undefined) {
            formParams.append('contract', requestParameters.contract as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/contracts/{contract_pk}/files/{id}/compare_versions/`.replace(`{${"contract_pk"}}`, encodeURIComponent(String(requestParameters.contractPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesCompareVersionsCreate(requestParameters: V1ContractContractsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContractFile> {
        const response = await this.v1ContractContractsFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesRevertPartialUpdateRaw(requestParameters: V1ContractContractsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContractFile>> {
        if (requestParameters.contractPk === null || requestParameters.contractPk === undefined) {
            throw new runtime.RequiredError('contractPk','Required parameter requestParameters.contractPk was null or undefined when calling v1ContractContractsFilesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.contract !== undefined) {
            formParams.append('contract', requestParameters.contract as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/contracts/{contract_pk}/files/{id}/revert/`.replace(`{${"contract_pk"}}`, encodeURIComponent(String(requestParameters.contractPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesRevertPartialUpdate(requestParameters: V1ContractContractsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContractFile> {
        const response = await this.v1ContractContractsFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesVersionsRetrieveRaw(requestParameters: V1ContractContractsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContractFile>> {
        if (requestParameters.contractPk === null || requestParameters.contractPk === undefined) {
            throw new runtime.RequiredError('contractPk','Required parameter requestParameters.contractPk was null or undefined when calling v1ContractContractsFilesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{contract_pk}/files/{id}/versions/`.replace(`{${"contract_pk"}}`, encodeURIComponent(String(requestParameters.contractPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsFilesVersionsRetrieve(requestParameters: V1ContractContractsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContractFile> {
        const response = await this.v1ContractContractsFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsRevertPartialUpdateRaw(requestParameters: V1ContractContractsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedContractRequestToJSON(requestParameters.aPIPatchedContractRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsRevertPartialUpdate(requestParameters: V1ContractContractsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsVersionsRetrieveRaw(requestParameters: V1ContractContractsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContract>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractContractsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/contracts/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractContractsVersionsRetrieve(requestParameters: V1ContractContractsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContract> {
        const response = await this.v1ContractContractsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesCompareVersionsCreateRaw(requestParameters: V1ContractFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContractFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractFilesCompareVersionsCreate.');
        }

        if (requestParameters.contract === null || requestParameters.contract === undefined) {
            throw new runtime.RequiredError('contract','Required parameter requestParameters.contract was null or undefined when calling v1ContractFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.contract !== undefined) {
            formParams.append('contract', requestParameters.contract as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesCompareVersionsCreate(requestParameters: V1ContractFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContractFile> {
        const response = await this.v1ContractFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesRevertPartialUpdateRaw(requestParameters: V1ContractFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContractFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.contract !== undefined) {
            formParams.append('contract', requestParameters.contract as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/contract/files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesRevertPartialUpdate(requestParameters: V1ContractFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContractFile> {
        const response = await this.v1ContractFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesVersionsRetrieveRaw(requestParameters: V1ContractFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContractFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContractFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractFilesVersionsRetrieve(requestParameters: V1ContractFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContractFile> {
        const response = await this.v1ContractFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesCompareVersionsCreateRaw(requestParameters: V1CustomerCustomersAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesCompareVersionsCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1CustomerCustomersAddressesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/compare_versions/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesCompareVersionsCreate(requestParameters: V1CustomerCustomersAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesRevertPartialUpdateRaw(requestParameters: V1CustomerCustomersAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/revert/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesRevertPartialUpdate(requestParameters: V1CustomerCustomersAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesVersionsRetrieveRaw(requestParameters: V1CustomerCustomersAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersAddressesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersAddressesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/addresses/{id}/versions/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersAddressesVersionsRetrieve(requestParameters: V1CustomerCustomersAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1CustomerCustomersAddressesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1GeneralFilesDestroyRaw(requestParameters: V1GeneralFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling v1GeneralFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/files/{file_name}`.replace(`{${"file_name"}}`, encodeURIComponent(String(requestParameters.fileName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralFilesDestroy(requestParameters: V1GeneralFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Return all articles that can safely be inserted as a bom child of the current article (pk) without producing circular bom relationships.
     */
    async v1ItemsArticlesAllowedBomChildrenRetrieveRaw(requestParameters: V1ItemsArticlesAllowedBomChildrenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesAllowedBomChildrenRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/allowed_bom_children/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Return all articles that can safely be inserted as a bom child of the current article (pk) without producing circular bom relationships.
     */
    async v1ItemsArticlesAllowedBomChildrenRetrieve(requestParameters: V1ItemsArticlesAllowedBomChildrenRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesAllowedBomChildrenRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return all articles that are ancestors to the specified article.
     */
    async v1ItemsArticlesAncestorsRetrieveRaw(requestParameters: V1ItemsArticlesAncestorsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesAncestorsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/ancestors/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Return all articles that are ancestors to the specified article.
     */
    async v1ItemsArticlesAncestorsRetrieve(requestParameters: V1ItemsArticlesAncestorsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesAncestorsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesBomCompareVersionsCreateRaw(requestParameters: V1ItemsArticlesBomCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBOM>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesBomCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesBomCompareVersionsCreate.');
        }

        if (requestParameters.aPIBOMRequest === null || requestParameters.aPIBOMRequest === undefined) {
            throw new runtime.RequiredError('aPIBOMRequest','Required parameter requestParameters.aPIBOMRequest was null or undefined when calling v1ItemsArticlesBomCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/bom/{id}/compare_versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBOMRequestToJSON(requestParameters.aPIBOMRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBOMFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesBomCompareVersionsCreate(requestParameters: V1ItemsArticlesBomCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBOM> {
        const response = await this.v1ItemsArticlesBomCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesBomRevertPartialUpdateRaw(requestParameters: V1ItemsArticlesBomRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBOM>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesBomRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesBomRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/bom/{id}/revert/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBOMRequestToJSON(requestParameters.aPIPatchedBOMRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBOMFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesBomRevertPartialUpdate(requestParameters: V1ItemsArticlesBomRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBOM> {
        const response = await this.v1ItemsArticlesBomRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesBomVersionsRetrieveRaw(requestParameters: V1ItemsArticlesBomVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBOM>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesBomVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesBomVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/bom/{id}/versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBOMFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesBomVersionsRetrieve(requestParameters: V1ItemsArticlesBomVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBOM> {
        const response = await this.v1ItemsArticlesBomVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesCompareVersionsCreateRaw(requestParameters: V1ItemsArticlesCategoriesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesCompareVersionsCreate.');
        }

        if (requestParameters.aPICategoryRequest === null || requestParameters.aPICategoryRequest === undefined) {
            throw new runtime.RequiredError('aPICategoryRequest','Required parameter requestParameters.aPICategoryRequest was null or undefined when calling v1ItemsArticlesCategoriesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/compare_versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICategoryRequestToJSON(requestParameters.aPICategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesCompareVersionsCreate(requestParameters: V1ItemsArticlesCategoriesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesRevertPartialUpdateRaw(requestParameters: V1ItemsArticlesCategoriesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/revert/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCategoryRequestToJSON(requestParameters.aPIPatchedCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesRevertPartialUpdate(requestParameters: V1ItemsArticlesCategoriesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesVersionsRetrieveRaw(requestParameters: V1ItemsArticlesCategoriesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesCategoriesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCategoriesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/categories/{id}/versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCategoriesVersionsRetrieve(requestParameters: V1ItemsArticlesCategoriesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsArticlesCategoriesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCompareVersionsCreateRaw(requestParameters: V1ItemsArticlesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesCompareVersionsCreate.');
        }

        if (requestParameters.aPIArticleRequest === null || requestParameters.aPIArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIArticleRequest','Required parameter requestParameters.aPIArticleRequest was null or undefined when calling v1ItemsArticlesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleRequestToJSON(requestParameters.aPIArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesCompareVersionsCreate(requestParameters: V1ItemsArticlesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesCompareVersionsCreateRaw(requestParameters: V1ItemsArticlesFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesCompareVersionsCreate.');
        }

        if (requestParameters.article === null || requestParameters.article === undefined) {
            throw new runtime.RequiredError('article','Required parameter requestParameters.article was null or undefined when calling v1ItemsArticlesFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/compare_versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesCompareVersionsCreate(requestParameters: V1ItemsArticlesFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesRevertPartialUpdateRaw(requestParameters: V1ItemsArticlesFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/revert/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesRevertPartialUpdate(requestParameters: V1ItemsArticlesFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesVersionsRetrieveRaw(requestParameters: V1ItemsArticlesFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesFilesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/files/{id}/versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesFilesVersionsRetrieve(requestParameters: V1ItemsArticlesFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsArticlesFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesCompareVersionsCreateRaw(requestParameters: V1ItemsArticlesPropertiesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesCompareVersionsCreate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesCompareVersionsCreate.');
        }

        if (requestParameters.aPIPropertyRequest === null || requestParameters.aPIPropertyRequest === undefined) {
            throw new runtime.RequiredError('aPIPropertyRequest','Required parameter requestParameters.aPIPropertyRequest was null or undefined when calling v1ItemsArticlesPropertiesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/compare_versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIPropertyRequestToJSON(requestParameters.aPIPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesCompareVersionsCreate(requestParameters: V1ItemsArticlesPropertiesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesRevertPartialUpdateRaw(requestParameters: V1ItemsArticlesPropertiesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesRevertPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/revert/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedPropertyRequestToJSON(requestParameters.aPIPatchedPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesRevertPartialUpdate(requestParameters: V1ItemsArticlesPropertiesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesVersionsRetrieveRaw(requestParameters: V1ItemsArticlesPropertiesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.articlePk === null || requestParameters.articlePk === undefined) {
            throw new runtime.RequiredError('articlePk','Required parameter requestParameters.articlePk was null or undefined when calling v1ItemsArticlesPropertiesVersionsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesPropertiesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{article_pk}/properties/{id}/versions/`.replace(`{${"article_pk"}}`, encodeURIComponent(String(requestParameters.articlePk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesPropertiesVersionsRetrieve(requestParameters: V1ItemsArticlesPropertiesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsArticlesPropertiesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesRevertPartialUpdateRaw(requestParameters: V1ItemsArticlesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedArticleRequestToJSON(requestParameters.aPIPatchedArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesRevertPartialUpdate(requestParameters: V1ItemsArticlesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesVersionsRetrieveRaw(requestParameters: V1ItemsArticlesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsArticlesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/articles/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsArticlesVersionsRetrieve(requestParameters: V1ItemsArticlesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsArticlesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesCompareVersionsCreateRaw(requestParameters: V1ItemsBarcodeTypesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesCompareVersionsCreate.');
        }

        if (requestParameters.aPIBarcodeTypeRequest === null || requestParameters.aPIBarcodeTypeRequest === undefined) {
            throw new runtime.RequiredError('aPIBarcodeTypeRequest','Required parameter requestParameters.aPIBarcodeTypeRequest was null or undefined when calling v1ItemsBarcodeTypesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBarcodeTypeRequestToJSON(requestParameters.aPIBarcodeTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesCompareVersionsCreate(requestParameters: V1ItemsBarcodeTypesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesRevertPartialUpdateRaw(requestParameters: V1ItemsBarcodeTypesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBarcodeTypeRequestToJSON(requestParameters.aPIPatchedBarcodeTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesRevertPartialUpdate(requestParameters: V1ItemsBarcodeTypesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesVersionsRetrieveRaw(requestParameters: V1ItemsBarcodeTypesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBarcodeType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBarcodeTypesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/barcode_types/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBarcodeTypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBarcodeTypesVersionsRetrieve(requestParameters: V1ItemsBarcodeTypesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBarcodeType> {
        const response = await this.v1ItemsBarcodeTypesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesCompareVersionsCreateRaw(requestParameters: V1ItemsBomBasesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesCompareVersionsCreate.');
        }

        if (requestParameters.aPIArticleRequest === null || requestParameters.aPIArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIArticleRequest','Required parameter requestParameters.aPIArticleRequest was null or undefined when calling v1ItemsBomBasesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIArticleRequestToJSON(requestParameters.aPIArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesCompareVersionsCreate(requestParameters: V1ItemsBomBasesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesRevertPartialUpdateRaw(requestParameters: V1ItemsBomBasesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedArticleRequestToJSON(requestParameters.aPIPatchedArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesRevertPartialUpdate(requestParameters: V1ItemsBomBasesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesVersionsRetrieveRaw(requestParameters: V1ItemsBomBasesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomBasesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/bom_bases/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomBasesVersionsRetrieve(requestParameters: V1ItemsBomBasesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1ItemsBomBasesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomsCompareVersionsCreateRaw(requestParameters: V1ItemsBomsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBOM>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomsCompareVersionsCreate.');
        }

        if (requestParameters.aPIBOMRequest === null || requestParameters.aPIBOMRequest === undefined) {
            throw new runtime.RequiredError('aPIBOMRequest','Required parameter requestParameters.aPIBOMRequest was null or undefined when calling v1ItemsBomsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/boms/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBOMRequestToJSON(requestParameters.aPIBOMRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBOMFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomsCompareVersionsCreate(requestParameters: V1ItemsBomsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBOM> {
        const response = await this.v1ItemsBomsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomsRevertPartialUpdateRaw(requestParameters: V1ItemsBomsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBOM>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/boms/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBOMRequestToJSON(requestParameters.aPIPatchedBOMRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBOMFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomsRevertPartialUpdate(requestParameters: V1ItemsBomsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBOM> {
        const response = await this.v1ItemsBomsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomsVersionsRetrieveRaw(requestParameters: V1ItemsBomsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBOM>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBomsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/boms/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBOMFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBomsVersionsRetrieve(requestParameters: V1ItemsBomsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBOM> {
        const response = await this.v1ItemsBomsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsCompareVersionsCreateRaw(requestParameters: V1ItemsBrandsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsCompareVersionsCreate.');
        }

        if (requestParameters.aPIBrandRequest === null || requestParameters.aPIBrandRequest === undefined) {
            throw new runtime.RequiredError('aPIBrandRequest','Required parameter requestParameters.aPIBrandRequest was null or undefined when calling v1ItemsBrandsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBrandRequestToJSON(requestParameters.aPIBrandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsCompareVersionsCreate(requestParameters: V1ItemsBrandsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsRevertPartialUpdateRaw(requestParameters: V1ItemsBrandsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBrandRequestToJSON(requestParameters.aPIPatchedBrandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsRevertPartialUpdate(requestParameters: V1ItemsBrandsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsVersionsRetrieveRaw(requestParameters: V1ItemsBrandsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsVersionsRetrieve(requestParameters: V1ItemsBrandsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesCompareVersionsCreateRaw(requestParameters: V1ItemsCategoriesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesCompareVersionsCreate.');
        }

        if (requestParameters.aPICategoryRequest === null || requestParameters.aPICategoryRequest === undefined) {
            throw new runtime.RequiredError('aPICategoryRequest','Required parameter requestParameters.aPICategoryRequest was null or undefined when calling v1ItemsCategoriesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICategoryRequestToJSON(requestParameters.aPICategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesCompareVersionsCreate(requestParameters: V1ItemsCategoriesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesRevertPartialUpdateRaw(requestParameters: V1ItemsCategoriesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCategoryRequestToJSON(requestParameters.aPIPatchedCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesRevertPartialUpdate(requestParameters: V1ItemsCategoriesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesVersionsRetrieveRaw(requestParameters: V1ItemsCategoriesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsCategoriesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/categories/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICategoryFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsCategoriesVersionsRetrieve(requestParameters: V1ItemsCategoriesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICategory> {
        const response = await this.v1ItemsCategoriesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesCompareVersionsCreateRaw(requestParameters: V1ItemsFamiliesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesCompareVersionsCreate.');
        }

        if (requestParameters.aPIFamilyRequest === null || requestParameters.aPIFamilyRequest === undefined) {
            throw new runtime.RequiredError('aPIFamilyRequest','Required parameter requestParameters.aPIFamilyRequest was null or undefined when calling v1ItemsFamiliesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIFamilyRequestToJSON(requestParameters.aPIFamilyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesCompareVersionsCreate(requestParameters: V1ItemsFamiliesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesRevertPartialUpdateRaw(requestParameters: V1ItemsFamiliesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedFamilyRequestToJSON(requestParameters.aPIPatchedFamilyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesRevertPartialUpdate(requestParameters: V1ItemsFamiliesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesVersionsRetrieveRaw(requestParameters: V1ItemsFamiliesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFamily>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFamiliesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/families/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFamilyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFamiliesVersionsRetrieve(requestParameters: V1ItemsFamiliesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFamily> {
        const response = await this.v1ItemsFamiliesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesCompareVersionsCreateRaw(requestParameters: V1ItemsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesCompareVersionsCreate.');
        }

        if (requestParameters.article === null || requestParameters.article === undefined) {
            throw new runtime.RequiredError('article','Required parameter requestParameters.article was null or undefined when calling v1ItemsFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesCompareVersionsCreate(requestParameters: V1ItemsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesRevertPartialUpdateRaw(requestParameters: V1ItemsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.article !== undefined) {
            formParams.append('article', requestParameters.article as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/items/files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesRevertPartialUpdate(requestParameters: V1ItemsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesVersionsRetrieveRaw(requestParameters: V1ItemsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticleFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFilesVersionsRetrieve(requestParameters: V1ItemsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticleFile> {
        const response = await this.v1ItemsFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesCompareVersionsCreateRaw(requestParameters: V1ItemsPropertiesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesCompareVersionsCreate.');
        }

        if (requestParameters.aPIPropertyRequest === null || requestParameters.aPIPropertyRequest === undefined) {
            throw new runtime.RequiredError('aPIPropertyRequest','Required parameter requestParameters.aPIPropertyRequest was null or undefined when calling v1ItemsPropertiesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIPropertyRequestToJSON(requestParameters.aPIPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesCompareVersionsCreate(requestParameters: V1ItemsPropertiesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesRevertPartialUpdateRaw(requestParameters: V1ItemsPropertiesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedPropertyRequestToJSON(requestParameters.aPIPatchedPropertyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesRevertPartialUpdate(requestParameters: V1ItemsPropertiesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesVersionsRetrieveRaw(requestParameters: V1ItemsPropertiesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIProperty>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsPropertiesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/properties/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPropertyFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsPropertiesVersionsRetrieve(requestParameters: V1ItemsPropertiesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIProperty> {
        const response = await this.v1ItemsPropertiesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View that returns all sharepoints that are used to share unrelated articles.
     */
    async v1ItemsSharepointsArticleSharesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepoint>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/article_shares/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointFromJSON(jsonValue));
    }

    /**
     * View that returns all sharepoints that are used to share unrelated articles.
     */
    async v1ItemsSharepointsArticleSharesRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepoint> {
        const response = await this.v1ItemsSharepointsArticleSharesRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsAutomaticSharesRetrieveRaw(requestParameters: V1ItemsSharepointsAutomaticSharesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsSharepointsAutomaticSharesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/{id}/automatic_shares/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestUserFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsAutomaticSharesRetrieve(requestParameters: V1ItemsSharepointsAutomaticSharesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestUser> {
        const response = await this.v1ItemsSharepointsAutomaticSharesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * View that returns all sharepoints that are used to share BOMs.
     */
    async v1ItemsSharepointsBomSharesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepoint>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/bom_shares/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointFromJSON(jsonValue));
    }

    /**
     * View that returns all sharepoints that are used to share BOMs.
     */
    async v1ItemsSharepointsBomSharesRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepoint> {
        const response = await this.v1ItemsSharepointsBomSharesRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * View that returns all sharepoints that are used to share guest requests (through guest-request-articles).
     */
    async v1ItemsSharepointsGuestRequestSharesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISharepoint>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/guest_request_shares/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISharepointFromJSON(jsonValue));
    }

    /**
     * View that returns all sharepoints that are used to share guest requests (through guest-request-articles).
     */
    async v1ItemsSharepointsGuestRequestSharesRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISharepoint> {
        const response = await this.v1ItemsSharepointsGuestRequestSharesRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsManualSharesRetrieveRaw(requestParameters: V1ItemsSharepointsManualSharesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsSharepointsManualSharesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/sharepoints/{id}/manual_shares/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestUserFromJSON(jsonValue));
    }

    /**
     * ViewSet required to enable the Sharepoint feature. A Sharepoint is intended to enable users to share articles with people outside of their organization/root_account. This is possible by creating guest users with very limited access permissions within their organization, that can be used by others to view specified data within that organization.  A sharepoint can be configured to share single articles, multiple articles, and BOMs containing multiple articles. Further it is possible to share guest requests by creating an article representation of that request (that article and guest request should then be linked via the ForeignKey relationship on the article).
     */
    async v1ItemsSharepointsManualSharesRetrieve(requestParameters: V1ItemsSharepointsManualSharesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestUser> {
        const response = await this.v1ItemsSharepointsManualSharesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesCompareVersionsCreateRaw(requestParameters: V1ItemsTypesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesCompareVersionsCreate.');
        }

        if (requestParameters.aPITypeRequest === null || requestParameters.aPITypeRequest === undefined) {
            throw new runtime.RequiredError('aPITypeRequest','Required parameter requestParameters.aPITypeRequest was null or undefined when calling v1ItemsTypesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APITypeRequestToJSON(requestParameters.aPITypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesCompareVersionsCreate(requestParameters: V1ItemsTypesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesRevertPartialUpdateRaw(requestParameters: V1ItemsTypesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedTypeRequestToJSON(requestParameters.aPIPatchedTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesRevertPartialUpdate(requestParameters: V1ItemsTypesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesVersionsRetrieveRaw(requestParameters: V1ItemsTypesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesVersionsRetrieve(requestParameters: V1ItemsTypesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteCompareVersionsCreateRaw(requestParameters: V1ItemsWasteCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWasteCompareVersionsCreate.');
        }

        if (requestParameters.aPIWasteRequest === null || requestParameters.aPIWasteRequest === undefined) {
            throw new runtime.RequiredError('aPIWasteRequest','Required parameter requestParameters.aPIWasteRequest was null or undefined when calling v1ItemsWasteCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIWasteRequestToJSON(requestParameters.aPIWasteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteCompareVersionsCreate(requestParameters: V1ItemsWasteCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWasteCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteRevertPartialUpdateRaw(requestParameters: V1ItemsWasteRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWasteRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedWasteRequestToJSON(requestParameters.aPIPatchedWasteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteRevertPartialUpdate(requestParameters: V1ItemsWasteRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWasteRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteVersionsRetrieveRaw(requestParameters: V1ItemsWasteVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWasteVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteVersionsRetrieve(requestParameters: V1ItemsWasteVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWasteVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsCompareVersionsCreateRaw(requestParameters: V1LogisticsInventoryMovementsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsCompareVersionsCreate.');
        }

        if (requestParameters.aPIInventoryMovementRequest === null || requestParameters.aPIInventoryMovementRequest === undefined) {
            throw new runtime.RequiredError('aPIInventoryMovementRequest','Required parameter requestParameters.aPIInventoryMovementRequest was null or undefined when calling v1LogisticsInventoryMovementsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIInventoryMovementRequestToJSON(requestParameters.aPIInventoryMovementRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsCompareVersionsCreate(requestParameters: V1LogisticsInventoryMovementsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsRevertPartialUpdateRaw(requestParameters: V1LogisticsInventoryMovementsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedInventoryMovementRequestToJSON(requestParameters.aPIPatchedInventoryMovementRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsRevertPartialUpdate(requestParameters: V1LogisticsInventoryMovementsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsVersionsRetrieveRaw(requestParameters: V1LogisticsInventoryMovementsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIInventoryMovement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsInventoryMovementsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/inventory_movements/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIInventoryMovementFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsInventoryMovementsVersionsRetrieve(requestParameters: V1LogisticsInventoryMovementsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIInventoryMovement> {
        const response = await this.v1LogisticsInventoryMovementsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesCompareVersionsCreateRaw(requestParameters: V1LogisticsStorageArticlesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesCompareVersionsCreate.');
        }

        if (requestParameters.aPIStorageArticleRequest === null || requestParameters.aPIStorageArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIStorageArticleRequest','Required parameter requestParameters.aPIStorageArticleRequest was null or undefined when calling v1LogisticsStorageArticlesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStorageArticleRequestToJSON(requestParameters.aPIStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesCompareVersionsCreate(requestParameters: V1LogisticsStorageArticlesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesRelocateCreateRaw(requestParameters: V1LogisticsStorageArticlesRelocateCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesRelocateCreate.');
        }

        if (requestParameters.aPIStorageArticleRequest === null || requestParameters.aPIStorageArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIStorageArticleRequest','Required parameter requestParameters.aPIStorageArticleRequest was null or undefined when calling v1LogisticsStorageArticlesRelocateCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/relocate/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStorageArticleRequestToJSON(requestParameters.aPIStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesRelocateCreate(requestParameters: V1LogisticsStorageArticlesRelocateCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesRelocateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesRevertPartialUpdateRaw(requestParameters: V1LogisticsStorageArticlesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedStorageArticleRequestToJSON(requestParameters.aPIPatchedStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesRevertPartialUpdate(requestParameters: V1LogisticsStorageArticlesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesStoreCreateRaw(requestParameters: V1LogisticsStorageArticlesStoreCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.aPIStorageArticleRequest === null || requestParameters.aPIStorageArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIStorageArticleRequest','Required parameter requestParameters.aPIStorageArticleRequest was null or undefined when calling v1LogisticsStorageArticlesStoreCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/store/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStorageArticleRequestToJSON(requestParameters.aPIStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesStoreCreate(requestParameters: V1LogisticsStorageArticlesStoreCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesStoreCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesVersionsRetrieveRaw(requestParameters: V1LogisticsStorageArticlesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesVersionsRetrieve(requestParameters: V1LogisticsStorageArticlesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesWithdrawCreateRaw(requestParameters: V1LogisticsStorageArticlesWithdrawCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesWithdrawCreate.');
        }

        if (requestParameters.aPIStorageArticleRequest === null || requestParameters.aPIStorageArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIStorageArticleRequest','Required parameter requestParameters.aPIStorageArticleRequest was null or undefined when calling v1LogisticsStorageArticlesWithdrawCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/withdraw/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStorageArticleRequestToJSON(requestParameters.aPIStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesWithdrawCreate(requestParameters: V1LogisticsStorageArticlesWithdrawCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesWithdrawCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsCompareVersionsCreateRaw(requestParameters: V1LogisticsStoragePositionsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsCompareVersionsCreate.');
        }

        if (requestParameters.aPIStoragePositionRequest === null || requestParameters.aPIStoragePositionRequest === undefined) {
            throw new runtime.RequiredError('aPIStoragePositionRequest','Required parameter requestParameters.aPIStoragePositionRequest was null or undefined when calling v1LogisticsStoragePositionsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStoragePositionRequestToJSON(requestParameters.aPIStoragePositionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsCompareVersionsCreate(requestParameters: V1LogisticsStoragePositionsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsRevertPartialUpdateRaw(requestParameters: V1LogisticsStoragePositionsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedStoragePositionRequestToJSON(requestParameters.aPIPatchedStoragePositionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsRevertPartialUpdate(requestParameters: V1LogisticsStoragePositionsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsVersionsRetrieveRaw(requestParameters: V1LogisticsStoragePositionsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStoragePosition>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStoragePositionsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_positions/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStoragePositionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStoragePositionsVersionsRetrieve(requestParameters: V1LogisticsStoragePositionsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStoragePosition> {
        const response = await this.v1LogisticsStoragePositionsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressCompareVersionsCreateRaw(requestParameters: V1OrganizationsOrganizationBillingAddressCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressCompareVersionsCreate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressCompareVersionsCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1OrganizationsOrganizationBillingAddressCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressCompareVersionsCreate(requestParameters: V1OrganizationsOrganizationBillingAddressCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressRevertPartialUpdateRaw(requestParameters: V1OrganizationsOrganizationBillingAddressRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressRevertPartialUpdate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressRevertPartialUpdate(requestParameters: V1OrganizationsOrganizationBillingAddressRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressVersionsRetrieveRaw(requestParameters: V1OrganizationsOrganizationBillingAddressVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressVersionsRetrieve.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressVersionsRetrieve(requestParameters: V1OrganizationsOrganizationBillingAddressVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationCompareVersionsCreateRaw(requestParameters: V1OrganizationsOrganizationCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationCompareVersionsCreate.');
        }

        if (requestParameters.aPIOrganizationRequest === null || requestParameters.aPIOrganizationRequest === undefined) {
            throw new runtime.RequiredError('aPIOrganizationRequest','Required parameter requestParameters.aPIOrganizationRequest was null or undefined when calling v1OrganizationsOrganizationCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOrganizationRequestToJSON(requestParameters.aPIOrganizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationCompareVersionsCreate(requestParameters: V1OrganizationsOrganizationCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationRevertPartialUpdateRaw(requestParameters: V1OrganizationsOrganizationRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedOrganizationRequestToJSON(requestParameters.aPIPatchedOrganizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationRevertPartialUpdate(requestParameters: V1OrganizationsOrganizationRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressCompareVersionsCreateRaw(requestParameters: V1OrganizationsOrganizationShippingAddressCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressCompareVersionsCreate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressCompareVersionsCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1OrganizationsOrganizationShippingAddressCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressCompareVersionsCreate(requestParameters: V1OrganizationsOrganizationShippingAddressCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressRevertPartialUpdateRaw(requestParameters: V1OrganizationsOrganizationShippingAddressRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressRevertPartialUpdate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressRevertPartialUpdate(requestParameters: V1OrganizationsOrganizationShippingAddressRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressVersionsRetrieveRaw(requestParameters: V1OrganizationsOrganizationShippingAddressVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationShippingAddressVersionsRetrieve.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationShippingAddressVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/shipping_address/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationShippingAddressVersionsRetrieve(requestParameters: V1OrganizationsOrganizationShippingAddressVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationShippingAddressVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationVersionsRetrieveRaw(requestParameters: V1OrganizationsOrganizationVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationVersionsRetrieve(requestParameters: V1OrganizationsOrganizationVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestFilesCompareVersionsCreateRaw(requestParameters: V1RequestFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestFilesCompareVersionsCreate.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling v1RequestFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.request !== undefined) {
            formParams.append('request', requestParameters.request as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestFilesCompareVersionsCreate(requestParameters: V1RequestFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequestFile> {
        const response = await this.v1RequestFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestFilesRevertPartialUpdateRaw(requestParameters: V1RequestFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.request !== undefined) {
            formParams.append('request', requestParameters.request as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestFilesRevertPartialUpdate(requestParameters: V1RequestFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequestFile> {
        const response = await this.v1RequestFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestFilesVersionsRetrieveRaw(requestParameters: V1RequestFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestFilesVersionsRetrieve(requestParameters: V1RequestFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequestFile> {
        const response = await this.v1RequestFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesCompareVersionsCreateRaw(requestParameters: V1RequestOfferFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesCompareVersionsCreate.');
        }

        if (requestParameters.offer === null || requestParameters.offer === undefined) {
            throw new runtime.RequiredError('offer','Required parameter requestParameters.offer was null or undefined when calling v1RequestOfferFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offer_files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesCompareVersionsCreate(requestParameters: V1RequestOfferFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesRevertPartialUpdateRaw(requestParameters: V1RequestOfferFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offer_files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesRevertPartialUpdate(requestParameters: V1RequestOfferFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesVersionsRetrieveRaw(requestParameters: V1RequestOfferFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offer_files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesVersionsRetrieve(requestParameters: V1RequestOfferFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersCompareVersionsCreateRaw(requestParameters: V1RequestOffersCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersCompareVersionsCreate.');
        }

        if (requestParameters.aPIOfferRequest === null || requestParameters.aPIOfferRequest === undefined) {
            throw new runtime.RequiredError('aPIOfferRequest','Required parameter requestParameters.aPIOfferRequest was null or undefined when calling v1RequestOffersCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOfferRequestToJSON(requestParameters.aPIOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersCompareVersionsCreate(requestParameters: V1RequestOffersCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesCompareVersionsCreateRaw(requestParameters: V1RequestOffersFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesCompareVersionsCreate.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesCompareVersionsCreate.');
        }

        if (requestParameters.offer === null || requestParameters.offer === undefined) {
            throw new runtime.RequiredError('offer','Required parameter requestParameters.offer was null or undefined when calling v1RequestOffersFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesCompareVersionsCreate(requestParameters: V1RequestOffersFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesRevertPartialUpdateRaw(requestParameters: V1RequestOffersFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesRevertPartialUpdate.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesRevertPartialUpdate(requestParameters: V1RequestOffersFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesVersionsRetrieveRaw(requestParameters: V1RequestOffersFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesVersionsRetrieve.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesVersionsRetrieve(requestParameters: V1RequestOffersFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersRevertPartialUpdateRaw(requestParameters: V1RequestOffersRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedOfferRequestToJSON(requestParameters.aPIPatchedOfferRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersRevertPartialUpdate(requestParameters: V1RequestOffersRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersVersionsRetrieveRaw(requestParameters: V1RequestOffersVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOffer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersVersionsRetrieve(requestParameters: V1RequestOffersVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOffer> {
        const response = await this.v1RequestOffersVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsCompareVersionsCreateRaw(requestParameters: V1RequestRequestsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsCompareVersionsCreate.');
        }

        if (requestParameters.aPIRequestRequest === null || requestParameters.aPIRequestRequest === undefined) {
            throw new runtime.RequiredError('aPIRequestRequest','Required parameter requestParameters.aPIRequestRequest was null or undefined when calling v1RequestRequestsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIRequestRequestToJSON(requestParameters.aPIRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsCompareVersionsCreate(requestParameters: V1RequestRequestsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsFilesCompareVersionsCreateRaw(requestParameters: V1RequestRequestsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsFilesCompareVersionsCreate.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1RequestRequestsFilesCompareVersionsCreate.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling v1RequestRequestsFilesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.request !== undefined) {
            formParams.append('request', requestParameters.request as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/requests/{request_pk}/files/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsFilesCompareVersionsCreate(requestParameters: V1RequestRequestsFilesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequestFile> {
        const response = await this.v1RequestRequestsFilesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsFilesRevertPartialUpdateRaw(requestParameters: V1RequestRequestsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsFilesRevertPartialUpdate.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1RequestRequestsFilesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.request !== undefined) {
            formParams.append('request', requestParameters.request as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/requests/{request_pk}/files/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsFilesRevertPartialUpdate(requestParameters: V1RequestRequestsFilesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequestFile> {
        const response = await this.v1RequestRequestsFilesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsFilesVersionsRetrieveRaw(requestParameters: V1RequestRequestsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsFilesVersionsRetrieve.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1RequestRequestsFilesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{request_pk}/files/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsFilesVersionsRetrieve(requestParameters: V1RequestRequestsFilesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequestFile> {
        const response = await this.v1RequestRequestsFilesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsRevertPartialUpdateRaw(requestParameters: V1RequestRequestsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedRequestRequestToJSON(requestParameters.aPIPatchedRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsRevertPartialUpdate(requestParameters: V1RequestRequestsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsVersionsRetrieveRaw(requestParameters: V1RequestRequestsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestRequestsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/requests/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRequestFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestRequestsVersionsRetrieve(requestParameters: V1RequestRequestsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRequest> {
        const response = await this.v1RequestRequestsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1RetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1Retrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1RetrieveRaw(initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesCompareVersionsCreateRaw(requestParameters: V1SupplierSuppliersAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesCompareVersionsCreate.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesCompareVersionsCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1SupplierSuppliersAddressesCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesCompareVersionsCreate(requestParameters: V1SupplierSuppliersAddressesCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesRevertPartialUpdateRaw(requestParameters: V1SupplierSuppliersAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesRevertPartialUpdate.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesRevertPartialUpdate(requestParameters: V1SupplierSuppliersAddressesRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesVersionsRetrieveRaw(requestParameters: V1SupplierSuppliersAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersAddressesVersionsRetrieve.');
        }

        if (requestParameters.suppliersPk === null || requestParameters.suppliersPk === undefined) {
            throw new runtime.RequiredError('suppliersPk','Required parameter requestParameters.suppliersPk was null or undefined when calling v1SupplierSuppliersAddressesVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{suppliers_pk}/addresses/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"suppliers_pk"}}`, encodeURIComponent(String(requestParameters.suppliersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersAddressesVersionsRetrieve(requestParameters: V1SupplierSuppliersAddressesVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1SupplierSuppliersAddressesVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * return all articles that are supplied by the specified supplier
     */
    async v1SupplierSuppliersArticlesRetrieveRaw(requestParameters: V1SupplierSuppliersArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersArticlesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{id}/articles/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIArticleFromJSON(jsonValue));
    }

    /**
     * return all articles that are supplied by the specified supplier
     */
    async v1SupplierSuppliersArticlesRetrieve(requestParameters: V1SupplierSuppliersArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIArticle> {
        const response = await this.v1SupplierSuppliersArticlesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsCompareVersionsCreateRaw(requestParameters: V1SupportTicketsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsCompareVersionsCreate.');
        }

        if (requestParameters.aPITicketRequest === null || requestParameters.aPITicketRequest === undefined) {
            throw new runtime.RequiredError('aPITicketRequest','Required parameter requestParameters.aPITicketRequest was null or undefined when calling v1SupportTicketsCompareVersionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/compare_versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APITicketRequestToJSON(requestParameters.aPITicketRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsCompareVersionsCreate(requestParameters: V1SupportTicketsCompareVersionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsCompareVersionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsRevertPartialUpdateRaw(requestParameters: V1SupportTicketsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsRevertPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/revert/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedTicketRequestToJSON(requestParameters.aPIPatchedTicketRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsRevertPartialUpdate(requestParameters: V1SupportTicketsRevertPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsRevertPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsVersionsRetrieveRaw(requestParameters: V1SupportTicketsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsVersionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/versions/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsVersionsRetrieve(requestParameters: V1SupportTicketsVersionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsVersionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
