import { createContext, useContext } from "react";
import { Channel, Message, MessageCountObject, MessageObject } from "./types";
import { FetchKwargs, User } from "../../../utils/types";


type ChatContextType = {
    channels: Channel[];
    setChannels: (channels: Channel[]) => void;
    messages: MessageObject;
    newestMessage: Message | undefined;
    setMessages: (messages: MessageObject) => void;
    newMessages: MessageObject;
    setNewMessages: (messages: MessageObject) => void;
    countMessages: MessageCountObject;
    activeUser: User;
    visitedChannel: Channel | undefined;
    activeChannel: Channel | undefined;
    setActiveChannel: (channel: Channel) => void;
    fetchChannels: (args: FetchKwargs<Channel>) => void;
    createChannelMode: boolean;
    handleMobileCloseChatView: () => void;
    handleCreateChannelClick: () => void;
    handleChannelClick: (channel: Channel) => void;
    handleChannelDelete: (channel: Channel) => void;
    handleClose: () => void;
    selectedChannels: Channel[];
    selectedChannelLeave: Channel[];
    setSelectedChannels: (channels: Channel[]) => void;
    setSelectedChannelLeave: (channels: Channel[]) => void;
    setDeleteMultipleModal: (open: boolean) => void;
    leaveChatDialogOpen: boolean;
    setLeaveChatDialogOpen: (open: boolean) => void;
}

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function useChatContext() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
}