/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIGuestRequestFile,
    APIGuestRequestFileFromJSON,
    APIGuestRequestFileToJSON,
    APIPaginatedGuestRequestFileList,
    APIPaginatedGuestRequestFileListFromJSON,
    APIPaginatedGuestRequestFileListToJSON,
} from '../models';

export interface V1GuestRequestFilesCreateRequest {
    guestRequest: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1GuestRequestFilesDestroyRequest {
    id: number;
}

export interface V1GuestRequestFilesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1GuestRequestFilesPartialUpdateRequest {
    id: number;
    guestRequest?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1GuestRequestFilesRetrieveRequest {
    id: number;
}

export interface V1GuestRequestFilesUpdateRequest {
    id: number;
    guestRequest: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1GuestRequestRequestsFilesCreateRequest {
    requestPk: string;
    guestRequest: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1GuestRequestRequestsFilesDestroyRequest {
    id: number;
    requestPk: string;
}

export interface V1GuestRequestRequestsFilesListRequest {
    requestPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1GuestRequestRequestsFilesPartialUpdateRequest {
    id: number;
    requestPk: string;
    guestRequest?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1GuestRequestRequestsFilesRetrieveRequest {
    id: number;
    requestPk: string;
}

export interface V1GuestRequestRequestsFilesUpdateRequest {
    id: number;
    requestPk: string;
    guestRequest: string;
    key?: string | null;
    document?: Blob | null;
}

/**
 * 
 */
export class GuestRequestrequestFileApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesCreateRaw(requestParameters: V1GuestRequestFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.guestRequest === null || requestParameters.guestRequest === undefined) {
            throw new runtime.RequiredError('guestRequest','Required parameter requestParameters.guestRequest was null or undefined when calling v1GuestRequestFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/guest_request/files/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesCreate(requestParameters: V1GuestRequestFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesDestroyRaw(requestParameters: V1GuestRequestFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesDestroy(requestParameters: V1GuestRequestFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GuestRequestFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesListRaw(requestParameters: V1GuestRequestFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedGuestRequestFileList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/files/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedGuestRequestFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesList(requestParameters: V1GuestRequestFilesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedGuestRequestFileList> {
        const response = await this.v1GuestRequestFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesPartialUpdateRaw(requestParameters: V1GuestRequestFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/guest_request/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesPartialUpdate(requestParameters: V1GuestRequestFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesRetrieveRaw(requestParameters: V1GuestRequestFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesRetrieve(requestParameters: V1GuestRequestFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesUpdateRaw(requestParameters: V1GuestRequestFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestFilesUpdate.');
        }

        if (requestParameters.guestRequest === null || requestParameters.guestRequest === undefined) {
            throw new runtime.RequiredError('guestRequest','Required parameter requestParameters.guestRequest was null or undefined when calling v1GuestRequestFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/guest_request/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestFilesUpdate(requestParameters: V1GuestRequestFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesCreateRaw(requestParameters: V1GuestRequestRequestsFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1GuestRequestRequestsFilesCreate.');
        }

        if (requestParameters.guestRequest === null || requestParameters.guestRequest === undefined) {
            throw new runtime.RequiredError('guestRequest','Required parameter requestParameters.guestRequest was null or undefined when calling v1GuestRequestRequestsFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/guest_request/requests/{request_pk}/files/`.replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesCreate(requestParameters: V1GuestRequestRequestsFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestRequestsFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesDestroyRaw(requestParameters: V1GuestRequestRequestsFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsFilesDestroy.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1GuestRequestRequestsFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{request_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesDestroy(requestParameters: V1GuestRequestRequestsFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GuestRequestRequestsFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesListRaw(requestParameters: V1GuestRequestRequestsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedGuestRequestFileList>> {
        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1GuestRequestRequestsFilesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{request_pk}/files/`.replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedGuestRequestFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesList(requestParameters: V1GuestRequestRequestsFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedGuestRequestFileList> {
        const response = await this.v1GuestRequestRequestsFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesPartialUpdateRaw(requestParameters: V1GuestRequestRequestsFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsFilesPartialUpdate.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1GuestRequestRequestsFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/guest_request/requests/{request_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesPartialUpdate(requestParameters: V1GuestRequestRequestsFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestRequestsFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesRetrieveRaw(requestParameters: V1GuestRequestRequestsFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsFilesRetrieve.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1GuestRequestRequestsFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/guest_request/requests/{request_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesRetrieve(requestParameters: V1GuestRequestRequestsFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestRequestsFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesUpdateRaw(requestParameters: V1GuestRequestRequestsFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIGuestRequestFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GuestRequestRequestsFilesUpdate.');
        }

        if (requestParameters.requestPk === null || requestParameters.requestPk === undefined) {
            throw new runtime.RequiredError('requestPk','Required parameter requestParameters.requestPk was null or undefined when calling v1GuestRequestRequestsFilesUpdate.');
        }

        if (requestParameters.guestRequest === null || requestParameters.guestRequest === undefined) {
            throw new runtime.RequiredError('guestRequest','Required parameter requestParameters.guestRequest was null or undefined when calling v1GuestRequestRequestsFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.guestRequest !== undefined) {
            formParams.append('guest_request', requestParameters.guestRequest as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/guest_request/requests/{request_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"request_pk"}}`, encodeURIComponent(String(requestParameters.requestPk))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIGuestRequestFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1GuestRequestRequestsFilesUpdate(requestParameters: V1GuestRequestRequestsFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIGuestRequestFile> {
        const response = await this.v1GuestRequestRequestsFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
