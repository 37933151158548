/* -------------------------- Design imports start -------------------------- */
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import { Alert, AlertTitle, Grid } from '@mui/material'
import '../utils/styles.css'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { ReactElement } from 'react'
import LogTool from '../../../logger/logTools'
import ReactDiffViewer from 'react-diff-viewer'
import { useTranslation } from 'react-i18next'
import styles from '../../../components/inputs/utils/infoField.module.css'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  versionDifferences: any | undefined
  onConfirm: () => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function CompareVersionDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, versionDifferences } = props
  const log = new LogTool({ context: 'CompareVersionDrawer', enable: false, logLevel: 'warn' })
  const { t } = useTranslation(['common'])
  /* --------------------------- Non state data end --------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = () => {
    setOpen(false)
  }
  log.debug('versionDifferences', versionDifferences)

  const getFormattedStringFromJsonDiff = (jsonDiff: string): string => {
    log.debug('jsonDiff', jsonDiff)
    try {
      const jsonDiffObject = JSON.parse(jsonDiff)
      log.debug('jsonDiffObject', jsonDiffObject)
      let formattedString = ''
      if (typeof jsonDiffObject === 'number') {
        return jsonDiff.toString()
      }
      for (const key in jsonDiffObject) {
        if (Object.prototype.hasOwnProperty.call(jsonDiffObject, key)) {

          if(jsonDiffObject[key] != null && jsonDiffObject[key] != undefined && jsonDiffObject[key] != '' && key == 'process' || key == 'category') {
            formattedString += `${t('item:content.label.'+ key.toLowerCase())}: ${t('item:content.label.selectProcess.'+ jsonDiffObject[key].toLowerCase())},\n`
          }
          else {
            if(jsonDiffObject[key] != null && jsonDiffObject[key] != undefined && jsonDiffObject[key] != '') {
              formattedString += `${t('item:content.label.'+ key.toLowerCase())}: ${jsonDiffObject[key]},\n`}
          }
        } else {
          return jsonDiff
        }
      }

      // Entferne das letzte Komma und Leerzeichen
      formattedString = formattedString.slice(0, -2)

      return formattedString
    } catch (error) {
      return jsonDiff
    }
  }

  const getJsonStringFromDiff = (diff: string, delOrIns: 'del' | 'ins'): string => {
    if (delOrIns === 'del') {
      const html = document.createElement('div')
      html.innerHTML = diff
      html.querySelectorAll('del').forEach(del => del.replaceWith(del.textContent as string))
      html.querySelectorAll('ins').forEach(ins => ins.remove())
      let textContent = html.textContent || ''
      textContent = textContent.replace(/\*/g, '')
      textContent = textContent.replace(/\+/g, '')
      textContent = textContent.replace(/'/g, '"')
      textContent = textContent.replace(/\s+/g, '')
      log.debug('textContent', textContent)
      return getFormattedStringFromJsonDiff(textContent)
    }
    if (delOrIns === 'ins') {
      const html = document.createElement('div')
      html.innerHTML = diff
      html.querySelectorAll('ins').forEach(ins => ins.replaceWith(ins.textContent as string))
      html.querySelectorAll('del').forEach(del => del.remove())
      let textContent = html.textContent || ''
      textContent = textContent.replace(/\*/g, '')
      textContent = textContent.replace(/\+/g, '')
      textContent = textContent.replace(/'/g, '"')
      textContent = textContent.replace(/\s+/g, '')
      log.debug('textContent', textContent)
      return getFormattedStringFromJsonDiff(textContent)
    }
    return ''
  }

  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const renderDiffObject = (diff: string) : boolean | ReactElement => {
    const oldValue = getJsonStringFromDiff(diff, 'del')
    const newValue = getJsonStringFromDiff(diff, 'ins')
    log.debug('newValue', newValue, oldValue, 'oldValue')
    if (oldValue === newValue) {
      return false
    }
    const filterValueList = ['None', 'add', 'changedto', 'Falsechangedto', 'del', '']
    const newValueList = newValue.split(":").filter((val: string) => !filterValueList.includes(val))
    const oldValueList = oldValue.split(":").filter((val: string) => !filterValueList.includes(val))
    if(newValueList.length === 0 && oldValueList.length === 0) {
      return false
    }
    return (
      <ReactDiffViewer
        oldValue={oldValueList.join(":")}
        newValue={newValueList.join(":")}
        splitView={true}
        disableWordDiff={true}
        styles={{ wordDiff: { display: 'none' } }}
      />
    )
  }

  const renderDiffObjectForFullAmount = (diff: string) => {
    const oldValue = getJsonStringFromDiff(diff, 'del')
    const newValue = getJsonStringFromDiff(diff, 'ins')
    const values = newValue
      .split(': ')
      .slice(1)
      .map(val => parseInt(val.trim())) // Extrahiere und konvertiere die Zahlenwerte
    const sum = values.reduce((acc, curr) => acc + curr, 0) // Berechne die Summe der Zahlen

    log.debug('newValue', newValue)
    if (oldValue === newValue) {
      return
    }
    return (
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={sum.toString()} // Setze die Summe als neuen Wert
        splitView={true}
        disableWordDiff={true}
        styles={{ wordDiff: { display: 'none' } }}
      />
    )
  }

  const renderVersionDifferences = (compare_data: any[]) => {
    const filteredData = compare_data.filter(
      (compare: any) => compare.field !== 'items.Article.root_account'
    )
    return filteredData.map((compare: any) => {
      const field = compare.field.split('.').pop()
      const indexOfGreaterThan = field.indexOf('>')
      const fieldWithoutSpecialChars =
        indexOfGreaterThan !== -1 ? field.substring(0, indexOfGreaterThan) : field

      // Überprüfen, ob das Diff Tags für Löschungen oder Einfügungen enthält
      const hasDelInsTags = compare.diff.includes('<del>') || compare.diff.includes('<ins>')

      // Wenn keine Löschungs- oder Einfügungstags vorhanden sind, wird kein Label erzeugt
      if (!hasDelInsTags) {
        return (
          <div className={`${styles.root} ${styles.fullwidth}`}>
            <span className={styles.value_text}>{renderDiffObject(compare.diff)}</span>
          </div>
        )
      }

      if (compare.field == '<ManyToOneRel: logistics.storagearticle>') {
        return (
          <div className={`${styles.root} ${styles.fullwidth}`}>
            <label className={styles.label}>{t('item:content.label.amountInStock')}</label>
            <div className={styles.value_container}>
              <span className={styles.value_text}>
                {renderDiffObjectForFullAmount(compare.diff)}
              </span>
            </div>
          </div>
        )
      }

      // Wenn Löschungs- oder Einfügungstags vorhanden sind, wird das Label erzeugt
      return (
        <div className={`${styles.root} ${styles.fullwidth}`}>
          {renderDiffObject(compare.diff) !== false && (<label className={styles.label}>
            {t(`item:content.label.${fieldWithoutSpecialChars}`)}
          </label>)}
          <div className={styles.value_container}>
            <span className={styles.value_text}>{renderDiffObject(compare.diff)}</span>
          </div>
        </div>
      )
    })
  }

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <MultilevelDrawer
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      size="big"
      title={t('common:content.label.compareVersions') as string}
      confirmButtonProps={{
        // hide the confirm button
        hidden: true,
        text: '',
        disabled: true,
        // do nothing on confirm
        onConfirm: async () => {},
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {versionDifferences && versionDifferences.compare_data.length > 0 ? (
            renderVersionDifferences(versionDifferences.compare_data)
          ) : (
            <Alert severity="info" color="warning">
              <AlertTitle>Info</AlertTitle>
              {t('item:feedback.info.noChangesInVersion')}
            </Alert>
          )}
        </Grid>
      </Grid>
    </MultilevelDrawer>
  )
}
