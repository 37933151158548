/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIBill,
    APIBillFromJSON,
    APIBillToJSON,
    APIBillRequest,
    APIBillRequestFromJSON,
    APIBillRequestToJSON,
    APIPaginatedBillList,
    APIPaginatedBillListFromJSON,
    APIPaginatedBillListToJSON,
    APIPatchedBillRequest,
    APIPatchedBillRequestFromJSON,
    APIPatchedBillRequestToJSON,
} from '../models';

export interface V1ContractBillsCreateRequest {
    aPIBillRequest: APIBillRequest;
}

export interface V1ContractBillsDestroyRequest {
    id: number;
}

export interface V1ContractBillsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ContractBillsPartialUpdateRequest {
    id: number;
    aPIPatchedBillRequest?: APIPatchedBillRequest;
}

export interface V1ContractBillsRetrieveRequest {
    id: number;
}

export interface V1ContractBillsUpdateRequest {
    id: number;
    aPIBillRequest: APIBillRequest;
}

/**
 * 
 */
export class ContractbillsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsCreateRaw(requestParameters: V1ContractBillsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.aPIBillRequest === null || requestParameters.aPIBillRequest === undefined) {
            throw new runtime.RequiredError('aPIBillRequest','Required parameter requestParameters.aPIBillRequest was null or undefined when calling v1ContractBillsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBillRequestToJSON(requestParameters.aPIBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsCreate(requestParameters: V1ContractBillsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsDestroyRaw(requestParameters: V1ContractBillsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsDestroy(requestParameters: V1ContractBillsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ContractBillsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsListRaw(requestParameters: V1ContractBillsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedBillList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedBillListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsList(requestParameters: V1ContractBillsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedBillList> {
        const response = await this.v1ContractBillsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsPartialUpdateRaw(requestParameters: V1ContractBillsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBillRequestToJSON(requestParameters.aPIPatchedBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsPartialUpdate(requestParameters: V1ContractBillsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsRetrieveRaw(requestParameters: V1ContractBillsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsRetrieve(requestParameters: V1ContractBillsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsUpdateRaw(requestParameters: V1ContractBillsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ContractBillsUpdate.');
        }

        if (requestParameters.aPIBillRequest === null || requestParameters.aPIBillRequest === undefined) {
            throw new runtime.RequiredError('aPIBillRequest','Required parameter requestParameters.aPIBillRequest was null or undefined when calling v1ContractBillsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/contract/bills/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIBillRequestToJSON(requestParameters.aPIBillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBillFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ContractBillsUpdate(requestParameters: V1ContractBillsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBill> {
        const response = await this.v1ContractBillsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
