/* -------------------------- Design imports start -------------------------- */
import Page from '../layout/Page'
import { Card, CardContent } from '@mui/material'
import { Skeleton } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  pagetitle: string
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function LoadingSkeleton(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { pagetitle } = props
  /* --------------------------- Non state data end --------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page title={pagetitle}>
      <h1>
        <Skeleton />
      </h1>
      <Card variant='outlined'>
        <CardContent>
          <Skeleton height={500} width={900} />
        </CardContent>
      </Card>
    </Page>
  )
}
