/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIMessage,
    APIMessageFromJSON,
    APIMessageToJSON,
    APIMessageRequest,
    APIMessageRequestFromJSON,
    APIMessageRequestToJSON,
    APIPaginatedMessageList,
    APIPaginatedMessageListFromJSON,
    APIPaginatedMessageListToJSON,
    APIPatchedMessageRequest,
    APIPatchedMessageRequestFromJSON,
    APIPatchedMessageRequestToJSON,
} from '../models';

export interface V1ChatChannelMessagesCreateRequest {
    channelPk: string;
    aPIMessageRequest: APIMessageRequest;
}

export interface V1ChatChannelMessagesDestroyRequest {
    channelPk: string;
    id: string;
}

export interface V1ChatChannelMessagesListRequest {
    channelPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1ChatChannelMessagesPartialUpdateRequest {
    channelPk: string;
    id: string;
    aPIPatchedMessageRequest?: APIPatchedMessageRequest;
}

export interface V1ChatChannelMessagesRetrieveRequest {
    channelPk: string;
    id: string;
}

export interface V1ChatChannelMessagesUpdateRequest {
    channelPk: string;
    id: string;
    aPIMessageRequest: APIMessageRequest;
}

/**
 * 
 */
export class ChatmessageApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesCreateRaw(requestParameters: V1ChatChannelMessagesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIMessage>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMessagesCreate.');
        }

        if (requestParameters.aPIMessageRequest === null || requestParameters.aPIMessageRequest === undefined) {
            throw new runtime.RequiredError('aPIMessageRequest','Required parameter requestParameters.aPIMessageRequest was null or undefined when calling v1ChatChannelMessagesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/messages/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIMessageRequestToJSON(requestParameters.aPIMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIMessageFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesCreate(requestParameters: V1ChatChannelMessagesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIMessage> {
        const response = await this.v1ChatChannelMessagesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesDestroyRaw(requestParameters: V1ChatChannelMessagesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMessagesDestroy.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMessagesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/messages/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesDestroy(requestParameters: V1ChatChannelMessagesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ChatChannelMessagesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesListRaw(requestParameters: V1ChatChannelMessagesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedMessageList>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMessagesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/messages/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedMessageListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesList(requestParameters: V1ChatChannelMessagesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedMessageList> {
        const response = await this.v1ChatChannelMessagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesPartialUpdateRaw(requestParameters: V1ChatChannelMessagesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIMessage>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMessagesPartialUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMessagesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/messages/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedMessageRequestToJSON(requestParameters.aPIPatchedMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIMessageFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesPartialUpdate(requestParameters: V1ChatChannelMessagesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIMessage> {
        const response = await this.v1ChatChannelMessagesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesRetrieveRaw(requestParameters: V1ChatChannelMessagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIMessage>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMessagesRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMessagesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/messages/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIMessageFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesRetrieve(requestParameters: V1ChatChannelMessagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIMessage> {
        const response = await this.v1ChatChannelMessagesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesUpdateRaw(requestParameters: V1ChatChannelMessagesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIMessage>> {
        if (requestParameters.channelPk === null || requestParameters.channelPk === undefined) {
            throw new runtime.RequiredError('channelPk','Required parameter requestParameters.channelPk was null or undefined when calling v1ChatChannelMessagesUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ChatChannelMessagesUpdate.');
        }

        if (requestParameters.aPIMessageRequest === null || requestParameters.aPIMessageRequest === undefined) {
            throw new runtime.RequiredError('aPIMessageRequest','Required parameter requestParameters.aPIMessageRequest was null or undefined when calling v1ChatChannelMessagesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/chat/channel/{channel_pk}/messages/{id}/`.replace(`{${"channel_pk"}}`, encodeURIComponent(String(requestParameters.channelPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIMessageRequestToJSON(requestParameters.aPIMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIMessageFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ChatChannelMessagesUpdate(requestParameters: V1ChatChannelMessagesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIMessage> {
        const response = await this.v1ChatChannelMessagesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
