/* -------------------------- Design imports start -------------------------- */
import styles from './utils/infoField.module.css'
import labelStyles from '../widgets/utils/fieldLabel.module.css'
/* --------------------------- Design imports end --------------------------- */

type Props = {
  label?: string | null
  value: string | undefined | null | any
  fullWidth?: boolean
  variant?: 'outlined' | 'underlined' | 'minimal'
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function InfoField(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { label, value, fullWidth = false, variant = 'minimal' } = props
  /* --------------------------- Non state data end --------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div id={label || ''} className={
      `${styles.root} ${fullWidth && styles.fullwidth}
      ${variant === 'outlined' && styles.outlined}
      ${variant === 'underlined' && styles.underlined}`
      }
    >
      {label && <label className={labelStyles.label}>{label}</label>}
      <div id={label || ''} className={styles.value_container}>
        <span className={styles.value_text}>{value || '-'}</span>
      </div>
    </div>
  )
}
