/* -------------------------- Design imports start -------------------------- */
import MultilevelDrawer from '../../components/layout/MultilevelDrawer'
import { Grid } from '@mui/material'
import { Form, FormFieldType } from '../Form'
/* -------------------------- Design imports end -------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { missingRequiredFormFields } from '../../utils/functions'
import GetConstant from './utils/constants'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onCancel?: () => void
  onCreateConfirm: (a: any) => Promise<void>
  enableEditMode?: boolean
  onEditConfirm?: (input: any) => Promise<void>
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function PropertyDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    onClose = () => null,
    formState,
    onCancel,
    onCreateConfirm = () => null,
    enableEditMode = false,
    onEditConfirm = () => alert('TODO: provide a onEditConfirm callback!'),
  } = props
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = (preventOnClose: boolean = false) => {
    // reset modal to initial state
    formState.setState({})
    setOpen(false)

    !preventOnClose && onClose()
  }

  const handleCreateConfirm = async () => {
    // posting request to server and waiting on response
    await onCreateConfirm({ ...formState.state, contacts: [formState.state.contact?.value] })
    handleClose(true)
  }

  const handleEditConfirm = async () => {
    // posting request to server and waiting on response
    await onEditConfirm(formState.state)
    handleClose(true)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const formFieldsProperty: FormFieldType[] = GetConstant({
    name: 'propertyFormFields',
  }) as FormFieldType[]
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <MultilevelDrawer
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      size="small"
      title={t('item:content.heading.property') as string}
      confirmButtonProps={{
        text: enableEditMode
          ? t('common:interaction.button.save')
          : t('common:interaction.button.create'),
        onConfirm: enableEditMode ? handleEditConfirm : handleCreateConfirm,
        disabled: missingRequiredFormFields(formFieldsProperty, formState.state),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Form
            editing={true}
            formFields={formFieldsProperty}
            formObject={formState.state}
            onChange={(value: any) => formState.setState(value)}
          />
        </Grid>
      </Grid>
    </MultilevelDrawer>
  )
}
