/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedSupplierList,
    APIPaginatedSupplierListFromJSON,
    APIPaginatedSupplierListToJSON,
    APIPatchedSupplierRequest,
    APIPatchedSupplierRequestFromJSON,
    APIPatchedSupplierRequestToJSON,
    APISupplier,
    APISupplierFromJSON,
    APISupplierToJSON,
    APISupplierRequest,
    APISupplierRequestFromJSON,
    APISupplierRequestToJSON,
} from '../models';

export interface V1SupplierSuppliersCreateRequest {
    aPISupplierRequest: APISupplierRequest;
}

export interface V1SupplierSuppliersDestroyRequest {
    id: number;
}

export interface V1SupplierSuppliersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1SupplierSuppliersPartialUpdateRequest {
    id: number;
    aPIPatchedSupplierRequest?: APIPatchedSupplierRequest;
}

export interface V1SupplierSuppliersRetrieveRequest {
    id: number;
}

export interface V1SupplierSuppliersUpdateRequest {
    id: number;
    aPISupplierRequest: APISupplierRequest;
}

/**
 * 
 */
export class SuppliersuppliersApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersCreateRaw(requestParameters: V1SupplierSuppliersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplier>> {
        if (requestParameters.aPISupplierRequest === null || requestParameters.aPISupplierRequest === undefined) {
            throw new runtime.RequiredError('aPISupplierRequest','Required parameter requestParameters.aPISupplierRequest was null or undefined when calling v1SupplierSuppliersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APISupplierRequestToJSON(requestParameters.aPISupplierRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplierFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersCreate(requestParameters: V1SupplierSuppliersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplier> {
        const response = await this.v1SupplierSuppliersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersDestroyRaw(requestParameters: V1SupplierSuppliersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersDestroy(requestParameters: V1SupplierSuppliersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1SupplierSuppliersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersListRaw(requestParameters: V1SupplierSuppliersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedSupplierList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedSupplierListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersList(requestParameters: V1SupplierSuppliersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedSupplierList> {
        const response = await this.v1SupplierSuppliersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersPartialUpdateRaw(requestParameters: V1SupplierSuppliersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedSupplierRequestToJSON(requestParameters.aPIPatchedSupplierRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplierFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersPartialUpdate(requestParameters: V1SupplierSuppliersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplier> {
        const response = await this.v1SupplierSuppliersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersRetrieveRaw(requestParameters: V1SupplierSuppliersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplierFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersRetrieve(requestParameters: V1SupplierSuppliersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplier> {
        const response = await this.v1SupplierSuppliersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersUpdateRaw(requestParameters: V1SupplierSuppliersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APISupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupplierSuppliersUpdate.');
        }

        if (requestParameters.aPISupplierRequest === null || requestParameters.aPISupplierRequest === undefined) {
            throw new runtime.RequiredError('aPISupplierRequest','Required parameter requestParameters.aPISupplierRequest was null or undefined when calling v1SupplierSuppliersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/supplier/suppliers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APISupplierRequestToJSON(requestParameters.aPISupplierRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APISupplierFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupplierSuppliersUpdate(requestParameters: V1SupplierSuppliersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APISupplier> {
        const response = await this.v1SupplierSuppliersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
