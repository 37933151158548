/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedStorageArticleList,
    APIPaginatedStorageArticleListFromJSON,
    APIPaginatedStorageArticleListToJSON,
    APIPatchedStorageArticleRequest,
    APIPatchedStorageArticleRequestFromJSON,
    APIPatchedStorageArticleRequestToJSON,
    APIStorageArticle,
    APIStorageArticleFromJSON,
    APIStorageArticleToJSON,
    APIStorageArticleRequest,
    APIStorageArticleRequestFromJSON,
    APIStorageArticleRequestToJSON,
} from '../models';

export interface V1LogisticsStorageArticlesCreateRequest {
    aPIStorageArticleRequest: APIStorageArticleRequest;
}

export interface V1LogisticsStorageArticlesDestroyRequest {
    id: number;
}

export interface V1LogisticsStorageArticlesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1LogisticsStorageArticlesPartialUpdateRequest {
    id: number;
    aPIPatchedStorageArticleRequest?: APIPatchedStorageArticleRequest;
}

export interface V1LogisticsStorageArticlesRetrieveRequest {
    id: number;
}

export interface V1LogisticsStorageArticlesUpdateRequest {
    id: number;
    aPIStorageArticleRequest: APIStorageArticleRequest;
}

/**
 * 
 */
export class LogisticsstorageArticlesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesCreateRaw(requestParameters: V1LogisticsStorageArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.aPIStorageArticleRequest === null || requestParameters.aPIStorageArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIStorageArticleRequest','Required parameter requestParameters.aPIStorageArticleRequest was null or undefined when calling v1LogisticsStorageArticlesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIStorageArticleRequestToJSON(requestParameters.aPIStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesCreate(requestParameters: V1LogisticsStorageArticlesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesDestroyRaw(requestParameters: V1LogisticsStorageArticlesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesDestroy(requestParameters: V1LogisticsStorageArticlesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1LogisticsStorageArticlesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesListRaw(requestParameters: V1LogisticsStorageArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedStorageArticleList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedStorageArticleListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesList(requestParameters: V1LogisticsStorageArticlesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedStorageArticleList> {
        const response = await this.v1LogisticsStorageArticlesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesPartialUpdateRaw(requestParameters: V1LogisticsStorageArticlesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedStorageArticleRequestToJSON(requestParameters.aPIPatchedStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesPartialUpdate(requestParameters: V1LogisticsStorageArticlesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesRetrieveRaw(requestParameters: V1LogisticsStorageArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesRetrieve(requestParameters: V1LogisticsStorageArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesUpdateRaw(requestParameters: V1LogisticsStorageArticlesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIStorageArticle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsStorageArticlesUpdate.');
        }

        if (requestParameters.aPIStorageArticleRequest === null || requestParameters.aPIStorageArticleRequest === undefined) {
            throw new runtime.RequiredError('aPIStorageArticleRequest','Required parameter requestParameters.aPIStorageArticleRequest was null or undefined when calling v1LogisticsStorageArticlesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/storage_articles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIStorageArticleRequestToJSON(requestParameters.aPIStorageArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIStorageArticleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1LogisticsStorageArticlesUpdate(requestParameters: V1LogisticsStorageArticlesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIStorageArticle> {
        const response = await this.v1LogisticsStorageArticlesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
