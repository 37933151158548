/* -------------------------- Design imports start -------------------------- */
import { TextField } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import formatDateString from '../../../Filesystem/utils/functions'
import MessageActions from './MessageActions'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  messageIndex: number
  onMessageEdit: (
    message: any,
    editValue: string,
    edit: boolean,
    setEdit: (edit: boolean) => void
  ) => void
  onMessageDelete: (message: any) => void
  message: any
  activeUser: any
  setShowMessageActions: (index: number) => void
  showMessageActions: number
  received: boolean
  cornerRadius?: number
}

/**
 * Component for a chat message
 * Has information about:
 *  - when the message was created/updated
 *  - the message text
 * Can be:
 *  - edited
 *  - deleted
 */

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function ChatMessage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    messageIndex,
    onMessageEdit,
    onMessageDelete,
    showMessageActions,
    setShowMessageActions,
    received,
    message,
    activeUser,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [edit, setEdit] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [editValue, setEditValue] = useState(message.body)
  const [touchTimeout, setTouchTimeout] = useState<any>(null)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleMouseEnter = () => {
    !received && setShowMessageActions(messageIndex)
  }

  const handleMouseLeave = () => {
    setShowMessageActions(-1)
  }

  const handleTouchStart = () => {
    const timeout = setTimeout(() => {
      !received && setShowMessageActions(messageIndex)
    }, 500) // 500ms Verzögerung für langes Drücken
    setTouchTimeout(timeout)
  }

  const handleTouchEnd = () => {
    if (touchTimeout) {
      clearTimeout(touchTimeout)
      setTouchTimeout(null)
    }
    setShowMessageActions(-1)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const messageStyle: React.CSSProperties = {
    backgroundColor: received ? '#16a9e4' : '#BFBFBF',
    borderRadius: '7px',
    padding: '8px',
    marginBottom: '8px',
    maxWidth: '70%',
    alignSelf: received ? 'flex-start' : 'flex-end',
  }

  const bodyStyle: React.CSSProperties = {
    color: received ?  'white' : 'black',
    fontSize: '15px',
    marginBottom: '2px',
  }

  const authorStyle: React.CSSProperties = {
    color: 'white',
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: '2px',
  }

  const timestampStyle: React.CSSProperties = {
    fontSize: '11px',
    color: received ?  'white' : 'black',
    marginTop: '4px',
    textAlign: 'right',
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div
        style={{
          height: '10px',
          width: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          alignSelf: received ? 'flex-start' : 'flex-end',
        }}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        onTouchStart={() => handleTouchStart()}
        onTouchCancel={() => handleTouchEnd()}
      >
        {(showMessageActions === messageIndex || edit) && (
          <MessageActions
            key={message.id}
            onMessageDelete={onMessageDelete}
            onMessageEdit={onMessageEdit}
            message={message}
            edit={edit}
            setShowMessageActions={setShowMessageActions}
            setEdit={setEdit}
            editValue={editValue}
            setEditValue={setEditValue}
            sx={{ alignSelf: received ? 'flex-start' : 'flex-end', marginBottom: '-5px' }}
          />
        )}
      </div>
      <div
        style={messageStyle}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        onTouchStart={() => handleTouchStart()}
        onTouchCancel={() => handleTouchEnd()}
      >
        {received && (
          <div
            style={authorStyle}
          >{message.author.firstName && message.author.lastName
              ?`${message.author.firstName} ${message.author.lastName}`
              :`${message.author.email}`}</div>
        )}
        {edit ? (
          <TextField
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            size="small"
            fullWidth
            multiline
            minRows={1}
            maxRows={4}
            autoFocus
            sx={{ color: 'white' }}
          />
        ) : (
          <div style={bodyStyle}>{message.body}</div>
        )}
        <div style={timestampStyle}>{formatDateString(message.created_at)}</div>
      </div>
    </>
  )
}
