/* -------------------------- Design imports start -------------------------- */
import { Stack, Box } from '@mui/material'
import DeleteModal from '../../../../components/widgets/DeleteModal'
import { Delete } from '@mui/icons-material'
import Edit from '@mui/icons-material/Edit'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CopyAllRoundedIcon from '@mui/icons-material/CopyAllRounded'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../../../hooks/useMobileService'
import { Message } from '../../utils/types'
import LogTool from '../../../../logger/logTools'
import Button from '../../../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  message: any
  onMessageDelete: (message: any) => void
  onMessageEdit: (
    message: any,
    editValue: string,
    edit: boolean,
    setEdit: (edit: boolean) => void
  ) => void
  setEdit: (edit: boolean) => void
  edit: boolean
  setEditValue: (value: string) => void
  editValue: string
  setShowMessageActions: (index: number) => void
  sx?: React.CSSProperties
}

/**
 * Actions for messages where messages can be deleted/edited
 */
/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function MessageActions(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    message,
    onMessageDelete,
    onMessageEdit,
    setEdit,
    edit,
    setEditValue,
    editValue,
    setShowMessageActions,
    sx,
  } = props
  const log = new LogTool({context: 'MessageActions', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['chat', 'common'])
  const isMobile = useMobileService()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  async function handleMessageCopy() {
    navigator.clipboard
      .writeText(message.body)
      .then(() => log.info('copied'))
      .catch(err => log.error(err))
  }

  /* -------------------------- Utility functions end ------------------------- */

  const disabledEdit = () => {
    if(edit && (editValue.length === 0 || editValue.trim().length === 0)){
      return true
    }
    if(message.body === t('chat:content.label.messageDeleted')){
      return true
    }
    return false
  }

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Box sx={{ ...sx, width: 'max-content', zIndex: 5 }}>
      <Stack spacing={1} direction="row" alignItems="center" sx={{ marginBottom: '8px' }}>
        {isMobile && !edit && (
          <Button
            id="closeButton"
            variant="contained"
            size="small"
            color='primary'
            onClick={() => setShowMessageActions(-1)}
            sx={{
              borderRadius: '2em',
              height: '1.7em',
              minWidth: 'max-content',

            }}
          >
            <CloseRoundedIcon fontSize="small" />
          </Button>
        )}
        {isMobile && !edit && (
          <Button
            id="copyButton"
            variant="contained"
            size="small"
            onClick={() => handleMessageCopy()}
            sx={{ borderRadius: '2em', height: '1.7em' }}
          >
            <CopyAllRoundedIcon fontSize="small" />
          </Button>
        )}
        <Button
          id="editButton"
          variant="contained"
          size="small"
          disabled={disabledEdit()} /* check if string is empty or only contains spaces*/
          onClick={() => onMessageEdit(message, editValue, edit, setEdit)}
          sx={{ borderRadius: '2em', height: '1.7em', }}
        >
          {edit ? <DoneRoundedIcon style={{color:'whitesmoke'}} fontSize="small" /> : <Edit fontSize="small" style={{color:'whitesmoke'}} />}
        </Button>
        <Button
          id='deleteButton'
          variant="contained"
          size="small"
          onClick={
            edit
              ? () => {
                  setEdit(false)
                  setEditValue(message.body)
                }
              : () => setOpenDeleteModal(!openDeleteModal)
          }
          disabled={disabledEdit()}
          sx={{ borderRadius: '2em', height: '1.7em' }}
        >
          {edit ? <CloseRoundedIcon style={{color:'whitesmoke'}} fontSize="small" /> : <Delete fontSize="small" style={{color:'whitesmoke'}} />}
        </Button>
      </Stack>
      <DeleteModal
        name={t('content.label.theMessage')}
        open={openDeleteModal}
        setOpen={() => setOpenDeleteModal(!openDeleteModal)}
        onDelete={() => {
          onMessageDelete(message)
          setOpenDeleteModal(!openDeleteModal)
        }}
      />
    </Box>
  )
}
