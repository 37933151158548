/* --------------------------- Design impors start -------------------------- */
import Page from '../../components/layout/Page'
import { Box, Grid, TextField, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { handleAPICallV1 } from '../../utils/functions'
import { HTTPMethod } from '../../utils/types'
import LogTool from '../../logger/logTools'
import logo from '../../assets/logo_black.svg'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ChangePasswordPage() {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'ChangePasswordPage', enable: true, logLevel: 'info' })
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  const { token } = useParams<{ token: string }>()
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['common'])
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [helperText, setHelperText] = useState('')
  const history = useHistory()
  /* ----------------------------- Data states end ---------------------------- */
  log.info('token', token)

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const passwordIsValid = (password: string) => {
    return password && password !== '' && passwordRegex.test(password)
  }

  const passwordsMatch = (password: string, passwordConfirm: string) => {
    return password === passwordConfirm
  }

  const handlePasswordChange = async (e: any) => {
    e.preventDefault()
    if (!passwordIsValid(password)) {
      setError(true)
      setHelperText(
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      )
      return
    } else if (!passwordsMatch(password, passwordConfirm)) {
      setError(true)
      setHelperText('Passwords do not match.')
      return
    }
    setError(false)

    const response = await fetch(
      process.env.REACT_APP_API_URL + 'auth/password_reset/validate_token/',
      {
        method: HTTPMethod.POST,
        body: JSON.stringify({
          token: token,
          password: password,
        }),
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    )
    if (response.status === 200) {
      setSuccess(true)
    } else {
      setError(true)
      setHelperText('Something went wrong. Please try again.')
    }
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        mt={4}
        height="100vh"
      >
        <Grid
          container
          maxWidth={600}
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} display="flex" justifyContent="center">
            <img
              src={logo}
              alt="logo"
              style={{width: '100px', height: '100px'}}
            />
          </Grid>
          {!success ? (
            <>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography display="block" variant="h5">
                  {t('content.auth.changePassword')}
                </Typography>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography display="block" variant="body1">
                  {t('content.auth.enterNewPassword')}
                </Typography>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                  id="password"
                  label={t('content.auth.password')}
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <TextField
                  id="passwordConfirm"
                  label={t('content.auth.passwordConfirm')}
                  type="password"
                  value={passwordConfirm}
                  onChange={e => setPasswordConfirm(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              {!error ? (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography display="block" variant="body1">
                    {t('contentauth.pwHelperText1')}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Typography display="block" variant="body1" color="error">
                    {helperText}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={handlePasswordChange}
                  fullWidth
                >
                  {t('content.auth.changePassword')}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography display="block" variant="h5">
                  {t('content.auth.passwordChangedSuccessfully')}
                </Typography>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography display="block" variant="body1">
                  {t('content.auth.passwordChangedText')}
                </Typography>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => history.push('/signIn')}
                  fullWidth
                >
                  {t('content.auth.goToSignIn')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Page>
  )
}
