/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Cookies from 'js-cookie'
/* ------------------------- Functional imports end ------------------------- */
interface Props {
  component?: any
  layout: any
  title?: string
  showBack?: boolean
  onBackClick?: () => void
  [x: string]: any
}

interface AuthProps extends Props {
  showSettings?: boolean
  user: any
  componentStaffView?: any
  guestUserRedirect?: string
}

export const PublicRoute = ({
  component: Component,
  layout: Layout,
  title,
  showBack,
  onBackClick,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={(props: { [x: string]: any }) => (
      <Layout title={title} showBack={showBack} onBackClick={onBackClick}>
        <Component {...props} />
      </Layout>
    )}
  />
)

export function AuthenticatedRoute(props: AuthProps) {
  const {
    user,
    layout: Layout,
    showSettings,
    showBack,
    onBackClick,
    title,
    component: Component,
    staffViewComponent: StaffViewComponent,
    guestUserRedirect,
    ...rest
  } = props
  return (
    <Route
      {...rest}
      render={props => {
        if( guestUserRedirect && (user && user.isGuestUser)) {
          return <Redirect to={{pathname: guestUserRedirect, state: {from: props.location}}} />
        }
        if ( user || Cookies.get('refresh')) {
          return (
            <Layout
              title={title}
              showBack={showBack}
              showSettings={showSettings}
              onBackClick={onBackClick}
            >
              {user && user.isStaff && StaffViewComponent
                ? <StaffViewComponent {...props} />
                : <Component {...props} />
              }
            </Layout>
          )
        }
        return <Redirect to={{ pathname: '/signIn', state: { from: props.location } }} />
      }}
    />
  )
}

