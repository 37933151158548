/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface V1GeneralFilesRetrieve2Request {
    fileName: string;
}

export interface V1GeneralFilesRetrieve3Request {
    id: string;
}

/**
 * 
 */
export class GeneralfilesApi extends runtime.BaseAPI {

    /**
     * Header: Content-Disposition: form-data; name=\"file\";\" Body: {file}
     */
    async v1GeneralFilesCreateRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/files/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Header: Content-Disposition: form-data; name=\"file\";\" Body: {file}
     */
    async v1GeneralFilesCreate(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralFilesCreateRaw(initOverrides);
    }

    /**
     */
    async v1GeneralFilesRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/files/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralFilesRetrieve(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralFilesRetrieveRaw(initOverrides);
    }

    /**
     */
    async v1GeneralFilesRetrieve2Raw(requestParameters: V1GeneralFilesRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling v1GeneralFilesRetrieve2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/files/{file_name}`.replace(`{${"file_name"}}`, encodeURIComponent(String(requestParameters.fileName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralFilesRetrieve2(requestParameters: V1GeneralFilesRetrieve2Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralFilesRetrieve2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async v1GeneralFilesRetrieve3Raw(requestParameters: V1GeneralFilesRetrieve3Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1GeneralFilesRetrieve3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/general/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1GeneralFilesRetrieve3(requestParameters: V1GeneralFilesRetrieve3Request, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1GeneralFilesRetrieve3Raw(requestParameters, initOverrides);
    }

}
