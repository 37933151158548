/* -------------------------- Design imports start -------------------------- */
import { Box, Chip, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Space } from 'antd'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table, { ColumnsType } from 'antd/es/table'
import FilterComponent, { FilterOptions } from '../../../components/widgets/FilterMenu'
import { DeleteOutlined, EditOutlined, InfoOutlined } from '@mui/icons-material'
import DeleteModal from '../../../components/widgets/DeleteModal'
import { toast } from 'react-toastify'
import LogTool from '../../../logger/logTools'
import {
  deleteRequests,
  fetchRequest,
  fetchRequests,
  inputToAPIOfferJSON, updateRequest
} from '../utils/functions'
import { Request } from '../utils/types'
import GetConstant from '../utils/constants'
import LoadingSkeleton from '../../../components/widgets/LoadingSkeleton'
import ColumnSelector from '../../../components/widgets/ColumnSelector'
import FilesDrawer from '../../../components/FilesDrawer'
import RequestDrawer from './RequestDrawer'
import RequestStaffDrawer from './RequestStaffDrawer'
import { canDelete, handleAPICallV1, isPrivileged } from '../../../utils/functions'
import { useCountContext, useUserContext } from '../../../utils/context'
import OfferDrawer from '../Offer/OfferDrawer'
import { HTTPMethod } from '../../../utils/types'
import Button from '../../../components/Button'
/* ------------------------- Functional import end ------------------------- */

type Props = {
  openInfoPanel: boolean
  setOpenInfoPanel: React.Dispatch<React.SetStateAction<boolean>>
  selectedRequest: Request | undefined
  setSelectedRequest: React.Dispatch<React.SetStateAction<Request | undefined>>
  openFilesDrawer: boolean
  setOpenFilesDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function RequestOverviewPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'RequestOverviewPage', enable: true, logLevel: 'warn' })
  const { t } = useTranslation()
  const {
    openInfoPanel,
    setOpenInfoPanel,
    selectedRequest,
    setSelectedRequest,
    openFilesDrawer,
    setOpenFilesDrawer,
  } = props
  const columnsData: ColumnsType = GetConstant({ name: 'requestTableColumns' }) as ColumnsType
  const { user, userPermissions } = useUserContext()
  const { initialRequests, setTabValue } = useCountContext()
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState<boolean>(false)
  const [requestDrawerOpen, setRequestDrawerOpen] = useState<boolean>(false)
  const [requestStaffDrawerOpen, setRequestStaffDrawerOpen] = useState<boolean>(false)
  const [offerDrawerOpen, setOfferDrawerOpen] = useState<boolean>(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* --------------------------- Data states start ---------------------------- */
  const dataSource = useRef<Request[]>([])
  const [myFilterOptions, setMyFilterOptions] = useState<FilterOptions>({})
  const [filteredData, setFilteredData] = useState<Request[]>([])
  const [defaultColumns] = useState<string[]>([
    "read",
    'self',
    'article',
    'quantities',
    'wishDate',
    'country',
    'status',
    'priority',
    'editors',
    'action',
  ])
  const [columns] = useState<ColumnsType>(columnsData)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRequests, setSelectedRequests] = useState<Request[]>([])
  const [totalRequests, setTotalRequests] = useState<number>(0)
  const [requestFormInput, setRequestFormInput] = useState<any>({})
  const [requestStaffFormInput, setRequestStaffFormInput] = useState<any>({})
  const [offerFormInput, setOfferFormInput] = useState<any>({})
  const [newRequests, setNewRequests] = useState<Request[]>(initialRequests)
  /* ---------------------------- Data states end ----------------------------- */

  log.debug('selectedRequest ->', selectedRequest)  
  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    setLoading(true)
    fetchRequests({
      onSuccess: (data: Request[], count: any) => {
        dataSource.current = [...dataSource.current, ...data]
        setTotalRequests(count)
        setFilteredData(dataSource.current)
        setLoading(false)
      },
      onError: error => {
        log.error('Error fetching Requests -> ', error)
        setLoading(false)
      },
      parameter: ['expand=editors,article'],
    })
  }, [])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  const filterData = (data: any[], filterOptions: { [x: string]: any }) => {
    if (!data || !Array.isArray(data)) {
      return [] // Rückgabe eines leeren Arrays, wenn die Daten ungültig sind
    }

    return data.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // Kein Filter angewendet
        if (key === 'name') {
          return filterValues.some((value: string) =>
            d.name.toLowerCase().includes(value.toLowerCase())
          )
        } else if (key === 'quantity') {
          return filterValues.some((value: string) => parseInt(d.in_storage) >= parseInt(value))
        } else {
          const cellValue = d[key]
          return filterValues.some((value: string) =>
            cellValue?.toString().toLowerCase().includes(value.toLowerCase())
          )
        }
      })
    })
  }
  /* -------------------------- Utility functions end ------------------------- */
  /* ------------------------ Callback functions start ------------------------ */

  async function handleUpdateRequest(input: any) {
    log.debug('input ->', input)
    const json = inputToAPIOfferJSON(input)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      input.self,
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.info('Success updating request', response)
      fetchRequest(
        response.self,
        (request: Request) => {
          dataSource.current = dataSource.current.map(r => (r.key === request.key ? request : r))
          setFilteredData(dataSource.current)
          toast.success(t('request:feedback.success.updateRequest'))
        },
        (error: any) => {
          log.error(error)
          toast.error(t('request:feedback.error.updateRequest'))
        },
        ["expand=editors,article"]
      )
      setSelectedRowKeys([])
      setSelectedRequests([])
      setSelectedRequest(undefined)
      setOpenInfoPanel(false)
      return response.files
    } else {
      log.error('Error updating request', error)
      toast.error(t('request:feedback.error.updateRequest'))
      setSelectedRowKeys([])
      setSelectedRequests([])
      setSelectedRequest(undefined)
      setOpenInfoPanel(false)
    }
  }

  const handleCreateOffer = async (offerInput: any) => {
    const json = inputToAPIOfferJSON({ ...offerInput, status: 0 })
    log.debug('Creating offer -> ', json)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'request/offers/',
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.info('Success creating request', response)
      toast.success(t('request:feedback.success.creatingRequest'))
      log.debug('Offer created -> ', response)
      return response.files
    } else {
      log.error('Error creating request', error)
      toast.error(t('request:feedback.error.creatingRequest'))
    }
  }

  /**
   * Redirect the user to the NewRequest page to create a request for the selected article
   * @param selectedArticle Article the user is trying to request
   */
  const handleOffer = (event: any, selectedRequest: Request) => {
    log.debug('selectedRequest ->', selectedRequest)
    event.stopPropagation()
    event.preventDefault()
    const newOffer: any = {
      request: selectedRequest,
      prices: {},
    }
    if (Array.isArray(selectedRequest.quantities) && selectedRequest.quantities.length > 0) {
      const newPrices: any = {}
      selectedRequest.quantities.forEach((quantity: number, index: number) => {
        newPrices[quantity] = ''
      })
      newOffer.prices = newPrices
    }
    const selectedRowKey: React.Key = selectedRequest.key
    setSelectedRowKeys([selectedRowKey])
    setOfferFormInput(newOffer)
    setOfferDrawerOpen(true)
  }

  const handleDecline = (event: any, selectedRequest: Request) => {
    log.debug('selectedRequest ->', selectedRequest)
    event.stopPropagation()
    event.preventDefault()
    updateRequest(
      { ...selectedRequest, status: 2 },
      [],
      (request: Request) => {
        log.debug('Declined request ->', request)
        dataSource.current = dataSource.current.map(r => (r.key === request.key ? request : r))
        setFilteredData(dataSource.current)
        toast.success(t('request:feedback.success.declineRequest'))
        setRequestDrawerOpen(false)
      },
      (error: any) => {
        log.error(error)
        toast.error(t('request:feedback.error.declineRequest'))
      }
    )
  }

  const handleApplyFilter = (filterOptions: Record<string, any>) => {
    setMyFilterOptions(filterOptions)

    const filteredProducts = dataSource.current.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // no filter applied

        const cellValue = d[key as keyof Request]
        if (cellValue === null) return null // Ignore null values
        if (typeof cellValue === 'string') {
          // String-based filtering
          if (typeof cellValue === 'string') {
            return filterValues.some((value: string) =>
              cellValue.toLowerCase().includes(value.toLowerCase())
            )
          }
        } else if (typeof cellValue === 'number') {
          // Number-based filtering
          const cellValueAsNumber =
            typeof cellValue === 'string' ? parseFloat(cellValue) : cellValue
          return filterValues.some((value: number) => cellValueAsNumber == value)
        }

        return true // Ignore other data types or no match
      })
    })

    setFilteredData(filteredProducts)
    setFilterVisible(false)
  }

  const handleColumnChange = (checkedColumns: string[]) => {
    setVisibleColumns(checkedColumns)
  }

  const handleCancelFilter = () => {
    setFilterVisible(false)
  }

  const handleReset = () => {
    setMyFilterOptions({})
    setFilteredData(dataSource.current)
  }

  const handleShowFilter = () => {
    setFilterVisible(true)
  }
  /* ------------------------- Callback functions end ------------------------- */
  /* ------------------------- Render constants start ------------------------- */
  const requestTableColumns: any = GetConstant({
    name: 'requestTableColumns',
    newRequests: newRequests,
    handleOffer: handleOffer,
    handleDecline: handleDecline,
  })
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns)
  const filteredColumns = requestTableColumns.filter((column: any) => {
    return visibleColumns.includes(column.key?.toString() ?? '')
  })
  const chips = Object.entries(myFilterOptions).map(([key, values]) => {
    if (!values.length) return <></>
    return (
      <Chip
        key={key}
        label={`${t('common:content.label.' + key)}: ${values}`}
        onDelete={() => {
          setMyFilterOptions(prev => {
            const updatedOptions = { ...prev, [key]: [] }
            setFilteredData(filterData(dataSource.current, updatedOptions))
            return updatedOptions
          })
        }}
      />
    )
  })

  const buttonContainerStyle = {
    marginLeft: openInfoPanel ? '-270px' : '0',
    transition: 'margin-left 0.3s ease-in-out',
    marginBottom: '5px',
  }

  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  if(loading) return <LoadingSkeleton pagetitle="" />

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>{chips}</Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" style={buttonContainerStyle}>
          {isPrivileged(user, 'STAFF') && (
            <Grid item>
              <Space>
                <Button
                  id="editRequestButton"
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<EditOutlined />}
                  onClick={() => {
                    if (isPrivileged(user, 'STAFF')) {
                      setRequestStaffFormInput(selectedRequests[0])
                      setRequestStaffDrawerOpen(true)
                    } else {
                      setRequestFormInput(selectedRequests[0])
                      setRequestDrawerOpen(true)
                    }
                  }}
                  disabled={selectedRowKeys.length !== 1}
                >
                  {t('request:interaction.button.editRequest')}
                </Button>
              </Space>
            </Grid>
          )}
          {(canDelete(userPermissions, "request:request") || isPrivileged(user)) && (<Grid item>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Tooltip title={t('request:content.description.youCanOnlyDeleteRejectedRequests')}>
                <InfoOutlined />
              </Tooltip>
              <Button
                id="deleteRequestButton"
                size="small"
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
                startIcon={<DeleteOutlined />}
                onClick={() => setOpenDelete(true)}
                disabled={
                  selectedRowKeys.length === 0 ||
                  selectedRequests.some(request => request.status !== 2)
                }
              >
                {t('request:interaction.button.deleteRequests')}
              </Button>
            </Stack>
          </Grid>)}
          <Grid item>
            <Space>
              <Button
                id="filterButton"
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<FilterAltOutlinedIcon />}
                onClick={handleShowFilter}
              >
                {t('common:content.label.filter')}
              </Button>
            </Space>
          </Grid>
          <Grid item>
            <Space>
              <ColumnSelector
                columns={columns as Array<{ key: string; title: string }>}
                onChangeColumns={handleColumnChange}
                defaultColumns={defaultColumns}
              />
            </Space>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          id="requestTable"
          columns={filteredColumns}
          dataSource={filteredData}
          pagination={{
            total: totalRequests,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
          locale={{
            emptyText: filteredData.length === 0 ? t('common:content.label.noData') : <CircularProgress />,
          }}
          rowSelection={{
            type: 'checkbox', // Set to 'checkbox' for multiple row selection
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedKeys: React.Key[], selectedRows: Request[]) => {
              setSelectedRowKeys(selectedKeys)
              setSelectedRequests(selectedRows)
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                setSelectedRequest(record as Request)
                const newRequest = newRequests.find(request => request.key === record.key)
                if(newRequest && !newRequest.read) {
                  setNewRequests(prev => prev.map(request => {
                    if(request.key === record.key) {
                      return {...request, read: true}
                    }
                    return request
                  }))
                }
                if (selectedRowKeys.includes(record.key as React.Key)) {
                  setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key))
                  setSelectedRequests(
                    selectedRequests.filter(request => request.key !== record.key)
                  )
                  setSelectedRequest(undefined)
                  setOpenInfoPanel(false)
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.key as React.Key])
                  setSelectedRequests([...selectedRequests, record as Request])
                  setSelectedRequest(record as Request)
                  setOpenInfoPanel(true)
                }
                /* fetchRequestFiles(
                        {
                            onSuccess: (data) => {
                                console.log(data)
                            },
                            onError: (error) => {
                                console.log(error)
                            },
                            url: record.self
                        }
                    ) */
              },
            }
          }}
        />
        {filterVisible && (
          <FilterComponent
            columns={requestTableColumns}
            onApplyFilter={handleApplyFilter}
            onCancelFilter={handleCancelFilter}
            onResetFilter={handleReset}
            filterOptions={myFilterOptions}
          />
        )}
        <RequestDrawer
          open={requestDrawerOpen}
          setOpen={setRequestDrawerOpen}
          formState={{
            state: requestFormInput,
            setState: setRequestFormInput,
          }}
          enableEditMode={true}
        />
        {isPrivileged(user, 'STAFF') && (
          <RequestStaffDrawer
            open={requestStaffDrawerOpen}
            setOpen={setRequestStaffDrawerOpen}
            formState={{
              state: requestStaffFormInput,
              setState: setRequestStaffFormInput,
            }}
            onEditConfirm={handleUpdateRequest}
          />
        )}
        <OfferDrawer
          open={offerDrawerOpen}
          setOpen={setOfferDrawerOpen}
          formState={{
            state: offerFormInput,
            setState: setOfferFormInput,
          }}
          onCreateConfirm={handleCreateOffer}
          setTabValue={setTabValue}
        />
      </Grid>
      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={() => {
          deleteRequests(
            selectedRequests,
            () => {
              toast.success(t('request:feedback.success.deleteRequest'))
              setSelectedRowKeys([])
              setSelectedRequests([])
              setFilteredData(prev => prev.filter(d => !selectedRequests.includes(d)))
              setOpenDelete(false)
            },
            (error: any) => {
              log.error(error)
              toast.error(error)
              setOpenDelete(false)
            }
          )
        }}
        name={t('common:content.label.requests')}
      />
      <FilesDrawer
        open={openFilesDrawer}
        setOpen={setOpenFilesDrawer}
        onClose={() => {}}
        files={selectedRequest?.files}
        title={t('common:content.label.requestFiles') as string}
      />
    </Grid>
  )
}
