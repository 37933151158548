import { createContext, useContext } from "react";
import { NewPermissions } from "../features/Roles/utils/types";
import { Role, User } from "./types"
import { GuestRequest } from "../features/UnauthRequest/utils/types";
import { Request, Offer } from "../features/Request/utils/types";
import { Bill, Contract } from "../features/Contract/utils/types";


type UserContextType = {
    accessToken: string;
    setAccessToken: (accessToken: string) => void;
    userPermissions: NewPermissions;
    roles: Role[];
    user: User | undefined;
    setUser: (user: User | undefined) => void;
}

type CountContextType = {
  initialGuestRequests: GuestRequest[]
  setInitialGuestRequests: (requests: GuestRequest[]) => void;
  initialRequests: Request[]
  setInitialRequests: (requests: Request[]) => void;
  initialOffers: Offer[]
  setInitialOffers: (offers: Offer[]) => void;
  initialContracts: Contract[]
  setInitialContracts: (contracts: Contract[]) => void;
  initialBills: Bill[]
  setInitialBills: (bills: Bill[]) => void;
  countTotals: number;
  setCountTotals: (count: number) => void;
  tabValue: number;
  setTabValue: (value: number) => void;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const CountContext = createContext<CountContextType | undefined>(undefined);

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}

export function useCountContext() {
  const context = useContext(CountContext);
  if (!context) {
    throw new Error("useCountContext must be used within a CountProvider");
  }
  return context;
}