/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React from 'react';
import LogTool from '../../../logger/logTools';
import InfoPanel from '../../../components/layout/InfoPanel';
import { Chip, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoField from '../../../components/inputs/InfoField';
import moment from 'moment';
import { Check, Close } from '@mui/icons-material';
import { APIAddress } from '../../../generated-types';
import { createAddress, getPKfromSelf, isPrivileged } from '../../../utils/functions';
import { Address } from '../../../utils/types';
import { Table } from 'antd';
import Button from '../../../components/Button';
import FieldLabel from '../../../components/widgets/FieldLabel';
import { useUserContext } from '../../../utils/context';
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOpenFilesDrawer: (open: boolean) => void;
  infoData: any;
  title?: string;
  disabled?: boolean;
}

export default function OfferInfoPanel(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, setOpenFilesDrawer, infoData, title = '', disabled = false } = props
  const log = new LogTool({ context: 'OfferInfoPanel', enable: true, logLevel: 'warn' })
  const { t } = useTranslation()
  const { user } = useUserContext()

  log.debug("Open -> ", open)
  log.debug("infoData -> ", infoData)
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const renderStatus = (status: number) => {
    switch(status) {
        case 0:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.pending")} color="warning" variant="outlined" />
                </Stack>
            )
        case 1:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.accepted")} color="success" variant="outlined" />
                </Stack>
            )
        case 2:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.rejected")} color="error" variant="outlined" />
                </Stack>
            )
        default:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.unknown")} color="default" variant="outlined" />
                </Stack>
            )
    }
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  log.debug("InfoData -> ", infoData)
  return (
    <InfoPanel
      open={open}
      setOpen={setOpen}
      label={t("common:content.label.offerInformation")}
      allowEdit={false}
      disabled={true}
    >
      <Grid container spacing={2} style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <InfoField
            label={t("request:content.label.offerNumber")}
            fullWidth
            value={infoData?.self && getPKfromSelf(infoData.self)}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("request:content.label.requestNumber")}
            fullWidth
            value={infoData?.request?.self && getPKfromSelf(infoData.request.self)}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.createdAt")}
            fullWidth
            value={moment(infoData?.createdAt).format('DD.MM.YYYY')}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.updatedAt")}
            fullWidth
            value={moment(infoData?.updatedAt).format('DD.MM.YYYY')}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.remarks")}
            fullWidth
            value={infoData?.remarks}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldLabel value={t("common:content.label.prices") as string} />
          <Table
            style={{ marginTop: "10px" }}
            dataSource={infoData?.prices && Object.keys(infoData?.prices).map((key: string) => {
              return {
                quantity: key,
                price: infoData?.prices[key] + " €"
              }
            })}
            columns={[
              {
                title: t("common:content.label.quantity"),
                dataIndex: 'quantity',
                key: 'quantity'
              },
              {
                title: t("common:content.label.price"),
                dataIndex: 'price',
                key: 'price'
              }
            ]}
            pagination={false}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.status")}
            fullWidth
            value={renderStatus(infoData?.status)}
          />
        </Grid>
        {isPrivileged(user, "STAFF") && (<Grid item xs={12}>
          <InfoField
            label={t('common:content.label.editors')}
            fullWidth
            value={""}
          />
          {infoData?.editors && infoData.editors.length > 0 && infoData.editors.map((editor: any) => {
            return <Chip key={editor.id} label={`${editor.first_name} ${editor.last_name}`} sx={{marginLeft: "10px"}} />
          })}
        </Grid>)}
        <Grid item xs={12}>
          <Button
            id="showFilesButton"
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenFilesDrawer(true)
            }}
          >
            {t('common:interaction.button.showFiles')}
          </Button>
        </Grid>
      </Grid>
    </InfoPanel>
  )
}