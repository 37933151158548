/* -------------------------- Design imports start -------------------------- */
import { Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: string) => void
  validation?: (value: string) => boolean
  errorMessage?: string
  disabled?: boolean
  required?: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function CheckedTextInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    label,
    value,
    onChange,
    labelWidth = 100,
    disabled = false,
    errorMessage = '',
    validation,
    required,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const error = value !== '' && !disabled && validation && !validation(value)
  const [open, setOpen] = useState<boolean>(!!value)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation()
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <FormControl fullWidth variant="outlined">
      <FormControlLabel
        control={<Checkbox defaultChecked={open} onClick={() => setOpen(!open)} />}
        label={label}
      />
      {open && (
        <TextField
          id={label}
          required={required}
          variant="outlined"
          label={t('custom')}
          value={value || ''}
          onChange={e => onChange(e.currentTarget.value)}
          autoComplete="text"
          disabled={disabled}
          error={error}
          aria-describedby="text-input"
          inputProps={{
            'aria-label': 'weight',
          }}
        />
      )}
      {error && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
