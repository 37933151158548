import { createTheme } from '@mui/material'

const theme = (prefersDarkMode: boolean) =>
  createTheme({
    palette: {
      //mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: '#16a9e4',
        light: '#46bfef',
        dark: '#1287b6',
      },
      secondary: {
        main: '#eb9928',
        light: '#ffb145',
        dark: '#db8f25',
      },
      info: {
        main: '#7f7f7f',
        light: '#bfbfbf',
        dark: '#595959',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          },
        },
      },
    },
  })

export default theme
