/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIAddress,
    APIAddressFromJSON,
    APIAddressToJSON,
    APIAddressRequest,
    APIAddressRequestFromJSON,
    APIAddressRequestToJSON,
    APIPaginatedAddressList,
    APIPaginatedAddressListFromJSON,
    APIPaginatedAddressListToJSON,
    APIPatchedAddressRequest,
    APIPatchedAddressRequestFromJSON,
    APIPatchedAddressRequestToJSON,
} from '../models';

export interface V1OrganizationsOrganizationBillingAddressCreateRequest {
    organizationPk: string;
    aPIAddressRequest: APIAddressRequest;
}

export interface V1OrganizationsOrganizationBillingAddressDestroyRequest {
    id: number;
    organizationPk: string;
}

export interface V1OrganizationsOrganizationBillingAddressListRequest {
    organizationPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1OrganizationsOrganizationBillingAddressPartialUpdateRequest {
    id: number;
    organizationPk: string;
    aPIPatchedAddressRequest?: APIPatchedAddressRequest;
}

export interface V1OrganizationsOrganizationBillingAddressRetrieveRequest {
    id: number;
    organizationPk: string;
}

export interface V1OrganizationsOrganizationBillingAddressUpdateRequest {
    id: number;
    organizationPk: string;
    aPIAddressRequest: APIAddressRequest;
}

/**
 * 
 */
export class OrganizationsorganizationsbillingAddressApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressCreateRaw(requestParameters: V1OrganizationsOrganizationBillingAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressCreate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1OrganizationsOrganizationBillingAddressCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/`.replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressCreate(requestParameters: V1OrganizationsOrganizationBillingAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressDestroyRaw(requestParameters: V1OrganizationsOrganizationBillingAddressDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressDestroy.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressDestroy(requestParameters: V1OrganizationsOrganizationBillingAddressDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1OrganizationsOrganizationBillingAddressDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressListRaw(requestParameters: V1OrganizationsOrganizationBillingAddressListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedAddressList>> {
        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/`.replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedAddressListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressList(requestParameters: V1OrganizationsOrganizationBillingAddressListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedAddressList> {
        const response = await this.v1OrganizationsOrganizationBillingAddressListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressPartialUpdateRaw(requestParameters: V1OrganizationsOrganizationBillingAddressPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressPartialUpdate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedAddressRequestToJSON(requestParameters.aPIPatchedAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressPartialUpdate(requestParameters: V1OrganizationsOrganizationBillingAddressPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressRetrieveRaw(requestParameters: V1OrganizationsOrganizationBillingAddressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressRetrieve.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressRetrieve(requestParameters: V1OrganizationsOrganizationBillingAddressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressUpdateRaw(requestParameters: V1OrganizationsOrganizationBillingAddressUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationBillingAddressUpdate.');
        }

        if (requestParameters.organizationPk === null || requestParameters.organizationPk === undefined) {
            throw new runtime.RequiredError('organizationPk','Required parameter requestParameters.organizationPk was null or undefined when calling v1OrganizationsOrganizationBillingAddressUpdate.');
        }

        if (requestParameters.aPIAddressRequest === null || requestParameters.aPIAddressRequest === undefined) {
            throw new runtime.RequiredError('aPIAddressRequest','Required parameter requestParameters.aPIAddressRequest was null or undefined when calling v1OrganizationsOrganizationBillingAddressUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{organization_pk}/billing_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organization_pk"}}`, encodeURIComponent(String(requestParameters.organizationPk))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIAddressRequestToJSON(requestParameters.aPIAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIAddressFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationBillingAddressUpdate(requestParameters: V1OrganizationsOrganizationBillingAddressUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIAddress> {
        const response = await this.v1OrganizationsOrganizationBillingAddressUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
