/* -------------------------- Design imports start -------------------------- */
import Page from '../../components/layout/Page'
import { Box, Grid, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useMobileService from '../../hooks/useMobileService'
import logo from '../../assets/logo_black.svg'
import Button from '../../components/Button'

/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function CheckEmailPage() {
  /* -------------------------- Non state data start -------------------------- */
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const isMobile = useMobileService()
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Grid
          container
          spacing={2}
          width={isMobile ? '100%' : '60%'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={12} display="flex" justifyContent="center">
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: '200px', height: '100px', marginBottom: '-30px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  {t('content.auth.successfullyRegistered')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  {t('content.auth.checkEmailText')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => history.push('/signIn?focus=signIn')}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      e.stopPropagation()
                      history.push('/signIn?focus=signIn')
                    }
                  }}
                >
                  {t('content.auth.goToSignIn')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={() => history.push('/signIn?focus=unauthRequest')}
                >
                  {t('content.auth.sendRequest')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}
