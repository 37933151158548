/* ------------------------ Functional imports start ------------------------ */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { handleAPICallV1 } from './utils/functions'
import { HTTPMethod } from './utils/types'
import Cookies from 'js-cookie'
import LogTool from './logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

export interface WebSocketContextValue {
  socket: WebSocket | null
  setIsLoggedIn: (isLoggedIn: boolean) => void
  setAccessTokenWS: (accessToken: string) => void
}

export const WebSocketContext = createContext<WebSocketContextValue>({
  socket: null,
  setIsLoggedIn: () => {},
  setAccessTokenWS: () => {},
})

export const useWebSocket = () => useContext(WebSocketContext)

type Props = {
  children: React.ReactNode
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export const WebSocketProvider = (props: Props) => {
  /* -------------------------- Non state data start -------------------------- */
  const { children } = props
  const log = new LogTool({context: 'WebSocketProvider', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [accessTokenWS, setAccessTokenWS] = useState<string>("")
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [socket, setSocket] = useState<WebSocket | null>(null)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    fetchCurrentUser()
  }, [])

  useEffect(() => {
    if (isLoggedIn && (Cookies.get('access') || accessTokenWS !== "")){
      const newSocket = new WebSocket(
        process.env.REACT_APP_WS_URL +`chat/?token=${Cookies.get('access') ? Cookies.get('access') : accessTokenWS}`
      )
      setSocket(newSocket)

      newSocket.onopen = () => {
        log.info('WebSocket connected')
      }

      newSocket.onclose = () => {
        log.info('WebSocket disconnected')
        setSocket(null)
      }
    } else if (socket) {
      socket.close()
      setSocket(null)
    } else {
      log.info('Not logged in')
      setSocket(null)
    }

    return () => {
      if (socket) {
        socket.close()
      }
    }
  }, [isLoggedIn])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  async function fetchCurrentUser() {
    log.info("Begin fetching current user")
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      'accounts/users/self/',
      undefined,
      undefined
    )
    if (response) {
      log.info('Success fetching current user', response)
      setIsLoggedIn(true)
    } else {
      log.error('Error fetching current user', error)
      setIsLoggedIn(false)
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  return <WebSocketContext.Provider value={{ socket, setIsLoggedIn, setAccessTokenWS }}>{children}</WebSocketContext.Provider>
}
