/* -------------------------- Design imports start -------------------------- */
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import GetConstant from '../utils/constants'
import { Form } from '../../Form'
import { Add, Delete } from '@mui/icons-material'
import { FileInfo, FileUploadStatus } from '../../../utils/types'
import { deleteFile, uploadFile } from '../../../utils/functions'
import FileInput from '../../../components/inputs/FileInput'
import InputFileList from '../../../components/widgets/InputFileList'
import LoadingButton from '@mui/lab/LoadingButton'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onEditConfirm?: (formState: any) => Promise<string>
}

/* -------------------------------------------------------------------------- */
/*                                Start Component                             */
/* -------------------------------------------------------------------------- */
export default function OfferStaffDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, formState, onClose = () => null, onEditConfirm = () => null } = props
  const { t } = useTranslation(['offer', 'common'])
  const log = new LogTool({ context: 'OfferStaffDrawer', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [quantities, setQuantities] = React.useState<string[]>([''])
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  const [waiting, setWaiting] = useState(false)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    log.debug('fileInput -> ', fileInput)
    if (fileInput.length > 0 && fileInput.every(file => file.uploadStatus === 'success')) {
      setWaiting(false);
      setOpen(false);
    }
  }, [fileInput])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  const handleClose = () => {
    setQuantities([''])
    onClose()
  }

  const handleEditConfirm = async () => {
    log.debug('handleCreateRequest -> ', formState.state)
    setWaiting(true)
    // set loading state on all files to loading
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    // posting request to server and waiting on response
    const contractFilesUrl = await onEditConfirm(formState.state)

    log.debug('contractFilesUrl -> ', contractFilesUrl)
    // check if the user specified files to upload !
    if (fileInput.length > 0 && contractFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of fileInput) {
        uploadOfferFile(file, contractFilesUrl)
        // response.articleFilesUrl cannot be undefined here as the server created the article successfully and therefore created an article files url

        // store the articleFilesUrl in the inputFiles state to have it available in case something goes wrong
        /* setInputFiles((prev: FileInfo[]) => prev.map(
          file => Object.assign(file, {uploadUrl: response.articleFilesUrl})
        )) */
      }
    } else {
      setWaiting(false)
      setOpen(false)
    }
  }

  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadOfferFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileUploadStatus(file, 'success')
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput((prevFileList: any[]) => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }
  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadOfferFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const offerStaffFormFields: any = GetConstant({ name: 'offerFormFieldsStaff' })
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */
  log.debug('formState -> ', formState.state)
  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        title={t('request:content.label.editOffer') || ''}
        size="big"
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Button
              id="cancelButton"
              onClick={() => setOpen(false)}
              color="inherit"
              variant="contained"
              sx={{ mr: 1, textTransform: 'none' }}
            >
              {t('common:interaction.button.cancel')}
            </Button>
            <LoadingButton
              id="createButton"
              variant="contained"
              sx={{ marginLeft: '10px', color: 'whitesmoke', textTransform: 'none' }}
              loading={waiting}
              onClick={handleEditConfirm}
            >
              {t('common:interaction.button.save')}
            </LoadingButton>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form
              editing={true}
              formFields={offerStaffFormFields}
              formObject={formState.state}
              onChange={(value: any) => {
                formState.setState(value)
              }}
            />
            <Grid container spacing={1} marginBottom={'20px'}>
              {formState.state?.prices &&
                formState.state?.request?.quantities &&
                formState.state.request.quantities.map((quantity: number, index: number) => {
                  return (
                    <>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          id="quantity-1"
                          label={t('request:content.label.quantity') + ` ${index + 1}`}
                          type="text"
                          variant="outlined"
                          fullWidth
                          value={quantity}
                          onChange={e => setQuantities([e.target.value, ...quantities.slice(1)])}
                          required
                          disabled
                          error={quantity === undefined || !/^\d+$/.test(quantity.toString())}
                          helperText={
                            quantity === undefined
                              ? t('request:feedback.error.pleaseEnterQuantity')
                              : !/^\d+$/.test(quantity.toString())
                              ? t('request:feedback.error.invalidQuantity')
                              : ''
                          }
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          id={`price-${index + 1}`}
                          type="text"
                          label={t('request:content.label.price') + ` ${index + 1}`}
                          value={formState.state?.prices[quantity] ?? ''}
                          onChange={e => {
                            formState.setState({
                              ...formState.state,
                              prices: {
                                ...formState.state.prices,
                                [quantity]: e.target.value.replace(',', '.'),
                              },
                            })
                          }}
                          required={index === 0}
                          error={
                            (index === 0 && formState.state?.prices[quantity] === '') ||
                            (formState.state?.prices[quantity] !== '' &&
                              !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[quantity]))
                          }
                          helperText={
                            index === 0 && formState.state?.prices[quantity] === ''
                              ? t('request:feedback.error.pleaseEnterPrice')
                              : formState.state?.prices[quantity] !== '' &&
                                !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[quantity])
                              ? t('request:feedback.error.invalidPrice')
                              : ''
                          }
                          inputProps={{
                            inputMode: 'decimal',
                            pattern: '[0-9]*[.,]?[0-9]*',
                          }}
                          InputProps={{
                            endAdornment: <div>€</div>,
                          }}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )
                })}
              {quantities?.slice(1)?.map((quantity, index) => (
                <>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={index}>
                    <TextField
                      id={`quantity-${index}`}
                      label={t('request:content.label.quantity') + ` ${index + 2}`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() =>
                              setQuantities([
                                ...quantities.slice(0, index),
                                ...quantities.slice(index + 1),
                              ])
                            }
                            style={{ color: 'grey' }}
                            size="small"
                            aria-label="add"
                          >
                            <Delete />
                          </IconButton>
                        ),
                      }}
                      value={quantity}
                      error={quantities[index + 1] !== '' && !/^\d+$/.test(quantities[index + 1])}
                      helperText={
                        quantities[index + 1] !== '' && !/^\d+$/.test(quantities[index + 1])
                          ? t('request:feedback.error.invalidQuantity')
                          : ''
                      }
                      onChange={e => {
                        const newQuantities = [...quantities]
                        newQuantities[index + 1] = e.target.value
                        setQuantities(newQuantities)
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      id={`price-${index + 2}`}
                      type="text"
                      label={t('request:content.label.price') + ` ${index + 2}`}
                      value={formState.state?.prices[quantity] ?? ''}
                      onChange={e => {
                        formState.setState({
                          ...formState.state,
                          prices: {
                            ...formState.state.prices,
                            [quantity]: e.target.value.replace(',', '.'),
                          },
                        })
                      }}
                      required={index === 0}
                      error={
                        (index === 0 && formState.state?.prices[index]?.price === '') ||
                        (formState.state?.prices[index]?.price !== '' &&
                          !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[index]?.price))
                      }
                      helperText={
                        index === 0 && formState.state?.prices[index]?.price === ''
                          ? t('request:feedback.error.pleaseEnterPrice')
                          : formState.state?.prices[index]?.price !== '' &&
                            !/^[0-9]*[.,]?[0-9]*$/.test(formState.state?.prices[index]?.price)
                          ? t('request:feedback.error.invalidPrice')
                          : ''
                      }
                      inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*[.,]?[0-9]*',
                      }}
                      InputProps={{
                        endAdornment: <div>€</div>,
                      }}
                      fullWidth
                    />
                  </Grid>
                </>
              ))}
              {quantities.length < 10 && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Button
                    onClick={() => setQuantities([...quantities, ''])}
                    color="primary"
                    variant="text"
                    startIcon={<Add />}
                    size="large"
                    aria-label="add"
                    style={{ marginBottom: '20px', textTransform: 'none' }}
                  >
                    {t('request:interaction.button.newQuantity')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
              {t('common:content.label.documents')}
            </Typography>
            <FileInput
              inputState={{ state: fileInput, setState: setFileInput }}
              maxAllowedFiles={10}
            />
            {fileInput.length > 0 && (
              <InputFileList
                files={fileInput}
                noFilesInfo=""
                enableEditMode={false}
                onRemoveFile={handleRemoveFileFromList}
                onReUploadFile={handleReUploadFile}
                onDeleteFile={handleDeleteFile}
              />
            )}
          </Grid>
        </Grid>
      </MultilevelDrawer>
    </>
  )
}
