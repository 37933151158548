/* -------------------------- Design imports start -------------------------- */
import { Timeline } from 'antd'
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material'
import { Grid, IconButton, Modal, Stack, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import { Version } from '../utils/types'
import moment from 'moment'
import { HTTPMethod } from '../../../utils/types'
import { handleAPICallV1 } from '../../../utils/functions'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import Button from '../../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  versions: Version[]
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  setOpen: (open: boolean) => void
  setVersionDifferences: React.Dispatch<React.SetStateAction<any | undefined>>
  setCompareOpen: React.Dispatch<React.SetStateAction<boolean>>
  onRevertVersion: (version: Version) => void
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function VersionTimeline(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { versions, formState, setOpen, setVersionDifferences, setCompareOpen } = props
  const { t } = useTranslation(['common', 'version'])
  const log = new LogTool({ context: 'VersionTimeline', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [revertModalOpen, setRevertModalOpen] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [showVersionData, setShowVersionData] = useState<number>(-1)

  const [versionToRevert, setVersionToRevert] = useState<Version | undefined>(undefined)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */

  const handleRevertVersion = async () => {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      formState.state.self + 'revert/',
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access')}`,
      },
      {
        version: versionToRevert,
      },
      'text'
    )
    if (!error) {
      toast.success(t('common:feedback.success.revertedToVersionSuccessfully'))

      if (versionToRevert) {
        props.onRevertVersion(versionToRevert)
      }
    } else {
      toast.error(t('common:feedback.error.revertedToVersionSuccessfully'))
    }
    setRevertModalOpen(false)
    setOpen(false)
  }

  const handleFetchVersionDifferences = async (
    object_self: string,
    version1: string,
    version2: string
  ) => {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      object_self + 'compare_versions/',
      undefined,
      { version1_id: version1, version2_id: version2 }
    )
    if (response) {
      setVersionDifferences(response)
    }
    if (error) {
      log.error(error)
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */

  const timelineItems = versions.map((version: Version, index: number) => {
    return (
      <Timeline.Item key={index} color="#16a9e4">
        <Grid container>
          <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
            <Typography>{version.comment}</Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <IconButton
              id="expandVersionButton"
              aria-label="expand"
              size="small"
              onClick={() =>
                showVersionData === index ? setShowVersionData(-1) : setShowVersionData(index)
              }
            >
              {showVersionData === index ? (
                <KeyboardArrowUpOutlined />
              ) : (
                <KeyboardArrowDownOutlined />
              )}
            </IconButton>
          </Grid>
        </Grid>
        {showVersionData === index && (
          <Stack spacing={2}>
            <p>
              {t('common:content.label.user')}: {version.user} <br />
              {t('common:content.label.date')}:{' '}
              {moment(version.created_date).format('MMMM Do YYYY, h:mm:ss a')} <br />
            </p>
            {index !== 0 && (
              <Stack spacing={1}>
                <Button
                  id="revertVersionButton"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setVersionToRevert(version)
                    setRevertModalOpen(true)
                  }}
                >
                  {t('common:interaction.button.revertToThisVersion')}
                </Button>
                <Button
                  id="compareVersionButton"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleFetchVersionDifferences(
                      formState.state.self,
                      version.version_id,
                      versions[0].version_id
                    )
                    setCompareOpen(true)
                  }}
                >
                  {t('common:interaction.button.compareWithCurrentVersion')}
                </Button>
              </Stack>
            )}
          </Stack>
        )}
      </Timeline.Item>
    )
  })

  const revertModal = () => {
    return (
      <Modal
        open={revertModalOpen}
        onClose={() => setRevertModalOpen(false)}
        aria-labelledby="revert-modal"
        aria-describedby="modal-for-reverting-version"
      >
        <Grid
          container
          spacing={2}
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6">{t('common:content.label.revertVersion')}</Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography>{t('common:feedback.info.areYouSureVersion')}</Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              id="cancelRevertButton"
              variant="outlined"
              color='inherit'
              sx={{ float: 'left' }}
              onClick={() => setRevertModalOpen(false)}
            >
              {t('common:interaction.button.cancel')}
            </Button>
            <Button
              id="revertButton"
              variant="contained"
              sx={{ float: 'right' }}
              onClick={handleRevertVersion}
            >
              {t('common:interaction.button.revert')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <Timeline mode={'left'}>{timelineItems}</Timeline>
      {revertModal()}
    </>
  )
}
