/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIOfferFile,
    APIOfferFileFromJSON,
    APIOfferFileToJSON,
    APIPaginatedOfferFileList,
    APIPaginatedOfferFileListFromJSON,
    APIPaginatedOfferFileListToJSON,
} from '../models';

export interface V1RequestOfferFilesCreateRequest {
    offer: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOfferFilesDestroyRequest {
    id: number;
}

export interface V1RequestOfferFilesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1RequestOfferFilesPartialUpdateRequest {
    id: number;
    offer?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOfferFilesRetrieveRequest {
    id: number;
}

export interface V1RequestOfferFilesUpdateRequest {
    id: number;
    offer: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOffersFilesCreateRequest {
    offerPk: number;
    offer: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOffersFilesDestroyRequest {
    id: number;
    offerPk: number;
}

export interface V1RequestOffersFilesListRequest {
    offerPk: number;
    page?: number;
    pageSize?: number;
}

export interface V1RequestOffersFilesPartialUpdateRequest {
    id: number;
    offerPk: number;
    offer?: string;
    key?: string | null;
    document?: Blob | null;
}

export interface V1RequestOffersFilesRetrieveRequest {
    id: number;
    offerPk: number;
}

export interface V1RequestOffersFilesUpdateRequest {
    id: number;
    offerPk: number;
    offer: string;
    key?: string | null;
    document?: Blob | null;
}

/**
 * 
 */
export class RequestofferFileApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesCreateRaw(requestParameters: V1RequestOfferFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.offer === null || requestParameters.offer === undefined) {
            throw new runtime.RequiredError('offer','Required parameter requestParameters.offer was null or undefined when calling v1RequestOfferFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offer_files/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesCreate(requestParameters: V1RequestOfferFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesDestroyRaw(requestParameters: V1RequestOfferFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offer_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesDestroy(requestParameters: V1RequestOfferFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1RequestOfferFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesListRaw(requestParameters: V1RequestOfferFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedOfferFileList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offer_files/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedOfferFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesList(requestParameters: V1RequestOfferFilesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedOfferFileList> {
        const response = await this.v1RequestOfferFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesPartialUpdateRaw(requestParameters: V1RequestOfferFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offer_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesPartialUpdate(requestParameters: V1RequestOfferFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesRetrieveRaw(requestParameters: V1RequestOfferFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offer_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesRetrieve(requestParameters: V1RequestOfferFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesUpdateRaw(requestParameters: V1RequestOfferFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOfferFilesUpdate.');
        }

        if (requestParameters.offer === null || requestParameters.offer === undefined) {
            throw new runtime.RequiredError('offer','Required parameter requestParameters.offer was null or undefined when calling v1RequestOfferFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offer_files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOfferFilesUpdate(requestParameters: V1RequestOfferFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOfferFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesCreateRaw(requestParameters: V1RequestOffersFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesCreate.');
        }

        if (requestParameters.offer === null || requestParameters.offer === undefined) {
            throw new runtime.RequiredError('offer','Required parameter requestParameters.offer was null or undefined when calling v1RequestOffersFilesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/`.replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesCreate(requestParameters: V1RequestOffersFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesDestroyRaw(requestParameters: V1RequestOffersFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesDestroy.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesDestroy(requestParameters: V1RequestOffersFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1RequestOffersFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesListRaw(requestParameters: V1RequestOffersFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedOfferFileList>> {
        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/`.replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedOfferFileListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesList(requestParameters: V1RequestOffersFilesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedOfferFileList> {
        const response = await this.v1RequestOffersFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesPartialUpdateRaw(requestParameters: V1RequestOffersFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesPartialUpdate.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesPartialUpdate(requestParameters: V1RequestOffersFilesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesRetrieveRaw(requestParameters: V1RequestOffersFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesRetrieve.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesRetrieve(requestParameters: V1RequestOffersFilesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesUpdateRaw(requestParameters: V1RequestOffersFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOfferFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1RequestOffersFilesUpdate.');
        }

        if (requestParameters.offerPk === null || requestParameters.offerPk === undefined) {
            throw new runtime.RequiredError('offerPk','Required parameter requestParameters.offerPk was null or undefined when calling v1RequestOffersFilesUpdate.');
        }

        if (requestParameters.offer === null || requestParameters.offer === undefined) {
            throw new runtime.RequiredError('offer','Required parameter requestParameters.offer was null or undefined when calling v1RequestOffersFilesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.offer !== undefined) {
            formParams.append('offer', requestParameters.offer as any);
        }

        if (requestParameters.key !== undefined) {
            formParams.append('key', requestParameters.key as any);
        }

        if (requestParameters.document !== undefined) {
            formParams.append('document', requestParameters.document as any);
        }

        const response = await this.request({
            path: `/v1/request/offers/{offer_pk}/files/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"offer_pk"}}`, encodeURIComponent(String(requestParameters.offerPk))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOfferFileFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1RequestOffersFilesUpdate(requestParameters: V1RequestOffersFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOfferFile> {
        const response = await this.v1RequestOffersFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
