/* -------------------------- Design imports start -------------------------- */
import { Box, Modal, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  removeMemberModal: boolean
  setRemoveMemberModal: (open: boolean) => void
  handleRemoveMember: (member: any) => void
  memberToRemove: any
}

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function RemoveMemberModal(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { removeMemberModal, setRemoveMemberModal, handleRemoveMember, memberToRemove } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['chat'])
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Modal
      open={removeMemberModal}
      onClose={() => setRemoveMemberModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '4px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('content.label.removeUserModalTitle')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('content.label.removeUserModalDescription')}
        </Typography>
        <Button
          id="removeButton"
          variant="contained"
          sx={{
            marginTop: '10px',
            float: 'right',
            marginLeft: '10px',
          }}
          onClick={() => {
            handleRemoveMember(memberToRemove)
            setRemoveMemberModal(false)
          }}
        >
          {t('common:interaction.button.remove')}
        </Button>
        <Button
          id="cancelButton"
          variant="outlined"
          color='primary'
          sx={{ marginTop: '10px', float: 'left' }}
          onClick={() => {
            setRemoveMemberModal(false)
          }}
        >
          {t('common:interaction.button.cancel')}
        </Button>
      </Box>
    </Modal>
  )
}
