/* --------------------------- Design states start -------------------------- */
import { Autocomplete, AutocompleteRenderOptionState, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material'
/* ---------------------------- Design states end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  value: string
  label: string
  labelWidth?: number
  onChange: (value: string) => void
  validation?: boolean
  errorMessage?: string
  disabled?: boolean
  required?: boolean
  multiline?: boolean
  showError?: boolean
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function OptionalTextInput(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    label,
    value = '',
    onChange,
    labelWidth = 100,
    disabled = false,
    required = false,
    errorMessage = '',
    validation,
    multiline,
    showError = true,
  } = props
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(showError)
  const [open, setOpen] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if(showError){
      validation !== undefined && value === "" ? setError(true) : setError(false)
    }
  }, [showError, validation, value])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Render constants start ------------------------- */

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container spacing={0} sx={{width: '100%'}}>
        <Grid
            item
            xs={open ? 1 : 12} sm={open ? 1 : 12} md={open ? 1 : 12} lg={open ? 1 : 12} xl={open ? 1 : 12}
            sx={{marginTop: "7px"}}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={open}
                        onChange={e => {
                            setOpen(e.currentTarget.checked)
                            e.currentTarget.checked ? onChange(value) : onChange('')
                        }}
                        name="checkedB"
                        color="primary"
                    />
                }
                label={!open && label}
            />
        </Grid>
        {open && (
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        id={label}
                        variant="outlined"
                        multiline={multiline}
                        rows={multiline ? 4 : 1}
                        value={value || ''}
                        label={open && label}
                        onChange={e => {
                            e.currentTarget.value !== '' || validation === undefined
                                ? setError(false)
                                : setError(true)
                            onChange(e.currentTarget.value)
                        }}
                        autoComplete="text"
                        required={required}
                        disabled={disabled}
                        error={error}
                        aria-describedby="text-input"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    />
                {error && (
                    <FormHelperText error variant="outlined">
                        {errorMessage}
                    </FormHelperText>
                )}
                </FormControl>
            </Grid>
        )}
    </Grid>
  )
}