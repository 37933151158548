/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { Scene } from 'babylonjs'
import 'babylonjs-loaders'
import SceneComponent from './SceneComponent'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/lab'
import * as BABYLON from 'babylonjs'
import ArcRotateCamera = BABYLON.ArcRotateCamera
import Vector3 = BABYLON.Vector3
import HemisphericLight = BABYLON.HemisphericLight
import SceneLoader = BABYLON.SceneLoader
import LogTool from '../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  link: string
  setSceneLoading: (sceneLoading: boolean) => void
}

/**
 * Component that loads and shows the converted stl file in a scene created with babylonjs
 */
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function DocumentView(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({context: 'DocumentView', enable: true, })
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation()
  const [link, setLink] = useState<string>(props.link)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    setLink(props.link)
  }, [props.link])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  const onError = (text?: string) => {
    console.error(text)
    setError(true)
    return null
  }

  const onSceneReady = (scene: Scene) => {
    log.debug('Scene started')
    // This creates and positions a free camera (non-mesh)
    const camera = new ArcRotateCamera('Camera', 0, 0, 10, new Vector3(0, 0, 0), scene)
    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero())
    const canvas = scene.getEngine().getRenderingCanvas()
    // This attaches the camera to the canvas
    camera.attachControl(canvas)

    camera.useFramingBehavior = true
    camera.useAutoRotationBehavior = true

    camera.framingBehavior!.framingTime = 0 // It looks wonky if animating
    camera.autoRotationBehavior!.idleRotationSpeed = 0.25 // default: 0.05 = very slow

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene)
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7
    if (!link) {
      return onError('Failed to load mesh resource (link property not set?)')
    }

    SceneLoader.ImportMesh(
      null,
      '',
      link,
      scene,
      function (meshes, particleSystems, skeletons, animationGroups) {
        if (meshes.length === 0) {
          return onError('No mesh to import from URL ' + link)
        }
        // The serverside decoding must have failed to combine the model into a single mesh, so we wouldn't know which is the primary one to display
        if (meshes.length !== 1) {
          return onError('Multiple meshes are contained in the model imported from URL ' + link)
        }
        const mesh = meshes[0] // It's safe due to the above assertion
        const isMeshCompletelyVisible = camera.isCompletelyInFrustum(mesh)
        if (isMeshCompletelyVisible) return // No need to re-adjust the perspective since it's already fully visible

        camera.framingBehavior!.zoomOnMesh(mesh)
      },
      function (progressEvent: any) {}, // onProgressCallback
      function (scene: any, message: any, exceptionObject: any) {}, // onErrorCallback
      '.stl' // force file type since the AWS link seems to obscure the URL (BJS fails to detect it automatically...)
    )
  }

  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = (scene: Scene) => {}

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  if (error) {
    return <Alert severity="error">{t('content.label.no3dPreview')}</Alert>
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
    </div>
  )
}
