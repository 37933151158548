/* -------------------------- Design imports start -------------------------- */
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import { toast } from 'react-toastify'
import { Grid, Typography } from '@mui/material'
import { Form } from '../../Form'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import GetConstant from '../../Customer/utils/constants'
import { FormFieldType } from '../../Form'
import { missingRequiredFormFields } from '../../../utils/functions'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onConfirm?: (input: any) => Promise<'SUCCESS' | 'ERROR'>
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function AddressDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'AddressDrawer', enable: true, logLevel: 'info' })
  const { open, formState, setOpen, onClose = () => null, onConfirm = (input: any) => null } = props

  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Data states start --------------------------- */

  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------- Utility functions start ------------------------ */
  // some API functions like fetch, create, ...
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = (preventOnClose: boolean = false) => {
    !preventOnClose && onClose()
  }

  const handleConfirm = async () => {
    // posting request to server and waiting on response
    const confirmStatus = await onConfirm(formState.state)

    // visual feedback: handle server response status
    if (confirmStatus === 'SUCCESS') {
      toast.success(t('address:feedback.success.creatingAddress'))
    } else {
      toast.error(t('address:feedback.error.creatingAddress'))
    }
    handleClose(true)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const addressFormFields = GetConstant({ name: 'addressFormFields' }) as FormFieldType[]
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <MultilevelDrawer
      open={open}
      setOpen={setOpen}
      size="big"
      title={t('address:content.heading.createNewAddress') as string}
      onClose={handleClose}
      confirmButtonProps={{
        text: t('common:interaction.button.add'),
        onConfirm: handleConfirm,
        disabled: missingRequiredFormFields(addressFormFields, formState.state),
      }}
    >
      <Typography sx={{ mb: 2, mt: 4 }} variant="h6">
        {t('common:content.label.address')}
      </Typography>
      <Grid container>
        <Form
          editing={true}
          formFields={addressFormFields}
          formObject={formState.state}
          onChange={(value: any) => formState.setState(value)}
        />
      </Grid>
    </MultilevelDrawer>
  )
}
