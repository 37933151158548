/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APICustomer,
    APICustomerFromJSON,
    APICustomerToJSON,
    APICustomerRequest,
    APICustomerRequestFromJSON,
    APICustomerRequestToJSON,
    APIPaginatedCustomerList,
    APIPaginatedCustomerListFromJSON,
    APIPaginatedCustomerListToJSON,
    APIPatchedCustomerRequest,
    APIPatchedCustomerRequestFromJSON,
    APIPatchedCustomerRequestToJSON,
} from '../models';

export interface V1CustomerCustomersCreateRequest {
    aPICustomerRequest: APICustomerRequest;
}

export interface V1CustomerCustomersDestroyRequest {
    id: number;
}

export interface V1CustomerCustomersListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1CustomerCustomersPartialUpdateRequest {
    id: number;
    aPIPatchedCustomerRequest?: APIPatchedCustomerRequest;
}

export interface V1CustomerCustomersRetrieveRequest {
    id: number;
}

export interface V1CustomerCustomersUpdateRequest {
    id: number;
    aPICustomerRequest: APICustomerRequest;
}

/**
 * 
 */
export class CustomercustomersApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersCreateRaw(requestParameters: V1CustomerCustomersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomer>> {
        if (requestParameters.aPICustomerRequest === null || requestParameters.aPICustomerRequest === undefined) {
            throw new runtime.RequiredError('aPICustomerRequest','Required parameter requestParameters.aPICustomerRequest was null or undefined when calling v1CustomerCustomersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APICustomerRequestToJSON(requestParameters.aPICustomerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersCreate(requestParameters: V1CustomerCustomersCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomer> {
        const response = await this.v1CustomerCustomersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersDestroyRaw(requestParameters: V1CustomerCustomersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersDestroy(requestParameters: V1CustomerCustomersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1CustomerCustomersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersListRaw(requestParameters: V1CustomerCustomersListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedCustomerList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedCustomerListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersList(requestParameters: V1CustomerCustomersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedCustomerList> {
        const response = await this.v1CustomerCustomersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersPartialUpdateRaw(requestParameters: V1CustomerCustomersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedCustomerRequestToJSON(requestParameters.aPIPatchedCustomerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersPartialUpdate(requestParameters: V1CustomerCustomersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomer> {
        const response = await this.v1CustomerCustomersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersRetrieveRaw(requestParameters: V1CustomerCustomersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersRetrieve(requestParameters: V1CustomerCustomersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomer> {
        const response = await this.v1CustomerCustomersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersUpdateRaw(requestParameters: V1CustomerCustomersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APICustomer>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersUpdate.');
        }

        if (requestParameters.aPICustomerRequest === null || requestParameters.aPICustomerRequest === undefined) {
            throw new runtime.RequiredError('aPICustomerRequest','Required parameter requestParameters.aPICustomerRequest was null or undefined when calling v1CustomerCustomersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APICustomerRequestToJSON(requestParameters.aPICustomerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APICustomerFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1CustomerCustomersUpdate(requestParameters: V1CustomerCustomersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APICustomer> {
        const response = await this.v1CustomerCustomersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
