/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const APINullEnum = {
    Null: 'null'
} as const;
export type APINullEnum = typeof APINullEnum[keyof typeof APINullEnum];


export function APINullEnumFromJSON(json: any): APINullEnum {
    return APINullEnumFromJSONTyped(json, false);
}

export function APINullEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): APINullEnum {
    return json as APINullEnum;
}

export function APINullEnumToJSON(value?: APINullEnum | null): any {
    return value as any;
}

