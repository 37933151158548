/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedShippingList,
    APIPaginatedShippingListFromJSON,
    APIPaginatedShippingListToJSON,
    APIPatchedShippingRequest,
    APIPatchedShippingRequestFromJSON,
    APIPatchedShippingRequestToJSON,
    APIShipping,
    APIShippingFromJSON,
    APIShippingToJSON,
    APIShippingRequest,
    APIShippingRequestFromJSON,
    APIShippingRequestToJSON,
} from '../models';

export interface V1LogisticsShippingCreateRequest {
    aPIShippingRequest: APIShippingRequest;
}

export interface V1LogisticsShippingDestroyRequest {
    id: number;
}

export interface V1LogisticsShippingListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1LogisticsShippingPartialUpdateRequest {
    id: number;
    aPIPatchedShippingRequest?: APIPatchedShippingRequest;
}

export interface V1LogisticsShippingRetrieveRequest {
    id: number;
}

export interface V1LogisticsShippingUpdateRequest {
    id: number;
    aPIShippingRequest: APIShippingRequest;
}

/**
 * 
 */
export class LogisticsshippingApi extends runtime.BaseAPI {

    /**
     */
    async v1LogisticsShippingCreateRaw(requestParameters: V1LogisticsShippingCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIShipping>> {
        if (requestParameters.aPIShippingRequest === null || requestParameters.aPIShippingRequest === undefined) {
            throw new runtime.RequiredError('aPIShippingRequest','Required parameter requestParameters.aPIShippingRequest was null or undefined when calling v1LogisticsShippingCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/shipping/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIShippingRequestToJSON(requestParameters.aPIShippingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIShippingFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsShippingCreate(requestParameters: V1LogisticsShippingCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIShipping> {
        const response = await this.v1LogisticsShippingCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsShippingDestroyRaw(requestParameters: V1LogisticsShippingDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsShippingDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/shipping/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v1LogisticsShippingDestroy(requestParameters: V1LogisticsShippingDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1LogisticsShippingDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v1LogisticsShippingListRaw(requestParameters: V1LogisticsShippingListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedShippingList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/shipping/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedShippingListFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsShippingList(requestParameters: V1LogisticsShippingListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedShippingList> {
        const response = await this.v1LogisticsShippingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsShippingPartialUpdateRaw(requestParameters: V1LogisticsShippingPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIShipping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsShippingPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/shipping/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedShippingRequestToJSON(requestParameters.aPIPatchedShippingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIShippingFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsShippingPartialUpdate(requestParameters: V1LogisticsShippingPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIShipping> {
        const response = await this.v1LogisticsShippingPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsShippingRetrieveRaw(requestParameters: V1LogisticsShippingRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIShipping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsShippingRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/shipping/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIShippingFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsShippingRetrieve(requestParameters: V1LogisticsShippingRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIShipping> {
        const response = await this.v1LogisticsShippingRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1LogisticsShippingUpdateRaw(requestParameters: V1LogisticsShippingUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIShipping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1LogisticsShippingUpdate.');
        }

        if (requestParameters.aPIShippingRequest === null || requestParameters.aPIShippingRequest === undefined) {
            throw new runtime.RequiredError('aPIShippingRequest','Required parameter requestParameters.aPIShippingRequest was null or undefined when calling v1LogisticsShippingUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/logistics/shipping/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIShippingRequestToJSON(requestParameters.aPIShippingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIShippingFromJSON(jsonValue));
    }

    /**
     */
    async v1LogisticsShippingUpdate(requestParameters: V1LogisticsShippingUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIShipping> {
        const response = await this.v1LogisticsShippingUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
