/* -------------------------- Design imports start -------------------------- */
import { Card, Container } from '@mui/material'
import { Alert, AlertTitle } from '@mui/lab'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import { ErrorBoundary as Boundary } from 'react-error-boundary'
import Button from '../Button'
/* ------------------------- Functional imports end ------------------------- */
interface Props {
  children: React.ReactNode
}

interface ErrorState {
  error: Error | null
  componentStack: string
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ErrorBoundary({ children }: Props) {
  /* -------------------------- Non state data start -------------------------- */
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [errorState, setErrorState] = useState<ErrorState>({
    error: null,
    componentStack: '',
  })
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleError = (error: Error, componentStack: string) => {
    setErrorState({ error, componentStack })
    console.error(error, componentStack)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Boundary
      fallbackRender={() => (
        <Container maxWidth="sm">
          <Card variant='outlined'>
            <Alert severity="error">
              <AlertTitle>Oops.. Something went wrong</AlertTitle>
              There must have been an error here. Please try again later or contact us with the
              following info:
              <pre>{errorState.error && errorState.error.message}</pre>
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                onClick={() => alert('TODO: Implement')}
              >
                Fehler übermitteln
              </Button>
            </Alert>
          </Card>
        </Container>
      )}
      onError={(error, { componentStack }) => handleError(error, componentStack)}
    >
      {children}
    </Boundary>
  )
}
