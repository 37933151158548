/* -------------------------- Design imports start -------------------------- */
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect } from 'react'
import { Version } from '../utils/types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import VersionTimeline from './VersionTimeline'
import { handleAPICallV1 } from '../../../utils/functions'
import { HTTPMethod } from '../../../utils/types'
import LogTool from '../../../logger/logTools'
import CompareVersionDrawer from './CompareVersionDrawer'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onConfirm: () => void
  onRevertVersion: (version: Version) => void
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function VersionDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, formState, onConfirm } = props
  const log = new LogTool({ context: 'VersionDrawer', enable: false, logLevel: 'warn' })
  const { t } = useTranslation(['common'])
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const [versions, setVersions] = React.useState<Version[]>([])
  const [versionDifferences, setVersionDifferences] = React.useState<any | undefined>(undefined)
  const [compareOpen, setCompareOpen] = React.useState<boolean>(false)
  const [revertVersion, setRevertVersion] = React.useState<Version | undefined>(undefined)
  /* ----------------------------- Data states end ---------------------------- */

  log.debug('formStateVersion', formState)
  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (open) {
      fetchVersions(formState.state.self)
    }
  }, [formState.state, open])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  async function fetchVersions(object_self: string) {
    log.debug('fetchVersions', object_self)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      object_self + 'versions/',
      undefined,
      undefined
    )

    if (response) {
      log.debug('Versions -> ', response)
      setVersions(response)
    }
    if (error) {
      log.error(error)
    }
  }


  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = () => {
    setOpen(false)
  }

  const handleRevertVersion = async (version: Version) => {
    setRevertVersion(version)
    props.onRevertVersion(version)
  }
  /* ------------------------- Callback functions end ------------------------- */
  /* ------------------------- Render constants start ------------------------- */

  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        size="small"
        onClose={handleClose}
        title={t('common:content.label.versionHistory') as string}
        confirmButtonProps={{
          // hide the confirm button
          hidden: true,
          text: '',
          disabled: true,
          // do nothing on confirm
          onConfirm: async () => {},
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VersionTimeline  versions={versions} formState={formState} setOpen={setOpen} setVersionDifferences={setVersionDifferences} setCompareOpen={setCompareOpen} onRevertVersion={handleRevertVersion } />
          </Grid>
        </Grid>
      </MultilevelDrawer>
      <CompareVersionDrawer
        open={compareOpen}
        setOpen={setCompareOpen}
        formState={formState}
        versionDifferences={versionDifferences}
        onConfirm={onConfirm}
      />
    </>
  )
}
