/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIStorageArticle
 */
export interface APIStorageArticle {
    /**
     * 
     * @type {string}
     * @memberof APIStorageArticle
     */
    readonly self: string;
    /**
     * 
     * @type {number}
     * @memberof APIStorageArticle
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof APIStorageArticle
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof APIStorageArticle
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof APIStorageArticle
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof APIStorageArticle
     */
    article: string;
}

export function APIStorageArticleFromJSON(json: any): APIStorageArticle {
    return APIStorageArticleFromJSONTyped(json, false);
}

export function APIStorageArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIStorageArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'id': json['id'],
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'position': json['position'],
        'article': json['article'],
    };
}

export function APIStorageArticleToJSON(value?: APIStorageArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'comment': value.comment,
        'position': value.position,
        'article': value.article,
    };
}

