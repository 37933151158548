/* -------------------------- Design imports start -------------------------- */
import { Form, FormFieldType } from '../../Form'
import {  Grid, Typography } from '@mui/material'
import FileInput from '../../../components/inputs/FileInput'
import Page from '../../../components/layout/Page'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import LogTool from '../../../logger/logTools'
import { APIContract, APIContractFromJSON } from '../../../generated-types'
import {
  handleAPICallV1,
  uploadFile,
  deleteFile
} from '../../../utils/functions'
import { FileInfo, FileUploadStatus, HTTPMethod, SelectOption } from '../../../utils/types'
import { inputToAPIContractJSON } from '.././utils/functions'
import { toast } from 'react-toastify'
import GetConstant from '.././utils/constants'
import InputFileList from '../../../components/widgets/InputFileList'
import { LoadingButton } from '@mui/lab'
import { Table } from 'antd'
import { ColumnProps } from 'antd/es/table'
import Button from '../../../components/Button'

/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ContractDetailsPage() {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'ContractPage', enable: true, logLevel: 'warn' })
  type ColumnsType = Array<ColumnProps<APIContract>>
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [waitingOnAction, setWaiting] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const { t } = useTranslation(['contract', 'common'])
  const history = useHistory()
  const location = useLocation<any>()
  const selectedContractData = location.state?.selectedContractData || null
  const [formInput, setFormInput] = useState<any>(selectedContractData || {})
  const [formInputShippingAddress, setFormInputShippingAddress] = useState<any>({})
  const [formInputBillingAddress, setFormInputBillingAddress] = useState<any>({})
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  /* ----------------------------- Data states end ---------------------------- */
  log.debug('selectedContractData', selectedContractData)

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (selectedContractData) {
      handleAddressSplittings()
      setFormInput({
        ...selectedContractData,
        certificates: {
          value: selectedContractData?.certificate,
          label: selectedContractData?.certificate,
        },
      })
    }
  }, [selectedContractData])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadContractFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileInput(prev =>
          prev.map((listedFile: FileInfo) => {
            if (listedFile.name === file.name) {
              return { ...response, uploadStatus: 'success' }
            }
            return listedFile
          })
        )
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  const handleUpdateRequest = async (contractInput: any) => {
    contractInput.article = formInput.articleKey
    log.info('Begin creating contract request')
    // set loading button to loading state
    setWaiting(true)
    // set loading state on all files to loading
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    log.debug('contractInput', contractInput)
    // posting request to server and waiting on response
    contractInput.certificate = contractInput.certificate?.value
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PUT,
      formInput.self || '',
      undefined,
      inputToAPIContractJSON(contractInput)
    )
    if (!error && response) {
      log.info('Contract request created successfully')
      const contract = APIContractFromJSON(response)
      // check if the user specified files to upload !
      if (fileInput.length > 0) {
        // upload the files one by one for a more detailed feedback if something goes wrong
        // and to make use parallel uploading to decrease upload time
        for (const file of fileInput) {
          uploadContractFile(file, contract.files as string)
        }
      }
      toast.success(t('contract:feedback.success.contractUpdated'))
    } else {
      log.error('Error creating contract request', error)
      toast.error(t('contract:feedback.error.contractNotUpdated'))
    }
    setWaiting(false)
    history.push('/contracts')
  }

  const handleCancel = () => {
    history.push('/contracts')
  }

  const handleRequest = () => {
    const shippingAddress = [
      formInputShippingAddress.address_1 || '',
      formInputShippingAddress.address_2 || '',
      formInputShippingAddress.address_3 || '',
      formInputShippingAddress.zip_code || '',
      formInputShippingAddress.city || '',
      formInputShippingAddress.country || '',
    ]
      .filter(Boolean)
      .join(', ')

    const billingAddress = [
      formInputBillingAddress.billing_address_1 || '',
      formInputBillingAddress.billing_address_2 || '',
      formInputBillingAddress.billing_address_3 || '',
      formInputBillingAddress.billing_zip_code || '',
      formInputBillingAddress.billing_city || '',
      formInputBillingAddress.billing_country || '',
    ]
      .filter(Boolean)
      .join(', ')

    const requestData = {
      billingAddress: billingAddress,
      desiredDelivery: formInput.desired_delivery,
      latestDelivery: formInput.latest_delivery,
      comment: formInput.comment,
      certificate: formInput.certificates,
      quantity: formInput.quantity,
      article: formInput.article,
      shippingAddress: shippingAddress,
      priorityShipping: formInput.priorityShipping,
    }
    handleUpdateRequest(requestData)
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }

  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadContractFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }

  const handleAddressSplittings = () => {
    const addressParts = formInput?.shipping_address?.split(', ')
    const addressPartsBilling = formInput?.billing_address?.split(', ')
    log.debug('addressPartsBilling', addressPartsBilling)

    const addressObj = {
      address_1: addressParts[0],
      zip_code: addressParts[1],
      city: addressParts[2],
      country: addressParts[3],
    }
    const addressObjBilling = {
      billing_address_1: addressPartsBilling[0],
      billing_zip_code: addressPartsBilling[1],
      billing_city: addressPartsBilling[2],
      billing_country: addressPartsBilling[3],
    }

    setFormInputShippingAddress(addressObj)
    setFormInputBillingAddress(addressObjBilling)
  }

  /* ------------------------- Callback functions end ------------------------- */

  log.debug('formInputBillingAddress', formInputBillingAddress)
  log.debug('selectedContractData', selectedContractData)
  /* ------------------------- Render constants start ------------------------- */
  const certificateOptions = GetConstant({ name: 'certificateOptions' }) as SelectOption[]
  const contractFormFields = GetConstant({
    name: 'contractFormFieldsDetailsEditable',
    certificateOptions: certificateOptions,
    selectedProduct: selectedContractData?.articleKey,
  }) as FormFieldType[]
  const contractFormFieldsDetails = GetConstant({
    name: 'contractFormFieldsDetails',
  }) as FormFieldType[]
  const addressFormFields = GetConstant({ name: 'addressFormFields' }) as FormFieldType[]
  const billingAddressFormFields = GetConstant({
    name: 'billingAddressFormFields',
  }) as FormFieldType[]

  const statusTableColumns = [
    {
      title: t('common:content.label.status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('common:content.label.date'),
      dataIndex: 'date',
      key: 'date',
    },
  ]

  /* -------------------------- Render constants end -------------------------- */

  log.debug('formInput', formInput)
  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <Typography variant="h6" style={{ marginBottom: '10px', marginTop: '20px' }}>
        {t('contract:content.label.requestedProduct')}
      </Typography>
      <div style={{ marginBottom: '20px' }}>
        <Form
          formObject={formInput}
          formFields={contractFormFieldsDetails}
          onChange={value => setFormInput(value as APIContract)}
          editing={false}
        />
      </div>
      <Form
        formObject={formInput}
        formFields={contractFormFields}
        onChange={value => setFormInput(value as APIContract)}
        editing={true}
      />
      <Typography variant="h6" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('address:content.label.shippingAddress')}
      </Typography>

      <Form
        formObject={formInputShippingAddress}
        formFields={addressFormFields}
        onChange={value => setFormInputShippingAddress(value as APIContract)}
        editing={true}
      />

      <Typography variant="h6" style={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('address:content.label.billingAddress')}
      </Typography>
      {formInputBillingAddress && (
        <Form
          formObject={formInputBillingAddress}
          formFields={billingAddressFormFields}
          onChange={value => setFormInputBillingAddress(value as APIContract)}
          editing={true}
        />
      )}
      <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('common:content.label.documents')}
      </Typography>
      <FileInput inputState={{ state: fileInput, setState: setFileInput }} maxAllowedFiles={10} />
      {fileInput.length > 0 && (
        <InputFileList
          files={fileInput}
          noFilesInfo=""
          enableEditMode={false}
          onRemoveFile={handleRemoveFileFromList}
          onReUploadFile={handleReUploadFile}
          onDeleteFile={handleDeleteFile}
        />
      )}
      <Table style={{ marginTop: '20px' }} columns={statusTableColumns} />
      {/* <S3FileUpload />
      <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
        {t('common:content.label.cadModels')}
      </Typography>
      <CADFileUpload /> */}
      <Grid container justifyContent="flex-end" sx={{ marginTop: '20px' }}>
        <Button
          id="cancelButton"
          style={{ marginRight: '10px' }}
          color="primary"
          variant="contained"
          onClick={handleCancel}
        >
          {t('common:interaction.button.cancel')}
        </Button>
        <LoadingButton
          id="saveButton"
          variant="contained"
          color="primary"
          onClick={handleRequest}
          loading={waitingOnAction}
        >
          {t('common:interaction.button.save')}
        </LoadingButton>
      </Grid>
    </Page>
  )
}
