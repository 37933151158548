/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIBrand,
    APIBrandFromJSON,
    APIBrandToJSON,
    APIBrandRequest,
    APIBrandRequestFromJSON,
    APIBrandRequestToJSON,
    APIPaginatedBrandList,
    APIPaginatedBrandListFromJSON,
    APIPaginatedBrandListToJSON,
    APIPatchedBrandRequest,
    APIPatchedBrandRequestFromJSON,
    APIPatchedBrandRequestToJSON,
} from '../models';

export interface V1ItemsBrandsCreateRequest {
    aPIBrandRequest: APIBrandRequest;
}

export interface V1ItemsBrandsDestroyRequest {
    id: number;
}

export interface V1ItemsBrandsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsBrandsPartialUpdateRequest {
    id: number;
    aPIPatchedBrandRequest?: APIPatchedBrandRequest;
}

export interface V1ItemsBrandsRetrieveRequest {
    id: number;
}

export interface V1ItemsBrandsUpdateRequest {
    id: number;
    aPIBrandRequest: APIBrandRequest;
}

/**
 * 
 */
export class ItemsbrandsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsCreateRaw(requestParameters: V1ItemsBrandsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.aPIBrandRequest === null || requestParameters.aPIBrandRequest === undefined) {
            throw new runtime.RequiredError('aPIBrandRequest','Required parameter requestParameters.aPIBrandRequest was null or undefined when calling v1ItemsBrandsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIBrandRequestToJSON(requestParameters.aPIBrandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsCreate(requestParameters: V1ItemsBrandsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsDestroyRaw(requestParameters: V1ItemsBrandsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsDestroy(requestParameters: V1ItemsBrandsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsBrandsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsListRaw(requestParameters: V1ItemsBrandsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedBrandList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedBrandListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsList(requestParameters: V1ItemsBrandsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedBrandList> {
        const response = await this.v1ItemsBrandsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsPartialUpdateRaw(requestParameters: V1ItemsBrandsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedBrandRequestToJSON(requestParameters.aPIPatchedBrandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsPartialUpdate(requestParameters: V1ItemsBrandsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsRetrieveRaw(requestParameters: V1ItemsBrandsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsRetrieve(requestParameters: V1ItemsBrandsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsUpdateRaw(requestParameters: V1ItemsBrandsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIBrand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsBrandsUpdate.');
        }

        if (requestParameters.aPIBrandRequest === null || requestParameters.aPIBrandRequest === undefined) {
            throw new runtime.RequiredError('aPIBrandRequest','Required parameter requestParameters.aPIBrandRequest was null or undefined when calling v1ItemsBrandsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/brands/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIBrandRequestToJSON(requestParameters.aPIBrandRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIBrandFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsBrandsUpdate(requestParameters: V1ItemsBrandsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIBrand> {
        const response = await this.v1ItemsBrandsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
