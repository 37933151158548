/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIGuestUser,
    APIGuestUserFromJSON,
    APIGuestUserFromJSONTyped,
    APIGuestUserToJSON,
} from './APIGuestUser';

/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIFeedbackSubmission
 */
export interface APIFeedbackSubmission {
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackSubmission
     */
    readonly self: string;
    /**
     * The submission for feedback_form decoded as s JSON string.
     * @type {{ [key: string]: any; }}
     * @memberof APIFeedbackSubmission
     */
    submission: { [key: string]: any; };
    /**
     * 
     * @type {Date}
     * @memberof APIFeedbackSubmission
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof APIFeedbackSubmission
     */
    readAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackSubmission
     */
    article: string;
    /**
     * 
     * @type {string}
     * @memberof APIFeedbackSubmission
     */
    feedbackForm: string;
    /**
     * 
     * @type {APIGuestUser}
     * @memberof APIFeedbackSubmission
     */
    submittedBy: APIGuestUser;
}

export function APIFeedbackSubmissionFromJSON(json: any): APIFeedbackSubmission {
    return APIFeedbackSubmissionFromJSONTyped(json, false);
}

export function APIFeedbackSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIFeedbackSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'submission': json['submission'],
        'createdAt': (new Date(json['created_at'])),
        'readAt': !exists(json, 'read_at') ? undefined : (json['read_at'] === null ? null : new Date(json['read_at'])),
        'article': json['article'],
        'feedbackForm': json['feedback_form'],
        'submittedBy': APIGuestUserFromJSON(json['submitted_by']),
    };
}

export function APIFeedbackSubmissionToJSON(value?: APIFeedbackSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'submission': value.submission,
        'read_at': value.readAt === undefined ? undefined : (value.readAt === null ? null : value.readAt.toISOString()),
        'article': value.article,
        'feedback_form': value.feedbackForm,
        'submitted_by': APIGuestUserToJSON(value.submittedBy),
    };
}

