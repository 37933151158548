/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedTypeList,
    APIPaginatedTypeListFromJSON,
    APIPaginatedTypeListToJSON,
    APIPatchedTypeRequest,
    APIPatchedTypeRequestFromJSON,
    APIPatchedTypeRequestToJSON,
    APIType,
    APITypeFromJSON,
    APITypeToJSON,
    APITypeRequest,
    APITypeRequestFromJSON,
    APITypeRequestToJSON,
} from '../models';

export interface V1ItemsTypesCreateRequest {
    aPITypeRequest: APITypeRequest;
}

export interface V1ItemsTypesDestroyRequest {
    id: number;
}

export interface V1ItemsTypesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsTypesPartialUpdateRequest {
    id: number;
    aPIPatchedTypeRequest?: APIPatchedTypeRequest;
}

export interface V1ItemsTypesRetrieveRequest {
    id: number;
}

export interface V1ItemsTypesUpdateRequest {
    id: number;
    aPITypeRequest: APITypeRequest;
}

/**
 * 
 */
export class ItemstypesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesCreateRaw(requestParameters: V1ItemsTypesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.aPITypeRequest === null || requestParameters.aPITypeRequest === undefined) {
            throw new runtime.RequiredError('aPITypeRequest','Required parameter requestParameters.aPITypeRequest was null or undefined when calling v1ItemsTypesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APITypeRequestToJSON(requestParameters.aPITypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesCreate(requestParameters: V1ItemsTypesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesDestroyRaw(requestParameters: V1ItemsTypesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesDestroy(requestParameters: V1ItemsTypesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsTypesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesListRaw(requestParameters: V1ItemsTypesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedTypeList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedTypeListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesList(requestParameters: V1ItemsTypesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedTypeList> {
        const response = await this.v1ItemsTypesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesPartialUpdateRaw(requestParameters: V1ItemsTypesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedTypeRequestToJSON(requestParameters.aPIPatchedTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesPartialUpdate(requestParameters: V1ItemsTypesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesRetrieveRaw(requestParameters: V1ItemsTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesRetrieve(requestParameters: V1ItemsTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesUpdateRaw(requestParameters: V1ItemsTypesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIType>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsTypesUpdate.');
        }

        if (requestParameters.aPITypeRequest === null || requestParameters.aPITypeRequest === undefined) {
            throw new runtime.RequiredError('aPITypeRequest','Required parameter requestParameters.aPITypeRequest was null or undefined when calling v1ItemsTypesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APITypeRequestToJSON(requestParameters.aPITypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITypeFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsTypesUpdate(requestParameters: V1ItemsTypesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIType> {
        const response = await this.v1ItemsTypesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
