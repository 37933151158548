/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIContactPreferredContactMethod,
    APIContactPreferredContactMethodFromJSON,
    APIContactPreferredContactMethodFromJSONTyped,
    APIContactPreferredContactMethodToJSON,
} from './APIContactPreferredContactMethod';
import {
    APIProductionOrderStatusEnum,
    APIProductionOrderStatusEnumFromJSON,
    APIProductionOrderStatusEnumFromJSONTyped,
    APIProductionOrderStatusEnumToJSON,
} from './APIProductionOrderStatusEnum';

/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIProductionOrder
 */
export interface APIProductionOrder {
    /**
     * 
     * @type {string}
     * @memberof APIProductionOrder
     */
    readonly self: string;
    /**
     * 
     * @type {Date}
     * @memberof APIProductionOrder
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof APIProductionOrder
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof APIProductionOrder
     */
    customerOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof APIProductionOrder
     */
    article: string;
    /**
     * 
     * @type {APIProductionOrderStatusEnum}
     * @memberof APIProductionOrder
     */
    status?: APIProductionOrderStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof APIProductionOrder
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof APIProductionOrder
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof APIProductionOrder
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof APIProductionOrder
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {APIContactPreferredContactMethod}
     * @memberof APIProductionOrder
     */
    planning?: APIContactPreferredContactMethod | null;
    /**
     * 
     * @type {number}
     * @memberof APIProductionOrder
     */
    priority?: number | null;
}

export function APIProductionOrderFromJSON(json: any): APIProductionOrder {
    return APIProductionOrderFromJSONTyped(json, false);
}

export function APIProductionOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIProductionOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'customerOrder': !exists(json, 'customer_order') ? undefined : json['customer_order'],
        'article': json['article'],
        'status': !exists(json, 'status') ? undefined : APIProductionOrderStatusEnumFromJSON(json['status']),
        'amount': json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'createdAt': (new Date(json['created_at'])),
        'planning': !exists(json, 'planning') ? undefined : APIContactPreferredContactMethodFromJSON(json['planning']),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
    };
}

export function APIProductionOrderToJSON(value?: APIProductionOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'customer_order': value.customerOrder,
        'article': value.article,
        'status': APIProductionOrderStatusEnumToJSON(value.status),
        'amount': value.amount,
        'description': value.description,
        'comment': value.comment,
        'planning': APIContactPreferredContactMethodToJSON(value.planning),
        'priority': value.priority,
    };
}

