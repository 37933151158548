import LogTool from "../../../logger/logTools"
import { buildFetchUrl, getPKfromSelf, handleAPICallV1 } from "../../../utils/functions"
import { HTTPMethod } from "../../../utils/types"
import { Contract } from "./types"

const log = new LogTool({context: "contractUtils", enable: true, logLevel: 'warn'})
/* -------------------------------------------------------------------------- */
/*                              Utility functions                             */
/* -------------------------------------------------------------------------- */

export function createContract(responseObject: any): Contract {
    return {
        key: getPKfromSelf(responseObject.self),
        self: responseObject.self,
        createdAt: responseObject.created_at,
        article: responseObject.article,
        request: responseObject.request,
        contractStatus: responseObject.contract_status,
        comment: responseObject.comment,
        desiredDelivery: responseObject.desired_delivery,
        latestDelivery: responseObject.latest_delivery,
        shippingAddress: responseObject.shipping_address,
        billingAddress: responseObject.billing_address,
        priorityShipping: responseObject.priority_shipping,
        files: responseObject.files,
        offer: responseObject.offer,
        editors: responseObject.editors,
        price: responseObject.price,
        read: responseObject.read,
    }
}

export function createBill(responseObject: any): any {
    return {
        key: getPKfromSelf(responseObject.self),
        self: responseObject.self,
        createdAt: responseObject.created_at,
        dueDate: responseObject.due_date,
        amount: responseObject.amount,
        paid: responseObject.paid,
        files: responseObject.files,
        contract: responseObject.contract,
        editors: responseObject.editors,
        read: responseObject.read,
    }
}

export function inputToAPIContractJSON(c: any): any {
    const offer: string = typeof c.offer === "string" ? c.offer : c.offer?.self
    const shippingAddress: string = typeof c.shippingAddress === "string" ? c.shippingAddress : c.shippingAddress?.value
    const billingAddress: string = typeof c.billingAddress === "string" ? c.billingAddress : c.billingAddress?.value
    return {
        ...(c.createdAt && {'created_at': c.createdAt}),
        ...(c.comment && {'comment': c.comment}),
        ...(c.desiredDelivery && {'desired_delivery': c.desiredDelivery}),
        ...(c.latestDelivery && {'latest_delivery': c.latestDelivery}),
        ...(c.shippingAddress && {'shipping_address': shippingAddress}),
        ...(c.billingAddress && {'billing_address': billingAddress}),
        ...(c.priorityShipping && {'priority_shipping': c.priorityShipping}),
        ...(c.price && {'price': c.price}),
        ...(c.offer && {'offer': offer}),
        ...(c.editors && { editors: c.editors.map((editor: any) => editor?.value?.self ? editor.value.self : editor.self)}),
        ...(c.contractStatus && {'contract_status': c.contractStatus.value ? c.contractStatus.value : c.contractStatus}),
        ...(c.self && {'self': c.self}),
    }
  }

export function inputToAPIBillJSON(b: any): any {
    return {
        ...(b.createdAt && {'created_at': b.createdAt}),
        ...(b.dueDate && {'due_date': b.dueDate}),
        ...(b.amount && {'amount': b.amount}),
        ...(b.paid !== undefined && {'paid': b.paid}),
        ...(b.contract && {'contract': b.contract?.self ? b.contract.self : b.contract}),
        ...(b.editors && { editors: b.editors.map((editor: any) => editor?.value?.self ? editor.value.self : editor.self)}),
        ...(b.self && {'self': b.self}),
    }
}

export async function fetchContracts(
    kwargs:{
        onSuccess: (contracts: Contract[], count?: number) => void,
        onError?: (error: any) => void,
        parameter?: string[],
        url?: string}
    ): Promise<void> {
    const {onSuccess, onError =(error: any)=> null, parameter = [], url='contract/contracts/'} = kwargs
    log.info('Begin fetching contracts.')
    if(!url.match(/contract\/contracts\//)) {
        log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
        return
        }
    const fetchUrl = buildFetchUrl(url, parameter)
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl)
    if(!error && response) {
        log.info('Successfully fetched contracts.')
        const contracts = response.results.map((c: any) => createContract(c))
        const count = response.count
        if(response.next) {
            fetchContracts({onSuccess: onSuccess, onError: onError, parameter: parameter, url: response.next})
        }
        onSuccess(contracts, count)
    } else {
        log.error('Error fetching contracts.', error)
        onError(error)
    }
}

export async function fetchContract(
    url: string,
    onSuccess: (contract: Contract) => void,
    onError: (error: any) => void,
    parameter: string[] = []
): Promise<void> {
    log.info("Begin fetching contract");
    const fetchUrl = buildFetchUrl(url, parameter);
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);
    if(!error && response) {
        log.info("Success fetching contract, Response ->", response);
        const contract = createContract(response);
        onSuccess(contract);
    } else {
        log.error("Error fetching contract", error);
        onError(error);
    }

}


export async function postContract(
    c: any,
    files: any[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin posting contract");


    const json = inputToAPIContractJSON(c);
    log.debug("JSON -> ", json);
    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "contract/contracts/",
        {
            "Content-Type": "application/json",
        },
        json
    );

    if(!error && response) {
        log.info("Success posting contract, Response ->", response);
        const contract = createContract(response);
        const success = await postContractFiles(files, contract);
        if(!success) {
            log.error("Error posting contract files");
            onError("Error posting contract files");
            return;
        } else {
            log.info("Success posting contract files");
            onSuccess();
        }
    } else {
        log.error("Error posting contract", error);
        onError(error);
    }
}

async function postContractFiles(files: any[], contract: Contract) : Promise<boolean> {
    log.info("Begin posting contract files");
    const promises = files.map((file : any) => handleFileUpload(file, contract));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error posting contract files");
        return false;
    } else {
        log.info("Success posting contract files");
        return true;
    }
}

async function handleFileUpload(file: any, contract: Contract) : Promise<boolean> {
    log.info("Begin posting contract file");
    const formData = new FormData();
    formData.append('contract', contract.self)
    formData.append('document', file)
    log.debug("FormData -> ", formData)
    log.debug("File -> ", file)

    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "contract/files/",
        {
            "Content-Type": "multipart/form-data",
        },
        formData
    );

    if(!error && response) {
        log.info("Success posting contract file, Response ->", response);
        return true;
    } else {
        log.error("Error posting contract file", error);
        return false;
    }
    /* return false; */
}

export async function updateContract(
    c: Contract,
    files: any[],
    onSuccess: (contract: Contract) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin updating contract", c);
    const json = inputToAPIContractJSON(c);
    const [response, error] = await handleAPICallV1(
        HTTPMethod.PUT,
        c.self,
        {
            "Content-Type": "application/json",
        },
        json
    );
    if(!error && response) {
        log.info("Success updating contract, Response ->", response);
        const contract = createContract(response);
        const success = await postContractFiles(files, contract);
        if(!success) {
            log.error("Error posting contract files");
            onError("Error posting contract files");
            return;
        } else {
            log.info("Success posting contract files");
            onSuccess(contract);
        }
    } else {
        log.error("Error posting contract", error);
        onError(error);
    }
}

export async function deleteContracts(
    contracts: Contract[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin deleting contracts");
    const promises = contracts.map((contract : Contract) => deleteContract(contract));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error deleting contracts");
        onError("Error deleting contracts");
        return;
    } else {
        log.info("Success deleting contracts");
        onSuccess();
    }
}

async function deleteContract(contract: Contract) : Promise<boolean> {
    log.info("Begin deleting contract");
    const [response, error] = await handleAPICallV1(
        HTTPMethod.DELETE,
        contract.self,
        undefined,
        undefined,
        "text"
    );

    if(!error) {
        log.info("Success deleting contract, Response ->", response);
        return true;
    } else {
        log.error("Error deleting contract", error);
        return false;
    }
}

export async function fetchBills(
    kwargs:{
        onSuccess: (bills: any[], count?: number) => void,
        onError?: (error: any) => void,
        parameter?: string[],
        url?: string}
    ): Promise<void> {
    const {onSuccess, onError =(error: any)=> null, parameter = [], url='contract/bills/'} = kwargs
    log.info('Begin fetching bills.')
    if(!url.match(/contract\/bills\//)) {
        log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
        return
        }
    const fetchUrl = buildFetchUrl(url, parameter)
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl)
    if(!error && response) {
        log.info('Successfully fetched bills.')
        const bills = response.results.map((b: any) => createBill(b))
        const count = response.count
        if(response.next) {
            fetchBills({onSuccess: onSuccess, onError: onError, parameter: parameter, url: response.next})
        }
        onSuccess(bills, count)
    } else {
        log.error('Error fetching bills.', error)
        onError(error)
    }
}

export async function fetchBill(
    url: string,
    onSuccess: (bill: any) => void,
    onError: (error: any) => void,
    parameter: string[] = []
): Promise<void> {
    log.info("Begin fetching bill");
    const fetchUrl = buildFetchUrl(url, parameter);
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);
    if(!error && response) {
        log.info("Success fetching bill, Response ->", response);
        const bill = createBill(response);
        onSuccess(bill);
    } else {
        log.error("Error fetching bill", error);
        onError(error);
    }

}

export async function postBill(
    b: any,
    files: any[],
    onSuccess: (bill: any) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin posting bill");
    const json = inputToAPIBillJSON(b);
    log.debug("JSON -> ", json);
    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "contract/bills/",
        {
            "Content-Type": "application/json",
        },
        json
    );

    if(!error && response) {
        log.info("Success posting bill, Response ->", response);
        const bill = createBill(response);
        const success = await postBillFiles(files, bill);
        if(!success) {
            log.error("Error posting bill files");
            onError("Error posting bill files");
            return;
        } else {
            log.info("Success posting bill files");
            onSuccess(bill);
        }
    } else {
        log.error("Error posting bill", error);
        onError(error);
    }
}

async function postBillFiles(files: any[], bill: any) : Promise<boolean> {
    log.info("Begin posting bill files");
    const promises = files.map((file : any) => handleBillFileUpload(file, bill));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error posting bill files");
        return false;
    } else {
        log.info("Success posting bill files");
        return true;
    }
}

async function handleBillFileUpload(file: any, bill: any) : Promise<boolean> {
    log.info("Begin posting bill file");
    const formData = new FormData();
    formData.append('bill', bill.self)
    formData.append('document', file)
    log.debug("FormData -> ", formData)
    log.debug("File -> ", file)

    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "contract/files/",
        {
            "Content-Type": "multipart/form-data",
        },
        formData
    );

    if(!error && response) {
        log.info("Success posting bill file, Response ->", response);
        return true;
    } else {
        log.error("Error posting bill file", error);
        return false;
    }
    /* return false; */
}

export async function updateBill(
    b: any,
    files: any[],
    onSuccess: (bill: any) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin updating bill");
    const json = inputToAPIBillJSON(b);
    const [response, error] = await handleAPICallV1(
        HTTPMethod.PUT,
        b.self,
        {
            "Content-Type": "application/json",
        },
        json
    );
    if(!error && response) {
        log.info("Success updating bill, Response ->", response);
        const bill = createBill(response);
        const success = await postBillFiles(files, bill);
        if(!success) {
            log.error("Error posting bill files");
            onError("Error posting bill files");
            return;
        } else {
            log.info("Success posting bill files");
            onSuccess(bill);
        }
    } else {
        log.error("Error posting bill", error);
        onError(error);
    }
}

export async function deleteBills(
    bills: any[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin deleting bills");
    const promises = bills.map((bill : any) => deleteBill(bill));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error deleting bills");
        onError("Error deleting bills");
        return;
    } else {
        log.info("Success deleting bills");
        onSuccess();
    }
}

async function deleteBill(bill: any) : Promise<boolean> {
    log.info("Begin deleting bill");
    const [response, error] = await handleAPICallV1(
        HTTPMethod.DELETE,
        bill.self,
        undefined,
        undefined,
        "text"
    );

    if(!error) {
        log.info("Success deleting bill, Response ->", response);
        return true;
    } else {
        log.error("Error deleting bill", error);
        return false;
    }
}