/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedTicketList,
    APIPaginatedTicketListFromJSON,
    APIPaginatedTicketListToJSON,
    APIPatchedTicketRequest,
    APIPatchedTicketRequestFromJSON,
    APIPatchedTicketRequestToJSON,
    APITicket,
    APITicketFromJSON,
    APITicketToJSON,
    APITicketRequest,
    APITicketRequestFromJSON,
    APITicketRequestToJSON,
} from '../models';

export interface V1SupportTicketsCreateRequest {
    aPITicketRequest: APITicketRequest;
}

export interface V1SupportTicketsDestroyRequest {
    id: string;
}

export interface V1SupportTicketsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1SupportTicketsPartialUpdateRequest {
    id: string;
    aPIPatchedTicketRequest?: APIPatchedTicketRequest;
}

export interface V1SupportTicketsRetrieveRequest {
    id: string;
}

export interface V1SupportTicketsUpdateRequest {
    id: string;
    aPITicketRequest: APITicketRequest;
}

/**
 * 
 */
export class SupportticketsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsCreateRaw(requestParameters: V1SupportTicketsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.aPITicketRequest === null || requestParameters.aPITicketRequest === undefined) {
            throw new runtime.RequiredError('aPITicketRequest','Required parameter requestParameters.aPITicketRequest was null or undefined when calling v1SupportTicketsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APITicketRequestToJSON(requestParameters.aPITicketRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsCreate(requestParameters: V1SupportTicketsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsDestroyRaw(requestParameters: V1SupportTicketsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsDestroy(requestParameters: V1SupportTicketsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1SupportTicketsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsListRaw(requestParameters: V1SupportTicketsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedTicketList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedTicketListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsList(requestParameters: V1SupportTicketsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedTicketList> {
        const response = await this.v1SupportTicketsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsPartialUpdateRaw(requestParameters: V1SupportTicketsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedTicketRequestToJSON(requestParameters.aPIPatchedTicketRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsPartialUpdate(requestParameters: V1SupportTicketsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsRetrieveRaw(requestParameters: V1SupportTicketsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsRetrieve(requestParameters: V1SupportTicketsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsUpdateRaw(requestParameters: V1SupportTicketsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APITicket>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1SupportTicketsUpdate.');
        }

        if (requestParameters.aPITicketRequest === null || requestParameters.aPITicketRequest === undefined) {
            throw new runtime.RequiredError('aPITicketRequest','Required parameter requestParameters.aPITicketRequest was null or undefined when calling v1SupportTicketsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/support/tickets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APITicketRequestToJSON(requestParameters.aPITicketRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APITicketFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1SupportTicketsUpdate(requestParameters: V1SupportTicketsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APITicket> {
        const response = await this.v1SupportTicketsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
