/* -------------------------- Design imports start -------------------------- */
import { Card, CardContent, Grid } from '@mui/material'
import ChannelList from './ChannelList'
import ChatView from '../Message/ChatView'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
//import { useTranslation } from 'react-i18next'
import useMobileService from '../../../../hooks/useMobileService'
import { useChatContext } from '../../utils/context'
/* ------------------------- Functional imports end ------------------------- */

type Props = {}

/**
 * Container to overview the message system
 * Consists of:
 *  - ChannelList
 *  - ChatView
 */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ChannelOverview(props: Props) {
  const { activeChannel, setActiveChannel, createChannelMode } = useChatContext()
  /* -------------------------- Non state data start -------------------------- */
  //const headerMarginBottom = '20px' // makes later calculations a little bit easier to read
  const TabbarHeight = '95px'
  const ChatTextInputHeight = '155px'
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  //const { t } = useTranslation(['chat', 'common'])
  const isMobile = useMobileService()
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  if (isMobile) {
    return (
      <div>
        {!activeChannel && !createChannelMode ? (
          <ChannelList />
        ) : (
          <>
            {activeChannel && (
              <ChatView
                sx={{
                  flexGrow: 1,
                  height: `calc(100vh - ${TabbarHeight} - ${ChatTextInputHeight})`,
                }}
              />
            )}
          </>
        )}
      </div>
    )
  } else {
    return (
      <div>
        <Card
          variant="outlined" sx={{ height: '100%', mt: '2rem' }}
        >
          <CardContent sx={{ width: '100%', height: 'calc(100vh - 230px)' }}>
            <Grid container spacing={2} sx={{ height: 'inherit' }}>
              <Grid item xs={5}>
                <ChannelList
                  sx={{
                    width: '100%',
                    height: '1000px',
                    flexShrink: 0,
                    padding: '10px',
                  }}
                />
              </Grid>
              <Grid item xs={7} sx={{borderLeft: '1px solid #E0E0E0',}}>
                <ChatView
                  sx={{
                    flexGrow: 1,
                    paddingLeft: '10px',
                    height: `calc(100vh - ${TabbarHeight} - ${ChatTextInputHeight})`,
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    )
  }
}
