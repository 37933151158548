/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIFeedbackSubmission,
    APIFeedbackSubmissionFromJSON,
    APIFeedbackSubmissionToJSON,
    APIFeedbackSubmissionRequest,
    APIFeedbackSubmissionRequestFromJSON,
    APIFeedbackSubmissionRequestToJSON,
    APIPaginatedFeedbackSubmissionList,
    APIPaginatedFeedbackSubmissionListFromJSON,
    APIPaginatedFeedbackSubmissionListToJSON,
    APIPatchedFeedbackSubmissionRequest,
    APIPatchedFeedbackSubmissionRequestFromJSON,
    APIPatchedFeedbackSubmissionRequestToJSON,
} from '../models';

export interface V1ItemsFeedbackSubmissionsCreateRequest {
    aPIFeedbackSubmissionRequest: APIFeedbackSubmissionRequest;
}

export interface V1ItemsFeedbackSubmissionsDestroyRequest {
    id: number;
}

export interface V1ItemsFeedbackSubmissionsListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsFeedbackSubmissionsPartialUpdateRequest {
    id: number;
    aPIPatchedFeedbackSubmissionRequest?: APIPatchedFeedbackSubmissionRequest;
}

export interface V1ItemsFeedbackSubmissionsRetrieveRequest {
    id: number;
}

export interface V1ItemsFeedbackSubmissionsUpdateRequest {
    id: number;
    aPIFeedbackSubmissionRequest: APIFeedbackSubmissionRequest;
}

/**
 * 
 */
export class ItemsfeedbackSubmissionApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsCreateRaw(requestParameters: V1ItemsFeedbackSubmissionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackSubmission>> {
        if (requestParameters.aPIFeedbackSubmissionRequest === null || requestParameters.aPIFeedbackSubmissionRequest === undefined) {
            throw new runtime.RequiredError('aPIFeedbackSubmissionRequest','Required parameter requestParameters.aPIFeedbackSubmissionRequest was null or undefined when calling v1ItemsFeedbackSubmissionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_submissions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIFeedbackSubmissionRequestToJSON(requestParameters.aPIFeedbackSubmissionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackSubmissionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsCreate(requestParameters: V1ItemsFeedbackSubmissionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackSubmission> {
        const response = await this.v1ItemsFeedbackSubmissionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deleting a feedback submission is not allowed. This might become a feature in the future.
     */
    async v1ItemsFeedbackSubmissionsDestroyRaw(requestParameters: V1ItemsFeedbackSubmissionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackSubmissionsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_submissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deleting a feedback submission is not allowed. This might become a feature in the future.
     */
    async v1ItemsFeedbackSubmissionsDestroy(requestParameters: V1ItemsFeedbackSubmissionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsFeedbackSubmissionsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsListRaw(requestParameters: V1ItemsFeedbackSubmissionsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedFeedbackSubmissionList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_submissions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedFeedbackSubmissionListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsList(requestParameters: V1ItemsFeedbackSubmissionsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedFeedbackSubmissionList> {
        const response = await this.v1ItemsFeedbackSubmissionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsPartialUpdateRaw(requestParameters: V1ItemsFeedbackSubmissionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackSubmission>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackSubmissionsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_submissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedFeedbackSubmissionRequestToJSON(requestParameters.aPIPatchedFeedbackSubmissionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackSubmissionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsPartialUpdate(requestParameters: V1ItemsFeedbackSubmissionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackSubmission> {
        const response = await this.v1ItemsFeedbackSubmissionsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsRetrieveRaw(requestParameters: V1ItemsFeedbackSubmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackSubmission>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackSubmissionsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_submissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackSubmissionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsRetrieve(requestParameters: V1ItemsFeedbackSubmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackSubmission> {
        const response = await this.v1ItemsFeedbackSubmissionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsUpdateRaw(requestParameters: V1ItemsFeedbackSubmissionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIFeedbackSubmission>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsFeedbackSubmissionsUpdate.');
        }

        if (requestParameters.aPIFeedbackSubmissionRequest === null || requestParameters.aPIFeedbackSubmissionRequest === undefined) {
            throw new runtime.RequiredError('aPIFeedbackSubmissionRequest','Required parameter requestParameters.aPIFeedbackSubmissionRequest was null or undefined when calling v1ItemsFeedbackSubmissionsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/feedback_submissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIFeedbackSubmissionRequestToJSON(requestParameters.aPIFeedbackSubmissionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIFeedbackSubmissionFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsFeedbackSubmissionsUpdate(requestParameters: V1ItemsFeedbackSubmissionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIFeedbackSubmission> {
        const response = await this.v1ItemsFeedbackSubmissionsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
