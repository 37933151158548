/* ------------------------ Functionsl imports start ------------------------ */
import { useTranslation } from "react-i18next"
import { SelectOption } from "../../../utils/types"
import { ColumnProps } from 'antd/es/table/Column'
import { FormFieldType } from "../../Form"
import { Article } from "./types"
import {  Chip, Stack } from "@mui/material"
import { cubic_symbol, loadFieldObjectsArticle, loadFieldOptionsArticle } from "./functions"
import moment from "moment"
import { APICategory, APIProperty } from "../../../generated-types"
import { canCreate, isPrivileged, loadArticleData, loadBrandOptions, loadCountries, loadSupplierData } from "../../../utils/functions"
import { useUserContext } from "../../../utils/context"
import Button from "../../../components/Button"
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  name: 'articleTableColumns'
  | 'generalFormFields'
  | 'dimensionsFormFields'
  | 'purchasedPartFormFields'
  | 'manufacturingProcessFormFields'
  | 'assemblyFormFields'
  | 'joiningFormFields'
  | 'cncMachiningFormFields'
  | 'castingFormFields'
  | 'postProcessingFormFields'
  | 'sheetMetalFormFields'
  | 'additiveManufacturingFormFields'
  | 'standardComponentFormFields'
  | 'rawMaterialFormFields'
  | 'qualityControlFormFields'
  | 'customProcessFormFields'
  | 'wareHouseFormFields'
  | 'propertyFormFields'
  | 'categoryFormFields'
  | 'categoryList'
  | 'manufacturingProcessOptions'
} & (
  ArticleTableKwargs
  | GeneralKwargs
  | DimensionKwargs
  | PurchasedPartKwargs
  | ManufacturingProcessKwargs
  | AssemblyKwargs
  | JoiningKwargs
  | CNCMachiningKwargs
  | CastingKwargs
  | PostProcessingKwargs
  | SheetMetalKwargs
  | AdditiveManufacturingKwargs
  | StandardComponentKwargs
  | RawMaterialKwargs
  | QualityControlKwargs
  | CustomProcessKwargs
  | WarehouseKwargs
  | PropertyKwargs
  | CategoryKwargs
  | CategoryListKwargs
  | ManufacturingProcessOptionsKwargs
)

type ArticleTableKwargs = {
  name: 'articleTableColumns',
  handleRequest: (e: any, selectedArticle: Article) => void
  handleProvideFeedback: (e: any, selectedArticle: Article) => void
  hideProvideFeedbackButton: boolean
}
type GeneralKwargs = {
  name: 'generalFormFields',
  brandOptions: SelectOption[]
  articleOptions: SelectOption[]
  categoryOptions: SelectOption[]
  propertyOptions: SelectOption[]
  onAddPropertyOption: () => void
  onAddCategoryOption: () => void
}

type PropertyKwargs = {
  name: 'propertyFormFields'
}

type CategoryKwargs = {
  name: 'categoryFormFields'
}

type DimensionKwargs = {
  name: 'dimensionsFormFields',
  isRequiredDimension: (dimension: string) => boolean
}
type PurchasedPartKwargs = {
  name: 'purchasedPartFormFields'
  supplierOptions: SelectOption[]
  onAddSupplierOption: () => void
}

type ManufacturingProcessKwargs = {
  name: 'manufacturingProcessFormFields'
  options: { value: string, label: string }[]
}

type AssemblyKwargs = {
  name: 'assemblyFormFields'
}
type JoiningKwargs = {
  name: 'joiningFormFields'
}
type CNCMachiningKwargs = {
  name: 'cncMachiningFormFields'
}
type CastingKwargs = {
  name: 'castingFormFields'
}
type PostProcessingKwargs = {
  name: 'postProcessingFormFields'
}
type SheetMetalKwargs = {
  name: 'sheetMetalFormFields'
}
type AdditiveManufacturingKwargs = {
  name: 'additiveManufacturingFormFields'
}
type StandardComponentKwargs = {
  name: 'standardComponentFormFields'
}
type RawMaterialKwargs = {
  name: 'rawMaterialFormFields'
}
type QualityControlKwargs = {
  name: 'qualityControlFormFields'
}
type CustomProcessKwargs = {
  name: 'customProcessFormFields'
}
type WarehouseKwargs = {
  name: 'wareHouseFormFields'
}
type CategoryListKwargs = {
  name: 'categoryList'
}
type ManufacturingProcessOptionsKwargs = {
  name: 'manufacturingProcessOptions'
}


/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function GetConstant(props: Props): FormFieldType[] | ColumnProps<Article>[] {
  /* -------------------------- Non state data start -------------------------- */
  const { t } = useTranslation()
  const { userPermissions, user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */

  if(props.name === 'articleTableColumns') {
    return [
    {
      title: t('common:content.label.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('common:content.label.articleNumber'),
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: t('common:content.label.weight'),
      dataIndex: 'weight',
      key: 'weight',
      render: (weight: any) => weight ? `${weight.value}${weight.unit}` : ''
    },
    {
      title: t('common:content.label.length'),
      dataIndex: 'length',
      key: 'length',
      render: (measurement: any) => measurement ? `${measurement.value}${measurement.unit}` : ''
    },
    {
      title: t('common:content.label.width'),
      dataIndex: 'width',
      key: 'width',
      render: (measurement: any) => measurement ? `${measurement.value}${measurement.unit}` : ''
    },
    {
      title: t('common:content.label.height'),
      dataIndex: 'height',
      key: 'height',
      render: (measurement: any) => measurement ? `${measurement.value}${measurement.unit}` : ''
    },
    {
      title: t('common:content.label.volume'),
      dataIndex: 'volume',
      key: 'volume',
      render: (measurement: any) => measurement ? `${measurement.value}${cubic_symbol(measurement.unit)}` : ''
    },
    {
      title: t('common:content.label.inStorage'),
      dataIndex: 'inStorage',
      key: 'inStorage',
    },
    {
      title: t('common:content.label.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: any) => type?.name
    },
    {
      title: t('common:content.label.family'),
      dataIndex: 'family',
      key: 'family',
      render: (family: any) => family?.name
    },
    {
      title: t('common:content.label.category'),
      dataIndex: 'category',
      key: 'category',
      render:  (categoryList: any) => {
        if(!Array.isArray(categoryList)) return ""
        return (
          <Stack direction='row' spacing={1}>
            {categoryList.map((category: APICategory, index: number) => {
              return <Chip label={category.name} key={index} />
            })}
          </Stack>
        )
      }
    },
    {
      title: t('common:content.label.variationOf'),
      dataIndex: 'variationOf',
      key: 'variationOf',
      render: (article: any) => article?.name
    },
    {
      title: t('common:content.label.properties'),
      dataIndex: 'properties',
      key: 'properties',
      render:  (propertyList: any) => {
        if(!Array.isArray(propertyList)) return ""
        return (
          <Stack direction='row' spacing={1}>
            {propertyList.map((property: APIProperty, index: number) => {
              return <Chip label={property.name} key={index} />
            })}
          </Stack>
        )
      }
    },
    {
      title: t('common:content.label.creationDate'),
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (date: any) => moment(date).format('DD.MM.YYYY'),
    },
    {
      title: `${t('common:content.label.brand')} / ${t('common:content.label.manufacturer')}`,
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: t('common:content.label.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      render: (supplier: any) => (supplier && typeof supplier === 'object') ? supplier.name : ''
    },
    {
      title: t('common:content.label.barcode'),
      dataIndex: 'barcodeText',
      key: 'barcodeText',
    },
    {
      title: t('common:content.label.barcodeType'),
      dataIndex: 'barcodeType',
      key: 'barcodeType',
    },
    {
      title: t('common:content.label.countryOfOrigin'),
      dataIndex: 'countryOfOrigin',
      key: 'countryOfOrigin',
    },
    {
      title: t('item:content.label.minStorageAmount'),
      dataIndex: 'minimumStorage',
      key: 'minimumStorage',
    },
    {
      title: t('common:content.label.batchSize'),
      dataIndex: 'batchSize',
      key: 'batchSize',
    },
    {
      title: '',
      key: 'request',
      dataIndex: 'request',
      render: (text: string, record: any) => (
        <Button id='requestByAssembleanButton' variant="contained"  onClick={e => props.handleRequest(e, record)}>
          {t('common:interaction.button.requestFromAssemblean')}
        </Button>
      ),
    },
    {
      title: '',
      key: 'feedback',
      dataIndex: 'feedback',
      render: (text: string, record: any) => {
        if(props.hideProvideFeedbackButton) return <></>
        else {
          return (
            <Button id='provideArticleFeedbackButton' variant="contained"  onClick={(e: any)=>props.handleProvideFeedback(e, record)}>
              {t('common:content.heading.giveFeedback')}
            </Button>
          )
        }
      },
    },
  ].filter((column) => (column.key === "request" && (canCreate(userPermissions, 'request:request') || isPrivileged(user))) || column.key !== "request")
  }

  if(props.name === 'generalFormFields') {
    return [
      {
        type: 'text',
        key: 'name',
        label: t('common:content.label.articleName'),
        required: false,
      },
      {
        type: 'text',
        key: 'number',
        label: t('common:content.label.articleNumber'),
        required: true,
        validation: true
      },
      {
        type: 'multiline',
        key: 'description',
        label: t('common:content.label.description'),
        xl: 12,
      },
      {
        type: 'text',
        key: 'barcodeText',
        label: t('common:content.label.barcode'),
      },
      {
        type: 'text',
        key: 'barcodeType',
        label: t('common:content.label.barcodeType'),
        loadSuggestions: async () => loadFieldOptionsArticle("items/barcode_types/"),
        /* options: [
          { value: 'ean', label: 'EAN' },
          { value: 'gtin', label: 'GTIN' },
          { value: 'upc', label: 'UPC' },
          { value: 'custom', label: t('common:content.label.custom') as string},
        ], */
      },
      {
        type: 'text',
        key: 'type',
        label: t('common:content.label.type'),
        loadSuggestions: async () =>  loadFieldOptionsArticle("items/types/"),
      },
      {
        type: 'text',
        key: 'family',
        label: t('common:content.label.family'),
        loadSuggestions: async () => loadFieldOptionsArticle("items/families/"),
      },
      {
        type: 'select-search-multi',
        key: 'properties',
        label: t('common:content.label.properties'),
        loadData: async () => loadFieldObjectsArticle("items/properties/"),
        required: false,
        validation: false,
        onAddOption: (canCreate(userPermissions, 'items:property') || isPrivileged(user)) && props.onAddPropertyOption,
      },
      {
        type: 'select-search-multi',
        key: 'category',
        label: t('common:content.label.categories'),
        loadData: async () => loadFieldObjectsArticle("items/categories/"),
        required: false,
        validation: false,
        onAddOption: (canCreate(userPermissions, 'items:category') || isPrivileged(user)) && props.onAddCategoryOption,
      },
      {
        type: 'text',
        key: 'brand',
        label: t('common:content.label.brand'),
        loadSuggestions: async () => loadFieldOptionsArticle("items/brands/"),
      },
      {
        type: 'select-search',
        key: 'variationOf',
        label: t('common:content.label.variationOf'),
        //options: props.articleOptions,
        loadData: loadArticleData,
        required: false,
        validation: false,
      },
      {
        type: 'select-search',
        key: 'qualityClass',
        label: t('common:content.label.qualityClass'),
        options: [
          { value: 'f', label: 'f' },
          { value: 'm', label: 'm' },
          { value: 'c', label: 'c' },
          { value: 'v', label: 'v' },
        ],        
        required: false,
        validation: false,
      }
    ] as FormFieldType[]
  }

  if(props.name === 'propertyFormFields') {
    return [
      {
        type: 'text',
        key: 'name',
        label: t('common:content.label.name'),
        required: true,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      {
        type: 'text',
        key: 'value',
        label: t('common:content.label.value'),
        required: true,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      {
        type: 'text',
        key: 'type',
        label: t('common:content.label.type'),
        required: true,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      }
    ] as FormFieldType[]
  }

  if(props.name === 'categoryFormFields') {
    return [
      {
        type: 'text',
        key: 'name',
        label: t('common:content.label.name'),
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      }
    ] as FormFieldType[]
  }

  if(props.name === 'dimensionsFormFields') {
    return [
      {
        type: 'measurement',
        key: 'weight',
        label: t('common:content.label.weight'),
        errorMessage: t('item:feedback.error.measurementInput') as string,
        required: props.isRequiredDimension('weight'),
        unit: 'g',
      },
      {
        type: 'measurement',
        key: 'length',
        label: t('common:content.label.length'),
        errorMessage: t('item:feedback.error.measurementInput') as string,
        required: props.isRequiredDimension('length'),
        unit: 'm',
      },
      {
        type: 'measurement',
        key: 'width',
        label: t('common:content.label.width'),
        errorMessage: t('item:feedback.error.measurementInput') as string,
        required: props.isRequiredDimension('width'),
        unit: 'm',
      },
      {
        type: 'measurement',
        key: 'height',
        label: t('common:content.label.height'),
        errorMessage: t('item:feedback.error.measurementInput') as string,
        required: props.isRequiredDimension('height'),
        unit: 'm',
      },
      {
        type: 'measurement',
        key: 'volume',
        label: t('common:content.label.volume'),
        errorMessage: t('item:feedback.error.measurementInput') as string,
        required: props.isRequiredDimension('volume'),
        unit: 'm³'
      },
    ] as FormFieldType[]
  }

  if(props.name === 'purchasedPartFormFields') {
    return [
      {
        type: 'select-search',
        key: 'supplier',
        label: t('common:content.label.supplier'),
        //options: props.supplierOptions,
        loadData: loadSupplierData,
        onAddOption: (canCreate(userPermissions, 'supplier:supplier') || isPrivileged(user)) && props.onAddSupplierOption,
        required: true,
        validation: true,
      },
      {
        type: 'select-search',
        key: 'countryOfOrigin',
        label: t('common:content.label.countryOfOrigin'),
        loadData: loadCountries,
        required: false,
        validation: false,
      },
      {
        type: 'text',
        key: 'minimumOrder',
        label: t('common:content.label.minimumOrder'),
      },

      {
        type: 'text',
        key: 'batchSize',
        label: t('common:content.label.batchSize'),
      },
      {
        type: 'timedelta',
        key: 'leadTime',
        label: t('common:content.label.leadTime'),
        xl: 6,
      },
    ] as FormFieldType[]
  }

  if(props.name === 'wareHouseFormFields') {
    return [
      {
        type: 'number',
        key: 'minimumStorage',
        label: t('item:content.label.minStorageAmount'),
        errorMessage: t('item:feedback.error.minStorageInput') as string,
      },
      /* {
        type: 'select-search',
        key: 'stockLocation',
        label: t('common:stockLocation'),
        options: [
          { value: 'A40', label: 'A40' },
          { value: 'A60', label: 'A60' },
        ],
        addButton: addButton,
      }, */
    ] as FormFieldType[]
  }
  if(props.name === 'categoryList') {
    return [
      {
        category: 'ASSEMBLY',
        label: 'content.label.assembly',
      },
      {
        category: 'JOINING',
        label: 'content.label.joining',
      },
      {
        category: 'CNCMACHINING',
        label: 'content.label.cncMachining',
      },
      {
          category: 'CASTING',
          label: 'content.label.casting',
      },
      {
        category: 'POSTPROCESSING',
        label: 'content.label.postProcessing',
      },
      {
        category: 'SHEETMETAL',
        label: 'content.label.sheetMetal',
      },
      {
        category: 'PRINTING3D',
        label: 'content.label.additiveManufacturing',
      },
      {
        category: 'STANDARDCOMPONENT',
        label: 'content.label.standardComponent',
      },
      {
          category: 'RAWMATERIAL',
          label: 'content.label.rawMaterial',
      },
      {
          category: 'QUALITYCONTROL',
          label: 'content.label.qualityControl',
      },
      {
        category: 'CUSTOMPROCESS',
        label: 'content.label.customProcess',
      },
    ] as any[]
  }
  return [] as FormFieldType[]
}