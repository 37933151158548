/* -------------------------- Design imports start -------------------------- */
import Page from '../components/layout/Page'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function WrongRoutePage() {
  /* ---------------------------- Data states start --------------------------- */
  const history = useHistory()
  const { t } = useTranslation(['common'])
  /* ----------------------------- Data states end ---------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      Nothing here: 404
      <button id="backButton" onClick={() => history.goBack()}>{t('common:interaction.button.back')}</button>
    </Page>
  )
}
