/* -------------------------- Design imports start -------------------------- */
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import useMobileService from '../../hooks/useMobileService'
import ErrorBoundary from './ErrorBoundry'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  children: React.ReactNode
  title?: React.ReactNode
  showTitleOnMobile?: boolean
  breadcrumbs?: { name: string; href?: string }[]
  className?: string
  marginBottom?: string
  withoutPadding?: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function Page(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const isMobile = useMobileService()
  const { children, title, breadcrumbs, className, showTitleOnMobile, marginBottom, withoutPadding } = props
  const mainContentWidth = isMobile ? '100%' : 'calc(100% - 250px)'
  /* --------------------------- Non state data end --------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Box
      flex={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: marginBottom,
      }}
    >
      <Box
        flex={1}
        sx={{
          width: mainContentWidth,
          padding: withoutPadding ? 0 : '10px',
          position: 'relative',
          margin: '0 auto',
        }}
      >
        <Grid style={{ height: '98%' }} container className={className || 'main'}>
          <Grid container className="header">
            <Grid item>
              {title &&
                (showTitleOnMobile || !isMobile) &&
                (typeof title === 'string' ? <h2 className="title">{title}</h2> : title)}
            </Grid>
            <Grid item>
              {breadcrumbs && (
                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                  {breadcrumbs.map(({ name, href }, index) =>
                    index < breadcrumbs.length - 1 ? (
                      <Link color="inherit" href={href}>
                        {name}
                      </Link>
                    ) : (
                      <Typography color="textPrimary">{name}</Typography>
                    )
                  )}
                </Breadcrumbs>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <ErrorBoundary>{children}</ErrorBoundary>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
