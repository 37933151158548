/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:
 * 
 * * Blacklisting fields through an "exclude" context variable
 * * Whitelisting fields through an "only" context variable
 * * Expanding related fields through an "expand" context variable
 * * Other helper methods
 * 
 * Blacklisting and whitelisting takes precedence over expanding, and can
 * be used in combination with one another.
 * @export
 * @interface APIChannel
 */
export interface APIChannel {
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    readonly creator: string;
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    readonly members: string;
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    readonly messages: string;
    /**
     * 
     * @type {string}
     * @memberof APIChannel
     */
    readonly lastReadAt: string;
}

export function APIChannelFromJSON(json: any): APIChannel {
    return APIChannelFromJSONTyped(json, false);
}

export function APIChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creator': json['creator'],
        'members': json['members'],
        'messages': json['messages'],
        'lastReadAt': json['last_read_at'],
    };
}

export function APIChannelToJSON(value?: APIChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

