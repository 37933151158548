/* -------------------------- Design imports start -------------------------- */
import Drawer from '@mui/material/Drawer'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { canView, isPrivileged } from '../../utils/functions'
import { useUserContext } from '../../utils/context'
/* ------------------------- Functional imports end ------------------------- */

const drawerWidth = 230

type Props = {
  openSidebar: boolean
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function SideBar(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { openSidebar } = props
  const { t } = useTranslation(['common'])
  const { userPermissions, user } = useUserContext()
  const sideBarItems = [
    {
      name: t('common:content.label.item'),
      path: '/items/landingpage',
      permission: ['items:article', 'items:bom_base', 'logistics:storage'],
      subMenu: [
        {
          name: t('content.label.allItems'),
          path: '/items',
          permission: 'items:article'
        },
        {
          name: t('content.label.boms'),
          path: '/items/bom/overview',
          permission: 'items:bom_base'
        },
        {
          name: t('content.label.stock'),
          path: '/items/itemstock',
          permission: 'logistics:storage'
        },
        {
          name: t('sharepoint:content.label.shares'),
          path: '/items/sharepoints',
          permission: 'items:sharepoint'
        },
      ].filter(item => canView(userPermissions, item.permission) || isPrivileged(user)),
    },
    {
      name: t('content.loginForm.company'),
      path: '/personnel/landingpage',
      permission: ['accounts:user', 'accounts:role', 'organizations:organization'],
      subMenu: [
        {
          name: t('content.label.employee'),
          path: '/personnel/employees/list',
          permission: 'accounts:user',
        },
        {
          name: t('content.label.roles'),
          path: '/personnel/roles/list',
          permission: 'accounts:role',
        },
        /* {
          name: t("content.label.permissions"),
          path: '/personnel/permissions/list',
        },*/
        {
          name: t('content.loginForm.company'),
          path: '/personnel/organization',
          permission: 'organizations:organization',
        },
      ].filter(item => canView(userPermissions, item.permission) || isPrivileged(user)),
    },
    {
      name: t('content.label.orderControl'),
      path: '/contacts/customer/list',
      permission: ['customer:customer', 'supplier:supplier', 'customer:production_order', 'supplier:supply_order', 'customer:customer_order'],
      subMenu: [
        {
          name: t('common:content.label.contacts'),
          path: '/contacts',
          permission: 'contacts:contact',
        },
        {
          name: t('common:content.label.customerList'),
          path: '/contacts/customer/list',
          permission: 'customer:customer',
        },
        {
          name: t('common:content.label.supplierList'),
          path: '/contacts/supplier/list',
          permission: 'supplier:supplier',
        },
        {
          name: t('common:content.label.customerOrders'),
          path: '/contacts/customer/orders',
          permission: 'customer:customer_order',
        },
        {
          name: t('common:content.label.supplierOrders'),
          path: '/orders',
          permission: 'supplier:supply_order',
        },
        {
          name: t('common:content.label.productionOrders'),
          path: '/productionOrder',
          permission: 'customer:production_order',
        },
      ].filter(item => canView(userPermissions, item.permission) || isPrivileged(user)),
    },
    {
      id: 'support',
      name: t('common:content.label.contact'),
      path: '/support/contact',
      permission: 'support:ticket',
    },
  ].filter(item => {
    if (item.permission) {
      if (Array.isArray(item.permission)) {
        return item.permission.some(permission => canView(userPermissions, permission) || isPrivileged(user))
      } else {
        return canView(userPermissions, item.permission) || isPrivileged(user)
      }
    } else {
      return true
    }
  })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [open, setOpen] = useState(true)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [screenSize, setScreenSize] = useState(window.innerWidth)
  const sidebarRef = useRef<any>(null)
  const history = useHistory()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    window.addEventListener('resize', updateScreenSize)
    return () => window.removeEventListener('resize', updateScreenSize)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (screenSize < 1600) {
        setOpen(false)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [sideBarItems])

  useEffect(() => {
    const adjustSidebarHeight = () => {
      const sidebarElement = sidebarRef.current
      if (sidebarElement) {
        const windowHeight = window.innerHeight
        const sidebarTopOffset = sidebarElement.getBoundingClientRect().top
        const newSidebarHeight = windowHeight - sidebarTopOffset
        sidebarElement.style.height = `${newSidebarHeight}px`
      }
    }

    adjustSidebarHeight()
    window.addEventListener('resize', adjustSidebarHeight)

    return () => {
      window.removeEventListener('resize', adjustSidebarHeight)
    }
  }, [])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------ Callback fucntions start ------------------------ */
  const handleDrawer = () => {
    setOpen(prevOpen => !prevOpen)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const updateScreenSize = () => {
    setScreenSize(window.innerWidth)
  }

  const sideList = () => {
    const currentPath = history.location.pathname
    return (
      <div id='sideBarList' ref={sidebarRef} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {sideBarItems?.map((listItem, index) => (
            <div key={index}>
              {listItem.subMenu ? (
                <Accordion defaultExpanded={false} >
                  <AccordionSummary id={`accordion-${listItem.id}`}>
                    <ExpandMoreIcon />
                    <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      {listItem.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {listItem.subMenu.map((subMenuItem, subIndex) => (
                        <ListItem
                          key={subIndex}
                          style={{
                            padding: '5px',
                            backgroundColor:
                              currentPath === subMenuItem.path ? '#16a9e4' : 'inherit',
                          }}
                          id={`subListItem-${listItem.id}-${subIndex}`}
                        >
                          <ListItemButton
                            style={{ justifyContent: 'left', marginLeft: '5px' }}
                            onClick={() => history.push(subMenuItem.path)}
                          >
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: currentPath === subMenuItem.path ? 'whitesmoke' : 'black',
                              }}
                            >
                              {subMenuItem.name}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <ListItem
                  style={{
                    padding: '5px',
                    backgroundColor: currentPath === listItem.path ? '#16a9e4' : 'inherit',
                  }}
                  id={`listItem-${listItem.id}`}
                >
                  <ListItemButton
                    style={{ justifyContent: 'left', marginLeft: '5px' }}
                    onClick={() => history.push(listItem.path)}
                  >
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: currentPath === listItem.path ? 'whitesmoke' : 'black',
                      }}
                    >
                      {listItem.name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              )}
              <Divider />
            </div>
          ))}
      </div>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: 70,
          },
        }}
        open={openSidebar}
        anchor="left"
        onClose={handleDrawer}
        variant="persistent"
        style={{ position: 'relative' }}
      >
        {sideList()}
      </Drawer>
    </div>
  )
}
