/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIOrganization,
    APIOrganizationFromJSON,
    APIOrganizationToJSON,
    APIOrganizationRequest,
    APIOrganizationRequestFromJSON,
    APIOrganizationRequestToJSON,
    APIPaginatedOrganizationList,
    APIPaginatedOrganizationListFromJSON,
    APIPaginatedOrganizationListToJSON,
    APIPatchedOrganizationRequest,
    APIPatchedOrganizationRequestFromJSON,
    APIPatchedOrganizationRequestToJSON,
} from '../models';

export interface V1OrganizationsOrganizationCreateRequest {
    aPIOrganizationRequest: APIOrganizationRequest;
}

export interface V1OrganizationsOrganizationDestroyRequest {
    id: number;
}

export interface V1OrganizationsOrganizationListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1OrganizationsOrganizationPartialUpdateRequest {
    id: number;
    aPIPatchedOrganizationRequest?: APIPatchedOrganizationRequest;
}

export interface V1OrganizationsOrganizationRetrieveRequest {
    id: number;
}

export interface V1OrganizationsOrganizationUpdateRequest {
    id: number;
    aPIOrganizationRequest: APIOrganizationRequest;
}

/**
 * 
 */
export class OrganizationsorganizationsApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationCreateRaw(requestParameters: V1OrganizationsOrganizationCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.aPIOrganizationRequest === null || requestParameters.aPIOrganizationRequest === undefined) {
            throw new runtime.RequiredError('aPIOrganizationRequest','Required parameter requestParameters.aPIOrganizationRequest was null or undefined when calling v1OrganizationsOrganizationCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIOrganizationRequestToJSON(requestParameters.aPIOrganizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationCreate(requestParameters: V1OrganizationsOrganizationCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationDestroyRaw(requestParameters: V1OrganizationsOrganizationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationDestroy(requestParameters: V1OrganizationsOrganizationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1OrganizationsOrganizationDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationListRaw(requestParameters: V1OrganizationsOrganizationListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedOrganizationList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedOrganizationListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationList(requestParameters: V1OrganizationsOrganizationListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedOrganizationList> {
        const response = await this.v1OrganizationsOrganizationListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationPartialUpdateRaw(requestParameters: V1OrganizationsOrganizationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedOrganizationRequestToJSON(requestParameters.aPIPatchedOrganizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationPartialUpdate(requestParameters: V1OrganizationsOrganizationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationRetrieveRaw(requestParameters: V1OrganizationsOrganizationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationRetrieve(requestParameters: V1OrganizationsOrganizationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationUpdateRaw(requestParameters: V1OrganizationsOrganizationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIOrganization>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1OrganizationsOrganizationUpdate.');
        }

        if (requestParameters.aPIOrganizationRequest === null || requestParameters.aPIOrganizationRequest === undefined) {
            throw new runtime.RequiredError('aPIOrganizationRequest','Required parameter requestParameters.aPIOrganizationRequest was null or undefined when calling v1OrganizationsOrganizationUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/organizations/organization/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIOrganizationRequestToJSON(requestParameters.aPIOrganizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIOrganizationFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1OrganizationsOrganizationUpdate(requestParameters: V1OrganizationsOrganizationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIOrganization> {
        const response = await this.v1OrganizationsOrganizationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
