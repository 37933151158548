/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedRoleList,
    APIPaginatedRoleListFromJSON,
    APIPaginatedRoleListToJSON,
    APIPatchedRoleRequest,
    APIPatchedRoleRequestFromJSON,
    APIPatchedRoleRequestToJSON,
    APIRole,
    APIRoleFromJSON,
    APIRoleToJSON,
    APIRoleRequest,
    APIRoleRequestFromJSON,
    APIRoleRequestToJSON,
} from '../models';

export interface V1AccountsRolesCreateRequest {
    aPIRoleRequest: APIRoleRequest;
}

export interface V1AccountsRolesDestroyRequest {
    id: number;
}

export interface V1AccountsRolesListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1AccountsRolesPartialUpdateRequest {
    id: number;
    aPIPatchedRoleRequest?: APIPatchedRoleRequest;
}

export interface V1AccountsRolesRetrieveRequest {
    id: number;
}

export interface V1AccountsRolesUpdateRequest {
    id: number;
    aPIRoleRequest: APIRoleRequest;
}

/**
 * 
 */
export class AccountsrolesApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesCreateRaw(requestParameters: V1AccountsRolesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRole>> {
        if (requestParameters.aPIRoleRequest === null || requestParameters.aPIRoleRequest === undefined) {
            throw new runtime.RequiredError('aPIRoleRequest','Required parameter requestParameters.aPIRoleRequest was null or undefined when calling v1AccountsRolesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIRoleRequestToJSON(requestParameters.aPIRoleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRoleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesCreate(requestParameters: V1AccountsRolesCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRole> {
        const response = await this.v1AccountsRolesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesDestroyRaw(requestParameters: V1AccountsRolesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsRolesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesDestroy(requestParameters: V1AccountsRolesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1AccountsRolesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesListRaw(requestParameters: V1AccountsRolesListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedRoleList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedRoleListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesList(requestParameters: V1AccountsRolesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedRoleList> {
        const response = await this.v1AccountsRolesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesPartialUpdateRaw(requestParameters: V1AccountsRolesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsRolesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedRoleRequestToJSON(requestParameters.aPIPatchedRoleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRoleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesPartialUpdate(requestParameters: V1AccountsRolesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRole> {
        const response = await this.v1AccountsRolesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesRetrieveRaw(requestParameters: V1AccountsRolesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsRolesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRoleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesRetrieve(requestParameters: V1AccountsRolesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRole> {
        const response = await this.v1AccountsRolesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesUpdateRaw(requestParameters: V1AccountsRolesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIRole>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1AccountsRolesUpdate.');
        }

        if (requestParameters.aPIRoleRequest === null || requestParameters.aPIRoleRequest === undefined) {
            throw new runtime.RequiredError('aPIRoleRequest','Required parameter requestParameters.aPIRoleRequest was null or undefined when calling v1AccountsRolesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/accounts/roles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIRoleRequestToJSON(requestParameters.aPIRoleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIRoleFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1AccountsRolesUpdate(requestParameters: V1AccountsRolesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIRole> {
        const response = await this.v1AccountsRolesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
