/* -------------------------- Design imports start -------------------------- */
// some design imports...
/* --------------------------- Design imports end --------------------------- */

import { Button as MuiButton } from '@mui/material'
import { forwardRef } from 'react'

/* ------------------------ Functional imports start ------------------------ */
// some functional imports...
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  onClick?: (event: any) => void
  onKeyDown?: (event: any) => void
  disabled?: boolean
  children: React.ReactNode
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info'
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  href?: string
  target?: string
  rel?: string
  type?: 'button' | 'submit' | 'reset'
  component?: React.ElementType
  to?: string
  replace?: boolean
  innerRef?: React.Ref<any>
  sx?: object
  className?: string
  style?: React.CSSProperties
  id?: string
  autoFocus?: boolean
  disableGutters?: boolean
  disablePadding?: boolean
  focusRipple?: boolean
  role?: string
  tabIndex?: number
  disableFocusRipple?: boolean
  disableRipple?: boolean
  disableElevation?: boolean
  disableTouchRipple?: boolean
  focusVisibleClassName?: string
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {

  return (
    <MuiButton
      ref={ref}
      sx={{
        color: props.color ?? (props.sx as { color?: string })?.color ?? 'whitesmoke',
        textTransform: 'none',
        ...props.sx,
      }}
      style={{
        color: props.color ?? props.style?.color ?? 'whitesmoke',
        textTransform: 'none',
        ...props.style,
      }}
      {...props}
    >
      {props.children}
    </MuiButton>
  )
})

export default Button
