/* ------------------------ Functionsl imports start ------------------------ */
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/es/table/Column'
import { FormFieldType } from '../../Form'
import moment from 'moment'
import { Chip, Grid, Stack, TextField, Typography } from '@mui/material'
import {
  canCreate,
  isPrivileged,
  loadAddressOptions,
  loadArticleData,
  loadCustomerOptions,
  loadAllArticleData,
  loadAllCustomer,
  getAddressRepresentation,
} from '../../../utils/functions'
import { Article } from '../../Orders/utils/types'
import { Customer } from './types'
import { useUserContext } from '../../../utils/context'

/* ------------------------- Functional imports end ------------------------- */

type Props = {
  name:
    | 'customerTableColumns'
    | 'customerOrderTableColumns'
    | 'expandedCustomerTableColumns'
    | 'contactFormFields'
    | 'addressFormFields'
    | 'customerFormFields'
    | 'customerOrderFormFields'
    | 'billingAddress'
    | 'customerOrderFormFieldsSelect'
    | 'selectAddressFormFields'
} & (
  | OrderKwargs
  | OrderTableKwargs
  | ContactKwargs
  | CustomerKwargs
  | BillingKwargs
  | SelectAddressFormFields
  | CustomerOrderSelect
)

type SelectAddressFormFields = {
  name: 'selectAddressFormFields'
  onAddBillingAddressOption: () => void
  onAddShippingAddressOption: () => void
  addressOptions: any[]
}

type CustomerKwargs = {
  name: 'customerTableColumns' | 'expandedCustomerTableColumns'
}

type ContactKwargs = {
  name: 'contactFormFields' | 'addressFormFields' | 'customerFormFields'
}

type OrderTableKwargs = {
  name: 'customerOrderTableColumns'
}

type CustomerOrderSelect = {
  name: 'customerOrderFormFieldsSelect'
  onAddArticle: () => void
  onAddCustomer: () => void
}

type OrderKwargs = {
  name: 'customerOrderFormFields'
}
type BillingKwargs = {
  name: 'billingAddress'
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function GetConstant(props: Props): FormFieldType[] | ColumnProps<any>[] | any {
  /* -------------------------- Non state data start -------------------------- */
  const { t } = useTranslation()
  const { userPermissions, user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */
  const renderStatus = (status: number) => {
    switch(status) {
        case 0:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.draft")} color="primary" variant="outlined" />
                </Stack>
            )
        case 1:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.offer")} color="primary" variant="outlined" />
                </Stack>
            )

        case 2:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.customerApproval")} color="primary" variant="outlined" />
                </Stack>
            )

        case 3:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.productionOrdersCreated")} color="primary" variant="outlined" />
                </Stack>
            )

        case 4:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.assignment")} color="primary" variant="outlined" />
                </Stack>
            )

        case 5:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.inProgress")} color="primary" variant="outlined" />
                </Stack>
            )

        case 6:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.completed")} color="primary" variant="outlined" />
                </Stack>
            )

        case 7:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.archived")} color="primary" variant="outlined" />
                </Stack>
            )

        case 8:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.invoiced")} color="primary" variant="outlined" />
                </Stack>
            )

        case 9:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.paidByCustomer")} color="primary" variant="outlined" />
                </Stack>
            )

        case 10:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("common:content.label.trash")} color="primary" variant="outlined" />
                </Stack>
            )

        default:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.unknown")} color="default" variant="outlined" />
                </Stack>
            )
    }
}

  if (props.name === 'customerTableColumns') {
    return [
      {
        title: t('common:content.label.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('common:content.label.vat'),
        dataIndex: 'ustId',
        key: 'ustId',
      },
    ]
  }
  if (props.name === 'customerOrderTableColumns') {
    return [
      {
        title: t('content.label.orderNo'),
        dataIndex: 'key',
        key: 'key',
        width: 10,
      },
      {
        title: t('common:content.label.article'),
        dataIndex: 'article',
        key: 'article',
        render: (article: Article) => `${article?.name}  (${article?.number})`,
      },
      {
        title: t('common:content.label.quantity'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: t('common:content.label.status'),
        dataIndex: 'status',
        key: 'status',
        render: (status: number) => renderStatus(status ?? 0),
      },
      {
        title: t('common:content.label.orderDate'),
        dataIndex: 'orderDate',
        key: 'orderDate',
        id: 'orderDate',
        render: (date: moment.MomentInput) => {
          if (!date) {
            return '-'
          } else {
            return moment(date).format('DD.MM.YYYY')
          }
        },
      },
      {
        title: t('common:content.label.startDate'),
        dataIndex: 'startDate',
        key: 'startDate',
        id: 'startDate',
        render: (date: moment.MomentInput) => {
          if (!date) {
            return '-'
          } else {
            return moment(date).format('DD.MM.YYYY')
          }
        },
      },
      {
        title: t('common:content.label.dueDate'),
        dataIndex: 'dueDate',
        key: 'dueDate',
        id: 'dueDate',
        render: (date: moment.MomentInput) => {
          if (!date) {
            return '-'
          } else {
            return moment(date).format('DD.MM.YYYY')
          }
        },
      },
      {
        title: t('common:content.label.wishDate'),
        dataIndex: 'wishDate',
        key: 'wishDate',
        id: 'wishDate',
        render: (date: moment.MomentInput) => {
          if (!date) {
            return '-'
          } else {
            return moment(date).format('DD.MM.YYYY')
          }
        },
      },
      {
        title: t('common:content.label.description'),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: t('common:content.label.customer'),
        dataIndex: 'customer',
        key: 'customer',
        render: (customer: any) => `${customer?.name} (${customer?.ust_id})`,
      },
      {
        title: t('content.label.customerReference'),
        dataIndex: 'customerReference',
        key: 'customerReference',
      },
      {
        title: t('content.label.planning'),
        dataIndex: 'planning',
        key: 'planning',
      },
      {
        title: t('content.label.priority'),
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: t('common:content.label.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
    ]
  }

  if (props.name === 'expandedCustomerTableColumns') {
    return [
      {
        title: t('common:content.label.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: t('common:content.label.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: t('common:content.label.email'),
        dataIndex: 'email',
        key: 'email',
        required: true,
        validation: true,
      },
      {
        title: t('common:content.label.phone'),
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: t('common:content.label.address'),
        dataIndex: 'addresses',
        key: 'addresses',
        width: '30%',
        render: (addresses: any[]) => (
          <ul>
            {addresses?.map((address: any, index: number) => (
              <li key={index}>
                {getAddressRepresentation(address)}
              </li>
            ))}
          </ul>
        ),
      },
      {
        title: t('common:content.label.language'),
        dataIndex: 'language',
        key: 'language',
      },
      {
        title: t('common:content.label.comment'),
        dataIndex: 'comment',
        key: 'comment',
      },
    ]
  }

  if (props.name === 'customerOrderFormFields') {
    return [
      {
        type: 'text',
        key: 'self',
        label: t('common:content.label.orderNumber'),
      },
      {
        type: 'text',
        key: 'amount',
        required: true,
        validation: true,
        label: t('common:content.label.quantity'),
      },
      {
        type: 'select-search',
        key: 'planning',
        label: t('common:content.label.planning'),
        options: [
          { value: 1, label: 'as soon as possible' },
          { value: 2, label: 'deadline' },
          { value: 3, label: 'priority' },
          { value: 4, label: 'ASAP with deadline' },
        ],
        required: false,
        validation: false,
      },
      {
        type: 'number',
        key: 'priority',
        label: t('common:content.label.priority'),
        helperText: t('common:content.label.priorityHelperText'),
      },
      {
        type: 'date',
        key: 'orderDate',
        label: t('common:content.label.orderDate'),
        required: false,
        validation: true,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
      {
        type: 'date',
        key: 'start_date',
        label: t('common:content.label.startDate'),
        required: true,
        validation: true,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
      {
        type: 'date',
        key: 'wishDate',
        label: t('common:content.label.wishDate'),
        required: false,
        validation: false,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
      {
        type: 'date',
        key: 'due_date',
        label: t('common:content.label.dueDate'),
        required: true,
        validation: true,
        xs: 3,
        sm: 3,
        md: 3,
        lg: 3,
        xl: 3,
      },
      {
        type: 'multiline',
        key: 'description',
        label: t('common:content.label.description'),
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      {
        type: 'multiline',
        key: 'comment',
        label: t('common:content.label.comment'),
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    ] as FormFieldType[]
  }

  if (props.name === 'customerOrderFormFieldsSelect') {
    return [
      {
        type: 'select-search',
        required: true,
        validation: true,
        key: 'article',
        label: t('common:content.label.article'),
        loadData: loadArticleData,
        loadAllData: loadAllArticleData,
        onAddOption:
          (canCreate(userPermissions, 'items:article') || isPrivileged(user)) && props.onAddArticle,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      {
        type: 'select-search',
        required: true,
        validation: true,
        key: 'customer',
        label: t('common:content.label.customer'),
        loadData: loadCustomerOptions,
        loadAllData: loadAllCustomer,
        onAddOption:
          (canCreate(userPermissions, 'customer:customer') || isPrivileged(user)) &&
          props.onAddCustomer,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ]
  }

  if (props.name === 'contactFormFields') {
    return [
      {
        type: 'text',
        key: 'firstName',
        label: t('common:content.label.firstName'),
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
      {
        type: 'text',
        key: 'lastName',
        label: t('common:content.label.lastName'),
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
      {
        type: 'text',
        key: 'email',
        label: t('common:content.label.email'),
        required: true,
        validation: true,
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
      {
        type: 'text',
        key: 'phone',
        label: t('common:content.label.phone'),
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
      {
        type: 'text',
        key: 'language',
        label: t('common:content.label.language'),
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
      {
        type: 'multiline',
        key: 'comment',
        label: t('common:content.label.comment'),
        required: false,
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      },
    ] as FormFieldType[]
  }

  if (props.name === 'addressFormFields') {
    return [
      {
        type: 'text',
        key: 'address1',
        label: t('address:content.label.address1'),
      },
      {
        type: 'text',
        key: 'address2',
        label: t('address:content.label.address2'),
      },
      {
        type: 'text',
        key: 'address3',
        label: t('address:content.label.address3'),
      },
      {
        type: 'text',
        key: 'zipCode',
        label: t('address:content.label.zipCode'),
      },
      {
        type: 'text',
        key: 'city',
        label: t('address:content.label.city'),
      },
      {
        type: 'text',
        key: 'country',
        label: t('address:content.label.country'),
      },
      {
        type: 'selectWithFreeText',
        key: 'type',
        label: t('address:content.label.type'),
        options: [
          { value: 'billing', label: t('address:content.label.billingAddress') },
          { value: 'shipping', label: t('address:content.label.shippingAddress') },
        ],
      },
      {
        type: 'multiline',
        key: 'info',
        label: t('address:content.label.additionalAddressInformation'),
      },
    ] as FormFieldType[]
  }

  if (props.name === 'selectAddressFormFields') {
    return [
      {
        type: 'select-search',
        key: 'addresses',
        label: t('common:content.label.address'),
        //options: props.addressOptions,
        onAddOption:
          (canCreate(userPermissions, 'organizations:billing_address') || isPrivileged(user)) &&
          props.onAddBillingAddressOption,
        loadData: loadAddressOptions,
        required: true,
        validation: false,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ] as FormFieldType[]
  }

  if (props.name === 'customerFormFields') {
    return [
      {
        type: 'text',
        key: 'name',
        label: t('common:content.label.name'),
        required: true,
        validation: true,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
      {
        type: 'text',
        key: 'ustId',
        label: t('common:content.label.vat'),
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      },
    ] as FormFieldType[]
  }

  if (props.name === 'billingAddress') {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {t('address:content.label.billingAddress')}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-address"
            label={t('common:content.label.contact')}
            type="text"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-country"
            label={t('address:content.label.address1')}
            type="text"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-zip"
            label={t('address:content.label.address2')}
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-city"
            label={t('address:content.label.address3')}
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-state"
            label={t('address:content.label.zipCode')}
            type="number"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-state"
            label={t('address:content.label.city')}
            type="text"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-state"
            label={t('address:content.label.country')}
            type="text"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            id="outlined-state"
            label={t('address:content.label.info')}
            type="text"
            multiline
            rows={4}
            maxRows={6}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    )
  }

  return [] as FormFieldType[]
}
