/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIContact,
    APIContactFromJSON,
    APIContactToJSON,
    APIPaginatedContactList,
    APIPaginatedContactListFromJSON,
    APIPaginatedContactListToJSON,
} from '../models';

export interface V1CustomerCustomersContactsListRequest {
    customersPk: string;
    page?: number;
    pageSize?: number;
}

export interface V1CustomerCustomersContactsRetrieveRequest {
    customersPk: string;
    id: number;
}

/**
 * 
 */
export class CustomercustomerContactsApi extends runtime.BaseAPI {

    /**
     */
    async v1CustomerCustomersContactsListRaw(requestParameters: V1CustomerCustomersContactsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedContactList>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersContactsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/contacts/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedContactListFromJSON(jsonValue));
    }

    /**
     */
    async v1CustomerCustomersContactsList(requestParameters: V1CustomerCustomersContactsListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedContactList> {
        const response = await this.v1CustomerCustomersContactsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v1CustomerCustomersContactsRetrieveRaw(requestParameters: V1CustomerCustomersContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIContact>> {
        if (requestParameters.customersPk === null || requestParameters.customersPk === undefined) {
            throw new runtime.RequiredError('customersPk','Required parameter requestParameters.customersPk was null or undefined when calling v1CustomerCustomersContactsRetrieve.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1CustomerCustomersContactsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/customer/customers/{customers_pk}/contacts/{id}/`.replace(`{${"customers_pk"}}`, encodeURIComponent(String(requestParameters.customersPk))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIContactFromJSON(jsonValue));
    }

    /**
     */
    async v1CustomerCustomersContactsRetrieve(requestParameters: V1CustomerCustomersContactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIContact> {
        const response = await this.v1CustomerCustomersContactsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
