/* -------------------------- Design imports start -------------------------- */
import { AppBar, Badge, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import AccountButton from '../widgets/AccountButton'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined'
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined'
import { Menu, ShareRounded } from '@mui/icons-material'
import { DraftsOutlined, MailOutlined } from '@mui/icons-material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useContext, useEffect, useState } from 'react'
import Joyride, {Step as JoyrideStep} from 'react-joyride'
import { useHistory } from 'react-router-dom'
import ChannelLogic from '../../features/ChatSystem/components/Channel/ChannelLogic'
import { canView, fetchUser, handleAPICallV1, isPrivileged } from '../../utils/functions'
import { HTTPMethod, User, WebsocketMessage } from '../../utils/types'
import { Channel, Message, MessageObject } from '../../features/ChatSystem/utils/types'
import { useWebSocket } from '../../WebSocketProvider'
import LogTool from '../../logger/logTools'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../utils/context'
import { useJoyride } from '../widgets/Joyride'
import logo from '../../assets/logo_white.svg'
import { makeStyles } from '@mui/styles'
import Button from '../Button'
import { useWebsocket } from '../../utils/WebsocketProvider'
import { SharepointGlobalStates } from '../../features/Sharepoint/SharepointGlobalStates'
import { ChatSystemGlobalStates } from '../../features/ChatSystem/ChatSystemGlobalStateProvider'
import ContractOverviewAdminPage from '../../pages/ContractOverviewAdminPage'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  title?: string
  showBack?: boolean
  centerTitle?: boolean
  isDekstop?: boolean
  onBackClick?: () => void
  setUser: (user: any) => void
  darkMode: boolean
  setDarkMode: (darkMode: boolean) => void
  open: boolean
  handleSideBar: () => void
}


export default function Header(props: Props) {
  // const { socket } = useWebSocket()
  const {websocketMessage, websocketIsReady, websocketSend} = useWebsocket()
  const {unreadFeedbackSubmissionCount, addToFeedbackCount} = useContext(SharepointGlobalStates)
  const {unreadMessagesTotal} = useContext(ChatSystemGlobalStates)
  const { userPermissions, user } = useUserContext()

  const { t } = useTranslation()
  const log = new LogTool({ context: 'Header', enable: true, logLevel: 'warn' })
  /* -------------------------- Non state data start -------------------------- */
  const { setUser, setDarkMode, darkMode, handleSideBar } = props
  const { startJoyride, runJoyride, resetJoyride } = useJoyride()
  const useStyles = makeStyles((theme) => ({
    customBadge: {
      backgroundColor: '#eb9928',
      color: 'white'
    }
  }))
  const classes = useStyles()
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */
  /* ---------------------------- Data states start --------------------------- */
  const history = useHistory()
  const [badgeStatus_countMessages, setBadgeStatus_countMessages] = useState<number>(0)
  const [badgeStatus_countTotals, setBadgeStatus_countTotals] = useState<number>(0)
  const [activeUser, setActiveUser] = useState<User>({} as User)

  const [joyrideSteps] = useState<(JoyrideStep & {stepIndex?: number})[]>([
    {
      target: '#chatButton',
      content: t('common:feedback.info.chatOnboarding'),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 0,
      event: 'hover',
    },
    {
      target: '#fileSystemButton',
      title: t('common:content.label.filesystem'),
      content: t('common:feedback.info.fileSystemOnboarding'),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 0,
      event: 'hover',
    },
    {
      target: '#requestOverviewButton',
      content: t('common:feedback.info.requestOnboarding'),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 0,
      event: 'hover',
    },
    {
      target: '#accountButton',
      title: t('common:content.label.profile'),
      content: t('common:feedback.info.accountOnboarding'),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 0,
      event: 'hover',
    },
    {
      content: t('common:feedback.info.mainMenuOnboarding'),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 0,
      target: '#sideBarList',
      event: 'hover',
      disableBeacon: true,
    },
    {
      target: '#accordion-item',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      stepIndex: 1,
      spotlightPadding: 0,
      event: 'hover',
      content: t('common:feedback.info.subMenuOnboarding'),
    },
  ])

  /* ----------------------------- Data states end ---------------------------- */
  /* ---------------------------- use Effects start --------------------------- */
  // TODO: Check if user is admin and render RequestPageIcon if true
  useEffect(() => {
    fetchUser({
      onSuccess: (user: User) => {
        setActiveUser(user)
      },
    })
  }, [])

  useEffect(() => {
    checkNewMessages()
  }, [activeUser])

  useEffect(() => {
    if(websocketIsReady && typeof websocketMessage !== 'undefined') {
      const msgType = websocketMessage.type
      log.info('Handling message of type', msgType)

      if(msgType.startsWith("sharepoint_")) {
        handleSharepointNotification(websocketMessage)
      } else if(msgType.startsWith("chat_")) {
        handleChatNotification(websocketMessage)
      }
    }
  }, [websocketMessage])
  /* ----------------------------- use Effects end ---------------------------- */
  /* ------------------------- Utility functions start ------------------------ */
  async function checkNewMessages() {
    const [response, error] = await handleAPICallV1(
      HTTPMethod.GET,
      `chat/channel/?expand=messages`,
      undefined,
      undefined
    )
    if (error) {
      log.error('Error fetching channels: ', error)
    } else {
      const channels: Channel[] = response.results
      let count = 0
      channels.forEach(channel => {
        if (channel.messages.length > 0 && Array.isArray(channel.messages)) {
          channel.messages.forEach((message: Message) => {
            if (message.isNewMessage && message.author !== activeUser.self) {
              count++
            }
          })
        }
      })
      setBadgeStatus_countMessages(count)
    }
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleStartTour = () => {
    simulateMenuButtonClick()
    setTimeout(() => {
      localStorage.setItem('tourCompleted', 'false')
      startJoyride()
    }, 500)
  }

  const simulateMenuButtonClick = () => {
    const menuButton = document.querySelector('#menuButton') as HTMLButtonElement

    if (menuButton) {
      menuButton.click()

      setTimeout(() => {
        const sidebarList = document.querySelector('#accordion-item') as HTMLButtonElement

        if (sidebarList) {
          sidebarList.click()
        }
      }, 1000)
    }
  }

  const handleJoyrideCallback = (data: any) => {
    if (data.type === 'tour:end' || data.type === 'tour:status:after') {
      resetJoyride()
    }
    if (data.type === 'step:after' && data.step.target === '#accordion-item') {
      // Wenn der Schritt "Sub menus" erreicht ist, führe eine Verzögerung durch und wechsle dann die Seite
      setTimeout(() => {
        history.push({
          pathname: '/items',
          state: {
            runJoyride: true,
          },
        })
      }, 1000) // Anpassen der Verzögerungszeit nach Bedarf
    }
  }

  const handleSharepointNotification = (msg: WebsocketMessage) => {
    if(msg.type === 'sharepoint_feedback_submission') {
      let shareType: 'articleShares' | 'BOMShares' | 'guestRequestShares' = 'articleShares'
      if(msg.data.is_bom_share && !msg.data.is_guest_request_share) {
        shareType = 'BOMShares'
      }
      if(!msg.data.is_bom_share && msg.data.is_guest_request_share) {
        shareType = 'guestRequestShares'
      }
      log.debug('Handling sharepoint feedback submission notification of type', shareType)
      addToFeedbackCount(1, shareType)
    }
  }

  const handleChatNotification = (msg: WebsocketMessage) => {
    const messageObject: MessageObject = msg.data
    const message: any = messageObject[Object.keys(messageObject)[0]][0]
    if (msg.type === 'chat_message') {
      if (message.author !== activeUser.self) {
        setBadgeStatus_countMessages(prevBadgeCount => {
          return prevBadgeCount + 1
        })
      }
    }
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <React.Fragment>
      <AppBar>
        <Toolbar style={{ height: '40px', minHeight: '10px', color: 'whitesmoke' }}>
          <a>
            <Typography onClick={() => history.push('/items')} variant="h5">
              <img
                src={logo}
                alt="assemblean logo"
                style={{ width: '130px', height: '80px', margin: 'auto', display: 'block'}}
              />
            </Typography>
          </a>
          {!activeUser.isGuestUser &&
            <Tooltip title={t('common:interaction.tooltip.messages')}>
              <IconButton
                id="chatButton"
                style={{ marginLeft: '15px', color: 'inherit' }}
                onClick={() => {
                  setBadgeStatus_countMessages(0)
                  history.push('/chats')
                }}
              >
                <Badge badgeContent={unreadMessagesTotal} max={99} classes={{badge: classes.customBadge}}>
                  {!(history.location.pathname === '/chats') ? (
                    <MailOutlined color="inherit" />
                  ) : (
                    <DraftsOutlined color="inherit" />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
          }
          {!activeUser.isGuestUser &&
            <ChannelLogic header={true} setBadgeStatus_countMessages={setBadgeStatus_countMessages} />
          }
          {!activeUser.isGuestUser &&
            <Tooltip title={t('common:interaction.tooltip.files')}>
              <IconButton
                id="fileSystemButton"
                style={{ color: 'inherit' }}
                onClick={() => history.push('/files')}
              >
                <FolderOpenOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          {(canView(userPermissions, 'request:request') ||
            canView(userPermissions, 'contract:contract') ||
            isPrivileged(user)) && (
            <Tooltip title={t('common:interaction.tooltip.contracts')}>
              <IconButton
                id="requestOverviewButton"
                style={{ color: 'inherit' }}
                onClick={() => history.push('/contracts')}
              >
                <Badge badgeContent={!(history.location.pathname === '/contracts') ? badgeStatus_countTotals : 0} max={99} classes={{badge: classes.customBadge}}>
                  { !(history.location.pathname === '/contracts') ? (
                    <RequestPageOutlinedIcon />
                  ) : (
                    <RequestQuoteOutlinedIcon />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          {!activeUser.isGuestUser &&
            <Tooltip title={t('sharepoint:content.label.shares')}>
              <IconButton
                id='sharedArticlesButton'
                style={{color: 'inherit'}}
                onClick={() => history.push('/items/sharepoints')}
              >
                <Badge badgeContent={unreadFeedbackSubmissionCount.total ?? 0} max={99} classes={{badge: classes.customBadge}}>
                  <ShareRounded/>
                </Badge>
              </IconButton>
            </Tooltip>
          }
          {!activeUser.isGuestUser &&
            <ContractOverviewAdminPage setBadgeStatus_countTotals={setBadgeStatus_countTotals} header={true} />
          }
          <div style={{ marginLeft: 'auto', color: 'inherit' }}>
            {!user?.isGuestUser &&
              <Button
              id="tourButton"
              variant="outlined"
              size="small"
              sx={{ borderColor: 'whitesmoke', color: 'whitesmoke'}}
              onClick={handleStartTour}
              >
                {t('common:interaction.button.startTour')}
              </Button>
            }
            <AccountButton setUser={setUser} />
          </div>
        </Toolbar>
        <Toolbar
          style={{
            backgroundColor: '#16a9e4',
            height: '30px',
            minHeight: '20px',
            color: 'whitesmoke',
          }}
        >
          <IconButton
            id="menuButton"
            style={{ color: 'inherit' }}
            onClick={handleSideBar}
            edge="start"
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Joyride
        steps={joyrideSteps as JoyrideStep[]}
        run={runJoyride}
        continuous
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: t('common:interaction.button.back'),
          close: t('common:interaction.button.close'),
          last: t('common:interaction.button.next'),
          next: t('common:interaction.button.next'),
          skip: t('common:interaction.button.skip'),
        }}
      />
    </React.Fragment>
  )
}
