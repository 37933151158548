/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    APIPaginatedWasteList,
    APIPaginatedWasteListFromJSON,
    APIPaginatedWasteListToJSON,
    APIPatchedWasteRequest,
    APIPatchedWasteRequestFromJSON,
    APIPatchedWasteRequestToJSON,
    APIWaste,
    APIWasteFromJSON,
    APIWasteToJSON,
    APIWasteRequest,
    APIWasteRequestFromJSON,
    APIWasteRequestToJSON,
} from '../models';

export interface V1ItemsWasteCreateRequest {
    aPIWasteRequest: APIWasteRequest;
}

export interface V1ItemsWasteDestroyRequest {
    id: number;
}

export interface V1ItemsWasteListRequest {
    page?: number;
    pageSize?: number;
}

export interface V1ItemsWastePartialUpdateRequest {
    id: number;
    aPIPatchedWasteRequest?: APIPatchedWasteRequest;
}

export interface V1ItemsWasteRetrieveRequest {
    id: number;
}

export interface V1ItemsWasteUpdateRequest {
    id: number;
    aPIWasteRequest: APIWasteRequest;
}

/**
 * 
 */
export class ItemswasteApi extends runtime.BaseAPI {

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteCreateRaw(requestParameters: V1ItemsWasteCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.aPIWasteRequest === null || requestParameters.aPIWasteRequest === undefined) {
            throw new runtime.RequiredError('aPIWasteRequest','Required parameter requestParameters.aPIWasteRequest was null or undefined when calling v1ItemsWasteCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: APIWasteRequestToJSON(requestParameters.aPIWasteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteCreate(requestParameters: V1ItemsWasteCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWasteCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteDestroyRaw(requestParameters: V1ItemsWasteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWasteDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteDestroy(requestParameters: V1ItemsWasteDestroyRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.v1ItemsWasteDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteListRaw(requestParameters: V1ItemsWasteListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIPaginatedWasteList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIPaginatedWasteListFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteList(requestParameters: V1ItemsWasteListRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIPaginatedWasteList> {
        const response = await this.v1ItemsWasteListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWastePartialUpdateRaw(requestParameters: V1ItemsWastePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWastePartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: APIPatchedWasteRequestToJSON(requestParameters.aPIPatchedWasteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWastePartialUpdate(requestParameters: V1ItemsWastePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWastePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteRetrieveRaw(requestParameters: V1ItemsWasteRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWasteRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteRetrieve(requestParameters: V1ItemsWasteRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWasteRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteUpdateRaw(requestParameters: V1ItemsWasteUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<APIWaste>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1ItemsWasteUpdate.');
        }

        if (requestParameters.aPIWasteRequest === null || requestParameters.aPIWasteRequest === undefined) {
            throw new runtime.RequiredError('aPIWasteRequest','Required parameter requestParameters.aPIWasteRequest was null or undefined when calling v1ItemsWasteUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/items/waste/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: APIWasteRequestToJSON(requestParameters.aPIWasteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => APIWasteFromJSON(jsonValue));
    }

    /**
     * Mixin to provide support for Serializer Extensions within API views.
     */
    async v1ItemsWasteUpdate(requestParameters: V1ItemsWasteUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<APIWaste> {
        const response = await this.v1ItemsWasteUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
