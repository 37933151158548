/* -------------------------- Design imports start -------------------------- */
import {
  Box,
  Grid,
  IconButton,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Add, Delete } from '@mui/icons-material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../../../logger/logTools'
import { FileInfo, FileUploadStatus, SelectOption } from '../../../utils/types'
import { deleteFile, missingRequiredFormFields, uploadFile } from '../../../utils/functions'
import GetConstant from '../utils/constants'
import { Form, FormFieldType } from '../../Form'
import MultilevelDrawer from '../../../components/layout/MultilevelDrawer'
import { APIRequest } from '../../../generated-types'
import FileInput from '../../../components/inputs/FileInput'
import InputFileList from '../../../components/widgets/InputFileList'
import Button from '../../../components/Button'
import { postRequest } from '../utils/functions'
import { toast } from 'react-toastify'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onCancel?: () => void
  onCreateConfirm?: (a: any) => Promise<void>
  enableEditMode?: boolean
  onEditConfirm?: (input: any) => Promise<void>
}

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function RequestDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    formState,
    onClose = () => null,
    onCancel = () => null,
    onCreateConfirm = () => null,
    enableEditMode = false,
    onEditConfirm = () => alert('TODO: provide a onEditConfirm callback!'),
  } = props

  const customTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: '#16a9e4',
            },
            '&.Mui-completed': {
              color: '#16a9e4',
            },
          },
          text: {
            color: 'white', // Set the text color to white for all steps
          },
        },
      },
    },
  })
  const { t } = useTranslation(['request', 'common'])
  const log = new LogTool({ context: 'RequestDrawer', enable: true, logLevel: 'warn' })
  const steps = [
    t('common:content.label.generalInformation'),
    t('common:content.label.shipping'),
    t('common:content.label.upload'),
  ]
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [sameBillingAddress, setSameBillingAddress] = useState(true)
  const [waitingOnAction, setWaiting] = useState(false)
  const [prevOpen, setPrevOpen] = useState<boolean>(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [fileInput, setFileInput] = useState<FileInfo[]>([])
  const [skippedSteps, setSkippedSteps] = useState(new Set<number>())
  const [activeStep, setActiveStep] = useState<number>(0)
  const [quantities, setQuantities] = useState<string[]>([''])
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (open && !prevOpen) {
      setPrevOpen(true)
      setQuantities([''])
    }
  }, [open])

  useEffect(() => {
    if (quantities && quantities.length > 0 && quantities[quantities.length - 1] !== '') {
      formState.setState({
        ...formState.state,
        quantities: quantities.map(quantity => parseInt(quantity)),
      })
    }
  }, [quantities])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Setter for the uploadStatus property on a FileInfo object within this component.
   * Do not use this function if you want to update multiple files at once as the component is rerendered after every status update!
   * @param file the file on which the uploadStatus should be updated.
   * @param status the updated uploadStatus.
   */
  function setFileUploadStatus(file: FileInfo, status: FileUploadStatus) {
    setFileInput((prev: FileInfo[]) =>
      prev.map((listedFile: FileInfo) => {
        if (listedFile.name === file.name) {
          // add an upload-status of success to the file info object or overwrite it with the provided status
          return Object.assign(file, { uploadStatus: status })
        }
        return listedFile
      })
    )
  }

  /**
   * Wrapper for the uploadFile function that specifies the onSuccess and onError callbacks.
   * This function simply allows us to upload files using the same behavior at multiple places
   * within this component without rewriting the callback functions all the time.
   * @param file the file to upload.
   * @param url the url to upload the file to.
   */
  async function uploadRequestFile(file: FileInfo, url: string) {
    uploadFile({
      url: url,
      file: file as File,
      onSuccess: (response: FileInfo) => {
        // update the listed file
        setFileInput(prev =>
          prev.map((listedFile: FileInfo) => {
            if (listedFile.name === file.name) {
              return { ...response, uploadStatus: 'success' }
            }
            return listedFile
          })
        )
      },
      onError: () => {
        // update the uploadStatus of the file
        setFileUploadStatus(file, 'uploadError')
      },
    })
  }

  /**
   * Check if all required FormFields of the current step are filled in correclty.
   * @param activeStep
   * @returns
   */
  const nextStepDisabled = (activeStep: number): boolean => {
    if (activeStep === 0) {
      return (
        quantities[0] === '' ||
        !/^\d+$/.test(quantities[0]) ||
        missingRequiredFormFields([...requestFormFields], formState.state)
      )
    } else if (activeStep === 1) {
      return missingRequiredFormFields([...addressFormFields], formState.state)
    }
    return false
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  const handleCreateRequest = async () => {
    log.debug('handleCreateRequest -> ', formState.state)
    setWaiting(true)
    // set loading state on all files to loading
    if (fileInput.length > 0) {
      setFileInput((prev: FileInfo[]) =>
        prev.map(file => Object.assign(file, { uploadStatus: 'loading' }))
      )
    }
    // posting request to server and waiting on response
    const requestFilesUrl = await onCreateConfirm({ ...formState.state })
    log.debug('requestFilesUrl -> ', requestFilesUrl)
    // check if the user specified files to upload !
    if (fileInput.length > 0 && requestFilesUrl) {
      // upload the files one by one for a more detailed feedback if something goes wrong,
      // and to make use parallel uploading to decrease upload time
      for (const file of fileInput) {
        uploadRequestFile(file, requestFilesUrl)
        // response.articleFilesUrl cannot be undefined here as the server created the article successfully and therefore created an article files url

        // store the articleFilesUrl in the inputFiles state to have it available in case something goes wrong
        /* setInputFiles((prev: FileInfo[]) => prev.map(
          file => Object.assign(file, {uploadUrl: response.articleFilesUrl})
        )) */
      }
    }
    setWaiting(false)
    handleClose()
  }

  const handleUpdateRequest = async () => {
    log.debug('handleUpdateRequest -> ', formState.state)
  }

  /**
   * Remove a file from fileInput list before it is uploaded
   * @param file
   */
  const handleRemoveFileFromList = (file: FileInfo) => {
    // update rendered files list
    setFileInput(prevFileList => {
      // get the location of the file to remove within the files list
      const fileIndex = prevFileList.findIndex(listedFile => listedFile.name === file.name)
      // remove the file from the list. Note that this does not work on the prevFileList directly
      let updatedFileList = [...prevFileList]
      updatedFileList.splice(fileIndex, 1)

      // return the files list where the file is removed
      return updatedFileList
    })
  }
  /**
   * (Re)upload a file to the specified url.
   * @param file The file to upload
   * @param url The url to upload the file to.
   */
  const handleReUploadFile = async (file: FileInfo, url: string) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // retry to upload the file
    uploadRequestFile(file, url)
  }

  /**
   * Delete a file that was uploaded already.
   * @param file The file to delete.
   */
  const handleDeleteFile = async (file: FileInfo) => {
    // set the uploadStatus of the current file to loading
    setFileUploadStatus(file, 'loading')
    // try to delete the file
    deleteFile({
      url: file.self as string,
      onSuccess: () => {
        handleRemoveFileFromList(file)
      },
      onError: () => {
        setFileUploadStatus(file, 'deleteError')
      },
    })
  }

  const isStepOptional = (step: number) => {
    return step === 4
  }

  const isStepSkipped = (step: number) => {
    return skippedSteps.has(step)
  }

  const handleNext = () => {
    let newSkipped = skippedSteps
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkippedSteps(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleClose = (preventOnClose: boolean = false) => {
    // reset form state
    setWaiting(false)
    formState.setState({})
    setFileInput([])
    setActiveStep(0)

    setOpen(false)
    setPrevOpen(false)
    !preventOnClose && onClose()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const certificateOptions = GetConstant({ name: 'certificateOptions' }) as SelectOption[]
  const requestFormFieldsDisabled = GetConstant({
    name: 'requestFormFieldsDisabled',
  }) as FormFieldType[]
  const requestFormFields = GetConstant({
    name: 'requestFormFields',
    certificateOptions: certificateOptions,
    selectedProduct: formState.state?.article?.self || '',
  }) as FormFieldType[]
  const addressFormFields = GetConstant({
    name: 'addressFormFields',
  }) as FormFieldType[]

  const CustomStepIcon = (props: any) => {
    return (
      <ThemeProvider theme={customTheme}>
        <StepIcon {...props} />
      </ThemeProvider>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  log.debug('FormInput -> ', formState.state)

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        size="big"
        title={t('common:content.label.createRequest') as string}
        customHeader={
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Typography variant="h6" noWrap sx={{ flexShrink: 0, mr: '2rem' }}>
              {enableEditMode
                ? t('request:content.label.editRequest')
                : t('common:content.label.createRequest')}
            </Typography>
            <Stepper
              activeStep={activeStep}
              sx={{ marginRight: '16px', marginLeft: '-8px', width: '100%' }}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: { optional?: React.ReactNode } = {}
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>
        }
        customActions={
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {activeStep === 0 ? (
              <Button
                id="cancelButton"
                onClick={() => setOpen(false)}
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                sx={{ mr: 1, textTransform: 'none' }}
              >
                {t('common:interaction.button.cancel')}
              </Button>
            ) : (
              <Button
                id="backButton"
                color="inherit"
                variant="contained"
                disabled={waitingOnAction}
                onClick={handleBack}
                sx={{ mr: 1, textTransform: 'none' }}
              >
                {t('common:interaction.button.back')}
              </Button>
            )}
            {isStepOptional(activeStep) && (
              <Button id="skipButton" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {t('common:interaction.button.skip')}
              </Button>
            )}
            {activeStep < steps.length - 1 ? (
              <Button
                id="nextButton"
                variant="contained"
                sx={{ marginLeft: '10px' }}
                disabled={nextStepDisabled(activeStep)}
                onClick={handleNext}
              >
                {t('common:interaction.button.next')}
              </Button>
            ) : (
              <LoadingButton
                id="createButton"
                variant="contained"
                sx={{ marginLeft: '10px', color: 'whitesmoke', textTransform: 'none' }}
                disabled={nextStepDisabled(activeStep)}
                loading={waitingOnAction}
                onClick={() => {
                  if (enableEditMode) {
                    handleUpdateRequest()
                  } else {
                    handleCreateRequest()
                  }
                }}
              >
                {enableEditMode
                  ? t('common:interaction.button.save')
                  : t('common:interaction.button.create')}
              </LoadingButton>
            )}
          </Box>
        }
      >
        <React.Fragment>
          {activeStep === 0 && (
            <>
              <Typography variant="h6" style={{ marginBottom: '10px', marginTop: '20px' }}>
                {t('common:content.label.generalInformation')}
              </Typography>
              <Form
                sx={{ marginBottom: '20px' }}
                formObject={formState.state?.article ?? { name: '', number: '' }}
                formFields={requestFormFieldsDisabled}
                editing={false}
                onChange={() => {}}
              />
              <Grid container spacing={1} marginBottom={'20px'}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    id="quantity-1"
                    label={t('request:content.label.quantity') + ' 1'}
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={quantities[0] || ''}
                    onChange={e => setQuantities([e.target.value, ...quantities.slice(1)])}
                    required
                    error={quantities[0] === '' || !/^\d+$/.test(quantities[0])}
                    helperText={
                      quantities[0] === ''
                        ? t('request:feedback.error.pleaseEnterQuantity')
                        : !/^\d+$/.test(quantities[0])
                        ? t('request:feedback.error.invalidQuantity')
                        : ''
                    }
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                  />
                </Grid>
                {quantities.slice(1).map((quantity, index) => (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                    <TextField
                      id={`quantity-${index}`}
                      label={t('request:content.label.quantity') + ` ${index + 2}`}
                      type="text"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() =>
                              setQuantities([
                                ...quantities.slice(0, index),
                                ...quantities.slice(index + 1),
                              ])
                            }
                            style={{ color: 'grey' }}
                            size="small"
                            aria-label="add"
                          >
                            <Delete />
                          </IconButton>
                        ),
                      }}
                      value={quantity}
                      error={quantities[index + 1] !== '' && !/^\d+$/.test(quantities[index + 1])}
                      helperText={
                        quantities[index + 1] !== '' && !/^\d+$/.test(quantities[index + 1])
                          ? t('request:feedback.error.invalidQuantity')
                          : ''
                      }
                      onChange={e => {
                        const newQuantities = [...quantities]
                        newQuantities[index + 1] = e.target.value
                        setQuantities(newQuantities)
                      }}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              {quantities.length < 10 && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Button
                    onClick={() => setQuantities([...quantities, ''])}
                    color="primary"
                    variant="text"
                    startIcon={<Add />}
                    size="large"
                    aria-label="add"
                    style={{ marginBottom: '20px' }}
                  >
                    {t('request:interaction.button.newQuantity')}
                  </Button>
                </Grid>
              )}
              <Form
                formObject={formState.state}
                formFields={requestFormFields}
                onChange={value => formState.setState(value as APIRequest)}
                editing={true}
              />
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography variant="h6" style={{ marginTop: '15px', marginBottom: '10px' }}>
                {t('common:content.label.shipping')}
              </Typography>
              <Form
                formObject={formState.state}
                formFields={addressFormFields}
                onChange={value => formState.setState(value as APIRequest)}
                editing={true}
              />
            </>
          )}
          {activeStep === 2 && (
            <Grid>
              <Typography variant="h6" sx={{ marginTop: '15px', marginBottom: '10px' }}>
                {t('common:content.label.documents')}
              </Typography>
              <FileInput
                inputState={{ state: fileInput, setState: setFileInput }}
                maxAllowedFiles={10}
              />
              {fileInput.length > 0 && (
                <InputFileList
                  files={fileInput}
                  noFilesInfo=""
                  enableEditMode={false}
                  onRemoveFile={handleRemoveFileFromList}
                  onReUploadFile={handleReUploadFile}
                  onDeleteFile={handleDeleteFile}
                />
              )}
            </Grid>
          )}
        </React.Fragment>
      </MultilevelDrawer>
    </>
  )
}
