/* ------------------------ Functional imports start ------------------------ */
import {  APISupplierFromJSON } from "../../../generated-types"
import LogTool from "../../../logger/logTools"
import { Supplier, SupplierInfo } from "./types"
import { buildFetchUrl, createContact, getPKfromSelf, handleAPICallV1 } from "../../../utils/functions"
import { Contact, FetchKwargs, HTTPMethod } from "../../../utils/types"
import { createArticle } from "../../Item/utils/functions"
/* ------------------------- Functional imports end ------------------------- */


const log = new LogTool({context: 'SupplierUtils', enable: true, logLevel: 'warn'})


/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */

export function createSupplierInfo(supplierResponse: any): SupplierInfo {
  const apiSupplier = APISupplierFromJSON(supplierResponse)

  return {
    ...apiSupplier,
    ...((supplierResponse.contacts
      && Array.isArray(supplierResponse.contacts))
        ? {contacts: supplierResponse.contacts}
        : {contacts: []}),
    key: getPKfromSelf(apiSupplier.self),
  }
}

export function createSupplier(supplierResponse: any): Supplier {
  const parseSupplierContacts = (contactResponse: any)=> {
    if(Array.isArray(contactResponse)) {
      if(contactResponse.every(contact => typeof contact === 'object')) {
        // contactResponse is a list of contacts
        return contactResponse.map(contact => createContact(contact))
      }
    }
    // contactResponse does not contain Contact objects
    // response likely contains url that leads to contacts
    return contactResponse
  }
  const parseSupplierArticles = (articleResponse: any)=> {
    if(Array.isArray(articleResponse)) {
      if(articleResponse.every(article => typeof article === 'object')) {
        // articleResponse is a list of articles
        return articleResponse.map(article => createArticle(article))
      }
    }
    // articleResponse does not contain Article objects
    // response likely contains url that leads to articles
    return articleResponse
  }
  const apiSupplier = APISupplierFromJSON(supplierResponse)

  return {
    ...apiSupplier,
    key: getPKfromSelf(apiSupplier.self),
    contacts: parseSupplierContacts(apiSupplier.contacts),
    articles: parseSupplierArticles(apiSupplier.articles)
  }
}

export function createSupplierContact(supplierContactResponse: any): Contact {
    return createContact(supplierContactResponse)
}

export function formInputToAPISupplierContactJSON(input: any) {
  return {
    ...((input.supplier || input.supplier === null) && {'supplier': input.supplier}),
    ...(input.email && {'email': input.email}),
    ...(input.phone && {'phone': input.phone}),
    ...(input.firstName && {'first_name': input.firstName}),
    ...(input.lastName && {'last_name': input.lastName}),
    ...(input.language && {'language': input.language}),
    ...(input.comment && {'comment': input.comment}),
  }
}

export function formInputToAPISupplierJSON(input: any) {
  return {
    ...(input.name && {'name': input.name}),
    ...(input.ustId && {'ust_id': input.ustId}),
    ...(input.versionComment && {'version_comment': input.versionComment}),
    'contacts': (input.contacts && Array.isArray(input.contacts) && input.contacts.length > 0)
      ? (typeof input.contacts[0] === 'string'
        ? input.contacts
        : input.contacts.map((contact: any) => contact.self))
      : [],
  };
}

/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */


/**
 * Fetch supplier data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchSuppliers({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'supplier/suppliers/'
}: FetchKwargs<Supplier>): Promise<void>
{
  log.info("Begin fetching suppliers.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/supplier\/suppliers\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch suppliers
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching suppliers for URL", fetchUrl, ", Response ->", response)
    const suppliers = response.results.map((supplierResponse: any) => createSupplier(supplierResponse))
    const count = response.count

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchSuppliers({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(suppliers, count)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching suppliers", error)
    onError(error)
  }
}

export async function fetchSupplier(
  url: string,
  onSuccess: (response: Supplier) => void,
  onError: (error: any) => void,
  parameter: string[] = []
) {
  log.info("Begin fetching supplier.")

  const fetchUrl = url + buildFetchUrl("", parameter)
  // fetch supplier
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching supplier for URL", url)
    const supplier = createSupplier(response)
    onSuccess(supplier)
  } else {
    log.error("Error fetching supplier", error)
    onError(error)
  }
}

export async function postSupplier(
  supplier: Supplier,
  onSuccess: (supplier: Supplier) => void,
  onError: (error: any) => void,
) {
  log.info("Begin posting supplier.")
  if(!supplier) return
  const json = formInputToAPISupplierJSON(supplier)
  const [response, error] = await handleAPICallV1(
    HTTPMethod.POST,
    'supplier/suppliers/',
    undefined,
    json
  )

  if(!error && response) {
    log.info("Successfully posted supplier.")
    fetchSupplier(
      response.self,
      (supplier: Supplier) => onSuccess(supplier),
      (error: any) => onError(error),
      ["expand=contacts"]
    )
  } else {
    log.error("Error posting supplier.", error)
    onError(error)
  }
}

export async function updateSupplier(
  supplier: Supplier,
  onSuccess: (supplier: Supplier) => void,
  onError: (error: any) => void,
) {
  log.info("Begin updating supplier.")
  if(!supplier) return
  const json = formInputToAPISupplierJSON(supplier)
  const [response, error] = await handleAPICallV1(
    HTTPMethod.PUT,
    supplier.self,
    undefined,
    json
  )

  if(!error && response) {
    log.info("Successfully updated supplier.")
    const supplier = createSupplier(response)
    onSuccess(supplier)
  } else {
    log.error("Error updating supplier.", error)
    onError(error)
  }
}

export async function deleteSuppliers(
  suppliers: Supplier[],
  onSuccess: (suppliers: Supplier[]) => void,
  onError: () => void,
) {
  log.info("Begin deleting suppliers.")
  if(suppliers.length === 0) return
  const promises = suppliers.map((supplier: Supplier) => deleteSupplier(supplier.self))
  const results = await Promise.all(promises)
  if(results.every(result => result === true)) {
    log.info("Successfully deleted suppliers.")
    onSuccess(suppliers)
  } else {
    log.error("Error deleting suppliers.")
    onError()
  }
}

async function deleteSupplier(supplier: string): Promise<boolean> {
  const [response, error] = await handleAPICallV1(
    HTTPMethod.DELETE,
    supplier,
    undefined,
    undefined,
    'text'
  )
  if(!error) {
    log.info("Successfully deleted supplier.")
    return true
  } else {
    log.error("Error deleting supplier.", error)
    return false
  }
}

/* export async function fetchSupplierContacts(kwargs: {
  onSuccess: (response: Contact[]) => void,
  onError?: (error: any) => void,
  url?: string,
}): Promise<void>
{
  // get kwargs and set defaults
  const {
    onSuccess,
    onError = (error: any) => null,
    url = 'supplier/contacts/'
  } = kwargs


  log.info("Begin fetching supplier-contacts.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/^supplier\/contacts\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
  }

  // fetch supplier contacts
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    url
  )

  if(!error && response) {
    log.info("Success fetching articles for URL", url)
    const supplierContacts = response.results.map((supplierContactResponse: any) => createSupplierContact(supplierContactResponse))
    onSuccess(supplierContacts)

    // fetch all pages of a paginated server response
    if(response.next) {
      fetchSupplierContacts({onSuccess: onSuccess, onError: onError, url: response.next})
    }
  } else {
    log.error("Error fetching articles", error)
    onError(error)
  }
} */

export const loadSupplierVersionsOptions = async (url?: string) : Promise<{ value: string; label?: string; disabled?: boolean}[]> => {
  log.debug('loadSupplierVersionsOptions -> ', url)
  if(!url) return []
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url + 'versions/', undefined, undefined)
  if (error) {
    log.error('Error fetching suppliers', error)
    return []
  }
  if (response) {
    const versions = response.map((version: any, index: number) => ({
      value: version.date_created,
      label: `${index + 1}: ${version.user} (${version.date_created}) - ${version.comment}`,
    }))
    if(response.next){
      const nextVersionsData = await loadSupplierVersionsOptions(response.next)
      versions.push(...nextVersionsData)
    }
    log.debug('versions', versions)
    return versions
  }
  return []
}